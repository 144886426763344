import React, { useState, useEffect, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { InputText, InputPhone, Button, toastHelper } from "@soltivo/draw-a-line";
import styles from "./create.location.module.scss";
import { RootState } from "../../../../../../redux/reducers";
import { MapboxPlacesFeatures, Organization } from "../../../../../../applications/auth/organization";
import { orgSetup } from "../../../../../../applications/auth/redux/org/reducer/actions";
import GuideOrgContext from "../../../context/guide.org.context";
import { guideChangeAction, guideOrgChangeContextState, guideOrgChangeFormState } from "../../../context/actions";
import { User } from "../../../../../../applications/auth/auth";
import useGuideHeading from "../../../hooks/useGuideHeading";
import { GuideOrgContextState } from "../../../context/reducer";
import { ValidationError } from "../../../../../../helpers/api.v2";
import GridTime from "../../../blocks/grid.time/grid.time";
import InputSearchPlaces from "../../../components/input.search.places/input.search.places";
import InputPlaces from "../../../components/input.places/input.places";
import moment from "moment";

export interface CreateLocationProps extends MapStateToProps {}

const CreateLocation: React.FC<CreateLocationProps> = ({ user, org }) => {
    const initialBusiness: Organization["locations"][0]["businessHours"] = [
        {
            day: "Monday",
            start: "00:00",
            end: "00:00",
        },
        {
            day: "Tuesday",
            start: "00:00",
            end: "00:00",
        },
        {
            day: "Wednesday",
            start: "00:00",
            end: "00:00",
        },
        {
            day: "Thursday",
            start: "00:00",
            end: "00:00",
        },
        {
            day: "Friday",
            start: "00:00",
            end: "00:00",
        },
        {
            day: "Saturday",
            start: "00:00",
            end: "00:00",
        },
        {
            day: "Sunday",
            start: "00:00",
            end: "00:00",
        },
    ];

    const [view, setView] = useState<"list" | "create" | "hours">("list");
    const [weekdays, setWeekdays] = useState<Organization["locations"][0]["businessHours"][0]["day"][]>([]);

    const {
        state: {
            form: { locations },
            locationIndex,
        },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    const dispatch = useDispatch();

    const selectedLocation = typeof locationIndex === "number" ? locations[locationIndex] : undefined;

    const onEnableWeekday = (checked: boolean, weekday: Organization["locations"][0]["businessHours"][0]["day"]) => {
        setWeekdays((state) => (checked ? [...state, weekday] : state.filter((item) => item !== weekday)));
        dispatchContext(
            guideOrgChangeFormState({
                locations: [...locations].map((loc, index) => {
                    if (index === locationIndex && selectedLocation) {
                        loc.businessHours = selectedLocation.businessHours.map((weekday) => {
                            if (!weekdays.includes(weekday.day)) {
                                weekday = {
                                    ...weekday,
                                    end: checked ? "" : "00:00",
                                    start: checked ? "" : "00:00",
                                };
                            }
                            return weekday;
                        });
                    }

                    return loc;
                }),
            })
        );
    };

    const onSelectLocation = (place: MapboxPlacesFeatures) => {
        const region = place.context?.find((context) => context.id.includes("region"));
        if (!place.center || !place.context) return;

        const ctx = place.context.find((ctx) => /^(place)/.test(ctx.id.toLowerCase()));
        let newListOfLocations: GuideOrgContextState["form"]["locations"] = [...locations];

        if (typeof locationIndex === "number" && view === "create") {
            // modify
            newListOfLocations[locationIndex] = {
                name: ctx?.text || place.place_name,
                phoneNumber: user.phoneNumber,
                businessHours: initialBusiness,
                email: user.email,
                address: place.place_name,
                center: place.center,
                context: place.context,
                taxes: region?.short_code || "tax-exempt",
            };
        } else if (view === "list") {
            newListOfLocations =
                org.businessType === "multi-organization"
                    ? [
                          ...locations,
                          {
                              name: ctx?.text || place.place_name,
                              phoneNumber: user.phoneNumber,
                              businessHours: initialBusiness,
                              email: user.email,
                              address: place.place_name,
                              center: place.center,
                              context: place.context,
                              taxes: region?.short_code || "tax-exempt",
                          },
                      ]
                    : [
                          {
                              name: ctx?.text || place.place_name,
                              phoneNumber: user.phoneNumber,
                              businessHours: initialBusiness,
                              email: user.email,
                              address: place.place_name,
                              center: place.center,
                              context: place.context,
                              taxes: region?.short_code || "tax-exempt",
                          },
                      ];

            if (org.businessType === "multi-organization") {
                setView("create");
                dispatchContext(
                    guideOrgChangeContextState({
                        locationIndex: newListOfLocations.length - 1,
                    })
                );
            }
        }

        dispatchContext(
            guideOrgChangeFormState({
                locations: newListOfLocations,
            })
        );
    };

    // useEffect(() => {
    //     onChangeActions({
    //         next: ,
    //     });
    // }, [locations, selectedLocation?.businessHours?.toString(), weekdays.toString(), view, onChangeActions]);

    useGuideHeading({
        title: view === "create" ? "Location information" : view === "hours" ? "Location’s business hours" : "Business location",
        description:
            view === "create"
                ? "Add information related to your location so your customer can easily contact you."
                : view === "hours"
                ? "Let your customer know when you’re open, plus we’ll create your schedule for you to receive appointments based on your business hours"
                : "Search your business address to add it as a location. A location is your point of sales.",
    });

    useEffect(() => {
        if (typeof locationIndex === "number") {
            setWeekdays(locations[locationIndex].businessHours?.filter((weekday) => weekday.start !== "00:00" && weekday.end !== "00:00").map((item) => item.day) || []);
        }
    }, [locationIndex]);
    //
    useEffect(() => {
        setView("list");
    }, []);

    useEffect(() => {
        // redirect to hours if not multi organization
        if (view === "list" && org.businessType !== "multi-organization" && locations.length === 1) {
            setView("hours");
            dispatchContext(
                guideOrgChangeContextState({
                    locationIndex: 0,
                })
            );
        }
    }, [org.businessType, locations.length, view]);

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    disabled: view === "hours" ? !weekdays.length : !locations.length,
                    onClick: () => {
                        try {
                            if (view === "list") {
                                if (org.businessType !== "multi-organization") {
                                    setView("hours");
                                } else {
                                    if (locations.length) {
                                        dispatch(
                                            orgSetup({
                                                nextStep: "services",
                                                data: {
                                                    locations: locations,
                                                },
                                            })
                                        );
                                    }
                                }
                            } else if (view === "create" && selectedLocation?.businessHours) {
                                if (!selectedLocation.name) {
                                    throw new ValidationError({ message: `Location name is missing.` });
                                } else if (selectedLocation.name.length < 3) {
                                    throw new ValidationError({ message: `Location name should have at least 3 characters.` });
                                } else if (!selectedLocation.address) {
                                    throw new ValidationError({ message: `Location is missing address.` });
                                }

                                setView("hours");
                            } else if (view === "hours" && selectedLocation?.businessHours) {
                                for (let i = 0; i < selectedLocation.businessHours.length; i++) {
                                    const weekday = selectedLocation.businessHours[i];

                                    if (weekdays.includes(weekday.day)) {
                                        if (!/([0-9]{2}):([0-9]{2})/.test(weekday.start) || !/([0-9]{2}):([0-9]{2})/.test(weekday.end)) {
                                            throw new ValidationError({ message: `${weekday.day} is invalid, make sure to add when you open and close with 24 hours format.` });
                                        } else if (weekday.start === weekday.end) {
                                            throw new ValidationError({ message: `${weekday.day} is invalid, you cannot open and close at the same time.` });
                                        } else {
                                            let dateStart = moment();
                                            let dateEnd = moment();
                                            const splitStart = weekday.start.split(":");
                                            const splitEnd = weekday.end.split(":");

                                            dateStart.set("hour", parseInt(splitStart[0]));
                                            dateStart.set("minute", parseInt(splitStart[1]));

                                            dateEnd.set("hour", parseInt(splitEnd[0]));
                                            dateEnd.set("minute", parseInt(splitEnd[1]));
                                            if (dateStart.valueOf() >= dateEnd.valueOf()) {
                                                throw new ValidationError({ message: `You cannot open at ${weekday.start} and close at ${weekday.end} on ${weekday.day}.` });
                                            }
                                        }
                                    }
                                }

                                dispatchContext(
                                    guideOrgChangeFormState({
                                        locations: [...locations].map((loc, index) => {
                                            if (index === locationIndex) {
                                                loc.businessHours = selectedLocation?.businessHours?.map((weekday) => {
                                                    if (!weekdays.includes(weekday.day)) {
                                                        weekday = {
                                                            ...weekday,
                                                            end: "00:00",
                                                            start: "00:00",
                                                        };
                                                    }
                                                    return weekday;
                                                });
                                            }

                                            return loc;
                                        }),
                                    })
                                );

                                if (org.businessType !== "multi-organization") {
                                    dispatch(
                                        orgSetup({
                                            nextStep: "services",
                                            data: {
                                                locations: locations,
                                            },
                                        })
                                    );
                                } else {
                                    setView("list");
                                }
                            }
                        } catch (error) {
                            if (error instanceof ValidationError) {
                                toastHelper.toastStartContent("danger", error.message);
                            }
                        }
                    },
                },
            })
        );
    }, [locations, selectedLocation?.businessHours?.toString(), weekdays.toString(), view]);

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "back",
                payload: {
                    onClick:
                        view === "list" || (org.businessType !== "multi-organization" && view === "hours")
                            ? undefined
                            : () => {
                                  if (view === "create") {
                                      setView("list");
                                      dispatchContext(
                                          guideOrgChangeContextState({
                                              locationIndex: undefined,
                                          })
                                      );
                                  } else if (view === "hours") {
                                      setView("list");
                                      if (org.businessType !== "multi-organization") {
                                          dispatchContext(
                                              guideOrgChangeFormState({
                                                  locations: [],
                                              })
                                          );
                                      }

                                      dispatchContext(
                                          guideOrgChangeContextState({
                                              locationIndex: undefined,
                                          })
                                      );
                                  }
                              },
                },
            })
        );
    }, [view]);

    return (
        <div className={styles.setup__location}>
            <div className="w-100 h-100">
                {view === "create" ? (
                    <div data-testid="inputsContainer" className={styles.form__container}>
                        <div className={styles.form__wrapper}>
                            <label>Location name</label>
                            <InputText
                                data-testid={"name"}
                                value={selectedLocation?.name}
                                required={true}
                                name={"name"}
                                focus={false}
                                type={"name"}
                                onChange={(e) => {
                                    if (typeof locationIndex !== "number") return;
                                    let newLocations = [...locations];
                                    newLocations[locationIndex].name = e.target.value;
                                    dispatchContext(
                                        guideOrgChangeFormState({
                                            locations: newLocations,
                                        })
                                    );
                                }}
                                placeholder={"Location name"}
                                autoComplete="off"
                            />
                        </div>

                        <div className={styles.form__wrapper}>
                            <label>Address</label>
                            <InputPlaces
                                onSelectPlace={(place) => {
                                    onSelectLocation(place);
                                }}
                                inputTextProps={{
                                    required: true,
                                    focus: false,
                                    value: selectedLocation?.address,
                                    onChange: (e) => {
                                        if (typeof locationIndex !== "number") return;
                                        let newLocations = [...locations];
                                        newLocations[locationIndex].address = e.target.value;
                                        dispatchContext(
                                            guideOrgChangeFormState({
                                                locations: newLocations,
                                            })
                                        );
                                    },
                                }}
                            />
                        </div>

                        <div className={styles.form__wrapper}>
                            <label>Location phone number</label>
                            <InputPhone
                                country={"ca"}
                                onlyCountries={["us", "ca", "fr"]}
                                inputStyle={{
                                    width: "100%",
                                }}
                                value={selectedLocation?.phoneNumber}
                                onChange={(value) => {
                                    if (typeof locationIndex !== "number") return;
                                    let newLocations = [...locations];
                                    newLocations[locationIndex].phoneNumber = value;
                                    dispatchContext(
                                        guideOrgChangeFormState({
                                            locations: newLocations,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                ) : view === "hours" ? (
                    <GridTime
                        enabledWeekdays={weekdays}
                        businessHours={selectedLocation?.businessHours || []}
                        onChangeTime={({ name, weekday, value }) => {
                            if (!selectedLocation) return;
                            let location = { ...selectedLocation };

                            location.businessHours.map((item) => {
                                if (weekday === item.day) {
                                    if (name === "start") {
                                        item.start = value;
                                    } else if (name === "end") {
                                        item.end = value;
                                    }
                                }
                                return item;
                            });

                            dispatchContext(
                                guideOrgChangeFormState({
                                    locations: locations.map((loc, index) => {
                                        if (index === locationIndex) {
                                            return location;
                                        }
                                        return loc;
                                    }),
                                })
                            );
                        }}
                        onEnableWeekday={(checked, weekday) => {
                            onEnableWeekday(checked, weekday);
                        }}
                    />
                ) : view === "list" ? (
                    <div className={styles.locations}>
                        <InputSearchPlaces onSelectPlace={(place) => onSelectLocation(place)} />

                        {locations.length ? (
                            <div className={styles.locations__group}>
                                <h6>Locations</h6>

                                <ul>
                                    {locations.map((loc, index) => (
                                        <li key={index}>
                                            <span>{loc.name}</span>{" "}
                                            <Button
                                                onClick={() => {
                                                    dispatchContext(
                                                        guideOrgChangeContextState({
                                                            locationIndex: index,
                                                        })
                                                    );
                                                    setView("create");
                                                }}
                                                outline
                                                padding={false}
                                                variant="primary">
                                                Edit
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
    user: AuthReducer.user as User,
    org: OrgReducer.org as Organization,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(CreateLocation);

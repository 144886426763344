import { AllEffect, call, CallEffect, delay, put, PutEffect, race, RaceEffect, select, SelectEffect, take, TakeEffect, takeLatest } from "@redux-saga/core/effects";
import { types } from "../reducer/types";
import { DomainActions } from "../reducer/actions.types";
import domainClass from "./domain.class";
import { BillingsInvoice } from "../../../billings";
import { PaymentMethodResult, SetupIntent } from "@stripe/stripe-js";
import history from "../../../../../app/routes/history";
import { RootState } from "../../../../../redux/reducers";
import { toastHelper, Validation } from "@soltivo/draw-a-line";
import billingsClass from "../../billings/saga/billings.class";
import * as domainActions from "../reducer/actions";
import domainValidation from "../../../validation/domain/domain.validation";
import { ErrorResponse } from "../../../../../helpers/api.v2";

/**
 * @description get organization domain info.
 */
function* domainGetDomainInfo(_action: DomainActions["domainGetDomainInfo"]): Generator<CallEffect | PutEffect, void, any> {
    try {
        const { data } = yield call(domainClass.domainGetDomainInfo);
        yield put({
            type: types.DOMAIN_GET_DOMAIN_INFO_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.DOMAIN_GET_DOMAIN_INFO_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description check the availability of domain.
 */
function* domainCheckAvailability({ payload }: DomainActions["domainCheckAvailability"]): Generator<TakeEffect | CallEffect | PutEffect, void, any> {
    try {
        yield delay(1000);
        domainValidation.domainCheckAvailability(payload);

        yield call(domainClass.domainCheckAvailability, payload);

        domainValidation.validation.set([{ message: "Domain is available.", fieldName: "domainName", formName: domainValidation.formTypes.DOMAIN_CREATE_DOMAIN, status: "success" }]);
        yield put({
            type: types.DOMAIN_CHECK_AVAILABILITY_SUCCESS,
            payload: {
                status: "available",
                domainName: payload.domainName,
            },
        });
    } catch (error) {
        if (error instanceof ErrorResponse) {
            domainValidation.validation.set([{ message: "Domain is not available.", formName: domainValidation.formTypes.DOMAIN_CREATE_DOMAIN, fieldName: "domainName", status: "failed" }]);

            yield put({
                type: types.DOMAIN_CHECK_AVAILABILITY_FAILURE,
                payload: {
                    status: "unavailable",
                    domainName: payload.domainName,
                    error: error?.error.message,
                },
            });
        } else {
            yield put({
                type: types.DOMAIN_CHECK_AVAILABILITY_FAILURE,
                payload: {
                    status: undefined,
                    domainName: payload.domainName,
                    error: "Something went wrong.",
                },
            });
        }
    }
}

/**
 *
 * @description create invoice for new domain name.
 */
function* domainSubscribeDomain({
    payload,
}: DomainActions["domainSubscribeDomain"]): Generator<SelectEffect | TakeEffect | CallEffect | CallEffect<Promise<PaymentMethodResult>> | RaceEffect<any> | PutEffect, void, any> {
    try {
        const { domainName, purchaseInfo, paymentMethodId, attachPaymentMethod } = payload;

        const mapStateToProps = ({ DomainReducer }: RootState) => ({
            domainInfo: DomainReducer.domainInfo,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        const { domainInfo }: MapStateToProps = yield select(mapStateToProps);

        domainValidation.domainSubscribeDomain(payload, domainInfo);

        if (!paymentMethodId) {
            throw new Error("Payment method is missing.");
        }

        //attach payment method.
        if (attachPaymentMethod && paymentMethodId) {
            yield call(billingsClass.billingsAttachPaymentMethod, {
                paymentMethod: paymentMethodId,
            });
        }

        const { data }: { data: SetupIntent } = yield call(billingsClass.billingsSetupIntent, {
            domainName,
            paymentMethodId: paymentMethodId,
        });

        if (data) {
            //Confirm payment intent
            yield call(billingsClass.billingsSetupIntentConfirm, { setupIntentId: data.id });
        }

        // create invoice for subscription
        const { data: invoice }: { data: BillingsInvoice } = yield call(domainClass.domainSubscribeDomain, {
            domainName: domainName,
            purchaseInfo: purchaseInfo,
            paymentMethodId: paymentMethodId,
        });

        yield put({
            type: types.DOMAIN_SUBSCRIBE_DOMAIN_SUCCESS,
            payload: {
                status: "success",
                invoice: invoice,
            },
        });

        history.push({
            search: `step=confirm`,
        });
    } catch (error: any) {
        yield put({
            type: types.DOMAIN_SUBSCRIBE_DOMAIN_FAILURE,
            payload: {
                status: "failure",
            },
        });

        if (error instanceof Validation) {
            yield put({
                type: types.DOMAIN_SUBSCRIBE_DOMAIN_FAILURE,
                payload: {
                    status: "failure",
                },
            });
        }
        if (error instanceof Error) {
            toastHelper.toastStartContent("danger", error.message);
        } else {
            toastHelper.toastStartContent("danger", error?.message || "Something bad happened.");
        }

        history.replace({
            pathname: "/settings/register-domain",
        });
    }
}

/**
 * @description confirm domain subscription
 */
function* domainSubscribeDomainConfirm({
    payload,
}: DomainActions["domainSubscribeDomainConfirm"]): Generator<
    SelectEffect | AllEffect<any> | TakeEffect | CallEffect | CallEffect<Promise<PaymentMethodResult>> | RaceEffect<any> | PutEffect,
    void,
    any
> {
    try {
        const { invoice } = payload;

        yield call(domainClass.domainSubscribeDomainConfirm, {
            invoice: invoice,
        });

        // show successful view.
        history.push({
            search: "step=done",
        });

        yield delay(3000);

        // // refresh domain info.
        yield put(domainActions.domainGetDomainInfo());

        yield race({
            success: take(types.DOMAIN_GET_DOMAIN_INFO_SUCCESS),
            failure: take(types.DOMAIN_GET_DOMAIN_INFO_FAILURE),
        });

        yield put({
            type: types.DOMAIN_SUBSCRIBE_CONFIRM_SUCCESS,
        });

        //move use to main email route.
        history.push("/collaboration/email/configuration/mailbox");
    } catch (error) {
        if (error instanceof ErrorResponse) {
            domainValidation.validation.set([
                {
                    message: error.error.errors[0].message,
                    fieldName: "global",
                    formName: domainValidation.formTypes.DOMAIN_CREATE_DOMAIN,
                    status: "failed",
                },
            ]);
            yield put({
                type: types.DOMAIN_SUBSCRIBE_CONFIRM_FAILURE,
            });
        } else {
            yield put({
                type: types.DOMAIN_SUBSCRIBE_CONFIRM_FAILURE,
            });
        }
    }
}

/**
 *
 * @description turn on/off domain renew flag
 */
function* domainRenewFlag({ payload }: DomainActions["domainRenewFlag"]): Generator<CallEffect | PutEffect, void, any> {
    try {
        yield call(domainClass.domainRenewFlag, payload);
        yield put({ type: types.DOMAIN_RENEW_FLAG_SUCCESS, payload: payload.renewable });
    } catch (error) {
        yield put({ type: types.DOMAIN_RENEW_FLAG_FAILURE, payload: error });
    }
}

/**
 *
 * @description update domain name informations
 */
function* domainUpdateInfos({ payload }: DomainActions["domainUpdateInfos"]): Generator<CallEffect | PutEffect, void, any> {
    const { autoSaveAPI, fieldName } = payload.autoSave;
    try {
        autoSaveAPI.updateByAttributeName(fieldName, {
            loading: true,
            message: "Saving",
            status: {
                type: "pending",
            },
        });
        yield delay(1500);
        const { data } = yield call(domainClass.domainUpdateInfos, payload);
        yield put({ type: types.DOMAIN_UPDATE_INFOS_SUCCESS, payload: data });
        autoSaveAPI.updatePending({
            loading: false,
            message: "Saved successufully.",
            status: {
                type: "success",
            },
        });
    } catch (error: any) {
        if (error instanceof ErrorResponse) {
            autoSaveAPI.updateByAttributeName(fieldName, {
                loading: false,
                message: error.error.errors.length ? `${error.error.errors[0].field} ${error.error.errors[0].message}` : error.error.message || error.message,
                status: {
                    type: "failed",
                },
            });
        }
        yield put({ type: types.DOMAIN_UPDATE_INFOS_FAILURE, payload: error });
    }
}

export default function* DomainSaga() {
    yield takeLatest(types.DOMAIN_GET_DOMAIN_INFO_REQUEST, domainGetDomainInfo);
    yield takeLatest(types.DOMAIN_CHECK_AVAILABILITY_REQUEST, domainCheckAvailability);
    yield takeLatest(types.DOMAIN_SUBSCRIBE_DOMAIN_REQUEST, domainSubscribeDomain);
    yield takeLatest(types.DOMAIN_SUBSCRIBE_CONFIRM_REQUEST, domainSubscribeDomainConfirm);
    yield takeLatest(types.DOMAIN_RENEW_FLAG_REQUEST, domainRenewFlag);
    yield takeLatest(types.DOMAIN_UPDATE_INFOS_REQUEST, domainUpdateInfos);
}

import { call, put, takeEvery, takeLatest, select, delay } from "redux-saga/effects";
import { types } from "../reducer/types";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import popoutHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import serviceClass from "./service.class";
import salesClass from "../../../sales/redux/saga/sales.class";
import { IServicesLastSixMonthsEE } from "../../service";
import { ServiceCategory, Service } from "@soltivo/types/types/applications/services/index";
import apiV2, { ValidationError } from "../../../../helpers/api.v2";
import { sagaDisplayError } from "./saga.helper";
import history from "../../../../app/routes/history";
// import bookingClass from "./booking.class";
import { ServicesActionsTypes } from "../reducer/actions.types";
import moment from "moment";
import { popupHelper } from "@soltivo/draw-a-line";
import { CategoryItemType } from "../reducer/reducer";

/**
 * @description get suggestions for name service category.
 */
function* serviceGetCategorySuggestions() {
    try {
        const { data } = yield call(serviceClass.serviceGetCategorySuggestions);
        yield put({ type: types.SERVICE_GET_CATEGORY_SUGGESTIONS_SUCCESS, payload: data.categories || [] });
    } catch (error: any) {
        yield put({ type: types.SERVICE_GET_CATEGORY_SUGGESTIONS_FAILURE, payload: [] });
    }
}

/**
 * @description get service categories of services.
 */
function* serviceGetCategories() {
    try {
        const { data } = yield call(serviceClass.serviceGetCategories);
        yield put({ type: types.SERVICE_GET_CATEGORIES_SUCCESS, payload: data });
    } catch (error: any) {
        if (error?.error) {
            if (error.error.code === 404) {
                yield put({ type: types.SERVICE_GET_CATEGORIES_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.SERVICE_GET_CATEGORIES_FAILURE, payload: error });
    }
}

/**
 * @description search service category of services
 */
function* serviceSearchCategory({ payload }: { payload: { name: string }; type: string }) {
    try {
        yield delay(1000);
        const { name } = payload;
        const { data } = yield call(serviceClass.serviceSearchCategory, payload);
        const { searching } = yield select(({ ServiceReducer }) => ({
            categories: ServiceReducer.categories,
            searching: ServiceReducer.searching,
        }));

        if (name.length) {
            yield put({ type: types.SERVICE_SEARCH_CATEGORY_SUCCESS, payload: data });
        } else {
            if (searching) {
                yield put({ type: types.SERVICE_SEARCH_CATEGORY_CLOSE });
            }
        }
    } catch (error: any) {
        if (error.code === 404) {
            yield put({ type: types.SERVICE_SEARCH_CATEGORY_SUCCESS, payload: [] });
            return;
        }
        yield put({ type: types.SERVICE_SEARCH_CATEGORY_FAILURE, payload: error });
    }
}

/**
 * @description get service category by id.
 */
function* serviceGetCategory({ payload }: { payload: { categoryId: string }; type: string }) {
    try {
        if (!payload.categoryId) throw new Error("Category id not defined.");
        const { data } = yield call(serviceClass.serviceGetCategory, payload);
        yield put({
            type: types.SERVICE_GET_CATEGORY_SUCCESS,
            payload: {
                ...data,
                path: window.location.pathname,
            },
        });
    } catch (error) {
        yield put({ type: types.SERVICE_GET_CATEGORY_FAILURE, payload: error });
    }
}

/**
 * @description create category of services.
 */
function* serviceCreateCategory({
    payload,
}: {
    payload: {
        name: string;
        description: string;
    };
    type: string;
}) {
    try {
        if (!payload.name) {
            throw new ValidationError({ message: `Service category requires a name.` });
        } else if (payload.name.length < 3) {
            throw new ValidationError({ message: `Service category name length should have at least 3 characters.` });
        }

        const { data } = yield call(serviceClass.serviceCreateCategory, payload);

        yield put({ type: types.SERVICE_SEARCH_CATEGORY_CLOSE });
        yield put({ type: types.SERVICE_CREATE_CATEGORY_SUCCESS, payload: data });
        yield put({ type: types.SERVICE_GET_CATEGORIES_REQUEST });
        toastHelper.toastStartContent("success", "Category created successfully");
        popoutHelper.popupEnd();
    } catch (error) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent(error.flag || "danger", error.message);
        }

        yield put({ type: types.SERVICE_CREATE_CATEGORY_FAILURE, payload: error });
    }
}

/**
 * @description update category of services.
 */
function* serviceUpdateCategory({
    payload,
}: {
    payload: {
        categoryId: string;
        name: string;
        description: string;
    };
    type: string;
}) {
    try {
        const { data } = yield call(serviceClass.serviceUpdateCategory, payload);
        let { categories, searchedCategories } = yield select(({ ServiceReducer }) => ({
            categories: ServiceReducer.categories,
            searchedCategories: ServiceReducer.searchedCategories,
        }));

        categories = categories.map((category: ServiceCategory) => {
            if (category.id === data.id) {
                return {
                    ...category,
                    ...data,
                };
            } else {
                return category;
            }
        });

        searchedCategories = searchedCategories.map((category: ServiceCategory) => {
            if (category.id === data.id) {
                return {
                    ...category,
                    ...data,
                };
            } else {
                return category;
            }
        });

        yield put({
            type: types.SERVICE_UPDATE_CATEGORY_SUCCESS,
            payload: {
                categories,
                searchedCategories,
            },
        });
        toastHelper.toastStartContent("success", "Category updated successfully");
        popoutHelper.popupEnd();
    } catch (error) {
        yield put({ type: types.SERVICE_UPDATE_CATEGORY_FAILURE, payload: error });
        sagaDisplayError(error, [422, 409]);
    }
}

/**
 * @description delete service category by id.
 */
function* serviceDeleteCategory({ payload }: { payload: { categoryId: string }; type: string }) {
    try {
        yield call(serviceClass.serviceDeleteCategory, payload);

        let { categories } = yield select(({ ServiceReducer }) => ({
            categories: ServiceReducer.categories,
        }));

        categories = categories.filter((category: ServiceCategory) => {
            return category.id !== payload.categoryId;
        });

        yield put({
            type: types.SERVICE_DELETE_CATEGORY_SUCCESS,
            payload: categories,
        });
        toastHelper.toastStartContent("success", "Category deleted successfully");
        history.push("/administration/services/categories");
        popoutHelper.popupEnd();
    } catch (error: any) {
        popoutHelper.popupEnd();
        if (!error.error) toastHelper.toastStartContent("danger", error.message);
        else toastHelper.toastStartContent("danger", apiV2.stringifyErrors(error, 404 || 422) || "");
        yield put({ type: types.SERVICE_DELETE_CATEGORY_FAILURE, payload: error });
    }
}

/**
 * @description get services of a category.
 */
function* serviceGetServices({ payload }: { payload: { categoryId: string }; type: string }) {
    try {
        if (!payload.categoryId) throw new Error("Failed to get category id.");
        const { data } = yield call(serviceClass.serviceGetServices, payload);
        if (data) {
            const services = data.map((service: Service) => {
                /* @ts-ignore */
                service.serviceId = service?.serviceId || service?.id;
                return service;
            });
            yield put({ type: types.SERVICE_GET_SERVICES_SUCCESS, payload: services });
        }
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.SERVICE_GET_SERVICES_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.SERVICE_GET_SERVICES_FAILURE, payload: error });
    }
}

/**
 * @description get all services.
 */
function* serviceGetAllServices() {
    try {
        const { data } = yield call(serviceClass.serviceGetAllServices);
        if (data) {
            const services = data.map((service: Service) => {
                /* @ts-ignore */
                service.serviceId = service?.serviceId || service?.id;
                return service;
            });
            yield put({ type: types.SERVICE_GET_ALL_SERVICES_SUCCESS, payload: services });
        }
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.SERVICE_GET_ALL_SERVICES_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.SERVICE_GET_ALL_SERVICES_FAILURE, payload: error });
    }
}

/**
 * @description get service by id
 */
function* serviceGetService({ payload }: { payload: { serviceId: string }; type: string }) {
    try {
        const { data } = yield call(serviceClass.serviceGetService, payload);
        if (data) {
            yield put({
                type: types.SERVICE_GET_SERVICE_SUCCESS,
                payload: {
                    ...data,
                    serviceId: data?.serviceId || data?.id,
                    path: window.location.pathname,
                },
            });
        }
    } catch (error) {
        yield put({ type: types.SERVICE_GET_SERVICE_FAILURE, payload: error });
    }
}

/**
 * @description validation of service form
 */
function validateServiceForm(payload: { image?: File | undefined; title: string; category: string; price: number; description?: string; bufferTime: number; duration: number }) {
    // Service image, description, equipments and locations are optional
    const required = Object.keys(payload)
        .filter((item) => !["locations", "equipments", "description", "image"].includes(item))
        .filter((item) => {
            return !(payload as { [index: string]: any })[item]?.toString().length;
        });

    if (required.length) {
        throw new Error(`Some fields are required, missing fields: ${required.toString().replace(/,/g, ", ")}.`);
    } else if (payload.image?.type && payload.image?.name) {
        if (!/(jpeg|jpg|png)/g.test(`${payload.image?.type.toLowerCase()}`)) {
            throw new Error(`Service image type must be jpeg, jpg or png.`);
        }
    }
}

/**
 * @description create service.
 */
function* serviceCreateService({ payload }: ServicesActionsTypes["serviceCreateService"]) {
    try {
        validateServiceForm(payload as any);

        let dataPayload = { ...payload };
        const { categories } = yield select(({ ServiceReducer }) => ({
            categories: ServiceReducer.categories,
        }));

        const found = categories.find((category: ServiceCategory) => category.id?.toLowerCase() === dataPayload.category?.toLowerCase());
        if (!found) throw new Error("You must select a category.");

        //@ts-ignore
        dataPayload["category"] = found.id;
        dataPayload["price"] = Number(dataPayload["price"]);

        if (typeof dataPayload.image !== "string" && dataPayload.image) {
            const { data: assignedUrl } = yield call(serviceClass.serviceUploadServiceImage, { file: dataPayload.image });
            const { fileLink } = yield call(apiV2.postAssignedUrl, assignedUrl, dataPayload.image);
            dataPayload.image = fileLink;
        }

        const { data } = yield call(serviceClass.serviceCreateService, dataPayload);

        yield put({ type: types.SERVICE_CREATE_SERVICE_SUCCESS, payload: { ...data, serviceId: data.id || data.serviceId } });

        yield put({
            type: types.SERVICE_GET_SERVICES_REQUEST,
            payload: {
                categoryId: found.id,
            },
        });

        toastHelper.toastStartContent("success", "Service created successfully");
        history.push(`/administration/services/categories/${found.id}/${data.id}`);
        popoutHelper.popupEnd();
    } catch (error) {
        yield put({ type: types.SERVICE_CREATE_SERVICE_FAILURE, payload: error });
        sagaDisplayError(error, [422, 409]);
    }
}

/**
 * @description update a service.
 */
function* serviceUpdateService({ payload }: ServicesActionsTypes["serviceUpdateService"]) {
    try {
        validateServiceForm(payload as any);
        let dataPayload = { ...payload.service };
        const { categories } = yield select(({ ServiceReducer }) => ({
            categories: ServiceReducer.categories,
        }));

        const found = categories.find((category: ServiceCategory) => category.name?.toLowerCase() === dataPayload.category?.toLowerCase());
        if (!found) throw new Error("You must select a category.");

        //@ts-ignore
        dataPayload["category"] = found.id;
        dataPayload["price"] = Number(dataPayload["price"]);

        if (typeof dataPayload.image !== "string" && dataPayload.image) {
            const { data: assignedUrl } = yield call(serviceClass.serviceUploadServiceImage, { file: dataPayload.image });
            const { fileLink } = yield call(apiV2.postAssignedUrl, assignedUrl, dataPayload.image);
            dataPayload.image = fileLink;
        }

        const { data } = yield call(serviceClass.serviceUpdateService, { ...payload, service: dataPayload });

        yield put({ type: types.SERVICE_UPDATE_SERVICE_SUCCESS, payload: { ...data, serviceId: data.id || data.serviceId } });
        
        toastHelper.toastStartContent("success", "Service updated successfully.");
        popupHelper.popupEnd();
        yield put({ type: types.SERVICE_GET_CATEGORIES_REQUEST });
    } catch (error) {
        yield put({ type: types.SERVICE_CREATE_SERVICE_FAILURE, payload: error });
        sagaDisplayError(error, [422, 409]);
    }
}

/**
 * @description delete service by id
 */
function* serviceDeleteService({ payload }: { payload: { serviceId: string; categoryId: string }; type: string }) {
    try {
        yield call(serviceClass.serviceDeleteService, payload);

        let { categories } : { categories: CategoryItemType[] } = yield select(({ ServiceReducer }) => ({
            categories: ServiceReducer.categories,
        }));

        // remove the service from the list
        categories = categories.map(cat => {
            if (cat.id === payload.categoryId) {
                cat.services = cat.services.filter((service: CategoryItemType["services"][0]) => {
                    return service.id !== payload.serviceId;
                });
            }
            return cat;
        });

        yield put({
            type: types.SERVICE_DELETE_SERVICE_SUCCESS,
            payload: { categories },
        });

        toastHelper.toastStartContent("success", "Service deleted successfully");
        popoutHelper.popupEnd();
        history.push(`/administration/services/categories`);
    } catch (error) {
        yield put({ type: types.SERVICE_DELETE_SERVICE_FAILURE, payload: error });
        sagaDisplayError(error, [404]);
    }
}

function* serviceGetEarningsMetrics({ payload }: { payload: { date: string }; type: string }) {
    try {
        let { data } = yield call(serviceClass.serviceGetEarningsMetrics, payload);
        let { data: salesEarnings } = yield call(salesClass.salesGetEarningsMetrics, payload);

        // Get weekly and monthly income
        const year = new Date().getFullYear();
        let month = `${new Date().getMonth() + 1}`;
        if (month.length === 1) month = `0${month}`;
        const startDate = moment().startOf("week").format("YYYY-MM-DD");
        const endDate = moment().endOf("week").format("YYYY-MM-DD");
        let { data: salesWeeklyEarnings } = yield call(salesClass.salesGetWeeklyIncome, { startDate, endDate });
        let { data: salesMonthlyEarnings } = yield call(salesClass.salesGetMonthlyIncome, { month, year: `${year}` });

        let expectedVsEarned: IServicesLastSixMonthsEE[] = salesEarnings.map((m: any) => ({
            month: m.month,
            actual: m.sales,
            expected: m.bookings,
        }));
        const salesMonthlyEarningsAmount = salesMonthlyEarnings.reduce(( sum: number, { income } : { income: number }) => sum + income , 0);
        const salesWeeklyEarningsAmount = salesWeeklyEarnings.reduce(( sum: number, { income } : { income: number }) => sum + income , 0);
        
        yield put({
            type: types.SERVICE_GET_EARNINGS_METRICS_SUCCESS,
            payload: {
                lastSixMonthsEE: expectedVsEarned,
                expectedTodayEarnings: data.expectedTodayEarnings,
                currentWeekEarnings: { amount: salesWeeklyEarningsAmount },
                currentMonthEarnings: { amount: salesMonthlyEarningsAmount },
            },
        });
    } catch (error) {
        yield put({
            type: types.SERVICE_GET_EARNINGS_METRICS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Get unsplash images
 */
function* serviceGetUnsplashImages({ payload }: { payload: { query: string; page: number }; type: string }) {
    try {
        yield delay(2000);
        const { results, total_pages } = yield call(serviceClass.serviceGetUnsplashImages, payload);

        yield put({
            type: types.SERVICE_GET_UNSPLASH_IMAGES_SUCCESS,
            payload: {
                results,
                page: payload.page,
                totalPages: total_pages,
            },
        });
    } catch (error) {
        yield put({
            type: types.SERVICE_GET_UNSPLASH_IMAGES_FAILURE,
            payload: error,
        });
    }
}

export default function* ServiceSaga() {
    yield takeLatest(types.SERVICE_GET_CATEGORY_SUGGESTIONS_REQUEST, serviceGetCategorySuggestions);
    yield takeEvery(types.SERVICE_GET_CATEGORIES_REQUEST, serviceGetCategories);
    yield takeEvery(types.SERVICE_GET_CATEGORY_REQUEST, serviceGetCategory);
    yield takeLatest(types.SERVICE_SEARCH_CATEGORY_REQUEST, serviceSearchCategory);
    yield takeLatest(types.SERVICE_CREATE_CATEGORY_REQUEST, serviceCreateCategory);
    yield takeLatest(types.SERVICE_UPDATE_CATEGORY_REQUEST, serviceUpdateCategory);
    yield takeLatest(types.SERVICE_DELETE_CATEGORY_REQUEST, serviceDeleteCategory);

    yield takeEvery(types.SERVICE_GET_SERVICES_REQUEST, serviceGetServices);
    yield takeEvery(types.SERVICE_GET_SERVICE_REQUEST, serviceGetService);
    yield takeEvery(types.SERVICE_GET_ALL_SERVICES_REQUEST, serviceGetAllServices);
    yield takeLatest(types.SERVICE_DELETE_SERVICE_REQUEST, serviceDeleteService);
    yield takeLatest(types.SERVICE_CREATE_SERVICE_REQUEST, serviceCreateService);
    yield takeLatest(types.SERVICE_UPDATE_SERVICE_REQUEST, serviceUpdateService);
    yield takeLatest(types.SERVICE_GET_EARNINGS_METRICS_REQUEST, serviceGetEarningsMetrics);
    yield takeLatest(types.SERVICE_GET_UNSPLASH_IMAGES_REQUEST, serviceGetUnsplashImages);
}

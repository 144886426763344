import { types } from "./types";
import { Equipment, Product } from "@soltivo/types";

interface InventoryState {
    error: any;
    equipments: Array<Equipment>;
    searchResults: Array<any>;
    equipmentsSearchResults: Array<Equipment>;
    equipment?: Equipment;
    loadingEquipments: boolean;
    creatingEquipment: boolean;
    deletingEquipment: boolean;
    updatingEquipment: boolean;
    loadingEquipment: boolean;
    archivingEquipment: boolean;
    searchingEquipments: boolean;
    searchingProducts: boolean;
    loadingProducts: boolean;
    products: Product[];
    product?: Product;
    creatingProduct: boolean;
    updatingProduct: boolean;
    loadingProduct: boolean;
    deletingProduct: boolean;
    archivingProduct: boolean;
}

const _INITIAL_STATE: InventoryState = {
    error: null,
    equipments: [],
    searchResults: [],
    equipmentsSearchResults: [],
    equipment: undefined,
    loadingEquipments: true,
    creatingEquipment: false,
    deletingEquipment: false,
    updatingEquipment: false,
    loadingEquipment: false,
    archivingEquipment: false,
    searchingEquipments: false,
    searchingProducts: false,
    loadingProducts: false,
    products: [],
    product: undefined,
    creatingProduct: false,
    updatingProduct: false,
    loadingProduct: true,
    deletingProduct: false,
    archivingProduct: false,
};

const InventoryReducer = (state = _INITIAL_STATE, action: { type: string; payload: any }): InventoryState => {
    switch (action.type) {
        case types.INVENTORY_RESET_ITEM:
            return {
                ...state,
                equipment: undefined
            };

        case types.INVENTORY_CHANGE_STATE:
            return {
                ...state,
                ...action.payload
            };

        case types.INVENTORY_GET_EQUIPMENTS_REQUEST:
            return {
                ...state,
                loadingEquipments: true
            };
            
        case types.INVENTORY_GET_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                loadingEquipments: false,
                equipments: action.payload,
                error: null
            };

        case types.INVENTORY_GET_EQUIPMENTS_FAILURE:
            return {
                ...state,
                loadingEquipments: false,
                equipments: [],
                error: action.payload
            };

        case types.INVENTORY_GET_EQUIPMENT_REQUEST:
            return {
                ...state,
                loadingEquipment: true
            };
            
        case types.INVENTORY_GET_EQUIPMENT_SUCCESS:
            return {
                ...state,
                loadingEquipment: false,
                equipment: action.payload,
                error: null
            };

        case types.INVENTORY_GET_EQUIPMENT_FAILURE:
            return {
                ...state,
                loadingEquipment: false,
                error: action.payload
            };

        case types.INVENTORY_CREATE_EQUIPMENT_REQUEST:
            return {
                ...state,
                creatingEquipment: true,
            };

        case types.INVENTORY_CREATE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                creatingEquipment: false,
                error: null
            };

        case types.INVENTORY_CREATE_EQUIPMENT_FAILURE:
            return {
                ...state,
                creatingEquipment: false,
                error: action.payload
            };

        case types.INVENTORY_UPDATE_EQUIPMENT_REQUEST:
            return {
                ...state,
                updatingEquipment: true,
            };

        case types.INVENTORY_UPDATE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                updatingEquipment: false,
                equipment: action.payload,
            };

        case types.INVENTORY_UPDATE_EQUIPMENT_FAILURE:
            return {
                ...state,
                updatingEquipment: false,
            };

        case types.INVENTORY_AUTO_UPDATE_EQUIPMENT_REQUEST:
            return {
                ...state,
                updatingEquipment: true,
            };

        case types.INVENTORY_AUTO_UPDATE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                updatingEquipment: false,
                equipment: action.payload,
            };

        case types.INVENTORY_AUTO_UPDATE_EQUIPMENT_FAILURE:
            return {
                ...state,
                updatingEquipment: false,
            };

        case types.INVENTORY_DELETE_EQUIPMENT_REQUEST:
            return {
                ...state,
                deletingEquipment: true,
            };

        case types.INVENTORY_DELETE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                deletingEquipment: false,
            };
        case types.INVENTORY_DELETE_EQUIPMENT_FAILURE:
            return {
                ...state,
                deletingEquipment: false,
            };

        case types.INVENTORY_ARCHIVE_EQUIPMENT_REQUEST:
            return {
                ...state,
                archivingEquipment: true,
            };

        case types.INVENTORY_ARCHIVE_EQUIPMENT_SUCCESS:
            return {
                ...state,
                archivingEquipment: false,
                equipment: state.equipment ? {
                    ...state.equipment,
                    isArchived: action.payload.isArchived
                } : undefined
            };
        case types.INVENTORY_ARCHIVE_EQUIPMENT_FAILURE:
            return {
                ...state,
                archivingEquipment: false,
            };

        case types.INVENTORY_SEARCH_EQUIPMENTS_REQUEST:
            return {
                ...state,
                searchingEquipments: true,
                equipmentsSearchResults: []
            };

        case types.INVENTORY_SEARCH_EQUIPMENTS_SUCCESS:
            return {
                ...state,
                searchingEquipments: false,
                equipmentsSearchResults: action.payload
            };

        case types.INVENTORY_SEARCH_EQUIPMENTS_FAILURE:
            return {
                ...state,
                searchingEquipments: false,
            };

        case types.INVENTORY_SEARCH_PRODUCT_REQUEST:
            return {
                ...state,
                searchingProducts: true,
            };

        case types.INVENTORY_SEARCH_PRODUCT_SUCCESS:
            return {
                ...state,
                searchingProducts: false,
                searchResults: action.payload
            };

        case types.INVENTORY_SEARCH_PRODUCT_FAILURE:
            return {
                ...state,
                searchingProducts: false,
            };

        case types.INVENTORY_GET_PRODUCTS_REQUEST:
            return {
                ...state,
                loadingProducts: true,
            };
        case types.INVENTORY_GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                loadingProducts: false,
                products: action.payload,
            };
        case types.INVENTORY_GET_PRODUCTS_FAILURE:
            return {
                ...state,
                loadingProducts: false,
                error: action.payload
            };

        case types.INVENTORY_CREATE_PRODUCT_REQUEST:
            return {
                ...state,
                creatingProduct: true,
            };
        case types.INVENTORY_CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                creatingProduct: false,
            };
        case types.INVENTORY_CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                creatingProduct: false,
            };

        case types.INVENTORY_UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                updatingProduct: true,
            };
        case types.INVENTORY_UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                updatingProduct: false,
                product: {
                    ...state.product,
                    ...action.payload
                }
            };
        case types.INVENTORY_UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                updatingProduct: false,
            };

        case types.INVENTORY_GET_PRODUCT_REQUEST:
            return {
                ...state,
                loadingProduct: true,
            };
        case types.INVENTORY_GET_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingProduct: false,
                product: action.payload,
            };
        case types.INVENTORY_GET_PRODUCT_FAILURE:
            return {
                ...state,
                loadingProduct: false,
            };

        case types.INVENTORY_DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                deletingProduct: true,
            };
        case types.INVENTORY_DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                deletingProduct: false,
            };
        case types.INVENTORY_DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                deletingProduct: false,
            };

        case types.INVENTORY_ARCHIVE_PRODUCT_REQUEST:
            return {
                ...state,
                archivingProduct: true,
            };
        case types.INVENTORY_ARCHIVE_PRODUCT_SUCCESS:
            return {
                ...state,
                archivingProduct: false,
            };
        case types.INVENTORY_ARCHIVE_PRODUCT_FAILURE:
            return {
                ...state,
                archivingProduct: false,
            };

        default:
            return state;
    }
};

export default InventoryReducer;

import { types } from "./types";
import { NotificationSettings } from "@soltivo/types";
import { NotificationsState } from "./reducer";

/**
 * @description change global state in notifications
 */
 export const notificationsChangeState = (payload: Partial<NotificationsState>) => ({
    type: types.NOTIFICATIONS_CHANGE_STATE,
    payload,
});

/**
 * @description list all notifications
 */
export const notificationsGetAllNotifications = () => ({
    type: types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_REQUEST,
});

/**
 * @description update notification
 */
export const notificationsUpdateNotification = (payload: { id: string, isRead: boolean }) => ({
    type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_REQUEST,
    payload,
});

/**
 * @description get notifications settings
 */
export const notificationsGetSettings = () => ({
    type: types.NOTIFICATIONS_GET_SETTINGS_REQUEST,
});

/**
 * @description update notifications settings
 */
export const notificationsUpdateSettings = (payload: { settings: NotificationSettings }) => ({
    type: types.NOTIFICATIONS_UPDATE_SETTINGS_REQUEST,
    payload,
});

/**
 * @description delete notifications
 */
export const notificationsDeleteNotifications = (payload: { id: string }) => ({
    type: types.NOTIFICATIONS_DELETE_NOTIFICATION_REQUEST,
    payload,
});

/**
 * @description clear all notifications
 */
 export const notificationsClearNotifications = (payload: { ids: string[] }) => ({
    type: types.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_REQUEST,
    payload,
});
import apiV2 from "../../../../helpers/api.v2";
import SalesActions from "../reducer/actions.types";

var _SALES_URL = `${process.env.REACT_APP_API_URL}/sales`;

/**
 * @description sales API calls.
 */
class salesClass {
    salesGetTransactions() {
        return apiV2.getRequest(`${_SALES_URL}/transactions`, {
            useToken: "accessToken",
        });
    }
    salesGetPayments() {
        return apiV2.getRequest(`${_SALES_URL}/payments`, {
            useToken: "accessToken",
        });
    }

    salesGetTransaction(payload: SalesActions["salesGetTransaction"]["payload"]) {
        return apiV2.getRequest(`${_SALES_URL}/payments/${payload.transactionId}`, {
            useToken: "accessToken",
        });
    }

    salesUpdateInvoiceNote(payload: SalesActions["salesUpdateInvoiceNote"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/invoices/${payload.transactionId}/note`, {
            data: JSON.stringify({ note: payload.note }),
            useToken: "accessToken",
        });
    }

    /**
     * @description get earnings  from the last 6 months.
     */
    salesGetEarningsMetrics(payload: { date: string }) {
        return apiV2.getRequest(`${_SALES_URL}/invoices/metrics/semi-annual/${payload.date}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get unpaid invoice metrics
     */
    salesGetUnpaidMetrics(payload: { startDate: string; endDate: string }) {
        return apiV2.getRequest(`${_SALES_URL}/invoices/metrics/unpaid/${payload.startDate}/${payload.endDate}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get transactions and refund metrics
     */
    salesGetTransactionsMetrics(payload: { startDate: string; endDate: string }) {
        return apiV2.getRequest(`${_SALES_URL}/transactions/metrics/${payload.startDate}/${payload.endDate}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get monthly income
     */
     salesGetMonthlyIncome(payload: SalesActions["salesGetMonthlyIncome"]["payload"]) {
        return apiV2.getRequest(`${_SALES_URL}/income/monthly/${payload.month}/${payload.year}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get weekly income
     */
     salesGetWeeklyIncome(payload: SalesActions["salesGetWeeklyIncome"]["payload"]) {
        return apiV2.getRequest(`${_SALES_URL}/income/weekly/${payload.startDate}/${payload.endDate}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description Verify Stripe Connect.
     */
    salesVerifyStripeConnect() {
        return apiV2.getRequest(`${_SALES_URL}/stripe/verify`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description Link Stripe Connect
     */
    salesLinkStripeConnect() {
        return apiV2.getRequest(`${_SALES_URL}/stripe/link`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description Get sales metrics
     */
    salesGetLastSixMonthSales(payload: { lastMonth: string, last6Month: string }) {
        return apiV2.getRequest(`${_SALES_URL}/income/${payload.last6Month}/${payload.lastMonth}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create invoice
     */
    salesCreateInvoice(payload: SalesActions["salesCreateInvoice"]["payload"]["invoice"]) {
        return apiV2.postRequest(`${_SALES_URL}/invoices`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description gey invoice
     */
    salesGetInvoice(payload: { invoiceId: string }) {
        return apiV2.getRequest(`${_SALES_URL}/invoices/${payload.invoiceId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description list invoices
     */
    salesGetInvoices() {
        return apiV2.getRequest(`${_SALES_URL}/invoices`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update invoice
     */
    salesUpdateInvoice(payload: SalesActions["salesUpdateInvoice"]["payload"]) {
        const { invoice, invoiceId } = payload;
        return apiV2.putRequest(`${_SALES_URL}/invoices/${invoiceId}`, {
            useToken: "accessToken",
            data: JSON.stringify(invoice),
        });
    }

    /**
     * @description create payment
     */
    salesCreatePayment(payload: SalesActions["salesCreatePayment"]["payload"]["paymentData"]) {
        return apiV2.postRequest(`${_SALES_URL}/payments`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description update payment
     */
    salesUpdatePayment(payload: SalesActions["salesUpdatePayment"]["payload"]) {
        const { payment, transactionId } = payload;
        return apiV2.putRequest(`${_SALES_URL}/payments/${transactionId}`, {
            useToken: "accessToken",
            data: JSON.stringify(payment),
        });
    }

    /**
     * @description void payment
     */
     salesVoidPayment(payload: SalesActions["salesVoidPayment"]["payload"]) {
        const { paymentId } = payload;
        return apiV2.putRequest(`${_SALES_URL}/payments/${paymentId}/void`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get invoices inside a payment
     */
    salesGetInvoicesInPayment(payload: { paymentId: string }) {
        return apiV2.getRequest(`${_SALES_URL}/payments/${payload.paymentId}/invoices`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description generate the next invoice number
     */
    salesGetNextInvoiceNumber() {
        return apiV2.getRequest(`${_SALES_URL}/invoices/ref`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description generate the next payment number
     */
    salesGetNextPaymentNumber() {
        return apiV2.getRequest(`${_SALES_URL}/payments/ref`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description generate the next refund number
     */
    salesGetNextRefundNumber() {
        return apiV2.getRequest(`${_SALES_URL}/refund/ref`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get configurations
     */
    salesGetConfigurations() {
        return apiV2.getRequest(`${_SALES_URL}/configurations`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update configurations
     */
    salesUpdateConfigurations(payload: SalesActions["salesUpdateConfigurations"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/configurations`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description create tax
     */
    salesCreateTax(payload: SalesActions["salesCreateTax"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/taxes`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description update tax
     */
    salesUpdateTax(payload: SalesActions["salesUpdateTax"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/taxes/${payload.taxId}`, {
            useToken: "accessToken",
            data: JSON.stringify(payload.tax),
        });
    }

    /**
     * @description create refund
     */
    salesCreateRefund(payload: SalesActions["salesCreateRefund"]["payload"]["refund"]) {
        return apiV2.postRequest(`${_SALES_URL}/refund`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description create online refund
     */
    salesCreateOnlineRefund(payload: SalesActions["salesCreateOnlineRefund"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/refund/online`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description create terminal refund
     */
     salesCreateTerminalRefund(payload: SalesActions["salesCreateTerminalRefund"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/refund/terminal`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description update refund
     */
    salesUpdateRefund(payload: SalesActions["salesUpdateRefund"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/refund/${payload.refundId}`, {
            useToken: "accessToken",
            data: JSON.stringify(payload.refund),
        });
    }

    /**
     * @description void refund
     */
    salesVoidRefund(payload: SalesActions["salesVoidRefund"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/refund/${payload.refundId}/void`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description void invoice
     */
    salesVoidInvoice(payload: SalesActions["salesVoidInvoice"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/invoices/${payload.invoiceId}/void`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get refund
     */
    salesGetRefund(payload: SalesActions["salesGetRefund"]["payload"]) {
        return apiV2.getRequest(`${_SALES_URL}/refund/${payload.refundId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description send email
     */
    salesSendEmail(payload: SalesActions["salesSendEmail"]["payload"]) {
        const { id, type } = payload;
        return apiV2.putRequest(`${_SALES_URL}/${type}/${id}/mail`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get all taxes
     */
    salesGetAllTaxes() {
        return apiV2.getRequest(`${_SALES_URL}/taxes`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get tax
     */
    salesGetTax(payload: SalesActions["salesGetTax"]["payload"]) {
        return apiV2.getRequest(`${_SALES_URL}/taxes/${payload.taxId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description calculate invoice taxes
     */
    salesInvoiceCalculator(payload: SalesActions["salesInvoiceCalculator"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/invoices/calculator`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description update business number
     */
    salesUpdateBusinessNumber(payload: SalesActions["salesUpdateBusinessNumber"]["payload"]) {
        return apiV2.putRequest(`${_SALES_URL}/configurations/business-numbers`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description Search invoices
     */
    salesSearchInvoices(payload: SalesActions["salesSearchInvoices"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/invoices/search`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description Register terminal
     */
     salesRegisterTerminal(payload: SalesActions["salesRegisterTerminal"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/stripe/terminals`, {
            useToken: "accessToken",
            data: JSON.stringify(payload.terminal),
        });
    }

    /**
     * @description list stripe locations
     */
     salesGetStripeLocations() {
        return apiV2.getRequest(`${_SALES_URL}/stripe/locations`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create stripe location
     */
     salesCreateStripeLocation(payload: SalesActions["salesCreateStripeLocation"]["payload"]) {
        return apiV2.postRequest(`${_SALES_URL}/stripe/locations`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description list terminals in locations
     */
     salesGetLocationTerminals(payload: SalesActions["salesGetLocationTerminals"]["payload"]) {
        return apiV2.getRequest(`${_SALES_URL}/stripe/locations/${payload.stripeLocationId}/terminals`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description Edit terminal label
     */
     salesEditTerminalLabel(payload: SalesActions["salesEditTerminalLabel"]["payload"]) {
         const { label, stripeTerminalId } = payload;
        return apiV2.putRequest(`${_SALES_URL}/stripe/terminals/${stripeTerminalId}`, {
            useToken: "accessToken",
            data: JSON.stringify({ label }),
        });
    }

    /**
     * @description remove terminal
     */
     salesDeleteTerminal(payload: SalesActions["salesDeleteTerminal"]["payload"]) {
         const { stripeTerminalId } = payload;
        return apiV2.deleteRequest(`${_SALES_URL}/stripe/terminals/${stripeTerminalId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description check if terminal is configured for an org
     */
    salesCheckTerminalConfiguration() {
        return apiV2.getRequest(`${_SALES_URL}/stripe/terminal/verify`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description delete terminal location
     */
     salesDeleteStripeLocation(payload: SalesActions["salesDeleteStripeLocation"]["payload"]) {
        return apiV2.deleteRequest(`${_SALES_URL}/stripe/locations/${payload.stripeLocationId}`, {
            useToken: "accessToken"
        })
    }

    /**
     * @description create stripe terminal connection
     */
    salesCreateConnectionToken = (payload: { locationId: string }) => {
        return apiV2.postRequest(`${_SALES_URL}/stripe/terminal/token`, {
            useToken: "accessToken",
            data: JSON.stringify({ locationId: payload.locationId }),
        });
    }
    
    /**
     * @description create payment intent
     */
    salesCreatePaymentIntent = (payload: { amount: number; description: string }) => {
        const { amount, description } = payload;
        const body = { amount, description };
        return apiV2.postRequest(`${_SALES_URL}/stripe/terminal/intent`, {
            useToken: "accessToken",
            data: JSON.stringify(body),
        });
    }
    
    /**
     * @description create payment intent
    */
    salesCapturePaymentIntent = (payload: { paymentIntentId: string }) => {
        const { paymentIntentId } = payload;
        return apiV2.postRequest(`${_SALES_URL}/stripe/terminal/intent/capture`, {
            useToken: "accessToken",
            data: JSON.stringify({ paymentIntentId }),
        });
    }
    
    salesCreatePaymentObject = (payload: SalesActions["salesCreatePaymentObject"]["payload"]) => {
        const { paymentIntentId, invoiceId } = payload;
        return apiV2.postRequest(`${_SALES_URL}/payments/terminals`, {
            useToken: "accessToken",
            data: JSON.stringify({ paymentIntentId, invoiceId }),
        });
    }
}

export default new salesClass();

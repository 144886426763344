import apiV2 from "../../../../../helpers/api.v2";
import { DomainActions } from "../reducer/actions.types";
const _URL = `${process.env.REACT_APP_API_URL}/domain`;
const BILLINGS_URL = `${process.env.REACT_APP_API_URL}/billing`;

/**
 * @description Domain API calls.
 */
class DomainClass {
    /**
     * @description get domain info
     */
    domainGetDomainInfo() {
        return apiV2.getRequest(`${_URL}`);
    }

    /**
     *
     * @description check the availability of domain.
     */
    domainCheckAvailability(payload: DomainActions["domainCheckAvailability"]["payload"]) {
        return apiV2.postRequest(`${_URL}/availability`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description subscribe domain name
     */
    domainSubscribeDomain(payload: Pick<DomainActions["domainSubscribeDomain"]["payload"], "domainName" | "purchaseInfo" | "paymentMethodId">) {
        return apiV2.postRequest(`${BILLINGS_URL}/domain/subscribe`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description confirm subscription name
     */
    domainSubscribeDomainConfirm(payload: DomainActions["domainSubscribeDomainConfirm"]["payload"]) {
        return apiV2.postRequest(`${BILLINGS_URL}/domain/subscribe/confirm`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description turn on/off domain renew flag
     */
    domainRenewFlag(payload: DomainActions["domainRenewFlag"]["payload"]) {
        return apiV2.postRequest(`${_URL}/renew`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     *
     * @description Update domain name informations
     */
    domainUpdateInfos(payload: DomainActions["domainUpdateInfos"]["payload"]) {
        const { domainInfos, domainName } = payload;
        return apiV2.putRequest(`${_URL}/${domainName}`, {
            data: JSON.stringify(domainInfos),
            useToken: "accessToken",
        });
    }
}

export default new DomainClass();

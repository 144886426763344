import { Organization } from "../organization";
import { OrgActions } from "../redux/org/reducer/actions.types";
import GOALS from "../../../helpers/json/goals.json";
import { OrganizationState } from "../redux/org/reducer/reducer";
import { Validation } from "@soltivo/draw-a-line/core/helpers/classes/Validation";

/**
 * @description validation for organization
 */
class OrganizationValidation {
    /**
     * @description form types.
     */
    public formTypes = {
        ORG_DELETE: "ORG_DELETE",
        ORG_CREATE_SECONDARY: "ORG_CREATE_SECONDARY",
        ORG_UPDATE: "ORG_UPDATE",
        ORG_UPDATE_TAXES: "ORG_UPDATE_TAXES",
    } as const;

    public validation = new Validation<keyof OrganizationValidation["formTypes"]>([]);

    public orgAutoUpdateOrganization(payload: OrgActions["orgAutoUpdateOrganization"]["payload"], validateFields?: string[]) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (typeof payload["logo"] !== "string" && payload["logo"]?.type) {
            const type = payload["logo"].type.toLowerCase();
            if (!type || !/(jpg|png|jpeg|webp)/g.test(type?.toLowerCase())) {
                errors.push({
                    fieldName: "logo",
                    formName: this.formTypes.ORG_UPDATE,
                    message: "Logo must have jpg, jpeg, png or webp extension.",
                });
            }
        }
        if (!payload.name) {
            errors.push({
                fieldName: "name",
                formName: this.formTypes.ORG_UPDATE,
                message: "Name is required.",
            });
        }
        if (!payload.fiscalYear) {
            errors.push({
                fieldName: "fiscalYear",
                formName: this.formTypes.ORG_UPDATE,
                message: "Fiscal year is required.",
            });
        } else if (
            ![
                "January - December",
                "February - January",
                "March - February",
                "April - March",
                "May - April",
                "June - May",
                "July - June",
                "August - July",
                "September - August",
                "October - September",
                "November - October",
                "December - November",
            ].includes(payload.fiscalYear)
        ) {
            errors.push({
                fieldName: "fiscalYear",
                formName: this.formTypes.ORG_UPDATE,
                message: `Fiscal year ${payload.fiscalYear} is invalid.`,
            });
        }
        if (!payload.timeZone) {
            errors.push({
                fieldName: "timeZone",
                formName: this.formTypes.ORG_UPDATE,
                message: "Time zone is required.",
            });
        }
        if (!payload.timeFormat) {
            errors.push({
                fieldName: "timeFormat",
                formName: this.formTypes.ORG_UPDATE,
                message: "Time format is required.",
            });
        } else if (!["12hours", "24hours"].includes(payload.timeFormat)) {
            errors.push({
                fieldName: "timeFormat",
                formName: this.formTypes.ORG_UPDATE,
                message: "Invalid time format.",
            });
        }
        if (!payload.currency) {
            errors.push({
                fieldName: "currency",
                formName: this.formTypes.ORG_UPDATE,
                message: "Currency is required.",
            });
        } else if (!payload.currency) {
            errors.push({
                fieldName: "currency",
                formName: this.formTypes.ORG_UPDATE,
                message: "Currency is required..",
            });
        } else if (!payload.headquarter) {
            errors.push({
                fieldName: "headquarter",
                formName: this.formTypes.ORG_UPDATE,
                message: "Head quarter is required.",
            });
        }

        if (validateFields) {
            errors = errors.filter((attr) => validateFields.includes(attr.fieldName));
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    public orgUpdateOrganization(
        payload: OrgActions["orgUpdateOrganization"]["payload"],
        data: {
            organizations: OrganizationState["organizations"];
        },
        validateFields?: string[]
    ) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (typeof payload["logo"] !== "string" && payload["logo"]?.type) {
            const type = payload["logo"].type.toLowerCase();
            if (!type || !/(jpg|png|jpeg|webp)/g.test(type?.toLowerCase())) {
                errors.push({
                    fieldName: "logo",
                    formName: this.formTypes.ORG_UPDATE,
                    message: "Logo must have jpg, jpeg, png or webp extension.",
                });
            }
        } else if (!payload.name) {
            errors.push({
                fieldName: "name",
                formName: this.formTypes.ORG_UPDATE,
                message: "Name is required.",
            });
        } else if (data.organizations.map((org) => org.name).includes(payload.name)) {
            errors.push({
                fieldName: "name",
                formName: this.formTypes.ORG_UPDATE,
                message: `You already have an organization named ${payload.name}`,
            });
        } else if (!payload.fiscalYear) {
            errors.push({
                fieldName: "fiscalYear",
                formName: this.formTypes.ORG_UPDATE,
                message: "Fiscal year is required.",
            });
        } else if (
            ![
                "January - December",
                "February - January",
                "March - February",
                "April - March",
                "May - April",
                "June - May",
                "July - June",
                "August - July",
                "September - August",
                "October - September",
                "November - October",
                "December - November",
            ].includes(payload.fiscalYear)
        ) {
            errors.push({
                fieldName: "fiscalYear",
                formName: this.formTypes.ORG_UPDATE,
                message: `Fiscal year ${payload.fiscalYear} is invalid.`,
            });
        } else if (!payload.timeZone) {
            errors.push({
                fieldName: "timeZone",
                formName: this.formTypes.ORG_UPDATE,
                message: "Time zone is required.",
            });
        } else if (!payload.timeFormat) {
            errors.push({
                fieldName: "timeFormat",
                formName: this.formTypes.ORG_UPDATE,
                message: "Time format is required.",
            });
        } else if (!["12hours", "24hours"].includes(payload.timeFormat)) {
            errors.push({
                fieldName: "timeFormat",
                formName: this.formTypes.ORG_UPDATE,
                message: "Invalid time format.",
            });
        } else if (!payload.currency) {
            errors.push({
                fieldName: "currency",
                formName: this.formTypes.ORG_UPDATE,
                message: "Currency is required.",
            });
        } else if (!payload.headquarter) {
            errors.push({
                fieldName: "headquarter",
                formName: this.formTypes.ORG_UPDATE,
                message: "Head quarter is required.",
            });
        }

        if (validateFields) {
            errors = errors.filter((attr) => validateFields.includes(attr.fieldName));
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    public orgCreateSecondaryOrganization(
        payload: OrgActions["orgCreateSecondaryOrganization"]["payload"],
        data: {
            org: Organization;
            organizations: Organization[];
        }
    ) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (data.organizations.length >= 10) {
            errors.push({
                message: "You cannot have more than 10 organizations.",
                fieldName: "global",
                formName: this.formTypes.ORG_CREATE_SECONDARY,
                status: "unprocessable",
            });
        } else {
            if (!payload.name) {
                errors.push({
                    message: "Organization name is required.",
                    fieldName: "name",
                    formName: this.formTypes.ORG_CREATE_SECONDARY,
                    status: "unprocessable",
                });
            } else if (data.organizations.map((org) => org.name).includes(payload.name)) {
                errors.push({
                    message: `You already have an organization named ${payload.name}.`,
                    fieldName: "name",
                    formName: this.formTypes.ORG_CREATE_SECONDARY,
                    status: "unprocessable",
                });
            }

            if (!payload.goal) {
                errors.push({
                    message: `Organization goal is required.`,
                    fieldName: "goal",
                    formName: this.formTypes.ORG_CREATE_SECONDARY,
                    status: "unprocessable",
                });
            } else if (!GOALS.map((goal) => goal.camelCase).includes(payload.goal)) {
                errors.push({
                    message: `Goal ${payload.goal} is not valid.`,
                    fieldName: "goal",
                    formName: this.formTypes.ORG_CREATE_SECONDARY,
                    status: "unprocessable",
                });
            }
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    /**
     * @description delete organization
     */
    public orgDeleteOrganization(
        payload: OrgActions["orgDeleteOrganization"]["payload"],
        data: {
            org: Organization;
            organizations: Organization[];
            cognitoUser: any;
        }
    ) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.orgId) {
            errors.push({
                message: "Organization is missing id.",
                fieldName: "org",
                formName: this.formTypes.ORG_DELETE,
                status: "unprocessable",
            });
        } else if (!data.cognitoUser?.attributes["custom:org_id"]) {
            errors.push({
                message: "Cognito user is missing attribute custom:org_id.",
                fieldName: "org",
                formName: this.formTypes.ORG_DELETE,
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    /**
     * @description update taxes for organization
     */
    public orgSetOrganizationSalesTaxes(payload: OrgActions["orgSetOrganizationSalesTaxes"]["payload"]) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        for (let i = 0; i < payload.taxes.length; i++) {
            let tax = payload.taxes[i];

            if (!tax.agency) {
                errors.push({
                    message: "Agency is required.",
                    fieldName: "agency",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            }
            if (!tax.code) {
                errors.push({
                    message: "Code is required.",
                    fieldName: "code",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            }

            if (typeof tax.tax !== "number") {
                errors.push({
                    message: "Tax is required.",
                    fieldName: "tax",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            } else if (tax.tax > 1.0) {
                errors.push({
                    message: "Tax cannot be greater than 100%.",
                    fieldName: "tax",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            } else if (tax.tax < 0) {
                errors.push({
                    message: "Tax cannot be less than 0%.",
                    fieldName: "tax",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            }

            if (!tax.type) {
                errors.push({
                    message: "Type is required.",
                    fieldName: "type",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            } else if (!["federal", "reginal"].includes(tax.type)) {
                errors.push({
                    message: "Type can only be federal or regional.",
                    fieldName: "type",
                    formName: this.formTypes.ORG_UPDATE_TAXES,
                    status: "unprocessable",
                });
            }
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }
}

export default new OrganizationValidation();

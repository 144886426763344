import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { orgChangeState } from "../../../../applications/auth/redux/org/reducer/actions";
import { OrganizationState } from "../../../../applications/auth/redux/org/reducer/reducer";
import { RootState } from "../../../../redux/reducers";

/**
 * @description Use url pagination to navigate beetween steps
 */
const useGuideStepsNavigation = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [isMounted, setIsMounted] = useState(false);

    const mapStateToProps = ({ OrgReducer }: RootState) => ({
        tour: OrgReducer.tour,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;

    let state: MapStateToProps = useSelector(mapStateToProps);

    const { tour } = state;

    useEffect(() => {
        if(!isMounted) {
            history.replace("/dashboard");
            setIsMounted(true);
        }

        const queryParam = location.search.split("=");
        let currentUrlParamStep = queryParam.length > 1 ? queryParam[1] as OrganizationState["tour"]["stepName"] : undefined;

        let currentStep: OrganizationState["tour"]["stepName"] | OrganizationState["tour"]["subStep"] = tour.stepName;
        if(tour.subStep) currentStep = tour.subStep;

        if(currentStep !== currentUrlParamStep) {
            // Update the state query param in the url
            if(currentStep) {
                history.push(`${location.pathname}?step=${currentStep}`);
            } else {
                history.replace(location.pathname)
            }
        }
    }, [tour.stepName, tour.subStep]);

    useEffect(() => {
        const queryParam = location.search.split("=");
        let currentUrlParamStep = queryParam.length > 1 ? queryParam[1] as OrganizationState["tour"]["stepName"] & OrganizationState["tour"]["subStep"]  : undefined;
        if(!currentUrlParamStep) return;

        // Check if it is a sub step
        if(["invitation", "list", "schedule", "employeeServices"].includes(currentUrlParamStep)) {
            dispatch(orgChangeState({ tour: {
                ...tour,
                subStep: currentUrlParamStep
            }}));
        } else {
            dispatch(orgChangeState({ tour: {
                ...tour,
                stepName: currentUrlParamStep
            }}));
        }

    }, [location.search]);
};

export default useGuideStepsNavigation;
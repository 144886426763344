import { AuthState } from "./reducer";
import { types } from "./types";

export const authGetIndexedDBData = () => ({
    type: types.AUTH_GET_INDEXED_DB_DATA,
});

/**
 * @description change auth state.
 * @warning be careful using this action, you could break the app.
 */
export const authChangeState = (payload: Partial<AuthState>) => ({
    type: types.AUTH_CHANGE_STATE,
    payload,
});

/**
 * @description get the current autheticated user.
 */
export const authCurrentAuthenticatedUser = (payload?: { refresh: boolean }) => ({
    type: types.AUTH_CURRENT_AUTHENTICATED_USER_REQUEST,
    payload,
});

/**
 * @description login user.
 */
export const authSignIn = (payload: { email: string; password: string }) => ({
    type: types.AUTH_SIGN_IN_USER_REQUEST,
    payload,
});

/**
 * @description create user account.
 */
export const authSignUp = (payload: { email: string; phone_number: string; password: string }) => ({
    type: types.AUTH_SIGN_UP_USER_REQUEST,
    payload,
});


/**
 * @description Change phone number validation
 */
export const authChangePhoneNumberValidation = (payload: { phoneNumber: string; userId: string }) => ({
    type: types.AUTH_CHANGE_PHONE_NUMBER_VALIDATION_REQUEST,
    payload,
});

/**
 * @description OnBoarding Employee account.
 */
export const authOnBoardEmployee = (payload: { email: string; oldPassword: string; password: string }) => ({
    type: types.AUTH_ON_BOARDING_USER_REQUEST,
    payload,
});

/**
 * @description validate Employee Registration.
 */
export const validateRegistration = (payload: { employeeId: string; registrationId: string }) => ({
    type: types.AUTH_VALIDATE_EMPLOYEE_REGISTRATION_REQUEST,
    payload,
});

/**
 * @description update Employee attribute.
 */
export const updateEmployeeAttribute = (payload: {
    // id: string;
    firstName: string;
    lastName: string;
    hourlyRate: number;
}) => ({
    type: types.AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_REQUEST,
    payload,
});

/**
 * @description confirm sign up user with sms code.
 */
export const authConfirmSignUp = (payload: { email: string; code: string }) => ({
    type: types.AUTH_CONFIRM_SIGN_UP_REQUEST,
    payload,
});

/**
 * @description resend code to confirm account created in sign up.
 */
export const authResendCodeSignUp = (payload: { email: string }) => ({
    type: types.AUTH_SIGN_UP_RESEND_CODE_REQUEST,
    payload,
});

/**
 * @description logout user.
 */
export const authLogout = () => ({ type: types.AUTH_LOGOUT_USER_REQUEST });

/**
 * @description user forgot password & send code to user cellphone.
 */
export const authForgotPassword = (payload: { email: string }) => ({
    type: types.AUTH_FORGOT_PASSWORD_REQUEST,
    payload,
});

/**
 * @description user forgot password submit.
 */
export const authForgotPasswordSubmit = (payload: { email: string; code: string; new_password: string; confirm_password: string }) => ({
    type: types.AUTH_FORGOT_PASSWORD_SUBMIT_REQUEST,
    payload,
});

/**
 * @description update user attributes
 */
export const authUpdateUserAttributes = (payload: { attributes: object }) => ({
    type: types.AUTH_UPDATE_USER_ATTRIBUTES_REQUEST,
    payload,
});

/**
 * @description update user status
 */
export const updateUserStatus = (payload: { status: "unavailable" | "available" | "idle" | "busy" }) => ({
    type: types.AUTH_UPDATE_USER_STATUS_REQUEST,
    payload,
});

/**
 * @description gets profile actual profile information.
 */
export const authProfileInfo = () => ({
    type: types.AUTH_PROFILE_INFO_REQUEST,
});

/**
 * @description update admin profile
 */
export const authUpdateAdminInfo = (payload: { firstName: string; lastName: string; hourlyRate: number; email: string; avatar: File | string; phoneNumber: string }) => ({
    type: types.AUTH_UPDATE_ADMIN_INFO_REQUEST,
    payload,
});

/**
 * @description verify user attribute, add code param to submit the SMS code.
 */
export const authVerifyCurrentUserAttribute = (payload: { attribute: "phone_number" | "email"; code?: string }) => ({
    type: types.AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_REQUEST,
    payload,
});

/**
 * @description change user password
 */
export const authChangeUserPassword = (payload: { oldPassword: string; newPassword: string; confirmPassword: string; code?: string }) => ({
    type: types.AUTH_CHANGE_USER_PASSWORD_REQUEST,
    payload,
});

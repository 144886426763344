import { type ResourcesConfig } from 'aws-amplify';

const authConfig: ResourcesConfig  = {
    Auth: {
        Cognito : {
            // region: process.env.REACT_APP_USER_POOL_REGION,
            userPoolId: process.env.REACT_APP_USER_POOL_ID || "",
            userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || "",
        }
    },
};

export default authConfig;

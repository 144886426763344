import { call, put, select } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description mark email as read | unread.
 */
export function* emailMarkReadAs({ payload }: EmailActions["emailMarkReadAs"]) {
    try {
        const mapStateToProps = ({ EmailReducer }: RootState) => ({
            out_exchanges: EmailReducer.out_exchanges,
            in_exchanges: EmailReducer.in_exchanges,
            deleted_exchanges: EmailReducer.deleted_exchanges,
        });
        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { deleted_exchanges, in_exchanges, out_exchanges }: MapStateToProps = yield select(mapStateToProps);

        const found = [...deleted_exchanges.items, ...in_exchanges.items, ...out_exchanges.items].find((item) => {
            return item.exchangeId === payload.exchangeId;
        });

        // only call api if email in the listing isn't marked already as the payload.
        if (found?.unread === payload.unread) return;

        yield call(emailClass.emailMarkReadAs, payload);

        deleted_exchanges.items = deleted_exchanges.items.map((item) => {
            if (item.exchangeId === payload.exchangeId) {
                item.unread = payload.unread;
            }
            return item;
        });

        in_exchanges.items = in_exchanges.items.map((item) => {
            if (item.exchangeId === payload.exchangeId) {
                item.unread = payload.unread;
            }
            return item;
        });

        out_exchanges.items = out_exchanges.items.map((item) => {
            if (item.exchangeId === payload.exchangeId) {
                item.unread = payload.unread;
            }
            return item;
        });

        yield put({
            type: emailTypes.EMAIL_MARK_READ_AS_SUCCESS,
            payload: {
                deleted_exchanges,
                in_exchanges,
                out_exchanges,
            },
        });
    } catch (error: any) {
        yield put({ type: emailTypes.EMAIL_MARK_READ_AS_FAILURE, payload: error });
    }
}

import styles from "./step.employees.module.scss";
import useGuideHeading from "../../../hooks/useGuideHeading";
import GuideOrgContext from "../../../context/guide.org.context";
import { useContext, useEffect, useState } from "react";
import { guideChangeAction, guideOrgChangeFormState } from "../../../context/actions";
import { Edit24Px, Check24Px, Plus, DeleteOutline24Px } from "@soltivo/draw-a-line/core/components/icons";
import { Button, InputText, soltivoHelper, FormMessages } from "@soltivo/draw-a-line";
import { RootState } from "../../../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { orgSetup, orgUpdateConfiguration } from "../../../../../../applications/auth/redux/org/reducer/actions";
import { Organization } from "../../../../../../applications/auth/organization";
import CreateSchedule from "../step.create.schedule/create.schedule";
import StepEmployeeServices from "../step.employee.services/step.employee.services";
import { User } from "../../../../../../applications/auth/auth";
import { Employee } from "@soltivo/types";
import { serviceGetAllServices, serviceGetCategories } from "../../../../../../applications/service/redux/reducer/actions";

export type StepEmployeesView = "list" | "invitation" | "schedule" | "employeeServices";

const StepEmployees = () => {
    const [employee, setEmployee] = useState<Employee | User>();

    /**
     * @description ids of employees not configured.
     */
    const [employeesNotSet, setEmployeesNotSet] = useState<string[]>([]);
    const dispatch = useDispatch();

    const mapStateToProps = ({ HRMReducer, OrgReducer, AuthReducer, ServiceReducer }: RootState) => ({
        employees: HRMReducer.employees,
        org: OrgReducer.org as Organization,
        error: OrgReducer.error,
        tour: OrgReducer.tour,
        configuration: OrgReducer.configuration,
        user: AuthReducer.user as User,
        allServices: ServiceReducer.allServices,
        categories: ServiceReducer.categories,
    });
    const {
        employees: registeredEmployees,
        tour,
        user,
        error,
        org: {
            configuration: { employees: configurationEmployees },
        },
        configuration: orgConfiguration,
        categories,
        allServices,
    } = useSelector(mapStateToProps);

    const views: StepEmployeesView[] = ["list", "invitation", "schedule", "employeeServices"];
    const [view, setView] = useState<StepEmployeesView>("invitation");

    const onChangeView = (view: StepEmployeesView) => {
        setView(view);
    };

    const {
        state: {
            form: { employees },
        },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    type labelTypes = ReturnType<() => keyof (typeof employees)[0]>;
    const formLabels: { [key: string]: string } = {
        firstName: "First Name",
        lastName: "Last Name",
        personalEmail: "Email",
    };

    useGuideHeading({
        title: view === "invitation" ? "Invite your employees" : "Configure your employees",
        description:
            view === "invitation"
                ? "Invite your employees to your organization! They will receive a link by email for them to create an account."
                : "Configure their schedules and their services so your customers can start booking appointments.",
    });

    useEffect(() => {
        // In case we refresh the page before finishing the configuration, refetch categories and services
        if (!categories.length) dispatch(serviceGetCategories());
        if (!allServices.length) dispatch(serviceGetAllServices());
    }, []);

    useEffect(() => {
        if (view === "employeeServices") return;

        let invalidForm = false;
        if (view === "invitation" || view === "list") {
            /** Form is valid if at least one employee line is filled correctly */
            employees.forEach((emp) => {
                if (!emp.firstName || !emp.lastName || soltivoHelper.validateEmail(emp.personalEmail)) {
                    invalidForm = true;
                    return;
                }
            });
        }

        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    value: view === "invitation" ? "Invite" : "Next",
                    onClick: () => {
                        dispatch(
                            orgSetup({
                                currentSubStep: view,
                                nextSubStep: view === "invitation" ? "list" : undefined,
                                nextStep: view === "list" ? "confirmation" : undefined,
                                data:
                                    view === "invitation"
                                        ? {
                                              employees: employees,
                                          }
                                        : {},
                            })
                        );
                    },
                    disabled: view === "invitation" ? invalidForm : view === "list" ? (employeesNotSet.length ? true : false) : false,
                },
            })
        );
    }, [JSON.stringify(employees), view, employeesNotSet]);

    useEffect(() => {
        if (view === "list") {
            dispatchContext(
                guideOrgChangeFormState({
                    employees: [...employees.filter((emp) => !soltivoHelper.validateEmail(emp.personalEmail))],
                })
            );
        }

        if (view === "invitation" || view === "list") {
            dispatchContext(
                guideChangeAction({
                    type: "back",
                    payload: {
                        onClick:
                            view === "invitation" || view === "list"
                                ? undefined
                                : () => {
                                      if (view === "services") {
                                          setView("employeeServices");
                                      } else if (view === "schedule") {
                                          setView(registeredEmployees.length ? "list" : "invitation");
                                      }
                                  },
                    },
                })
            );
        }
    }, [view, registeredEmployees]);

    const onChange = (payload: { key: string; value: string; id: number }) => {
        const { key, value, id } = payload;
        let employee = employees[id];
        employee = { ...employee, [key]: value };
        const newEmployeeList = [...employees];
        newEmployeeList[id] = employee;
        dispatchContext(
            guideOrgChangeFormState({
                employees: newEmployeeList,
            })
        );
    };

    const onAddNewEmployee = () => {
        dispatchContext(
            guideOrgChangeFormState({
                employees: [...employees, { firstName: "", lastName: "", personalEmail: "" }],
            })
        );
    };

    const onRemoveEmployee = (index: number) => {
        if (employees.length === 1) return;
        dispatchContext(
            guideOrgChangeFormState({
                employees: [...employees.filter((el, i) => i !== index)],
            })
        );
    };

    const configureEmployee = (employee: Employee | User) => {
        const configurationEmployee = configurationEmployees[employee.userId];
        if (configurationEmployee) {
            if (!configurationEmployee.schedule) {
                setView("schedule");
            } else if (!configurationEmployee.services) {
                setView("employeeServices");
            }
            setEmployee(employee);
        } else {
            // init object if it has not been created yet.
            dispatch(
                orgUpdateConfiguration({
                    configuration: {
                        employees: {
                            [employee.userId]: {
                                schedule: false,
                                services: false,
                            },
                        },
                    },
                })
            );
            setView("schedule");
            setEmployee(employee);
        }
    };

    useEffect(() => {
        if (tour.subStep) {
            if (views.includes(tour.subStep)) {
                setView(tour.subStep);
            }
        }
    }, [tour.subStep]);

    useEffect(() => {
        if (view === "list" || view === "invitation") {
            setEmployee(undefined);
        }
    }, [view]);

    useEffect(() => {
        const notSet = Object.keys(configurationEmployees).filter((key) => !configurationEmployees[key].schedule || !configurationEmployees[key].services);
        // Substract 1 from `registeredEmployees` in order to allow the admin to add some employees
        if (registeredEmployees.length - 1 && view === "invitation") {
            setView("list");
        }

        setEmployeesNotSet(notSet);
    }, [registeredEmployees.length, configurationEmployees, view]);

    return (
        <div className={styles.employees}>
            {view === "schedule" && employee ? (
                <CreateSchedule user={user} employee={employee} onChangeView={onChangeView} />
            ) : view === "employeeServices" && employee ? (
                <StepEmployeeServices user={user} employee={employee} onChangeView={onChangeView} />
            ) : view === "list" ? (
                <div className={styles.employee_config}>
                    {registeredEmployees.map((emp, index) => {
                        return (
                            <div key={index} className={styles.employee_line}>
                                <p className={styles.name}>{emp.userId === user.userId ? "You" : `${emp.firstName || ""} ${emp.lastName || ""}`}</p>
                                <p className={styles.email}>{emp.email}</p>
                                <Button
                                    className={styles.button}
                                    onClick={() => configureEmployee(emp)}
                                    variant={configurationEmployees[emp.userId]?.schedule && configurationEmployees[emp.userId]?.services ? "elm-500" : `primary`}
                                    border={false}
                                    padding={false}
                                    disabled={orgConfiguration.loading}
                                    loading={employee?.userId === emp.userId ? orgConfiguration.loading : false}
                                    outline={true}>
                                    {configurationEmployees[emp.userId]?.schedule && configurationEmployees[emp.userId]?.services ? (
                                        <>
                                            <Check24Px className={styles.icon} /> Configured
                                        </>
                                    ) : (
                                        <>
                                            <Edit24Px className={styles.icon} /> Configure
                                        </>
                                    )}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            ) : view === "invitation" ? (
                <>
                    {error && typeof error === "string" && (
                        <div className={styles.error__box}>
                            <FormMessages
                                messages={[
                                    {
                                        status: "danger",
                                        value: error,
                                    },
                                ]}
                            />
                        </div>
                    )}
                    <div className={styles.form}>
                        <div className={styles.employee_line}>
                            <p className={styles.name}>You</p>
                            <p className={styles.email}>{user.email}</p>
                            <Button
                                type="button"
                                className={styles.button}
                                onClick={() => configureEmployee(user)}
                                variant={configurationEmployees[user.userId]?.schedule && configurationEmployees[user.userId]?.services ? "elm-500" : `primary`}
                                border={false}
                                padding={false}
                                disabled={true}
                                loading={employee?.userId === user.userId ? orgConfiguration.loading : false}
                                outline={true}>
                                {configurationEmployees[user.userId]?.schedule && configurationEmployees[user.userId]?.services ? (
                                    <>
                                        <Check24Px className={styles.icon} /> Configured
                                    </>
                                ) : (
                                    <>
                                        <Edit24Px className={styles.icon} /> Configure
                                    </>
                                )}
                            </Button>
                        </div>

                        {employees.map((emp, employeeIndex) => {
                            return (
                                <div key={employeeIndex} className={styles.employee_form__line}>
                                    {Object.keys(emp).map((key: string, index: number) => {
                                        const keyValue = key as labelTypes;
                                        return (
                                            <div key={index} className={styles.input__wrapper}>
                                                <label>{formLabels[key]}</label>
                                                <InputText
                                                    value={`${emp[keyValue]}`}
                                                    placeholder={formLabels[key]}
                                                    name={key}
                                                    type={key === "personalEmail" ? "email" : "text"}
                                                    onChange={(e) => onChange({ key, value: e.target.value, id: employeeIndex })}
                                                />
                                            </div>
                                        );
                                    })}
                                    {employees.length > 1 ? (
                                        <Button className={styles.remove_employee} onClick={() => onRemoveEmployee(employeeIndex)} variant="primary" border={false} padding={false} outline={true}>
                                            <DeleteOutline24Px />
                                        </Button>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                    <Button data-testid="addEmployeeBtn" onClick={onAddNewEmployee} variant="primary" border={false} padding={false} outline={true}>
                        <Plus /> Add employee
                    </Button>
                </>
            ) : null}
        </div>
    );
};

export default StepEmployees;

import {types} from './types';

const _INITIAL_STATE = {
    error: null,
    categories: [],
    loadingCategories: true,
    searchedCategories: [],
    searching: false,
    searchRequest: false,
    category: null,
    loadingCategory: true,
    products: [],
    loadingProducts: true,
    product: null, // opened product. 
    loadingProduct: true,
    variants: [] // opened product variants form. 
}

const ProductReducer = (state = _INITIAL_STATE, action) =>
{
   
    switch(action.type)
    {

        case types.PRODUCT_CHANGE_STATE: 
            return {
                ...state,
                ...action.payload,
            }

        case types.PRODUCT_GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loadingCategories: true,
            }

        case types.PRODUCT_GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                loadingCategories: false,
                error: null,
            }

        case types.PRODUCT_GET_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingCategories: false,
            }

        case types.PRODUCT_SEARCH_CATEGORY_REQUEST:
            return {
                ...state,
                searchRequest: true,
            }
            
        case types.PRODUCT_SEARCH_CATEGORY_SUCCESS:
            return {
                ...state,
                searching:true,
                searchRequest: false,
                searchedCategories: action.payload,
                error: null,
            }

        case types.PRODUCT_SEARCH_CATEGORY_FAILURE:
            return {
                ...state,
                searchRequest: false,
                error: action.payload,
            }

        case types.PRODUCT_GET_CATEGORY_REQUEST:
            return {
                ...state,
                category: null,
                loadingCategory: true,
                error: null,
            }

        case types.PRODUCT_GET_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loadingCategory: false,
                error: null,
            }

        case types.PRODUCT_GET_CATEGORY_FAILURE:
            return {
                ...state,
                loadingCategory: false,
                error: action.payload,
            }

        case types.PRODUCT_CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                error: null,
            }
        
        case types.PRODUCT_CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
            }

        case types.PRODUCT_UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload.categories,
                searchedCategories: action.payload.searchedCategories,
                error: null,
            }
        
        case types.PRODUCT_UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
            }

        case types.PRODUCT_DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                error: null,
            }
        
        case types.PRODUCT_DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
            }


        case types.PRODUCT_GET_PRODUCTS_REQUEST:
            return {
                ...state,
                loadingProducts: true,
            }

        case types.PRODUCT_GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                loadingProducts: false,
                error: null,
            }

        case types.PRODUCT_GET_PRODUCTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingProducts: false,
            }
            
        case types.PRODUCT_GET_PRODUCT_REQUEST : 
            return {
                ...state,
                loadingProduct: true,
            }

        case types.PRODUCT_GET_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                variants: action.payload.variants || [],
                loadingProduct: false,
                error: null,
            }

        case types.PRODUCT_GET_PRODUCT_FAILURE:
            return {
                ...state,
                loadingProduct: false,
                error: action.payload,
            }
            
        case types.PRODUCT_CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                error: null,
            }
        
        case types.PRODUCT_CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                error: action.payload,
            }

        case types.PRODUCT_UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                error: null,
            }
        
        case types.PRODUCT_UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                error: action.payload,
            }

        case types.PRODUCT_DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                product: null,
                error: null,
            }
        
        case types.PRODUCT_DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                error: action.payload,
            }

        default :
            return state;
    }
}

export default ProductReducer;
export const types = {
    ORG_CHANGE_STATE_REQUEST: "ORG_CHANGE_STATE_REQUEST",

    ORG_CREATE_PRIMARY_ORGANIZATION_REQUEST: "ORG_CREATE_PRIMARY_ORGANIZATION_REQUEST",
    ORG_CREATE_PRIMARY_ORGANIZATION_FAILURE: "ORG_CREATE_PRIMARY_ORGANIZATION_FAILURE",
    ORG_CREATE_PRIMARY_ORGANIZATION_SUCCESS: "ORG_CREATE_PRIMARY_ORGANIZATION_SUCCESS",

    ORG_UPDATE_CONFIGURATION_REQUEST: "ORG_UPDATE_CONFIGURATION_REQUEST",
    ORG_UPDATE_CONFIGURATION_FAILURE: "ORG_UPDATE_CONFIGURATION_FAILURE",
    ORG_UPDATE_CONFIGURATION_SUCCESS: "ORG_UPDATE_CONFIGURATION_SUCCESS",

    ORG_CREATE_SECONDARY_ORGANIZATION_REQUEST: "ORG_CREATE_SECONDARY_ORGANIZATION_REQUEST",
    ORG_CREATE_SECONDARY_ORGANIZATION_FAILURE: "ORG_CREATE_SECONDARY_ORGANIZATION_FAILURE",
    ORG_CREATE_SECONDARY_ORGANIZATION_SUCCESS: "ORG_CREATE_SECONDARY_ORGANIZATION_SUCCESS",

    ORG_AUTO_UPDATE_ORGANIZATION_REQUEST: "ORG_AUTO_UPDATE_ORGANIZATION_REQUEST",
    ORG_AUTO_UPDATE_ORGANIZATION_SUCCESS: "ORG_AUTO_UPDATE_ORGANIZATION_SUCCESS",
    ORG_AUTO_UPDATE_ORGANIZATION_FAILURE: "ORG_AUTO_UPDATE_ORGANIZATION_FAILURE",

    ORG_UPDATE_ORGANIZATION_REQUEST: "ORG_UPDATE_ORGANIZATION_REQUEST",
    ORG_UPDATE_ORGANIZATION_FAILURE: "ORG_UPDATE_ORGANIZATION_FAILURE",
    ORG_UPDATE_ORGANIZATION_SUCCESS: "ORG_UPDATE_ORGANIZATION_SUCCESS",

    ORG_MANAGE_ORGANIZATION_LOCATIONS_REQUEST: "ORG_MANAGE_ORGANIZATION_LOCATIONS_REQUEST",
    ORG_MANAGE_ORGANIZATION_LOCATIONS_FAILURE: "ORG_MANAGE_ORGANIZATION_LOCATIONS_FAILURE",
    ORG_MANAGE_ORGANIZATION_LOCATIONS_SUCCESS: "ORG_MANAGE_ORGANIZATION_LOCATIONS_SUCCESS",

    ORG_CREATE_ORGANIZATION_LOCATION_REQUEST: "ORG_CREATE_ORGANIZATION_LOCATION_REQUEST",
    ORG_CREATE_ORGANIZATION_LOCATION_FAILURE: "ORG_CREATE_ORGANIZATION_LOCATION_FAILURE",
    ORG_CREATE_ORGANIZATION_LOCATION_SUCCESS: "ORG_CREATE_ORGANIZATION_LOCATION_SUCCESS",

    ORG_CREATE_LOCATION_REQUEST: "ORG_CREATE_LOCATION_REQUEST",
    ORG_CREATE_LOCATION_FAILURE: "ORG_CREATE_LOCATION_FAILURE",
    ORG_CREATE_LOCATION_SUCCESS: "ORG_CREATE_LOCATION_SUCCESS",

    ORG_UPDATE_LOCATION_REQUEST: "ORG_UPDATE_LOCATION_REQUEST",
    ORG_UPDATE_LOCATION_FAILURE: "ORG_UPDATE_LOCATION_FAILURE",
    ORG_UPDATE_LOCATION_SUCCESS: "ORG_UPDATE_LOCATION_SUCCESS",

    ORG_DELETE_LOCATION_REQUEST: "ORG_DELETE_LOCATION_REQUEST",
    ORG_DELETE_LOCATION_FAILURE: "ORG_DELETE_LOCATION_FAILURE",
    ORG_DELETE_LOCATION_SUCCESS: "ORG_DELETE_LOCATION_SUCCESS",

    ORG_GET_PLACES_REQUEST: "ORG_GET_PLACES_REQUEST",
    ORG_GET_PLACES_FAILURE: "ORG_GET_PLACES_FAILURE",
    ORG_GET_PLACES_SUCCESS: "ORG_GET_PLACES_SUCCESS",

    ORG_SET_ORGANIZATION_SALES_TAXES_REQUEST: "ORG_SET_ORGANIZATION_SALES_TAXES_REQUEST",
    ORG_SET_ORGANIZATION_SALES_TAXES_FAILURE: "ORG_SET_ORGANIZATION_SALES_TAXES_FAILURE",
    ORG_SET_ORGANIZATION_SALES_TAXES_SUCCESS: "ORG_SET_ORGANIZATION_SALES_TAXES_SUCCESS",

    ORG_DELETE_ORGANIZATION_REQUEST: "ORG_DELETE_ORGANIZATION_REQUEST",
    ORG_DELETE_ORGANIZATION_FAILURE: "ORG_DELETE_ORGANIZATION_FAILURE",
    ORG_DELETE_ORGANIZATION_SUCCESS: "ORG_DELETE_ORGANIZATION_SUCCESS",

    ORG_GET_ORGANIZATION_REQUEST: "ORG_GET_ORGANIZATION_REQUEST",
    ORG_GET_ORGANIZATION_FAILURE: "ORG_GET_ORGANIZATION_FAILURE",
    ORG_GET_ORGANIZATION_SUCCESS: "ORG_GET_ORGANIZATION_SUCCESS",

    ORG_GET_ALL_ORGANIZATIONS_REQUEST: "ORG_GET_ALL_ORGANIZATIONS_REQUEST",
    ORG_GET_ALL_ORGANIZATIONS_FAILURE: "ORG_GET_ALL_ORGANIZATIONS_FAILURE",
    ORG_GET_ALL_ORGANIZATIONS_SUCCESS: "ORG_GET_ALL_ORGANIZATIONS_SUCCESS",

    ORG_GET_ORGANIZATION_STATS_REQUEST: "ORG_GET_ORGANIZATION_STATS_REQUEST",
    ORG_GET_ORGANIZATION_STATS_FAILURE: "ORG_GET_ORGANIZATION_STATS_FAILURE",
    ORG_GET_ORGANIZATION_STATS_SUCCESS: "ORG_GET_ORGANIZATION_STATS_SUCCESS",

    ORG_ENTER_ORGANIZATION_REQUEST: "ORG_ENTER_ORGANIZATION_REQUEST",
    ORG_ENTER_ORGANIZATION_FAILURE: "ORG_ENTER_ORGANIZATION_FAILURE",
    ORG_ENTER_ORGANIZATION_SUCCESS: "ORG_ENTER_ORGANIZATION_SUCCESS",

    ORG_SETUP_REQUEST: "ORG_SETUP_REQUEST",
    ORG_SETUP_FAILURE: "ORG_SETUP_FAILURE",
    ORG_SETUP_SUCCESS: "ORG_SETUP_SUCCESS",

    ORG_TOUR_STEP_REQUEST: "ORG_TOUR_STEP_REQUEST",
};

import { Redirect } from "react-router";
import { PermissionValue } from "../../../applications/hrm/hrm";
// import Page403 from '@soltivo/draw-a-line/core/components/pages/403/403'
import Page404 from "../../pages/404/404";

type CheckPermissionsProps = {
    children: React.ReactNode;
    /**
     * @description true if the ressource can be accessed by the current user (ex: assign={appointment.userId === userId})
     */
    assign?: boolean;
    /**
     * @description permission value (allow, assign, deny)
     */
    permission: PermissionValue | undefined;
    /**
     * @description wrap a page or a component?
     */
    isPage?: boolean;
    /**
     * @description where to redirect if permission is not granted
     */
    redirect?: string;
};

const CheckPermission: React.FC<CheckPermissionsProps> = ({
    children,
    assign = true,
    permission = "allow", // Default allow for administrator
    isPage,
    redirect,
}) => {
    if ((permission === "assign" && assign) || (permission === "assign" && isPage) || permission === "allow") {
        return <>{children}</>;
    } else {
        if (isPage) {
            if (redirect) return <Redirect to={redirect} />
            return <Page404 />;
        }
        return null;
    }
};

export default CheckPermission;

import { Industry } from "./industry";

/**
 * @description all industries available for organizations.
 */
class Industries extends Industry {
    /**
     * @description available industries for organization.
     */
    protected industries: Industry["industry"][] = [
        {
            id: "beautyWellness",
            name: "Beauty & Wellness",
            subs: [
                {
                    id: "aesthetic",
                    name: "Aesthetic",
                },
                {
                    id: "barbershop",
                    name: "Barbershop",
                },
                {
                    id: "beautySalon",
                    name: "Beauty Salon",
                },
                {
                    id: "eyebrowsLashes",
                    name: "Eyebrows & Lashes",
                },
                {
                    id: "hairSalon",
                    name: "Hair Salon",
                },
                {
                    id: "massage",
                    name: "Massage",
                },
                {
                    id: "spa",
                    name: "Spa",
                },
                {
                    id: "tattooPiercing",
                    name: "Tattoo & Piercing",
                },
                {
                    id: "waxingSalon",
                    name: "Waxing Salon",
                },
                {
                    id: "other",
                    name: "Other",
                },
            ],
        },
        {
            id: "healthFitness",
            name: "Healthcare & Fitness",
            subs: [
                {
                    id: "coach",
                    name: "Coach",
                },
                {
                    id: "dentist",
                    name: "Dentist",
                },
                {
                    id: "kinesiologist",
                    name: "Kinesiologist",
                },
                {
                    id: "nutritionist",
                    name: "Nutritionist",
                },
                {
                    id: "personalTrainer",
                    name: "Personal trainer",
                },
                {
                    id: "terapist",
                    name: "Terapist",
                },
                {
                    id: "other",
                    name: "Other",
                },
            ],
        },
        {
            id: "professionalServices",
            name: "Professional Services",
            subs: [
                {
                    id: "accountant",
                    name: "Accountant",
                },
                {
                    id: "consultant",
                    name: "Consultant",
                },
                {
                    id: "legalService",
                    name: "Legal service",
                },
                {
                    id: "other",
                    name: "Other",
                },
            ],
        },
        {
            id: "other",
            name: "Other",
            subs: [
                {
                    id: "other",
                    name: "Other",
                },
            ],
        },
    ];

    /**
     * @description get all industries.
     */
    public getAllIndustries() {
        return this.industries;
    }

    /**
     * @description get industry by id.
     */
    public getIndustryById(id: string) {
        return this.industries.find((industry) => industry.id === id);
    }

    /**
     * @description get sub industry of an industry by id.
     */
    public getSubIndustryById(industryId: string, subId: string) {
        const industry = this.industries.find((industry) => industry.id === industryId);
        if (industry) {
            return industry.subs.find((sub) => sub.id === subId);
        } else {
            return null;
        }
    }

    /**
     * @description description validates industry id.
     */
    public validateIndustryId(industryId: string) {
        if (industryId === "other") {
            return true;
        } else if (!industryId.includes("_")) {
            return false;
        } else {
            const [indId, subId] = industryId.split("_");
            const fullIndustry = this.getSubIndustryById(indId, subId);
            if (!fullIndustry) {
                return false;
            } else {
                return true;
            }
        }
    }
}

export { Industries, Industry };

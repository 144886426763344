import { Button, SkeletonLoader } from "@soltivo/draw-a-line";
import { ArrowBackward, EditNotification, Calendar, Close, Contacts, Delete24Px, Folder24Px, FlagLine } from "@soltivo/draw-a-line/core/components/icons";
import React, { useContext, useEffect, useState } from "react";
import { headerChangeNotificationsBar } from "../header/reducer/actions";
import { HeaderContext } from "../header/reducer/header.context";
import styles from "./notifications.module.scss";
import { NotificationsMenuProps } from "../header/reducer/reducer";
import NotificationItem from "./components/notifications.item/notifications.item";
import EmptyBlock from "../../blocks/empty/empty.block";
import { ReactComponent as SteveRelax } from "@soltivo/draw-a-line/core/assets/illutrations/SteveRelax.svg";
import NotificationsManage from "./components/notifications.manage/notifications.manage";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { notificationsClearNotifications, notificationsGetAllNotifications, notificationsGetSettings } from "../../../applications/settings/redux/notifications/reducer/actions";
import { _COLORS } from "@soltivo/draw-a-line/core/helpers/colors.styles";
import { Notification } from "@soltivo/types";

interface NotificationsProps extends MapStateType {}

/**
 * @description app notifications side bar
 */
const Notifications: React.FC<NotificationsProps> = ({ notifications, org, clearingNotifications }) => {
    const dispatch = useDispatch();
    const [notificationsList, setNotificationsList] = useState<Array<Notification>>([]);
    const {
        dispatch: dispatchContext,
        state: { notifs },
    } = useContext(HeaderContext);
    const { showNotifications, manageNotifications, selectedMenu } = notifs;

    useEffect(() => {
        if (!org || !showNotifications) return;
        dispatch(notificationsGetAllNotifications());

        dispatch(notificationsGetSettings());
    }, [org, showNotifications]);

    useEffect(() => {
        /**
         * @description filter notification by types
         */
        if (selectedMenu === "all") {
            setNotificationsList(notifications.list);
        } else if (selectedMenu === "calendar") {
            setNotificationsList(notifications.list.filter(item => item.eventType.match(/appointment/i)));
        } else if (selectedMenu === "contacts") {
            setNotificationsList(notifications.list.filter(item => (item.eventType.match(/Lead/i) || item.eventType.match(/client/i) )));
        } 
        // else if (selectedMenu === "emails") {
        //     setNotificationsList(notifications.list.filter(item => item.eventType.match(/email/i)));
        // }
    }, [notifications.list.length, selectedMenu]);

    const serviceList: { key: NotificationsMenuProps; icon: JSX.Element }[] = [
        { key: "all", icon: <Folder24Px /> },
        // { key: "emails", icon: <Email /> },
        { key: "calendar", icon: <Calendar /> },
        { key: "contacts", icon: <Contacts /> },
    ];

    return (
        <div className={`${showNotifications ? styles.app__notifications__show : ""}`} id={`${styles.app__notifications}`}>
            <div className={styles.app__notifications__menu}>
                <Button
                    data-testid="toggleButton"
                    onClick={() => {
                        if (manageNotifications) {
                            dispatchContext(
                                headerChangeNotificationsBar({
                                    notifs: {
                                        ...notifs,
                                        manageNotifications: false,
                                    },
                                })
                            );
                        } else {
                            dispatchContext(
                                headerChangeNotificationsBar({
                                    notifs: {
                                        ...notifs,
                                        showNotifications: false,
                                    },
                                })
                            );
                        }
                    }}
                    className={styles.close__btn}
                    padding={false}
                    outline
                    variant="geyser-700">
                    {manageNotifications ? <ArrowBackward /> : <Close />}
                </Button>

                {!manageNotifications && (
                    <>
                        <div className={styles.applications__list}>
                            {serviceList.map((item) => {
                                const Icon = () => item.icon;
                                return (
                                    <div
                                        key={item.key}
                                        onClick={() => {
                                            dispatchContext(
                                                headerChangeNotificationsBar({
                                                    notifs: {
                                                        ...notifs,
                                                        selectedMenu: item.key,
                                                    },
                                                })
                                            );
                                        }}
                                        className={`${styles.applications__list__item} ${selectedMenu === item.key ? styles.applications__list__item__selected : ""}`}>
                                        <Icon />
                                        <span>{item.key}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            data-testid="manageNotifButton"
                            onClick={() =>
                                dispatchContext(
                                    headerChangeNotificationsBar({
                                        notifs: {
                                            ...notifs,
                                            manageNotifications: true,
                                        },
                                    })
                                )
                            }
                            className={styles.manage__notifications__btn}>
                            <EditNotification />
                            <span>Manage</span>
                        </div>
                    </>
                )}
            </div>

            {manageNotifications ? (
                <NotificationsManage />
            ) : (
                <div key={`${manageNotifications}`} className={styles.app__notifications__content}>
                    {notifications.loading ? (
                        <div className={styles.app__notifications__content__list}>
                            <div className={styles.app__notifications__content__header}>
                                <h6 className="text-geyser-900">Notifications</h6>
                            </div>
                            <div className={styles.app__notifications__content__infos}>
                                {(() => {
                                    let opacity = 120;
                                    return [...Array(5).keys()].map((key) => {
                                        opacity = opacity - 20;
                                        return (
                                            <div data-testidd="loader" key={key} className={styles.skeleton__loader__block}>
                                                <SkeletonLoader
                                                    backgroundColor={`${_COLORS["geyser-200"]}${opacity === 100 ? "" : opacity}`}
                                                    foregroundColor={`${_COLORS["geyser-200"]}${opacity - 10}`}
                                                    height={20}
                                                />
                                                <SkeletonLoader
                                                    backgroundColor={`${_COLORS["geyser-50"]}${opacity === 100 ? "" : opacity}`}
                                                    foregroundColor={`${_COLORS["geyser-50"]}${opacity - 10}`}
                                                    height={16}
                                                />
                                            </div>
                                        );
                                    });
                                })()}
                            </div>
                        </div>
                    ) : notificationsList.length ? (
                        <div className={styles.app__notifications__content__list}>
                            <div className={styles.app__notifications__content__header}>
                                <h6 className="text-geyser-900">Notifications</h6>
                                <Button data-testid="clearButton" disabled={clearingNotifications} onClick={() => {
                                    dispatch(notificationsClearNotifications({ ids: notificationsList.map(item => item.id )}))
                                }} outline padding={false} variant="primary">
                                    <Delete24Px /> Clear all
                                </Button>
                            </div>
                            <div className={styles.app__notifications__content__infos}>
                                {notificationsList.map((item, index) => {
                                    return (
                                    <NotificationItem 
                                        key={index} notification={item} 
                                        closeNotifications={() => dispatchContext(
                                            headerChangeNotificationsBar({
                                                notifs: {
                                                    ...notifs,
                                                    showNotifications: false,
                                                },
                                            })
                                        )} 
                                    />);
                                })}
                                <div className={styles.all__notifs__message}>
                                    <FlagLine />
                                    <p className={styles.all__notifs}>
                                        That's all your notifications <br /> from the last 30 days.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <EmptyBlock svg={SteveRelax} title="You don’t have any notification." description="You've read all your notifications from the last 30 days." />
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ NotificationReducer, OrgReducer }: RootState) => ({
    notifications: NotificationReducer.notifications,
    clearingNotifications: NotificationReducer.clearingNotifications,
    org: OrgReducer.org,
});
type MapStateType = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Notifications);
import React, { useContext, useState } from "react";
import { useDispatch, connect } from "react-redux";
import useError from "../../../../helpers/hooks/useError";
import "../../assets/styles/auth.style.scss";
import { authConfirmSignUp } from "../../redux/auth/reducer/actions";
import { Redirect , useHistory} from "react-router-dom";
import { Button, InputText } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { RootState } from "../../../../redux/reducers";
import { ReactComponent as EnterCodeCaracter } from "@soltivo/draw-a-line/core/assets/illutrations/SteveTypePhone.svg";

interface PhoneValidationCodeProps extends MapStateToProps {}

const PhoneValidationCode: React.FC<PhoneValidationCodeProps> = ({ error }) => {
    const history = useHistory();
    const {
        state: { SUForm: form },
        // dispatch: dispatchContext,
    } = useContext(authContext);
    const dispatch = useDispatch();

    const [submited, setSubmited] = useState(false);
    const CODE_LENGTH = new Array(6).fill(0);
    const [value, setValue] = useState("");
    const [focused, setFocused] = useState(false);
    const inputRef = React.createRef<HTMLInputElement>();

    const handleClick = () => {
        inputRef.current?.focus();
    };

    const handleFocused = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Backspace") {
            setValue(value.slice(0, value.length - 1));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event.target.value;
        setValue(value.length >= CODE_LENGTH.length ? value : (value + _value).slice(0, CODE_LENGTH.length));
    };

    // /**
    //  * @description resends the verification code.
    //  */
    // const onResendCode = () => {
    //     if (submited) {
    //         return;
    //     }
    //     dispatch(authResendCodeSignUp(form));
    // };

    const values = value.split("");

    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

    /**
     * @description on submit code.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(authConfirmSignUp({ ...form, code: value }));
        setSubmited(true);
    };

    useError(error, [() => setSubmited(false)]);

    if (!form.email) {
        return <Redirect to="/sign-up" />;
    }

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Enter the code received</h2>

                        <div className="auth-container__input-container-code" onClick={handleClick}>
                            <InputText
                                type="number"
                                value=""
                                bottomBorderStyle={true}
                                ref={inputRef}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={handleFocused}
                                onBlur={handleBlur}
                                className="auth-container__input-code"
                                style={{
                                    width: "32px",
                                    top: "0px",
                                    bottom: "0px",
                                    left: `${selectedIndex * 50}px`,
                                }}
                            />
                            {CODE_LENGTH.map((v, index) => {
                                const selected = values.length === index;
                                const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                return (
                                    <div key={index} className="auth-container__code-box">
                                        {values[index]}
                                        {(selected || filled) && focused && <div className="shadows" />}
                                    </div>
                                );
                            })}
                        </div>

                        <p className="auth-container__description">An SMS code was sent to {form.phone_number ? `+${form.phone_number}` : "your phone number."}. <br/>
                        Wrong phone number? 
                        <span onClick={() => history.push({
                            pathname: "/phone-validation",
                            state: { changingPhone: true }
                        })}> Click here to change it</span></p>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="auth-container__button-primary" disabled={submited}>
                            Validate
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <EnterCodeCaracter />
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});
type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PhoneValidationCode);

import { CustomFieldItem, EntityForm, ContactType } from "../../contacts";
import { ContactsState } from "./reducer";
import { types } from "./types";

/**
 * @description  change global state in CRM
 * @warning be careful using this action, you can break the app if not used correctly.
 */
export const contactsChangeState = (payload: Partial<ContactsState>) => ({
    type: types.CONTACTS_CHANGE_STATE_REQUEST,
    payload,
});

/**
 * @description get entity (client | lead | supplier)
 */
export const contactsGetEntity = (payload: { entityId: string }) => ({
    type: types.CONTACTS_GET_ENTITY_REQUEST,
    payload,
});

////////LEADS

/**
 * @description create a lead
 */
export const contactsCreateLead = (payload: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    /**
     * @description after done, if successfull it will not move the user to contact profile.
     */
    keepHistory?: boolean;
}) => ({
    type: types.CONTACTS_CREATE_LEAD_REQUEST,
    payload,
});

/**
 * @description Get crm leads
 */
export const contactsGetLeads = (payload?: { lastKey: string }) => ({
    type: types.CONTACTS_GET_LEADS_REQUEST,
    payload,
});

/**
 * @description delete crm lead
 */
export const contactsDeleteLead = (payload: { entityId: string }) => ({
    type: types.CONTACTS_DELETE_LEAD_REQUEST,
    payload,
});

/**
 * @description update crm lead
 */
export const contactsUpdateLead = (payload: EntityForm) => ({
    type: types.CONTACTS_UPDATE_LEAD_REQUEST,
    payload,
});

/**
 * @description Convert lead to client
 */
export const contactsConvertLead = (payload: { entityId: string; redirect?: boolean }) => ({
    type: types.CONTACTS_CONVERT_LEAD_REQUEST,
    payload,
});

////////CIENTS

/**
 * @description Create client
 */
export const contactsCreateClient = (payload: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string
    /**
     * @description after done, if successfull it will not move the user to contact profile.
     */;
    keepHistory?: boolean;
}) => ({
    type: types.CONTACTS_CREATE_CLIENT_REQUEST,
    payload,
});

/**
 * @description Get clients
 */
export const contactsGetClients = (payload?: { lastKey: string }) => ({
    type: types.CONTACTS_GET_CLIENTS_REQUEST,
    payload,
});

/**
 * @description delete crm client
 */
export const contactsDeleteClient = (payload: { entityId: string }) => ({
    type: types.CONTACTS_DELETE_CLIENT_REQUEST,
    payload,
});

/**
 * @description update crm client
 */
export const contactsUpdateClient = (payload: EntityForm) => ({
    type: types.CONTACTS_UPDATE_CLIENT_REQUEST,
    payload,
});

////////SUPPLIER

/**
 * @description Create supplier
 */
export const contactsCreateSupplier = (payload: { firstName: string; lastName: string; email: string; phoneNumber: string }) => ({
    type: types.CONTACTS_CREATE_SUPPLIER_REQUEST,
    payload,
});

/**
 * @description Get contacts
 */
export const contactsGetSuppliers = (payload?: { lastKey: string }) => ({
    type: types.CONTACTS_GET_SUPPLIERS_REQUEST,
    payload,
});

/**
 * @description delete crm supplier
 */
export const contactsDeleteSupplier = (payload: { entityId: string }) => ({
    type: types.CONTACTS_DELETE_SUPPLIER_REQUEST,
    payload,
});

/**
 * @description update crm supplier
 */
export const contactsUpdateSupplier = (payload: EntityForm) => ({
    type: types.CONTACTS_UPDATE_SUPPLIER_REQUEST,
    payload,
});

////////NOTES

/**
 * @description create note
 */
export const contactsCreateNote = (payload: { entityId: string; noteText: string }) => ({
    type: types.CONTACTS_CREATE_NOTE_REQUEST,
    payload,
});

/**
 * @description Get notes
 */
export const contactsGetNotes = (payload: { entityId: string; lastKey?: string }) => ({
    type: types.CONTACTS_GET_NOTES_REQUEST,
    payload,
});

/**
 * @description Update note
 */
export const contactsUpdateNote = (payload: { noteId: string; entityId: string; noteText: string }) => ({
    type: types.CONTACTS_UPDATE_NOTE_REQUEST,
    payload,
});

/**
 * @description create custom fields.
 */
export const contactsCreateCustomFields = (payload: { entityType: ContactType; fields: CustomFieldItem[] }) => ({
    type: types.CONTACTS_CREATE_CUSTOM_FIELDS_REQUEST,
    payload,
});

/**
 * @description get custom fields based on a entity type
 */
export const contactsGetCustomFields = (payload: { entityType: ContactType }) => ({
    type: types.CONTACTS_GET_CUSTOM_FIELDS_REQUEST,
    payload,
});

/**
 * @description search entity
 */
export const contactsSearchEntity = (payload: string) => ({
    type: types.CONTACTS_SEARCH_ENTITY_REQUEST,
    payload,
});

import React, { useContext, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { updateEmployeeAttribute } from "../../redux/auth/reducer/actions";
import useError from "../../../../helpers/hooks/useError";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Button from "@soltivo/draw-a-line/core/components/buttons/button/button";
import authContext from "../../context/auth.context";
import { employeeOnChangeOnboarding } from "../../context/actions";
import { RootState } from "../../../../redux/reducers";
import { ReactComponent as AddInfoImg } from "@soltivo/draw-a-line/core/assets/illutrations/SteveSinging.svg";
import { InputText } from "@soltivo/draw-a-line";

interface EmployeeAddInfoProps extends MapStateToProps {
    error: any;
}

const EmployeeAddInfo: React.FC<EmployeeAddInfoProps> = ({ error }) => {
    const {
        state: { employeeForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);

    const dispatch = useDispatch();
    const [submited, setSubmited] = useState(false);

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatchContext(employeeOnChangeOnboarding({ ...form, [e.target.name]: e.target.value }));

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        //if (submited) { return }
        if (form.firstName && form.lastName) {
            dispatch(
                updateEmployeeAttribute({
                    firstName: form.firstName,
                    lastName: form.lastName,
                    hourlyRate: 0,
                })
            );
        }
        setSubmited(true);
    };

    useError(error, [() => setSubmited(false)]);

    useEffect(() => {
        // const userId = localStorage.getItem("id");
        dispatchContext(employeeOnChangeOnboarding({ ...form, firstName: "", lastName: "" }));
    }, []);

    if (!localStorage.getItem("token")) {
        return <Redirect to="/login" />;
    }
    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Set up your profile</h2>

                        <div className="auth-container__input-container">
                            {Object.keys(form).map((input, index) => {
                                if (input === "firstName" || input === "lastName") {
                                    return (
                                        <InputText
                                            bottomBorderStyle={true}
                                            key={index}
                                            name={input}
                                            onChange={onChange}
                                            value={form[input]}
                                            placeholder={input.replace(/([A-Z])/g, " $1").toLowerCase()}
                                            type={"text"}
                                            autoComplete={"off"}
                                            required
                                            className={`auth-container__input`}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}{" "}
                        </div>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="btn auth-container__button-primary" disabled={submited}>
                            Next
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <AddInfoImg />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(EmployeeAddInfo);

import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { orgSetup, orgTourStep } from "../../../../../../applications/auth/redux/org/reducer/actions";
import { Industries } from "../../../../../../helpers/industry/Industries";
import { guideChangeAction, guideOrgChangeContextState } from "../../../context/actions";
import GuideOrgContext from "../../../context/guide.org.context";
import useGuideHeading from "../../../hooks/useGuideHeading";
import styles from "./step.industry.module.scss";

const Industry = () => {
    const [view, setView] = useState<"industry" | "sub">("industry");
    const dispatch = useDispatch();
    const {
        state: { form, industries },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    const [selectedIndustry, setSelectedIndustry] = useState<Industries["industry"]>();

    const onSelect = (industry: Industries["industry"], sub?: Industries["industry"]["subs"][0]) => {
        setSelectedIndustry(industry);
        dispatchContext(
            guideOrgChangeContextState({
                form: {
                    ...form,
                    // industry format {industryId}_{subId}
                    industry: industries.createIndustryId(industry.id, sub?.id),
                },
            })
        );
    };

    useGuideHeading({
        title: view === "sub" && selectedIndustry ? selectedIndustry.name : "Please select your industry",
    });

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "all",
                payload: {
                    next: {
                        value: "Next",
                        disabled: view === "sub" ? !industries.validateIndustryId(form.industry) : !Boolean(selectedIndustry),
                        onClick:
                            view === "sub"
                                ? () => {
                                      dispatch(
                                          orgSetup({
                                              nextStep: "locations",
                                              data: {
                                                  industry: form.industry,
                                              },
                                          })
                                      );
                                  }
                                : () => {
                                      if (form.industry.toLowerCase() === "other") {
                                          dispatch(
                                              orgSetup({
                                                  nextStep: "locations",
                                                  data: {
                                                      industry: form.industry,
                                                  },
                                              })
                                          );
                                      } else {
                                          setView("sub");
                                      }
                                  },
                    },
                    back: {
                        disabled: false,
                        onClick:
                            view === "sub"
                                ? () => {
                                      setSelectedIndustry(undefined);
                                      setView("industry");
                                  }
                                : () => {
                                      dispatch(
                                          orgTourStep({
                                              stepName: "businessType",
                                          })
                                      );
                                  },
                    },
                },
            })
        );

        setSelectedIndustry(industries.getIndustryById(form.industry.split("_")[0]));
    }, [form.industry, view, selectedIndustry]);

    return (
        <div className={styles.setup__business}>
            <div className="row">
                {selectedIndustry && selectedIndustry.id !== "other" && view === "sub" ? (
                    <div className={styles.subs}>
                        {selectedIndustry.subs.map((sub, index) => {
                            return (
                                <div
                                    data-testid="industryItem"
                                    className={`${form.industry.endsWith(sub.id) ? styles.active : ""}`}
                                    key={index}
                                    onClick={() => {
                                        onSelect(selectedIndustry, sub);
                                    }}>
                                    <p>{sub.name}</p>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={styles.categories}>
                        {industries.getAllIndustries().map((industry, index) => {
                            return (
                                <div
                                    data-testid="industryCategory"
                                    className={`${form.industry.startsWith(industry.id) ? styles.active : ""}`}
                                    key={index}
                                    onClick={() => {
                                        onSelect(industry);
                                    }}>
                                    <p>{industry.name}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Industry;

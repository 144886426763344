import { call, put, takeLatest, select, delay } from "redux-saga/effects";
import { types } from "../reducer/types";
import { toastHelper, popupHelper } from "@soltivo/draw-a-line";
import salesClass from "./sales.class";
import history from "../../../../app/routes/history";
import SalesActions from "../reducer/actions.types";
import * as salesActions from "../reducer/actions";
import apiV2 from "../../../../helpers/api.v2";
import moment from "moment";
import salesValidation from "../../validation/sales.validation";
import { RootState } from "../../../../redux/reducers";
import { MONTH_LABELS } from "../../../../helpers/formatters/time";
import { SalesMetrics } from "../../sales";

/**
 * @description get all transactions (Payments & Refund)
 */
function* salesGetTransactions() {
    try {
        const { data } = yield call(salesClass.salesGetTransactions);
        yield put({ type: types.SALES_GET_TRANSACTIONS_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.SALES_GET_TRANSACTIONS_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.SALES_GET_TRANSACTIONS_FAILURE, payload: error });
    }
}
/**
 * @description get payments
 */
function* salesGetPayments() {
    try {
        const { data } = yield call(salesClass.salesGetPayments);
        yield put({ type: types.SALES_GET_PAYMENTS_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.SALES_GET_PAYMENTS_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.SALES_GET_PAYMENTS_FAILURE, payload: error });
    }
}

/**
 * @description get single sales item
 */
function* salesGetTransaction({ payload }: SalesActions["salesGetTransaction"]) {
    try {
        const { data } = yield call(salesClass.salesGetTransaction, payload);
        yield put({ type: types.SALES_GET_TRANSACTION_SUCCESS, payload: data });
    } catch (error: any) {
        apiV2.toastAllErrors(error);
        yield put({ type: types.SALES_GET_TRANSACTION_FAILURE, payload: error });
    }
}

/**
 * @description Refund invoice
 */
function* salesCreateRefund({ payload }: SalesActions["salesCreateRefund"]) {
    try {
        const { nextAction, refund } = payload;
        const { data } = yield call(salesClass.salesCreateRefund, refund);
        yield put({ type: types.SALES_CREATE_REFUND_SUCCESS, payload: data });
        if(nextAction === "send") {
            history.push(`/administration/sales/refunds/${data.id}`);
            yield put({ type: types.SALES_GET_REFUND_REQUEST, payload: { refundId: data.id } });
        } else {
            history.push("/administration/sales/transactions");
        }
        toastHelper.toastStartContent("success", "Refund created successfully");
        yield call(fetchSalesMetrics);
    } catch (error) {
        yield put({ type: types.SALES_CREATE_REFUND_FAILURE, payload: error });
    }
}

/**
 * @description create online refund
 */
function* salesCreateOnlineRefund({ payload }: SalesActions["salesCreateOnlineRefund"]) {
    try {
        const { data } = yield call(salesClass.salesCreateOnlineRefund, payload);
        yield put({ type: types.SALES_CREATE_ONLINE_REFUND_SUCCESS, payload: data });
        yield put({ type: types.SALES_GET_TRANSACTION_REQUEST, payload: { transactionId: payload.paymentId }});
        toastHelper.toastStartContent("success", "Refund created successfully");
        popupHelper.popupEnd();
        yield call(fetchSalesMetrics);
    } catch (error) {
        yield put({ type: types.SALES_CREATE_REFUND_FAILURE, payload: error });
    }
}

/**
 * @description create online refund
 */
function* salesCreateTerminalRefund({ payload }: SalesActions["salesCreateTerminalRefund"]) {
    try {
        const { data } = yield call(salesClass.salesCreateTerminalRefund, payload);
        yield put({ type: types.SALES_CREATE_TERMINAL_REFUND_SUCCESS, payload: data });
        yield put({ type: types.SALES_GET_TRANSACTION_REQUEST, payload: { transactionId: payload.paymentId }});
        toastHelper.toastStartContent("success", "Refund created successfully");
        popupHelper.popupEnd();
        yield call(fetchSalesMetrics);
    } catch (error) {
        yield put({ type: types.SALES_CREATE_REFUND_FAILURE, payload: error });
    }
}

/**
 * @description Update invoice note
 */
function* salesUpdateInvoiceNote({ payload }: SalesActions["salesUpdateInvoiceNote"]) {
    try {
        salesValidation.salesUpdateInvoiceNote(payload);

        yield call(salesClass.salesUpdateInvoiceNote, payload);

        const mapStateToProps = ({ SalesReducer }: RootState) => ({
            transaction: SalesReducer.transaction,
        });
        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { transaction }: MapStateToProps = yield select(mapStateToProps);
        yield put({ type: types.SALES_UPDATE_INVOICE_NOTE_SUCCESS, payload: { ...transaction, note: payload.note } });
        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", "Note updated successfully.");
    } catch (error) {
        yield put({ type: types.SALES_UPDATE_INVOICE_NOTE_FAILURE, payload: error });
    }
}

/**
 * @description Verify Stripe Connect
 */
function* salesVerifyStripeConnect() {
    try {
        yield call(salesClass.salesVerifyStripeConnect);
        yield put({ type: types.SALES_VERIFY_STRIPE_CONNECT_SUCCESS, payload: "completed" });
    } catch (error: any) {
        if (error?.error) {
            if (error?.error?.code === 404) {
                yield put({ type: types.SALES_VERIFY_STRIPE_CONNECT_SUCCESS, payload: "not-started" });
            } else if (error?.error?.code === 401) {
                yield put({ type: types.SALES_VERIFY_STRIPE_CONNECT_SUCCESS, payload: "started" });
            }
        }
        yield put({ type: types.SALES_VERIFY_STRIPE_CONNECT_FAILURE, payload: error });
    }
}

/**
 * @description Verify Stripe Connect
 */
function* salesLinkStripeConnect() {
    try {
        const { data } = yield call(salesClass.salesLinkStripeConnect);
        yield put({ type: types.SALES_LINK_STRIPE_CONNECT_SUCCESS });
        window.open(data.link, "_blank")?.focus();
    } catch (error: any) {
        apiV2.toastAllErrors(error);
        yield put({ type: types.SALES_LINK_STRIPE_CONNECT_FAILURE, payload: error });
    }
}

/**
 * @description Sales get metrics
 */
function* salesGetLastSixMonthSales({ payload }: { payload: { lastMonth: string, last6Month: string }; type: string }) {
    try {
        const { data: lastMonthSalesMetrics } = yield call(salesClass.salesGetLastSixMonthSales, payload);

        const metrics: SalesMetrics = lastMonthSalesMetrics.map((item: any) => ({
            // date return is 2021-11
            label: MONTH_LABELS[parseInt(item.month.split('-')[1], 10) - 1],
            income: item.income
        }));

        yield put({
            type: types.SALES_GET_SIX_MONTHS_SALES_SUCCESS,
            payload: {
                lastMonthSalesMetrics: metrics,
            },
        });
    } catch (error: any) {
        yield put({ type: types.SALES_GET_SIX_MONTHS_SALES_FAILURE, payload: error });
    }
}

/**
 * @description Sales get montly income
 */
function* salesGetMonthlyIncome({ payload }: SalesActions["salesGetMonthlyIncome"]) {
    try {
        const { data } = yield call(salesClass.salesGetMonthlyIncome, payload);
        const metrics: SalesMetrics = data.map((item: any) => ({
            label: `${moment(item.date).format("MMM DD")}`,
            income: item.income,
        }));
        yield put({ type: types.SALES_GET_MONTHLY_INCOME_SUCCESS, payload: metrics });
    } catch (error: any) {
        yield put({ type: types.SALES_GET_MONTHLY_INCOME_FAILURE, payload: error });
    }
}

/**
 * @description Sales get weekly income
 */
function* salesGetWeeklyIncome({ payload }: SalesActions["salesGetWeeklyIncome"]) {
    try {
        const { data } = yield call(salesClass.salesGetWeeklyIncome, payload);
        const metrics: SalesMetrics = data.map((item: any) => ({
            label: `${moment(item.date).format("ddd")}.`,
            income: item.income,
        }));

        yield put({ type: types.SALES_GET_WEEKLY_INCOME_SUCCESS, payload: metrics });
    } catch (error: any) {
        yield put({ type: types.SALES_GET_WEEKLY_INCOME_FAILURE, payload: error });
    }
}

/**
 * @description refetch metrics
 */
function* fetchSalesMetrics() {
    // Update the metrics
    const today = moment().format("YYYY-MM-DD");
    const startDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    yield put(salesActions.salesGetWeeklyIncome({ startDate, endDate: today }));
    const paidStartDate = moment().subtract(30, 'days').format("YYYY-MM-DD"); // To get the payments metrics
    const unPaidStartDate = moment().subtract(365, 'days').format("YYYY-MM-DD"); // To get the unpaid metrics
    yield put(salesActions.salesGetTransactionsMetrics({ date: moment().format("YYYY-MM-DD"), unPaidStartDate, paidStartDate }));
}

/**
 * @description Sales get transactions metrics
 */
 function* salesGetTransactionsMetrics({ payload }: SalesActions["salesGetTransactionsMetrics"]) {
    try {
        const { date, unPaidStartDate, paidStartDate } = payload;
        const { data: unpaidInvoiceMetrics } = yield call(salesClass.salesGetUnpaidMetrics, { startDate: unPaidStartDate, endDate: date });
        const { data: transactionsMetrics } = yield call(salesClass.salesGetTransactionsMetrics, { startDate: paidStartDate, endDate: date });

        yield put({
            type: types.SALES_GET_TRANSACTIONS_METRICS_SUCCESS,
            payload: {
                unpaidInvoiceMetrics,
                transactionsMetrics,
            },
        });
    } catch (error: any) {
        yield put({ type: types.SALES_GET_TRANSACTIONS_METRICS_FAILURE, payload: error });
    }
}

/**
 * @description Create invoice
 */
function* salesCreateInvoice({ payload }: SalesActions["salesCreateInvoice"]) {
    try {
        const { nextAction, invoice } = payload;
        const { data } = yield call(salesClass.salesCreateInvoice, invoice);
        if(nextAction === "close") {
            history.push(`/administration/sales/invoices`);
        } else if(nextAction === "send") {
            yield put({ type: types.SALES_GET_INVOICE_REQUEST, payload: { invoiceId: data.id } });
            history.push(`/administration/sales/invoices/${data.id}`);
        } else if(nextAction === "recordPayment") {
            history.push({
                pathname: `/administration/sales/payments`,
                state: { invoice: data }
            });
        }
        yield put({ type: types.SALES_CREATE_INVOICE_SUCCESS, payload: data });
        yield call(fetchSalesMetrics);
        toastHelper.toastStartContent('success', "Invoice created successfully.");
    } catch (error) {
        yield put({ type: types.SALES_CREATE_INVOICE_FAILURE, payload: error });
    }
}

/**
 * @description update invoice
 */
function* salesUpdateInvoice({ payload }: SalesActions["salesUpdateInvoice"]) {
    try {
        const { data } = yield call(salesClass.salesUpdateInvoice, payload);
        yield put({ type: types.SALES_UPDATE_INVOICE_SUCCESS, payload: data });
        if (payload.nextAction === "close") {
            history.push(`/administration/sales/invoices`);
        } else if (payload.nextAction === "send") {
            yield put({ type: types.SALES_SEND_EMAIL_REQUEST, payload: { invoiceId: payload.invoiceId, showToast: false } });
        }
        toastHelper.toastStartContent("success", "Invoice updated successfully.");
        popupHelper.popupEnd();
        yield call(fetchSalesMetrics);
    } catch (error) {
        yield put({ type: types.SALES_UPDATE_INVOICE_FAILURE, payload: error });
    }
}

/**
 * @description List invoices
 */
function* salesGetInvoices() {
    try {
        const { data } = yield call(salesClass.salesGetInvoices);
        yield put({ type: types.SALES_GET_INVOICES_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_INVOICES_FAILURE, payload: error });
    }
}

/**
 * @description Get invoice
 */
function* salesGetInvoice({ payload }: { payload: { invoiceId: string }; type: string }) {
    try {
        const { data } = yield call(salesClass.salesGetInvoice, payload);
        yield put({ type: types.SALES_GET_INVOICE_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_INVOICE_FAILURE, payload: error });
    }
}

/**
 * @description Create payment
 */
function* salesCreatePayment({ payload }: SalesActions["salesCreatePayment"]) {
    try {
        const { nextAction, paymentData } = payload;
        const { data } = yield call(salesClass.salesCreatePayment, paymentData);
        if (nextAction === "close") {
            history.push(`/administration/sales/transactions`);
        } else if(nextAction === "send") {
            history.push(`/administration/sales/payments/${data.id}`);
            yield put(salesActions.salesGetTransaction({ transactionId: data.id }));
        } else if (nextAction === "new") {
            window.location.reload();
        }
        yield put({ type: types.SALES_CREATE_PAYMENT_SUCCESS });
        toastHelper.toastStartContent("success", "Payment created successfully.");
        yield call(fetchSalesMetrics);
    } catch (error: any) {
        apiV2.toastAllErrors(error);
        yield put({ type: types.SALES_CREATE_PAYMENT_FAILURE, payload: error });
    }
}

/**
 * @description update invoice
 */
function* salesUpdatePayment({ payload }: SalesActions["salesUpdatePayment"]) {
    try {
        yield call(salesClass.salesUpdatePayment, payload);
        yield put({ type: types.SALES_UPDATE_PAYMENT_SUCCESS });
        if (payload.nextAction === "close") {
            history.push(`/administration/sales/transactions`);
        } else if(payload.nextAction === "send") {
            yield put(salesActions.salesSendEmail({ id: payload.transactionId, type: "payments", showToast: false }));
        }
        yield call(fetchSalesMetrics);
        toastHelper.toastStartContent("success", "Payment successfully updated.");
    } catch (error: any) {
        apiV2.toastAllErrors(error);
        yield put({ type: types.SALES_UPDATE_PAYMENT_FAILURE, payload: error });
    }
}

/**
 * @description void payment
 */
function* salesVoidPayment({ payload }: SalesActions["salesVoidPayment"]) {
    try {
        yield call(salesClass.salesVoidPayment, payload);
        yield put({ type: types.SALES_VOID_PAYMENT_SUCCESS });
        yield put({ type: types.SALES_GET_TRANSACTION_REQUEST, payload: { transactionId: payload.paymentId } });
        toastHelper.toastStartContent("success", "Payment successfully voided.");
    } catch (error: any) {
        apiV2.toastAllErrors(error);
        yield put({ type: types.SALES_VOID_PAYMENT_FAILURE, payload: error });
    }
}

/**
 * @description get invoices inside a payment
 */
function* salesGetInvoicesInPayment({ payload }: { payload: { paymentId: string }; type: string }) {
    try {
        const { data } = yield call(salesClass.salesGetInvoicesInPayment, payload);
        yield put({ type: types.SALES_GET_INVOICES_IN_PAYMENT_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_INVOICES_IN_PAYMENT_FAILURE, payload: error });
    }
}

/**
 * @description generate next invoice number
 */
function* salesGetNextInvoiceNumber() {
    try {
        const { data } = yield call(salesClass.salesGetNextInvoiceNumber);
        yield put({ type: types.SALES_GET_NEXT_INVOICE_NUMBER_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_NEXT_INVOICE_NUMBER_FAILURE, payload: error });
    }
}

/**
 * @description generate next payment number
 */
function* salesGetNextPaymentNumber() {
    try {
        const { data } = yield call(salesClass.salesGetNextPaymentNumber);
        yield put({ type: types.SALES_GET_NEXT_PAYMENT_NUMBER_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_NEXT_PAYMENT_NUMBER_FAILURE, payload: error });
    }
}

/**
 * @description generate next refund number
 */
function* salesGetNextRefundNumber() {
    try {
        const { data } = yield call(salesClass.salesGetNextRefundNumber);
        yield put({ type: types.SALES_GET_NEXT_REFUND_NUMBER_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_NEXT_REFUND_NUMBER_FAILURE, payload: error });
    }
}

/**
 * @description get configurations
 */
function* salesGetConfigurations() {
    try {
        yield delay(1500);
        const { data } = yield call(salesClass.salesGetConfigurations);
        yield put({ type: types.SALES_GET_CONFIGURATIONS_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_CONFIGURATIONS_FAILURE, payload: error });
    }
}

/**
 * @description Update configurations
 */
function* salesUpdateConfigurations({ payload }: SalesActions["salesUpdateConfigurations"]) {
    try {
        yield delay(1500);
        const { data } = yield call(salesClass.salesUpdateConfigurations, payload);
        yield put({ type: types.SALES_UPDATE_CONFIGURATIONS_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_UPDATE_CONFIGURATIONS_FAILURE, payload: error });
    }
}

/**
 * @description create sales tax
 */
function* salesCreateTax({ payload }: SalesActions["salesCreateTax"]) {
    try {
        yield call(salesClass.salesCreateTax, payload);
        yield put({ type: types.SALES_CREATE_TAX_SUCCESS });
        yield put({ type: types.SALES_GET_ALL_TAXES_REQUEST });
        toastHelper.toastStartContent("success", "Tax successfully created.");
        popupHelper.popupEnd();
    } catch (error) {
        yield put({ type: types.SALES_CREATE_TAX_FAILURE, payload: error });
    }
}

/**
 * @description Update sales tax
 */
function* salesUpdateTax({ payload }: SalesActions["salesUpdateTax"]) {
    try {
        const { data } = yield call(salesClass.salesUpdateTax, payload);
        const mapStateToProps = ({ SalesReducer }: RootState) => ({
            taxes: SalesReducer.taxes,
        });
        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { taxes }: MapStateToProps = yield select(mapStateToProps);
        const updatedTaxes = taxes.map((tax) => {
            if (tax.id === data.id) tax = data;
            return tax;
        });
        yield put({ type: types.SALES_UPDATE_TAX_SUCCESS, payload: updatedTaxes });
        toastHelper.toastStartContent("success", "Tax successfully updated.");
        popupHelper.popupEnd();
    } catch (error) {
        yield put({ type: types.SALES_UPDATE_TAX_FAILURE, payload: error });
    }
}

/**
 * @description update refund
 */
function* salesUpdateRefund({ payload }: SalesActions["salesUpdateRefund"]) {
    try {
        yield call(salesClass.salesUpdateRefund, payload);
        yield put({ type: types.SALES_UPDATE_REFUND_SUCCESS });
        if (payload.nextAction === "close") {
            history.push("/administration/sales/transactions");
        } else if(payload.nextAction === "send") {
            yield put(salesActions.salesSendEmail({ id: payload.refundId, type: "refunds", showToast: false }));
        }
        yield call(fetchSalesMetrics);
        toastHelper.toastStartContent("success", "Refund successfully updated.");
    } catch (error) {
        yield put({ type: types.SALES_UPDATE_REFUND_FAILURE, payload: error });
    }
}

/**
 * @description void refund
 */
function* salesVoidRefund({ payload }: SalesActions["salesVoidRefund"]) {
    try {
        yield call(salesClass.salesVoidRefund, payload);
        yield put({ type: types.SALES_GET_REFUND_REQUEST, payload: { refundId: payload.refundId } });
        yield put({ type: types.SALES_VOID_REFUND_SUCCESS });
        toastHelper.toastStartContent('success', 'Refund successfully voided.');
    } catch (error: any) {
        yield put({ type: types.SALES_VOID_REFUND_FAILURE, payload: error });
        apiV2.toastAllErrors(error);
    }
}

/**
 * @description void invoice
 */
function* salesVoidInvoice({ payload }: SalesActions['salesVoidInvoice']) {
    try {
        yield call(salesClass.salesVoidInvoice, payload);
        yield put({ type: types.SALES_GET_INVOICE_REQUEST, payload: { invoiceId: payload.invoiceId } });
        yield put({ type: types.SALES_VOID_INVOICE_SUCCESS });
        toastHelper.toastStartContent('success', 'Invoice successfully voided.');
    } catch (error: any) {
        yield put({ type: types.SALES_VOID_INVOICE_FAILURE, payload: error });
        apiV2.toastAllErrors(error);
    }
}

/**
 * @description get refund
 */
function* salesGetRefund({ payload }: SalesActions["salesGetRefund"]) {
    try {
        const { data } = yield call(salesClass.salesGetRefund, payload);
        yield put({ type: types.SALES_GET_REFUND_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_REFUND_FAILURE, payload: error });
    }
}

/**
 * @description send invoice
 */
function* salesSendEmail({ payload }: SalesActions["salesSendEmail"]) {
    try {
        yield call(salesClass.salesSendEmail, payload);
        yield put({ type: types.SALES_SEND_EMAIL_SUCCESS });
        if (payload.showToast) {
            toastHelper.toastStartContent("success", "Invoice sent successfully.");
        }
    } catch (error) {
        yield put({ type: types.SALES_SEND_EMAIL_FAILURE, payload: error });
    }
}

/**
 * @description get taxes
 */
function* salesGetAllTaxes() {
    try {
        const { data } = yield call(salesClass.salesGetAllTaxes);
        yield put({ type: types.SALES_GET_ALL_TAXES_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_ALL_TAXES_FAILURE, payload: error });
    }
}

/**
 * @description get tax
 */
function* salesGetTax({ payload }: SalesActions["salesGetTax"]) {
    try {
        const { data } = yield call(salesClass.salesGetTax, payload);
        yield put({ type: types.SALES_GET_TAX_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_TAX_FAILURE, payload: error });
    }
}

/**
 * @description calculate invoice taxes
 */
function* salesInvoiceCalculator({ payload }: SalesActions["salesInvoiceCalculator"]) {
    try {
        yield delay(1000);
        const { data } = yield call(salesClass.salesInvoiceCalculator, payload);
        yield put({ type: types.SALES_INVOICE_CALCULATOR_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_INVOICE_CALCULATOR_FAILURE, payload: error });
    }
}

/**
 * @description update business number
 */
function* salesUpdateBusinessNumber({ payload }: SalesActions["salesUpdateBusinessNumber"]) {
    try {
        yield delay(1500);
        yield call(salesClass.salesUpdateBusinessNumber, payload);
        yield put({ type: types.SALES_GET_CONFIGURATIONS_REQUEST });
        yield put({ type: types.SALES_UPDATE_BUSINESS_NUMBER_SUCCESS });
    } catch (error) {
        yield put({ type: types.SALES_UPDATE_BUSINESS_NUMBER_FAILURE, payload: error });
    }
}

/**
 * @description Search invoices
 */
function* salesSearchInvoices({ payload }: SalesActions["salesSearchInvoices"]) {
    try {
        const { data } = yield call(salesClass.salesSearchInvoices, payload);
        yield put({ type: types.SALES_SEARCH_INVOICES_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_SEARCH_INVOICES_FAILURE, payload: error });
    }
}

/**
 * @description register terminal
 */
 function* salesRegisterTerminal({ payload }: SalesActions["salesRegisterTerminal"]) {
    const { terminal, loadTerminals } = payload;
    try {
        yield call(salesClass.salesRegisterTerminal, payload);
        yield put({ type: types.SALES_LIST_STRIPE_LOCATIONS_REQUEST });
        yield put({ type: types.SALES_REGISTER_TERMINAL_SUCCESS });
        if(loadTerminals) {
            yield put({ type: types.SALES_GET_LOCATION_TERMINALS_REQUEST, payload: { stripeLocationId: terminal.stripeLocationId } });
        }
    } catch (error) {
        yield put({ type: types.SALES_REGISTER_TERMINAL_FAILURE, payload: error });
    }
}

/**
 * @description List stripe locations
 */
 function* salesGetStripeLocations() {
    try {
        const { data } = yield call(salesClass.salesGetStripeLocations);
        yield put({ type: types.SALES_LIST_STRIPE_LOCATIONS_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_LIST_STRIPE_LOCATIONS_FAILURE, payload: error });
    }
}

/**
 * @description Create stripe location
 */
 function* salesCreateStripeLocation({ payload }: SalesActions["salesCreateStripeLocation"]) {
    try {
        yield call(salesClass.salesCreateStripeLocation, payload);
        yield put({ type: types.SALES_LIST_STRIPE_LOCATIONS_REQUEST });
        yield put({ type: types.SALES_CREATE_STRIPE_LOCATIONS_SUCCESS });
        toastHelper.toastStartContent("success", "Stripe location successfully created.");
        popupHelper.popupEnd();
    } catch (error) {
        yield put({ type: types.SALES_CREATE_STRIPE_LOCATIONS_SUCCESS, payload: error });
    }
}

/**
 * @description List terminals in locations
 */
 function* salesGetLocationTerminals({ payload }:  SalesActions["salesGetLocationTerminals"]) {
    try {
        const { data } = yield call(salesClass.salesGetLocationTerminals, payload);
        yield put({ type: types.SALES_GET_LOCATION_TERMINALS_SUCCESS, payload: data });
    } catch (error) {
        yield put({ type: types.SALES_GET_LOCATION_TERMINALS_FAILURE, payload: error });
    }
}

/**
 * @description Update terminal label
 */
 function* salesEditTerminalLabel({ payload }: SalesActions["salesEditTerminalLabel"]) {
    try {
        yield call(salesClass.salesEditTerminalLabel, payload);
        yield put({ type: types.SALES_EDIT_TERMINAL_LABEL_SUCCESS });
        toastHelper.toastStartContent("success", "Label successfully created.");
    } catch (error) {
        yield put({ type: types.SALES_EDIT_TERMINAL_LABEL_FAILURE, payload: error });
    }
}

/**
 * @description remove terminal
 */
 function* salesDeleteTerminal({ payload }: SalesActions["salesDeleteTerminal"]) {
    try {
        yield call(salesClass.salesDeleteTerminal, payload);
        yield put({ type: types.SALES_REMOVE_TERMINAL_SUCCESS });
        toastHelper.toastStartContent("success", "Terminal successfully deleted.");
        popupHelper.popupEnd();
    } catch (error) {
        yield put({ type: types.SALES_REMOVE_TERMINAL_FAILURE, payload: error });
    }
}

/**
 * @description check if terminal is configured for an org
 */
 function* salesCheckTerminalConfiguration() {
    try {
        const { data } = yield call(salesClass.salesCheckTerminalConfiguration);
        yield put({ type: types.SALES_VERIFY_TERMINAL_CONFIGURATION_SUCCESS, payload: data.configured });
    } catch (error) {
        yield put({ type: types.SALES_VERIFY_TERMINAL_CONFIGURATION_FAILURE, payload: error });
    }
}

/**
 * @description delete terminal location
 */
 function* salesDeleteStripeLocation({ payload }: SalesActions["salesDeleteStripeLocation"]) {
    try {
        yield call(salesClass.salesDeleteStripeLocation, payload);
        yield put({ type: types.SALES_REMOVE_STRIPE_LOCATIONS_SUCCESS });
        history.push("/administration/sales/settings/terminal");
    } catch (error) {
        yield put({ type: types.SALES_REMOVE_STRIPE_LOCATIONS_SUCCESS, payload: error });
    }
 }
 
/**
 * @description description create payment object
 */
 function* salesCreatePaymentObject({ payload }: SalesActions["salesCreatePaymentObject"]) {
    try {
        yield call(salesClass.salesCreatePaymentObject, payload);
        yield put({ type: types.SALES_GET_INVOICE_REQUEST, payload: { invoiceId: payload.invoiceId } });
        yield put({ type: types.SALES_CREATE_PAYMENT_OBJECT_SUCCESS });
    } catch (error) {
        yield put({ type: types.SALES_CREATE_PAYMENT_OBJECT_FAILURE, payload: error });
    }
}


export default function* SalesSaga() {
    yield takeLatest(types.SALES_GET_TRANSACTIONS_REQUEST, salesGetTransactions);
    yield takeLatest(types.SALES_GET_PAYMENTS_REQUEST, salesGetPayments);
    yield takeLatest(types.SALES_GET_TRANSACTION_REQUEST, salesGetTransaction);
    yield takeLatest(types.SALES_UPDATE_INVOICE_NOTE_REQUEST, salesUpdateInvoiceNote);
    yield takeLatest(types.SALES_VERIFY_STRIPE_CONNECT_REQUEST, salesVerifyStripeConnect);
    yield takeLatest(types.SALES_LINK_STRIPE_CONNECT_REQUEST, salesLinkStripeConnect);
    yield takeLatest(types.SALES_GET_SIX_MONTHS_SALES_REQUEST, salesGetLastSixMonthSales);
    yield takeLatest(types.SALES_CREATE_INVOICE_REQUEST, salesCreateInvoice);
    yield takeLatest(types.SALES_CREATE_REFUND_REQUEST, salesCreateRefund);
    yield takeLatest(types.SALES_GET_INVOICE_REQUEST, salesGetInvoice);
    yield takeLatest(types.SALES_GET_INVOICES_REQUEST, salesGetInvoices);
    yield takeLatest(types.SALES_UPDATE_INVOICE_REQUEST, salesUpdateInvoice);
    yield takeLatest(types.SALES_GET_NEXT_INVOICE_NUMBER_REQUEST, salesGetNextInvoiceNumber);
    yield takeLatest(types.SALES_CREATE_PAYMENT_REQUEST, salesCreatePayment);
    yield takeLatest(types.SALES_UPDATE_PAYMENT_REQUEST, salesUpdatePayment);
    yield takeLatest(types.SALES_GET_INVOICES_IN_PAYMENT_REQUEST, salesGetInvoicesInPayment);
    yield takeLatest(types.SALES_GET_NEXT_PAYMENT_NUMBER_REQUEST, salesGetNextPaymentNumber);
    yield takeLatest(types.SALES_GET_NEXT_REFUND_NUMBER_REQUEST, salesGetNextRefundNumber);
    yield takeLatest(types.SALES_GET_CONFIGURATIONS_REQUEST, salesGetConfigurations);
    yield takeLatest(types.SALES_UPDATE_CONFIGURATIONS_REQUEST, salesUpdateConfigurations);
    yield takeLatest(types.SALES_CREATE_TAX_REQUEST, salesCreateTax);
    yield takeLatest(types.SALES_UPDATE_TAX_REQUEST, salesUpdateTax);
    yield takeLatest(types.SALES_UPDATE_REFUND_REQUEST, salesUpdateRefund);
    yield takeLatest(types.SALES_VOID_REFUND_REQUEST, salesVoidRefund);
    yield takeLatest(types.SALES_VOID_INVOICE_REQUEST, salesVoidInvoice);
    yield takeLatest(types.SALES_GET_REFUND_REQUEST, salesGetRefund);
    yield takeLatest(types.SALES_SEND_EMAIL_REQUEST, salesSendEmail);
    yield takeLatest(types.SALES_GET_ALL_TAXES_REQUEST, salesGetAllTaxes);
    yield takeLatest(types.SALES_GET_TAX_REQUEST, salesGetTax);
    yield takeLatest(types.SALES_INVOICE_CALCULATOR_REQUEST, salesInvoiceCalculator);
    yield takeLatest(types.SALES_UPDATE_BUSINESS_NUMBER_REQUEST, salesUpdateBusinessNumber);
    yield takeLatest(types.SALES_SEARCH_INVOICES_REQUEST, salesSearchInvoices);
    yield takeLatest(types.SALES_GET_MONTHLY_INCOME_REQUEST, salesGetMonthlyIncome);
    yield takeLatest(types.SALES_GET_WEEKLY_INCOME_REQUEST, salesGetWeeklyIncome);
    yield takeLatest(types.SALES_GET_TRANSACTIONS_METRICS_REQUEST, salesGetTransactionsMetrics);
    yield takeLatest(types.SALES_REGISTER_TERMINAL_REQUEST, salesRegisterTerminal);
    yield takeLatest(types.SALES_LIST_STRIPE_LOCATIONS_REQUEST, salesGetStripeLocations);
    yield takeLatest(types.SALES_CREATE_STRIPE_LOCATIONS_REQUEST, salesCreateStripeLocation);
    yield takeLatest(types.SALES_GET_LOCATION_TERMINALS_REQUEST, salesGetLocationTerminals);
    yield takeLatest(types.SALES_EDIT_TERMINAL_LABEL_REQUEST, salesEditTerminalLabel);
    yield takeLatest(types.SALES_REMOVE_TERMINAL_REQUEST, salesDeleteTerminal);
    yield takeLatest(types.SALES_VERIFY_TERMINAL_CONFIGURATION_REQUEST, salesCheckTerminalConfiguration);
    yield takeLatest(types.SALES_REMOVE_STRIPE_LOCATIONS_REQUEST, salesDeleteStripeLocation);
    yield takeLatest(types.SALES_CREATE_PAYMENT_OBJECT_REQUEST, salesCreatePaymentObject);
    yield takeLatest(types.SALES_VOID_PAYMENT_REQUEST, salesVoidPayment);
    yield takeLatest(types.SALES_CREATE_ONLINE_REFUND_REQUEST, salesCreateOnlineRefund);
    yield takeLatest(types.SALES_CREATE_TERMINAL_REFUND_REQUEST, salesCreateTerminalRefund);
}

import { InputRadio } from "@soltivo/draw-a-line";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Organization } from "../../../../../../applications/auth/organization";
import { orgSetup } from "../../../../../../applications/auth/redux/org/reducer/actions";
import { guideChangeAction, guideOrgChangeFormState } from "../../../context/actions";
import GuideOrgContext from "../../../context/guide.org.context";
import useGuideHeading from "../../../hooks/useGuideHeading";
import styles from "./step.business.module.scss";

const Business = () => {
    const dispatch = useDispatch();
    const {
        state: { form },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    disabled: !form.businessType,
                    onClick: () => {
                        dispatch(
                            orgSetup({
                                nextStep: "industry",
                                data: {
                                    businessType: form.businessType,
                                },
                            })
                        );
                    },
                },
            })
        );
    }, [form.businessType]);

    useGuideHeading({
        title: "What best describes your business?",
    });

    const onChange = (businessType: Organization["businessType"]) => {
        dispatchContext(
            guideOrgChangeFormState({
                businessType: businessType,
            })
        );
    };

    return (
        <div className={styles.setup__business}>
            <div className="org__description-radio">
                <InputRadio type="radio" name="individual" checked={form.businessType === "individual"} onChange={() => onChange("individual")} />
                <div className="input__info">
                    <h6 className="text-geyser-900">Individual</h6>
                    <p className="text-geyser-500">You are a solopreneur that has no employee yet.</p>
                </div>
            </div>
            <div className="org__description-radio">
                <InputRadio type="radio" name="business" checked={form.businessType === "business"} onChange={() => onChange("business")} />
                <div className="input__info">
                    <h6 className="text-geyser-900">Business</h6>
                    <p className="text-geyser-500">You have a team of 2 or more people.</p>
                </div>
            </div>
            <div className="org__description-radio">
                <InputRadio type="radio" name="multi" checked={form.businessType === "multi-organization"} onChange={() => onChange("multi-organization")} />
                <div className="input__info">
                    <h6 className="text-geyser-900">Multi Location Business</h6>
                    <p className="text-geyser-500">You have multiple points of sales with many employees.</p>
                </div>
            </div>
        </div>
    );
};

export default Business;

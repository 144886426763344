import { FC, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { Button, popupHelper} from "@soltivo/draw-a-line";
import { appointmentUpdateStatus } from "../../redux/reducer/actions";
import { PopupState } from "@soltivo/draw-a-line/core/components/popup/reducer/reducer";
import { getEntityBasicInfo } from "../../helpers/utils";
import { Appointment } from "@soltivo/types";

interface PopupNotShowProps {
    appointment: Appointment;
    footer: ((
        children: (
          props: Pick<
            PopupState,
            | "show"
            | "actions"
            | "removeActions"
            | "animation"
            | "width"
            | "cancelEscEvent"
          >
        ) => JSX.Element | React.ReactNode
      ) => JSX.Element | React.ReactNode) | undefined;
}

const PopupNotShow: FC<PopupNotShowProps> = ({ footer, appointment }) => {
    const dispatch = useDispatch();
    const submitRef = useRef<HTMLButtonElement>(null);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        dispatch(
            appointmentUpdateStatus({
                appointmentId: appointment.id,
                moveTo: "not_show",
            })
        );
        popupHelper.popupEnd()
    };

    return (
        <>
        <div className="popup__cancel-apt">
            <form onSubmit={onSubmit}>
                <p className="ml-10 text-15 text-geyser-900">
                    {`Do you want to notify ${getEntityBasicInfo(appointment.entityInfo).fullname} about not show ?`}
                </p>
                <button ref={submitRef} type="submit" style={{ display: "none" }}></button>
            </form>
        </div>
        {footer && footer(() => (
            <div>
                <Button onClick={() => submitRef.current?.click()} variant="geyser-500" outline border={false} padding={false}>
                    No don’t
                </Button>
                <Button onClick={() => submitRef.current?.click()} variant="primary" disabled={false}>
                    Yes notify
                </Button>
            </div>
        ))}
        </>
    );
};
const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
    loading: AppointmentReducer.deletingStatus,
});

export default connect(mapStateToProps)(PopupNotShow);

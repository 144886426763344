import { FC, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { appointmentDeleteAppointment } from "../../redux/reducer/actions";
import { PopupState } from "@soltivo/draw-a-line/core/components/popup/reducer/reducer";
import { Button } from "@soltivo/draw-a-line"
import useCreateAppointmentHandlers from "../../hooks/useCreateAppointmentHandlers";
import { Appointment } from "@soltivo/types";

interface PopupCancelProps {
    appointment: Appointment;
    loading: boolean;
    footer: ((
        children: (
          props: Pick<
            PopupState,
            | "show"
            | "actions"
            | "removeActions"
            | "animation"
            | "width"
            | "cancelEscEvent"
          >
        ) => JSX.Element | React.ReactNode
      ) => JSX.Element | React.ReactNode) | undefined;
}

const PopupCancel: FC<PopupCancelProps> = ({ appointment, footer, loading }) => {
    const submitRef = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        dispatch(appointmentDeleteAppointment({ appointmentId: appointment.id, employeeId: appointment.userId }));
    };

    const { onAppointmentDetailsPopup } = useCreateAppointmentHandlers({ appointment });

    return (
        <>
        <div className="popup__cancel-apt">
            <form onSubmit={onSubmit}>
                <p className="text-15 text-geyser-900">Are you sure that you want to cancel this appointment? Cancelling will delete it from your calendar.</p>
                <button ref={submitRef} type="submit" style={{ display: "none" }}></button>
            </form>
        </div>
        {footer && footer(() => (
            <div>
                <Button disabled={loading} onClick={() => onAppointmentDetailsPopup(appointment.id)} variant="geyser-500" outline border={false} padding={false}>
                    Cancel
                </Button>
                <Button data-testid="confirmCancel" disabled={loading} loading={loading ? { keepContent: true }: false} onClick={() => submitRef.current?.click()} variant="valencia-600">
                    Cancel appointment
                </Button>
            </div>
        ))}
        </>
    );
};
const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
    loading: AppointmentReducer.deleteAppointment.loading,
});

export default connect(mapStateToProps)(PopupCancel);

import apiV2 from "../../../../../helpers/api.v2";

const _URL = `${process.env.REACT_APP_API_URL}/billing`;
const _URL_PUBLIC = `${process.env.REACT_APP_API_URL}/public-billing`;

/**
 * @description Billings API calls.
 */
class BillingsClass {
    /**
     * @description get a plan
     */
    billingsGetPlan({ planId }: { planId: string }) {
        return apiV2.getRequest(`${_URL}/plan/${planId}`);
    }

    /**
     * @description get customer by id
     */
    billingsGetCustomer({ customerId }: { customerId: string }) {
        return apiV2.getRequest(`${_URL}/customers/${customerId}`);
    }

    /**
     * @description get all customers within an org
     * @depracated API it's deprecated.
     */
    billingsGetCustomers() {
        return apiV2.getRequest(`${_URL}/customers`);
    }

    /**
     * @description update customer (using only to update default payment method at the time.)
     */
    billingsUpdateCustomer({
        customerId,
        ...payload
    }: {
        customerId: string;
        invoice_settings?: {
            default_payment_method?: string;
        };
    }) {
        return apiV2.putRequest(`${_URL}/customers/${customerId}`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get organization next invoice payment
     */
    billingsGetNextInvoicePayment() {
        return apiV2.getRequest(`${_URL}/v1/invoices/next`);
    }

    /**
     * @description pagination get organization invoices.
     */
    billingsGetInvoices(payload: { lastKey?: string }) {
        const url = new URL(`${_URL}/v1/invoices`);
        if (payload.lastKey) url.searchParams.set("lastKey", payload.lastKey);
        return apiV2.getRequest(url.toString());
    }

    /**
     * @description get organization single invoice.
     */
    billingsGetInvoice({ invoiceId }: { invoiceId: string }) {
        return apiV2.getRequest(`${_URL_PUBLIC}/invoices/${invoiceId}`);
    }

    /**
     * @description get pdf of invoice.
     */
    billingsGetInvoicePdf({ invoiceId }: { invoiceId: string }) {
        return apiV2.getRequest(`${_URL}/invoices/pdf/${invoiceId}`);
    }

    /**
     * @description get list of customers
     */
    billingsListCustomers() {
        return apiV2.getRequest(`${_URL}/customers`);
    }

    /**
     * @description attach a payment method to a customer.
     */
    billingsAttachPaymentMethod({ paymentMethod }: { paymentMethod: string }) {
        return apiV2.postRequest(`${_URL_PUBLIC}/paymentmethods/attach/${paymentMethod}`);
    }

    /**
     * @description detach a payment method from a customer.
     */
    billingsDetachPaymentMethod({ paymentMethod }: { paymentMethod: string }) {
        return apiV2.deleteRequest(`${_URL}/paymentmethods/${paymentMethod}`);
    }

    /**
     * @description get payment methods of a org
     */
    billingsGetPaymentMethods({ stripeCustomerId }: { stripeCustomerId: string }) {
        return apiV2.getRequest(`${_URL_PUBLIC}/paymentmethods/${stripeCustomerId}`);
    }

    /**
     * @description create payment intent
     * @deprecated
     */
    billingsCreatePaymentIntent(payload: { customer: string; amount: number; currency: "cad" | "usd"; card: "card" }) {
        return apiV2.postRequest(`${_URL_PUBLIC}/paymentintent`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description update org subscription
     */
    billingsUpdateSubscription({ stripeCustomerId }: { stripeCustomerId: string }) {
        return apiV2.putRequest(`${_URL}/subscriptions/${stripeCustomerId}`);
    }

    /**
     * @description customer subscription
     */
    billingsGetSubscription({ customerId }: { customerId: string }) {
        return apiV2.getRequest(`${_URL_PUBLIC}/subscriptions/${customerId}`);
    }

    /**
     * @description get org subscription
     */
    billingsGetSubscriptions() {
        return apiV2.getRequest(`${_URL}/v1/subscriptions`, {
            useToken: "accessToken",
            useOrg: true,
        });
    }

    /**
     * @description get org subscription
     */
    billingsGetPublicSubscription() {
        return apiV2.getRequest(`${_URL_PUBLIC}/subscriptions/organization`, {
            useToken: false,
            useOrg: true,
        });
    }

    /**
     * @description update number of seats
     */
    billingsUpdateNumberOfSeats(payload: { seats: number }) {
        return apiV2.postRequest(`${_URL}/subscriptions/seats`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description Pay invoice
     */
    billingsPayInvoice(payload: { paymentMethodId?: string | null; invoiceId: string }) {
        const { paymentMethodId, invoiceId } = payload;
        return apiV2.putRequest(`${_URL_PUBLIC}/invoices/${invoiceId}/pay`, {
            useToken: false,
            useOrg: false,
            data: paymentMethodId ? JSON.stringify({ paymentMethodId: paymentMethodId }) : null,
        });
    }

    /**
     * @description create payment intent for domains.
     */
    billingsPaymentIntentDomains(payload: { domainName: string }) {
        return apiV2.postRequest(`${_URL}/paymentintent/domains`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description confirm created payment intent.
     */
    billingsConfirmPaymentIntent(payload: { paymentIntentId: string; paymentMethodId: string }) {
        return apiV2.putRequest(`${_URL_PUBLIC}/paymentintent`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description setup intent (or in other words setup user payment method)
     */
    billingsSetupIntent(payload: { domainName: string; paymentMethodId: string }) {
        return apiV2.postRequest(`${_URL}/setupintent`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description confirm setup intent
     */
    billingsSetupIntentConfirm(payload: { setupIntentId: string }) {
        return apiV2.postRequest(`${_URL}/setupintent/confirm`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }
}

export default new BillingsClass();

import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import popupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import history from "../../../../app/routes/history";
import { ContactEntityList, ContactEntityItem } from "../../contacts";
import { types } from "../reducer/types";
import contactsClass from "./contacts.class";
import { ContactsActions } from "../reducer/actions.types";
import { soltivoHelper } from "@soltivo/draw-a-line";
import apiV2, { ErrorResponseV2 } from "../../../../helpers/api.v2";

/**
 * @HELPER
 * @description change phone number format to unique format instead of string or object returned by api
 */
function changePhoneNumberFormat(entity: ContactEntityList) {
    return entity.items.map((item: any) => {
        if (typeof item.phoneNumber === "string") {
            item.phoneNumber = {
                number: item.phoneNumber,
                ext: undefined, // if type is string there's no extension yet.
            };
        }
        return item;
    });
}

/**
 * @description get entity (client | lead | supplier)
 */
function* contactsGetEntity({ payload }: ContactsActions["contactsGetEntity"]) {
    try {
        const { data } = yield call(contactsClass.contactsGetEntity, payload);
        yield put({
            type: types.CONTACTS_GET_ENTITY_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({ type: types.CONTACTS_GET_ENTITY_FAILURE, payload: error });
    }
}

////////// LEADS

/**
 * @description create a lead
 */
function* contactsCreateLead({ payload }: ContactsActions["contactsCreateLead"]) {
    try {
        if (!payload.firstName) {
            throw new Error("First name is required.");
        } else if (!payload.lastName) {
            throw new Error("Last name is required.");
        } else if (!payload.email) {
            throw new Error("Email is required.");
        } else if (soltivoHelper.validateEmail(payload.email)) {
            throw new Error("Email is not valid.");
        }

        const { keepHistory, ...payloadRest } = payload;

        const { data } = yield call(contactsClass.contactsCreateLead, payloadRest);

        yield put({
            type: types.CONTACTS_GET_LEADS_REQUEST,
            payload: null,
        });

        yield put({ type: types.CONTACTS_CREATE_LEAD_SUCCESS, payload: data });

        if (!keepHistory) {
            history.push(`/sales/contacts/leads/${data.entityId}`);
        }

        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", `Lead created successfully`);
    } catch (error: any) {
        let errorV2 = error as ErrorResponseV2;
        if (errorV2.error.errors) {
            errorV2.error.errors.forEach(errorItem => {
                if (errorItem.reason === "AlreadyExistsException") {
                    toastHelper.toastStartContent("danger", `An account with the given email ${payload.email} already exists.` );
                } else {
                    toastHelper.toastStartContent("danger", errorItem.message);
                }
            })
        } else {
            apiV2.toastAllErrors(error);
        }

        yield put({ type: types.CONTACTS_CREATE_LEAD_FAILURE, payload: error });
    }
}

/**
 * @description Get crm leads
 */
function* contactsGetLeads({ payload }: ContactsActions["contactsGetLeads"]) {
    try {
        const { data } = yield call(contactsClass.contactsGetLeads, payload);

        let { leads } = yield select(({ ContactsReducer }) => ({
            leads: ContactsReducer.leads,
        }));

        if (payload?.lastKey) {
            leads.items = [...leads.items, ...data.items];
        } else {
            leads.items = data.items;
        }
        leads.lastKey = data.lastKey;

        leads.items = changePhoneNumberFormat(leads);

        yield put({
            type: types.CONTACTS_GET_LEADS_SUCCESS,
            payload: leads,
        });
    } catch (error: any) {
        console.log(error);
        yield put({ type: types.CONTACTS_GET_LEADS_FAILURE, payload: error });
    }
}

/**
 * @description delete a lead
 */
function* contactsDeleteLead({ payload }: ContactsActions["contactsDeleteLead"]) {
    try {
        yield call(contactsClass.contactsDeleteLead, payload);
        yield put({ type: types.CONTACTS_DELETE_LEAD_SUCCESS, payload: payload });

        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", `Lead deleted successfully`);
    } catch (error: any) {
        popupHelper.popupEnd();
        toastHelper.toastStartContent("danger", "Failed to delete lead");
        yield put({ type: types.CONTACTS_DELETE_LEAD_FAILURE, payload: error });
    }
}

/**
 * @description update a lead
 */
function* contactsUpdateLead({ payload }: ContactsActions["contactsUpdateLead"]) {
    try {
        yield delay(2000);
        const { data } = yield call(contactsClass.contactsUpdateEntity, payload);

        let { leads, entity } = yield select(({ ContactsReducer }) => ({
            leads: ContactsReducer.leads,
            entity: ContactsReducer.entity,
        }));

        if (leads?.items.length) {
            leads.items = leads.items.map((item: ContactEntityItem) =>
                item.entityId === payload.entityId
                    ? {
                          ...item,
                          firstName: data.firstName,
                          phoneNumber: data.phoneNumber,
                          createdAt: data.createdAt,
                          lastName: data.lastName,
                          email: data.email,
                      }
                    : item
            );
        }

        entity = { ...entity, ...data };

        yield put({
            type: types.CONTACTS_UPDATE_LEAD_SUCCESS,
            payload: {
                entity,
                leads,
            },
        });

        popupHelper.popupEnd();
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_UPDATE_LEAD_FAILURE, payload: error });
    }
}

/**
 * @description convert lead to client.
 */
function* contactsConvertLead({ payload }: ContactsActions["contactsConvertLead"]) {
    try {
        toastHelper.toastStartContent("info", "Converting lead to client...");

        yield delay(2000);
        const { data } = yield call(contactsClass.contactsConvertLead, payload);

        let { leads } = yield select(({ ContactsReducer }) => ({
            leads: ContactsReducer.leads,
        }));
        if (leads?.items.length) leads.items = leads.items.filter((lead: ContactEntityItem) => lead.entityId !== payload.entityId);

        yield put({
            type: types.CONTACTS_CONVERT_LEAD_SUCCESS,
            payload: {
                client: data,
                leads: leads,
            },
        });

        if(payload.redirect) history.push(`/sales/contacts/clients/${payload.entityId}`);
        toastHelper.toastStartContent("success", "Lead has been converted to client successfully.");
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_CONVERT_LEAD_FAILURE, payload: error });
    }
}

////////// CLIENTS

/**
 * @description create a client
 */
function* contactsCreateClient({ payload }: ContactsActions["contactsCreateClient"]) {
    try {
        if (!payload.firstName) {
            throw new Error("First name is required.");
        } else if (!payload.lastName) {
            throw new Error("Last name is required.");
        } else if (!payload.email) {
            throw new Error("Email is required.");
        } else if (soltivoHelper.validateEmail(payload.email)) {
            throw new Error("Email is not valid.");
        }

        const { keepHistory, ...payloadRest } = payload;

        const { data } = yield call(contactsClass.contactsCreateClient, payloadRest);

        yield put({
            type: types.CONTACTS_GET_CLIENTS_REQUEST,
            payload: null,
        });

        yield put({ type: types.CONTACTS_CREATE_CLIENT_SUCCESS, payload: data });

        if (!keepHistory) {
            history.push(`/sales/contacts/clients/${data.entityId}`);
            popupHelper.popupEnd();
        }

        toastHelper.toastStartContent("success", `Client created successfully`);
    } catch (error: any) {
        let errorV2 = error as ErrorResponseV2;
        if (errorV2.error.errors) {
            errorV2.error.errors.forEach(errorItem => {
                if (errorItem.reason === "AlreadyExistsException") {
                    toastHelper.toastStartContent("danger", `An account with the given email ${payload.email} already exists.` );
                } else {
                    toastHelper.toastStartContent("danger", errorItem.message);
                }
            })
        } else {
            apiV2.toastAllErrors(error);
        }

        yield put({ type: types.CONTACTS_CREATE_CLIENT_FAILURE, payload: error });
    }
}

/**
 * @description Get crm clients
 */
function* contactsGetClients({ payload }: ContactsActions["contactsGetClients"]) {
    try {
        const { data } = yield call(contactsClass.contactsGetClients, payload);
        let { clients } = yield select(({ ContactsReducer }) => ({
            clients: ContactsReducer.clients,
        }));

        if (payload?.lastKey) {
            clients.items = [...clients.items, ...data.items];
        } else {
            clients.items = data.items;
        }
        clients.lastKey = data.lastKey;

        clients.items = changePhoneNumberFormat(clients);

        yield put({
            type: types.CONTACTS_GET_CLIENTS_SUCCESS,
            payload: clients,
        });
    } catch (error: any) {
        console.log(error);
        yield put({ type: types.CONTACTS_GET_CLIENTS_FAILURE, payload: error });
    }
}

/**
 * @description delete a client
 */
function* contactsDeleteClient({ payload }: ContactsActions["contactsDeleteClient"]) {
    try {
        yield call(contactsClass.contactsDeleteClient, payload);
        yield put({ type: types.CONTACTS_DELETE_CLIENT_SUCCESS, payload: payload });

        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", `Client deleted successfully`);
    } catch (error: any) {
        popupHelper.popupEnd();
        toastHelper.toastStartContent("danger", "Failed to delete client");
        yield put({ type: types.CONTACTS_DELETE_CLIENT_FAILURE, payload: error });
    }
}

/**
 * @description update a client
 */
function* contactsUpdateClient({ payload }: ContactsActions["contactsUpdateClient"]) {
    try {
        yield delay(2000);
        const { data } = yield call(contactsClass.contactsUpdateEntity, payload);

        let { clients, entity } = yield select(({ ContactsReducer }) => ({
            clients: ContactsReducer.clients,
            entity: ContactsReducer.entity,
        }));

        if (clients?.items.length) {
            clients.items = clients.items.map((item: ContactEntityItem) =>
                item.entityId === payload.entityId
                    ? {
                          ...item,
                          firstName: data.firstName,
                          phoneNumber: data.phoneNumber,
                          createdAt: data.createdAt,
                          lastName: data.lastName,
                          email: data.email,
                      }
                    : item
            );
        }

        entity = { ...entity, ...data };

        yield put({
            type: types.CONTACTS_UPDATE_CLIENT_SUCCESS,
            payload: {
                entity,
                clients,
            },
        });

        popupHelper.popupEnd();
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_UPDATE_CLIENT_FAILURE, payload: error });
    }
}

////////// SUPPLIER

/**
 * @description create a supplier
 */
function* contactsCreateSupplier({ payload }: ContactsActions["contactsCreateSupplier"]) {
    try {
        if (!payload.firstName) {
            throw new Error("First name is required.");
        } else if (!payload.lastName) {
            throw new Error("Last name is required.");
        } else if (!payload.email) {
            throw new Error("Email is required.");
        } else if (soltivoHelper.validateEmail(payload.email)) {
            throw new Error("Email is not valid.");
        }

        const { data } = yield call(contactsClass.contactsCreateSupplier, payload);

        yield put({
            type: types.CONTACTS_GET_SUPPLIERS_REQUEST,
            payload: null,
        });

        yield put({ type: types.CONTACTS_CREATE_SUPPLIER_SUCCESS });
        history.push(`/sales/contacts/suppliers/${data.entityId}`);

        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", `Contact created successfully`);
    } catch (error: any) {
        let errorV2 = error as ErrorResponseV2;
        if (errorV2.error.errors) {
            errorV2.error.errors.forEach(errorItem => {
                if (errorItem.reason === "AlreadyExistsException") {
                    toastHelper.toastStartContent("danger", `An account with the given email ${payload.email} already exists.` );
                } else {
                    toastHelper.toastStartContent("danger", errorItem.message);
                }
            })
        } else {
            apiV2.toastAllErrors(error);
        }

        yield put({ type: types.CONTACTS_CREATE_SUPPLIER_FAILURE, payload: error });
    }
}

/**
 * @description Get crm contacts
 */
function* contactsGetSuppliers({ payload }: ContactsActions["contactsGetSuppliers"]) {
    try {
        const { data } = yield call(contactsClass.contactsGetSuppliers, payload);

        let { suppliers } = yield select(({ ContactsReducer }) => ({
            suppliers: ContactsReducer.suppliers,
        }));
        if (payload?.lastKey) {
            suppliers.items = [...suppliers.items, ...data.items];
        } else {
            suppliers.items = data.items;
        }
        suppliers.lastKey = data.lastKey;

        suppliers.items = changePhoneNumberFormat(suppliers);

        yield put({
            type: types.CONTACTS_GET_SUPPLIERS_SUCCESS,
            payload: suppliers,
        });
    } catch (error: any) {
        console.log(error);
        yield put({ type: types.CONTACTS_GET_SUPPLIERS_FAILURE, payload: error });
    }
}

/**
 * @description delete a supplier
 */
function* contactsDeleteSupplier({ payload }: ContactsActions["contactsDeleteSupplier"]) {
    try {
        yield call(contactsClass.contactsDeleteSupplier, payload);
        yield put({ type: types.CONTACTS_DELETE_SUPPLIER_SUCCESS, payload: payload });

        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", `Contact deleted successfully`);
    } catch (error: any) {
        popupHelper.popupEnd();
        toastHelper.toastStartContent("danger", "Failed to delete supplier");
        yield put({ type: types.CONTACTS_DELETE_SUPPLIER_FAILURE, payload: error });
    }
}

/**
 * @description update a supplier
 */
function* contactsUpdateSupplier({ payload }: ContactsActions["contactsUpdateSupplier"]) {
    try {
        yield delay(2000);
        const { data } = yield call(contactsClass.contactsUpdateSupplier, payload);

        let { suppliers, entity } = yield select(({ ContactsReducer }) => ({
            suppliers: ContactsReducer.suppliers,
            entity: ContactsReducer.entity,
        }));

        if (suppliers?.items.length) {
            suppliers.items = suppliers.items.map((item: any) =>
                item.entityId === payload.entityId
                    ? {
                          ...item,
                          ...data,
                      }
                    : item
            );
        }

        entity = { ...entity, ...data };

        yield put({
            type: types.CONTACTS_UPDATE_SUPPLIER_SUCCESS,
            payload: {
                entity,
                contacts: suppliers,
            },
        });

        popupHelper.popupEnd();
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_UPDATE_SUPPLIER_FAILURE, payload: error });
    }
}

////////// NOTES

/**
 * @description create a note
 */
function* contactsCreateNote({ payload }: ContactsActions["contactsCreateNote"]) {
    try {
        const { data } = yield call(contactsClass.contactsCreateNote, payload);
        let { notes } = yield select(({ ContactsReducer }) => ({
            notes: ContactsReducer.notes,
        }));
        notes.list = [data, ...notes.list];
        yield put({ type: types.CONTACTS_CREATE_NOTE_SUCCESS, payload: notes });
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_CREATE_NOTE_FAILURE, payload: error });
    }
}

/**
 * @description Get notes
 */
function* contactsGetNotes({ payload }: ContactsActions["contactsGetNotes"]) {
    try {
        const { data } = yield call(contactsClass.contactsGetNotes, payload);
        let { notes } = yield select(({ ContactsReducer }) => ({
            notes: ContactsReducer.notes,
        }));

        notes.list = [...notes.list, ...data.list];
        notes.lastKey = data.lastKey || undefined;

        yield put({
            type: types.CONTACTS_GET_NOTES_SUCCESS,
            payload: notes,
        });
    } catch (error: any) {
        console.log(error);
        yield put({ type: types.CONTACTS_GET_NOTES_FAILURE, payload: error });
    }
}

/**
 * @description update a note
 */
function* contactsUpdateNote({ payload }: ContactsActions["contactsUpdateNote"]) {
    try {
        const { data } = yield call(contactsClass.contactsUpdateNote, payload);
        let { notes } = yield select(({ ContactsReducer }) => ({
            notes: ContactsReducer.notes,
        }));

        if (notes?.list.length) {
            notes.list = notes.list.map((item: ContactEntityItem) =>
                item.entityId === payload.entityId
                    ? {
                          ...item,
                          noteText: data.noteText,
                      }
                    : item
            );
        }

        yield put({
            type: types.CONTACTS_UPDATE_NOTE_SUCCESS,
            payload: {
                notes: notes,
            },
        });
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_UPDATE_NOTE_FAILURE, payload: error });
    }
}

/**
 * @description get entity custom fields
 */
function* contactsGetCustomFields({ payload }: ContactsActions["contactsGetCustomFields"]) {
    try {
        const { data } = yield call(contactsClass.contactsGetCustomFields, payload);
        yield put({
            type: types.CONTACTS_GET_CUSTOM_FIELDS_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({ type: types.CONTACTS_GET_CUSTOM_FIELDS_FAILURE, payload: error });
    }
}

/**
 * @description create entity custom fields
 */
function* contactsCreateCustomFields({ payload }: ContactsActions["contactsCreateCustomFields"]) {
    try {
        yield call(contactsClass.contactsCreateCustomFields, payload);
        yield put({
            type: types.CONTACTS_CREATE_CUSTOM_FIELDS_SUCCESS,
        });

        yield put({
            type: types.CONTACTS_GET_CUSTOM_FIELDS_REQUEST,
            payload: {
                entityType: payload.entityType,
            },
        });
    } catch (error: any) {
        apiV2.toastAllErrors(error);

        yield put({ type: types.CONTACTS_CREATE_CUSTOM_FIELDS_FAILURE, payload: error });
    }
}

/**
 * @description search entity
 */
function* contactsSearchEntity({ payload }: ContactsActions["contactsSearchEntity"]) {
    try {
        if (!payload) {
            yield put({
                type: types.CONTACTS_SEARCH_ENTITY_SUCCESS,
                payload: [],
            });
            return;
        }
        // this delay is important while the user is typing.
        yield delay(1500);
        const { data } = yield call(contactsClass.contactsSearchEntity, payload);
        yield put({
            type: types.CONTACTS_SEARCH_ENTITY_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        apiV2.toastAllErrors(error);
        yield put({ type: types.CONTACTS_SEARCH_ENTITY_FAILURE, payload: error });
    }
}

export default function* contactsSaga() {
    yield takeLatest(types.CONTACTS_GET_ENTITY_REQUEST, contactsGetEntity);

    ////////////////////////////////////////////////////////////////

    yield takeLatest(types.CONTACTS_CREATE_LEAD_REQUEST, contactsCreateLead);
    yield takeEvery(types.CONTACTS_GET_LEADS_REQUEST, contactsGetLeads);
    yield takeLatest(types.CONTACTS_DELETE_LEAD_REQUEST, contactsDeleteLead);
    yield takeLatest(types.CONTACTS_UPDATE_LEAD_REQUEST, contactsUpdateLead);
    yield takeLatest(types.CONTACTS_CONVERT_LEAD_REQUEST, contactsConvertLead);

    /////////////////////////////////////////////////////////////////

    yield takeLatest(types.CONTACTS_CREATE_CLIENT_REQUEST, contactsCreateClient);
    yield takeEvery(types.CONTACTS_GET_CLIENTS_REQUEST, contactsGetClients);
    yield takeLatest(types.CONTACTS_DELETE_CLIENT_REQUEST, contactsDeleteClient);
    yield takeLatest(types.CONTACTS_UPDATE_CLIENT_REQUEST, contactsUpdateClient);

    /////////////////////////////////////////////////////////////////

    yield takeLatest(types.CONTACTS_CREATE_SUPPLIER_REQUEST, contactsCreateSupplier);
    yield takeEvery(types.CONTACTS_GET_SUPPLIERS_REQUEST, contactsGetSuppliers);
    yield takeLatest(types.CONTACTS_DELETE_SUPPLIER_REQUEST, contactsDeleteSupplier);
    yield takeLatest(types.CONTACTS_UPDATE_SUPPLIER_REQUEST, contactsUpdateSupplier);

    /////////////////////////////////////////////////////////////////

    yield takeEvery(types.CONTACTS_CREATE_NOTE_REQUEST, contactsCreateNote);
    yield takeEvery(types.CONTACTS_GET_NOTES_REQUEST, contactsGetNotes);
    // yield takeLatest(types.CRM_DELETE_NOTE_REQUEST, crmDeleteNote);
    yield takeEvery(types.CONTACTS_UPDATE_NOTE_REQUEST, contactsUpdateNote);

    yield takeLatest(types.CONTACTS_GET_CUSTOM_FIELDS_REQUEST, contactsGetCustomFields);
    yield takeLatest(types.CONTACTS_CREATE_CUSTOM_FIELDS_REQUEST, contactsCreateCustomFields);
    yield takeLatest(types.CONTACTS_SEARCH_ENTITY_REQUEST, contactsSearchEntity);
}

import { Guide, popupHelper } from "@soltivo/draw-a-line";
import { FC, useEffect } from "react";
import { User } from "../../../applications/auth/auth";
import { Organization } from "../../../applications/auth/organization";
import { ReactComponent as SteveCowboySVG } from "@soltivo/draw-a-line/core/assets/illutrations/SteveCowboy.svg";
import PopupFirstSetup from "./popup.first.setup/popup.first.setup";
import { useState } from "react";
import { matchPath, useHistory } from "react-router";

interface GuideOrganizationProps {
    org: Organization;
    user: User;
}

const GuideOrganization: FC<GuideOrganizationProps> = ({ org, user }) => {
    const [started, setStarted] = useState(false);
    const history = useHistory();
    const RouteDashboard = matchPath(history.location.pathname, {
        path: "/dashboard",
    });

    const Route403 = matchPath(history.location.pathname, {
        path: "/organization/403",
    });

    useEffect(() => {
        if (!org?.configuration) return;
        // Check for the organization subscription
        // if(org.subscription?.status !== "active" && org.subscription?.status !== "trialing") return;
        //check for a valid organization configuration
        if (Object.keys(org.configuration).find((x) => !["services", "locations", "industry", "employees", "confirmation", "businessType"].includes(x))) return;

        if (started || !org?.configuration || org?.configuration?.confirmation === true || Route403) {
            return;
        }

        if (!RouteDashboard) {
            history.push("/dashboard");
            return;
        }

        setStarted(true);
        // user guide to introduce use to first setup of organization.
        const guide = new Guide();
        guide.start(
            [
                {
                    id: "set_first_acc",
                    description: (
                        <span>
                            {`Howdie ${user?.firstName}, welcome to your extraordinary journey with us!\n Let us begin your tour!`} <br /> <br />
                            <span className="text-geyser-400">Click anywhere to start.</span>
                        </span>
                    ),
                },
            ],
            {
                onlyInfo: true,
                characterBubble: "elm-50",
                CustomCharacterSVG: SteveCowboySVG,
                onClose: () => {
                    popupHelper.popupCustomOpen(() => <PopupFirstSetup />, {
                        className: "custom__popup-first-setup",
                        cancelEscEvent: true,
                    });
                },
            }
        );
    }, [org?.configuration?.confirmation, RouteDashboard, Route403]);

    return null;
};

export default GuideOrganization;

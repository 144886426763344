import Gregorian from "../../../helpers/unix-time/gregorian";
import moment from "moment-timezone";

export const nextDay = (time: number): number => {
    let next = new Date(time);
    next.setDate(new Date(time).getDate() + 1);
    return next.getTime();
};

export const previousDay = (time: number): number => {
    let previous = new Date(time);
    previous.setDate(new Date(time).getDate() - 1);
    return previous.getTime();
};

/**
 * @description format time to hh:mm
 */
export const formatTime = (date: string | number): string => {
    let hh = new Date(date).getHours().toString();
    let mm = new Date(date).getMinutes().toString();
    if (hh.length === 1) hh = `0${hh}`;
    if (mm.length === 1) mm = `0${mm}`;
    return `${hh}:${mm}`;
};

/**
 * @description format Date to yyy:mm:dd
 */
export const dateTimeToDate = (date: Date | number): string => {
    const givenDateInSeconds = new Date(date).getTime();
    return moment(new Date(givenDateInSeconds)).format("YYYY-MM-DD");
};

/**
 * @description check if a given date is the date of today or tomorrow other a coming date
 * @param {Date} date | string
 */
export const prettyDate = (date: Date): string => {
    const today = new Date(new Date(moment().format("YYYY-MM-DD")).getTime() + new Date().getTimezoneOffset() * 60 * 1000);

    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
        return "Today";
    } else if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() + 1 === date.getDate()) {
        return "Tomorrow";
    } else {
        return moment(date).format("DD MMM YYYY");
    }
};

/**
 * @description from yyyy-mm-dd format to Date string
 * @param {string} date yyyy-mm-dd
 */
export const dateToDateTime = (date: string): Date => {
    const items = date.split("-");
    let yyyy = parseInt(items[0]);
    let mm = parseInt(items[1]);
    let dd = parseInt(items[2]);
    return new Date(yyyy, mm, dd);
};

export const startDay = (time: number): number => {
    return Gregorian.startDay(time);
};

/**
 * @description get elapsed time in seconds in a day
 */
export const getElapsedSeconds = (date: number): number => {
    return date - Gregorian.startDay(date);
};

/**
 * @description convert hours to seconds
 */
export const hoursToSeconds = (hours: string, minutes: string): number => {
    return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60;
};

/**
 * @description convert from seconds to hours and minutes
 */
export const secondsToHoursSeconds = (seconds: number): { hours: number; minutes: number } => {
    const hours = Math.trunc(seconds / 3600);
    const minutes = (seconds % 3600) / 60;
    return {
        hours,
        minutes,
    };
};

/**
 * @description convert time to utc
 */
export const timeToUTC = (time: string, timezone: string, timeFormat?: string): string => {
    if (!timezone) return time;
    // it the time format is HH:mm
    if(/^(?:[01]\d|2[0-3]):[0-5]\d$/.test(time)) {
        const localTime = moment.tz(time, 'HH:mm', timezone);
        const utcTime = localTime.utc();
        return utcTime.format("HH:mm")
    }
    // Parse the input time in the specified timezone
    const localTime = timeFormat ? moment.tz(time, timeFormat, timezone): moment.tz(time, timezone);
    // Convert the local time to UTC
    const utcTime = localTime.utc();
    return utcTime.format();
};

/**
 * @description convert utc time to a specific timezone
 */
export const utcToTimezone = (utcTime: string, timezone: string, timeFormat?: string): string => {
    if (!timezone) return utcTime;
    // if the time format is HH:mm
    if(/^(?:[01]\d|2[0-4]):[0-5]\d$/.test(utcTime)) {
        const utcMoment = moment.utc(utcTime, 'HH:mm');
        const localTime = utcMoment.tz(timezone);
        return localTime.format("HH:mm"); 
    }
    // Create a Moment.js object for the UTC time
    const utcMoment = timeFormat ? moment.utc(utcTime, timeFormat):  moment.utc(utcTime);
    // Convert the UTC time to the target timezone
    const localTime = utcMoment.tz(timezone);
    return localTime.format();
};

export const renderTime = (datetime: Date | string | moment.Moment, orgTimeZone: string, format: "HH:mm" | "YYYY-MM-DD") => {
    return moment.tz(datetime, orgTimeZone).format(format)
};

/**
 * @description get time zone offset based on the time zone
 */
 export const timeZoneOffet = (timeZone: string): number => {
    if(!timeZone) return 0;
    const d = new Date();
    const localeDate: any = d.toLocaleString("ja", { timeZone: timeZone }).split(/[/\s:]/);
    localeDate[1]--;
    const t1 = Date.UTC.apply(null, localeDate);
    const t2 = new Date(d).setMilliseconds(0);
    return (t2 - t1) / 60 / 1000;
};

// If use moment-timezone library
// export const toTimeZone = (time: string, zone: string) => {
//     const format = 'YYYY-MM-DD HH:mm:ss ZZ';
//     return moment(time, format).tz(zone).format(format);
// };

export const getBrowserTimezone = () => {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
}
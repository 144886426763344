import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ArrowRight24Px, ArrowDropDown24Px, Copy } from "@soltivo/draw-a-line/core/components/icons";
import { Button, InputTime } from "@soltivo/draw-a-line";
import "./create.schedule.scss";
import { RootState } from "../../../../../../redux/reducers";
import { Organization } from "../../../../../../applications/auth/organization";
import { User } from "../../../../../../applications/auth/auth";
import { TWeekDay } from "../../../../../../applications/hrm/hrm";
// import { hrmGetEndStart__helper } from "../../../../../../applications/hrm/helpers/helpers";

export interface ConfigLocationProps extends MapStateToProps {
    workingDays: TWeekDay[];
    setWorkingDays: React.Dispatch<React.SetStateAction<TWeekDay[]>>;
    selectedWeekdays: { id: number; name: TWeekDay["weekday"] }[];
}

const ConfigSchedule: React.FC<ConfigLocationProps> = ({ workingDays, setWorkingDays, selectedWeekdays }) => {
    // const [edit, setEdit] = useState<boolean>(false);
    // const [toUpdate, setToUpdate] = useState<{ id: number; break: TBreak } | null>(null);

    const [weekDay, setWeekDay] = useState<TWeekDay | null>(workingDays[0]);

    // const [breakForm, setForm] = useState<{
    //     type: "break" | "lunch";
    //     name: string;
    //     start: string;
    //     end: string;
    // }>(() => {
    //     return {
    //         type: "break",
    //         name: "",
    //         start: "00:00",
    //         end: "00:00",
    //     };
    // });

    // useEffect(() => {
    //     if (toUpdate) {
    //         const { break: breakTime } = toUpdate;
    //         setForm({
    //             ...breakForm,
    //             start: breakTime.start,
    //             end: breakTime.end,
    //             name: breakTime.name,
    //             type: breakTime.type,
    //         });
    //     }
    // }, [toUpdate]);

    /** Update days schedule when working day change */
    useEffect(() => {
        if (weekDay) {
            const workingDaysList = workingDays.map((workingDay) => {
                if (workingDay.weekday === weekDay.weekday) {
                    workingDay = weekDay;
                }
                return workingDay;
            });
            setWorkingDays(workingDaysList);
        }
    }, [weekDay]);

    // const onAddBreak = () => {
    //     if (!weekDay) return;

    //     const { start: sStart, end: sEnd } = hrmGetEndStart__helper({
    //         start: weekDay.start,
    //         end: weekDay.end,
    //     });

    //     if (sEnd.getTime() <= sStart.getTime()) {
    //         toastHelper.toastStartContent("danger", `A working day cannot be set from "${weekDay.start}" to "${weekDay.end}".`);
    //         return;
    //     }

    //     if (!breakForm.name) {
    //         toastHelper.toastStartContent("danger", "Break name is required.");
    //         return;
    //     }

    //     const { start, end, type } = breakForm;
    //     if (!start && !end) return;
    //     const { start: xStart, end: xEnd } = hrmGetEndStart__helper({
    //         start,
    //         end,
    //     });

    //     if (xEnd.getTime() <= xStart.getTime()) {
    //         toastHelper.toastStartContent("danger", `A ${type} time cannot be set from "${start}" to "${end}".`);
    //         return;
    //     }

    //     if (xStart < sStart || xEnd > sEnd || xStart > sEnd || xEnd < sStart) {
    //         toastHelper.toastStartContent("danger", `A ${type} cannot be set from "${start}" to "${end} because the schedule is from ${weekDay.start} to ${weekDay.end}".`);
    //         return;
    //     }
    //     let _weekDay: TWeekDay = {
    //         ...weekDay,
    //         start: weekDay.start,
    //         end: weekDay.end,
    //     };
           
    //     setWeekDay(_weekDay);
    //     initForm();
    // };

    // const initForm = () => {
    //     setToUpdate(null);
    //     setEdit(false);
    //     setForm({ ...breakForm, name: "", start: "00:00", end: "00:00" });
    // };

    // const onRemoveBreak = () => {
    //     if (!weekDay) return;
    //     let _weekDay: TWeekDay = {
    //         ...weekDay,
    //     };
    //     setWeekDay(_weekDay);
    //     initForm();
    //     setEdit(false);
    // };

    const onChangeInterval = (e: { value: string; name: string }) => {
        if (!weekDay) return;
        setWeekDay({ ...weekDay, [e.name]: e.value });
    };

    // const onChangeTime = (e: { value: string; name: string }) => {
    //     setForm({ ...breakForm, [e.name]: e.value });
    // };

    // const onBreakClick = (id: number, _break: TBreak) => {
    //     setToUpdate({ id, break: _break });
    //     setEdit(true);
    // };

    const toggle = (_weekDay: TWeekDay) => {
        if (weekDay?.weekday === _weekDay.weekday) {
            setWeekDay(null);
        } else {
            setWeekDay(_weekDay);
        }
    };

    const copyToEmptyDays = (day: TWeekDay) => {
        if (day.start === "00:00" && day.end === "00:00") return;
        const updatedDays = workingDays.map((workingDay) => {
            if (selectedWeekdays.map((selectedDay) => selectedDay.name).includes(workingDay.weekday)) {
                if (workingDay.start === "00:00" && workingDay.end === "00:00") {
                    workingDay = { ...workingDay, start: day.start, end: day.end };
                }
            }

            return workingDay;
        });
        setWorkingDays(updatedDays);
    };

    const selectedWorkingDays = workingDays.filter((workingDay) => {
        return selectedWeekdays.map((selectedDay) => selectedDay.name).includes(workingDay.weekday);
    });

    const hasEmptyDays = selectedWorkingDays.filter((day) => day.start === "00:00" && day.end === "00:00");

    return (
        <div id="setup__schedules">
            <div className="schedule-config">
                {selectedWorkingDays.map((day, index) => {
                    return (
                        <div key={index} className="day-item__schedule">
                            <div className="item-header">
                                <div className="title-wrapper" onClick={() => toggle(day)}>
                                    {weekDay?.weekday === day.weekday ? <ArrowDropDown24Px className="arrow-icon" /> : <ArrowRight24Px className="arrow-icon" />}
                                    <h5>{day.weekday}</h5>
                                </div>
                                {weekDay?.weekday === day.weekday && hasEmptyDays.length && weekDay.start !== "00:00" && weekDay.end !== "00:00" ? (
                                    <Button onClick={() => copyToEmptyDays(day)} border={false} padding={false} outline={true} variant="primary">
                                        <Copy /> Copy to empty days
                                    </Button>
                                ) : null}
                            </div>
                            {weekDay && weekDay.weekday === day.weekday && (
                                <form className="item-content">
                                    <div className="form-group group-time" data-testid="scheduleDayInterval">
                                        <div>
                                            <label className="startLabel">Start</label>
                                            <InputTime name="start" value={weekDay.start} maxHours={23} onChange={onChangeInterval} withIcon={true} />
                                        </div>
                                        <div>
                                            <label className="endLabel">End</label>
                                            <InputTime name="end" value={weekDay.end} maxHours={23} onChange={onChangeInterval} withIcon={true} />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
    user: AuthReducer.user as User,
    org: OrgReducer.org as Organization,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ConfigSchedule);

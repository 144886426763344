import { Validation } from "@soltivo/draw-a-line/core/helpers/classes/Validation";

/**
 * @description validation for email.
 */
class SalesValidation {
    /**
     * @description form types.
     */
    public formTypes = {
        SALES_CREATE_TRANSACTION: "SALES_CREATE_TRANSACTION",
        SALES_REFUND_INVOICE: "SALES_REFUND_INVOICE",
        SALES_UPDATE_INVOICE_NOTE: "SALES_UPDATE_INVOICE_NOTE",
    } as const;

    public validation = new Validation<keyof SalesValidation["formTypes"]>([]);

    /**
     * @description Validate price on refund
     */
    public salesRefundInvoice(payload: { refundPrice: number }) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (isNaN(payload.refundPrice)) {
            errors.push({
                formName: this.formTypes.SALES_REFUND_INVOICE,
                fieldName: "refundPrice",
                message: `Price is invalid.`,
                status: "unprocessable",
            });
        } else if (payload.refundPrice === 0) {
            errors.push({
                formName: this.formTypes.SALES_REFUND_INVOICE,
                fieldName: "refundPrice",
                message: `Price cannot be 0.`,
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    /**
     * @description Update invoice note
     */
    public salesUpdateInvoiceNote(payload: { note: string }) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.note) {
            errors.push({
                formName: this.formTypes.SALES_UPDATE_INVOICE_NOTE,
                fieldName: "note",
                message: `Note is required`,
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }
}

export default new SalesValidation();

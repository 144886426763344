export const types = {

    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    CHANGE_VIEW: 'CHANGE_VIEW',

    ////// INVOICES //////

    CREATE_INVOICE_REQUEST: 'CREATE_INVOICE_REQUEST',
    CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',

    UPDATE_INVOICE_REQUEST: 'UPDATE_INVOICE_REQUEST',
    UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',

    DELETE_INVOICE_REQUEST: 'UPDATE_INVOICE_REQUEST',
    DELETE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',

    GET_INVOICES_REQUEST: 'GET_INVOICES_REQUEST',
    GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',

    GET_INVOICE_PDF_REQUEST: 'GET_INVOICE_PDF_REQUEST',
    GET_INVOICE_PDF_SUCCESS: 'GET_INVOICE_PDF_SUCCESS',

    SEND_INVOICE_REQUEST: 'SEND_INVOICE_REQUEST',
    SEND_INVOICE_SUCCESS: 'SEND_INVOICE_SUCCESS',

    GET_SINGLE_INVOICE_REQUEST: 'GET_SINGLE_INVOICE_REQUEST',
    GET_SINGLE_INVOICE_SUCCESS: 'GET_SINGLE_INVOICE_SUCCESS',

    INIT_INVOICE: 'INIT_INVOICE',

    SEARCH_CLIENT_REQUEST: 'SEARCH_CLIENT_REQUEST',
    SEARCH_CLIENT_SUCCESS: 'SEARCH_CLIENT_SUCCESS',

    ////// GLOBAL //////
}
import PopupEditNote from "../components/popup.edit.note/popup.edit.note";
import PopupReschedule from "../components/popup.reschedule/popup.reschedule";
import PopupCancel from "../components/popup.cancel/popup.cancel";
import PopupNotShow from "../components/popup.not.show/popup.not.show";
import PopupAppointmentDetails from "../components/popup.appointment.management/popup.appointment.details/popup.appointment.details";
import { useDispatch } from "react-redux";
import { appointmentChangeState, appointmentUpdateStatus } from "../redux/reducer/actions";
import { contactsChangeState } from "../../contacts/redux/reducer/actions";
import { popupHelper, Button } from "@soltivo/draw-a-line";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { AppointmentRouteParams } from "../appointment";
import AppointmentContext from "../context/appointment.context";
import moment from "moment";
import { AppointmentContextState } from "../context/reducer";
import { appointmentChangeContextState, appointmentChangeForm } from "../context/actions";
import { salesChangeState } from "../../sales/redux/reducer/actions";
import PopupCreateAppointment, { PopupCreateAppointmentProps } from "../components/popup.appointment.management/popup.create.appointment/popup.create.appointment";
import PopupCreateEvent from "../components/popup.create.event/popup.create.event";
import { Appointment, Event } from "@soltivo/types";

type UseCreateAppointmentHandlers = (options?: { appointment: Appointment | null }) => {
    /**
     * @description redirect to checkout if appointment is not completed
     * else it will redirect to transaction completed.
     */
    onSubmitExistingAppointment: () => void;
    /**
     * @description opens the popup to cancel an appointment.
     */
    onCancel: (appointment: Appointment) => void;
    /**
     * @description redirect user to rebook an completed appointment.
     */
    onRebook: () => void;
    /**
     * @description popup edit note of an completed appointment.
     */
    onEditNote: () => void;
    /**
     * @description opens popup to reschedule.
     */
    onReschedulePopup: () => void;
    /**
     * @description opens popup to start reschedule.
     */
    onAppointmentDetailsPopup: (appointmentId: string) => void;
    /**
     * @description opens the popup to set an appointment to not show.
     */
    onNotShowPopup: (appointment: Appointment) => void;
    /**
     * @description open modal to create / reschedule appointments
     */
    onStartCreateAppointmentPopup: (popupTitle: string, popupAction: "reschedule" | "create" | "quickCreate", options?: PopupCreateAppointmentProps["options"]) => void;
    /**
     * @description clear form and used attributes in redux if component unmount.
     */
    clearState: () => void;
    /**
     * @description format date for title, days slots  adding custom today tomorrow and yesterday.
     */
    displayDateCell: (
        datePayload?: string | undefined,
        option?:
            | {
                  title?: boolean | undefined;
              }
            | undefined
    ) => string;
    /**
     * @description change any attribute inside the form based on a name key and value.
     */
    onChangeForm: ({ name, value }: { name: keyof AppointmentContextState["form"]; value: any }) => void;
    /**
     * @description allow user to see more slots.
     */
    onMore: (type: "slots") => void;
    /**
     * @description open modal to create personal event
     */
    onStartCreatePersonalEvent: (event?: Event) => void;
};

type Handlers = ReturnType<UseCreateAppointmentHandlers>;

const useCreateAppointmentHandlers: UseCreateAppointmentHandlers = (options) => {
    const {
        dispatch: dispatchContextAppt,
        state: { form, dateFormat, slotsLength },
    } = useContext(AppointmentContext);
    const { type, aptId, aptDate } = useParams<AppointmentRouteParams>();

    const history = useHistory();

    const dispatch = useDispatch();

    const clearState: Handlers["clearState"] = () => {
        dispatch(
            appointmentChangeState({
                slots: [],
                availableDates: [],
                appointment: null,
                newAppointment: undefined,
            })
        );

        dispatch(
            contactsChangeState({
                searchResults: [],
                searchingEntity: false,
            })
        );

        dispatch(
            salesChangeState({
                loadingTransaction: true,
                transaction: undefined,
            })
        );

        // setForm(formInitialState);
    };

    const onNotShowPopup: Handlers["onNotShowPopup"] = (appointment) => {
        if (appointment.status === "not_show") {
            dispatch(
                appointmentUpdateStatus({
                    appointmentId: appointment.id,
                    moveTo: "unconfirmed",
                })
            );
            return;
        }

        popupHelper.popupOpenV2(({ footer }) => <PopupNotShow appointment={appointment} footer={footer} />, {
            title: <span>No Show</span>,
            width: 460,
            useV2Design: true,
        });
    };

    const onReschedulePopup: Handlers["onReschedulePopup"] = () => {
        popupHelper.popupOpenV2(
            ({ footer }) => (
                <>
                    <PopupReschedule />
                    {footer &&
                        footer(() => (
                            <div>
                                <Button onClick={() => onAppointmentDetailsPopup(options?.appointment?.id || "")} variant="geyser-500" outline border={false} padding={false}>
                                    Cancel
                                </Button>
                                <Button data-testid="startScheduleButton" onClick={() => onStartCreateAppointmentPopup("Appointment rescheduling", "reschedule")} variant="primary">
                                    Reschedule
                                </Button>
                            </div>
                        ))}
                </>
            ),
            {
                title: <span>Reschedule appointment</span>,
                width: 460,
                useV2Design: true,
            }
        );
    };

    const onStartCreateAppointmentPopup = (
        popupTitle: string, popupAction: "reschedule" | "create" | "quickCreate", 
        options?: PopupCreateAppointmentProps["options"]) => {
        popupHelper.popupOpenV2(() => <PopupCreateAppointment popupAction={popupAction} options={options} />, {
            title: <span>{popupTitle}</span>,
            width: 728,
            useV2Design: true,
        });
    };

    const onAppointmentDetailsPopup = (appointmentId: string) => {
        popupHelper.popupOpenV2(() => <PopupAppointmentDetails appointmentId={appointmentId} />, {
            title: <span>Appointment details</span>,
            width: 768,
            useV2Design: true,
        });
    };

    const onEditNote: Handlers["onEditNote"] = () => {
        popupHelper.popupOpenV2(({ footer }) => <PopupEditNote footer={footer} form={form} />, {
            title: <span>Edit appointment notes</span>,
            width: 500,
        });
    };

    const onRebook: Handlers["onRebook"] = () => {
        history.push(`/collaboration/calendar/${type}/reschedule/${aptDate}/appointments/${aptId}`);
    };

    const onCancel: Handlers["onCancel"] = (appointment: Appointment) => {
        const appt = options?.appointment;
        if (!appt) return;
        popupHelper.popupOpenV2(({ footer }) => <PopupCancel appointment={appointment} footer={footer} />, {
            title: <span>Cancel appointment</span>,
            width: 460,
            useV2Design: true,
        });
    };

    const onSubmitExistingAppointment: Handlers["onSubmitExistingAppointment"] = () => {
        if (!options?.appointment) return;
        // view transaction ✅
            //check out 💰
            history.push({
                pathname: `/administration/sales/invoices/create`,
                state: {
                    entity: {
                        ...(typeof options?.appointment.entityInfo !== "string") && {
                            ...options?.appointment?.entityInfo,
                            id: options?.appointment.entityInfo.entityId,
                        },
                    },
                    services: [options?.appointment.serviceInfo],
                },
            });
    };

    const displayDateCell: Handlers["displayDateCell"] = (datePayload, option) => {
        const today = moment().format(dateFormat);
        const tomorrow = moment().add(1, "days").format(dateFormat);
        const yesterday = moment().subtract(1, "days").format(dateFormat);
        const date = moment(datePayload).format(dateFormat);
        const titleFormat = moment(datePayload).format("DD MMM YYYY");

        if (date === today) {
            return option?.title ? `Today, ${titleFormat}` : "Today";
        } else if (date === tomorrow) {
            return option?.title ? `Tomorrow, ${titleFormat}` : "Tomorrow";
        } else if (date === yesterday) {
            return option?.title ? `Yesterday, ${titleFormat}` : "Yesterday";
        } else {
            return option?.title ? moment(datePayload).format("dddd, DD MMMM YYYY") : moment(datePayload).format("DD MMMM");
        }
    };

    const onChangeForm: Handlers["onChangeForm"] = ({ name, value }) => {
        dispatchContextAppt(
            appointmentChangeForm({
                [name]: value,
            })
        );
    };

    const onMore = (type: "slots") => {
        if (type === "slots") {
            dispatchContextAppt(
                appointmentChangeContextState({
                    slotsLength: slotsLength + 7,
                })
            );
        }
    };

    const onStartCreatePersonalEvent: Handlers["onStartCreatePersonalEvent"] = (event) => {
        popupHelper.popupOpenV2(({ footer }) => <PopupCreateEvent event={event} footer={footer} />, {
            title: <span>Personal Event</span>,
            width: 577,
            useV2Design: true,
        });
    };

    return {
        onSubmitExistingAppointment,
        onCancel,
        onRebook,
        onEditNote,
        onReschedulePopup,
        onAppointmentDetailsPopup,
        onNotShowPopup,
        onStartCreateAppointmentPopup,
        clearState,
        displayDateCell,
        onChangeForm,
        onMore,
        onStartCreatePersonalEvent
    };
};

export default useCreateAppointmentHandlers;

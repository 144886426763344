const INITIAL_STATE = Object.seal({
    partner: {
        // the current conversation partner in the text chats
        avatar: null,
        name: "Natasha Telmi",
        text: "Available",
        status: "Available",
    },
    chats: [
        {
            key: "BRIEF_0001",
            status: "Available",
            avatar: null,
            name: "Natasha K.",
            time: "3:22 PM",
            text: "Okay :)",
            count: 8,
        },
        {
            key: "BRIEF_0002",
            status: "Busy",
            avatar: null,
            name: "Natasha K.",
            time: "3:22 PM",
            text: "Okay :)",
            count: 0,
        },
        {
            key: "BRIEF_0003",
            status: "Idle",
            avatar: null,
            name: "Natasha K.",
            time: "3:22 PM",
            text: "Okay :)",
            count: 0,
        },
    ],
    groups: [
        {
            key: "GROUP_0001",
            avatar: "https://placekitten.com/38",
            name: "Administration",
            time: "3:22 PM",
            text: "Okay :)",
            count: 0,
        },
        {
            key: "GROUP_0002",
            avatar: "https://placekitten.com/38",
            name: "Designer",
            time: "3:22 PM",
            text: "Okay :)",
            count: 0,
        },
        {
            key: "GROUP_0003",
            avatar: "https://placekitten.com/38",
            name: "Hairdresser",
            time: "3:22 PM",
            text: "Okay :)",
            count: 0,
        },
    ],
    contacts: [
        {
            key: "CONTACT_0001",
            avatar: null,
            name: "Natasha K.",
            status: "available",
            text: "Available",
        },
        {
            key: "CONTACT_0002",
            avatar: null,
            name: "Natasha K.",
            status: "available",
            text: "Available",
        },
        {
            key: "CONTACT_0003",
            avatar: null,
            name: "Natasha K.",
            status: "available",
            text: "Available",
        },
    ],
    history: [
        {
            key: "HISTORY_0001",
            direction: "send",
            type: "text",
            contents: "Hello. How is it going?",
            timeLabel: "9:15 AM",
            confirmationLevel: "read",
        },
        {
            key: "HISTORY_0002",
            direction: "receive",
            type: "text",
            timeLabel: "8:00 AM",
            contents: "Hello. How is it going?",
        },
        {
            key: "HISTORY_0003",
            direction: "send",
            type: "text",
            contents: "Hello. How is it going?",
            timeLabel: "9:15 AM",
            confirmationLevel: "read",
        },
        {
            key: "HISTORY_0004",
            direction: "receive",
            type: "attachment",
            comment: "This is a very important file with a very long file name",
            timeLabel: "8:00 AM",
            fileName: "this-is-a-very-important-file-with-a-very-long-file-name.pdf",
            fileIcon: null, // TODO: PDF icon
            fileType: "PDF",
            fileSize: "17 KiB",
        },
        {
            key: "HISTORY_0005",
            direction: "send",
            type: "audio",
            audio: "https://freesound.org/data/previews/529/529915_4397472-lq.mp3",
            timeLabel: "10:00 AM",
            confirmationLevel: "sent",
        },
        {
            key: "HISTORY_0006",
            direction: "middle",
            type: "date",
            date: "YESTERDAY",
        },
        {
            key: "HISTORY_0007",
            direction: "middle",
            type: "lost-call",
            situation: "unavailable",
            text: "Natasha is unavailable",
        },
        {
            key: "HISTORY_0008",
            direction: "middle",
            type: "lost-call",
            situation: "in-another-call",
            text: "Natasha is in another call",
        },
        {
            key: "HISTORY_0009",
            direction: "middle",
            type: "lost-call",
            situation: "missed-call",
            text: "Return call",
        },
    ],
    attachments: {
        image: "http://placekitten.com/g/200/300",
        name: "attachment",
        date: "12 June",
        hour: "8:00 AM",
        size: "17 KiB",
        files: ["http://placekitten.com/g/200/300", "http://placekitten.com/g/200/300", "http://placekitten.com/g/200/300", "http://placekitten.com/g/200/300"],
    },
});

const ChatReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default ChatReducer;

// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import AccountingReducer from "../applications/accounting/redux/reducer/reducer";
import AppointmentReducer from "../applications/appointment/redux/reducer/reducer";
import AuthReducer from "../applications/auth/redux/auth/reducer/reducer";
import BillingsReducer from "../applications/settings/redux/billings/reducer/reducer";
import CalendarReducer from "../applications/calendar/redux/reducer/reducer";
import ChatReducer from "../applications/chat/redux/reducer/reducer";
import ContactsReducer from "../applications/contacts/redux/reducer/reducer";
import DomainReducer from "../applications/settings/redux/domain/reducer/reducer";
import EmailReducer from "../applications/email/redux/reducer/reducer";
import HRMReducer from "../applications/hrm/redux/reducer/reducer";
import InventoryReducer from "../applications/inventory/redux/reducer/reducer";
import OrgReducer from "../applications/auth/redux/org/reducer/reducer";
import ProductReducer from "../applications/product/redux/reducer/reducer";
import ProjectReducer from "../applications/project/redux/reducer/reducer";
import SalesReducer from "../applications/sales/redux/reducer/reducer";
import ServiceReducer from "../applications/service/redux/reducer/reducer";
import StorageReducer from "../applications/storage/redux/reducer/reducer";
import WebsiteReducer from "../applications/website/redux/reducer/reducer";
import NotificationReducer from "../applications/settings/redux/notifications/reducer/reducer";

// const persistConfig = {
// 	key: "root",
// 	storage,
// 	whiteList: [
// 		'ServiceReducer'
// 	]
// }

const reducerCombination = combineReducers({
    AccountingReducer,
    AppointmentReducer,
    AuthReducer,
    BillingsReducer,
    CalendarReducer,
    ChatReducer,
    ContactsReducer,
    DomainReducer,
    EmailReducer,
    HRMReducer,
    InventoryReducer,
    OrgReducer,
    ProductReducer,
    ProjectReducer,
    SalesReducer,
    ServiceReducer,
    StorageReducer,
    WebsiteReducer,
    NotificationReducer,
});

export type RootState = ReturnType<typeof reducerCombination>;
// export default persistReducer(persistConfig, reducerCombination);
export default reducerCombination;

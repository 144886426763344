import { Service, ServiceCategory } from "@soltivo/types/types/applications/services";
import moment from "moment";
import { User } from "../../auth/auth";
import { Location } from "../../auth/organization";
import { ContactEntity, EntityForm } from "../../contacts/contacts";
import { EmployeeItem } from "../../hrm/hrm";
import { AppointmentCreationSteps, DateSlot, AppointmentCreationSubSteps } from "../appointment";
import { AppointmentState } from "../redux/reducer/reducer";
import { types } from "./types";

export interface AppointmentContextState {
    /**
     * @description default format for url and apis.
     */
    dateFormat: "YYYY-MM-DD";
    /**
     * @description running date for the calendar.
     */
    date: number;
    form: {
        category?: ServiceCategory;
        service?: Service;
        location?: Location;
        employee?: User | EmployeeItem;
        date?: string;
        slot?: Required<Pick<DateSlot, "start" | "end">>
        contactEntityInfo?: Required<Pick<ContactEntity, "email" | "entityId" | "firstName" | "lastName" | "phoneNumber" | "type">>;
    };
    employeeList: (User | EmployeeItem)[];
    selectableLocations: Location[];
    boardId: "calendar__board";
    /**
     * @description used in create appointment to sticky the first fetched appointments based on
     * service, location and employee.
     */
    stickyAvaialbleDates: AppointmentState["availableDates"];
    /**
     * @description how many slots to show at once.
     */
    slotsLength: number;
    /**
     * @description steps when creating appointments on popup
     */
    appointmentCreationView: {
        currentStep?: AppointmentCreationSteps;
        nextStep?: AppointmentCreationSteps;
        previousStep?: AppointmentCreationSteps;
        disableButton?: boolean;
        subStep?: AppointmentCreationSubSteps;
    },
    // Selected employee for filter
    selectedEmployee?: User | EmployeeItem;
    createEntityForm: Partial<Pick<EntityForm, "firstName" | "email" | "lastName" | "phoneNumber">>;
    archiveMenuId?: string;
}

export const INITIAL_STATE: AppointmentContextState = {
    dateFormat: "YYYY-MM-DD",
    date: moment().valueOf(),
    form: {
        category: undefined,
        service: undefined,
        location: undefined,
        employee: undefined,
        date: undefined,
        slot: undefined,
        contactEntityInfo: undefined,
    },
    employeeList: [],
    selectableLocations: [],
    boardId: "calendar__board",
    stickyAvaialbleDates: [],
    slotsLength: 7,
    appointmentCreationView: {
        currentStep: undefined,
        disableButton: true,
        subStep: undefined,
    },
    selectedEmployee: undefined,
    createEntityForm: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
    },
};

/**
 * @description Appointment Context
 */
const AppointmentContextReducer = (state = INITIAL_STATE, action: { type: string; payload?: any }): AppointmentContextState => {
    switch (action.type) {
        case types.APPOINTMENT_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.APPOINTMENT_CHANGE_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
            };
        case types.APPOINTMENT_SET_INITIAL_STATE:
            return INITIAL_STATE;

        case types.APPOINTMENT_SET_INITIAL_FORM:
            return {
                ...state,
                form: INITIAL_STATE.form,
            };

        case types.APPOINTMENT_CREATION_CHANGE_VIEW:
            return {
                ...state,
                appointmentCreationView: {
                    ...state.appointmentCreationView,
                    ...action.payload
                }
            };
        
        case types.APPOINTMENT_UPDATE_EMPLOYEES_LIST:
            return {
                ...state,
                employeeList: action.payload
            };

        case types.APPOINTMENT_UPDATE_SELECTABLE_LOCATIONS:
            return {
                ...state,
                selectableLocations: action.payload
            };

        case types.APPOINTMENT_CHANGE_ENTITY_FORM:
            return {
                ...state,
                createEntityForm: {
                    ...state.createEntityForm,
                    ...action.payload,
                }
            };
        default:
            return state;
    }
};

export default AppointmentContextReducer;

import { AutoSaveAPI } from "@soltivo/draw-a-line";
import { DomainState } from "./reducer";
import { types } from "./types";

/**
 * @description change global state in domain reducer.
 * @warning be careful using this action, you may break the application.
 */
export const domainChangeState = (payload: Partial<DomainState>) => ({
    type: types.DOMAIN_CHANGE_STATE,
    payload,
});

/**
 * @description get organization domain info.
 */
export const domainGetDomainInfo = () => ({
    type: types.DOMAIN_GET_DOMAIN_INFO_REQUEST,
});

/**
 * @description check new domain availability.
 */
export const domainCheckAvailability = (payload: { domainName: string }) => ({
    type: types.DOMAIN_CHECK_AVAILABILITY_REQUEST,
    payload,
});

/**
 *
 * @description subscribe domain name
 */
export const domainSubscribeDomain = (payload: {
    domainName: string;
    paymentMethodId?: string;
    /**
     * @description if new payment method we set this to true to call
     * attach payment method to customer, (Required to be attached)
     */
    attachPaymentMethod: boolean;
    purchaseInfo: {
        firstName: string;
        lastName: string;
        address1: string;
        address2?: string;
        country: string;
        countryCode: string;
        city: string;
        state: string;
        zipCode: string;
        phoneNumber: string;
        email: string;
    };
}) => ({
    type: types.DOMAIN_SUBSCRIBE_DOMAIN_REQUEST,
    payload,
});

/**
 * @description confirm subscribe domain with invoice id.
 */
export const domainSubscribeDomainConfirm = (payload: { invoice: string }) => ({
    type: types.DOMAIN_SUBSCRIBE_CONFIRM_REQUEST,
    payload,
});

/**
 * @description create setup intent.
 */
export const domainCreateSetupIntent = (payload: { domainName: string; paymentMethodId: string }) => ({
    type: types.DOMAIN_CREATE_SETUP_INTENT_REQUEST,
    payload,
});

/**
 * @description turn on/off domain renew flag
 */
export const domainRenewFlag = (payload: { renewable: boolean }) => ({
    type: types.DOMAIN_RENEW_FLAG_REQUEST,
    payload,
});

export const domainUpdateInfos = (payload: {
    domainInfos: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        address1: string;
        address2: string;
        countryCode: string;
        state: string;
        city: string;
        zipCode: string;
    };
    domainName: string;
    autoSave: {
        fieldName: string;
        autoSaveAPI: AutoSaveAPI;
    }
}) => ({
    type: types.DOMAIN_UPDATE_INFOS_REQUEST,
    payload
})
import { useDropContainer } from "@soltivo/draw-a-line";
import InputText, { InputTextProps } from "@soltivo/draw-a-line/core/components/inputs/input.text/input.text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapboxPlacesFeatures } from "../../../../../applications/auth/organization";
import { orgGetPlaces } from "../../../../../applications/auth/redux/org/reducer/actions";
import { RootState } from "../../../../../redux/reducers";
import styles from "./input.places.module.scss";

interface InputPlacesProps {
    onSelectPlace: (place: MapboxPlacesFeatures) => void;
    inputTextProps?: InputTextProps;
}

const InputPlaces = ({ onSelectPlace, inputTextProps }: InputPlacesProps) => {
    const { containerRef, dropState, droppedRef, onDropdown } = useDropContainer({
        centeredX: true,
        withParentWidth: true,
        containerGap: 7,
    });

    const dispatch = useDispatch();
    const [query, setQuery] = useState("");

    const mapStateToProps = ({ OrgReducer }: RootState) => ({
        places: OrgReducer.places,
        loadingPlaces: OrgReducer.loadingPlaces,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;

    const { loadingPlaces, places }: MapStateToProps = useSelector(mapStateToProps);

    /**
     * @description change form input value
     */
    const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);
        if (inputTextProps?.onChange) inputTextProps?.onChange(e);
    };

    useEffect(() => {
        if (query.length >= 3) {
            dispatch(
                orgGetPlaces({
                    query: query,
                })
            );
        }
    }, [query]);

    useEffect(() => {
        onDropdown(Boolean(query.length >= 3 && !loadingPlaces));
    }, [query, loadingPlaces]);

    return (
        <div className={styles.input__places} ref={containerRef}>
            <InputText 
                {...inputTextProps} 
                onChange={onSearchQueryChange} 
                placeholder={"Location address"}
                autoComplete="off"
            />

            <div data-testid="resultsContainer" className={`${styles.search__results} ${dropState.drop ? styles.show : ""}`} ref={droppedRef}>
                {places?.length === 0 && query ? (
                    <p className={styles.message}>No location found.</p>
                ) : (
                    places?.map((place, i: number) => (
                        <p
                            data-testid="placeItem"
                            onClick={() => {
                                setQuery("");
                                onSelectPlace(place);
                            }}
                            key={i}
                            className={styles.place}>
                            {place.place_name}
                        </p>
                    ))
                )}
            </div>
        </div>
    );
};

export default InputPlaces;

import React from 'react';
import styles from "./page404.module.scss";

const NotFound = () =>
{
    return (
        <div className={styles.not__found}>
            <h2>404</h2>
            <p>Not Found</p>
        </div>
    )
}

export default NotFound;
export const types = {
    
    STORAGE_TOGGLE_STYLE_REQUEST: "STORAGE_TOGGLE_STYLE_REQUEST",
    STORAGE_ON_CHANGE_FORM: "STORAGE_ON_CHANGE_FORM",
    
    STORAGE_SEARCH_REQUEST: "STORAGE_SEARCH_REQUEST",
    STORAGE_SEARCH_SUCCESS: "STORAGE_SEARCH_SUCCESS",
    STORAGE_SEARCH_FAILURE: "STORAGE_SEARCH_FAILURE",
    
    STORAGE_GET_WORKSPACES_REQUEST: "STORAGE_GET_WORKSPACES_REQUEST",
    STORAGE_GET_WORKSPACES_SUCCESS: "STORAGE_GET_WORKSPACES_SUCCESS",
    STORAGE_GET_WORKSPACES_FAILURE: "STORAGE_GET_WORKSPACES_FAILURE",
    
    STORAGE_GET_WORKSPACE_ITEMS_REQUEST: "STORAGE_GET_WORKSPACE_ITEMS_REQUEST",
    STORAGE_GET_WORKSPACE_ITEMS_SUCCESS: "STORAGE_GET_WORKSPACE_ITEMS_SUCCESS",
    STORAGE_GET_WORKSPACE_ITEMS_FAILURE: "STORAGE_GET_WORKSPACE_ITEMS_FAILURE",

    STORAGE_GET_FOLDER_ITEMS_REQUEST: "STORAGE_GET_FOLDER_ITEMS_REQUEST",
    STORAGE_GET_FOLDER_ITEMS_SUCCESS: "STORAGE_GET_FOLDER_ITEMS_SUCCESS",
    STORAGE_GET_FOLDER_ITEMS_FAILURE: "STORAGE_GET_FOLDER_ITEMS_FAILURE",

    STORAGE_GET_ALL_FOLDERS_REQUEST: "STORAGE_GET_ALL_FOLDERS_REQUEST",
    STORAGE_GET_ALL_FOLDERS_SUCCESS: "STORAGE_GET_ALL_FOLDERS_SUCCESS",
    STORAGE_GET_ALL_FOLDERS_FAILURE: "STORAGE_GET_ALL_FOLDERS_FAILURE",
    
    STORAGE_UPLOAD_FILES_REQUEST: "STORAGE_UPLOAD_FILES_REQUEST",
    STORAGE_UPLOAD_FILES_SUCCESS: "STORAGE_UPLOAD_FILES_SUCCESS",
    STORAGE_UPLOAD_FILES_FAILURE: "STORAGE_UPLOAD_FILES_FAILURE",

    STORAGE_OPEN_FILE_REQUEST: "STORAGE_OPEN_FILE_REQUEST",
    STORAGE_OPEN_FILE_SUCCESS: "STORAGE_OPEN_FILE_SUCCESS",
    STORAGE_OPEN_FILE_FAILURE: "STORAGE_OPEN_FILE_FAILURE",

    STORAGE_CLOSE_FILE_REQUEST: "STORAGE_CLOSE_FILE_REQUEST",

    STORAGE_GET_FILE_REQUEST: "STORAGE_GET_FILE_REQUEST",
    STORAGE_GET_FILE_SUCCESS: "STORAGE_GET_FILE_SUCCESS",
    STORAGE_GET_FILE_FAILURE: "STORAGE_GET_FILE_FAILURE",

    STORAGE_DELETE_ITEMS_REQUEST: "STORAGE_DELETE_ITEMS_REQUEST",
    STORAGE_DELETE_ITEMS_SUCCESS: "STORAGE_DELETE_ITEMS_SUCCESS",
    STORAGE_DELETE_ITEMS_FAILURE: "STORAGE_DELETE_ITEMS_FAILURE",

    STORAGE_RENAME_FILE_REQUEST: "STORAGE_RENAME_FILE_REQUEST",
    STORAGE_RENAME_FILE_SUCCESS: "STORAGE_RENAME_FILE_SUCCESS",
    STORAGE_RENAME_FILE_FAILURE: "STORAGE_RENAME_FILE_FAILURE",

    STORAGE_CREATE_FOLDER_REQUEST: "STORAGE_CREATE_FOLDER_REQUEST",
    STORAGE_CREATE_FOLDER_SUCCESS: "STORAGE_CREATE_FOLDER_SUCCESS",
    STORAGE_CREATE_FOLDER_FAILURE: "STORAGE_CREATE_FOLDER_FAILURE",

    STORAGE_DELETE_FOLDER_REQUEST: "STORAGE_DELETE_FOLDER_REQUEST",
    STORAGE_DELETE_FOLDER_SUCCESS: "STORAGE_DELETE_FOLDER_SUCCESS",
    STORAGE_DELETE_FOLDER_FAILURE: "STORAGE_DELETE_FOLDER_FAILURE",

    STORAGE_RENAME_FOLDER_REQUEST: "STORAGE_RENAME_FOLDER_REQUEST",
    STORAGE_RENAME_FOLDER_SUCCESS: "STORAGE_RENAME_FOLDER_SUCCESS",
    STORAGE_RENAME_FOLDER_FAILURE: "STORAGE_RENAME_FOLDER_FAILURE",

    STORAGE_COPY_ITEM_REQUEST: "STORAGE_COPY_ITEM_REQUEST",
    STORAGE_COPY_ITEM_CANCEL: "STORAGE_COPY_ITEM_CANCEL",
    STORAGE_PASTE_ITEMS_REQUEST: "STORAGE_PASTE_ITEMS_REQUEST",
    STORAGE_PASTE_ITEMS_SUCCESS: "STORAGE_PASTE_ITEMS_SUCCESS",
    STORAGE_PASTE_ITEMS_FAILURE: "STORAGE_PASTE_ITEMS_FAILURE",

    STORAGE_CUT_ITEM_REQUEST: "STORAGE_CUT_ITEM_REQUEST",
    STORAGE_CUT_ITEM_CANCEL: "STORAGE_CUT_ITEM_CANCEL",
    STORAGE_MOVE_ITEMS_REQUEST: "STORAGE_MOVE_ITEMS_REQUEST",
    STORAGE_MOVE_ITEMS_SUCCESS: "STORAGE_MOVE_ITEMS_SUCCESS",
    STORAGE_MOVE_ITEMS_FAILURE: "STORAGE_MOVE_ITEMS_FAILURE",

    STORAGE_SELECT_ITEM_REQUEST: "STORAGE_SELECT_ITEM_REQUEST",
    STORAGE_SELECT_ITEM_CANCEL: "STORAGE_SELECT_ITEM_CANCEL",

    STORAGE_ORGANIZATION_STATS_REQUEST: "STORAGE_ORGANIZATION_STATS_REQUEST",
    STORAGE_ORGANIZATION_STATS_SUCCESS: "STORAGE_ORGANIZATION_STATS_SUCCESS",
    STORAGE_ORGANIZATION_STATS_FAILURE: "STORAGE_ORGANIZATION_STATS_FAILURE",

    STORAGE__REQUEST: "STORAGE_GET_ALL_FOLDERS_REQUEST",
    STORAGE__SUCCESS: "STORAGE_GET_ALL_FOLDERS_SUCCESS",
    STORAGE__FAILURE: "STORAGE_GET_ALL_FOLDERS_FAILURE",
}
export const types = {

    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
    CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',

    LOAD_EVENTS_REQUEST: 'LOAD_EVENTS_REQUEST',
    LOAD_EVENTS_SUCCESS: 'LOAD_EVENTS_SUCCESS',

}
import { soltivoHelper } from "@soltivo/draw-a-line";
import { PaymentMethod } from "@stripe/stripe-js";
import { DomainContextState } from "../../contexts/domain/reducer";
import { DomainRegisterSteps } from "../../domain";
import { _CANADA_STATES } from "../../helpers/contants";
import { DomainActions } from "../../redux/domain/reducer/actions.types";
import { DomainState } from "../../redux/domain/reducer/reducer";
import { Validation } from "@soltivo/draw-a-line/core/helpers/classes/Validation";

/**
 * @description validation for domain.
 */
class DomainValidation {
    /**
     * @description form types.
     */
    public formTypes = {
        DOMAIN_CREATE_DOMAIN: "DOMAIN_CREATE_DOMAIN",
    } as const;

    public validation = new Validation<keyof DomainValidation["formTypes"]>([]);

    /**
     * @description validate domain availability.
     */
    public domainCheckAvailability(payload: DomainActions["domainCheckAvailability"]["payload"]) {
        const acceptEndDomainRegex = /^([a-zA-Z0-9]+)(.com|.net|.ca|.org)$/;
        const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i;
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.domainName) {
            errors.push({ message: "Domain url is required.", fieldName: "domainName", formName: this.formTypes.DOMAIN_CREATE_DOMAIN, status: "unprocessable" });
        } else if (!domainRegex.test(payload.domainName)) {
            errors.push({
                message: "Domain name must be a valid url, eg. myorganization.com",
                fieldName: "domainName",
                formName: this.formTypes.DOMAIN_CREATE_DOMAIN,
                status: "unprocessable",
            });
        } else if (!acceptEndDomainRegex.test(payload.domainName)) {
            errors.push({
                message: "We accept only the following extension .com .net .org and or .ca",
                fieldName: "domainName",
                formName: this.formTypes.DOMAIN_CREATE_DOMAIN,
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    /**
     * @description subscribe domain
     */
    public domainSubscribeDomain(payload: DomainActions["domainSubscribeDomain"]["payload"], domainInfo: DomainState["domainInfo"]) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (domainInfo?.domainName) {
            errors.push({ message: "You cannot have more than one domain.", status: "failed", fieldName: "domainName", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.firstName) {
            errors.push({ message: "First name is required.", status: "failed", fieldName: "firstName", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.lastName) {
            errors.push({ message: "Last name is required.", status: "failed", fieldName: "lastName", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.email) {
            errors.push({ message: "Email is required.", status: "failed", fieldName: "email", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (soltivoHelper.validateEmail(payload.purchaseInfo.email)) {
            errors.push({ message: "Email is invalid.", status: "failed", fieldName: "email", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.phoneNumber) {
            errors.push({ message: "Phone number is required.", status: "failed", fieldName: "phoneNumber", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(payload.purchaseInfo.phoneNumber)) {
            errors.push({ message: "Phone number is invalid.", status: "failed", fieldName: "phoneNumber", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (payload.purchaseInfo.phoneNumber.slice(0, 3) !== "+1.") {
            errors.push({ message: "Canada country code must start with +1.", status: "failed", fieldName: "phoneNumber", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.countryCode) {
            errors.push({ message: "Country is required.", status: "failed", fieldName: "country", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.state) {
            errors.push({ message: "State is required.", status: "failed", fieldName: "state", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!_CANADA_STATES.map((c) => c.abbreviation.toUpperCase()).includes(payload.purchaseInfo.state.toUpperCase())) {
            errors.push({ message: "Canada state is not valid.", status: "failed", fieldName: "state", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.city) {
            errors.push({ message: "City is required.", status: "failed", fieldName: "city", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.address1) {
            errors.push({ message: "First address is required.", status: "failed", fieldName: "address1", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!payload.purchaseInfo.zipCode) {
            errors.push({ message: "Zip code is required.", status: "failed", fieldName: "zipCode", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }
        if (!new RegExp("^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$").test(payload.purchaseInfo.zipCode)) {
            errors.push({ message: "Zip code must follow canada states format.", status: "failed", fieldName: "zipCode", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    /**
     * @description validate register domain by step.
     */
    public registerStep(
        step: DomainRegisterSteps,
        payload: Partial<
            DomainContextState["form"] & {
                paymentMethod: PaymentMethod;
            }
        >
    ) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];
        switch (step) {
            case "1":
                if (!payload.firstName) {
                    errors.push({ message: "First name is required.", status: "unprocessable", fieldName: "firstName", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                }
                if (!payload.lastName) {
                    errors.push({ message: "Last name is required.", status: "unprocessable", fieldName: "lastName", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                }

                if (!payload.email) {
                    errors.push({ message: "Email is required.", status: "unprocessable", fieldName: "email", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                } else if (soltivoHelper.validateEmail(payload.email)) {
                    errors.push({ message: "Email is invalid.", status: "unprocessable", fieldName: "email", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                }

                if (!payload.phoneNumber) {
                    errors.push({ message: "Email is invalid.", status: "unprocessable", fieldName: "email", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                } else if (!/^[+]*[(]{0,1}[1]{1,4}[)]{0,1}[-\s./0-9]*$/.test(payload.phoneNumber)) {
                    errors.push({ message: "Phone number is invalid.", status: "unprocessable", fieldName: "phoneNumber", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                }
                break;

            case "2":
                if (step === "2") {
                    if (!payload.countryCode || !payload.country) {
                        errors.push({ message: "Country is required.", status: "unprocessable", fieldName: "country", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                    }

                    if (!payload.state || !payload.stateCode) {
                        errors.push({ message: "State is required.", status: "unprocessable", fieldName: "state", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                    } else if (!_CANADA_STATES.map((c) => c.abbreviation.toUpperCase()).includes(payload.stateCode.toUpperCase())) {
                        errors.push({
                            message: "Canada state is not valid, couldn't not find state code.",
                            status: "unprocessable",
                            fieldName: "state",
                            formName: this.formTypes.DOMAIN_CREATE_DOMAIN,
                        });
                    }

                    if (!payload.city) {
                        errors.push({ message: "City is required.", status: "unprocessable", fieldName: "city", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                    }

                    if (!payload.address1) {
                        errors.push({ message: "First address is required.", status: "unprocessable", fieldName: "address1", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                    }

                    if (!payload.zipCode) {
                        errors.push({ message: "Zip code is required.", status: "unprocessable", fieldName: "zipCode", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                    } else if (!new RegExp("^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$").test(payload.zipCode)) {
                        errors.push({
                            message: "Zip code must follow canada state format, eg. A1A 1A1",
                            status: "unprocessable",
                            fieldName: "zipCode",
                            formName: this.formTypes.DOMAIN_CREATE_DOMAIN,
                        });
                    }
                }
                break;

            case "3":
                if (!payload.nameOnCard) {
                    errors.push({ message: "Name on card is required.", status: "unprocessable", fieldName: "nameOnCard", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                }
                break;

            case "card":
                if (!payload.paymentMethod) {
                    errors.push({ message: "Please select a card.", status: "unprocessable", fieldName: "card", formName: this.formTypes.DOMAIN_CREATE_DOMAIN });
                }
                break;
        }

        return errors;
    }
}

export default new DomainValidation();

import apiV2 from "../../../../helpers/api.v2";
import { ServicesActionsTypes } from "../reducer/actions.types";
var _SERVICE_URL = `${process.env.REACT_APP_API_URL}/services`;

/**
 * @description Service API calls.
 */
class ServiceClass {
    /**
     * @description get suggestions for name service category.
     */
    serviceGetCategorySuggestions() {
        return apiV2.getRequest(`${_SERVICE_URL}/v2/categories/suggestion`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description assign employee to service.
     */
    serviceAssignEmployee(payload: ServicesActionsTypes["serviceAssignEmployee"]["payload"]) {
        return apiV2.postRequest(`${_SERVICE_URL}/v2/assign`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get service categories of services.
     */
    serviceGetCategories() {
        return apiV2.getRequest(`${_SERVICE_URL}/v2`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get service category by id.
     */
    serviceGetCategory({ categoryId }: { categoryId: string }) {
        return apiV2.getRequest(`${_SERVICE_URL}/categories/${categoryId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description search service category of services.
     * @param {{
     *  name:string,
     * }} payload
     */
    serviceSearchCategory(payload: { name: string }) {
        return apiV2.postRequest(`${_SERVICE_URL}/categories/search`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    serviceCreateCategory(payload: { name: string; description: string }) {
        return apiV2.postRequest(`${_SERVICE_URL}/categories`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
            headers: {
                "content-type": "application/json",
            },
        });
    }

    /**
     * @description update category of services.
     */
    serviceUpdateCategory({ categoryId, ...restPayload }: { categoryId: string; name: string; description: string }) {
        return apiV2.putRequest(`${_SERVICE_URL}/categories/${categoryId}`, {
            data: JSON.stringify(restPayload),
            useToken: "accessToken",
        });
    }

    /**
     * @description delete service category by id.
     */
    serviceDeleteCategory({ categoryId }: { categoryId: string }) {
        return apiV2.deleteRequest(`${_SERVICE_URL}/categories/${categoryId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get services of a category.
     */
    serviceGetServices({ categoryId }: { categoryId: string }) {
        return apiV2.getRequest(`${_SERVICE_URL}/category/${categoryId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get all services.
     */
    serviceGetAllServices() {
        return apiV2.getRequest(`${_SERVICE_URL}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get service by id.
     */
    serviceGetService({ serviceId }: { serviceId: string }) {
        return apiV2.getRequest(`${_SERVICE_URL}/${serviceId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description upload image before create service.
     */
    serviceUploadServiceImage(payload: { file: File }) {
        return apiV2.getRequest(`${_SERVICE_URL}/url?contentType=${payload.file.type}&cacheControl=max-age=300,public`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create a service inside a category
     */
    serviceCreateService(payload: ServicesActionsTypes["serviceCreateService"]["payload"]) {
        return apiV2.postRequest(`${_SERVICE_URL}/v2`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
            headers: {
                "content-type": "application/json",
            },
        });
    }

    /**
     * @description update a service
     */
    serviceUpdateService(payload: ServicesActionsTypes["serviceUpdateService"]["payload"]) {
        const { serviceId, service } = payload;
        return apiV2.putRequest(`${_SERVICE_URL}/v2/${serviceId}`, {
            useToken: "accessToken",
            data: JSON.stringify(service),
            headers: {
                "content-type": "application/json",
            },
        });
    }

    /**
     * @description delete a service inside a category
     */
    serviceDeleteService(payload: { serviceId: string }) {
        return apiV2.deleteRequest(`${_SERVICE_URL}/${payload.serviceId}`, {
            useToken: "accessToken",
        });
    }

    serviceGetEarningsMetrics(payload: { date: string }) {
        return apiV2.getRequest(`${_SERVICE_URL}/earnings?date=${payload.date}`, {
            useToken: "accessToken",
        });
    }

    serviceGetUnsplashImages(payload: { query: string; page: number }) {
        let page = payload.page ? payload.page : 0;
        let url = `https://api.unsplash.com/photos?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&page=1&per_page=20`;
        if (payload.query) {
            url = `https://api.unsplash.com/search/photos?query=${payload.query}&page=${page}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&per_page=20`;
        }
        return apiV2.getRequest(url, {
            useToken: false,
            useOrg: false,
            headers: {
                authorization: undefined,
                orgid: undefined,
            },
        });
    }
}

export default new ServiceClass();

import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import useError from "../../../../helpers/hooks/useError";
import { useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Button, InputText, toastHelper } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeCAForm } from "../../context/actions";
import { ReactComponent as AddInfoImg } from "@soltivo/draw-a-line/core/assets/illutrations/SteveSinging.svg";
import { RootState } from "../../../../redux/reducers";

interface AddInfoProps extends MapStateToProps {}

const AddInfo: React.FC<AddInfoProps> = ({ error }) => {
    const {
        state: { CAForm },
        dispatch: dispatchContext,
    } = useContext(authContext);
    const history = useHistory();
    const [submited, setSubmited] = useState(false);

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatchContext(
            authOnChangeCAForm({
                ...CAForm,
                [e.target.name]: e.target.value,
            })
        );

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (submited) {
            return;
        }
        let fail = false;
        Object.keys(CAForm).forEach((key) => {
            if (["firstName", "lastName"].includes(key) && !CAForm[key as ReturnType<() => keyof typeof CAForm>]) {
                toastHelper.toastStartContent("danger", "First name is required.");
                fail = true;
                return;
            }
        });
        if (fail) return;
        history.push("/create-organization");
        setSubmited(true);
    };

    useError(error, [() => setSubmited(false)]);

    useEffect(() => {
        dispatchContext(
            authOnChangeCAForm({
                firstName: "",
                lastName: "",
            })
        );
    }, []);

    if (!localStorage.getItem("token")) {
        return <Redirect to="/login" />;
    }
    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Set up your profile</h2>

                        <div className="auth-container__input-container">
                            {Object.keys(CAForm).map((input, index) => {
                                if (input === "firstName" || input === "lastName") {
                                    return (
                                        <InputText
                                            bottomBorderStyle={true}
                                            key={index}
                                            name={input}
                                            onChange={onChange}
                                            value={CAForm[input]}
                                            placeholder={input.replace(/([A-Z])/g, " $1")}
                                            type={"text"}
                                            autoComplete={"off"}
                                            required
                                            className={`auth-container__input`}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}{" "}
                        </div>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="btn auth-container__button-primary" disabled={submited}>
                            Continue
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <AddInfoImg />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});
type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(AddInfo);

export const ONE_DAY_TIME = 86400000;

export const ONE_WEEK_TIME = 604800000;

export const ONE_LEAP_YEAR_TIME = 31622400000;

export const ONE_REGULAR_YEAR_TIME = 31536000000;

export const DAYS_IN_WEEK = 7;

export const FOUR_YEARS_CYCLE = 126230400000;

export const FOUR_HUNDRED_YEARS_CYCLE = 12622780800000;

export const YEAR_2000 = 946684800000;

const UNIX_DAY = (milliseconds) => {
    if (milliseconds < 0) {
        milliseconds -= ONE_DAY_TIME;
    }

    const seconds = milliseconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    return days | 0;
};

const WEEK_DAY = (milliseconds) => {
    const unixDay = UNIX_DAY(milliseconds);

    // UNIX day 0 was a Thursday (4)
    const weekDay = ((unixDay + 4) | 0) % DAYS_IN_WEEK | 0;

    return weekDay; // Sunday is 0.
};

// the year functions are accurate from 1970 to 2099, which is enough for us.

const YEAR_NUMBER = (milliseconds) => {
    let baseYear = 2000;

    if (milliseconds >= YEAR_2000) {
        milliseconds -= YEAR_2000;

        const quotientA = (milliseconds / FOUR_HUNDRED_YEARS_CYCLE) | 0;
        const remainderA = milliseconds % FOUR_HUNDRED_YEARS_CYCLE;

        baseYear = (baseYear + ((quotientA * 400) | 0)) | 0;

        const quotientB = (remainderA / FOUR_YEARS_CYCLE) | 0;
        const remainderB = remainderA % FOUR_YEARS_CYCLE;

        baseYear = (baseYear + ((quotientB * 4) | 0)) | 0;

        if (remainderB >= ONE_LEAP_YEAR_TIME) {
            baseYear = (baseYear + 1) | 0;
        }

        const quotientC = ((remainderB - ONE_LEAP_YEAR_TIME) / ONE_REGULAR_YEAR_TIME) | 0;

        baseYear = (baseYear + quotientC) | 0;
    }

    // TODO: handle time before year 2000
    return baseYear;
};

const LEAP_YEAR = (milliseconds) => {
    const yearNumber = YEAR_NUMBER(milliseconds);

    const conditionA = yearNumber % 4 === 0 && yearNumber % 100 !== 0;
    const conditionB = yearNumber % 400 === 0;

    return conditionA || conditionB;
};

const YEAR_DAY = (milliseconds) => {
    if (milliseconds >= YEAR_2000) {
        milliseconds -= YEAR_2000;

        const remainderA = milliseconds % FOUR_HUNDRED_YEARS_CYCLE;
        const remainderB = remainderA % FOUR_YEARS_CYCLE;

        if (remainderB < ONE_LEAP_YEAR_TIME) {
            return (remainderB / ONE_DAY_TIME) | 0;
        }

        const remainderC = (remainderB - ONE_LEAP_YEAR_TIME) % ONE_REGULAR_YEAR_TIME;
        const quotientC = (remainderC / ONE_DAY_TIME) | 0;

        return quotientC;
    }

    // TODO: handle time before year 2000.
    return -1;
};

const MONTH_JANUARY = 0;
const MONTH_FEBRUARY = 1;
const MONTH_MARCH = 2;
const MONTH_APRIL = 3;
const MONTH_MAY = 4;
const MONTH_JUNE = 5;
const MONTH_JULY = 6;
const MONTH_AUGUST = 7;
const MONTH_SEPTEMBER = 8;
const MONTH_OCTOBER = 9;
const MONTH_NOVEMBER = 10;
const MONTH_DECEMBER = 11;

const JANUARY_DATA = Object.freeze([MONTH_JANUARY, 31]);
const FEBRUARY_DATA_A = Object.freeze([MONTH_FEBRUARY, 28]);
const FEBRUARY_DATA_B = Object.freeze([MONTH_FEBRUARY, 29]);
const MARCH_DATA = Object.freeze([MONTH_MARCH, 31]);
const APRIL_DATA = Object.freeze([MONTH_APRIL, 30]);
const MAY_DATA = Object.freeze([MONTH_MAY, 31]);
const JUNE_DATA = Object.freeze([MONTH_JUNE, 30]);
const JULY_DATA = Object.freeze([MONTH_JULY, 31]);
const AUGUST_DATA = Object.freeze([MONTH_AUGUST, 31]);
const SEPTEMBER_DATA = Object.freeze([MONTH_SEPTEMBER, 30]);
const OCTOBER_DATA = Object.freeze([MONTH_OCTOBER, 31]);
const NOVEMBER_DATA = Object.freeze([MONTH_NOVEMBER, 30]);
const DECEMBER_DATA = Object.freeze([MONTH_DECEMBER, 31]);

const MONTH_DATA = (milliseconds) => {
    const leapYear = LEAP_YEAR(milliseconds);

    let yearDay = YEAR_DAY(milliseconds);

    if (0 <= yearDay && yearDay < 31) {
        return JANUARY_DATA;
    }

    const FEBRUARY_LIMIT = leapYear ? 60 : 59;

    if (31 <= yearDay && yearDay < FEBRUARY_LIMIT) {
        if (leapYear) {
            return FEBRUARY_DATA_B;
        } else {
            return FEBRUARY_DATA_A;
        }
    }

    yearDay = leapYear ? (yearDay - 1) | 0 : yearDay;

    if (59 <= yearDay && yearDay < 90) {
        return MARCH_DATA;
    }

    if (90 <= yearDay && yearDay < 120) {
        return APRIL_DATA;
    }

    if (120 <= yearDay && yearDay < 151) {
        return MAY_DATA;
    }

    if (151 <= yearDay && yearDay < 181) {
        return JUNE_DATA;
    }

    if (181 <= yearDay && yearDay < 212) {
        return JULY_DATA;
    }

    if (212 <= yearDay && yearDay < 243) {
        return AUGUST_DATA;
    }

    if (243 <= yearDay && yearDay < 273) {
        return SEPTEMBER_DATA;
    }

    if (273 <= yearDay && yearDay < 304) {
        return OCTOBER_DATA;
    }

    if (304 <= yearDay && yearDay < 334) {
        return NOVEMBER_DATA;
    }

    if (334 <= yearDay && yearDay < 365) {
        return DECEMBER_DATA;
    }
};

const MONTH_DAY = (milliseconds) => {
    const leapYear = LEAP_YEAR(milliseconds);

    let yearDay = YEAR_DAY(milliseconds);

    if (0 <= yearDay && yearDay < 31) {
        return yearDay;
    }

    const FEBRUARY_LIMIT = leapYear ? 60 : 59;

    if (31 <= yearDay && yearDay < FEBRUARY_LIMIT) {
        return (yearDay - 31) | 0;
    }

    yearDay = leapYear ? (yearDay - 1) | 0 : yearDay;

    if (59 <= yearDay && yearDay < 90) {
        return (yearDay - 59) | 0;
    }

    if (90 <= yearDay && yearDay < 120) {
        return (yearDay - 90) | 0;
    }

    if (120 <= yearDay && yearDay < 151) {
        return (yearDay - 120) | 0;
    }

    if (151 <= yearDay && yearDay < 181) {
        return (yearDay - 151) | 0;
    }

    if (181 <= yearDay && yearDay < 212) {
        return (yearDay - 181) | 0;
    }

    if (212 <= yearDay && yearDay < 243) {
        return (yearDay - 212) | 0;
    }

    if (243 <= yearDay && yearDay < 273) {
        return (yearDay - 243) | 0;
    }

    if (273 <= yearDay && yearDay < 304) {
        return (yearDay - 273) | 0;
    }

    if (304 <= yearDay && yearDay < 334) {
        return (yearDay - 304) | 0;
    }

    if (334 <= yearDay && yearDay < 365) {
        return (yearDay - 334) | 0;
    }
};

export const nextDay = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    return milliseconds + ONE_DAY_TIME - remainder;
};

export const nextWeek = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const weekDay = WEEK_DAY(milliseconds);
    milliseconds -= weekDay * ONE_DAY_TIME;

    return milliseconds + ONE_WEEK_TIME;
};

export const nextMonth = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const monthDay = MONTH_DAY(milliseconds);
    milliseconds -= monthDay * ONE_DAY_TIME;

    const [
        ,
        // monthNumber
        monthDays,
    ] = MONTH_DATA(milliseconds);
    milliseconds += monthDays * ONE_DAY_TIME;

    return milliseconds;
};

export const nextYear = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const yearDay = YEAR_DAY(milliseconds);
    milliseconds -= yearDay * ONE_DAY_TIME;

    if (LEAP_YEAR(milliseconds)) {
        milliseconds += ONE_LEAP_YEAR_TIME;
    } else {
        milliseconds += ONE_REGULAR_YEAR_TIME;
    }

    return milliseconds;
};

export const previousDay = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    return milliseconds - ONE_DAY_TIME - remainder;
};

export const previousWeek = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const weekDay = WEEK_DAY(milliseconds);
    milliseconds -= weekDay * ONE_DAY_TIME;

    return milliseconds - ONE_WEEK_TIME;
};

export const previousMonth = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const monthDay = MONTH_DAY(milliseconds);
    milliseconds -= monthDay * ONE_DAY_TIME;

    // takes away one millisecond in order to be in the previous month.
    milliseconds -= 1;

    const [
        ,
        // monthNumber
        monthDays,
    ] = MONTH_DATA(milliseconds);
    milliseconds -= monthDays * ONE_DAY_TIME;

    // gives back the missing millisecond
    milliseconds += 1;

    return milliseconds;
};

export const previousYear = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const yearDay = YEAR_DAY(milliseconds);
    milliseconds -= yearDay * ONE_DAY_TIME;

    // takes away one millisecond in order to be in the previous year
    milliseconds -= 1;

    if (LEAP_YEAR(milliseconds)) {
        milliseconds -= ONE_LEAP_YEAR_TIME;
    } else {
        milliseconds -= ONE_REGULAR_YEAR_TIME;
    }

    // gives back the missing millisecond
    milliseconds += 1;

    return milliseconds;
};

export const startDay = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    return milliseconds;
};

export const startWeek = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const weekDay = WEEK_DAY(milliseconds);
    milliseconds -= weekDay * ONE_DAY_TIME;

    return milliseconds;
};

export const startMonth = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const monthDay = MONTH_DAY(milliseconds);
    milliseconds -= monthDay * ONE_DAY_TIME;

    return milliseconds;
};

export const startYear = (milliseconds) => {
    const remainder = milliseconds % ONE_DAY_TIME;
    milliseconds -= remainder;

    const yearDay = YEAR_DAY(milliseconds);
    milliseconds -= yearDay * ONE_DAY_TIME;

    return milliseconds;
};

export const weekDay = (milliseconds) => {
    return WEEK_DAY(milliseconds);
};

export const monthData = (milliseconds) => {
    return MONTH_DATA(milliseconds);
};

export const monthDay = (milliseconds) => {
    return MONTH_DAY(milliseconds);
};

export const monthWeekOffset = (milliseconds) => {
    const firstDay = startMonth(milliseconds);
    return WEEK_DAY(firstDay);
};

export const isLeapYear = (milliseconds) => {
    return LEAP_YEAR(milliseconds);
};

export const yearNumber = (milliseconds) => {
    return YEAR_NUMBER(milliseconds);
};

const Gregorian = Object.freeze({
    nextDay,
    nextWeek,
    nextMonth,
    nextYear,

    previousDay,
    previousWeek,
    previousMonth,
    previousYear,
    startDay,
    startWeek,
    startMonth,
    startYear,
    weekDay,
    monthDay,
    monthData,
    monthWeekOffset,
    isLeapYear,
    yearNumber,

    ONE_DAY_TIME,
    ONE_WEEK_TIME,
    ONE_LEAP_YEAR_TIME,
    ONE_REGULAR_YEAR_TIME,
    DAYS_IN_WEEK,
});

export default Gregorian;

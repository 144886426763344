export const emailTypes = {
    EMAIL_CHANGE_STATE_REQUEST: "EMAIL_CHANGE_STATE_REQUEST",
    EMAIL_CANCEL_REQUEST: "EMAIL_CANCEL_REQUEST",

    EMAIL_GET_EMAIL_REQUEST: "EMAIL_GET_EMAIL_REQUEST",
    EMAIL_GET_EMAIL_SUCCESS: "EMAIL_GET_EMAIL_SUCCESS",
    EMAIL_GET_EMAIL_FAILURE: "EMAIL_GET_EMAIL_FAILURE",

    EMAIL_SEND_EMAIL_REQUEST: "EMAIL_SEND_EMAIL_REQUEST",
    EMAIL_SEND_EMAIL_SUCCESS: "EMAIL_SEND_EMAIL_SUCCESS",
    EMAIL_SEND_EMAIL_FAILURE: "EMAIL_SEND_EMAIL_FAILURE",
    EMAIL_SEND_EMAIL_CANCELED: "EMAIL_SEND_EMAIL_CANCELED",

    EMAIL_REPLY_EMAIL_REQUEST: "EMAIL_REPLY_EMAIL_REQUEST",
    EMAIL_REPLY_EMAIL_SUCCESS: "EMAIL_REPLY_EMAIL_SUCCESS",
    EMAIL_REPLY_EMAIL_FAILURE: "EMAIL_REPLY_EMAIL_FAILURE",
    EMAIL_REPLY_EMAIL_CANCELED: "EMAIL_REPLY_EMAIL_CANCELED",

    EMAIL_OUT_EXCHANGES_REQUEST: "EMAIL_OUT_EXCHANGES_REQUEST",
    EMAIL_OUT_EXCHANGES_SUCCESS: "EMAIL_OUT_EXCHANGES_SUCCESS",
    EMAIL_OUT_EXCHANGES_FAILURE: "EMAIL_OUT_EXCHANGES_FAILURE",

    EMAIL_IN_EXCHANGES_REQUEST: "EMAIL_IN_EXCHANGES_REQUEST",
    EMAIL_IN_EXCHANGES_SUCCESS: "EMAIL_IN_EXCHANGES_SUCCESS",
    EMAIL_IN_EXCHANGES_FAILURE: "EMAIL_IN_EXCHANGES_FAILURE",

    EMAIL_DELETE_EXCHANGE_REQUEST: "EMAIL_DELETE_EXCHANGE_REQUEST",
    EMAIL_DELETE_EXCHANGE_SUCCESS: "EMAIL_DELETE_EXCHANGE_SUCCESS",
    EMAIL_DELETE_EXCHANGE_FAILURE: "EMAIL_DELETE_EXCHANGE_FAILURE",

    EMAIL_GET_DELETED_EXCHANGES_REQUEST: "EMAIL_GET_DELETED_EXCHANGES_REQUEST",
    EMAIL_GET_DELETED_EXCHANGES_SUCCESS: "EMAIL_GET_DELETED_EXCHANGES_SUCCESS",
    EMAIL_GET_DELETED_EXCHANGES_FAILURE: "EMAIL_GET_DELETED_EXCHANGES_FAILURE",

    EMAIL_RESTORE_EMAIL_REQUEST: "EMAIL_RESTORE_EMAIL_REQUEST",
    EMAIL_RESTORE_EMAIL_SUCCESS: "EMAIL_RESTORE_EMAIL_SUCCESS",
    EMAIL_RESTORE_EMAIL_FAILURE: "EMAIL_RESTORE_EMAIL_FAILURE",

    EMAIL_SETUP_EMAIL_BUSINESS_REQUEST: "EMAIL_SETUP_EMAIL_BUSINESS_REQUEST",
    EMAIL_SETUP_EMAIL_BUSINESS_SUCCESS: "EMAIL_SETUP_EMAIL_BUSINESS_SUCCESS",
    EMAIL_SETUP_EMAIL_BUSINESS_FAILURE: "EMAIL_SETUP_EMAIL_BUSINESS_FAILURE",

    EMAIL_SEARCH_ENTITY_CONTACTS_REQUEST: "EMAIL_SEARCH_ENTITY_CONTACTS_REQUEST",
    EMAIL_SEARCH_ENTITY_CONTACTS_SUCCESS: "EMAIL_SEARCH_ENTITY_CONTACTS_SUCCESS",
    EMAIL_SEARCH_ENTITY_CONTACTS_FAILURE: "EMAIL_SEARCH_ENTITY_CONTACTS_FAILURE",

    EMAIL_MARK_READ_AS_REQUEST: "EMAIL_MARK_READ_AS_REQUEST",
    EMAIL_MARK_READ_AS_SUCCESS: "EMAIL_MARK_READ_AS_SUCCESS",
    EMAIL_MARK_READ_AS_FAILURE: "EMAIL_MARK_READ_AS_FAILURE",
};

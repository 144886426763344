import apiV2 from "../../../../helpers/api.v2";

const API_URL = `${process.env.REACT_APP_API_URL}/builder`;

/**
 * @description apis related to the wizard editor.
 */
class WebsiteWizardClass {
    /**
     * @description get list of themes.
     * @observe this api only calls themes related to the organization industry.
     */
    websiteGetThemes() {
        return apiV2.getRequest(`${API_URL}/themes`, {
            useToken: "accessToken",
            useOrg: true,
        });
    }

    /**
     * @description get website pages published in a organization
     */
    websiteGetThemePages() {
        return apiV2.getRequest(`${API_URL}/website`, {
            useToken: "accessToken",
            useOrg: true,
        });
    }
}

export default new WebsiteWizardClass();

import React, { useState, useEffect, useContext } from "react";
import { useDispatch, connect } from "react-redux";
import { useError, Button, InputText, InputSelect, toastHelper } from "@soltivo/draw-a-line";
import { Redirect } from "react-router-dom";
import { orgCreatePrimaryOrganization } from "../../redux/org/reducer/actions";
import GOALS from "../../../../helpers/json/goals.json";
import authContext from "../../context/auth.context";
import { authOnChangeCAForm } from "../../context/actions";
import { ReactComponent as CreateOrgImg } from "@soltivo/draw-a-line/core/assets/illutrations/SteveWithPen.svg";
import { ReactComponent as CreateOrgLoaderImg } from "@soltivo/draw-a-line/core/assets/illutrations/SteveWithBirth.svg";
import { RootState } from "../../../../redux/reducers";

interface CreateOrganizationProps extends MapStateToProps {}

const CreateOrganization: React.FC<CreateOrganizationProps> = ({ error }) => {
    const {
        state: { CAForm },
        dispatch: dispatchContext,
    } = useContext(authContext);
    const dispatch = useDispatch();

    const [submited, setSubmited] = useState(false);

    const onChangeGoal = ({ value, name }: { name: any; value: string | number | JSX.Element }) => {
        dispatchContext(authOnChangeCAForm({ ...CAForm, [name]: value }));
    };

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatchContext(authOnChangeCAForm({ ...CAForm, [e.target.name]: e.target.value }));

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (submited) {
            return;
        }
        let fail = false;
        const goal = GOALS.find((goal) => goal.value === CAForm.orgGoal);
        Object.keys(CAForm).forEach((key) => {
            if (!CAForm[key as ReturnType<() => keyof typeof CAForm>]) {
                fail = true;
                toastHelper.toastStartContent("danger", `${key.replace(/_/g, "")} is required.`);
            }
        });

        if (fail || !goal) return;

        dispatch(
            orgCreatePrimaryOrganization({
                organization: {
                    name: CAForm.orgName,
                    goal: goal?.camelCase,
                },
                user: {
                    firstName: CAForm.firstName,
                    lastName: CAForm.lastName,
                },
            })
        );
        setSubmited(true);
    };

    useError(error, [() => setSubmited(false)]);

    useEffect(() => {
        dispatchContext(authOnChangeCAForm({ ...CAForm, orgName: "", orgGoal: "" }));
    }, []);

    if (!CAForm.firstName || !CAForm.lastName) {
        return <Redirect to="/add-info" />;
    }

    if (submited) {
        return (
            <div className="org-creation__loader--wrapper">
                <CreateOrgLoaderImg />
                <p>We are creating your organization</p>
            </div>
        );
    }

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Create your organization</h2>

                        <div className="auth-container__input-container">
                            <InputText
                                bottomBorderStyle={true}
                                name={"orgName"}
                                onChange={onChange}
                                value={CAForm["orgName"]}
                                placeholder={"Business name"}
                                type={"text"}
                                autoComplete={"off"}
                                className={`auth-container__input`}
                            />
                            <InputSelect
                                data-testid="inputSelect"
                                className={`auth-container__input industry`}
                                name="orgGoal"
                                placeholder="Goal"
                                options={GOALS}
                                value={CAForm["orgGoal"]}
                                onChange={({ value, name }) => onChangeGoal({ value, name })}
                            />
                        </div>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="btn auth-container__button-primary" disabled={submited}>
                            Create
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <CreateOrgImg />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ OrgReducer }: RootState) => ({
    error: OrgReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(CreateOrganization);

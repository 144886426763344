import { DraggableLocation } from "react-beautiful-dnd";
import { ContactEntity } from "../../../contacts/contacts";
import { AppointmentDefaultStatus } from "../../appointment";
import { AppointmentState } from "./reducer";
import { types } from "./types";
import { Appointment } from "@soltivo/types";

/**
 * @description on change appointment state
 * @warning be careful using this action you could break the application.
 */
export const appointmentChangeState = (payload: Partial<AppointmentState>) => ({
    type: types.APPOINTMENT_CHANGE_STATE_REQUEST,
    payload,
});

/** @description Change the appointment state in reducer */
export const appointmentSetCurrent = (payload: AppointmentState["appointment"]) => ({
    type: types.APPOINTMENT_SET_APPOINTMENT_REQUEST,
    payload,
});

/**
 * @description get appointment by id.
 */
export const appointmentGetAppointment = (payload: { appointmentId: string }) => ({
    type: types.APPOINTMENT_GET_APPOINTMENT_REQUEST,
    payload,
});

/**
 * @description Create appointment
 */
export const appointmentCreateAppointment = (
    payload: Partial<{
        userId: string;
        serviceId: string;
        start: string;
        end: string;
        locationId: string;
        entityInfo: Partial<Pick<ContactEntity, "email" | "phoneNumber" | "firstName" | "lastName">>;
    }>,
    options?: {
        reschedule?: {
            appointmentId: string;
            moveTo: AppointmentDefaultStatus;
        };
    }
) => ({
    type: types.APPOINTMENT_CREATE_APPOINTMENT_REQUEST,
    payload,
    options,
});

/**
 * @description get entity past appointments
 */
export const appointmentListByEntityId = (payload: { entityId: string; limit?: number; lastKey?: string }) => ({
    type: types.APPOINTMENT_LIST_BY_ENTITY_ID_REQUEST,
    payload,
});

/**
 * @description Get quick days
 */
export const appointmentGetQuickDates = (payload: { locationId: string; userId: string; serviceId: string; start: string }) => ({
    type: types.APPOINTMENT_GET_QUICK_DATES_REQUEST,
    payload,
});

/**
 * @description Get available Dates
 */
export const appointmentGetAvailableDates = (payload: { start: string; end: string; userId: string; serviceId: string; locationId: string }) => ({
    type: types.APPOINTMENT_GET_AVAILABLE_DATES_REQUEST,
    payload,
});

/**
 * @description Get slots time
 */
export const appointmentGetSlotTimes = (payload: { start: string; end: string; userId: string; serviceId: string; locationId: string }) => ({
    type: types.APPOINTMENT_GET_SLOTS_REQUEST,
    payload,
});

/**
 * @description Get all appointments statuses
 */
// export const appointmentGetAppointmentStatuses = () => ({
//     type: types.APPOINTMENT_GET_APPOINTMENT_STATUSES_REQUEST,
// });

/**
 * @description Create status
 */
// export const appointmentCreateStatus = (payload: { name: string }) => ({
//     type: types.APPOINTMENT_CREATE_STATUS_REQUEST,
//     payload,
// });

/**
 * @description Update status of an appointment
 */
export const appointmentUpdateStatus = (payload: {
    appointmentId: string;
    moveTo: AppointmentDefaultStatus | string;
    kanban?: {
        source: DraggableLocation;
        destination: DraggableLocation;
        appointment: Appointment;
    };
}) => ({
    type: types.APPOINTMENT_UPDATE_STATUS_REQUEST,
    payload,
});

/**
 * @description Arrange appointments statuses
 */
// export const appointmentArrangeStatuses = (payload: { steps: string[]; statuses: Statuses[] }) => ({
//     type: types.APPOINTMENT_ARRANGE_STATUSES_REQUEST,
//     payload,
// });

/**
 * @description update status name
 */
// export const appointmentUpdateStatusName = (payload: { statusId: string; name: string }) => ({
//     type: types.APPOINTMENT_UPDATE_STATUS_NAME_REQUEST,
//     payload,
// });

/**
 * @description Delete status column
 */
// export const appointmentDeleteStatusColumn = (payload: { movedTo: string; statusId: string }) => ({
//     type: types.APPOINTMENT_DELETE_STATUS_REQUEST,
//     payload,
// });

/**
 * @description Bulk update appointment
 */
export const appointmentBulkUpdateAppointment = (payload: { appointmentsIds: string[]; moveTo: string }) => ({
    type: types.APPOINTMENT_BULK_UPDATE_APPOINTMENT_REQUEST,
    payload,
});

/**
 * @description List appointment's notes.
 */
export const appointmentListNotes = (payload: { appointmentId: string }) => ({
    type: types.APPOINTMENT_LIST_NOTE_REQUEST,
    payload,
});

/**
 * @description create appointment note.
 */
export const appointmentCreateNote = (payload: { appointmentId: string; note: string }) => ({
    type: types.APPOINTMENT_CREATE_NOTE_REQUEST,
    payload,
});

/**
 * @description create appointment note.
 */
export const appointmentUpdateNote = (payload: { appointmentId: string; note: string }) => ({
    type: types.APPOINTMENT_UPDATE_NOTE_REQUEST,
    payload,
});

/**
 * @description delete appointment
 */
export const appointmentDeleteAppointment = (payload: { appointmentId: string; employeeId?: string }) => ({
    type: types.APPOINTMENT_DELETE_APPOINTMENT_REQUEST,
    payload,
});

/**
 * @description get the appointments of a specific kanban status
 */
export const appointmentGetAppointmentsByStatus = (payload: {
    range: { startDate: string; endDate: string };
    includeArchived?: boolean;
    statusId: string;
    entityId?: string;
    userId?: string;
    lastKey?: string;
}) => ({
    type: types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST,
    payload,
});

/**
 * @description archive appointments
 */
export const appointmentArchiveAppointments = (payload: { appointmentIds: string[]; statusId: string; isArchived: boolean }) => ({
    type: types.APPOINTMENT_ARCHIVE_APPOINTMENTS_REQUEST,
    payload,
});

/**
 * @description archive single appointment
 */
export const appointmentArchiveAppointment = (payload: { aptId: string; isArchived: boolean }) => ({
    type: types.APPOINTMENT_ARCHIVE_APPOINTMENT_REQUEST,
    payload,
});

/**
 * @description Get all user events
 */
interface AppointmentGetTeamEventsPayload {
    userId?: string;
    start?: string;
    end?: string;
    type?: "apt" | "event";
    includeArchived?: boolean;
}
export const appointmentGetTeamEvents = (payload: AppointmentGetTeamEventsPayload) => ({
    type: types.APPOINTMENT_GET_TEAM_EVENTS_REQUEST,
    payload,
});

/**
 * @description create personal event
 */
export const appointmentCreatePersonalEvent = (payload: { title: string; start: string; end: string; isPrivate: boolean; userId: string }) => ({
    type: types.APPOINTMENT_CREATE_PERSONAL_EVENT_REQUEST,
    payload,
});

/**
 * @description delete personnal event
 */
export const appointmentDeleteEvent = (payload: { id: string; employeeId?: string }) => ({
    type: types.APPOINTMENT_DELETE_EVENT_REQUEST,
    payload,
});

/**
 * @description update personnal event
 */
export const appointmentUpdateEvent = (payload: {
    event: {
        title: string;
        start: string;
        end: string;
        isPrivate: boolean;
        userId: string;
    };
    id: string;
}) => ({
    type: types.APPOINTMENT_UPDATE_EVENT_REQUEST,
    payload,
});

/**
 * @description reschedule appointment
 */
export const appointmentRescheduleAppointment = (payload: {
    appointment: {
        userId: string;
        serviceId: string;
        start: string;
        end: string;
        locationId: string;
        entityInfo: Partial<Pick<ContactEntity, "email" | "phoneNumber" | "firstName" | "lastName">>;
    };
    appointmentId: string;
}) => ({
    type: types.APPOINTMENT_RESCHEDULE_APPOINTMENT_REQUEST,
    payload,
});

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

/** 
 @description returns organization time zone
*/
const useOrgTimeZone = (): string => {
    const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
        org: OrgReducer.org,
        user: AuthReducer.user,
    });

    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { org, user } = useSelector(mapStateToProps);
    //@ts-ignore
    const defaultTz: string = user?.timeZone ? user?.timeZone : org?.timeZone ? org?.timeZone: browserTimezone
    const [timeZone, setTimeZone] = useState<string>(defaultTz);

    useEffect(() => {
        // @ts-ignore return user timezone
        if(user?.timeZone) {
            //@ts-ignore
            setTimeZone(user?.timeZone);
        } else if(org?.timeZone) {
            // return org timezone
            setTimeZone(org?.timeZone);
        }
    }, [org, user]);

    return timeZone || browserTimezone;
}

export default useOrgTimeZone;
import React from 'react';
import Icon from './icon.component';

export const Actiondot = props => <Icon {...props} name="actiondot" />;
export const AddGroup = props => <Icon {...props} name="add_group" />;
export const AddImage = props => <Icon {...props} name="add_image" />;
export const Anex = props => <Icon {...props} name="anex" />;
export const AngleBracketLeft = props => <Icon {...props} name="angle_bracket_left" />;
export const AngleBracketRight = props => <Icon {...props} name="angle_bracket_right" />;
export const ArrowBackwards = props => <Icon {...props} name="arrow_backwards" />;
export const ArrowDownFilled = props => <Icon {...props} name="arrow_down_filled" />;
export const ArrowDropdown = props => <Icon {...props} name="arrow_dropdown" />;
export const ArrowLeft = props => <Icon {...props} name="arrow_left" />;
export const ArrowRight = props => <Icon {...props} name="arrow_right" />;
export const Assignment = props => <Icon {...props} name="assignment" />;
export const AssignmentInd = props => <Icon {...props} name="assignment_ind" />;
export const Attachment = props => <Icon {...props} name="attachment" />;
export const AudioFile = props => <Icon {...props} name="audio_file" />;
export const Back = props => <Icon {...props} name="back" />;
export const Basket = props => <Icon {...props} name="basket" />;
export const Bell = props => <Icon {...props} name="bell" />;
export const BlankFile = props => <Icon {...props} name="blank_file" />;
export const Block = props => <Icon {...props} name="block" />;
export const Building = props => <Icon {...props} name="building" />;
export const Calendar = props => <Icon {...props} name="calendar" />;
export const CallHangUp = props => <Icon {...props} name="call_hang_up" />;
export const CallPickUp = props => <Icon {...props} name="call_pick_up" />;
export const Chat = props => <Icon {...props} name="chat" />;
export const Check = props => <Icon {...props} name="check" />;
export const CheckCicleOutline = props => <Icon {...props} name="check_cicle_outline" />;
export const Checked = props => <Icon {...props} name="checked" />;
export const ChevronLeft = props => <Icon {...props} name="chevron_left" />;
export const ChevronRight = props => <Icon {...props} name="chevron_right" />;
export const Clock = props => <Icon {...props} name="clock" />;
export const Close = props => <Icon {...props} name="close" />;
export const CloudUpload = props => <Icon {...props} name="cloud_upload" />;
export const CloudUploadOutlined = props => <Icon {...props} name="cloud_upload_outlined" />;
export const Computer = props => <Icon {...props} name="computer" />;
export const Copy = props => <Icon {...props} name="copy" />;
export const Crm = props => <Icon {...props} name="crm" />;
export const CropFree = props => <Icon {...props} name="crop_free" />;
export const Date = props => <Icon {...props} name="date" />;
export const Delete = props => <Icon {...props} name="delete" />;
export const DisableAudio = props => <Icon {...props} name="disable_audio" />;
export const DisableVideo = props => <Icon {...props} name="disable_video" />;
export const Doublecheck = props => <Icon {...props} name="doublecheck" />;
export const Download = props => <Icon {...props} name="download" />;
export const Email = props => <Icon {...props} name="email" />;
export const ErrorCircle = props => <Icon {...props} name="error_circle" />;
export const ErrorTriangle = props => <Icon {...props} name="error_triangle" />;
export const Eye = props => <Icon {...props} name="eye" />;
export const FirstPage = props => <Icon {...props} name="first_page" />;
export const Folder = props => <Icon {...props} name="folder" />;
export const FolderAdd = props => <Icon {...props} name="folder_add" />;
export const FolderDoc = props => <Icon {...props} name="folder_doc" />;
export const FolderImage = props => <Icon {...props} name="folder_image" />;
export const FolderPdf = props => <Icon {...props} name="folder_pdf" />;
export const FolderPresentation = props => <Icon {...props} name="folder_presentation" />;
export const FolderSound = props => <Icon {...props} name="folder_sound" />;
export const FolderVideo = props => <Icon {...props} name="folder_video" />;
export const GlobalFile = props => <Icon {...props} name="global-file" />;
export const Help = props => <Icon {...props} name="help" />;
export const Illustration = props => <Icon {...props} name="illustration" />;
export const Image = props => <Icon {...props} name="image" />;
export const ImageFile = props => <Icon {...props} name="image_file" />;
export const ImagePreview = props => <Icon {...props} name="image_preview" />;
export const Info = props => <Icon {...props} name="info" />;
export const Kanban = props => <Icon {...props} name="kanban" />;
export const LastPage = props => <Icon {...props} name="last_page" />;
export const List = props => <Icon {...props} name="list" />;
export const LoadingFile = props => <Icon {...props} name="loading_file" />;
export const Lock = props => <Icon {...props} name="lock" />;
export const Logout = props => <Icon {...props} name="logout" />;
export const LostCallAnotherCall = props => <Icon {...props} name="lost_call_another_call" />;
export const LostCallUnavailable = props => <Icon {...props} name="lost_call_unavailable" />;
export const Minus = props => <Icon {...props} name="minus" />;
export const Mobile = props => <Icon {...props} name="mobile" />;
export const Pallette = props => <Icon {...props} name="pallette" />;
export const Pause = props => <Icon {...props} name="pause" />;
export const PdfFile = props => <Icon {...props} name="pdf-file" />;
export const Pen = props => <Icon {...props} name="pen" />;
export const Pencil = props => <Icon {...props} name="pencil" />;
export const PencilSecondary = props => <Icon {...props} name="pencil_secondary" />;
export const Phone = props => <Icon {...props} name="phone" />;
export const Pipe = props => <Icon {...props} name="pipe" />;
export const Play = props => <Icon {...props} name="play" />;
export const Plus = props => <Icon {...props} name="plus" />;
export const Redo = props => <Icon {...props} name="redo" />;
export const Refresh = props => <Icon {...props} name="refresh" />;
export const Reply = props => <Icon {...props} name="reply" />;
export const Schedule = props => <Icon {...props} name="schedule" />;
export const Scissors = props => <Icon {...props} name="scissors" />;
export const Screen = props => <Icon {...props} name="screen" />;
export const Search = props => <Icon {...props} name="search" />;
export const SearchSecondary = props => <Icon {...props} name="search_secondary" />;
export const Sent = props => <Icon {...props} name="sent" />;
export const Share = props => <Icon {...props} name="share" />;
export const ShareScreen = props => <Icon {...props} name="share_screen" />;
export const Smiley = props => <Icon {...props} name="smiley" />;
export const SmileyFlame = props => <Icon {...props} name="smiley_flame" />;
export const SmileyHappy = props => <Icon {...props} name="smiley_happy" />;
export const SmileyKnockedOut = props => <Icon {...props} name="smiley_knocked_out" />;
export const SmileyNeutral = props => <Icon {...props} name="smiley_neutral" />;
export const SmileyOverjoyed = props => <Icon {...props} name="smiley_overjoyed" />;
export const SmileySad = props => <Icon {...props} name="smiley_sad" />;
export const Storage = props => <Icon {...props} name="storage" />;
export const Store = props => <Icon {...props} name="store" />;
export const SuccessCicle = props => <Icon {...props} name="success_cicle" />;
export const Surline = props => <Icon {...props} name="surline" />;
export const SystemVerticalAlt = props => <Icon {...props} name="system_vertical_alt" />;
export const Tag = props => <Icon {...props} name="tag" />;
export const TagHeart = props => <Icon {...props} name="tag_heart" />;
export const TeamCalendar = props => <Icon {...props} name="team_calendar" />;
export const Template = props => <Icon {...props} name="template" />;
export const ThumbsDown = props => <Icon {...props} name="thumbs_down" />;
export const ThumbsUp = props => <Icon {...props} name="thumbs_up" />;
export const Undo = props => <Icon {...props} name="undo" />;
export const Uneye = props => <Icon {...props} name="uneye" />;
export const UnknownFile = props => <Icon {...props} name="unknown_file" />;
export const Update = props => <Icon {...props} name="update" />;
export const Upload = props => <Icon {...props} name="upload" />;
export const User = props => <Icon {...props} name="user" />;
export const UsersConf = props => <Icon {...props} name="users_conf" />;
export const VideoCall = props => <Icon {...props} name="video_call" />;
export const VideoFile = props => <Icon {...props} name="video_file" />;
export const VideoSwitch = props => <Icon {...props} name="video_switch" />;
export const ViewAgenda = props => <Icon {...props} name="view_agenda" />;
export const ViewCarousel = props => <Icon {...props} name="view_carousel" />;
export const ViewWeek = props => <Icon {...props} name="view_week" />;
export const VisaLogo = props => <Icon {...props} name="visa_logo" />;
export const VpnKey = props => <Icon {...props} name="vpn_key" />;
export const Warning = props => <Icon {...props} name="warning" />;
export const Website = props => <Icon {...props} name="website" />;
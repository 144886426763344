export const types = {
    INVENTORY_CHANGE_STATE: "INVENTORY_CHANGE_STATE",
    INVENTORY_RESET_ITEM: "INVENTORY_RESET_ITEM",

    INVENTORY_GET_EQUIPMENTS_REQUEST: "INVENTORY_GET_EQUIPMENTS_REQUEST",
    INVENTORY_GET_EQUIPMENTS_SUCCESS: "INVENTORY_GET_EQUIPMENTS_SUCCESS",
    INVENTORY_GET_EQUIPMENTS_FAILURE: "INVENTORY_GET_EQUIPMENTS_FAILURE",

    INVENTORY_GET_EQUIPMENT_REQUEST: "INVENTORY_GET_EQUIPMENT_REQUEST",
    INVENTORY_GET_EQUIPMENT_SUCCESS: "INVENTORY_GET_EQUIPMENT_SUCCESS",
    INVENTORY_GET_EQUIPMENT_FAILURE: "INVENTORY_GET_EQUIPMENT_FAILURE",
    
    INVENTORY_CREATE_EQUIPMENT_REQUEST: "INVENTORY_CREATE_EQUIPMENT_REQUEST",
    INVENTORY_CREATE_EQUIPMENT_SUCCESS: "INVENTORY_CREATE_EQUIPMENT_SUCCESS",
    INVENTORY_CREATE_EQUIPMENT_FAILURE: "INVENTORY_CREATE_EQUIPMENT_FAILURE",
    
    INVENTORY_UPDATE_EQUIPMENT_REQUEST: "INVENTORY_UPDATE_EQUIPMENT_REQUEST",
    INVENTORY_UPDATE_EQUIPMENT_SUCCESS: "INVENTORY_UPDATE_EQUIPMENT_SUCCESS",
    INVENTORY_UPDATE_EQUIPMENT_FAILURE: "INVENTORY_UPDATE_EQUIPMENT_FAILURE",
    
    INVENTORY_AUTO_UPDATE_EQUIPMENT_REQUEST: "INVENTORY_AUTO_UPDATE_EQUIPMENT_REQUEST",
    INVENTORY_AUTO_UPDATE_EQUIPMENT_SUCCESS: "INVENTORY_AUTO_UPDATE_EQUIPMENT_SUCCESS",
    INVENTORY_AUTO_UPDATE_EQUIPMENT_FAILURE: "INVENTORY_AUTO_UPDATE_EQUIPMENT_FAILURE",

    INVENTORY_ARCHIVE_EQUIPMENT_REQUEST: "INVENTORY_ARCHIVE_EQUIPMENT_REQUEST",
    INVENTORY_ARCHIVE_EQUIPMENT_SUCCESS: "INVENTORY_ARCHIVE_EQUIPMENT_SUCCESS",
    INVENTORY_ARCHIVE_EQUIPMENT_FAILURE: "INVENTORY_ARCHIVE_EQUIPMENT_FAILURE",
    
    INVENTORY_DELETE_EQUIPMENT_REQUEST: "INVENTORY_DELETE_EQUIPMENT_REQUEST",
    INVENTORY_DELETE_EQUIPMENT_SUCCESS: "INVENTORY_DELETE_EQUIPMENT_SUCCESS",
    INVENTORY_DELETE_EQUIPMENT_FAILURE: "INVENTORY_DELETE_EQUIPMENT_FAILURE",
    
    INVENTORY_SEARCH_EQUIPMENTS_REQUEST: "INVENTORY_SEARCH_EQUIPMENTS_REQUEST",
    INVENTORY_SEARCH_EQUIPMENTS_SUCCESS: "INVENTORY_SEARCH_EQUIPMENTS_SUCCESS",
    INVENTORY_SEARCH_EQUIPMENTS_FAILURE: "INVENTORY_SEARCH_EQUIPMENTS_FAILURE",
    
    INVENTORY_SEARCH_PRODUCT_REQUEST: "INVENTORY_SEARCH_PRODUCT_REQUEST",
    INVENTORY_SEARCH_PRODUCT_SUCCESS: "INVENTORY_SEARCH_PRODUCT_SUCCESS",
    INVENTORY_SEARCH_PRODUCT_FAILURE: "INVENTORY_SEARCH_PRODUCT_FAILURE",
    
    INVENTORY_GET_PRODUCTS_REQUEST: "INVENTORY_GET_PRODUCTS_REQUEST",
    INVENTORY_GET_PRODUCTS_SUCCESS: "INVENTORY_GET_PRODUCTS_SUCCESS",
    INVENTORY_GET_PRODUCTS_FAILURE: "INVENTORY_GET_PRODUCTS_FAILURE",
    
    INVENTORY_CREATE_PRODUCT_REQUEST: "INVENTORY_CREATE_PRODUCT_REQUEST",
    INVENTORY_CREATE_PRODUCT_SUCCESS: "INVENTORY_CREATE_PRODUCT_SUCCESS",
    INVENTORY_CREATE_PRODUCT_FAILURE: "INVENTORY_CREATE_PRODUCT_FAILURE",
    
    INVENTORY_UPDATE_PRODUCT_REQUEST: "INVENTORY_UPDATE_PRODUCT_REQUEST",
    INVENTORY_UPDATE_PRODUCT_SUCCESS: "INVENTORY_UPDATE_PRODUCT_SUCCESS",
    INVENTORY_UPDATE_PRODUCT_FAILURE: "INVENTORY_UPDATE_PRODUCT_FAILURE",
    
    INVENTORY_GET_PRODUCT_REQUEST: "INVENTORY_GET_PRODUCT_REQUEST",
    INVENTORY_GET_PRODUCT_SUCCESS: "INVENTORY_GET_PRODUCT_SUCCESS",
    INVENTORY_GET_PRODUCT_FAILURE: "INVENTORY_GET_PRODUCT_FAILURE",
    
    INVENTORY_DELETE_PRODUCT_REQUEST: "INVENTORY_DELETE_PRODUCT_REQUEST",
    INVENTORY_DELETE_PRODUCT_SUCCESS: "INVENTORY_DELETE_PRODUCT_SUCCESS",
    INVENTORY_DELETE_PRODUCT_FAILURE: "INVENTORY_DELETE_PRODUCT_FAILURE",
    
    INVENTORY_ARCHIVE_PRODUCT_REQUEST: "INVENTORY_ARCHIVE_PRODUCT_REQUEST",
    INVENTORY_ARCHIVE_PRODUCT_SUCCESS: "INVENTORY_ARCHIVE_PRODUCT_SUCCESS",
    INVENTORY_ARCHIVE_PRODUCT_FAILURE: "INVENTORY_ARCHIVE_PRODUCT_FAILURE",
};

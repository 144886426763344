import styles from "./step.services.module.scss";
import useGuideHeading from "../../../hooks/useGuideHeading";
import GuideOrgContext from "../../../context/guide.org.context";
import { useContext, useEffect } from "react";
import { guideChangeAction, guideOrgChangeFormState } from "../../../context/actions";
import { Plus } from "@soltivo/draw-a-line/core/components/icons";
import { Button } from "@soltivo/draw-a-line";
import { useDispatch, useSelector } from "react-redux";
import { orgSetup } from "../../../../../../applications/auth/redux/org/reducer/actions";
import { Organization } from "../../../../../../applications/auth/organization";
import { RootState } from "../../../../../../redux/reducers";
import CategoryCreation from "../../../components/category.create/category.create";

const StepServices = () => {
    const mapStateToProps = ({ OrgReducer, ServiceReducer }: RootState) => ({
        org: OrgReducer.org as Organization,
        allServices: ServiceReducer.allServices,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;

    const {
        org,
    }: // allServices
    MapStateToProps = useSelector(mapStateToProps);

    const dispatch = useDispatch();

    const {
        state: { form },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    useGuideHeading({
        title: "Create your service menu",
        description: "A service must be inside a category in order to be created."
    });

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    value: "Create",
                    onClick: () => dispatch(
                        orgSetup({
                            nextStep: org.businessType === "individual" ? "confirmation" : "employees",
                            data: {
                                service: form.service,
                            },
                        })
                    )
                },
            })
        );
    }, [form.service, org]);

    useEffect(() => {
        let disabled = false;

        if (!form.service.categories.length) {
            disabled = true;
        } else {
            for (let i = 0; i < form.service.categories.length; i++) {
                const category = form.service.categories[i];
                if (!category.services.length || !category.name) {
                    disabled = true;
                }
                const services = form.service.categories[i].services;
                for (let index = 0; index < services.length; index++) {
                    const service = services[index];
                    if (!service.title || !service.duration) {
                        disabled = true;
                    } else if (service.title.length < 3) {
                        disabled = true;
                    } else if (service.duration === "00:00") {
                        disabled = true;
                    } else if (!service.price) {
                        disabled = true;
                    }  else if (!service.bufferTime) {
                        disabled = true;
                    } else if(org.businessType === "multi-organization") {
                        if (!service.locations.length) {
                            disabled = true;
                        }
                    }
                }
            }
        }

        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    disabled: disabled,
                },
            })
        );

        return () => {
            disabled = false;
        };
    }, [form.service]);

    useEffect(() => {
        // Initialize empty category & service
        if(!org.locations.length) return;
        dispatchContext(
            guideOrgChangeFormState({
                service: {
                    categories: [
                        {
                            name: "",
                            description: "",
                            services: [
                                {
                                    bufferTime: "",
                                    category: "",
                                    duration: "00:00",
                                    price: "",
                                    title: "",
                                    locations: org.businessType !== "multi-organization" ? org.locations.map(l => l.id) : [],
                                }
                            ]
                        }
                    ]
                },
            })
        );
    }, [org.locations]);

    const addNewCategoryLine = () => {
        dispatchContext(
            guideOrgChangeFormState({
                service: {
                    ...form.service,
                    categories: [
                        ...form.service.categories,
                        {
                            name: "",
                            description: "",
                            services: [
                                {
                                    bufferTime: "",
                                    category: "",
                                    duration: "00:00",
                                    price: "",
                                    title: "",
                                    locations: org.businessType !== "multi-organization" ? org.locations.map(l => l.id) : [],
                                }
                            ]
                        }
                    ]
                },
            })
        );
    };
    
    return (
        <div className={styles.services}>
            <div className={styles.services__creation}>
                <div className={styles.actions__buttons}>
                    <Button data-testid="addCategoryLine" onClick={addNewCategoryLine} variant="primary" outline padding={false}>
                        <Plus /> Add A Category
                    </Button>
                </div>
                <table className={styles.services__table}>
                    {
                        form.service.categories.map((category, index) => (
                            <CategoryCreation 
                                key={index}
                                categoryIndex={index}
                                category={category}
                            />
                        ))
                    }
                </table>
            </div>
        </div>
    );
};

export default StepServices;

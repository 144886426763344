import { put, call, takeLatest, select, CallEffect, PutEffect, SelectEffect, delay } from "redux-saga/effects";
import { types as orgTypes } from "../../../../auth/redux/org/reducer/types";
import popupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import { types } from "../reducer/types";
import billingsClass from "./billings.class";
import { SagaIterator } from "@redux-saga/core";
import { RootState } from "../../../../../redux/reducers";
import { BillingsCustomer, BillingsInvoices } from "../../../billings";
import { PaymentMethod } from "@stripe/stripe-js";
import apiV2 from "../../../../../helpers/api.v2";

/**
 * @description
 */
function* billingsGetPlan({
    payload,
}: {
    type: string;
    payload: {
        planId: string;
    };
}): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsGetPlan, payload);
        yield put({ type: types.BILLINGS_GET_PLAN_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_PLAN_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization invoices.
 */
function* billingsGetCustomer({
    payload,
}: {
    type: string;
    payload: {
        customerId: string;
    };
}): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsGetCustomer, payload);
        yield put({ type: types.BILLINGS_GET_CUSTOMER_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.code === 500) {
            if (error.errors[0].reason === "InternalServerError") {
                yield put({
                    type: types.BILLINGS_GET_CUSTOMER_REQUEST,
                    payload: payload,
                });
            }
            return;
        }
        yield put({
            type: types.BILLINGS_GET_CUSTOMER_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get all customers within an org
 */
function* billingsGetCustomers(): SagaIterator<void> {
    try {
        const data = yield call(billingsClass.billingsGetCustomers);
        yield put({
            type: types.BILLINGS_GET_CUSTOMERS_SUCCESS,
            payload: data.data,
        });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_CUSTOMERS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description update customer (using only to update default payment method at the time.)
 */
function* billingsUpdateCustomer({
    payload,
}: {
    type: string;
    payload: {
        customerId: string;
        invoice_settings?: {
            default_payment_method?: string;
        };
    };
}): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsUpdateCustomer, payload);

        const { customer }: { customer: BillingsCustomer } = yield select(({ BillingsReducer }: RootState) => ({
            customer: BillingsReducer.customer,
        }));

        if (payload.invoice_settings?.default_payment_method) {
            if (customer.invoice_settings.default_payment_method !== payload.invoice_settings?.default_payment_method) {
                toastHelper.toastStartContent("success", "Primary card has been set successfully.");
            }
        }

        yield put({ type: types.BILLINGS_UPDATE_CUSTOMER_SUCCESS, payload: data });
    } catch (error: any) {
        const { customer }: { customer: BillingsCustomer } = yield select(({ BillingsReducer }: RootState) => ({
            customer: BillingsReducer.customer,
        }));

        if (payload.invoice_settings?.default_payment_method) {
            if (customer.invoice_settings.default_payment_method !== payload.invoice_settings?.default_payment_method) {
                toastHelper.toastStartContent("danger", "Failed to set primary card.");
            }
        }

        yield put({
            type: types.BILLINGS_UPDATE_CUSTOMER_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization next invoice payment
 */
function* billingsGetNextInvoicePayment(): Generator<CallEffect | PutEffect | SelectEffect, void, any> {
    try {
        let { data } = yield call(billingsClass.billingsGetNextInvoicePayment);

        yield put({ type: types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization invoices.
 */
function* billingsGetInvoices({
    payload,
}: {
    type: string;
    payload: {
        lastKey?: string;
    };
}): Generator<CallEffect | PutEffect | SelectEffect, void, any> {
    try {
        let { data } = yield call(billingsClass.billingsGetInvoices, payload);

        if (payload.lastKey) {
            const {
                invoices,
            }: {
                invoices: BillingsInvoices;
            } = yield select(({ BillingsReducer }: RootState) => ({
                invoices: BillingsReducer.invoices,
            }));

            data.list = [...invoices.list, ...data.list];
        }

        yield put({ type: types.BILLINGS_GET_INVOICES_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_INVOICES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization invoice.
 */
function* billingsGetInvoice({ payload }: { payload: { invoiceId: string }; type: string }): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsGetInvoice, payload);
        yield put({ type: types.BILLINGS_GET_INVOICE_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_INVOICE_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization subscriptions.
 */
function* billingsGetSubscriptions(): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsGetSubscriptions);
        yield put({ type: types.BILLINGS_GET_SUBSCRIPTIONS_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_SUBSCRIPTIONS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization public subscription.
 */
function* billingsGetPublicSubscription(): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsGetPublicSubscription);
        yield put({ type: types.BILLINGS_GET_PUBLIC_SUBSCRIPTION_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_PUBLIC_SUBSCRIPTION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get organization invoices.
 */
function* billingsGetInvoicePdf(): SagaIterator<void> {
    // try
    // {
    // 	const { data } = yield call(billingsClass.invoiceGetInvoicePdf, { invoiceId: "sdsad" });
    // 	console.log(data)
    // 	yield put({ type: types.INVOICE_GET_INVOICE_PDF_SUCCESS, payload: data });
    // } catch (error: any)
    // {
    // 	yield put({
    // 		type: types.INVOICE_GET_INVOICE_PDF_FAILURE,
    // 		payload: error,
    // 	});
    // }
}

/**
 * @description attach payment method to a customer
 */
function* billingsAttachPaymentMethod({
    payload,
}: {
    type: string;
    payload: {
        paymentMethod: string;
    };
}): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsAttachPaymentMethod, payload);
        yield put({
            type: types.BILLINGS_ATTACH_PAYMENT_METHOD_SUCCESS,
            payload: data,
        });
        toastHelper.toastStartContent("success", "Card added successfully.");
        popupHelper.popupEnd();
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_ATTACH_PAYMENT_METHOD_FAILURE,
            payload: error,
        });
        apiV2.toastAllErrors(error);
    }
}

/**
 * @description detach payment method to a customer
 */
function* billingsDetachPaymentMethod({
    payload,
}: {
    type: string;
    payload: {
        paymentMethod: string;
    };
}): SagaIterator<void> {
    try {
        yield call(billingsClass.billingsDetachPaymentMethod, payload);

        let {
            paymentMethods,
        }: {
            paymentMethods: PaymentMethod[];
        } = yield select(({ BillingsReducer }: RootState) => ({
            paymentMethods: BillingsReducer.paymentMethods,
        }));

        paymentMethods = paymentMethods.filter((pm) => pm.id !== payload.paymentMethod);

        yield put({
            type: types.BILLINGS_DETACH_PAYMENT_METHOD_SUCCESS,
            payload: paymentMethods,
        });
        toastHelper.toastStartContent("success", "Payment method was removed successfully.");
        popupHelper.popupEnd();
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_DETACH_PAYMENT_METHOD_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", "Failed to remove payment method.");
    }
}

/**
 * @description get payment methods of a org
 */
function* billingsGetPaymentMethods({
    payload,
}: {
    type: string;
    payload: {
        stripeCustomerId: string;
    };
}): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsGetPaymentMethods, payload);
        yield put({
            type: types.BILLINGS_GET_PAYMENT_METHODS_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_GET_PAYMENT_METHODS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Update number of seats
 */
function* billingsUpdateNumberOfSeats({
    payload,
}: {
    type: string;
    payload: {
        seats: number;
    };
}): SagaIterator<void> {
    try {
        yield call(billingsClass.billingsUpdateNumberOfSeats, payload);
        yield put({
            type: types.BILLINGS_UPDATE_SEATS_SUCCESS,
            payload: payload,
        });
        const orgId = localStorage.getItem("orgId");
        yield put({
            type: orgTypes.ORG_GET_ORGANIZATION_REQUEST,
            payload: { orgId },
        });
        toastHelper.toastStartContent("success", "Seats updated successfully.");
        popupHelper.popupEnd();
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_UPDATE_SEATS_FAILURE,
            payload: error,
        });

        if (error.aborted) return;
        toastHelper.toastStartContent("danger", error?.error?.message);
    }
}

/**
 * @description Pay invoice
 */
function* billingsPayInvoice({
    payload,
}: {
    type: string;
    payload: {
        paymentMethodId: string | null;
        invoiceId: string;
    };
}): SagaIterator<void> {
    try {
        yield call(billingsClass.billingsPayInvoice, payload);
        yield delay(3500);
        yield put({
            type: types.BILLINGS_PAY_INVOICE_SUCCESS,
            payload: payload,
        });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_PAY_INVOICE_SUCCESS,
            payload: error,
        });
        toastHelper.toastStartContent("danger", error?.error?.message);
    }
}

/**
 * @description create payment intent for domains.
 */
function* billingsPaymentIntentDomains({
    payload,
}: {
    type: string;
    payload: {
        domainName: string;
    };
}): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsPaymentIntentDomains, payload);
        // keep in mind this dispatch is being used in domains, so if you wanna change this
        // you should keep in mind to also look the domain in emails and check if your changes
        // breaks anything there.
        yield put({
            type: types.BILLINGS_PAYMENT_INTENT_DOMAINS_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_PAYMENT_INTENT_DOMAINS_FAILURE,
            payload: error,
        });
        apiV2.toastAllErrors(error);
    }
}

/**
 * @description confirm created payment intent
 */
function* billingsConfirmPaymentIntent({ payload }: { type: string; payload: { paymentIntentId: string; paymentMethodId: string } }): SagaIterator<void> {
    try {
        const { data } = yield call(billingsClass.billingsConfirmPaymentIntent, payload);
        // keep in mind this dispatch is being used in domains, so if you wanna change this
        // you should keep in mind to also look the domain in emails and check if your changes
        // breaks anything there.
        yield put({
            type: types.BILLINGS_CONFIRM_PAYMENT_INTENT_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({
            type: types.BILLINGS_CONFIRM_PAYMENT_INTENT_FAILURE,
            payload: error,
        });
        apiV2.toastAllErrors(error);
    }
}

export default function* InvoiceSaga() {
    yield takeLatest(types.BILLINGS_GET_PLAN_REQUEST, billingsGetPlan);
    yield takeLatest(types.BILLINGS_GET_CUSTOMER_REQUEST, billingsGetCustomer);
    yield takeLatest(types.BILLINGS_GET_CUSTOMERS_REQUEST, billingsGetCustomers);
    yield takeLatest(types.BILLINGS_UPDATE_CUSTOMER_REQUEST, billingsUpdateCustomer);
    yield takeLatest(types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_REQUEST, billingsGetNextInvoicePayment);
    yield takeLatest(types.BILLINGS_GET_INVOICES_REQUEST, billingsGetInvoices);
    yield takeLatest(types.BILLINGS_GET_INVOICE_REQUEST, billingsGetInvoice);
    yield takeLatest(types.BILLINGS_GET_SUBSCRIPTIONS_REQUEST, billingsGetSubscriptions);
    yield takeLatest(types.BILLINGS_GET_PUBLIC_SUBSCRIPTION_REQUEST, billingsGetPublicSubscription);
    yield takeLatest(types.BILLINGS_GET_INVOICE_PDF_REQUEST, billingsGetInvoicePdf);
    yield takeLatest(types.BILLINGS_ATTACH_PAYMENT_METHOD_REQUEST, billingsAttachPaymentMethod);
    yield takeLatest(types.BILLINGS_DETACH_PAYMENT_METHOD_REQUEST, billingsDetachPaymentMethod);
    yield takeLatest(types.BILLINGS_GET_PAYMENT_METHODS_REQUEST, billingsGetPaymentMethods);
    yield takeLatest(types.BILLINGS_UPDATE_SEATS_REQUEST, billingsUpdateNumberOfSeats);
    yield takeLatest(types.BILLINGS_PAY_INVOICE_REQUEST, billingsPayInvoice);
    yield takeLatest(types.BILLINGS_PAYMENT_INTENT_DOMAINS_REQUEST, billingsPaymentIntentDomains);
    yield takeLatest(types.BILLINGS_CONFIRM_PAYMENT_INTENT_REQUEST, billingsConfirmPaymentIntent);
}

export const types = {
    AUTH_GET_INDEXED_DB_DATA: "AUTH_GET_INDEXED_DB_DATA",
    AUTH_CHANGE_STATE: "AUTH_CHANGE_STATE",

    INDEXEDDB_AUTHREDUCER_REQUEST: "INDEXEDDB_AUTHREDUCER_REQUEST",

    AUTH_CURRENT_AUTHENTICATED_USER_REQUEST: "AUTH_CURRENT_AUTHENTICATED_USER_REQUEST",
    AUTH_CURRENT_AUTHENTICATED_USER_SUCCESS: "AUTH_CURRENT_AUTHENTICATED_USER_SUCCESS",
    AUTH_CURRENT_AUTHENTICATED_USER_FAILURE: "AUTH_CURRENT_AUTHENTICATED_USER_FAILURE",

    AUTH_PROFILE_INFO_REQUEST: "AUTH_PROFILE_INFO_REQUEST",
    AUTH_PROFILE_INFO_SUCCESS: "AUTH_PROFILE_INFO_SUCCESS",
    AUTH_PROFILE_INFO_FAILURE: "AUTH_PROFILE_INFO_FAILURE",

    AUTH_UPDATE_ADMIN_INFO_REQUEST: "AUTH_UPDATE_ADMIN_INFO_REQUEST",
    AUTH_UPDATE_ADMIN_INFO_SUCCESS: "AUTH_UPDATE_ADMIN_INFO_SUCCESS",
    AUTH_UPDATE_ADMIN_INFO_FAILURE: "AUTH_UPDATE_ADMIN_INFO_FAILURE",

    AUTH_SIGN_IN_USER_REQUEST: "AUTH_SIGN_IN_USER_REQUEST",
    AUTH_SIGN_IN_USER_SUCCESS: "AUTH_SIGN_IN_USER_SUCCESS",
    AUTH_SIGN_IN_USER_FAILURE: "AUTH_SIGN_IN_USER_FAILURE",

    AUTH_SIGN_UP_USER_REQUEST: "AUTH_SIGN_UP_USER_REQUEST",
    AUTH_SIGN_UP_USER_SUCCESS: "AUTH_SIGN_UP_USER_SUCCESS",
    AUTH_SIGN_UP_USER_FAILURE: "AUTH_SIGN_UP_USER_FAILURE",

    AUTH_CHANGE_PHONE_NUMBER_VALIDATION_REQUEST: "AUTH_CHANGE_PHONE_NUMBER_VALIDATION_REQUEST",
    AUTH_CHANGE_PHONE_NUMBER_VALIDATION_SUCCESS: "AUTH_CHANGE_PHONE_NUMBER_VALIDATION_SUCCESS",
    AUTH_CHANGE_PHONE_NUMBER_VALIDATION_FAILURE: "AUTH_CHANGE_PHONE_NUMBER_VALIDATION_FAILURE",

    AUTH_ON_BOARDING_USER_REQUEST: "AUTH_ON_BOARDING_USER_REQUEST",
    AUTH_ON_BOARDING_USER_SUCCESS: "AUTH_ON_BOARDING_USER_SUCCESS",
    AUTH_ON_BOARDING_USER_FAILURE: "AUTH_ON_BOARDING_USER_FAILURE",

    AUTH_CONFIRM_SIGN_UP_REQUEST: "AUTH_CONFIRM_SIGN_UP_REQUEST",
    AUTH_CONFIRM_SIGN_UP_SUCCESS: "AUTH_CONFIRM_SIGN_UP_SUCCESS",
    AUTH_CONFIRM_SIGN_UP_FAILURE: "AUTH_CONFIRM_SIGN_UP_FAILURE",

    AUTH_SIGN_UP_RESEND_CODE_REQUEST: "AUTH_SIGN_UP_RESEND_CODE_REQUEST",
    AUTH_SIGN_UP_RESEND_CODE_SUCCESS: "AUTH_SIGN_UP_RESEND_CODE_SUCCESS",
    AUTH_SIGN_UP_RESEND_CODE_FAILURE: "AUTH_SIGN_UP_RESEND_CODE_FAILURE",

    AUTH_LOGOUT_USER_REQUEST: "AUTH_LOGOUT_USER_REQUEST",
    AUTH_LOGOUT_USER_SUCCESS: "AUTH_LOGOUT_USER_SUCCESS",
    AUTH_LOGOUT_USER_FAILURE: "AUTH_LOGOUT_USER_FAILURE",

    AUTH_FORGOT_PASSWORD_REQUEST: "AUTH_FORGOT_PASSWORD_REQUEST",
    AUTH_FORGOT_PASSWORD_SUCCESS: "AUTH_FORGOT_PASSWORD_SUCCESS",
    AUTH_FORGOT_PASSWORD_FAILURE: "AUTH_FORGOT_PASSWORD_FAILURE",

    AUTH_FORGOT_PASSWORD_SUBMIT_REQUEST: "AUTH_FORGOT_PASSWORD_SUBMIT_REQUEST",
    AUTH_FORGOT_PASSWORD_SUBMIT_SUCCESS: "AUTH_FORGOT_PASSWORD_SUBMIT_SUCCESS",
    AUTH_FORGOT_PASSWORD_SUBMIT_FAILURE: "AUTH_FORGOT_PASSWORD_SUBMIT_FAILURE",

    AUTH_UPDATE_USER_ATTRIBUTES_REQUEST: "AUTH_UPDATE_USER_ATTRIBUTES_REQUEST",
    AUTH_UPDATE_USER_ATTRIBUTES_SUCCESS: "AUTH_UPDATE_USER_ATTRIBUTES_SUCCESS",
    AUTH_UPDATE_USER_ATTRIBUTES_FAILURE: "AUTH_UPDATE_USER_ATTRIBUTES_FAILURE",

    AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_REQUEST: "AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_REQUEST",
    AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_SUCCESS: "AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_SUCCESS",
    AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_FAILURE: "AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_FAILURE",

    AUTH_VALIDATE_EMPLOYEE_REGISTRATION_REQUEST: "AUTH_VALIDATE_EMPLOYEE_REGISTRATION_REQUEST",
    AUTH_VALIDATE_EMPLOYEE_REGISTRATION_SUCCESS: "AUTH_VALIDATE_EMPLOYEE_REGISTRATION_SUCCESS",
    AUTH_VALIDATE_EMPLOYEE_REGISTRATION_FAILURE: "AUTH_VALIDATE_EMPLOYEE_REGISTRATION_FAILURE",

    AUTH_UPDATE_USER_STATUS_REQUEST: "AUTH_UPDATE_USER_STATUS_REQUEST",
    AUTH_UPDATE_USER_STATUS_SUCCESS: "AUTH_UPDATE_USER_STATUS_SUCCESS",
    AUTH_UPDATE_USER_STATUS_FAILURE: "AUTH_UPDATE_USER_STATUS_FAILURE",

    AUTH_CHANGE_USER_PASSWORD_REQUEST: "AUTH_CHANGE_USER_PASSWORD_REQUEST",
    AUTH_CHANGE_USER_PASSWORD_SUCCESS: "AUTH_CHANGE_USER_PASSWORD_SUCCESS",
    AUTH_CHANGE_USER_PASSWORD_FAILURE: "AUTH_CHANGE_USER_PASSWORD_FAILURE",

    AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_REQUEST: "AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_REQUEST",
    AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_SUCCESS: "AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_SUCCESS",
    AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_FAILURE: "AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_FAILURE",
};

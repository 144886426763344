import { AuthContextState } from "./reducer";
import { types } from "./types";

/**
 * @description on change form in auth.
 */
export const authOnChangeLoginForm = (payload: Partial<AuthContextState["form"]>) => ({
    type: types.AUTH_ON_CHANGE_LOGIN_FORM,
    payload,
});

/**
 * @description on change sign up form in auth.
 */
export const authOnChangeSUForm = (payload: Partial<AuthContextState["SUForm"]>) => ({
    type: types.AUTH_ON_CHANGE_SIGN_UP_FORM,
    payload,
});

/**
 * @description on change create admin form (add-info)
 */
export const authOnChangeCAForm = (payload: Partial<AuthContextState["CAForm"]>) => ({
    type: types.AUTH_ON_CHANGE_CREATE_ADMIN_FORM,
    payload,
});

/**
 * @description on change form in auth.
 */
export const authOnChangeFPForm = (payload: Partial<AuthContextState["FPForm"]>) => ({
    type: types.AUTH_ON_CHANGE_FORGOT_PASSWORD_FORM,
    payload,
});

/**
 * @description on change employee form in onboarding.
 */
export const employeeOnChangeOnboarding = (payload: Partial<AuthContextState["employeeForm"]>) => ({
    type: types.EMPLOYEE_ON_CHANGE_ONBOARDING,
    payload,
});

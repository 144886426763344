import { emailTypes } from "./types";
import { EmailConversations, EmailList } from "../../emails";
import { ContactEntity } from "../../../contacts/contacts";

export interface EmailState {
    /**
     * @description sent emails
     */
    out_exchanges: {
        loading: boolean;
        refreshing: boolean;
    } & EmailList;
    /**
     * @description inbox emails
     */
    in_exchanges: {
        loading: boolean;
        refreshing: boolean;
    } & EmailList;
    /**
     * @description trash emails
     */
    deleted_exchanges: {
        loading: boolean;
        refreshing: boolean;
    } & EmailList;
    /**
     * @description opened email at /collaboration/email/view/exchangeId
     */
    email: {
        loading: boolean;
        refreshing: boolean;
    } & EmailConversations;
    /**
     * @description when request for delete exchange is pending.
     */
    loadingDeleteExchange: boolean;
    /**
     * @description when request for restore email is pending.
     */
    loadingRestoreEmail: boolean;
    /**
     * @description when request for send [new/reply] email is pending.
     */
    sendingEmail: {
        loading: boolean;
        status?: "failed" | "success" | "canceled";
        message?: string;
    };
    /**
     * @description when loading setup email business is pending.
     */
    setupEmail: {
        loading: boolean;
        status?: "pending" | "success" | "failed";
    };
    searchEntity: {
        loading: boolean;
        status?: "failed" | "success";
        message?: string;
        items: ContactEntity[];
    };
}

const _INITIAL_STATE: EmailState = {
    out_exchanges: {
        items: [],
        lastKey: {},
        loading: true,
        refreshing: false,
    },
    in_exchanges: {
        items: [],
        lastKey: {},
        loading: true,
        refreshing: false,
    },
    deleted_exchanges: {
        items: [],
        lastKey: {},
        loading: true,
        refreshing: false,
    },
    email: {
        items: [],
        lastKey: {},
        loading: true,
        refreshing: false,
    },
    loadingDeleteExchange: false,
    loadingRestoreEmail: false,
    sendingEmail: {
        loading: false,
    },
    setupEmail: {
        loading: false,
        status: undefined,
    },
    searchEntity: {
        loading: false,
        items: [],
    },
};

const EmailReducer = (state = _INITIAL_STATE, action: { type: string; payload: any }): EmailState => {
    switch (action.type) {
        case emailTypes.EMAIL_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...action.payload,
            };

        case emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_REQUEST:
            return {
                ...state,
                setupEmail: {
                    ...state.setupEmail,
                    loading: true,
                    status: "pending",
                },
            };

        case emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_SUCCESS:
            return {
                ...state,
                setupEmail: {
                    ...state.setupEmail,
                    loading: false,
                    status: "success",
                },
            };

        case emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_FAILURE:
            return {
                ...state,
                setupEmail: {
                    ...state.setupEmail,
                    loading: false,
                    status: "failed",
                },
            };

        case emailTypes.EMAIL_OUT_EXCHANGES_REQUEST:
            return {
                ...state,
                out_exchanges: {
                    ...state.out_exchanges,
                    loading: true,
                    refreshing: true,
                },
            };

        case emailTypes.EMAIL_OUT_EXCHANGES_SUCCESS:
            return {
                ...state,
                out_exchanges: {
                    ...action.payload,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_OUT_EXCHANGES_FAILURE:
            return {
                ...state,
                out_exchanges: {
                    ...state.out_exchanges,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_IN_EXCHANGES_REQUEST:
            return {
                ...state,
                in_exchanges: {
                    ...state.in_exchanges,
                    loading: true,
                    refreshing: true,
                },
            };

        case emailTypes.EMAIL_IN_EXCHANGES_SUCCESS:
            return {
                ...state,
                in_exchanges: {
                    ...action.payload,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_IN_EXCHANGES_FAILURE:
            return {
                ...state,
                in_exchanges: {
                    ...state.in_exchanges,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_GET_DELETED_EXCHANGES_REQUEST:
            return {
                ...state,
                deleted_exchanges: {
                    ...state.deleted_exchanges,
                    loading: true,
                    refreshing: true,
                },
            };

        case emailTypes.EMAIL_GET_DELETED_EXCHANGES_SUCCESS:
            return {
                ...state,
                deleted_exchanges: {
                    ...action.payload,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_GET_DELETED_EXCHANGES_FAILURE:
            return {
                ...state,
                deleted_exchanges: {
                    ...state.deleted_exchanges,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_GET_EMAIL_REQUEST:
            return {
                ...state,
                email: {
                    ...state.email,
                    loading: true,
                    refreshing: true,
                },
            };

        case emailTypes.EMAIL_GET_EMAIL_SUCCESS:
            return {
                ...state,
                email: {
                    ...action.payload,
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_GET_EMAIL_FAILURE:
            return {
                ...state,
                email: {
                    items: [],
                    lastKey: {},
                    loading: false,
                    refreshing: false,
                },
            };

        case emailTypes.EMAIL_DELETE_EXCHANGE_REQUEST:
            return {
                ...state,
                loadingDeleteExchange: true,
            };

        case emailTypes.EMAIL_DELETE_EXCHANGE_SUCCESS:
            return {
                ...state,
                loadingDeleteExchange: false,
                in_exchanges: action.payload.in_exchanges,
                out_exchanges: action.payload.out_exchanges,
                email: action.payload.email,
            };

        case emailTypes.EMAIL_DELETE_EXCHANGE_FAILURE:
            return {
                ...state,
                loadingDeleteExchange: false,
            };

        case emailTypes.EMAIL_RESTORE_EMAIL_REQUEST:
            return {
                ...state,
                loadingRestoreEmail: true,
            };

        case emailTypes.EMAIL_RESTORE_EMAIL_SUCCESS:
            return {
                ...state,
                deleted_exchanges: action.payload,
                loadingRestoreEmail: false,
            };

        case emailTypes.EMAIL_RESTORE_EMAIL_FAILURE:
            return {
                ...state,
                loadingRestoreEmail: false,
            };

        case emailTypes.EMAIL_SEND_EMAIL_REQUEST:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: true,
                },
            };

        case emailTypes.EMAIL_SEND_EMAIL_SUCCESS:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: false,
                    message: "Email sent successfully.",
                    status: "success",
                },
            };

        case emailTypes.EMAIL_SEND_EMAIL_FAILURE:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: false,
                    message: "Failed to send email.",
                    status: "failed",
                },
            };

        case emailTypes.EMAIL_SEND_EMAIL_CANCELED:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: false,
                    message: "Email was canceled.",
                    status: "canceled",
                },
            };

        case emailTypes.EMAIL_REPLY_EMAIL_REQUEST:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: true,
                },
            };

        case emailTypes.EMAIL_REPLY_EMAIL_SUCCESS:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: false,
                    status: "success",
                    message: "Reply sent successfully.",
                },
                email: action.payload,
            };

        case emailTypes.EMAIL_REPLY_EMAIL_FAILURE:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: false,
                    status: "failed",
                    message: "Failed to send reply.",
                },
            };
        case emailTypes.EMAIL_REPLY_EMAIL_CANCELED:
            return {
                ...state,
                sendingEmail: {
                    ...state.sendingEmail,
                    loading: false,
                    message: "Email was canceled.",
                    status: "canceled",
                },
            };

        case emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_REQUEST:
            return {
                ...state,
                searchEntity: {
                    ...state.searchEntity,
                    loading: true,
                },
            };

        case emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_SUCCESS:
            return {
                ...state,
                searchEntity: {
                    ...state.searchEntity,
                    loading: false,
                    status: "success",
                    message: "Contacts fetched successfully",
                    items: action.payload,
                },
            };

        case emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_FAILURE:
            return {
                ...state,
                searchEntity: {
                    ...state.searchEntity,
                    loading: false,
                    status: "failed",
                    message: "No contacts found",
                    items: [],
                },
            };

        default:
            return state;
    }
};

export default EmailReducer;

import { useEffect, useState } from "react";
import useRolePermissions from "../../applications/auth/hooks/useRolePermissions";
import { RolePermissions, RoleSubModules, RoleModules, PermissionValue } from "../../applications/hrm/hrm";
import { hideSideBarModule } from "../../applications/settings/helpers/check.rights";
import { Dashboard24Px, Date24Px, PeopleOutline24Px, Web24Px, Inventory, NoteChecked, Contacts } from "@soltivo/draw-a-line/core/components/icons";

/**
 * @description return navigations taking in account permissions
 */
const useAppNavigation = () => {
    const permissions = useRolePermissions() as RolePermissions;
    const [navigations, setNavigations] = useState<NavigationAPI[]>([]);

    /**
     * @description Check if user can access a module/service
     * @returns {array} return an array with module's read permissions values
     */
    const getModuleReadPermissions = (module: RoleModules) => {
        if(!permissions) return [];
        const _readPermissions: PermissionValue[] = [];
        const modulePermissions = permissions[module];
        Object.keys(modulePermissions).forEach((key) => {
            const subKey = key as RoleSubModules;
            /**@ts-ignore */
            const readValue = modulePermissions[subKey]['read'];
            _readPermissions.push(readValue);
        });
        return _readPermissions;
    };

    /**
     * @description this navigation object maps each app inside the correct module/section
     * @important the order matters.
     */
     const _NAVIGATIONS: NavigationAPI[] = [
        {
            id: 1,
            name: "all",
            apps: [
                /**
                 * Dashboard group entry
                 */
                {
                    groupId: "dashboard",
                    id: 1,
                    sub: "dashboard",
                    name: "Dashboard",
                    path: "/dashboard",
                    sub_paths: [],
                    icon: Dashboard24Px,
                },
                /**
                 * Dashboard group close
                 */
    
                /**
                 * Collaboration group entry
                 */
                // {
                //     groupId: "collaboration",
                //     id: 2,
                //     sub: "collaboration",
                //     name: "Email",
                //     path: "/collaboration/email/inbox",
                //     hide: hideSideBarModule(getModuleReadPermissions('emails')),
                //     icon: Inbox24Px,
                // },
                {
                    groupId: "collaboration",
                    id: 13,
                    sub: "collaboration",
                    name: "Calendar",
                    path: `/collaboration/calendar/personal`,
                    sub_paths: ["/collaboration/calendar/personal", "/collaboration/calendar/event", "/collaboration/calendar/create"],
                    hide: hideSideBarModule(getModuleReadPermissions('bookings')),
                    icon: Date24Px,
                },
                {
                    groupId: "collaboration",
                    id: 8,
                    sub: "sales",
                    name: "Contacts",
                    path: "/sales/contacts/leads",
                    sub_paths: ["/sales/contacts/client", "/sales/contacts/suppliers", "/sales/contacts/lead"],
                    hide: hideSideBarModule(getModuleReadPermissions('crm')),
                    icon: Contacts,
                },
                // {
                //     groupId: "collaboration",
                //     id: 4,
                //     sub: "collaboration",
                //     name: "Task",
                //     path: "/collaboration/project/personal",
                //     sub_paths: ["/collaboration/project/team"],
                //     hide: false,
                //     icon: CheckCircle,
                // },
                // {
                //     id: 15,
                //     groupId: "administration",
                //     sub: "administration",
                //     name: "Sales",
                //     path: "/administration/sales",
                //     sub_paths: ["/administration/sales/transaction"],
                //     hide: hideSideBarModule(getModuleReadPermissions('sales')),
                //     icon: AttachMoney24Px,
                // },
                {
                    id: 9,
                    groupId: "administration",
                    sub: "administration",
                    name: "Teams",
                    path: "/administration/team",
                    sub_paths: [],
                    hide: hideSideBarModule(getModuleReadPermissions('hrm')),
                    icon: PeopleOutline24Px
                },
                {
                    id: 14,
                    groupId: "administration",
                    sub: "administration",
                    name: "Inventory",
                    path: "/administration/inventory",
                    sub_paths: [],
                    hide: hideSideBarModule(getModuleReadPermissions('inventory')),
                    icon: Inventory,
                },
                {
                    id: 12,
                    groupId: "sales",
                    sub: "administration",
                    name: "Services",
                    path: "/administration/services/categories",
                    sub_paths: ["/administration/services"],
                    hide: false,
                    icon: NoteChecked,
                },
                {
                    id: 7,
                    groupId: "sales",
                    sub: "sales",
                    name: "Website Builder",
                    path: "/sales/website",
                    sub_paths: ["/sales/website/themes"],
                    hide: hideSideBarModule(getModuleReadPermissions('builder')),
                    icon: Web24Px,
                },
            ],
            getApp(
                appName,
                options = {
                    navId: 1,
                }
            ) {
                return _NAVIGATIONS[options.navId].apps.find((item) => item.name === appName);
            },
        },
    ];

    useEffect(() => {
        setNavigations(_NAVIGATIONS);
    }, [permissions]);

    return navigations.length ? navigations : _NAVIGATIONS;
}

export default useAppNavigation;


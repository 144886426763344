import websiteWizardClass from "./website.class";
import contactFormMessageClass from "./contactform.class";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { types } from "../reducer/types";
import { WebsiteActionsTypes } from "../reducer/actions.types";

/**
 * @description get list of themes.
 */
function* websiteGetThemes() {
    try {
        let { data } = yield call(websiteWizardClass.websiteGetThemes);

        const themes = data.map((theme: any) => ({
            ...theme,
            thumbnail: `${process.env.REACT_APP_SOLTIVO_CDN}/themes/${theme.id}/thumbnail.png`,
        }));
        yield put({
            type: types.WEBSITE_GET_THEMES_SUCCESS,
            payload: themes,
        });
    } catch (error) {
        yield put({
            type: types.WEBSITE_GET_THEMES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get list of themes.
 */
function* websiteGetThemePages() {
    try {
        const { data: websites } = yield call(websiteWizardClass.websiteGetThemePages);

        yield put({
            type: types.WEBSITE_GET_THEME_PAGES_SUCCESS,
            payload: websites,
        });
    } catch (error) {
        yield put({
            type: types.WEBSITE_GET_THEME_PAGES_FAILURE,
            payload: error,
        });
    }
}

function* contactFormGetMessages() {
    try {
        const { data } = yield call(contactFormMessageClass.contactFormGetMessages);

        yield put({
            type: types.CONTACT_FORM_GET_MESSAGES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.CONTACT_FORM_GET_MESSAGES_FAILURE,
            payload: error,
        });
    }
}

function* contactFormGetMessage({ payload }: WebsiteActionsTypes["contactFormGetMessage"]) {
    try {
        const { data } = yield call(contactFormMessageClass.contactFormGetMessage, payload);

        yield put({
            type: types.CONTACT_FORM_GET_MESSAGE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.CONTACT_FORM_GET_MESSAGE_FAILURE,
            payload: error,
        });
    }
}

function* contactFormUpdateMessage({ payload }: WebsiteActionsTypes["contactFormUpdateMessage"]) {
    try {
        const { data } = yield call(contactFormMessageClass.contactFormUpdateMessage, payload);

        yield put({
            type: types.CONTACT_FORM_UPDATE_MESSAGE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.CONTACT_FORM_UPDATE_MESSAGE_FAILURE,
            payload: error,
        });
    }
}

function* contactFormDeleteMessage({ payload }: WebsiteActionsTypes["contactFormDeleteMessage"]) {
    try {
        const { data } = yield call(contactFormMessageClass.contactFormDeleteMessage, payload);

        yield put({
            type: types.CONTACT_FORM_UPDATE_MESSAGE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.CONTACT_FORM_UPDATE_MESSAGE_FAILURE,
            payload: error,
        });
    }
}

export default function* WebsiteSaga() {
    yield takeLatest(types.WEBSITE_GET_THEMES_REQUEST, websiteGetThemes);
    yield takeLatest(types.WEBSITE_GET_THEME_PAGES_REQUEST, websiteGetThemePages);

    yield takeEvery(types.CONTACT_FORM_GET_MESSAGES_REQUEST, contactFormGetMessages);
    yield takeLatest(types.CONTACT_FORM_GET_MESSAGE_REQUEST, contactFormGetMessage);
    yield takeLatest(types.CONTACT_FORM_UPDATE_MESSAGE_REQUEST, contactFormUpdateMessage);
    yield takeLatest(types.CONTACT_FORM_DELETE_MESSAGE_REQUEST, contactFormDeleteMessage);
}

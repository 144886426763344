import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../pages/login/login";
import ForgotPassword from "../pages/login/forgot.password";
import ForgotPasswordPhoneCode from "../pages/login/forgot.password.phone.code";
import SignUp from "../pages/signup/sign.up";
import ResetForgotPassword from "../pages/login/confirm.forgot.password";
import PhoneValidation from "../pages/signup/phone.number";
import PhoneValidationCode from "../pages/signup/phone.code";
import AddInfo from "../pages/signup/add.info";
import CreateOrganization from "../pages/signup/create.organization";
import EmployeeOnBoarding from "../pages/employee.sign.up/onboard.employee";
import EmployeeAddInfo from "../pages/employee.sign.up/employee.add.info";
import AuthContext from "../context/auth.context";
import { AuthContextReducer } from "../context/reducer";
import { INITIAL_STATE } from "../context/reducer";

const AuthRoutes = () => {
    const [state, dispatch] = useReducer(AuthContextReducer, INITIAL_STATE);

    return (
        <AuthContext.Provider
            value={{
                state,
                dispatch,
            }}>
            <Switch>
                <Route exact path={"/login"} component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/forgot-password-reset" component={ResetForgotPassword} />
                <Route exact path="/forgot-password-code" component={ForgotPasswordPhoneCode} />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/phone-validation" component={PhoneValidation} />
                <Route exact path="/phone-validation-code" component={PhoneValidationCode} />
                <Route exact path="/add-info" component={AddInfo} />
                <Route exact path="/create-organization" component={CreateOrganization} />
                <Route exact path="/employee-registration" component={EmployeeOnBoarding} />
                <Route exact path="/employee-addinfo" component={EmployeeAddInfo} />
            </Switch>
        </AuthContext.Provider>
    );
};

export default AuthRoutes;

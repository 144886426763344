import { call, put, select } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description get email in exchanges (INBOX)
 */
export function* emailGetInExchanges({ payload }: EmailActions["emailGetInExchanges"]) {
    try {
        const { data } = yield call(emailClass.emailGetInExchanges, payload);

        let { in_exchanges }: { in_exchanges: RootState["EmailReducer"]["out_exchanges"] } = yield select(({ EmailReducer }: RootState) => ({
            in_exchanges: EmailReducer.in_exchanges,
        }));

        // set new key
        in_exchanges.lastKey = data.lastKey;

        //with last key (pagination)
        if (payload) {
            in_exchanges.items = [...in_exchanges.items, ...data.items];
        } else {
            in_exchanges.items = data.items;
        }

        yield put({
            type: emailTypes.EMAIL_IN_EXCHANGES_SUCCESS,
            payload: in_exchanges,
        });
    } catch (error: any) {
        yield put({
            type: emailTypes.EMAIL_IN_EXCHANGES_FAILURE,
            payload: error,
        });
    }
}

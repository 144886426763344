import { Button, FormControl, InputSelect, Loader, popupHelper } from "@soltivo/draw-a-line";
import { Archive24Px, ArrowRight } from "@soltivo/draw-a-line/core/components/icons";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../../redux/reducers";
import { User } from "../../../../auth/auth";
import { Location } from "../../../../auth/organization";
import { AllEmployee, Appointment } from "../../../appointment";
import useCreateAppointmentHandlers from "../../../hooks/useCreateAppointmentHandlers";
import { appointmentGetAppointment, appointmentUpdateStatus, appointmentArchiveAppointment } from "../../../redux/reducer/actions";
import AppointmentsNotes from "../appointments.notes/appointments.notes";
import styles from "./popup.appointment.details.module.scss";
import { KANBAN_DEFAULT_COLUMNS, STATUSES_NAMES } from "../../../helpers/constants";

interface AppointmentDetailsProps extends StateToProps {
    appointmentId: string;
}

const PopupAppointmentDetails: FC<AppointmentDetailsProps> = ({ appointment, employees, loadingAppointment, appointmentId, locations, archiving, updatingAppointment }) => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState("");
    const [showingNotes, setShowingNotes] = useState(false);
    const allEmployees: AllEmployee = employees;
    const { onCancel, onNotShowPopup, onReschedulePopup } = useCreateAppointmentHandlers({ appointment });

    useEffect(() => {
        if (appointment?.id !== appointmentId) dispatch(appointmentGetAppointment({ appointmentId }));
    }, []);

    useEffect(() => {
        if (appointment) setStatus(appointment.status);
    }, [appointment]);

    // Update appointment status
    const onChangeStatus = (option: string) => {
        setStatus(option);
        if (option === "not_show") {
            // If changing status to not_show, open confirmation modal
            onNotShowPopup(appointment);
            return;
        }
        dispatch(appointmentUpdateStatus({ appointmentId: appointment.id, moveTo: option }));
    };

    const staff = allEmployees.find((emp) => emp.userId === appointment?.userId);

    // Check is event is past the current day to disable Reschedule & Canceling
    const eventIsPast = appointment ? moment().isAfter(moment(appointment.end), "day") : false;

    return (
        <div className={styles.appointment__popup__container}>
            <div className={`${styles.appointment__details} ${showingNotes ? styles.appointment__details__hidden : ""}`}>
                {loadingAppointment || !appointment ? (
                    <div className={styles.appointment__loader}>
                        <Loader size="md" />
                    </div>
                ) : (
                    <>
                        <div data-testid="appointmentModalContent" className={styles.appointment__details__content}>
                            <div className={styles.appointment__details__infos__column}>
                                <div>
                                    <span>Customer</span>
                                    <p>
                                        {typeof appointment?.entityInfo !== "string" ? (
                                            <Link onClick={() => popupHelper.popupEnd()} to={`/sales/contacts/${appointment.entityInfo.type}s/${appointment.entityInfo.entityId}`}>
                                                {appointment?.entityInfo?.fullname || "No name"}
                                            </Link>
                                        ) : (
                                            "Customer not found"
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <span>Service</span>
                                    <p>
                                        {typeof appointment.serviceInfo !== "string" ? (
                                            <Link to={`/administration/services/categories/${appointment?.serviceInfo?.category?.id}/${appointment?.serviceInfo?.id}`}>
                                                {appointment?.serviceInfo?.title}
                                            </Link>
                                        ) : (
                                            "Service not found"
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <span>Staff</span>
                                    <p>
                                        <Link onClick={() => popupHelper.popupEnd()} to={staff?.roleId === "0" ? "/settings/profile" : `/administration/team/employees/${staff?.userId}`}>
                                            {staff?.firstName || ""} {staff?.lastName || ""}
                                        </Link>
                                    </p>
                                </div>
                                <div>
                                    <span>Location</span>
                                    <p>{locations.find((loc) => loc.id === appointment.locationId)?.name}</p>
                                </div>
                                <div>
                                    <span>Duration</span>
                                    <p>{typeof appointment.serviceInfo !== "string" ? `${appointment.serviceInfo?.duration / 60} minutes` : "0 minutes"}</p>
                                </div>
                                <div>
                                    <span>Start time</span>
                                    <p>{moment(appointment.start).format("LL [at] LT")}</p>
                                </div>
                                <div>
                                    <span>End time</span>
                                    <p>{moment(appointment.end).format("LL [at] LT")}</p>
                                </div>
                            </div>
                            <div className={styles.appointment__details__actions__column}>
                                <FormControl labelProps={{ value: "Appointment status" }} saving={updatingAppointment}>
                                    <InputSelect
                                        data-testid="inputStatus"
                                        onChange={({ option }) => onChangeStatus(option)}
                                        value={STATUSES_NAMES?.[status as keyof typeof STATUSES_NAMES] || ""}
                                        name="status"
                                        options={KANBAN_DEFAULT_COLUMNS.map((item) => ({ value: item.status, option: item.id }))}
                                    />
                                </FormControl>

                                <Button data-testid="viewNotesButton" onClick={() => setShowingNotes(true)} className={styles.activity__button} padding={false} outline variant="geyser-900">
                                    Appointment Notes &nbsp;
                                    <ArrowRight />
                                </Button>

                                <Button
                                    data-testid="archiveButton"
                                    onClick={() => dispatch(appointmentArchiveAppointment({ aptId: appointment.id, isArchived: !appointment?.isArchived }))}
                                    disabled={archiving.loading}
                                    className={styles.archive__button}
                                    padding={false}
                                    outline
                                    variant="primary">
                                    {appointment?.isArchived ? "Unarchive" : "Archive"} appointment &nbsp; <Archive24Px />
                                </Button>
                            </div>
                        </div>
                        <div className={styles.appointment__details__action__buttons}>
                            <Button role="button" aria-label="Cancel Appointment" disabled={eventIsPast} onClick={() => onCancel(appointment)} outline padding={false} variant="valencia-600">
                                Cancel Appointment
                            </Button>
                            <Button disabled={appointment.status === "cancelled" || eventIsPast} data-testid="rescheduleButton" onClick={onReschedulePopup} variant="primary">
                                Reschedule
                            </Button>
                        </div>
                    </>
                )}
            </div>

            <div className={`${styles.notes__wrapper} ${!showingNotes ? styles.notes__wrapper__hidden : ""}`}>
                {appointment && <AppointmentsNotes closeNotes={() => setShowingNotes(false)} appointmentId={appointment.id} />}
            </div>
        </div>
    );
};

const mapStateToProps = ({ AppointmentReducer, OrgReducer, HRMReducer, AuthReducer }: RootState) => ({
    loadingAppointment: AppointmentReducer.loadingAppointment,
    updatingAppointment: AppointmentReducer.updatingAppointment.loading,
    archiving: AppointmentReducer.archiving,
    appointment: AppointmentReducer.appointment as Appointment,
    locations: OrgReducer.org?.locations as Location[],
    employees: HRMReducer.employees,
    user: AuthReducer.user as User,
});

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PopupAppointmentDetails);

import { Avatar, Button, popupHelper, soltivoHelper, useClickOutsideRef } from "@soltivo/draw-a-line";
import { ArrowBack24Px, Help, Logout, Plus, Settings24Px, SwitchAccount } from "@soltivo/draw-a-line/core/components/icons";
import React, { FC, useContext, useState } from "react";
import { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Organization } from "../../../applications/auth/organization";
import { User } from "../../../applications/auth/auth";
import { authLogout } from "../../../applications/auth/redux/auth/reducer/actions";
import { orgEnterOrganization } from "../../../applications/auth/redux/org/reducer/actions";
import PopupCreateOrg from "../../../applications/settings/pages/organizations/org.profile/components/popup.create.org/popup.create.org";
import { RootState } from "../../../redux/reducers";
import useAppNavigation from "../../routes/navigations.all";
import { headerSidebarToggleDisplay } from "../header/reducer/actions";
import { HeaderContext } from "../header/reducer/header.context";
import { OrganizationState } from "../../../applications/auth/redux/org/reducer/reducer";
import styles from "./sidebar.module.scss";

export interface SidebarProps extends MapStateToProps {}

/**
 * @description creates the main application sidebar.
 */
const Sidebar: FC<SidebarProps> = ({ org, organizations, user, subscriptions }) => {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const [viewOrgs, setViewOrgs] = useState(false);
    const {
        dispatch: dispatchContext,
        state: { showMainSidebar },
    } = useContext(HeaderContext);

    const _NAVIGATIONS = useAppNavigation();

    /**
     * @description exit user of the organization
     */
    const onLogout = () => dispatch(authLogout());

    /**
     *
     * @description toggle between list of organizations in the sidebar.
     */
    const onToggleOrgs = () => setViewOrgs(!viewOrgs);

    /**
     * @description toggles to another organization.
     */
    const onToggleOrganization = (organization: OrganizationState["organizations"][0]) => {
        if (user.roleId !== "0" || organization.orgId === org?.orgId) return;
        setViewOrgs(false);
        dispatch(orgEnterOrganization({ orgId: organization.orgId }));
    };

    /**
     * @description create a new organization.
     */
    const createNewOrganization = () => {
        // close sidebar;
        dispatchContext(headerSidebarToggleDisplay({ show: false }));
        // close orgs view together
        setViewOrgs(false);

        // open create popup
        popupHelper.popupOpenV2(({ footer }) => <PopupCreateOrg footer={footer} />, {
            title: <span>Create organization</span>,
        });
    };

    const handleClick = () => {
        dispatchContext(headerSidebarToggleDisplay({ show: !showMainSidebar }));
    };

    useClickOutsideRef(
        [window],
        () => {
            if (showMainSidebar) {
                dispatchContext(headerSidebarToggleDisplay({ show: false }));
                setViewOrgs(false);
            }
        },
        sidebarRef
    );

    if (!org || !user || !_NAVIGATIONS.length) return null;
    const navigationsGrouping = [
        _NAVIGATIONS[0].apps.filter((x) => x.groupId === "dashboard"),
        _NAVIGATIONS[0].apps.filter((x) => x.groupId === "collaboration"),
        _NAVIGATIONS[0].apps.filter((x) => x.groupId === "administration"),
        _NAVIGATIONS[0].apps.filter((x) => x.groupId === "sales"),
    ];

    const subscriptionTeamPlan = subscriptions.find((sub) => {
        return sub.plan.productName === "Team Plan";
    });

    const addOrgIf = {
        trialling: subscriptionTeamPlan?.status === "trialing" && organizations.length < 2,
        teamPlan: subscriptionTeamPlan?.status !== "trialing" && organizations.length < 10,
    };

    return (
        <div id={styles.sidebar} className={styles.sidebar} ref={sidebarRef}>
            <div className={styles.sidebar__head}>
                <div className={styles.sidebar__head__content}>
                    <div className={`${styles.sidebar__organizations__return__container} ${viewOrgs ? styles.show : styles.hide} `} onClick={onToggleOrgs}>
                        <div className={styles.sidebar__organizations__return}>
                            <ArrowBack24Px />
                            <p className={styles.sidebar__organizations__return__text}>Return</p>
                        </div>
                    </div>
                    <div className={`${styles.sidebar__account__settings__container} ${!viewOrgs ? styles.show : styles.hide}`}>
                        <div className={styles.organization__switch__container}>
                            <h6 className={styles.organization__switch__container__name}>{soltivoHelper.sliceText(org.name, 18)}</h6>
                        </div>
                        {user.roleId === "0" ? 
                        <div className={styles.organization__change__container}>
                            <Button data-testid="listOrgBtn" variant="primary" onClick={onToggleOrgs} outline padding={false}><SwitchAccount /> Change Account</Button>
                        </div> : null}
                    </div>
                </div>
            </div>

            <nav className={styles.sidebar__navigation}>
                {/* Organizations listing */}
                <div className={`${styles.sidebar__navigation__organizations__list} ${viewOrgs ? styles.show : styles.hide}`}>
                    {organizations.map((_org, index) => (
                        <div className={`${styles.sidebar__navigation__organizations__item} ${org.orgId === _org.orgId ? styles.active : ""}`} key={index} data-testid="orgListItem" onClick={() => onToggleOrganization(_org)}>
                            <span className={styles.sidebar__navigation__organizations__name}>{_org.name}</span>
                        </div>
                    ))}

                    {addOrgIf.teamPlan || addOrgIf.trialling ? (
                        <div className={styles.sidebar__navigation__organizations__add} onClick={createNewOrganization}>
                            <Plus />
                            <span>Add organization</span>
                        </div>
                    ) : null}
                </div>

                {/* Links listing  */}
                <div className={`${styles.sidebar__navigation__links__container} ${!viewOrgs ? styles.show : styles.hide}`}>
                    {navigationsGrouping.map((group, groupIndex) => {
                        return (
                            <div className={styles.sidebar__navigation__links__group} key={groupIndex}>
                                {group.map((module, index) => {
                                    if(module.hide) return null;
                                    const isActive = location.pathname.toLowerCase().startsWith(module.path.toLowerCase());
                                    const Icon = module.icon ? module.icon : null;
                                    return (
                                        <Link data-testid="menuItem" key={index} to={module.path} onClick={() => handleClick()}>
                                            <p className={`${styles.sidebar__navigation__link} ${isActive ? styles.sidebar__navigation__link__active : ""}`}>
                                                {Icon && <Icon />}
                                                {module.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    })}

                    <div className={styles.sidebar__navigation__links__group}>
                        <Link data-testid="menuItem" to={user.roleId === "0" ? "/settings/organization" : `/administration/team/employees/${user.userId}`} onClick={handleClick}>
                            <p className={`${styles.sidebar__navigation__link} ${window.location.pathname.startsWith("/settings/organization") ? styles.sidebar__navigation__link__active : ""}`}><Settings24Px /> Settings</p>
                        </Link>
                        <a data-testid="menuItem" target="_blank" rel="noreferrer" href="https://help.soltivo.com">
                            <p className={`${styles.sidebar__navigation__link}`}> <Help /> Help</p>
                        </a>
                    </div>
                </div>
            </nav>

            <div className={styles.sidebar__footer}>
                <div className={styles.sidebar__footer__user__info__container}>
                    <Link to={user.roleId === "0" ? "/settings/profile" : `/administration/team/employees/${user.userId}`}>
                        <Avatar size="md" avatar={user.avatar} name={`${user.firstName} ${user.lastName}`} />
                    </Link>
                    <div className={styles.sidebar__footer__user__info}>
                        <Link data-testid="profileLink" to={user.roleId === "0" ? "/settings/profile" : `/administration/team/employees/${user.userId}`}>
                            <span>{soltivoHelper.sliceText(`${user.firstName} ${user.lastName}`, 15)}</span>
                        </Link>
                        <Button data-testid="logoutBtn" variant="geyser-500" outline padding={false} onClick={onLogout}>
                            <Logout /> Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({ OrgReducer, AuthReducer, BillingsReducer }: RootState) => ({
    org: OrgReducer.org as Organization,
    organizations: OrgReducer.organizations,
    user: AuthReducer.user as User,
    subscriptions: BillingsReducer.subscriptions,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Sidebar);

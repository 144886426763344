import { Button, InputSearch, useDropContainer } from "@soltivo/draw-a-line";
import { Plus } from "@soltivo/draw-a-line/core/components/icons";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapboxPlacesFeatures } from "../../../../../applications/auth/organization";
import { orgGetPlaces } from "../../../../../applications/auth/redux/org/reducer/actions";
import { RootState } from "../../../../../redux/reducers";
import CustomLocationForm from "../custom.location.form/custom.location.form";
import styles from "./input.search.places.module.scss";
import GuideOrgContext from "../../context/guide.org.context";
import { guideChangeAction } from "../../context/actions";

interface InputSearchPlacesProps {
    onSelectPlace: (place: MapboxPlacesFeatures) => void;
}

const InputSearchPlaces = ({ onSelectPlace }: InputSearchPlacesProps) => {
    const { containerRef, dropState, droppedRef, onDropdown } = useDropContainer({
        centeredX: true,
        withParentWidth: true,
        containerGap: 7,
    });

    const {
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    const dispatch = useDispatch();
    const [query, setQuery] = useState("");
    const [creatingCustomAddress, setCreatingCustomAddress] = useState(false);
    const [customLocation, setCustomLocation] = useState<MapboxPlacesFeatures>();

    const mapStateToProps = ({ OrgReducer }: RootState) => ({
        places: OrgReducer.places,
        loadingPlaces: OrgReducer.loadingPlaces,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;

    const { loadingPlaces, places }: MapStateToProps = useSelector(mapStateToProps);

    /**
     * @description change form input value
     */
    const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);
    };

    useEffect(() => {
        if (query.length >= 3) {
            dispatch(
                orgGetPlaces({
                    query: query,
                })
            );
        }
    }, [query]);

    useEffect(() => {
        onDropdown(Boolean(query.length >= 3 && !loadingPlaces));
    }, [query, loadingPlaces]);

    useEffect(() => {
        if(!creatingCustomAddress) return;
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    disabled: !customLocation?.place_name || !customLocation.text,
                    onClick: () => {
                        if(!customLocation) return;
                        onSelectPlace(customLocation);
                    },
                },
            })
        );
    }, [creatingCustomAddress, customLocation]);

    const onCustomAddressSelection = (_customLocation: MapboxPlacesFeatures) => {
        // @ts-ignore
        setCustomLocation(_customLocation);
        setQuery("");
    };

    return (
        creatingCustomAddress ? <CustomLocationForm onChangeCustomLocation={onCustomAddressSelection} />:
        <div className={styles.search__input} ref={containerRef}>
            <InputSearch
                data-testid="searchInput"
                name={"query"}
                hidden={false}
                type={"text"}
                focus={false}
                onChange={onSearchQueryChange}
                placeholder={"Search location"}
                autoComplete="off"
                isSearching={loadingPlaces}
            />

            <div data-testid="resultsContainer" className={`${styles.search__results} ${dropState.drop ? styles.show : ""}`} ref={droppedRef}>
                {places?.length === 0 && query ? (
                    <p className={styles.message}>No location found.</p>
                ) : (
                    places?.map((place, i: number) => (
                        <p
                            data-testid="placeItem"
                            onClick={() => {
                                setQuery("");
                                onSelectPlace(place);
                            }}
                            key={i}
                            className={styles.place}>
                            {place.place_name}
                        </p>
                    ))
                )}
                {!loadingPlaces &&
                <div className={styles.button__wrapper}>
                    <Plus />
                    <Button data-testid="customLocBtn" onClick={() => {
                        setCreatingCustomAddress(true);
                    }} variant="primary" outline={true} border={false} padding={false}>Enter a custom address</Button>
                </div>}
            </div>
        </div>
    );
};

export default InputSearchPlaces;

import { EmployeeInactiveItem, EmployeeItem, TEmployee, TRole, TTemplate, TWeekDay } from "../../hrm";
import { HRMReducerState } from "./reducer";
import { types } from "./types";

/**
 * @description change global state in hrm
 * @warning you this action carefully because you could break the application.
 */
export const hrmChangeState = (payload: Partial<HRMReducerState>) => ({
    type: types.HRM_CHANGE_STATE_REQUEST,
    payload,
});

/**
 * @description create a employee.
 */
export const hrmCreateEmployee = (payload: Required<Pick<TEmployee, "firstName" | "lastName" | "email" | "roleId">>) => ({
    type: types.HRM_CREATE_EMPLOYEE_REQUEST,
    payload,
});

/**
 * @description get all employees.
 */
export const hrmGetAllEmployees = (payload?: { includeInactive: boolean }) => ({
    type: types.HRM_GET_ALL_EMPLOYEES_REQUEST,
    payload,
});

/**
 * @description get employee by id
 */
export const hrmGetEmployee = (payload: { employeeId: string }) => ({
    type: types.HRM_GET_EMPLOYEE_BY_ID_REQUEST,
    payload,
});

/**
 * @description delete employee by id
 */
export const hrmDeleteEmployee = (payload: { employeeId: string }) => ({
    type: types.HRM_DELETE_EMPLOYEE_REQUEST,
    payload,
});

/**
 * @description update employee by id
 */
export const hrmResetEmployeePassword = (payload: { employeeId: string }) => ({
    type: types.HRM_RESET_EMPLOYEE_PASSWORD_REQUEST,
    payload,
});

/**
 * @description update employee by id
 */
export const hrmUpdateEmployee = (payload: {
    employeeId: string;
    avatar?: string | File;
    firstName: string;
    middleName?: string;
    lastName: string;
    personalEmail?: string;
    phoneNumber?: string;
    birthDate?: string;
    address?: string;
    address2?: string;
    country?: string;
    city?: string;
    addressState?: string;
    businessEmail?: string;
    zipCode?: string;
    emergencyContactName?: string;
    emergencyContactPhoneNumber?: string;
    role?: string;
    hourlyRate: number;
    hireDate?: string;
    workPlace?: string;
    workAddress1?: string;
    workAddress2?: string;
    workCountry?: string;
    workState?: string;
    workCity?: string;
    workZipCode?: string;
    inviteAccepted?: boolean;
}) => ({
    type: types.HRM_UPDATE_EMPLOYEE_REQUEST,
    payload,
});

/**
 *
 * @description get all activities tracked of an employee
 */
export const hrmGetEmployeeActivities = (payload: { employeeId: string; exactDate: string }) => ({
    type: types.HRM_GET_EMPLOYEE_ACTIVITIES_REQUEST,
    payload,
});

/**
 * @description change the date
 */
export const changeDate = (payload: { date: number }) => ({
    type: types.CHANGE_DATE,
    payload,
});

/////////////////////////////////////////////////////////////////////////////

/**
 * @description create a role.
 */
export const hrmCreateRole = (payload: { roleName: string; description: string; rolePermissions: Object[] }) => ({
    type: types.HRM_CREATE_ROLE_REQUEST,
    payload,
});

/**
 * @description get all roles.
 */
export const hrmGetAllRoles = () => ({
    type: types.HRM_GET_ALL_ROLES_REQUEST,
});

/**
 * @description get role by id
 */
export const hrmGetRole = (payload: { roleId: string }) => ({
    type: types.HRM_GET_ROLE_BY_ID_REQUEST,
    payload,
});

/**
 * @description delete role by id
 */
export const hrmDeleteRole = (payload: { roleId: string }) => ({
    type: types.HRM_DELETE_ROLE_REQUEST,
    payload,
});

/**
 * @description update role by id
 */
export const hrmUpdateRole = (payload: { role: Omit<TRole, "roleId" | "createdAt">; roleId: string }) => ({
    type: types.HRM_UPDATE_ROLE_REQUEST,
    payload,
});

/**
 * @description reset permissions by id
 */
export const hrmResetPermissions = (payload: { roleId: string }) => ({
    type: types.HRM_RESET_PERMISSIONS_REQUEST,
    payload,
});

/**
 * @description verify permissions
 */
export const hrmVerifyPermissions = () => ({
    type: types.HRM_VERIFY_PERMISSIONS_REQUEST,
});

/**
 * @description update employee role
 */
export const hrmUpdateEmployeeRole = (payload: { userId: string; roleId: string }) => ({
    type: types.HRM_UPDATE_EMPLOYEE_ROLE_REQUEST,
    payload,
});

/**
 * @description Validate role
 */
export const hrmValidateRole = () => ({
    type: types.HRM_VALIDATE_ROLES_REQUEST,
});

/////////////////////////////////////////////////////////////////////////////////
/**
 * @description get all Employee schedules.
 */
export const hrmGetAllEmployeeSchedules = (payload: { start: string; end: string }) => ({
    type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST,
    payload,
});

/**
 * @description get all schedule Template.
 */
export const hrmGetAllScheduleTemplates = () => ({
    type: types.HRM_GET_ALL_SCHEDULES_TEMPLATE_REQUEST,
});

/**
 * @description get schedule template
 */
export const getScheduleTemplate = (payload: { template: object }) => ({
    type: types.HRM_GET_TEMPLATE_BY_ID_REQUEST,
    payload,
});

/**
 * @description create schedule for a employee
 */
export const hrmCreateSchedule = (payload: { employeeId: string; description: string; weekDays: TWeekDay[]; locationId?: string }) => ({
    type: types.HRM_CREATE_SCHEDULE_REQUEST,
    payload,
});

/**
 * @description Update schedule for a employee
 */
export const hrmUpdateSchedule = (payload: {
    scheduleId: string;
    employeeId: string;
    option: string;
    date: string;
    currentDate: string;
    start: string;
    end: string;
    breaks: any[];
    days?: number[];
}) => ({
    type: types.HRM_UPDATE_SCHEDULE_REQUEST,
    payload,
});

/**
 * @description Delete schedule for a employee
 */
export const hrmDeleteSchedule = (payload: { scheduleId: string; option: string; date: string; currentDate: string; days?: number[] }) => ({
    type: types.HRM_DELETE_SCHEDULE_REQUEST,
    payload,
});

/**
 * @description create/update employee schedule
 */
export const hrmUpdateEmployeeSchedule = (payload: {
    userId: string;
    schedule: {
        timezone: string;
        weekdays: {
            start: string;
            end: string;
            locationId: string;
        }[][];
    };
}) => ({
    type: types.HRM_UPDATE_EMPLOYEE_SCHEDULE_REQUEST,
    payload,
});

/**
 * @description get employee schedule
 */
export const hrmGetEmployeeSchedule = (payload: { userId: string }) => ({
    type: types.HRM_GET_EMPLOYEE_SCHEDULE_REQUEST,
    payload,
});

/**
 * @description add schedule template
 */
export const hrmCreateTemplate = (payload: { name: string; description: string; weekDays: TWeekDay[] }) => ({
    type: types.HRM_CREATE_TEMPLATE_REQUEST,
    payload,
});

/**
 * @description update schedule template
 */
export const hrmUpdateTemplate = (payload: { templateId: string; name: string; description: string; weekDays: TWeekDay[] }) => ({
    type: types.HRM_UPDATE_TEMPLATE_REQUEST,
    payload,
});

/**
 * @description update schedule with a template
 */
export const hrmUpdateScheduleWithTemplate = (payload: { templateId: string; currentDate: string; scheduleId: string }) => ({
    type: types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_REQUEST,
    payload,
});

/**
 * @description add schedule
 */
export const addScheduleTemplate = (payload: { template: TTemplate }) => ({
    type: types.HRM_ADD_TEMPLATE_REQUEST,
    payload,
});

/**
 * @description delete schedule template
 */
export const deleteScheduleTemplate = (payload: TTemplate) => ({
    type: types.HRM_DELETE_TEMPLATE_REQUEST,
    payload,
});
/**
 * @description get Employee schedules By Id.
 */
export const hrmGetEmployeeSchedules = (payload: { employeeId: string }) => ({
    type: types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_REQUEST,
    payload,
});

/**
 * @description assign a template (attach template) to employee
 */
export const hrmAssignTemplate = (payload: { templateId: string; employeeId: string; locationId: string; createdAt: string }) => ({
    type: types.HRM_ASSIGN_TEMPLATE_REQUEST,
    payload,
});

/**
 * @description get all payrolls.
 */
export const hrmGetAllPayrolls = (payload: {
    status: "PAID" | "UNPAID";
    date: {
        from: string;
        to: string;
    };
}) => ({
    type: types.HRM_GET_ALL_PAYROLLS_REQUEST,
    payload,
});

/**
 * @description update payroll.
 */
export const hrmUpdatePayroll = (
    payload: { userId: string; dates: string[]; hourlyRate: number; status: "PAID" | "UNPAID" } | { userId: string; dates: string[]; hourlyRate: number; status: "PAID" | "UNPAID" }[]
) => ({
    type: types.HRM_UPDATE_PAYROLL_REQUEST,
    payload,
});

/**
 * @description resend invitation to inactived employee
 */
export const hrmResendEmployeeInvitation = (payload: { inactiveEmployee: EmployeeInactiveItem | EmployeeItem }) => ({
    type: types.HRM_RESEND_EMPLOYEE_INVITATION_REQUEST,
    payload,
});

/**
 * @description Check collision
 */
export const hrmCheckCollisions = (payload: { templateId: string; scheduleId: string; userId: string; currentDate: string }) => ({
    type: types.HRM_CHECK_COLLISIONS_REQUEST,
    payload,
});

import { Button, Column, FormControl, FormMessages, InputDatepicker, InputSelect, Loader, Row, popupHelper, toastHelper, useComponentVisible, useValidation, soltivoHelper } from "@soltivo/draw-a-line";
import { ArrowRight, ArrowRight24Px } from "@soltivo/draw-a-line/core/components/icons";
import { InputDatapickerProps } from "@soltivo/draw-a-line/core/components/inputs/input.date.picker/input.date.picker";
import { Service } from "@soltivo/types/types/applications/services";
import moment, { Moment } from "moment-timezone";
import { FC, useEffect, useReducer, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { RootState } from "../../../../../redux/reducers";
import { User } from "../../../../auth/auth";
import { Organization } from "../../../../auth/organization";
import { contactsCreateClient } from "../../../../contacts/redux/reducer/actions";
import { TWeekDay } from "../../../../hrm/hrm";
import { ReactComponent as CheckedSuccess } from "../../../../sales/assets/svg/custom_checked.svg";
import { serviceGetCategories, serviceGetServices } from "../../../../service/redux/reducer/actions";
import { AllEmployee, Appointment, DateSlot } from "../../../appointment";
import { appointmentChangeEmployeesList, appointmentChangeForm, appointmentChangeLocations, appointmentCreationChangeView } from "../../../context/actions";
import AppointmentContext from "../../../context/appointment.context";
import AppointmentContextReducer, { AppointmentContextState, INITIAL_STATE } from "../../../context/reducer";
import useCreateAppointmentHandlers from "../../../hooks/useCreateAppointmentHandlers";
import useCreateAppointmentNextStep from "../../../hooks/useCreateAppointmentSteps";
import {
    appointmentCreateAppointment,
    appointmentGetAvailableDates,
    appointmentGetQuickDates,
    appointmentGetSlotTimes,
    appointmentListByEntityId,
    appointmentRescheduleAppointment,
} from "../../../redux/reducer/actions";
import appointmentValidation from "../../../validation/appointment.validation";
import SelectCustomerStep from "../step.customer.selection/step.customer.selection";
import styles from "./popup.create.appointment.module.scss";
import { UnwrappedUserSchedule } from "../../appointment.body/appointment.body";

export interface PopupCreateAppointmentProps extends MapState {
    popupAction: "reschedule" | "create" | "quickCreate";
    options?: {
        date?: string;
        entity?: AppointmentContextState["form"]["contactEntityInfo"];
        slot?: AppointmentContextState["form"]["slot"];
        location?: AppointmentContextState["form"]["location"];
        employee?: AppointmentContextState["form"]["employee"];
        employeeSchedule?: { [weekday: string]: UnwrappedUserSchedule[] };
        weekDay?: TWeekDay;
    };
}

const PopupCreateAppointment: FC<PopupCreateAppointmentProps> = ({
    appointment,
    calendarAvailableDates,
    categories,
    reschedulingAppointment,
    creatingAppointment,
    creatingClient,
    employees,
    loadingAvailableDates,
    loadingCategories,
    loadingQuickDates,
    loadingServices,
    loadingSlots,
    newAppointment,
    options,
    org,
    popupAction,
    quickDates,
    services,
    slots,
}) => {
    const [appointmentState, dispatchContext] = useReducer(AppointmentContextReducer, INITIAL_STATE);
    const dispatch = useDispatch();
    const history = useHistory();
    const [availableSlots, setAvailableSlots] = useState<DateSlot[]>([]);

    const {
        form: { date, slot, category, service, employee, location, contactEntityInfo },
        dateFormat,
        appointmentCreationView: { currentStep, disableButton, subStep },
        employeeList,
        selectableLocations,
        createEntityForm,
    } = appointmentState;

    const allEmployees: AllEmployee = employees;

    const { displayDateCell } = useCreateAppointmentHandlers();

    const { ref: calendarRef, isComponentVisible: calendarVisible, setIsComponentVisible: setCalendarVisible } = useComponentVisible();

    const { forms } = useValidation({
        validation: appointmentValidation.validation,
    });
    const validation = forms[appointmentValidation.formTypes.APPOINTMENT_CREATE];

    const { moveNext, onGoBack, onChangeForm, clearState } = useCreateAppointmentNextStep(dispatchContext, appointmentState, popupAction);

    /**
     * @description display a custom calendar when use click on select another date.
     */
    const [servicesList, setServicesList] = useState<Service[]>([]);

    /**
     * @description open calendar to select another date.
     */
    const onOtherDate = () => {
        setCalendarVisible(!calendarVisible);
    };

    /**
     * @description when employee pick a date on the calendar
     */
    const onSelectCustomDate: InputDatapickerProps["onChange"] = (date) => {
        if (Array.isArray(date)) return;

        appointmentValidation.validation.clearFieldByName("APPOINTMENT_CREATE", "date");

        onChangeForm({ name: "date", value: moment(date).format(dateFormat) });

        fetchSlots(moment(date).format(dateFormat));

        if (currentStep !== "slots") moveNext();

        setCalendarVisible(false);
    };

    /**
     * @description Handle prefered date selection
     */
    const handleQuickDateSelection = (date: string) => {
        appointmentValidation.validation.clearFieldByName("APPOINTMENT_CREATE", "date");
        onChangeForm({ name: "date", value: moment(date).format(dateFormat) });
    };

    /**
     * @description select slot time after select a date Y YYY-MM-DD, user will
     * select an available time lot.
     */
    const onSelectSlot = (slot: DateSlot | undefined) => {
        onChangeForm({ name: "slot", value: slot });
    };

    const onMoveDate: InputDatapickerProps["onMoveDate"] = (payload) => {
        let date = moment();
        date.set("date", 1); // From beginning of the month
        date.set("month", payload.month);
        date.set("year", payload.year);

        const now = moment();

        // We are on the same month/year of now so we still need to show
        // from current to end of month.
        if (date.month() === now.month() && date.year() === now.year()) {
            date = now;
        }
        // Don't fetch passed dates
        if (date < now) {
            return;
        }

        appointmentValidation.validation.clearFieldByName("APPOINTMENT_CREATE", "date");
        fetchAvailableDates({ date });
    };

    /**
     * @description filter the slots time of the selected date
     */
    useEffect(() => {
        if (!slots.length) return;
        const availableSlots = slots.filter((item) => {
            return moment(item.start).format(dateFormat) === moment(date).format(dateFormat);
        });

        setAvailableSlots(availableSlots);
    }, [slots, date]);

    useEffect(() => {
        if(!calendarVisible) return;
        fetchAvailableDates({ fromToday: !date });
    }, [calendarVisible]);

    /**
     * @description fetch quick availability dates
     */
    const fetchQuickAvailability = () => {
        if (!location || !service || !employee) return;

        dispatch(
            appointmentGetQuickDates({
                locationId: location.id,
                serviceId: service.id,
                userId: employee.userId,
                start: moment.utc().format("YYYY-MM-DD"),
            })
        );
    };

    /**
     * @description Fetch slots
     */
    const fetchSlots = (dateString?: string) => {
        if (!location || !service || !employee || !date) return;
        // start and end dates are the current selected date fomatted as YYYY-MM-DD, no timezone conversion needed
        dispatch(
            appointmentGetSlotTimes({
                start: dateString ? dateString : date,
                end: dateString ? dateString : date,
                userId: employee.userId,
                serviceId: service.id,
                locationId: location.id,
            })
        );
    };

    const fetchAvailableDates = (params: { fromToday?: boolean; date?: Moment | Date }) => {
        if (!location || !service || !employee) return;
        const fetchDate = params?.fromToday ? moment.utc() : date;

        dispatch(
            appointmentGetAvailableDates({
                start: moment(params?.date ?? fetchDate)
                    .utc()
                    .format(dateFormat),
                end: moment(params?.date ?? fetchDate)
                    .endOf("month")
                    .utc()
                    .format(dateFormat),
                userId: employee.userId,
                serviceId: service.id,
                locationId: location.id,
            })
        );
    };

    // When user click on the next button
    const onNext = () => {
        if (currentStep === "customer" && subStep === "createClient") {
            const { firstName, lastName, email, phoneNumber } = createEntityForm;
            if (!firstName || !lastName || !email) return;
            dispatch(
                contactsCreateClient({
                    firstName,
                    lastName,
                    email,
                    phoneNumber: phoneNumber || "",
                    keepHistory: true,
                })
            );
            return;
        }
        if (currentStep === "confirmation") {
            onSubmitAppointment();
            return;
        }
        moveNext();
    };

    /**
     *
     * @description on create a new appointment.
     */
    const onSubmitAppointment = () => {
        if (!location || !service || !employee || !contactEntityInfo || !slot) return;

        // Send utc time to backend
        const startUtc = moment(slot.start).utc().format();
        const endUtc = moment(slot.end).utc().format();

        const _appointment = {
            userId: employee.userId,
            serviceId: service.id,
            locationId: location.id,
            entityInfo: contactEntityInfo,
            start: startUtc,
            end: endUtc,
        };

        if (popupAction === "reschedule") {
            dispatch(
                appointmentRescheduleAppointment({
                    appointmentId: appointment.id,
                    appointment: _appointment,
                })
            );
        } else {
            dispatch(appointmentCreateAppointment(_appointment));
        }
        dispatch(appointmentListByEntityId({ entityId: contactEntityInfo.entityId }));
    };

    /**
     * @description Check is the appointment overlaps on the user's schedule
     */
    const isOverlappingSchedule = (params: { appointmentStart: string; appointmentEnd: string; userSchedule?: { [weekday: string]: UnwrappedUserSchedule[] } }): boolean => {
        const { appointmentStart, appointmentEnd, userSchedule } = params;

        const aptStart = moment(appointmentStart);
        const aptEnd = moment(appointmentEnd);
        const isOverflowing = aptEnd.isAfter(aptStart, "day");

        // check if the schedule ends at 23:59 (24:00) because we need to check for overflowing this new
        // appointment on the next day
        if (isOverflowing) {
            const weekday = aptEnd.format("dddd");
            const isWithinSchedule = userSchedule?.[weekday].some((schedule) => {
                const endSchedule = moment(schedule.scheduleEnd);
                return schedule.start === "00:00" && endSchedule.isAfter(aptEnd, "minutes");
            });
            return !isWithinSchedule;
        }

        const weekDay = aptEnd.clone().format("dddd");
        const daySchedule = userSchedule?.[weekDay].find(w => w.start === aptStart.format("HH:mm"))?.scheduleEnd;
        const scheduleEndTime = moment(daySchedule).valueOf();
        return aptEnd.valueOf() > scheduleEndTime;
    };

    /**
     * @description Prefill appointment information
     */
    useEffect(() => {
        if (options && Object.keys(options).length) {
            dispatchContext(
                appointmentChangeForm({
                    contactEntityInfo: options?.entity,
                    slot: options?.slot,
                    location: options?.location,
                    employee: options?.employee,
                    date: options.date,
                })
            );
        }
    }, [options]);

    useEffect(() => {
        if (appointment && popupAction === "reschedule") {
            const { entityInfo, locationId, serviceInfo } = appointment;
            dispatchContext(
                appointmentChangeForm({
                    contactEntityInfo: typeof entityInfo !== "string" ? entityInfo : undefined,
                    location: org?.locations.find((loc) => loc.id === locationId),
                    employee: allEmployees.find((emp) => emp.userId === appointment.userId),
                    //@ts-ignore
                    service: serviceInfo,
                })
            );
        }
    }, [appointment]);

    useEffect(() => {
        // when the appointment is rescheduled, display the success view
        if (newAppointment) moveNext();
    }, [creatingAppointment, newAppointment]);

    useEffect(() => {
        if (currentStep === "categories" && popupAction !== "reschedule") {
            if (!categories.length) dispatch(serviceGetCategories());
        } else if (currentStep === "services" && category) {
            dispatch(serviceGetServices({ categoryId: category?.id }));
        } else if (currentStep === "availableDates") {
            fetchQuickAvailability();
        } else if (currentStep === "slots") {
            fetchSlots();
        }
    }, [currentStep]);

    useEffect(() => {
        // Set date to null when returning back to the date selection step
      if (currentStep === "availableDates") onChangeForm({ name: "date", value: undefined });
    }, [currentStep]);

    useEffect(() => {
        let disableNext = false;
        if (
            (currentStep === "categories" && !category) ||
            (currentStep === "services" && !service) ||
            (currentStep === "availableDates" && !date) ||
            (currentStep === "location" && (!location || !employee)) ||
            (currentStep === "customer" && !contactEntityInfo) ||
            (currentStep === "slots" && !slot)
        )
            disableNext = true;
        if (subStep === "createClient") disableNext = false;
        dispatchContext(appointmentCreationChangeView({ disableButton: disableNext }));
    }, [currentStep, date, slot, category, service, location, employee, contactEntityInfo, subStep]);

    useEffect(() => {
        if (popupAction === "reschedule") return;
        if (service) {
            // get available employees inside a service.
            const serviceEmployees = employees.filter((employee) => {
                return service?.employees?.includes(employee.userId);
            });
            dispatchContext(appointmentChangeEmployeesList(serviceEmployees));

            // If quick appointment creation, set the end time according to the service duration
            if (popupAction === "quickCreate" && slot) {
                const endTime = moment(slot.start).valueOf() + service.duration * 1000;
                const newSlot = {
                    ...slot,
                    end: moment(endTime).format(),
                };
                onChangeForm({ name: "slot", value: newSlot });

                // Check if appointment overlaps on the user's schedule
                const isOverlapping = isOverlappingSchedule({
                    appointmentStart: newSlot.start,
                    appointmentEnd: newSlot.end,
                    userSchedule: options?.employeeSchedule,
                });
                if (isOverlapping) {
                    toastHelper.toastStartContent("warning", "The service duration is overflowing on the user's schedule but you can still make the booking.");
                }
            }

            // get available locations inside a service.
            const locationList = org.locations.filter((loc) => {
                return service.locations?.includes(loc.id);
            });
            dispatchContext(appointmentChangeLocations(locationList));

            // If there is one location only, select it by default
            if (locationList.length === 1) onChangeForm({ name: "location", value: locationList[0] });
        }
    }, [service]);

    useEffect(() => {
        /**
         * @description In the case of quick appointment creation, if the user isn't part of
         * a given service we should not be able to select it.
         * Also remove services where the user doesn't work
         */
        if (popupAction === "quickCreate" && employee) {
            if (!employee || !location) return;
            const employeeServices = services.filter((ser) => {
                return ser.employees.includes(employee.userId) && ser.locations.includes(location.id);
            });
            setServicesList(employeeServices);
            return;
        }
        setServicesList(services);
    }, [services]);

    useEffect(() => {
        history.push(`${window.location.pathname}?step=${popupAction === "reschedule" ? "availableDates" : "categories"}`);
        // When rescheduling, move directly to the availableDates step
        dispatchContext(
            appointmentCreationChangeView({
                currentStep: popupAction === "reschedule" ? "availableDates" : "categories",
            })
        );

        return () => {
            history.replace(window.location.pathname);
            clearState();
        };
    }, []);

    return (
        <AppointmentContext.Provider
            value={{
                dispatch: dispatchContext,
                state: appointmentState,
            }}>
            <div key={`${currentStep}`} className={styles.popup__rescheduling}>
                <Row>
                    <Column size="12">
                        <h5 data-testid="modalTitle" className="text-geyser-900 text-19">
                            {currentStep === "slots"
                                ? `${displayDateCell(date)} Availabilities`
                                : currentStep === "availableDates"
                                ? "Select a date"
                                : currentStep === "confirmation"
                                ? "Confirmation"
                                : currentStep === "categories"
                                ? "Select a category"
                                : currentStep === "services"
                                ? `${category?.name} - Select a service`
                                : currentStep === "location"
                                ? `Select the location and the employee`
                                : null}
                        </h5>
                    </Column>
                </Row>

                <Row>
                    {validation?.["date"]?.message ? (
                        <Column size="12">
                            <FormMessages
                                messages={[
                                    {
                                        status: validation?.["date"].status?.color || "info",
                                        value: validation?.["date"].message || "",
                                    },
                                ]}
                            />
                        </Column>
                    ) : null}
                </Row>

                {(currentStep === "availableDates" || currentStep === "slots") && (
                    <div ref={calendarRef} className={styles.calendar__wrapper}>
                        <Button onClick={onOtherDate} variant="primary" outline padding={false}>
                            Select another date
                        </Button>
                        {calendarVisible && (
                            <div className={styles.calendar__content}>
                                <InputDatepicker
                                    style={{ width: "100%" }}
                                    filteredDates={calendarAvailableDates}
                                    date={date}
                                    onChange={onSelectCustomDate}
                                    onlyCalendar
                                    onMoveDate={(payload) => onMoveDate({ month: payload.month, year: payload.year })}
                                    disabled={loadingAvailableDates}
                                    loading={loadingAvailableDates}
                                />
                            </div>
                        )}
                    </div>
                )}

                {currentStep === "categories" && (
                    <div className={`${styles.categories} ${styles.step__content}`}>
                        {loadingCategories
                            ? [...Array(3).keys()].map((a) => (
                                  <div key={a} className={styles.service__item}>
                                      <Loader size="sm" variant="secondary" />
                                  </div>
                              ))
                            : categories.length
                            ? categories.map((cat, index) => (
                                  <div
                                      role="listitem"
                                      aria-label="Category"
                                      onClick={() => onChangeForm({ name: "category", value: cat })}
                                      key={index}
                                      className={`${styles.service__item} ${cat.id === category?.id ? styles.active : ""}`}>
                                      <span>{soltivoHelper.sliceText(cat.name, 26)}</span>
                                  </div>
                              ))
                            : null}
                    </div>
                )}

                {currentStep === "services" &&
                    (loadingServices ? (
                        <div className={`${styles.categories} ${styles.step__content}`}>
                            {[...Array(3).keys()].map((a) => (
                                <div key={a} className={styles.service__item}>
                                    <Loader size="sm" variant="secondary" />
                                </div>
                            ))}
                        </div>
                    ) : servicesList.length ? (
                        <div className={`${styles.categories} ${styles.step__content}`}>
                            {servicesList.map((ser, index) => (
                                <div
                                    role="listitem"
                                    aria-label="Service"
                                    key={index}
                                    onClick={() => {
                                        onChangeForm({ name: "service", value: ser });
                                        // When service changes, clear location and employee
                                        if (popupAction === "create") dispatchContext(appointmentChangeForm({ location: undefined, employee: undefined }));
                                    }}
                                    className={`${styles.service__item} ${ser.id === service?.id ? styles.active : ""}`}>
                                    <span>{soltivoHelper.sliceText(ser.title, 26)}</span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-geyser-600 text-13">Looks like there are no service in the category.</p>
                    ))}

                {currentStep === "location" && (
                    <div className={`${styles.locations__employee__selection} ${styles.step__content}`}>
                        <FormControl
                            labelProps={{
                                value: "Location",
                            }}
                            status={validation?.["location"]?.status?.color}
                            footerProps={{
                                value: validation?.["location"]?.message,
                            }}>
                            <InputSelect
                                name={"location"}
                                data-testid="selectLocation"
                                options={selectableLocations.map((loc) => ({ value: loc.name, option: loc }))}
                                value={location?.name || ""}
                                onChange={({ option }) => onChangeForm({ name: "location", value: option })}
                                placeholder={"Location"}
                            />
                        </FormControl>

                        <FormControl
                            labelProps={{
                                value: "Employee",
                            }}
                            status={validation?.["employee"]?.status?.color}
                            footerProps={{
                                value: validation?.["employee"]?.message,
                            }}>
                            <InputSelect
                                name={"employee"}
                                data-testid="selectEmployee"
                                options={employeeList.map((emp) => ({ value: `${emp.firstName} ${emp.lastName}`, option: emp }))}
                                value={employee ? `${employee.firstName} ${employee.lastName}` : ""}
                                onChange={({ option }) => onChangeForm({ name: "employee", value: option })}
                                placeholder={"Search an employee"}
                            />
                        </FormControl>
                    </div>
                )}

                {(currentStep === "availableDates" || currentStep === "slots") && (
                    <div className={`${styles.step__content}`}>
                        {currentStep === "availableDates" ? (
                            loadingQuickDates ? (
                                <div className={styles.dates__group}>
                                    {[...Array(3).keys()].map((a) => (
                                        <div key={a} className={styles.date}>
                                            {loadingQuickDates ? <Loader size="sm" variant="secondary" /> : <span>-</span>}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className={styles.dates__group}>
                                    {quickDates.length
                                        ? quickDates.slice(0, 8).map((_date, i) => {
                                              return (
                                                  <div
                                                      key={i}
                                                      role="listbox"
                                                      aria-label="Available date"
                                                      onClick={() => handleQuickDateSelection(_date)}
                                                      className={`${styles.date} ${date && moment(date).format(dateFormat) === moment(_date).format(dateFormat) ? styles.active : ""}`}>
                                                      <span>{displayDateCell(_date)}</span>
                                                      <span>{moment.utc(_date).format("dddd")}</span>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            )
                        ) : null}

                        {date && currentStep === "slots" ? (
                            <div className={styles.slots__group}>
                                {loadingSlots ? (
                                    <div className={styles.slots}>
                                        {[...Array(3).keys()].map((item) => (
                                            <div key={item} className={`${styles.slot} ${styles.loading}`}>
                                                <Loader size="sm" variant="secondary" />
                                            </div>
                                        ))}
                                    </div>
                                ) : !availableSlots.length ? (
                                    <p>Looks like there are no slots available for {moment(date).format("dddd DD, MMMM YYYY")}.</p>
                                ) : (
                                    <div className={styles.slots}>
                                        {availableSlots.map((_slot, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    role="listbox"
                                                    aria-label="Slot item"
                                                    onClick={() => onSelectSlot(_slot)}
                                                    className={`${styles.slot} ${JSON.stringify(_slot) === JSON.stringify(slot) ? styles.active : ""}`}>
                                                    <span>{moment(_slot.start).format("HH:mm")}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                )}

                {currentStep === "customer" && <SelectCustomerStep moveNext={moveNext} onChangeForm={onChangeForm} />}

                {slot && currentStep === "confirmation" && (
                    <div className={styles.confirmation__view}>
                        <div className={styles.confirmation__view__content}>
                            <div>
                                <span>Customer</span>
                                <p>
                                    {contactEntityInfo?.firstName} {contactEntityInfo?.lastName}
                                </p>
                            </div>
                            <div>
                                <span>Service</span>
                                <p>{service?.title}</p>
                            </div>
                            <div>
                                <span>Staff</span>
                                <p>
                                    <Link onClick={() => popupHelper.popupEnd()} to={employee?.roleId === "0" ? "/settings/profile" : `/administration/team/employees/${employee?.userId}`}>
                                        {employee?.firstName || ""} {employee?.lastName || ""}
                                    </Link>
                                </p>
                            </div>
                            <div>
                                <span>Location</span>
                                <p>{location?.name}</p>
                            </div>
                            <div>
                                <span>Duration</span>
                                <p>{(service?.duration || 0) / 60} minutes</p>
                            </div>
                            {popupAction !== "reschedule" && (
                                <>
                                    <div>
                                        <span>Start time</span>
                                        <p>{moment(slot.start).format("LL [at] LT")}</p>
                                    </div>
                                    <div>
                                        <span>End time</span>
                                        <p>{moment(slot.end).format("LL [at] LT")}</p>
                                    </div>
                                </>
                            )}
                        </div>
                        {popupAction === "reschedule" && (
                            <div className={styles.confirmation__view__new__dates}>
                                <div className={styles.confirmation__view__new__dates__header}>
                                    <p></p>
                                    <p className="text-geyser-800">Before</p>
                                    <p></p>
                                    <p className="text-geyser-900">New date</p>
                                </div>
                                <div className={styles.confirmation__view__new__dates__values}>
                                    <span>Start time</span>
                                    <p className="text-geyser-800">{moment(appointment.start).format("LL [at] LT")}</p>
                                    <ArrowRight24Px />
                                    <p className="text-geyser-900">{moment(slot.start).format("LL [at] LT")}</p>
                                </div>
                                <div className={styles.confirmation__view__new__dates__values}>
                                    <span>End time</span>
                                    <p className="text-geyser-800">{moment(appointment.end).format("LL [at] LT")}</p>
                                    <ArrowRight24Px />
                                    <p className="text-geyser-900">{moment(slot.end).format("LL [at] LT")}</p>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {currentStep === "success" && (
                    <div className={styles.success__view}>
                        <CheckedSuccess />
                        <h6 className="text-geyser-900">{popupAction === "reschedule" ? "Appointment rescheduled" : "Appointment scheduled"}</h6>
                        <p className="text-geyser-500 text-15">{moment(slot?.start).format("LL [at] LT")}</p>
                    </div>
                )}

                {currentStep !== "success" && (
                    <div className={styles.actions__button}>
                        {currentStep === "categories" || (currentStep === "availableDates" && popupAction === "reschedule") ? (
                            <Button onClick={() => popupHelper.popupEnd()} outline padding={false} variant="geyser-500">
                                Cancel
                            </Button>
                        ) : (
                            <Button onClick={onGoBack} outline padding={false} variant="geyser-500">
                                Back
                            </Button>
                        )}

                        {currentStep === "confirmation" ? (
                            <Button data-testid="confirmButton" loading={creatingAppointment || reschedulingAppointment} onClick={onNext} disabled={disableButton} variant="primary">
                                {popupAction === "reschedule" ? "Reschedule" : "Confirm"}
                            </Button>
                        ) : (
                            <Button onClick={onNext} disabled={disableButton} loading={creatingClient} variant="primary">
                                Next <ArrowRight />
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </AppointmentContext.Provider>
    );
};
const mapStateToProps = ({ AppointmentReducer, OrgReducer, AuthReducer, HRMReducer, ServiceReducer, ContactsReducer }: RootState) => ({
    creatingAppointment: AppointmentReducer.creatingAppointment,
    reschedulingAppointment: AppointmentReducer.rescheduleAppointment.loading,
    quickDates: AppointmentReducer.quickDates,
    calendarAvailableDates: AppointmentReducer.availableDates,
    slots: AppointmentReducer.slots,
    loadingSlots: AppointmentReducer.loadingSlots,
    appointment: AppointmentReducer.appointment as Appointment,
    loadingAvailableDates: AppointmentReducer.loadingAvailableDates,
    loadingQuickDates: AppointmentReducer.loadingQuickDates,
    newAppointment: AppointmentReducer.newAppointment,
    org: OrgReducer.org as Organization,
    user: AuthReducer.user as User,
    employees: HRMReducer.employees,
    categories: ServiceReducer.categories,
    loadingCategories: ServiceReducer.loadingCategories,
    services: ServiceReducer.services,
    loadingServices: ServiceReducer.loadingServices,
    creatingClient: ContactsReducer.creatingClient,
});

type MapState = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PopupCreateAppointment);

import PercentageBar from "@soltivo/draw-a-line/core/components/others/percentage.bar/percentage.bar";
import { useReducer, useState } from "react";
import Business from "./steps/step.business/step.business";
import Industry from "./steps/step.industry/step.industry";
import "./popup.first.setup.styles.scss";
import { Button, popupHelper } from "@soltivo/draw-a-line";
import CreateLocation from "./steps/step.create.location/create.location";
import Employees from "./steps/step.employees/step.employees";
import Confirmation from "./steps/step.confirm/step.confirm";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { FC } from "react";
import { useEffect } from "react";
import { Organization } from "../../../../applications/auth/organization";
import GuideOrgContextReducer, { INITIAL_STATE } from "../context/reducer";
import GuideOrgContext from "../context/guide.org.context";
import { guideChangeAction, guideOrgChangeFormState } from "../context/actions";
import StepServices from "./steps/step.services/step.services";
import { secondsToHoursSeconds } from "../../../../applications/appointment/helpers/time";
import { ReactComponent as SteveHiFiveSVG } from "@soltivo/draw-a-line/core/assets/illutrations/SteveHiFive.svg";
import { CheckCircle24Px } from "@soltivo/draw-a-line/core/components/icons";
import { orgChangeState } from "../../../../applications/auth/redux/org/reducer/actions";
import useGuideStepsNavigation from "../hooks/useGuideStepsNavigation";
export interface PopupFirstSetupStepProps {}

interface PopupFirstSetupProps extends MapStateToProps {}

const PopupFirstSetup: FC<PopupFirstSetupProps> = ({ org, tour, categories, allServices, employees }) => {
    const dispatch = useDispatch();
    const [guideState, dispatchContext] = useReducer(GuideOrgContextReducer, INITIAL_STATE);

    const { stepHeading, actions } = guideState;

    useGuideStepsNavigation();

    useEffect(() => {
        dispatch(
            orgChangeState({
                tour: {
                    ...tour,
                    subStep: undefined,
                },
            })
        );

        // clear on back when moved to a main step, normally we don't want the user
        // to setup twice the same step, but if you feel like you need it you can,
        // setup manualy inside a step component. 😉
        return () => {
            dispatchContext(
                guideChangeAction({
                    type: "all",
                    payload: {
                        back: {
                            ...actions.back,
                            onClick: undefined,
                        },
                        next: {
                            ...actions.next,
                            onClick: undefined,
                            value: "Next",
                        },
                    },
                })
            );
        };
    }, [tour.stepName]);

    useEffect(() => {
        // default form information, if the user have done a fewer of these steps
        // her/his organization must already have some informations, this hook is just
        // for prefill possible completed steps informations.
        dispatchContext(
            guideOrgChangeFormState({
                businessType: org.businessType || "",
                industry: org.industry || "",
                locations: org.locations,
                service: {
                    categories: categories.map((category) => {
                        return {
                            description: category.description,
                            name: category.name,
                            services: allServices
                                .filter((service) => service.category === category.id)
                                .map((service) => ({
                                    bufferTime: `${service.bufferTime / 60} minutes`,
                                    category: category.name,
                                    duration: `${secondsToHoursSeconds(service.duration).hours || "00"}:${secondsToHoursSeconds(service.duration).minutes || "00"}` || "00:00",
                                    price: `${service.price}`,
                                    title: service.title,
                                    locations: service.locations || [],
                                })),
                        };
                    }),
                },
                // This should contain employees infos for invitation but not the admin user, so remove the admin
                employees: employees.filter(emp => emp.roleId !== "0").length
                    ? employees.map((employee) => ({
                          firstName: employee?.firstName || "",
                          lastName: employee?.lastName || "",
                          personalEmail: employee?.personalEmail || "",
                      }))
                    : [{ firstName: "", lastName: "", personalEmail: "" }],
            })
        );
    }, []);

    const [completed, setCompleted] = useState(false);
    useEffect(() => {
        if (org.configuration.confirmation) {
            setCompleted(true);
        }
    }, [org.configuration.confirmation]);

    useEffect(() => {
        let i: NodeJS.Timeout;
        if (completed) {
            i = setTimeout(() => {
                popupHelper.popupEnd();
            }, 5000);
        }

        return () => {
            clearTimeout(i);
        };
    }, [completed]);

    useEffect(() => {
        if (org.businessType === "individual") {
            if (org.configuration.services) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "confirmation",
                            step: tour.stepsProgress.indexOf("confirmation"),
                        },
                    })
                );
            } else if (org.configuration.locations) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "services",
                            step: tour.stepsProgress.indexOf("services"),
                        },
                    })
                );
            } else if (org.configuration.industry) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "locations",
                            step: tour.stepsProgress.indexOf("locations"),
                        },
                    })
                );
            } else if (org.configuration.businessType) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "industry",
                            step: tour.stepsProgress.indexOf("industry"),
                        },
                    })
                );
            }
        } else if (org.businessType === "business" || org.businessType === "multi-organization") {
            const configurationEmployeesMissing = Object.keys(org.configuration.employees).filter((key) => !org.configuration.employees[key].schedule || !org.configuration.employees[key].services);
            if (Object.keys(org.configuration.employees).length && !configurationEmployeesMissing.length) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "confirmation",
                            step: tour.stepsProgress.indexOf("confirmation"),
                        },
                    })
                );
            } else if (org.configuration.services) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "employees",
                            step: tour.stepsProgress.indexOf("employees"),
                        },
                    })
                );
            } else if (org.configuration.locations) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "services",
                            step: tour.stepsProgress.indexOf("services"),
                        },
                    })
                );
            } else if (org.configuration.industry) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "locations",
                            step: tour.stepsProgress.indexOf("locations"),
                        },
                    })
                );
            } else if (org.configuration.businessType) {
                dispatch(
                    orgChangeState({
                        tour: {
                            ...tour,
                            stepName: "industry",
                            step: tour.stepsProgress.indexOf("industry"),
                        },
                    })
                );
            }
        }
    }, [org.configuration]);

    return (
        <GuideOrgContext.Provider
            value={{
                dispatch: dispatchContext,
                state: guideState,
            }}>
            <div id="popupFirstSetup" className="pcfs">
                {/* Header */}
                {!completed ? (
                    <div className="pcfs-header d-flex w-100">
                        <h5 className="text-geyser-900">{stepHeading.title}</h5>
                        {stepHeading.description ? <p className="text-geyser-500 text-15">{stepHeading.description}</p> : null}
                    </div>
                ) : null}

                <div className="guide__content scroll-bluemine">
                    {/* Display body of steps. */}
                    {completed ? (
                        <div className="tour__completed">
                            <div>
                                <SteveHiFiveSVG />
                            </div>
                            <div>
                                <h6>
                                    Organization configured <CheckCircle24Px />
                                </h6>
                            </div>
                        </div>
                    ) : tour.stepName === "businessType" ? (
                        <Business />
                    ) : tour.stepName === "industry" ? (
                        //
                        <Industry />
                    ) : tour.stepName === "locations" ? (
                        <CreateLocation />
                    ) : tour.stepName === "services" ? (
                        <StepServices />
                    ) : tour.stepName === "employees" ? (
                        <Employees />
                    ) : tour.stepName === "confirmation" ? (
                        <Confirmation />
                    ) : null}
                </div>

                {/* Percentage */}
                {!completed ? (
                    <div className="percentage">
                        {tour.step > 0 && actions.back.onClick ? (
                            <Button onClick={actions.back.onClick} disabled={tour.loadingRequest || actions.back.disabled} reversedPrimary outline border>
                                Back
                            </Button>
                        ) : null}

                        <PercentageBar
                            className="step__bar"
                            percentage={org.configuration.confirmation ? 100 : (tour.step / tour.stepsProgress.length) * 100}
                            style={{
                                width: "100%",
                            }}
                        />

                        <Button
                            disabled={tour.loadingRequest || actions.next.disabled}
                            className="guide-next-button"
                            onClick={actions.next.onClick}
                            loading={
                                tour.loadingRequest
                                    ? {
                                          keepContent: tour.loadingRequest,
                                      }
                                    : undefined
                            }
                            // disabled={disabled || settingLocations || loadingUpdateOrg}
                            reversedPrimary
                            outline
                            border>
                            {tour.stepName !== "confirmation" ? actions.next.value || "Next" : "Confirm"}
                        </Button>
                    </div>
                ) : null}
            </div>
        </GuideOrgContext.Provider>
    );
};

const mapStateToProps = ({ OrgReducer, ServiceReducer, HRMReducer }: RootState) => ({
    org: OrgReducer.org as Organization,
    tour: OrgReducer.tour,
    categories: ServiceReducer.categories,
    allServices: ServiceReducer.allServices,
    employees: HRMReducer.employees,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PopupFirstSetup);

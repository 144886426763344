import { Organization, Location } from "../../../../applications/auth/organization";
import { TEmployee } from "../../../../applications/hrm/hrm";
import { Industries } from "../../../../helpers/industry/Industries";
import { types } from "./types";
import * as guideActions from "./actions";

export interface GuideOrgContextState {
    // disableNext: boolean;
    actions: {
        next: {
            disabled: boolean;
            value: string;
            onClick?: () => void;
        };
        back: {
            disabled: boolean;
            onClick?: () => void;
        };
    };
    // onNext?: () => void;
    // onBack?: () => void;
    stepHeading: {
        title: string;
        description?: string;
    };
    locationIndex?: number;
    form: {
        businessType?: Organization["businessType"];
        industry: string;
        locations: Omit<Organization["locations"][0], "id">[];
        confirmForm: Partial<Organization>;
        service: {
            categories: { name: string; description: string; services: { category: string; title: string; price: string; duration: string; bufferTime: string; locations: string[] }[] }[];
        };
        employees: Pick<TEmployee, "firstName" | "lastName" | "personalEmail">[];
        schedules: {
            location: Partial<Omit<Location, "id">> | undefined;
        };
        employeeServices: { serviceId: string; categoryId: string }[];
    };
    industries: Readonly<Industries>;
}

export const INITIAL_STATE: GuideOrgContextState = {
    /**
     * @description disabled on next button.
     */
    // disableNext: true,
    /**
     * @description allow to call on next inside custom component which have more steps.
     */
    // onNext: undefined,
    /**
     * @description allow to go back for steps or inside steps.
     */
    // onBack: undefined,

    actions: {
        next: {
            disabled: false,
            value: "Next",
            onClick: undefined,
        },
        back: {
            disabled: false,
            onClick: undefined,
        },
    },

    stepHeading: {
        title: "",
        description: undefined,
    },
    /**
     * @description selected location being edited in the form.
     */
    locationIndex: undefined,
    form: {
        businessType: undefined,
        industry: "",
        locations: [],
        confirmForm: {
            name: "",
            primaryLocation: undefined,
            fiscalYear: "",
            timeZone: "",
            currency: "",
            timeFormat: "",
        },
        service: {
            categories: [],
        },
        employees: [],
        schedules: {
            location: undefined,
        },
        employeeServices: [],
    },
    industries: new Industries(),
};

const GuideOrgContextReducer = (state = INITIAL_STATE, action: { type: string; payload?: any }): GuideOrgContextState => {
    switch (action.type) {
        case types.GUIDE_ORG_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.GUIDE_ORG_FORM_STATE:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
            };
        case types.GUIDE_CHANGE_ACTION:
            const data: ReturnType<typeof guideActions["guideChangeAction"]>["payload"] = action.payload;

            return {
                ...state,
                actions:
                    data.type === "all"
                        ? {
                              ...state.actions,
                              ...data.payload,
                          }
                        : data.type === "back"
                        ? {
                              ...state.actions,
                              back: {
                                  ...state.actions.back,
                                  ...data.payload,
                              },
                          }
                        : data.type === "next"
                        ? {
                              ...state.actions,
                              next: {
                                  ...state.actions.next,
                                  ...data.payload,
                              },
                          }
                        : {
                              ...state.actions,
                          },
            };

        default:
            return state;
    }
};

export default GuideOrgContextReducer;

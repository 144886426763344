import React, { FC, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { User } from "../../applications/auth/auth";
import { RootState } from "../../redux/reducers";
import Layout from "../layout/layout";
import { routesCheckLoading } from "./context/routes/actions";
import RoutesReducer, { INITIAL_STATE } from "./context/routes/reducer";
import RoutesContext from "./context/routes/routes.context";
import { Organization } from "../../applications/auth/organization";
import GuideOrganization from "../blocks/guide.organization/guide.organization";
import history from "./history";

interface PrivateRouteProps extends MapStateToProps {
    /**
     * @description use private route without layout.
     */
    clearLayout?: boolean;
    render?: (
        props: RouteComponentProps<
            {
                [x: string]: string | undefined;
            },
            any,
            unknown
        >
    ) => React.ReactNode;
    component?: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, any, unknown>>;
    path: string | string[];
    children?: React.ReactNode | any;
}

/**
 * @description creates a private route to any route you want the user to be logged in.
 */
const PrivateRoute: FC<PrivateRouteProps> = ({
    clearLayout,
    loading_current_user,
    user,
    org,
    loadingOrganizations,
    loadingOrg,
    children,
    render,
    path,
    component,
}) => {
    const [state, dispatch] = useReducer(RoutesReducer, INITIAL_STATE);
    const { loading } = state;
    const isLoading =
        loadingOrg ||
        (loadingOrganizations && user?.roleId === "0") ||
        loading_current_user;

    useEffect(() => {
        dispatch(routesCheckLoading({ loading: isLoading }));
    }, [isLoading]);

    // useSoftwareSupport();

    if (!user && !loading_current_user) {
        return <Redirect to={`/login?next=${encodeURIComponent(history.location.pathname)}`} />;
    }

    //without layout.
    if (clearLayout) {
        return (
            <RoutesContext.Provider
                value={{
                    state,
                    dispatch,
                }}>
                <Route exact path={path} component={component ? component : undefined} render={render ? render : children ? () => children : undefined} />
                <GuideOrganization user={user as User} org={org as Organization} />
            </RoutesContext.Provider>
        );
    }

    return (
        <RoutesContext.Provider
            value={{
                state,
                dispatch,
            }}>
            <Layout>{!loading && <Route exact path={path} component={component ? component : undefined} render={render ? render : children ? () => children : undefined} />}</Layout>
            <GuideOrganization user={user as User} org={org as Organization} />
        </RoutesContext.Provider>
    );
};

const mapStateToProps = ({ AuthReducer, OrgReducer, BillingsReducer, DomainReducer, HRMReducer, ServiceReducer }: RootState) => ({
    user: AuthReducer.user,
    org: OrgReducer.org,
    loadingOrganizations: OrgReducer.loadingOrganizations,
    loadingOrg: OrgReducer.loadingOrg,
    loadingCustomer: BillingsReducer.loadingCustomer,
    loading_current_user: AuthReducer.loading_current_user,
    loadingDomainInfo: DomainReducer.loadingDomainInfo,
    loadingEmployees: HRMReducer.loadingEmployees,
    loadingAllServices: ServiceReducer.loadingAllServices,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PrivateRoute);

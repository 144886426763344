import { types } from "./types";

const _INITIAL_STATE = {
    style: "table", //table || column
    search: "",
    workspaceType: "personal", // personal || team

    // workspaces: null,
    // personal : null, //personal type workspaces
    // team: null, //team type workspaces
    // folder: null, // opened folder

    location: [],
    // folders : [],

    fileOpen: null, // recent file opened with filestream url
    file: null, // file or file details ...
    form: {
        files: [],
    },
    selectedItems: [], // selected items
    copyItemsList: [], // array of items to be copy
    cutItemsList: [], // array of items to be cut
    error: null,
};

const StorageReducer = (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case types.STORAGE_TOGGLE_STYLE_REQUEST:
            const isValidPayload = action.payload === "column" || action.payload === "table";
            return {
                ...state,
                style: isValidPayload ? action.payload : state.style === "column" ? "table" : "column",
            };

        case types.STORAGE_ON_CHANGE_FORM:
            return {
                ...state,
                form: action.payload,
            };

        case types.STORAGE_SEARCH_SUCCESS:
            return {
                ...state,
                search: action.payload,
            };

        case types.STORAGE_SEARCH_FAILURE:
            return {
                ...state,
                search: "",
            };

        // case types.STORAGE_GET_WORKSPACES_SUCCESS :
        //     return {
        //         ...state,
        //         workspaces: action.payload.all,

        //         personal: action.payload.personal.map(workspace => {
        //             workspace.items = [];
        //             return workspace;
        //         }),
        //         team: action.payload.team.map(workspace => {
        //             workspace.items = [];
        //             return workspace;
        //         }),
        //     };

        // case types.STORAGE_GET_WORKSPACES_FAILURE :
        //     return {
        //         ...state,
        //         workspaces: null,
        //         personal: null,
        //         team: null,
        //     };

        case types.STORAGE_GET_WORKSPACE_ITEMS_SUCCESS:
            return {
                ...state,
                workspaceType: action.payload.type,
                location: [action.payload[action.payload.type]],
            };

        case types.STORAGE_GET_WORKSPACE_ITEMS_FAILURE:
            return {
                ...state,
                error: action.payload,
                location: [],
            };

        case types.STORAGE_GET_FOLDER_ITEMS_SUCCESS:
            return {
                ...state,
                location: action.payload.location,
                selectedItems: [],
            };

        case types.STORAGE_GET_FOLDER_ITEMS_FAILURE:
            return {
                ...state,
                location: [],
            };

        case types.STORAGE_OPEN_FILE_REQUEST:
            return {
                ...state,
                fileOpen: null,
            };
        case types.STORAGE_OPEN_FILE_SUCCESS:
            return {
                ...state,
                fileOpen: action.payload,
            };

        case types.STORAGE_OPEN_FILE_FAILURE:
            return {
                ...state,
                fileOpen: null,
            };

        case types.STORAGE_CLOSE_FILE_REQUEST:
            return {
                ...state,
                fileOpen: null,
                file: null,
                selectedItems: [],
                copyItemsList: [],
            };

        case types.STORAGE_GET_FILE_SUCCESS:
            return {
                ...state,
                file: action.payload,
            };

        case types.STORAGE_GET_FILE_FAILURE:
            return {
                ...state,
                file: null,
            };

        case types.STORAGE_DELETE_ITEMS_SUCCESS:
            return {
                ...state,
                location: [...action.payload.location],
                selectedItems: [],
            };

        case types.STORAGE_DELETE_ITEMS_FAILURE:
            return {
                ...state,
                selectedItems: [],
            };

        case types.STORAGE_RENAME_FILE_SUCCESS:
            return {
                ...state,
                location: [...action.payload.location],
            };

        case types.STORAGE_RENAME_FILE_FAILURE:
            return {
                ...state,
            };

        case types.STORAGE_RENAME_FOLDER_SUCCESS:
            return {
                ...state,
                location: [...action.payload.location],
            };

        case types.STORAGE_RENAME_FOLDER_FAILURE:
            return {
                ...state,
            };

        case types.STORAGE_COPY_ITEM_REQUEST:
            return {
                ...state,
                copyItemsList: state.selectedItems,
            };

        case types.STORAGE_COPY_ITEM_CANCEL:
            // Action hasn't been created.
            return {
                ...state,
                copyItemsList: [],
            };

        case types.STORAGE_PASTE_ITEMS_SUCCESS:
            return {
                ...state,
                cutItemsList: [],
                copyItemsList: [],
                selectedItems: [],
            };

        case types.STORAGE_PASTE_ITEMS_FAILURE:
            return {
                ...state,
                cutItemsList: [],
                copyItemsList: [],
                selectedItems: [],
                error: action.payload,
            };

        case types.STORAGE_CUT_ITEM_REQUEST:
            return {
                ...state,
                cutItemsList: state.selectedItems,
            };

        case types.STORAGE_CUT_ITEM_CANCEL:
            // Action hasn't been created.
            return {
                ...state,
                cutItemsList: [],
            };

        case types.STORAGE_MOVE_ITEMS_SUCCESS:
            return {
                ...state,
                cutItemsList: [],
                copyItemsList: [],
                selectedItems: [],
                location: action.payload.location,
            };

        case types.STORAGE_MOVE_ITEMS_FAILURE:
            return {
                ...state,
                cutItemsList: [],
                copyItemsList: [],
                selectedItems: [],
                error: action.payload,
            };

        case types.STORAGE_SELECT_ITEM_REQUEST:
            const { multiple, ...rest } = action.payload;

            return {
                ...state,
                selectedItems: multiple ? [...state.selectedItems, rest] : [rest],
            };

        case types.STORAGE_SELECT_ITEM_CANCEL:
            return {
                ...state,
                selectedItems: [],
            };

        case types.STORAGE_ORGANIZATION_STATS_SUCCESS:
            return {
                ...state,
                organizationStorage: action.payload,
            };

        case types.STORAGE_ORGANIZATION_STATS_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default StorageReducer;

import { ReactComponent as AmericanExpressSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/american_express.svg";
import { ReactComponent as DiscoverSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/discover.svg";
import { ReactComponent as JBCSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/jbc.svg";
import { ReactComponent as MaestroSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/maestro.svg";
import { ReactComponent as MasterCardSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/master_card.svg";
import { ReactComponent as UnionPaySVG } from "@soltivo/draw-a-line/core/assets/icons/cards/union_pay.svg";
import { ReactComponent as VisaSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/visa.svg";
import { ReactComponent as DinersSVG } from "@soltivo/draw-a-line/core/assets/icons/cards/diners.svg";

/**
 * @description list card with icons.
 */
export const _CREDIT_CARDS = [
    {
        name: "amex",
        Icon: AmericanExpressSVG,
    },
    {
        name: "discover",
        Icon: DiscoverSVG,
    },
    {
        name: "jcb",
        Icon: JBCSVG,
    },
    {
        name: "maestro",
        Icon: MaestroSVG,
    },
    {
        name: "mastercard",
        Icon: MasterCardSVG,
    },
    {
        name: "unionpay",
        Icon: UnionPaySVG,
    },
    {
        name: "visa",
        Icon: VisaSVG,
    },
    {
        name: "diners",
        Icon: DinersSVG,
    },
];

export const _CANADA_STATES = [
    {
        name: "Alberta",
        abbreviation: "AB",
    },
    {
        name: "British Columbia",
        abbreviation: "BC",
    },
    {
        name: "Manitoba",
        abbreviation: "MB",
    },
    {
        name: "New Brunswick",
        abbreviation: "NB",
    },
    {
        name: "Newfoundland and Labrador",
        abbreviation: "NL",
    },
    {
        name: "Northwest Territories",
        abbreviation: "NT",
    },
    {
        name: "Nova Scotia",
        abbreviation: "NS",
    },
    {
        name: "Nunavut",
        abbreviation: "NU",
    },
    {
        name: "Ontario",
        abbreviation: "ON",
    },
    {
        name: "Prince Edward Island",
        abbreviation: "PE",
    },
    {
        name: "Quebec",
        abbreviation: "QC",
    },
    {
        name: "Saskatchewan",
        abbreviation: "SK",
    },
    {
        name: "Yukon Territory",
        abbreviation: "YT",
    },
];

import React, { FC, useEffect, useState } from "react";
import styles from "./popup.create.org.module.scss";
import { connect, useDispatch } from "react-redux";
import { orgCreateSecondaryOrganization } from "../../../../../../auth/redux/org/reducer/actions";
import GOALS from "../../../../../../../helpers/json/goals.json";
import { RootState } from "../../../../../../../redux/reducers";
import { PopupState } from "@soltivo/draw-a-line/core/components/popup/reducer/reducer";
import { InputSelect, InputText, Button, popupHelper, FormControl, FormMessages, useValidation } from "@soltivo/draw-a-line";
import organizationValidation from "../../../../../../auth/validation/organization.validation";

interface PopupCreateOrgProps extends MapStateToProps {
    footer:
        | ((children: (props: Pick<PopupState, "show" | "actions" | "removeActions" | "animation" | "width" | "cancelEscEvent">) => JSX.Element | React.ReactNode) => JSX.Element | React.ReactNode)
        | undefined;
}

const PopupCreateOrg: FC<PopupCreateOrgProps> = ({ createOrganization, footer }) => {
    const { forms } = useValidation(organizationValidation);
    const validation = forms[organizationValidation.formTypes.ORG_CREATE_SECONDARY];

    const dispatch = useDispatch();
    const [form, setForm] = useState({
        name: "",
        goal: "",
    });

    const onChange = (payload: Partial<ReturnType<() => typeof form>>) => {
        setForm({ ...form, ...payload });
    };

    const onSubmit = () => {
        const goal = GOALS.find((g) => g.value === form.goal);
        organizationValidation.validation.clearAll();
        dispatch(
            orgCreateSecondaryOrganization({
                goal: goal?.camelCase,
                name: form.name,
            })
        );
    };

    useEffect(() => {
        return () => {
            organizationValidation.validation.clearAll();
        };
    }, []);

    return (
        <>
            <div className={styles.popup}>
                {validation?.global?.message ? (
                    <FormMessages
                        messages={[
                            {
                                status: validation?.global?.status?.color || "info",
                                value: validation.global.message || "",
                            },
                        ]}
                    />
                ) : null}

                <FormControl
                    status={validation?.name?.status?.color}
                    labelProps={{
                        value: "Organization name",
                    }}
                    footerProps={{
                        value: validation?.name?.message || "",
                    }}>
                    <InputText
                        disabled={createOrganization.loading}
                        name="name"
                        autoComplete={"off"}
                        value={form.name}
                        onChange={(e) => {
                            onChange({ name: e.target.value });
                            organizationValidation.validation.clearAll();
                        }}
                        placeholder={"Organization name"}
                        required
                    />
                </FormControl>

                <FormControl
                    status={validation?.goal?.status?.color}
                    labelProps={{
                        value: "Organization's goal",
                    }}
                    footerProps={{
                        value: validation?.goal?.message || "",
                    }}>
                    <InputSelect
                        disabled={createOrganization.loading}
                        name="goal"
                        onChange={(e) => {
                            onChange({ goal: e.value as string });
                            organizationValidation.validation.clearAll();
                        }}
                        placeholder="Goal"
                        value={form.goal}
                        options={GOALS}
                    />
                </FormControl>
            </div>
            {footer &&
                footer(() => (
                    <div>
                        <Button onClick={() => popupHelper.popupEnd()} variant="grey-300" outline border={false} padding={false}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => onSubmit()}
                            loading={createOrganization.loading ? { keepContent: true } : false}
                            variant="primary"
                            outline
                            border={false}
                            padding={false}
                            disabled={createOrganization.loading}>
                            Create
                        </Button>
                    </div>
                ))}
        </>
    );
};

const mapStateToProps = ({ OrgReducer }: RootState) => ({
    createOrganization: OrgReducer.createOrganization,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PopupCreateOrg);

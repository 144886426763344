import { useEffect, useReducer, useState } from "react";
import Routes from "./routes/routes";
import { useDispatch } from "react-redux";
import { authCurrentAuthenticatedUser } from "../applications/auth/redux/auth/reducer/actions";
import { HeaderContext } from "./layout/header/reducer/header.context";
import { HeaderReducer, INITIAL_STATE } from "./layout/header/reducer/reducer";
import useBuilderAuthentication from "../helpers/hooks/useBuilderAuthentication";
import { useCurrency } from "../applications/sales/hooks/useCurrency";
// import { CacheHelper } from "../redux/cache.helper";

const App = () => {
    const [headerState, dispatchHeaderContext] = useReducer(HeaderReducer, INITIAL_STATE);
    const [hasMountedBefore, setHasMountedBefore] = useState(false);
    const dispatch = useDispatch();
    /**
     * @description whenever the application mounts we make a api call
     * to aws server to check if there is any user logged in.
     */
    useEffect(() => {
        if (hasMountedBefore) {
            return;
        }
        // dispatch(authGetIndexedDBData());
        dispatch(authCurrentAuthenticatedUser());
        setHasMountedBefore(true);
    }, [dispatch, setHasMountedBefore, hasMountedBefore]);

    useCurrency();

    useBuilderAuthentication();

    return (
        <HeaderContext.Provider
            value={{
                dispatch: dispatchHeaderContext,
                state: headerState,
            }}>
            <Routes />
        </HeaderContext.Provider>
    );
};

export default App;

import React from "react";
import { INITIAL_STATE } from "./reducer";

const authContext = React.createContext<{
    state: ReturnType<() => typeof INITIAL_STATE>;
    dispatch: React.Dispatch<{
        type: string;
        payload?: any;
    }>;
}>({
    state: INITIAL_STATE,
    dispatch: () => undefined,
});
export default authContext;

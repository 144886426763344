import apiV2 from "../../../../../helpers/api.v2";
import { NotificationsActions } from "../reducer/actions.types";
const _URL = `${process.env.REACT_APP_API_URL}/notifications`;

/**
 * @description Notifications API calls.
 */
class NotificationsClass {
    /**
     * @description get all notifications
     */
    notificationsGetAllNotifications() {
        return apiV2.getRequest(`${_URL}/`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update notification
     */
    notificationsUpdateNotification(payload: NotificationsActions["notificationsUpdateNotification"]["payload"]) {
        const { id, isRead } = payload;
        return apiV2.putRequest(`${_URL}/${id}`, {
            data: JSON.stringify({ isRead }),
            useToken: "accessToken",
        });
    }

    /**
     * @description get notification settings
     */
     notificationsGetSettings() {
        return apiV2.getRequest(`${_URL}/settings`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update notifications settings
     */
     notificationsUpdateSettings(payload: NotificationsActions["notificationsUpdateSettings"]["payload"]) {
        return apiV2.putRequest(`${_URL}/settings`, {
            useToken: "accessToken",
            data: JSON.stringify(payload)
        });
    }

    /**
     * @description delete notification
     */
    notificationsDeleteNotifications(payload: NotificationsActions["notificationsDeleteNotifications"]["payload"]) {
        return apiV2.deleteRequest(`${_URL}/${payload.id}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description delete notification
     */
     notificationsClearNotifications(payload: NotificationsActions["notificationsClearNotifications"]["payload"]) {
        return apiV2.postRequest(`${_URL}/bulk-delete`, {
            data: JSON.stringify({ notificationIds: payload.ids }),
            useToken: "accessToken",
        });
    }
}

export default new NotificationsClass();

import { all } from "redux-saga/effects";
import AuthSaga from "../applications/auth/redux/auth/saga/saga";
import OrgSaga from "../applications/auth/redux/org/saga/saga";
import EmailSaga from "../applications/email/redux/saga/saga";
import contactsSaga from "../applications/contacts/redux/saga/saga";
// import StorageSaga from "../applications/storage/redux/saga/saga";
import HRMSaga from "../applications/hrm/redux/saga/saga";
import ProjectSaga from "../applications/project/redux/saga/saga";
// import ProductSaga from "../applications/product/redux/saga/saga";
// import AccountingSaga from "../applications/accounting/redux/saga/saga";
import ServiceSaga from "../applications/service/redux/saga/saga";
import WebsiteSaga from "../applications/website/redux/saga/saga";
import InvoiceSaga from "../applications/settings/redux/billings/saga/saga";
import AppointmentSaga from "../applications/appointment/redux/saga/saga";
import InventorySaga from "../applications/inventory/redux/saga/saga";
import SalesSaga from "../applications/sales/redux/saga/saga";
import DomainSaga from "../applications/settings/redux/domain/saga/saga";
import NotificationSaga from "../applications/settings/redux/notifications/saga/saga";

/**
 * @description combine sagas.
 */
export default function* Sagas() {
    yield all([
        AuthSaga(),
        EmailSaga(),
        DomainSaga(),
        // SettingsSaga(),
        ProjectSaga(),
        contactsSaga(),
        // StorageSaga(),
        HRMSaga(),
        OrgSaga(),
        // AccountingSaga(),
        // ProductSaga(),
        ServiceSaga(),
        WebsiteSaga(),
        InvoiceSaga(),
        AppointmentSaga(),
        InventorySaga(),
        SalesSaga(),
        NotificationSaga(),
    ]);
}

import { ContactFormMessage, ThemeList } from "../../types";
import { WebsiteList } from "../../website";
import { types } from "./types";

export interface WebsiteState {
    websites: WebsiteList;
    loadingWebsites: boolean;
    websiteInformation: {
        themeId?: string
    }
    themes: ThemeList;
    loadingThemes: boolean;

    messages: {
        items: ContactFormMessage[];
        lastKey?: string;
    };
    loadingMessages: boolean;

    message: ContactFormMessage | null;
    loadingMessage: boolean;

    error: any;
}

const INITIAL_STATE: WebsiteState = {
    websites: [],
    loadingWebsites: true,
    themes: [],
    loadingThemes: true,
    websiteInformation: {},
    messages: {
        items: [],
    },
    loadingMessages: true,
    message: {} as ContactFormMessage,
    loadingMessage: true,
    error: null,
};

const WebsiteReducer = (state = INITIAL_STATE, action: { type: string; payload: any }): WebsiteState => {
    switch (action.type) {
        case types.WEBSITE_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.WEBSITE_GET_THEMES_REQUEST: {
            return {
                ...state,
                loadingThemes: true,
            };
        }

        case types.WEBSITE_GET_THEMES_SUCCESS:
            return {
                ...state,
                themes: action.payload,
                loadingThemes: false,
            };

        case types.WEBSITE_GET_THEMES_FAILURE:
            return {
                ...state,
                loadingThemes: false,
            };

        case types.WEBSITE_GET_THEME_PAGES_REQUEST: {
            return {
                ...state,
                loadingWebsites: true,
            };
        }

        case types.WEBSITE_GET_THEME_PAGES_SUCCESS:
            return {
                ...state,
                websiteInformation: action.payload,
                loadingWebsites: false,
            };

        case types.WEBSITE_GET_THEME_PAGES_FAILURE:
            return {
                ...state,
                loadingWebsites: false,
            };

        case types.CONTACT_FORM_GET_MESSAGES_REQUEST:
            return {
                ...state,
                loadingMessages: true,
            };

        case types.CONTACT_FORM_GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.payload,
                loadingMessages: false,
            };

        case types.CONTACT_FORM_GET_MESSAGES_FAILURE:
            return {
                ...state,
                messages: {
                    items: [],
                },
                loadingMessages: false,
                error: action.payload,
            };

        case types.CONTACT_FORM_GET_MESSAGE_REQUEST:
            return {
                ...state,
                message: null,
                loadingMessage: true,
            };

        case types.CONTACT_FORM_GET_MESSAGE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                loadingMessage: false,
            };

        case types.CONTACT_FORM_GET_MESSAGE_FAILURE:
            return {
                ...state,
                message: null,
                loadingMessage: false,
                error: action.payload,
            };

        case types.CONTACT_FORM_UPDATE_MESSAGE_REQUEST:
            return {
                ...state,
                loadingMessage: true,
            };
        case types.CONTACT_FORM_UPDATE_MESSAGE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                loadingMessage: false,
            };

        case types.CONTACT_FORM_UPDATE_MESSAGE_FAILURE:
            return {
                ...state,
                loadingMessage: false,
                error: action.payload,
            };

        case types.CONTACT_FORM_DELETE_MESSAGE_REQUEST:
            return {
                ...state,
                loadingMessage: true,
            };
        case types.CONTACT_FORM_DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                message: null,
                loadingMessage: false,
            };

        case types.CONTACT_FORM_DELETE_MESSAGE_FAILURE:
            return {
                ...state,
                loadingMessage: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default WebsiteReducer;

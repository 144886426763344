import apiV2 from "../../../../helpers/api.v2";
import { InventoryActionsTypes } from "../reducer/actions.types";

var _INVENTORY_URL = `${process.env.REACT_APP_API_URL}/inventory`;

/**
 * @description Inventory API calls.
 */
class InventoryClass {
    inventoryGetEquipments() {
        return apiV2.getRequest(`${_INVENTORY_URL}/items`, {
            useToken: "accessToken",
        });
    }

    inventoryGetEquipment(payload: InventoryActionsTypes["inventoryGetEquipment"]["payload"]) {
        return apiV2.getRequest(`${_INVENTORY_URL}/items/${payload.equipmentId}`, {
            useToken: "accessToken",
        });
    }

    inventoryCreateEquipment(payload: InventoryActionsTypes["inventoryCreateEquipment"]["payload"]) {
        return apiV2.postRequest(`${_INVENTORY_URL}/v1/items/equipments`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    inventoryUpdateEquipment(payload: InventoryActionsTypes["inventoryUpdateEquipment"]["payload"]) {
        return apiV2.putRequest(`${_INVENTORY_URL}/v1/items/equipments/${payload.id}`, {
            useToken: "accessToken",
            data: JSON.stringify(payload.item),
        });
    }

    inventoryDeleteEquipment(payload: string) {
        return apiV2.deleteRequest(`${_INVENTORY_URL}/items/equipment/${payload}`, {
            useToken: "accessToken",
        });
    }

    inventoryArchiveEquipment(payload: InventoryActionsTypes["inventoryArchiveEquipment"]["payload"]) {
        const { equipmentId, isArchived } = payload
        return apiV2.putRequest(`${_INVENTORY_URL}/v2/items/equipments/${equipmentId}/archive`, {
            useToken: "accessToken",
            data: JSON.stringify({ isArchived })
        });
    }

    inventorySearchEquipments(payload: InventoryActionsTypes["inventorySearchEquipments"]["payload"]) {
        return apiV2.getRequest(`${_INVENTORY_URL}/items/search?data=${payload.query}`, {
            useToken: "accessToken",
        });
    }

    inventorySearchProduct(payload: InventoryActionsTypes["inventorySearchProduct"]["payload"]) {
        const { filterType, filterValue, archived } = payload;
        return apiV2.getRequest(`${_INVENTORY_URL}/products/search?filterType=${filterType}&filterValue=${filterValue}&archived=${archived}`, {
            useToken: "accessToken",
        });
    }

    inventoryGetProducts() {
        return apiV2.getRequest(`${_INVENTORY_URL}/products`, {
            useToken: "accessToken",
        });
    }

    inventoryCreateProduct(payload: InventoryActionsTypes["inventoryCreateProduct"]["payload"]) {
        return apiV2.postRequest(`${_INVENTORY_URL}/products`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    inventoryUpdateProduct(payload: InventoryActionsTypes["inventoryUpdateProduct"]["payload"]) {
        const { product, productId } = payload;
        return apiV2.putRequest(`${_INVENTORY_URL}/products/${productId}`, {
            useToken: "accessToken",
            data: JSON.stringify(product),
        });
    }

    inventoryGetProduct(payload: InventoryActionsTypes["inventoryGetProduct"]["payload"]) {
        return apiV2.getRequest(`${_INVENTORY_URL}/products/${payload.productId}`, {
            useToken: "accessToken",
        });
    }

    inventoryDeleteProduct(payload: InventoryActionsTypes["inventoryDeleteProduct"]["payload"]) {
        return apiV2.deleteRequest(`${_INVENTORY_URL}/products/${payload.productId}`, {
            useToken: "accessToken",
        });
    }

    inventoryArchiveProduct(payload: InventoryActionsTypes["inventoryArchiveProduct"]["payload"]) {
        return apiV2.deleteRequest(`${_INVENTORY_URL}/products/${payload.productId}/archive`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get signed url
     */
     getSignedUrl(payload: { file: File }) {
        return apiV2.getRequest(`${_INVENTORY_URL}/url?contentType=${payload.file.type}&cacheControl=max-age=300,public`);
    }
}

export default new InventoryClass();

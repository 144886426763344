import { types } from "./types";
import {updateList} from './utils'

const INITIAL_STATE = {
    tasks: [], 
    loading: false,
    isListView: true,
    task: null,
    personalTasks: [],
    projects: {items: [], lastKey: null},
    tasklists: [],
    subTasks: [],
    lastKey: {
        taskList: null,
        task: null,
        personalTask: null
    },
    statuses: [],
    organisationMembers: [],
    error: {
        actions: [],
        message: null
    },
    loader: {
        actions: []
    }
}

const projectReducer = (state = INITIAL_STATE, action: {
    type: string;
	payload: any;
}) => {
    const { error, loader } = state;
    const { payload } = action;
    switch (action.type) {
        case types.CHANGE_VIEW:
            return {
                ...state,
                isListView: !state.isListView,
            };

        case types.START_LOADING:
            return {
                ...state,
                loading: true,
                loader: {
                    ...loader,
                    actions: [...new Set([...loader.actions, payload])], 
                }
            };

        case types.STOP_LOADING:
            return {
                ...state,
                loading: false,
                loader: {
                    ...loader,
                    actions: loader.actions.filter(action => action !== payload),
                }
            };

        case types.CLEAR_ERROR:
            return {
                ...state,
                error: {
                    ...error,
                    actions: error.actions.filter(action => action !== payload),
                    message: null
                }
            };

        case types.RENDER_ERROR:
            return {
                ...state,
                loading: false,
                error: {
                    ...error,
                    actions: [...new Set([...error.actions, payload.type])], 
                    message: action.payload.error.message
                }
            };

        case types.CREATE_PROJECT_SUCCESS:
            const currentProjects = state.projects?.items ? state.projects.items: []
            return {
                ...state,
                loading: false,
                projects: {...state.projects, items: [action.payload, ...currentProjects]}
            };

        case types.UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                projects: {...state.projects, items: action.payload} 
            };

        case types.DELETE_PROJECT_SUCCESS:
            return {
                ...state,
            };
        
        case types.UPDATE_TASKS_LIST_SUCCESS:
            return {
                ...state,
            };

        case types.DELETE_TASKS_LIST_SUCCESS:
            return {
                ...state,
                tasklists: action.payload
            };

        case types.CREATE_TASKS_LIST_SUCCESS:
            return {
                ...state,
                tasklists: [...state.tasklists, action.payload]
            };

        case types.CREATE_TASK_SUCCESS:
            return {
                ...state,
                tasks: [...state.tasks, action.payload]
            };
        
        case types.DELETE_TASK_SUCCESS:
            return {
                ...state,
                tasks: action.payload
            };

        case types.CREATE_PERSONAL_TASK_SUCCESS:
            return {
                ...state,
                personalTasks: [...state.personalTasks, action.payload]
            };

        case types.CREATE_SUBTASK_SUCCESS:
            let currentTask: any = state.task
            currentTask.totalSubTasks = currentTask.totalSubTasks + 1 || 1
            let newProgress = currentTask.doneSubTasks/currentTask.totalSubTasks * 100
            currentTask.progress = newProgress
            return {
                ...state,
                subTasks: [...state.subTasks, action.payload],
                tasks: updateList(state.tasks, currentTask, "taskId")
            };

        case types.CREATE_STATUS_COLUMN_SUCCESS:
            return {
                ...state,
                statuses: action.payload.statuses
            };

        case types.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                tasks: updateList(state.tasks, action.payload, "taskId"),
                task: action.payload
            };

        case types.UPDATE_TASKS_STATUS:
            const taskItems = state.tasks.map((item: any) => {
                const task = action.payload.find((t: any) => t.taskId === item.taskId)
                if(task) item = task
                return item
            })
            return {
                ...state,
                tasks: taskItems
            };

        case types.UPDATE_PERSONAL_TASKS_SUCCESS:
            return {
                ...state,
                personalTasks: updateList(state.personalTasks, action.payload, "taskId")
            };

        case types.UPDATE_SUBTASK_SUCCESS:
            const updatedSubtask = action.payload
            let selectedTask: any = state.task
            if(updatedSubtask.status) {
                selectedTask.doneSubTasks +=  1
            } else {
                selectedTask.doneSubTasks -=  1
            }
            let progress = selectedTask.doneSubTasks/selectedTask.totalSubTasks * 100
            selectedTask.progress = progress
            return {
                ...state,
                subTasks: updateList(state.subTasks, updatedSubtask, "subTaskId"),
                tasks: updateList(state.tasks, selectedTask, "taskId")
            };

        case types.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                statuses: action.payload
            };

        case types.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                task: action.payload.data
            };

        case types.GET_PROJECT_LIST_REQUEST:
            return {
                ...state,
                tasklists: [],
                lastKey: {...state.lastKey, taskList: null }
            };

        case types.GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projects: action.payload,
            };

        case types.GET_TASKLISTS_SUCCESS:
            return {
                ...state,
                tasklists: action.payload.isLoadingMore ? [...state.tasklists, ...action.payload.taskList]: action.payload.taskList,
                lastKey: {...state.lastKey, taskList: action.payload.lastKey},
            };


        case types.GET_TASKS_REQUEST:
            return {
                ...state,
                tasks: !action.payload.isLoadingMore ? []: state.tasks,
                lastKey: {...state.lastKey, task: !action.payload.isLoadingMore ? null: state.lastKey.task }
            };

        case types.GET_TASKS_SUCCESS:
            return {
                ...state,
                tasks: action.payload.isLoadingMore ? [...state.tasks, ...action.payload.tasks]: action.payload.tasks,
                lastKey: {...state.lastKey, task: action.payload.lastKey}
            };

        case types.GET_PERSONAL_TASKS_SUCCESS:
            return {
                ...state,
                personalTasks: [...state.personalTasks, ...action.payload.tasks],
                lastKey: {...state.lastKey, personalTask: action.payload.lastKey}
            };

        case types.GET_SUBTASK_SUCCESS:
            return {
                ...state,
                subTasks: action.payload.isLoadingMore ? [...state.subTasks, ...action.payload.subTasks]: action.payload.subTasks,
                lastKey: {...state.lastKey, subTask: action.payload.lastKey}
            };

        case types.GET_SINGLE_TASK_SUCCESS:
            return {
                ...state,
                task: action.payload
            };

        case types.GET_ORGANISATION_MEMBERS_SUCCESS:
            return {
                ...state,
                organisationMembers: action.payload
            };

        case types.GET_STATUSES_SUCCESS:
            return {
                ...state,
                statuses: action.payload
            };

        case types.REORDER_COLUMNS_SUCCESS:
            return {
                ...state,
                statuses: action.payload
            };

        case types.DELETE_STATUS_SUCCESS:
            return {
                ...state,
                statuses: action.payload,
            };

        case types.DELETE_PERSONAL_TASK_SUCCESS:
            const newTasks = state.personalTasks.filter((task: any) => task.taskId !== action.payload.taskId)
            return {
                ...state,
                personalTasks: newTasks
            };

        default:
            return state;
    }
}

export default projectReducer;
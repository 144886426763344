import { types } from "./types";
import { GuideOrgContextState } from "./reducer";

/**
 * @description change state object.
 */
export const guideOrgChangeContextState = (payload: Partial<GuideOrgContextState>) => ({
    type: types.GUIDE_ORG_CHANGE_STATE,
    payload,
});

/**
 * @description change form object.
 */
export const guideOrgChangeFormState = (payload: Partial<GuideOrgContextState["form"]>) => ({
    type: types.GUIDE_ORG_FORM_STATE,
    payload,
});

/**
 * @description change guide action
 */
export const guideChangeAction = <T extends "next" | "back" | "all">(payload: {
    type: T;
    payload: T extends "next" ? Partial<GuideOrgContextState["actions"]["next"]> : T extends "back" ? Partial<GuideOrgContextState["actions"]["back"]> | undefined : GuideOrgContextState["actions"];
}) => ({
    type: types.GUIDE_CHANGE_ACTION,
    payload,
});

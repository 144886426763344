import { takeLatest } from "redux-saga/effects";
import { emailTypes } from "../reducer/types";
import { emailSetupEmailBusiness } from "./sagas/emailSetupEmailBusiness";
import { emailGetOutExchanges } from "./sagas/emailGetOutExchanges";
import { emailGetInExchanges } from "./sagas/emailGetInExchanges";
import { emailGetDeletedExchanges } from "./sagas/emailGetDeletedExchanges";
import { emailSendEmail } from "./sagas/emailSendEmail";
import { emailReplyEmail } from "./sagas/emailReplyEmail";
import { emailGetEmail } from "./sagas/emailGetEmail";
import { emailDeleteExchange } from "./sagas/emailDeleteExchange";
import { emailRestoreEmail } from "./sagas/emailRestoreEmail";
import { emailSearchEntityContacts } from "./sagas/emailSearchEntityContacts";
import { emailMarkReadAs } from "./sagas/emailMarkReadAs";

export default function* EmailSaga() {
    yield takeLatest(emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_REQUEST, emailSetupEmailBusiness);
    yield takeLatest(emailTypes.EMAIL_OUT_EXCHANGES_REQUEST, emailGetOutExchanges);
    yield takeLatest(emailTypes.EMAIL_IN_EXCHANGES_REQUEST, emailGetInExchanges);
    yield takeLatest(emailTypes.EMAIL_GET_DELETED_EXCHANGES_REQUEST, emailGetDeletedExchanges);
    yield takeLatest(emailTypes.EMAIL_SEND_EMAIL_REQUEST, emailSendEmail);
    yield takeLatest(emailTypes.EMAIL_GET_EMAIL_REQUEST, emailGetEmail);
    yield takeLatest(emailTypes.EMAIL_DELETE_EXCHANGE_REQUEST, emailDeleteExchange);
    yield takeLatest(emailTypes.EMAIL_RESTORE_EMAIL_REQUEST, emailRestoreEmail);
    yield takeLatest(emailTypes.EMAIL_REPLY_EMAIL_REQUEST, emailReplyEmail);
    yield takeLatest(emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_REQUEST, emailSearchEntityContacts);
    yield takeLatest(emailTypes.EMAIL_MARK_READ_AS_REQUEST, emailMarkReadAs);
}

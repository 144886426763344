import { types } from "./types";

export interface AuthContextState {
    form: {
        email: string;
        password: string;
    };
    SUForm: {
        email: string;
        password: string;
        phone_number: string;
        code: string;
    };
    CAForm: {
        firstName: string;
        lastName: string;
        orgName: string;
        orgGoal: string;
    };
    FPForm: {
        email: string;
        code: string;
        new_password: string;
        confirm_password: string;
    };
    employeeForm: {
        email: string;
        password: string;
        oldPassword: string;
        firstName: string;
        lastName: string;
    };
}

export const INITIAL_STATE: AuthContextState = {
    form: {
        email: "",
        password: "",
    },
    SUForm: {
        email: "",
        password: "",
        phone_number: "",
        code: "",
    },
    CAForm: {
        firstName: "",
        lastName: "",
        orgName: "",
        orgGoal: "",
    },
    FPForm: {
        email: "",
        code: "",
        new_password: "",
        confirm_password: "",
    },
    employeeForm: {
        email: "",
        password: "",
        oldPassword: "",
        firstName: "",
        lastName: "",
    },
};

/**
 * @description Auth context reducer
 */
export const AuthContextReducer = (state = INITIAL_STATE, action: { type: string; payload?: any }): AuthContextState => {
    switch (action.type) {
        case types.AUTH_ON_CHANGE_LOGIN_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
            };

        case types.AUTH_ON_CHANGE_SIGN_UP_FORM:
            return {
                ...state,
                SUForm: {
                    ...state.SUForm,
                    ...action.payload,
                },
            };

        case types.AUTH_ON_CHANGE_CREATE_ADMIN_FORM:
            return {
                ...state,
                CAForm: {
                    ...state.CAForm,
                    ...action.payload,
                },
            };

        case types.AUTH_ON_CHANGE_FORGOT_PASSWORD_FORM:
            return {
                ...state,
                FPForm: {
                    ...state.FPForm,
                    ...action.payload,
                },
            };

        case types.EMPLOYEE_ON_CHANGE_ONBOARDING:
            return {
                ...state,
                employeeForm: {
                    ...state.employeeForm,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default AuthContextReducer;

import { Textarea } from "@soltivo/draw-a-line";
import { FC, useState, useRef } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { AppointmentContextState } from "../../context/reducer";
import { Button, popupHelper } from "@soltivo/draw-a-line";
import { PopupState } from "@soltivo/draw-a-line/core/components/popup/reducer/reducer";

interface PopupEditNoteProps {
    form: AppointmentContextState["form"];
    footer: ((
        children: (
          props: Pick<
            PopupState,
            | "show"
            | "actions"
            | "removeActions"
            | "animation"
            | "width"
            | "cancelEscEvent"
          >
        ) => JSX.Element | React.ReactNode
      ) => JSX.Element | React.ReactNode) | undefined;
}

const PopupEditNote: FC<PopupEditNoteProps> = ({ footer }) => {
    const [note, setNote] = useState("");
    const submitRef = useRef<HTMLButtonElement>(null);
    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
    };

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setNote(e.target.value);
    };

    return (
        <>
        <div>
            <form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column" }}>
                <label className="text-12 text-geyser-800 mb-10">Appointment notes</label>
                <Textarea onChange={onChange} value={note} rows={3} style={{ resize: "vertical" }} placeholder={"Add an appointment note (visible by staff only)"} />

            </form>
        </div>
        {footer && footer(() => (
            <div>
                <Button onClick={() => popupHelper.popupEnd()} variant="grey-300" outline border={false} padding={false}>
                    Cancel
                </Button>
                <Button onClick={() => submitRef.current?.click()} variant="primary" outline border={false} padding={false}>
                    Save
                </Button>
            </div>
        ))}
        </>
    );
};
const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
    loading: AppointmentReducer.deletingStatus,
});

export default connect(mapStateToProps)(PopupEditNote);

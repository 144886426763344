import React, { useState, useEffect } from "react";
import { FormControl, InputText, InputSelect } from "@soltivo/draw-a-line";
import contriesStates from "@soltivo/draw-a-line/core/assets/json/contries.states.json";
import styles from "./custom.location.form.module.scss";
import { MapboxPlacesFeatures } from "../../../../../applications/auth/organization";

export interface CustomLocationFormProps {
    onChangeCustomLocation(customLocation: MapboxPlacesFeatures): void;
}

const CustomLocationForm: React.FC<CustomLocationFormProps> = ({ onChangeCustomLocation }) => {
    const [form, setForm] = useState<{
        address: string;
        name: string;
        country: string;
        state: string;
        postCode: string;
    }>({
        address: "",
        name: "",
        country: "",
        state: "",
        postCode: "",
    });
    type formKeys = "name" | "address" | "country" | "state" | "postCode";
    const formLabel = {
        name: "Place Name",
        address: "Your Custom Address",
        country: "Country",
        state: "State",
        postCode: "Zip Code",
    };

    useEffect(() => {
        if(!form.address || !form.name) return;
        const { address, country, postCode, state } = form;
        const place: MapboxPlacesFeatures = {
            id: `postcode.${Date.now()}`,
            type: "place",
            place_name: address,
            text: form.name,
            center: [],
            context: [
                {
                    id: `postcode.${Date.now()}`,
                    text: postCode,
                },
                {
                    id: `place.${Date.now()}`,
                    text: form.name,
                },
                {
                    id: `region.${Date.now()}`,
                    text: state,
                },
                {
                    id: `country.${Date.now()}`,
                    text: country,
                },
            ],
        };
        onChangeCustomLocation(place);
    }, [form]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm({ ...form, [name]: value });
    };

    return (
        <div className={styles.create__custom__location}>
            {Object.keys(form).map((key, index) => {
                const formKey = key as formKeys;
                if (formKey === "country" || formKey === "state") {
                    let countryList = contriesStates.countries.map((c) => ({ value: c.country }));
                    let statesList = (contriesStates.countries.find(st => st.country === form.country)?.states || []).map(state => ({ value: state }));
                    return (
                        <FormControl key={index} labelProps={{ value: formLabel[formKey] }}>
                            <InputSelect
                                name={formKey}
                                value={form[formKey]}
                                data-testid={formKey}
                                options={formKey === "country" ? countryList : statesList}
                                onChange={(e) => setForm({ ...form, [formKey]: e.value as string })}
                                placeholder={`Select ${formKey}`}
                            />
                        </FormControl>
                    );
                }
                return (
                    <FormControl key={index} labelProps={{ value: formLabel[formKey].replace(/([A-Z0-9])/g, " $1") }}>
                        <InputText name={formKey} value={form[formKey]} onChange={onChange} placeholder={formLabel[formKey].replace(/([A-Z0-9])/g, " $1")} autoComplete="off" />
                    </FormControl>
                );
            })}
        </div>
    );
};

export default CustomLocationForm;

import { types } from "./types";
export const INITIAL_STATE: {
    loading: boolean;
} = {
    loading: true,
};

/**
 * @description Routes context.
 */
const RoutesReducer = (state = INITIAL_STATE, action: { type: string; payload?: any }) => {
    switch (action.type) {
        case types.ROUTES_CHECK_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            };
        default:
            return INITIAL_STATE;
    }
};

export default RoutesReducer;

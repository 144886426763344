import { call, put, select } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description get email out exchanges (SENT MESSAGES)
 */
export function* emailGetOutExchanges({ payload }: EmailActions["emailGetOutExchanges"]) {
    try {
        const { data } = yield call(emailClass.emailGetOutExchanges, payload);

        let { out_exchanges }: { out_exchanges: RootState["EmailReducer"]["out_exchanges"] } = yield select(({ EmailReducer }: RootState) => ({
            out_exchanges: EmailReducer.out_exchanges,
        }));

        // set new key
        out_exchanges.lastKey = data.lastKey;

        //with last key (pagination)
        if (payload) {
            out_exchanges.items = [...out_exchanges.items, ...data.items];
        } else {
            out_exchanges.items = data.items;
        }

        yield put({
            type: emailTypes.EMAIL_OUT_EXCHANGES_SUCCESS,
            payload: out_exchanges,
        });
    } catch (error: any) {
        yield put({
            type: emailTypes.EMAIL_OUT_EXCHANGES_FAILURE,
            payload: error,
        });
    }
}

import { Invoice,  Refund, SalesConfigurations, Tax, PaymentResponse } from "@soltivo/types";
import { InvoiceList, SalesMetrics, InvoiceValues, UnpaidInvoiceMetrics, TransactionsMetrics, StripeLocation, StripeTerminalType } from "../../sales";
import { types } from "./types";

export interface SalesState {
    error: any;
    transactions: (PaymentResponse | Refund)[];
    payments: PaymentResponse[];
    transaction: PaymentResponse | null;
    loadingTransactions: boolean;
    loadingPayments: boolean;
    creatingTransaction: boolean;
    creatingInvoice: boolean;
    creatingPayment: boolean;
    invoice?: Invoice;
    loadingInvoice: boolean;
    invoices: InvoiceList;
    loadingInvoices: boolean;
    invoiceNumber: string;
    paymentNumber: string;
    refundNumber: string;
    updatingInvoice: boolean;
    updatingPayment: boolean;
    loadingTransaction: boolean;
    deletingTransaction: boolean;
    updatingInvoiceNote: boolean;
    stripeConnectConfigured: 'not-started' | 'started' | 'completed';
    checkingStripeConnect: boolean;
    linkingStripeConnect: boolean;
    lastMonthSalesMetrics: SalesMetrics | undefined;
    transactionsMetrics?: TransactionsMetrics;
    unpaidInvoiceMetrics?: UnpaidInvoiceMetrics;
    loadingIncomeMetrics: boolean;
    loadingTransactionsMetrics: boolean;
    invoicesInPayment: Invoice[];
    loadingInvoicesInPayment: boolean;
    salesConfigurations: SalesConfigurations | null;
    loadingSalesConfig: boolean;
    updatingConfig: boolean;
    creatingTax: boolean;
    creatingRefund: boolean;
    refund: Refund | null;
    updatingRefund: boolean;
    voidingRefund: boolean;
    voidingInvoice: boolean;
    loadingRefund: boolean;
    sendingEmail: boolean;
    loadingTaxes: boolean;
    taxes: Tax[];
    loadingTax: boolean;
    tax: Tax | null;
    updatingTax: boolean;
    isCalculating: boolean;
    invoiceValues: InvoiceValues | null;
    updatingBusinessNumber: boolean;
    searchingInvoices: boolean;
    invoicesSearchResults: InvoiceList;
    addingTerminal: boolean;
    stripeLocations: StripeLocation[];
    loadingStripeLocations: boolean;
    creatingStripeLocation: boolean;
    loadingTerminals: boolean;
    terminalsInLocation: StripeTerminalType[];
    editingTerminalLabel: boolean;
    deletingTerminal: boolean;
    terminalConfigured: boolean;
    deletingStripeLocation: boolean;
    verifyingTerminalConfig: boolean;
    voidingPayment: boolean;
}

const _INITIAL_STATE: SalesState = {
    error: null,
    transactions: [],
    payments: [],
    transaction: null,
    loadingTransactions: false,
    loadingPayments: false,
    creatingTransaction: false,
    creatingInvoice: false,
    creatingPayment: false,
    invoice: undefined,
    loadingInvoice: false,
    invoices: [],
    loadingInvoices: false,
    invoiceNumber: "",
    paymentNumber: "",
    refundNumber: "",
    updatingInvoice: false,
    updatingPayment: false,
    loadingTransaction: false,
    deletingTransaction: false,
    updatingInvoiceNote: false,
    stripeConnectConfigured: 'not-started',
    checkingStripeConnect: false,
    linkingStripeConnect: false,
    lastMonthSalesMetrics: undefined,
    transactionsMetrics: undefined,
    unpaidInvoiceMetrics: undefined,
    loadingIncomeMetrics: true,
    loadingTransactionsMetrics: true,
    invoicesInPayment: [],
    loadingInvoicesInPayment: false,
    salesConfigurations: null,
    loadingSalesConfig: false,
    updatingConfig: false,
    creatingTax: false,
    creatingRefund: false,
    refund: null,
    updatingRefund: false,
    voidingRefund: false,
    voidingInvoice: false,
    loadingRefund: false,
    sendingEmail: false,
    loadingTaxes: false,
    taxes: [],
    loadingTax: false,
    updatingTax: false,
    tax: null,
    isCalculating: false,
    invoiceValues: null,
    updatingBusinessNumber: false,
    searchingInvoices: false,
    invoicesSearchResults: [],
    addingTerminal: false,
    stripeLocations: [],
    loadingStripeLocations: false,
    creatingStripeLocation: false,
    loadingTerminals: false,
    terminalsInLocation: [],
    editingTerminalLabel: false,
    deletingTerminal: false,
    terminalConfigured: false,
    deletingStripeLocation: false,
    verifyingTerminalConfig: false,
    voidingPayment: false,
};

const SalesReducer = (state = _INITIAL_STATE, action: { type: string; payload: any }): SalesState => {
    switch (action.type) {
        case types.SALES_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...(action.payload as Partial<SalesState>),
            };

        case types.SALES_GET_TRANSACTIONS_REQUEST:
            return {
                ...state,
                loadingTransactions: true,
            };

        case types.SALES_GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loadingTransactions: false,
                transactions: action.payload,
                error: null,
            };

        case types.SALES_GET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                loadingTransactions: false,
                transactions: [],
                error: action.payload,
            };

        case types.SALES_GET_PAYMENTS_REQUEST:
            return {
                ...state,
                loadingPayments: true,
            };

        case types.SALES_GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                loadingPayments: false,
                payments: action.payload,
            };

        case types.SALES_GET_PAYMENTS_FAILURE:
            return {
                ...state,
                loadingPayments: false,
                payments: [],
                error: action.payload,
            };

        case types.SALES_GET_TRANSACTION_REQUEST:
            return {
                ...state,
                loadingTransaction: true,
            };

        case types.SALES_GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loadingTransaction: false,
                transaction: action.payload,
                error: null,
            };

        case types.SALES_GET_TRANSACTION_FAILURE:
            return {
                ...state,
                loadingTransaction: false,
                error: action.payload,
            };

        case types.SALES_CREATE_INVOICE_REQUEST:
            return {
                ...state,
                creatingInvoice: true,
            };
        case types.SALES_CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                creatingInvoice: false,
                invoice: action.payload,
            };
        case types.SALES_CREATE_INVOICE_FAILURE:
            return {
                ...state,
                creatingInvoice: false,
            };

        case types.SALES_GET_INVOICE_REQUEST:
            return {
                ...state,
                loadingInvoice: true,
            };
        case types.SALES_GET_INVOICE_SUCCESS:
            return {
                ...state,
                loadingInvoice: false,
                invoice: action.payload,
            };
        case types.SALES_GET_INVOICE_FAILURE:
            return {
                ...state,
                loadingInvoice: false,
                invoice: undefined,
            };

        case types.SALES_GET_INVOICES_REQUEST:
            return {
                ...state,
                loadingInvoices: true,
            };
        case types.SALES_GET_INVOICES_SUCCESS:
            return {
                ...state,
                loadingInvoices: false,
                invoices: action.payload,
            };
        case types.SALES_GET_INVOICES_FAILURE:
            return {
                ...state,
                loadingInvoices: false,
            };

        case types.SALES_UPDATE_INVOICE_REQUEST:
            return {
                ...state,
                updatingInvoice: true,
            };
        case types.SALES_UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                updatingInvoice: false,
            };
        case types.SALES_UPDATE_INVOICE_FAILURE:
            return {
                ...state,
                updatingInvoice: false,
            };

        case types.SALES_CREATE_PAYMENT_REQUEST:
            return {
                ...state,
                creatingPayment: true,
            };
        case types.SALES_CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                creatingPayment: false,
            };
        case types.SALES_CREATE_PAYMENT_FAILURE:
            return {
                ...state,
                creatingPayment: false,
            };

        case types.SALES_UPDATE_PAYMENT_REQUEST:
            return {
                ...state,
                updatingPayment: true,
            };
        case types.SALES_UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                updatingPayment: false,
            };
        case types.SALES_UPDATE_PAYMENT_FAILURE:
            return {
                ...state,
                updatingPayment: false,
            };

        case types.SALES_VOID_PAYMENT_REQUEST:
            return {
                ...state,
                voidingPayment: true,
            };
        case types.SALES_VOID_PAYMENT_SUCCESS:
            return {
                ...state,
                voidingPayment: false,
            };
        case types.SALES_VOID_PAYMENT_FAILURE:
            return {
                ...state,
                voidingPayment: false,
            };

        case types.SALES_GET_NEXT_INVOICE_NUMBER_SUCCESS:
            return {
                ...state,
                invoiceNumber: action.payload.invoiceNo
            };
        case types.SALES_GET_NEXT_INVOICE_NUMBER_FAILURE:
            return {
                ...state,
                invoiceNumber: "",
            };

        case types.SALES_GET_NEXT_PAYMENT_NUMBER_SUCCESS:
            return {
                ...state,
                paymentNumber: action.payload.paymentNo
            };
        case types.SALES_GET_NEXT_PAYMENT_NUMBER_FAILURE:
            return {
                ...state,
                paymentNumber: "",
            };

        case types.SALES_GET_NEXT_REFUND_NUMBER_SUCCESS:
            return {
                ...state,
                refundNumber: action.payload.referenceNo
            };
        case types.SALES_GET_NEXT_REFUND_NUMBER_FAILURE:
            return {
                ...state,
                refundNumber: "",
            };

        case types.SALES_UPDATE_INVOICE_NOTE_REQUEST:
            return {
                ...state,
                updatingInvoiceNote: true,
            };

        case types.SALES_UPDATE_INVOICE_NOTE_SUCCESS:
            return {
                ...state,
                updatingInvoiceNote: false,
                transaction: action.payload,
            };
        case types.SALES_UPDATE_INVOICE_NOTE_FAILURE:
            return {
                ...state,
                updatingInvoiceNote: false,
            };

        case types.SALES_VERIFY_STRIPE_CONNECT_REQUEST:
            return {
                ...state,
                checkingStripeConnect: true,
            };
        case types.SALES_VERIFY_STRIPE_CONNECT_SUCCESS:
            return {
                ...state,
                stripeConnectConfigured: action.payload,
                checkingStripeConnect: false,
            };
        case types.SALES_VERIFY_STRIPE_CONNECT_FAILURE:
            return {
                ...state,
                checkingStripeConnect: false,
            };

        case types.SALES_LINK_STRIPE_CONNECT_REQUEST:
            return {
                ...state,
                linkingStripeConnect: true,
            };
        case types.SALES_LINK_STRIPE_CONNECT_SUCCESS:
            return {
                ...state,
                linkingStripeConnect: false,
                // stripeConnectConfigured: true,
            };
        case types.SALES_LINK_STRIPE_CONNECT_FAILURE:
            return {
                ...state,
                linkingStripeConnect: false,
            };

        case types.SALES_GET_SIX_MONTHS_SALES_REQUEST:
            return {
                ...state,
                loadingIncomeMetrics: true,
            };
        case types.SALES_GET_SIX_MONTHS_SALES_SUCCESS:
            return {
                ...state,
                loadingIncomeMetrics: false,
                lastMonthSalesMetrics: action.payload.lastMonthSalesMetrics,
            };
        case types.SALES_GET_SIX_MONTHS_SALES_FAILURE:
            return {
                ...state,
                loadingIncomeMetrics: false,
            };

        case types.SALES_GET_MONTHLY_INCOME_REQUEST:
            return {
                ...state,
                loadingIncomeMetrics: true,
            };
        case types.SALES_GET_MONTHLY_INCOME_SUCCESS:
            return {
                ...state,
                loadingIncomeMetrics: false,
                lastMonthSalesMetrics: action.payload,
            };
        case types.SALES_GET_MONTHLY_INCOME_FAILURE:
            return {
                ...state,
                loadingIncomeMetrics: false,
            };

        case types.SALES_GET_WEEKLY_INCOME_REQUEST:
            return {
                ...state,
                loadingIncomeMetrics: true,
            };
        case types.SALES_GET_WEEKLY_INCOME_SUCCESS:
            return {
                ...state,
                loadingIncomeMetrics: false,
                lastMonthSalesMetrics: action.payload,
            };
        case types.SALES_GET_WEEKLY_INCOME_FAILURE:
            return {
                ...state,
                loadingIncomeMetrics: false,
            };

        case types.SALES_GET_TRANSACTIONS_METRICS_REQUEST:
            return {
                ...state,
                loadingTransactionsMetrics: true,
            };
        case types.SALES_GET_TRANSACTIONS_METRICS_SUCCESS:
            return {
                ...state,
                loadingTransactionsMetrics: false,
                transactionsMetrics: action.payload.transactionsMetrics,
                unpaidInvoiceMetrics: action.payload.unpaidInvoiceMetrics,
            };
        case types.SALES_GET_TRANSACTIONS_METRICS_FAILURE:
            return {
                ...state,
                loadingTransactionsMetrics: false,
            };

        case types.SALES_GET_INVOICES_IN_PAYMENT_REQUEST:
            return {
                ...state,
                loadingInvoicesInPayment: true,
            };
        case types.SALES_GET_INVOICES_IN_PAYMENT_SUCCESS:
            return {
                ...state,
                loadingInvoicesInPayment: false,
                invoicesInPayment: action.payload,
            };
        case types.SALES_GET_INVOICES_IN_PAYMENT_FAILURE:
            return {
                ...state,
                loadingInvoicesInPayment: false,
            };

        case types.SALES_GET_CONFIGURATIONS_REQUEST:
            return {
                ...state,
                loadingSalesConfig: true,
            };
        case types.SALES_GET_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                loadingSalesConfig: false,
                salesConfigurations: action.payload,
            };
        case types.SALES_GET_CONFIGURATIONS_FAILURE:
            return {
                ...state,
                loadingSalesConfig: false,
            };

        case types.SALES_UPDATE_CONFIGURATIONS_REQUEST:
            return {
                ...state,
                updatingConfig: true,
            };
        case types.SALES_UPDATE_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                updatingConfig: false,
                salesConfigurations: action.payload
            };
        case types.SALES_UPDATE_CONFIGURATIONS_FAILURE:
            return {
                ...state,
                updatingConfig: false,
            };

        case types.SALES_CREATE_TAX_REQUEST:
            return {
                ...state,
                creatingTax: true,
            };
        case types.SALES_CREATE_TAX_SUCCESS:
            return {
                ...state,
                creatingTax: false,
            };
        case types.SALES_CREATE_TAX_FAILURE:
            return {
                ...state,
                creatingTax: false,
            };

        case types.SALES_UPDATE_TAX_REQUEST:
            return {
                ...state,
                updatingTax: true,
            };
        case types.SALES_UPDATE_TAX_SUCCESS:
            return {
                ...state,
                updatingTax: false,
                taxes: action.payload,
            };
        case types.SALES_UPDATE_TAX_FAILURE:
            return {
                ...state,
                updatingTax: false,
            };

        case types.SALES_CREATE_REFUND_REQUEST:
            return {
                ...state,
                creatingRefund: true,
            };
        case types.SALES_CREATE_REFUND_SUCCESS:
            return {
                ...state,
                creatingRefund: false,
            };
        case types.SALES_CREATE_REFUND_FAILURE:
            return {
                ...state,
                creatingRefund: false,
            };

        case types.SALES_UPDATE_REFUND_REQUEST:
            return {
                ...state,
                updatingRefund: true,
            };
        case types.SALES_UPDATE_REFUND_SUCCESS:
            return {
                ...state,
                updatingRefund: false,
            };
        case types.SALES_UPDATE_REFUND_FAILURE:
            return {
                ...state,
                updatingRefund: false,
            };

        case types.SALES_GET_REFUND_REQUEST:
            return {
                ...state,
                loadingRefund: true,
            };
        case types.SALES_GET_REFUND_SUCCESS:
            return {
                ...state,
                loadingRefund: false,
                refund: action.payload,
            };
        case types.SALES_GET_REFUND_FAILURE:
            return {
                ...state,
                loadingRefund: false,
                refund: null,
            };

        case types.SALES_VOID_REFUND_REQUEST:
            return {
                ...state,
                voidingRefund: true,
            };
        case types.SALES_VOID_REFUND_SUCCESS:
            return {
                ...state,
                voidingRefund: false,
            };
        case types.SALES_VOID_REFUND_FAILURE:
            return {
                ...state,
                voidingRefund: false,
            };

        case types.SALES_VOID_INVOICE_REQUEST:
            return {
                ...state,
                voidingInvoice: true,
            };
        case types.SALES_VOID_INVOICE_SUCCESS:
            return {
                ...state,
                voidingInvoice: false,
            };
        case types.SALES_VOID_INVOICE_FAILURE:
            return {
                ...state,
                voidingInvoice: false,
            };

        case types.SALES_SEND_EMAIL_REQUEST:
            return {
                ...state,
                sendingEmail: true,
            };
        case types.SALES_SEND_EMAIL_SUCCESS:
            return {
                ...state,
                sendingEmail: false,
            };
        case types.SALES_SEND_EMAIL_FAILURE:
            return {
                ...state,
                sendingEmail: false,
            };

        case types.SALES_GET_ALL_TAXES_REQUEST:
            return {
                ...state,
                loadingTaxes: true,
            };
        case types.SALES_GET_ALL_TAXES_SUCCESS:
            return {
                ...state,
                loadingTaxes: false,
                taxes: action.payload,
            };
        case types.SALES_GET_ALL_TAXES_FAILURE:
            return {
                ...state,
                loadingTaxes: false,
            };

        case types.SALES_GET_TAX_REQUEST:
            return {
                ...state,
                loadingTax: true,
            };
        case types.SALES_GET_TAX_SUCCESS:
            return {
                ...state,
                loadingTax: false,
                tax: action.payload,
            };
        case types.SALES_GET_TAX_FAILURE:
            return {
                ...state,
                loadingTax: false,
            };

        case types.SALES_INVOICE_CALCULATOR_REQUEST:
            return {
                ...state,
                isCalculating: true,
            };
        case types.SALES_INVOICE_CALCULATOR_SUCCESS:
            return {
                ...state,
                isCalculating: false,
                invoiceValues: action.payload,
            };
        case types.SALES_INVOICE_CALCULATOR_FAILURE:
            return {
                ...state,
                isCalculating: false,
            };

        case types.SALES_UPDATE_BUSINESS_NUMBER_REQUEST:
            return {
                ...state,
                updatingBusinessNumber: true,
            };
        case types.SALES_UPDATE_BUSINESS_NUMBER_SUCCESS:
            return {
                ...state,
                updatingBusinessNumber: false,
            };
        case types.SALES_UPDATE_BUSINESS_NUMBER_FAILURE:
            return {
                ...state,
                updatingBusinessNumber: false,
            };

        case types.SALES_SEARCH_INVOICES_REQUEST:
            return {
                ...state,
                searchingInvoices: true,
            };
        case types.SALES_SEARCH_INVOICES_SUCCESS:
            return {
                ...state,
                searchingInvoices: false,
                invoicesSearchResults: action.payload,
            };
        case types.SALES_SEARCH_INVOICES_FAILURE:
            return {
                ...state,
                searchingInvoices: false,
            };

        case types.SALES_REGISTER_TERMINAL_REQUEST:
            return {
                ...state,
                addingTerminal: true,
            };
        case types.SALES_REGISTER_TERMINAL_SUCCESS:
            return {
                ...state,
                addingTerminal: false,
            };
        case types.SALES_REGISTER_TERMINAL_FAILURE:
            return {
                ...state,
                addingTerminal: false,
            };

        case types.SALES_LIST_STRIPE_LOCATIONS_REQUEST:
            return {
                ...state,
                loadingStripeLocations: true,
            };
        case types.SALES_LIST_STRIPE_LOCATIONS_SUCCESS:
            return {
                ...state,
                loadingStripeLocations: false,
                stripeLocations: action.payload,
            };
        case types.SALES_LIST_STRIPE_LOCATIONS_FAILURE:
            return {
                ...state,
                loadingStripeLocations: false,
            };

        case types.SALES_CREATE_STRIPE_LOCATIONS_REQUEST:
            return {
                ...state,
                creatingStripeLocation: true,
            };
        case types.SALES_CREATE_STRIPE_LOCATIONS_SUCCESS:
            return {
                ...state,
                creatingStripeLocation: false,
            };
        case types.SALES_CREATE_STRIPE_LOCATIONS_FAILURE:
            return {
                ...state,
                creatingStripeLocation: false,
            };

        case types.SALES_GET_LOCATION_TERMINALS_REQUEST:
            return {
                ...state,
                loadingTerminals: true,
            };
        case types.SALES_GET_LOCATION_TERMINALS_SUCCESS:
            return {
                ...state,
                loadingTerminals: false,
                terminalsInLocation: action.payload
            };
        case types.SALES_GET_LOCATION_TERMINALS_FAILURE:
            return {
                ...state,
                loadingTerminals: false,
            };

        case types.SALES_EDIT_TERMINAL_LABEL_REQUEST:
            return {
                ...state,
                editingTerminalLabel: true,
            };
        case types.SALES_EDIT_TERMINAL_LABEL_SUCCESS:
            return {
                ...state,
                editingTerminalLabel: false,
            };
        case types.SALES_EDIT_TERMINAL_LABEL_FAILURE:
            return {
                ...state,
                editingTerminalLabel: false,
            };

        case types.SALES_REMOVE_TERMINAL_REQUEST:
            return {
                ...state,
                deletingTerminal: true,
            };
        case types.SALES_REMOVE_TERMINAL_SUCCESS:
            return {
                ...state,
                deletingTerminal: false,
            };
        case types.SALES_REMOVE_TERMINAL_FAILURE:
            return {
                ...state,
                deletingTerminal: false,
            };

        case types.SALES_VERIFY_TERMINAL_CONFIGURATION_REQUEST:
            return {
                ...state,
                verifyingTerminalConfig: true,
            };
        case types.SALES_VERIFY_TERMINAL_CONFIGURATION_SUCCESS:
            return {
                ...state,
                terminalConfigured: action.payload,
                verifyingTerminalConfig: false,
            };
        case types.SALES_VERIFY_TERMINAL_CONFIGURATION_FAILURE:
            return {
                ...state,
                terminalConfigured: false,
                verifyingTerminalConfig: false,
            };

        case types.SALES_CREATE_PAYMENT_OBJECT_SUCCESS:
            return {
                ...state,
            };
        case types.SALES_CREATE_PAYMENT_OBJECT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.SALES_REMOVE_STRIPE_LOCATIONS_REQUEST:
            return {
                ...state,
                deletingStripeLocation: true,
            };
        case types.SALES_REMOVE_STRIPE_LOCATIONS_SUCCESS:
            return {
                ...state,
                deletingStripeLocation: false,
            };
        case types.SALES_REMOVE_STRIPE_LOCATIONS_FAILURE:
            return {
                ...state,
                deletingStripeLocation: false,
                error: action.payload
            };

        case types.SALES_CREATE_ONLINE_REFUND_REQUEST:
            return {
                ...state,
                creatingRefund: true,
            };
        case types.SALES_CREATE_ONLINE_REFUND_SUCCESS:
            return {
                ...state,
                creatingRefund: false,
            };
        case types.SALES_CREATE_ONLINE_REFUND_FAILURE:
            return {
                ...state,
                creatingRefund: false,
                error: action.payload
            };

        case types.SALES_CREATE_TERMINAL_REFUND_REQUEST:
            return {
                ...state,
                creatingRefund: true,
            };
        case types.SALES_CREATE_TERMINAL_REFUND_SUCCESS:
            return {
                ...state,
                creatingRefund: false,
            };
        case types.SALES_CREATE_TERMINAL_REFUND_FAILURE:
            return {
                ...state,
                creatingRefund: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default SalesReducer;

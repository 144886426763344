export const types = {
    SERVICE_CHANGE_STATE_REQUEST: "SERVICE_CHANGE_STATE_REQUEST",

    SERVICE_TOGGLE_STYLE_REQUEST: "SERVICE_TOGGLE_STYLE_REQUEST",
    SERVICE_ADD_VARIANT_REQUEST: "SERVICE_ADD_VARIANT_REQUEST",

    SERVICE_GET_CATEGORY_SUGGESTIONS_REQUEST: "SERVICE_GET_CATEGORY_SUGGESTIONS_REQUEST",
    SERVICE_GET_CATEGORY_SUGGESTIONS_SUCCESS: "SERVICE_GET_CATEGORY_SUGGESTIONS_SUCCESS",
    SERVICE_GET_CATEGORY_SUGGESTIONS_FAILURE: "SERVICE_GET_CATEGORY_SUGGESTIONS_FAILURE",

    SERVICE_ASSIGN_EMPLOYEE_REQUEST: "SERVICE_ASSIGN_EMPLOYEE_REQUEST",
    SERVICE_ASSIGN_EMPLOYEE_SUCCESS: "SERVICE_ASSIGN_EMPLOYEE_SUCCESS",
    SERVICE_ASSIGN_EMPLOYEE_FAILURE: "SERVICE_ASSIGN_EMPLOYEE_FAILURE",

    SERVICE_UPDATE_VARIANT_REQUEST: "SERVICE_UPDATE_VARIANT_REQUEST",
    SERVICE_UPDATE_VARIANT_SUCCESS: "SERVICE_UPDATE_VARIANT_SUCCESS",
    SERVICE_CLEAR_VARIANTS_REQUEST: "SERVICE_CLEAR_VARIANTS_REQUEST",

    SERVICE_GET_CATEGORIES_REQUEST: "SERVICE_GET_CATEGORIES_REQUEST",
    SERVICE_GET_CATEGORIES_SUCCESS: "SERVICE_GET_CATEGORIES_SUCCESS",
    SERVICE_GET_CATEGORIES_FAILURE: "SERVICE_GET_CATEGORIES_FAILURE",

    SERVICE_SEARCH_CATEGORY_REQUEST: "SERVICE_SEARCH_CATEGORY_REQUEST",
    SERVICE_SEARCH_CATEGORY_SUCCESS: "SERVICE_SEARCH_CATEGORY_SUCCESS",
    SERVICE_SEARCH_CATEGORY_FAILURE: "SERVICE_SEARCH_CATEGORY_FAILURE",
    SERVICE_SEARCH_CATEGORY_CLOSE: "SERVICE_SEARCH_CATEGORY_CLOSE",

    SERVICE_GET_CATEGORY_REQUEST: "SERVICE_GET_CATEGORY_REQUEST",
    SERVICE_GET_CATEGORY_SUCCESS: "SERVICE_GET_CATEGORY_SUCCESS",
    SERVICE_GET_CATEGORY_FAILURE: "SERVICE_GET_CATEGORY_FAILURE",

    SERVICE_CREATE_CATEGORY_REQUEST: "SERVICE_CREATE_CATEGORY_REQUEST",
    SERVICE_CREATE_CATEGORY_SUCCESS: "SERVICE_CREATE_CATEGORY_SUCCESS",
    SERVICE_CREATE_CATEGORY_FAILURE: "SERVICE_CREATE_CATEGORY_FAILURE",

    SERVICE_UPDATE_CATEGORY_REQUEST: "SERVICE_UPDATE_CATEGORY_REQUEST",
    SERVICE_UPDATE_CATEGORY_SUCCESS: "SERVICE_UPDATE_CATEGORY_SUCCESS",
    SERVICE_UPDATE_CATEGORY_FAILURE: "SERVICE_UPDATE_CATEGORY_FAILURE",

    SERVICE_DELETE_CATEGORY_REQUEST: "SERVICE_DELETE_CATEGORY_REQUEST",
    SERVICE_DELETE_CATEGORY_SUCCESS: "SERVICE_DELETE_CATEGORY_SUCCESS",
    SERVICE_DELETE_CATEGORY_FAILURE: "SERVICE_DELETE_CATEGORY_FAILURE",

    SERVICE_GET_ALL_SERVICES_REQUEST: "SERVICE_GET_ALL_SERVICES_REQUEST",
    SERVICE_GET_ALL_SERVICES_SUCCESS: "SERVICE_GET_ALL_SERVICES_SUCCESS",
    SERVICE_GET_ALL_SERVICES_FAILURE: "SERVICE_GET_ALL_SERVICES_FAILURE",

    SERVICE_GET_SERVICES_REQUEST: "SERVICE_GET_SERVICES_REQUEST",
    SERVICE_GET_SERVICES_SUCCESS: "SERVICE_GET_SERVICES_SUCCESS",
    SERVICE_GET_SERVICES_FAILURE: "SERVICE_GET_SERVICES_FAILURE",
    SERVICE_CLOSE_SERVICES_REQUEST: "SERVICE_CLOSE_SERVICES_REQUEST",

    SERVICE_SEARCH_SERVICE_REQUEST: "SERVICE_SEARCH_SERVICE_REQUEST",
    SERVICE_SEARCH_SERVICE_SUCCESS: "SERVICE_SEARCH_SERVICE_SUCCESS",
    SERVICE_SEARCH_SERVICE_FAILURE: "SERVICE_SEARCH_SERVICE_FAILURE",
    SERVICE_SEARCH_SERVICE_CLOSE: "SERVICE_SEARCH_SERVICE_CLOSE",

    SERVICE_GET_SERVICE_REQUEST: "SERVICE_GET_SERVICE_REQUEST",
    SERVICE_GET_SERVICE_SUCCESS: "SERVICE_GET_SERVICE_SUCCESS",
    SERVICE_GET_SERVICE_FAILURE: "SERVICE_GET_SERVICE_FAILURE",

    SERVICE_CREATE_SERVICE_REQUEST: "SERVICE_CREATE_SERVICE_REQUEST",
    SERVICE_CREATE_SERVICE_SUCCESS: "SERVICE_CREATE_SERVICE_SUCCESS",
    SERVICE_CREATE_SERVICE_FAILURE: "SERVICE_CREATE_SERVICE_FAILURE",

    SERVICE_UPDATE_SERVICE_REQUEST: "SERVICE_UPDATE_SERVICE_REQUEST",
    SERVICE_UPDATE_SERVICE_SUCCESS: "SERVICE_UPDATE_SERVICE_SUCCESS",
    SERVICE_UPDATE_SERVICE_FAILURE: "SERVICE_UPDATE_SERVICE_FAILURE",

    SERVICE_DELETE_SERVICE_REQUEST: "SERVICE_DELETE_SERVICE_REQUEST",
    SERVICE_DELETE_SERVICE_SUCCESS: "SERVICE_DELETE_SERVICE_SUCCESS",
    SERVICE_DELETE_SERVICE_FAILURE: "SERVICE_DELETE_SERVICE_FAILURE",

    SERVICE_GET_EARNINGS_METRICS_REQUEST: "SERVICE_GET_EARNINGS_METRICS_REQUEST",
    SERVICE_GET_EARNINGS_METRICS_SUCCESS: "SERVICE_GET_EARNINGS_METRICS_SUCCESS",
    SERVICE_GET_EARNINGS_METRICS_FAILURE: "SERVICE_GET_EARNINGS_METRICS_FAILURE",

    SERVICE_GET_UNSPLASH_IMAGES_REQUEST: "SERVICE_GET_UNSPLASH_IMAGES_REQUEST",
    SERVICE_GET_UNSPLASH_IMAGES_SUCCESS: "SERVICE_GET_UNSPLASH_IMAGES_SUCCESS",
    SERVICE_GET_UNSPLASH_IMAGES_FAILURE: "SERVICE_GET_UNSPLASH_IMAGES_FAILURE",

    SERVICE__REQUEST: "SERVICE__REQUEST",
    SERVICE__SUCCESS: "SERVICE__SUCCESS",
    SERVICE__FAILURE: "SERVICE__FAILURE",
};

import { soltivoHelper } from "@soltivo/draw-a-line";
import { EmailDomainInfo } from "../emails";
import { EmailActions } from "../redux/reducer/actions.types";
import { Validation } from "@soltivo/draw-a-line/core/helpers/classes/Validation";

/**
 * @description validation for email.
 */
class EmailValidation {
    /**
     * @description form types.
     */
    public formTypes = {
        EMAIL_CREATE_MAILBOX: "EMAIL_CREATE_MAILBOX",
        EMAIL_SEND_MESSAGE: "EMAIL_SEND_MESSAGE",
        EMAIL_MAILBOX_MESSAGE: "EMAIL_MAILBOX_MESSAGE",
    } as const;

    public validation = new Validation<keyof EmailValidation["formTypes"]>([]);

    /**
     * @description setup email business
     */
    public emailSetupEmailBusiness(
        payload: EmailActions["emailSetupEmailBusiness"]["payload"] & {
            domainInfo?: EmailDomainInfo;
        }
    ) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];
        if (!payload.businessEmail) {
            errors.push({
                message: "Business email is required",
                fieldName: "businessEmail",
                formName: this.formTypes.EMAIL_CREATE_MAILBOX,
                status: "unprocessable",
            });
        } else if (!/(@)/g.test(payload.businessEmail)) {
            errors.push({
                message: "Business email cannot have invalid format, missing @ character.",
                fieldName: "businessEmail",
                formName: this.formTypes.EMAIL_CREATE_MAILBOX,
                status: "unprocessable",
            });
        } else {
            const splitAt = payload.businessEmail.split("@");
            const typed = splitAt[0];
            const domainName = splitAt[1];

            if (payload.domainInfo?.domainName !== domainName) {
                errors.push({
                    message: `Email Domain ${domainName || ""} is invalid for this organization.`,
                    fieldName: "businessEmail",
                    formName: this.formTypes.EMAIL_CREATE_MAILBOX,
                    status: "unprocessable",
                });
            } else if (/(?![_])([\W])/g.test(typed)) {
                errors.push({
                    message: `Domain cannot have ${typed.match(/(?![_])([\W])/g)?.join(", ")} characters.`,
                    fieldName: "businessEmail",
                    formName: this.formTypes.EMAIL_CREATE_MAILBOX,
                    status: "unprocessable",
                });
            }
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    public emailSendEmail(payload: EmailActions["emailSendEmail"]["payload"]) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.recipients.length) {
            errors.push({
                message: `You need at least one recipient to send an email.`,
                fieldName: "recipients",
                formName: this.formTypes.EMAIL_SEND_MESSAGE,
                status: "unprocessable",
            });
        } else if (payload.recipients.length) {
            for (let i = 0; i < payload.recipients.length; i++) {
                let item = payload.recipients[i];

                if (soltivoHelper.validateEmail(item)) {
                    errors.push({
                        message: `Recipient ${item} is not a valid email.`,
                        fieldName: "recipients",
                        formName: this.formTypes.EMAIL_SEND_MESSAGE,
                        status: "unprocessable",
                    });
                }
            }
        }

        if (!payload.subject) {
            errors.push({
                message: `Subject is required.`,
                fieldName: "subject",
                formName: this.formTypes.EMAIL_SEND_MESSAGE,
                status: "unprocessable",
            });
        }

        if (payload.cc?.length) {
            for (let i = 0; i < payload.cc.length; i++) {
                let item = payload.cc[i];

                if (soltivoHelper.validateEmail(item)) {
                    errors.push({
                        message: `CC ${item} is not a valid email.`,
                        fieldName: "cc",
                        formName: this.formTypes.EMAIL_SEND_MESSAGE,
                        status: "unprocessable",
                    });
                }
            }
        }

        if (payload.bcc?.length) {
            for (let i = 0; i < payload.bcc.length; i++) {
                let item = payload.bcc[i];

                if (soltivoHelper.validateEmail(item)) {
                    errors.push({
                        message: `BCC ${item} is not a valid email.`,
                        fieldName: "bcc",
                        formName: this.formTypes.EMAIL_SEND_MESSAGE,
                        status: "unprocessable",
                    });
                }
            }
        }

        if (!payload.body?.length) {
            errors.push({
                message: `You cannot send an email with empty message.`,
                fieldName: "body",
                formName: this.formTypes.EMAIL_SEND_MESSAGE,
                status: "unprocessable",
            });
        }

        if (!payload.content?.length) {
            errors.push({
                message: `You cannot send an email with empty message.`,
                fieldName: "content",
                formName: this.formTypes.EMAIL_SEND_MESSAGE,
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    public emailReplyEmail(payload: EmailActions["emailReplyEmail"]["payload"]) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.recipients.length) {
            errors.push({
                message: `You cannot reply an email without recipients.`,
                fieldName: "reply",
                formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                status: "unprocessable",
            });
        } else if (payload.recipients.length) {
            for (let i = 0; i < payload.recipients.length; i++) {
                let item = payload.recipients[i];

                if (soltivoHelper.validateEmail(item)) {
                    errors.push({
                        message: `Recipient ${item} is not a valid email.`,
                        fieldName: "reply",
                        formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                        status: "unprocessable",
                    });
                }
            }
        } else if (!payload.subject) {
            errors.push({
                message: `Email cannot be replied without subject.`,
                fieldName: "reply",
                formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                status: "unprocessable",
            });
        } else if (payload.cc?.length) {
            for (let i = 0; i < payload.cc.length; i++) {
                let item = payload.cc[i];

                if (soltivoHelper.validateEmail(item)) {
                    errors.push({
                        message: `CC ${item} is not a valid email.`,
                        fieldName: "reply",
                        formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                        status: "unprocessable",
                    });
                }
            }
        } else if (payload.bcc?.length) {
            for (let i = 0; i < payload.bcc.length; i++) {
                let item = payload.bcc[i];

                if (soltivoHelper.validateEmail(item)) {
                    errors.push({
                        message: `BCC ${item} is not a valid email.`,
                        fieldName: "reply",
                        formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                        status: "unprocessable",
                    });
                }
            }
        } else if (!payload.body?.length) {
            errors.push({
                message: `You cannot send an email with empty message.`,
                fieldName: "reply",
                formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                status: "unprocessable",
            });
        } else if (!payload.content?.length) {
            errors.push({
                message: `You cannot send an email with empty message.`,
                fieldName: "reply",
                formName: this.formTypes.EMAIL_MAILBOX_MESSAGE,
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }
}

export default new EmailValidation();

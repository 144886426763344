import { HeaderState } from "./reducer";
import types from "./types";

/**
 * @description change state of header.
 * @warning be careful using this function you may break the head if you not pass the right attributes for it.
 */
export const headerChangeState = (payload: {
    state: {
        appName?: HeaderState["appName"];
    };
}) => ({
    type: types.HEADER_CHANGE_STATE_REQUEST,
    payload,
});

/**
 * @description display sidebar
 */
export const headerSidebarToggleDisplay = (payload: { show: boolean }) => ({
    type: types.HEADER_SIDEBAR_TOGGLE_DISPLAY_REQUEST,
    payload,
});

/**
 * @description display loading app in the header while loading component.
 */
export const headerStartLoadingApp = (payload: { loading: boolean }) => ({
    type: types.HEADER_START_LOADING_APP_REQUEST,
    payload,
});

/**
 * @description add/remove actions to the main header, pass undefined to
 * remove actions.
 */
export const headerActions = (payload: Partial<HeaderState["headerActions"]>) => ({
    type: types.HEADER_ACTIONS_REQUEST,
    payload,
});

/**
 * @description add/remove menu to the main header, pass undefined to
 * remove menu.
 */
export const headerMenu = (payload: Pick<HeaderState, "menu">) => ({
    type: types.HEADER_MENU_REQUEST,
    payload,
});

/**
 * @description Change menu type, INITIAL | SECONDARY
 */
export const changeHeaderType = (payload: Pick<HeaderState, "header">) => ({
    type: types.HEADER_TYPE_REQUEST,
    payload,
});

/**
 * @description Change notifications bar
 */
export const headerChangeNotificationsBar = (payload: Pick<HeaderState, "notifs">) => ({
    type: types.HEADER_CHANGE_NOTIFICATIONS_BAR_REQUEST,
    payload,
});

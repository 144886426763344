import { call, CallEffect, put, PutEffect, takeLatest, select, SelectEffect, AllEffect } from "@redux-saga/core/effects";
import { types } from "../reducer/types";
import { NotificationsActions } from "../reducer/actions.types";
import notificationsClass from "./notifications.class";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description get all notifications
 */
function* notificationsGetAllNotifications(): Generator<CallEffect | PutEffect, void, any> {
    try {
        const { data } = yield call(notificationsClass.notificationsGetAllNotifications,);
        yield put({
            type: types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_SUCCESS,
            payload: data.items,
        });
    } catch (error) {
        yield put({
            type: types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description update notifications
 */
function* notificationsUpdateNotification({ payload }: NotificationsActions["notificationsUpdateNotification"]): Generator<CallEffect | SelectEffect | PutEffect, void, any> {
    try {
        yield call(notificationsClass.notificationsUpdateNotification, payload);

        const mapStateToProps = ({ NotificationReducer }: RootState) => ({
            notifications: NotificationReducer.notifications,
        });
    
        type MapStateToProps = ReturnType<typeof mapStateToProps>;
    
        const { notifications }: MapStateToProps = yield select(mapStateToProps);
        notifications.list = notifications.list.map(item => {
            if(item.id === payload.id) {
                item.isRead = payload.isRead
            }
            return item;
        });

        yield put({
            type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_SUCCESS,
            payload: notifications,
        });
    } catch (error) {
        yield put({
            type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get notifications settings
 */
 function* notificationsGetSettings(): Generator<CallEffect | PutEffect, void, any> {
    try {
        const { data } = yield call(notificationsClass.notificationsGetSettings);
        yield put({
            type: types.NOTIFICATIONS_GET_SETTINGS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.NOTIFICATIONS_GET_SETTINGS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description update notifications settings
 */
 function* notificationsUpdateSettings({ payload }: NotificationsActions["notificationsUpdateSettings"]): Generator<CallEffect | PutEffect, void, any> {
    try {
        const { data } = yield call(notificationsClass.notificationsUpdateSettings, payload);
        yield put({
            type: types.NOTIFICATIONS_UPDATE_SETTINGS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({
            type: types.NOTIFICATIONS_UPDATE_SETTINGS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description delete notifications
 */
 function* notificationsDeleteNotifications({ payload }: NotificationsActions["notificationsDeleteNotifications"]): Generator<CallEffect | SelectEffect | PutEffect, void, any> {
    try {
        yield call(notificationsClass.notificationsDeleteNotifications, payload);

        const mapStateToProps = ({ NotificationReducer }: RootState) => ({
            notifications: NotificationReducer.notifications,
        });
    
        type MapStateToProps = ReturnType<typeof mapStateToProps>;
    
        const { notifications }: MapStateToProps = yield select(mapStateToProps);
        notifications.list = notifications.list.filter(item => {
            return item.id !== payload.id
        });
        yield put({
            type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_SUCCESS,
            payload: notifications,
        });
    } catch (error) {
        yield put({
            type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description delete notifications
 */
 function* notificationsClearNotifications({ payload }: NotificationsActions["notificationsClearNotifications"]): Generator<CallEffect | AllEffect<any> | SelectEffect | PutEffect, void, any> {
    try {
        yield call(notificationsClass.notificationsClearNotifications, payload);

        const mapStateToProps = ({ NotificationReducer }: RootState) => ({
            notifications: NotificationReducer.notifications,
        });
    
        type MapStateToProps = ReturnType<typeof mapStateToProps>;
    
        const { notifications }: MapStateToProps = yield select(mapStateToProps);
        notifications.list = notifications.list.filter(item => {
            return !payload.ids.includes(item.id)
        });
        yield put({
            type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_SUCCESS,
            payload: notifications,
        });
    } catch (error) {
        yield put({
            type: types.NOTIFICATIONS_UPDATE_NOTIFICATION_FAILURE,
            payload: error,
        });
    }
}

export default function* DomainSaga() {
    yield takeLatest(types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_REQUEST, notificationsGetAllNotifications);
    yield takeLatest(types.NOTIFICATIONS_UPDATE_NOTIFICATION_REQUEST, notificationsUpdateNotification);
    yield takeLatest(types.NOTIFICATIONS_GET_SETTINGS_REQUEST, notificationsGetSettings);
    yield takeLatest(types.NOTIFICATIONS_UPDATE_SETTINGS_REQUEST, notificationsUpdateSettings);
    yield takeLatest(types.NOTIFICATIONS_DELETE_NOTIFICATION_REQUEST, notificationsDeleteNotifications);
    yield takeLatest(types.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_REQUEST, notificationsClearNotifications);
}

import React, { FC, useEffect, useState } from "react";

import useDropContainer, {
  UseDropContainerProps,
} from "@soltivo/draw-a-line/core/hooks/useDropContainer";
import { Checkbox } from "@soltivo/draw-a-line"
import useListHelpers from "@soltivo/draw-a-line/core/hooks/useListHelpers";
import { ArrowDropDown24Px } from "@soltivo/draw-a-line/core/components/icons";
import "./custom.selection.scss";

export interface InputSelectProps extends UseDropContainerProps {
  multipleChoice?: boolean;
  onChange: (
    option: InputSelectProps["options"][0] & { name?: string }
  ) => void;
  value: string | number | JSX.Element | string[];
  placeholder?: string;
  options: {
    value: string | number | JSX.Element;
    [index: string]: any | undefined;
  }[];
  border?: boolean;
  className?: string;
  name?: string;
  disabled?: boolean;
  style?: React.HTMLAttributes<HTMLDivElement>["style"];
}

const CustomInputSelect: FC<InputSelectProps> = ({
  onChange,
  placeholder,
  value,
  options,
  className,
  name,
  disabled,
  border = true,
  centeredX,
  containerGap,
  withParentWidth = true,
  multipleChoice = false,
  ...rest
}) => {
  const { containerRef, droppedRef, onDropdown, dropState } = useDropContainer({
    centeredX,
    containerGap,
    withParentWidth,
  });

  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const {
    listState,
    onKeydown,
    onKeydownScrollable,
    setListState,
  } = useListHelpers();

  const onDrop: React.MouseEventHandler<HTMLSpanElement> = (_e) => {
    onDropdown(!dropState.drop);
    if (containerRef.current instanceof HTMLDivElement) {
      containerRef.current.focus();
      setListState((state) => ({
        ...state,
        selected: undefined,
      }));
    }
  };

  const onSelect: InputSelectProps["onChange"] = (option) => {
    if(multipleChoice) {
      let choices = [];
      if(selectedItems.map(item => item.value).includes(option.value)) {
        choices = [...selectedItems.filter(item => item.value !== option.value)]
      } else {
        choices =  [...selectedItems, option]
      }
      setSelectedItems(choices);
      onChange({ ...option, values: choices, name: name });
    } else {
      onChange({ ...option, name: name });
      onDropdown(false);
    }
  };

  useEffect(() => {
    onKeydownScrollable(droppedRef.current?.querySelector("ul"), {
      move: dropState.drop ? "continue" : "initial",
    });
  }, [listState.selected, dropState.drop]);

  return (
    <div
      {...rest}
      className={`custom__select ${className ? className : ""} ${
        disabled ? "disabled" : ""
      } ${border ? "" : "no-border"}`}
      ref={containerRef}
      tabIndex={1}
      onBlur={_e => {
        onDropdown(false);
      }}
      onKeyDown={(e) => {
        if(e.key === "Enter" && !dropState.drop) {
          onDropdown(!dropState.drop);
          return;
        }
        onKeydown(e, {
          list: options.map((item) => item.value) as string[],
          onEnter: (_state) => {
            if (listState.selected !== undefined) {
              onSelect(options[listState.selected]);
            } else {
              onDropdown(false);
            }
          },
        });
      }}
    >
      <span
        role="button"
        onClick={disabled === true ? undefined : onDrop}
        data-placeholder={value ? "" : placeholder || "Select an option"}
      >
        {multipleChoice ? placeholder : value} <ArrowDropDown24Px className="arrow" />
      </span>
      <div className="custom__select-wrapper" ref={droppedRef}>
        <ul>
          {options?.map((option, index) => {
            return (
              <li
                className={`${index === listState.selected ? "selected" : ""}`}
                key={index}
                onClick={(_e) => onSelect({ ...option, name: name })}
              >
                {multipleChoice &&
                <Checkbox 
                  tabIndex={1}
                  onChange={() => onSelect({ ...option, name: name })}
                  checked={selectedItems.map(item => item.value).includes(option.value)} />
                }
                {option.value}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CustomInputSelect;

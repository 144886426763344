import { EmailDomainInfo } from "../../../../email/emails";
import { types } from "./types";
import { SetupIntent } from "@stripe/stripe-js";
import { BillingsInvoice } from "../../../billings";

export interface DomainState {
    /**
     * @description information of the current domain. 🔥
     */
    domainInfo: EmailDomainInfo | undefined;
    loadingDomainInfo: boolean;
    updatingDomainInfo: boolean;
    error: any;

    /**
     * @description this is used for create domain.
     */
    domain: {
        /**
         * @description status of the typed domain.
         */
        status: "checking" | "available" | "unavailable" | undefined;
        /**
         * @description full domain string.
         */
        domainName: string;
        /**
         * @description errors
         */
        error?: string;
    };
    /**
     * @description new domain subscription invoice
     */
    invoice: {
        in: BillingsInvoice | undefined;
        loading: boolean;
        /**
         * @description if something like validation fails and user tries again.
         */
        confirmed: boolean;
    };
    /**
     * @description new setup intent for the domain.
     */
    setupIntent: {
        si: SetupIntent | undefined;
        loading: boolean;
        /**
         * @description check if si ak setup intent is confirmed.
         */
        confirmed: boolean;
    };
}

export const _INITIAL_STATE: DomainState = {
    domainInfo: undefined,
    loadingDomainInfo: true,
    updatingDomainInfo: false,
    error: null,
    domain: {
        domainName: "",
        status: undefined,
        error: undefined,
    },
    invoice: {
        in: undefined,
        confirmed: false,
        loading: false,
    },
    setupIntent: {
        si: undefined,
        confirmed: false,
        loading: false,
    },
};

const DomainReducer = (
    state = _INITIAL_STATE,
    action: {
        type: string;
        payload: any;
    }
): DomainState => {
    switch (action.type) {
        case types.DOMAIN_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.DOMAIN_GET_DOMAIN_INFO_REQUEST:
            return {
                ...state,
                loadingDomainInfo: true,
            };

        case types.DOMAIN_GET_DOMAIN_INFO_SUCCESS:
            return {
                ...state,
                domainInfo: action.payload,
                loadingDomainInfo: false,
            };

        case types.DOMAIN_GET_DOMAIN_INFO_FAILURE:
            return {
                ...state,
                loadingDomainInfo: false,
            };

        case types.DOMAIN_CHECK_AVAILABILITY_REQUEST:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    status: "checking",
                    error: undefined,
                },
            };
        case types.DOMAIN_CHECK_AVAILABILITY_SUCCESS:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    ...action.payload,
                },
            };
        case types.DOMAIN_CHECK_AVAILABILITY_FAILURE:
            return {
                ...state,
                domain: {
                    ...state.domain,
                    ...action.payload,
                },
            };

        case types.DOMAIN_SUBSCRIBE_CONFIRM_REQUEST:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: true,
                },
            };

        case types.DOMAIN_SUBSCRIBE_CONFIRM_SUCCESS:
            return {
                ...state,

                invoice: {
                    ...state.invoice,
                    loading: false,
                    in: action.payload,
                },
            };

        case types.DOMAIN_SUBSCRIBE_CONFIRM_FAILURE:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: false,
                },
            };

        case types.DOMAIN_CREATE_SETUP_INTENT_REQUEST:
            return {
                ...state,
                setupIntent: {
                    ...state.setupIntent,
                    loading: true,
                },
            };

        case types.DOMAIN_CREATE_SETUP_INTENT_SUCCESS:
            return {
                ...state,

                setupIntent: {
                    ...state.setupIntent,
                    loading: false,
                    confirmed: false,
                    si: action.payload,
                },
            };

        case types.DOMAIN_CREATE_SETUP_INTENT_FAILURE:
            return {
                ...state,
                setupIntent: {
                    ...state.setupIntent,
                    loading: false,
                },
            };

        case types.DOMAIN_SUBSCRIBE_DOMAIN_REQUEST:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: true,
                },
            };

        case types.DOMAIN_SUBSCRIBE_DOMAIN_SUCCESS:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: false,
                    in: action.payload.invoice,
                    confirmed: false,
                },
            };

        case types.DOMAIN_SUBSCRIBE_DOMAIN_FAILURE:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    loading: false,
                },
            };

        case types.DOMAIN_RENEW_FLAG_SUCCESS:
            return {
                ...state,
                domainInfo: state.domainInfo
                    ? {
                          ...state.domainInfo,
                          renewable: action.payload,
                      }
                    : undefined,
            };

        case types.DOMAIN_RENEW_FLAG_FAILURE:
            return {
                ...state,
            };

        case types.DOMAIN_UPDATE_INFOS_REQUEST:
            return {
                ...state,
                updatingDomainInfo: true,
            };

        case types.DOMAIN_UPDATE_INFOS_SUCCESS:
            return {
                ...state,
                domainInfo: state.domainInfo ? {
                    ...state.domainInfo,
                    registrationDetails: action.payload
                } : undefined,
                updatingDomainInfo: false,
                error: null,
            };

        case types.DOMAIN_UPDATE_INFOS_FAILURE:
            return {
                ...state,
                updatingDomainInfo: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default DomainReducer;

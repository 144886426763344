import { AutoSaveAPI } from "@soltivo/draw-a-line";
import { GuideOrgContextState } from "../../../../../app/blocks/guide.organization/context/reducer";
import { SingleEmployeeSchedules } from "../../../../hrm/hrm";
import { Organization, Location } from "../../../organization";
import { OrganizationState } from "./reducer";
import { types } from "./types";

/**
 * @description on change organization state
 * @warning be careful using this action you could break the application.
 */
export const orgChangeState = (payload: Partial<OrganizationState>) => ({
    type: types.ORG_CHANGE_STATE_REQUEST,
    payload,
});

/**
 * @description get single organization
 */
export const orgUpdateConfiguration = (payload: { configuration: Partial<Organization["configuration"]> }) => ({
    type: types.ORG_UPDATE_CONFIGURATION_REQUEST,
    payload: payload,
});

/**
 * @description get single organization
 */
export const orgGetOrganization = (payload: { orgId: Organization["orgId"] }) => ({
    type: types.ORG_GET_ORGANIZATION_REQUEST,
    payload: payload,
});

/**
 * @description get list of organizations. (not working maybe)
 */
export const orgGetOrganizations = () => ({
    type: types.ORG_GET_ALL_ORGANIZATIONS_REQUEST,
});

/**
 * @description create primary organization
 */
export const orgCreatePrimaryOrganization = (payload: {
    organization: {
        name: string;
        goal: string;
    };
    user: {
        firstName: string;
        lastName: string;
    };
}) => ({
    type: types.ORG_CREATE_PRIMARY_ORGANIZATION_REQUEST,
    payload,
});

/**
 * @description create secondary organization
 */
export const orgCreateSecondaryOrganization = (payload: Partial<{ name: string; goal: string }>) => ({
    type: types.ORG_CREATE_SECONDARY_ORGANIZATION_REQUEST,
    payload,
});

/**
 * @description update organization
 */
export const orgAutoUpdateOrganization = (
    payload: Partial<Omit<Organization, "logo" | "primaryLocation" | "currency" | "name" | "timeFormat" | "timeZone">> & {
        logo?: File | string;
        /**
         * @deprecated use headquarter instead.
         */
        primaryLocation?: Omit<Organization["primaryLocation"], "id">;
        currency?: Organization["currency"];
        name?: Organization["name"];
        timeFormat?: Organization["timeFormat"];
        timeZone?: Organization["timeZone"];
    },
    autoSave: {
        fieldName: string;
        autoSaveAPI: AutoSaveAPI;
    }
) => ({
    type: types.ORG_AUTO_UPDATE_ORGANIZATION_REQUEST,
    payload,
    autoSave,
});

/**
 * @description update organization
 */
export const orgUpdateOrganization = (
    payload: Partial<Omit<Organization, "logo" | "primaryLocation" | "currency" | "name" | "timeFormat" | "timeZone">> & {
        logo?: File | string;
        /**
         * @deprecated use headquarter instead.
         */
        primaryLocation?: Omit<Organization["primaryLocation"], "id">;
        currency?: Organization["currency"];
        name?: Organization["name"];
        timeFormat?: Organization["timeFormat"];
        timeZone?: Organization["timeZone"];
    }
) => ({
    type: types.ORG_UPDATE_ORGANIZATION_REQUEST,
    payload,
});

/**
 * @description Manage locations array in organization object
 */
export const orgManageLocations = (payload: { locations: Omit<Location, "id">[] }) => ({
    type: types.ORG_MANAGE_ORGANIZATION_LOCATIONS_REQUEST,
    payload,
});

/**
 * @description Create organization locations
 */
export const orgCreateLocation = (payload: { location: Partial<Omit<Location, "id">> }) => ({
    type: types.ORG_CREATE_LOCATION_REQUEST,
    payload,
});

/**
 * @description Update organization locations
 */
export const orgUpdateLocation = (payload: { location: Partial<Omit<Location, "id">>; id: string }) => ({
    type: types.ORG_UPDATE_LOCATION_REQUEST,
    payload,
});

/**
 * @description Delete organization locations
 */
export const orgDeleteLocation = (payload: { id: string }) => ({
    type: types.ORG_DELETE_LOCATION_REQUEST,
    payload,
});

/**
 * @description search for available locations
 */
export const orgGetPlaces = (payload: { query: string }) => ({
    type: types.ORG_GET_PLACES_REQUEST,
    payload,
});

/**
 * @description Set organization sales taxes
 */
export const orgSetOrganizationSalesTaxes = (payload: { country: string; region: string; taxes: Organization["taxes"]["CA"]["BC"]["taxes"] }) => ({
    type: types.ORG_SET_ORGANIZATION_SALES_TAXES_REQUEST,
    payload,
});

/**
 * @description delete organization
 */
export const orgDeleteOrganization = (payload: { orgId: Organization["orgId"] }) => ({
    type: types.ORG_DELETE_ORGANIZATION_REQUEST,
    payload,
});

/**
 * @description enter organization
 */
export const orgEnterOrganization = (payload: {
    orgId: Organization["orgId"];
    /**
     * @description if specified it will only request data from api without reloading,
     * @requires orgId attribute
     */
    preventReload?: boolean;
    /**
     * @description if specified it will redirect user to the page
     */
    redirect?: string;
}) => ({
    type: types.ORG_ENTER_ORGANIZATION_REQUEST,
    payload,
});

/**
 * @description walkthrough organization  setup
 */
export const orgSetup = (payload: {
    /**
     * @description which step you want the user to move to when everything is done in the
     * actual dispatch call.
     */
    nextStep?: OrganizationState["tour"]["stepName"];
    /**
     * @description allows you to validate sub steps inside popups.
     */
    currentSubStep?: OrganizationState["tour"]["subStep"];
    /**
     * @description allows to move to a next sub step in an step, do not use it  as global
     * because some steps uses different naming for sub steps/view
     */
    nextSubStep?: OrganizationState["tour"]["subStep"];
    data: Partial<{
        businessType: Organization["businessType"];
        industry: string;
        locations: GuideOrgContextState["form"]["locations"];
        service: GuideOrgContextState["form"]["service"];
        employees: GuideOrgContextState["form"]["employees"];
        employeeSchedule: SingleEmployeeSchedules;
        employeeServices: { employeeId: string; services: GuideOrgContextState["form"]["employeeServices"] };
        confirmation: Partial<{
            name: Organization["name"];
            headquarter: Organization["headquarter"];
            fiscalYear: Organization["fiscalYear"];
            timeZone: Organization["timeZone"];
            currency: Organization["currency"];
            timeFormat: Organization["timeFormat"];
        }>;
    }>;
}) => ({
    type: types.ORG_SETUP_REQUEST,
    payload,
});

/**
 * @description move organization on tour step.
 */
export const orgTourStep = (payload: {
    /**
     * @description which step you want to move to.
     */
    stepName: OrganizationState["tour"]["stepName"];
}) => ({
    type: types.ORG_TOUR_STEP_REQUEST,
    payload,
});

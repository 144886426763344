import React, { useState, useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { authChangeState, authForgotPassword } from "../../redux/auth/reducer/actions";
import useError from "../../../../helpers/hooks/useError";
import "../../assets/styles/auth.style.scss";
import { Button, InputText } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeFPForm } from "../../context/actions";
import { ReactComponent as SteveThink } from "@soltivo/draw-a-line/core/assets/illutrations/SteveThink.svg";
import { RootState } from "../../../../redux/reducers";

interface ForgotPasswordProps extends MapStateToProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ error }) => {
    const dispatch = useDispatch();
    const {
        state: { FPForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);
    const history = useHistory();
    const [submited, setSubmited] = useState(false);

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatchContext(authOnChangeFPForm({ ...form, [e.target.name]: e.target.value }));

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (submited) {
            return;
        }
        dispatch(authForgotPassword(form));
        history.push("/forgot-password-code");
    };

    useEffect(() => {
        dispatch(
            authChangeState({
                error: null,
            })
        );
    }, []);

    useError(error, [() => setSubmited(false)]);

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Forgot Password</h2>

                        {Object.keys(form).map((input, index) => {
                            if (input !== "email") return;

                            return (
                                <div key={index} className="auth-container__input-container">
                                    <InputText
                                        name={input}
                                        onChange={onChange}
                                        bottomBorderStyle={true}
                                        value={form[input]}
                                        placeholder={`Insert your ${input}`}
                                        type={"email"}
                                        autoComplete={"off"}
                                        required
                                        className={`auth-container__input`}
                                    />
                                </div>
                            );
                        })}

                         <div className="auth-container__button-container">
                            <span className="auth-container__link--create-account">
                                All good with your password? {" "}
                                <Link to="/login">
                                Log in here 
                                </Link>
                            </span>
                        </div>

                        <Button reversedPrimary={true} variant="primary" loading={submited} className="btn auth-container__button-primary" type="submit" disabled={submited}>
                            Reset Password
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <SteveThink />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(ForgotPassword);

import { InputSelect, InputText } from "@soltivo/draw-a-line";
import { FC, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Location, Organization } from "../../../../../../applications/auth/organization";
import { orgSetup } from "../../../../../../applications/auth/redux/org/reducer/actions";
import timezones from "../../../../../../helpers/json/timezones.json";
import { RootState } from "../../../../../../redux/reducers";
import { guideChangeAction } from "../../../context/actions";
import GuideOrgContext from "../../../context/guide.org.context";
import useGuideHeading from "../../../hooks/useGuideHeading";
import currencies from "../../../../../../helpers/json/currencies.json";
import "./step.confirm.scss";

interface StepConfirmProps {
    org: Organization;
}

const StepConfirm: FC<StepConfirmProps> = ({ org }) => {
    const dispatch = useDispatch();
    const { dispatch: dispatchContext } = useContext(GuideOrgContext);

    //* Fields label
    const formLabels = {
        name: "Organization Name",
        headquarter: "Headquarter",
        fiscalYear: "Fiscal year",
        timeZone: "Timezone",
        timeFormat: "Time Format",
        currency: "Currency",
    };

    const [selectedHeadquarter, setSelectedHeadquarter] = useState<Location>();
    const [form, setForm] = useState<Partial<Pick<Organization, "name" | "headquarter" | "fiscalYear" | "timeZone" | "currency" | "timeFormat">>>({
        name: "",
        headquarter: "",
        fiscalYear: "",
        timeZone: "",
        currency: "",
        timeFormat: "",
    });

    let formOptions: { [key: string]: any[] } = {
        timeFormat: ["24 hours", "12 hours"],
        currency: Object.values(currencies).map(item => item.code.toLowerCase()),
        fiscalYear: [
            "January - December",
            "February - January",
            "March - February",
            "April - March",
            "May - April",
            "June - May",
            "July - June",
            "August - July",
            "September - August",
            "October - September",
            "November - October",
            "December - November",
        ],
        timeZone: timezones,
        headquarter: org?.locations || [],
    };

    useGuideHeading({
        title: "We’re almost done!",
        description: "Just confirm with us some information related to your business to simplify your experience on your platform.",
    });

    /**
     * @description change form input values when an change occur in on of the inputs
     */
    const onChange = ({ value, name }: { value: any; name?: string }) => {
        if (value === null || !name) return;

        if (name === "headQuarter") {
            const orgLocations = org?.locations || [];
            value = orgLocations.find((location) => location.name === value);
        }

        //remove space between for time format.
        if (name === "timeFormat") {
            value = value.replace(/\s/g, "");
        }

        //add underscore to timeZone
        if (name === "timeZone") {
            value = value.replace(/\s/g, "_");
        }

        setForm({
            ...form,
            [name]: value,
        });
    };

    useEffect(() => {
        if (!org) return;
        const headQuarter = org.locations.find((loc) => loc.id === org?.headquarter);
        let form = {
            name: org.name || "",
            fiscalYear: org?.fiscalYear || "",
            timeZone: org?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeFormat: org?.timeFormat,
            currency: org?.currency || "",
            headquarter: headQuarter?.id || org.locations[0]?.id,
        };
        setForm(form);
        setSelectedHeadquarter(headQuarter || org.locations[0]);
    }, [org]);

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    disabled: !form.currency || !form.fiscalYear || !form.headquarter || !form.timeFormat || !form.timeZone || !form.name,

                    onClick: () => {
                        dispatch(
                            orgSetup({
                                data: {
                                    confirmation: form,
                                },
                            })
                        );
                    },
                },
            })
        );
    }, [form]);

    return (
        <div id="stepConfirm" className="w-100">
            <div className="organization-form row w-100">
                <div className="form-group col-12">
                    <label className="text-geyser-800" htmlFor="text">
                        {formLabels["name"]}
                    </label>
                    <InputText name="name" onChange={(e) => onChange({ name: "name", value: e.target.value })} value={form.name} />
                </div>

                <div className="form-group col-12">
                    <label className="text-geyser-800" htmlFor="text">
                        {formLabels["headquarter"]}
                    </label>
                    <InputSelect
                        name={"headquarter"}
                        data-testid="headquarter"
                        options={(formOptions["headquarter"] || []).map((item: Location) => ({ value: item.name, option: item }))}
                        onChange={({ option }) => {
                            setSelectedHeadquarter(option);
                            setForm({ ...form, headquarter: option.id });
                        }}
                        value={selectedHeadquarter?.name || ""}
                    />
                    <p className="desc">Use to apply sales taxes correctly on your subscription.</p>
                </div>

                {Object.keys(form).map((key: string, index: number) => {
                    let input = key as ReturnType<() => keyof typeof form>;

                    if (input === "name" || input === "headquarter") return null;

                    return (
                        <div key={index} className={`form-group col-12 col-lg-6`}>
                            <label className="text-geyser-800" htmlFor="text">
                                {formLabels[input as ReturnType<() => keyof typeof formLabels>]}
                            </label>
                            <InputSelect
                                name={input}
                                data-testid={input}
                                options={formOptions[input].map((item: string) => ({ value: item }))}
                                onChange={(e) => onChange({ value: e.value, name: e.name })}
                                value={`${form[input] || ""}`.replace(/([0-9]+)/g, "$1 ").replace(/_/g, " ")}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = ({ OrgReducer }: RootState) => ({
    org: OrgReducer.org as Organization,
});

export default connect(mapStateToProps)(StepConfirm);

import { Button, InputSearch, Loader, useClickOutsideRef, FormControl, InputText, InputPhone } from "@soltivo/draw-a-line";
import { useContext, useState, useRef, useEffect, FC } from "react";
import styles from "./step.customer.selection.module.scss";
import AppointmentContext from "../../../context/appointment.context";
import { AppointmentContextState } from "../../../context/reducer";
import { useDispatch, connect } from "react-redux";
import { contactsSearchEntity, contactsChangeState } from "../../../../contacts/redux/reducer/actions";
import { RootState } from "../../../../../redux/reducers";
import useListHelpers from "@soltivo/draw-a-line/core/hooks/useListHelpers";
import { ContactSearchItem } from "../../../../contacts/contacts";
import { Plus } from "@soltivo/draw-a-line/core/components/icons";
import { appointmentChangeEntityForm, appointmentCreationChangeView } from "../../../context/actions";

export interface DisplayInfoProps extends MapState {
    onChangeForm: ({ name, value }: { name: keyof AppointmentContextState["form"]; value: any }) => void;
    moveNext(): void;
}

const SelectCustomerStep: FC<DisplayInfoProps> = ({ searchResults, searchingEntity, newEntity, onChangeForm, moveNext }) => {
    const { state, dispatch: dispatchContext } = useContext(AppointmentContext);
    const dispatch = useDispatch();

    const {
        form: { contactEntityInfo },
        appointmentCreationView: { subStep },
        createEntityForm: form,
    } = state;

    //* Fields label
    const formLabels = {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phoneNumber: "Phone number",
    };

    const [searchContact, setSearchContact] = useState("");
    const resultsRef = useRef<any>(null);
    const [showSearchResults, setShowSearchResults] = useState(false);

    useEffect(() => {
        if (searchingEntity || searchContact) {
            onDrop();
        }
    }, [searchingEntity, searchContact]);

    useEffect(() => {
        // When a new client is created, automatically select it
        if (newEntity) {
            onSelect({
                entityId: newEntity.entityId,
                firstName: newEntity.firstName,
                lastName: newEntity.lastName,
                email: newEntity.email,
                phoneNumber: `${newEntity.phoneNumber}` || "",
            });
            dispatchContext(appointmentCreationChangeView({ subStep: undefined }));
            // Move to the next step
            moveNext();
        }

        return () => {
            dispatch(contactsChangeState({ newEntity: undefined }));
            dispatchContext(appointmentCreationChangeView({ subStep: undefined }));
        };
    }, [newEntity]);

    const onSearchContact = (query: string) => {
        setSearchContact(query);
        dispatch(contactsSearchEntity(query));
    };

    const { listState, onKeydown, onKeydownScrollable, setListState } = useListHelpers();

    const onSelect = (entity: ContactSearchItem) => {
        onChangeForm({ name: "contactEntityInfo", value: entity });
        dispatch(
            contactsChangeState({
                searchResults: [],
            })
        );
        setShowSearchResults(false);
    };

    const onDrop = () => {
        setShowSearchResults(true);
        if (resultsRef.current instanceof HTMLDivElement) {
            resultsRef.current.focus();
            setListState((state) => ({
                ...state,
                selected: undefined,
            }));
        }
    };

    useEffect(() => {
        onKeydownScrollable(resultsRef.current?.querySelector(".search__contact-entities"), {
            move: showSearchResults ? "continue" : "initial",
        });
        if (searchResults.length === 1) {
            setListState((state) => ({
                ...state,
                selected: 0,
            }));
        }
    }, [listState.selected, showSearchResults, searchResults.length]);

    const highlightText = (text: string): JSX.Element => {
        const lower = text.toLowerCase();
        const change = lower.replace(new RegExp(`(${searchContact.toLowerCase()})`, "g"), "<mark>$1</mark>");
        return <span dangerouslySetInnerHTML={{ __html: change }}></span>;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatchContext(appointmentChangeEntityForm({ ...form, [name]: value }));
    };

    useClickOutsideRef([window], () => setShowSearchResults(false), resultsRef);

    return (
        <div className={styles.search__wrapper__component}>
            {subStep !== "createClient" ? (
                <div className={styles.search__wrapper}>
                    <div className={styles.search__wrapper__content}>
                        <p>Search a customer</p>
                        <InputSearch
                            hidden={false}
                            autoComplete="off"
                            value={searchContact}
                            focus={true}
                            onChange={(e) => {
                                onSearchContact(e.target.value);
                            }}
                            placeholder="Search a contact"
                            name="search"
                            isSearching={searchingEntity}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !showSearchResults) {
                                    setShowSearchResults(true);
                                    return;
                                }
                                onKeydown(e, {
                                    list: searchResults.map((item) => item.entityId) as string[],
                                    onEnter: () => {
                                        if (listState.selected !== undefined) {
                                            onSelect(searchResults[listState.selected]);
                                        } else {
                                            setShowSearchResults(false);
                                        }
                                    },
                                });
                            }}
                        />

                        <Button
                            onClick={() => {
                                setShowSearchResults(false);
                                dispatchContext(appointmentCreationChangeView({ subStep: "createClient" }));
                            }}
                            outline
                            style={{marginTop: "12px"}}
                            variant="primary"
                            padding={false}>
                            <Plus /> Create A Client
                        </Button>

                        <p className="phone-preview">{searchContact?.length > 0 && !isNaN(parseInt(searchContact)) && `+${searchContact}`}</p>
                        {showSearchResults && (
                            <div ref={resultsRef} className={styles.results__wrapper}>
                                {searchingEntity ? (
                                    <div className={styles.loader__wrapper}>
                                        <Loader data-testid="loader" variant="primary" size="sm" />
                                    </div>
                                ) : (
                                    <div data-testid="searchResult" className={styles.search__contact__entities}>
                                        {searchResults.map((entity, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    tabIndex={1}
                                                    id={searchResults.length === 1 ? styles.default__selected : ""}
                                                    className={`${styles.search__contact__item} ${listState.selected === index ? styles.selected : ""}`}
                                                    onClick={() => onSelect(entity)}>
                                                    <span>
                                                        <p className="text-15 text-geyser-900" data-query-contact={searchContact}>
                                                            {highlightText(`${entity.firstName} ${entity?.lastName || ""}`)}
                                                        </p>

                                                        <span className="text-12 text-geyser-700">{highlightText(`${entity.email}`)}</span>
                                                    </span>
                                                    <p className="text-12 text-geyser-500">{highlightText(`${entity.phoneNumber}`)}</p>
                                                </div>
                                            );
                                        })}
                                        <div className={styles.button__wrapper}>
                                            <Button
                                                onClick={() => {
                                                    setShowSearchResults(false);
                                                    dispatchContext(appointmentCreationChangeView({ subStep: "createClient" }));
                                                }}
                                                outline
                                                variant="primary"
                                                padding={false}>
                                                <Plus /> Create A Client
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {contactEntityInfo && (
                        <div data-testid="contactInfos" className={`${styles.customer__info} `}>
                            <h6 className="text-geyser-900">
                                {contactEntityInfo?.firstName || ""} {contactEntityInfo?.lastName || ""}
                            </h6>
                            <p className={`${styles.email} text-geyser-600`}>{contactEntityInfo?.email}</p>
                            <p className={`${styles.phoneNumber} text-geyser-600`}>{typeof contactEntityInfo?.phoneNumber === "string" ? contactEntityInfo?.phoneNumber : ""}</p>
                        </div>
                    )}
                </div>
            ) : (
                <div data-testid="createClientForm" className={styles.create__client__wrapper}>
                    {Object.keys(form).map((key: string, index: number) => {
                        let input = key as ReturnType<() => keyof typeof form>;

                        if (input === "phoneNumber") {
                            return (
                                <FormControl
                                    key={index}
                                    footerProps={{
                                        align: "right",
                                        value: undefined,
                                    }}
                                    labelProps={{
                                        value: formLabels[input],
                                    }}>
                                    <InputPhone
                                        preferredCountries={["us", "ca", "fr"]}
                                        inputProps={{
                                            name: "phoneNumber",
                                            required: false,
                                        }}
                                        value={form.phoneNumber}
                                        masks={{ fr: "(...) ..-..-..", at: "(....) ...-...." }}
                                        onChange={(value, country, e, formattedValue) => {
                                            dispatchContext(appointmentChangeEntityForm({ ...form, phoneNumber: formattedValue }));
                                        }}
                                    />
                                </FormControl>
                            );
                        }

                        return (
                            <FormControl
                                key={index}
                                footerProps={{
                                    align: "right",
                                    value: undefined,
                                }}
                                labelProps={{
                                    value: formLabels[input as ReturnType<() => keyof typeof formLabels>],
                                }}>
                                <InputText name={input} type={input === "email" ? "email" : "text"} placeholder={formLabels[input]} onChange={(e) => handleInputChange(e)} value={form[input]} />
                            </FormControl>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ ContactsReducer }: RootState) => ({
    searchResults: ContactsReducer.searchResults,
    searchingEntity: ContactsReducer.searchingEntity,
    newEntity: ContactsReducer.newEntity,
});

type MapState = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(SelectCustomerStep);

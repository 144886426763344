import { call, put, takeEvery, takeLatest, delay, select, CallEffect, PutEffect, SelectEffect, all, race, take, RaceEffect, TakeEffect } from "redux-saga/effects";
import { types } from "../reducer/types";
import { types as orgTypes } from "../../../auth/redux/org/reducer/types";
import { types as authTypes } from "../../../auth/redux/auth/reducer/types";

import HRMClass from "./hrm.class";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import popupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import apiV2, { ValidationError } from "../../../../helpers/api.v2";
import history from "../../../../app/routes/history";
import { soltivoHelper } from "@soltivo/draw-a-line";
import { IHRMActions } from "../reducer/actions.d";
import { RootState } from "../../../../redux/reducers";
import { Organization } from "../../../auth/organization";
import { User } from "../../../auth/auth";
import { TEmployee, EmployeeList, TPayrolls, TRole, TRoles, SingleEmployeeSchedules } from "../../hrm";
import { convert24hrTo12hr } from "../../helpers/helpers";
// import { utcToTimezone } from "../../../appointment/helpers/time";

/**
 * @description display toast with any error
 */
function displayAnyError(error: any) {
    if (error.error?.code >= 400) {
        const errors = apiV2.stringifyErrors(error);
        if (errors) toastHelper.toastStartContent("danger", errors);
    } else if (error.messsage) {
        toastHelper.toastStartContent("danger", error.message);
    }
}

/**
 * @done
 * @description create employee.
 * @todo actions related to Schedule, Timer tracker & Payroll wasn't created yet.
 */
function* hrmCreateEmployee({ payload }: IHRMActions["hrmCreateEmployee"]) {
    try {
        const { data } = yield call(HRMClass.hrmCreateEmployee, payload);
        yield put({ type: types.HRM_CREATE_EMPLOYEE_SUCCESS, payload: data });

        let { org }: { org: Organization; organizations: Organization[] } = yield select(({ OrgReducer }: RootState) => ({
            org: OrgReducer.org,
            organizations: OrgReducer.organizations,
        }));

        yield put({
            type: orgTypes.ORG_CHANGE_STATE_REQUEST,
            payload: {
                // Update at organization
                org: {
                    ...org,
                    userNumber: org.userNumber + 1,
                },
            },
        });

        toastHelper.toastStartContent("success", "Employee invitation was sent.");

        yield put({ type: types.HRM_GET_ALL_EMPLOYEES_REQUEST, payload: { includeInactive: true } });
        popupHelper.popupEnd();
    } catch (error: any) {
        yield put({
            type: types.HRM_CREATE_EMPLOYEE_FAILURE,
            payload: error,
        });
        displayAnyError(error);
    }
}

/**
 * @done
 * @description get all active employees.
 */
function* hrmGetAllEmployees({ payload }: IHRMActions["hrmGetAllEmployees"]) {
    try {
        const { data } = yield call(HRMClass.hrmGetAllEmployees, payload);

        yield put({
            type: types.HRM_GET_ALL_EMPLOYEES_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        console.error(error);
        if (error?.error?.code === 404) {
            yield put({
                type: types.HRM_GET_ALL_EMPLOYEES_FAILURE,
                payload: [],
            });
            return;
        }
        yield put({
            type: types.HRM_GET_ALL_EMPLOYEES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @done
 * @description get employees activities.
 */
function* hrmGetEmployeeActivities({ payload }: IHRMActions["hrmGetEmployeeActivities"]) {
    //{ employeeId: string; exactDate: string; from: string; to: string }
    try {
        const { data } = yield call(HRMClass.hrmGetEmployeeActivities, payload);
        yield put({
            type: types.HRM_GET_EMPLOYEE_ACTIVITIES_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        console.error(error);
        if (error.error?.code === 404) {
            yield put({
                type: types.HRM_GET_EMPLOYEE_ACTIVITIES_SUCCESS,
                payload: [],
            });
            return;
        }
        yield put({
            type: types.HRM_GET_EMPLOYEE_ACTIVITIES_FAILURE,
            payload: error,
        });
    }
}

function* hrmGetAllEmployeeSchedules() {
    try {
        yield delay(500);

        const { data } = yield call(HRMClass.hrmGetAllEmployeeSchedules);

        yield put({
            type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        console.error(error);

        if (error?.error?.code === 404) {
            yield put({
                type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_FAILURE,
                payload: error,
            });
        }
    }
}

// /**
//  * @description get all schedules by id
//  */
// function* hrmGetEmployeeSchedules({ payload }: IHRMActions["hrmGetEmployeeSchedules"]) {
//     try {
//         const { data } = yield call(HRMClass.hrmGetEmployeeSchedules, payload);
//         yield put({
//             type: types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_SUCCESS,
//             payload: data,
//         });
//     } catch (error: any) {
//         console.error(error);
//         if (error.error?.code === 404) {
//             yield put({
//                 type: types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_SUCCESS,
//                 payload: [],
//             });
//             return;
//         }
//         yield put({
//             type: types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_FAILURE,
//             payload: error,
//         });
//     }
// }

function* hrmGetAllPayrolls({ payload }: IHRMActions["hrmGetAllPayrolls"]) {
    try {
        yield delay(2000);
        let { data: payrolls }: { data: TPayrolls } = yield call(HRMClass.hrmGetAllPayrolls, payload);

        const { user }: { employees: EmployeeList; user: User } = yield select(({ AuthReducer }: RootState) => ({
            user: AuthReducer.user,
        }));

        if (user.roleId !== "0") {
            // if user is not admin, do not show payrolls of other employees.
            payrolls = payrolls.filter((payroll) => {
                return user?.userId === payroll.employeeId;
            });
        }

        yield put({ type: types.HRM_GET_ALL_PAYROLLS_SUCCESS, payload: payrolls });
    } catch (error: any) {
        console.error(error);
        if (error?.error?.code === 404) {
            yield put({
                type: types.HRM_GET_ALL_PAYROLLS_SUCCESS,
                payload: [],
            });
            return;
        }
        yield put({
            type: types.HRM_GET_ALL_PAYROLLS_FAILURE,
            payload: error,
        });
    }
}

// /**
//  * @description get all schedule template.
//  */
// function* hrmGetAllScheduleTemplates() {
//     try {
//         const { data } = yield call(HRMClass.hrmGetAllScheduleTemplates);

//         const { org }: { org: Organization } = yield select(({ OrgReducer }: RootState) => ({
//             org: OrgReducer.org,
//         }));

//         // // convert received time into org timezone
//         const parsedData = data.map((template: TTemplate) => {
//             template.weekDays = convertWeekDaysToLocalTimeZone(template.weekDays, org?.timeZone as string);
//             return template
//         });

//         yield put({
//             type: types.HRM_GET_ALL_SCHEDULES_TEMPLATE_SUCCESS,
//             payload: parsedData,
//         });
//     } catch (error: any) {
//         console.error(error);
//         if (error.code === 404) {
//             yield put({
//                 type: types.HRM_GET_ALL_SCHEDULES_TEMPLATE_SUCCESS,
//                 payload: [],
//             });
//             return;
//         }
//         yield put({
//             type: types.HRM_GET_ALL_SCHEDULES_TEMPLATE_FAILURE,
//             payload: error,
//         });
//     }
// }

// function* hrmCreateSchedule({ payload }: IHRMActions["hrmCreateSchedule"]) {
//     try {
//         const { user, org }: { user: User, org: Organization } = yield select(({ AuthReducer, OrgReducer }: RootState) => ({
//             user: AuthReducer.user,
//             org: OrgReducer.org,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.write))
//             throw new Error("Employee is not allowed to create schedule.");

//         // convert time from timezone to UTC
//         payload.weekDays = convertWeekDaysToUtc(payload.weekDays, org?.timeZone as string);

//         yield call(HRMClass.hrmCreateSchedule, payload);
//         yield put({
//             type: types.HRM_CREATE_SCHEDULE_SUCCESS,
//         });

//         toastHelper.toastStartContent("success", "Schedule created successfully.");
//         history.push("/administration/team/schedules");
//     } catch (error: any) {
//         console.error(error);
//         yield put({
//             type: types.HRM_CREATE_SCHEDULE_FAILURE,
//             payload: error,
//         });

//         displayAnyError(error);
//     }
// }

// function* hrmUpdateSchedule({ payload }: IHRMActions["hrmUpdateSchedule"]) {
//     try {
//         const { user, org }: { user: User, org: Organization } = yield select(({ AuthReducer, OrgReducer }) => ({
//             user: AuthReducer.user,
//             org: OrgReducer.org,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.write))
//             throw new Error("Employee is not allowed to update schedule.");

//         //  Convert to UTC
//         payload.start = timeToUTC(payload.start, org?.timeZone as string);
//         payload.end = timeToUTC(payload.end, org?.timeZone as string);

//         payload.breaks = payload.breaks.map(_break => {
//             const { start, end } = _break;
//             _break.start = timeToUTC(start, org?.timeZone as string) ;
//             _break.end = timeToUTC(end, org?.timeZone as string);
//             return _break;
//         });

//         yield call(HRMClass.hrmUpdateSchedule, payload);

//         // refresh if has query string in url
//         const query = new URLSearchParams(history.location.search);
//         const qStart = query.get("start");
//         const qEnd = query.get("end");
//         if (qStart && qEnd)
//             yield put({
//                 type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST,
//                 payload: {
//                     start: qStart,
//                     end: qEnd,
//                 },
//             });

//         yield put({ type: types.HRM_UPDATE_SCHEDULE_SUCCESS });

//         toastHelper.toastStartContent("success", "Schedule updated successfully.");
//         popupHelper.popupEnd();
//     } catch (error: any) {
//         console.error(error);
//         yield put({
//             type: types.HRM_UPDATE_SCHEDULE_FAILURE,
//             payload: error,
//         });

//         displayAnyError(error);
//     }
// }

// function* hrmUpdateScheduleWithTemplate({ payload }: IHRMActions["hrmUpdateScheduleWithTemplate"]) {
//     try {
//         const { user }: { user: User } = yield select(({ AuthReducer }) => ({
//             user: AuthReducer.user,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.write))
//             throw new Error("Employee is not allowed to update schedule.");

//         yield call(HRMClass.hrmUpdateScheduleWithTemplate, payload);

//         // refresh if has query string in url
//         const query = new URLSearchParams(history.location.search);
//         const qStart = query.get("start");
//         const qEnd = query.get("end");
//         if (qStart && qEnd)
//             yield put({
//                 type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST,
//                 payload: {
//                     start: qStart,
//                     end: qEnd,
//                 },
//             });

//         yield put({ type: types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_SUCCESS });

//         toastHelper.toastStartContent("success", "Schedule updated successfully.");
//         popupHelper.popupEnd();
//     } catch (error: any) {
//         console.error(error);
//         yield put({
//             type: types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_FAILURE,
//             payload: error,
//         });

//         displayAnyError(error);
//     }
// }

// function* hrmDeleteSchedule({ payload }: IHRMActions["hrmDeleteSchedule"]) {
//     try {
//         const { user }: { user: User } = yield select(({ AuthReducer }) => ({
//             user: AuthReducer.user,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.delete))
//             throw new Error("Employee is not allowed to delete schedule.");

//         yield call(HRMClass.hrmDeleteSchedule, payload);
//         yield put({
//             type: types.HRM_DELETE_SCHEDULE_SUCCESS,
//         });

//         // refresh if has query string in url
//         const query = new URLSearchParams(history.location.search);
//         const qStart = query.get("start");
//         const qEnd = query.get("end");
//         if (qStart && qEnd)
//             yield put({
//                 type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST,
//                 payload: {
//                     start: qStart,
//                     end: qEnd,
//                 },
//             });

//         toastHelper.toastStartContent("success", "Schedule deleted successfully.");
//         popupHelper.popupEnd();
//     } catch (error: any) {
//         console.error(error);
//         yield put({
//             type: types.HRM_DELETE_SCHEDULE_FAILURE,
//             payload: error,
//         });

//         displayAnyError(error);
//     }
// }

// /**
//  * @description create schedule template
//  */
// function* hrmCreateTemplate({ payload }: IHRMActions["hrmCreateTemplate"]) {
//     try {
//         const mapStateToProps = ({ AuthReducer, HRMReducer, OrgReducer }: RootState) => ({
//             scheduleTemplates: HRMReducer.scheduleTemplates,
//             org: OrgReducer.org,
//             user: AuthReducer.user as Readonly<User>,
//         });

//         type MapStateToProps = ReturnType<typeof mapStateToProps>;

//         const { scheduleTemplates, user, org }: MapStateToProps = yield select(mapStateToProps);

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.write)) {
//             throw new ValidationError({ message: "You do not have the right permissions for create a new template." });
//         }

//         for (let i = 0; i < scheduleTemplates.length; i++) {
//             let template = scheduleTemplates[i];

//             if (template.name.toLowerCase() === payload.name.toLowerCase()) {
//                 throw new ValidationError({ message: `Template name ${payload.name} already exists in your organization.`, flag: "info" });
//             }
//         }

//         for (let i = 0; i < payload.weekDays.length; i++) {
//             const weekday = payload.weekDays[i];

//             const { start, end } = hrmGetEndStart__helper({
//                 start: weekday.start,
//                 end: weekday.end,
//             });

//             if (end < start) {
//                 throw new ValidationError({ message: `Failed to template schedule ${payload.name}, ${weekday.weekday} start ${weekday.start} is not valid to end at ${weekday.end}.` });
//             }
//         }

//         // convert time from timezone to UTC
//         payload.weekDays = convertWeekDaysToUtc(payload.weekDays, org?.timeZone as string);
//         const { data } = yield call(HRMClass.hrmCreateTemplate, payload);

//         // convert created template from UTC to timezone
//         data.weekDays = convertWeekDaysToLocalTimeZone(data.weekDays, org?.timeZone as string);
//         yield put({ type: types.HRM_CREATE_TEMPLATE_SUCCESS, payload: data });
//         toastHelper.toastStartContent("success", "Template created successfully.");
//     } catch (error) {
//         if (error instanceof ValidationError) {
//             toastHelper.toastStartContent(error.flag || "danger", error.message);
//         }

//         yield put({ type: types.HRM_CREATE_TEMPLATE_FAILURE, payload: error });
//     }
// }

// /**
//  * @description update schedule template by id
//  */
// function* hrmUpdateTemplate({ payload }: IHRMActions["hrmUpdateTemplate"]) {
//     try {
//         const { user, org }: { user: User, org: Organization } = yield select(({ AuthReducer, OrgReducer }) => ({
//             user: AuthReducer.user,
//             org: OrgReducer.org,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.write))
//             throw "User is not allowed to update template.";

//         // convert time from timezone to UTC
//         payload.weekDays = convertWeekDaysToUtc(payload.weekDays, org?.timeZone as string)

//         const { data } = yield call(HRMClass.hrmUpdateTemplate, payload);

//         // convert received UTC time into organization timezone
//         data.weekDays = data.weekDays = convertWeekDaysToLocalTimeZone(data.weekDays, org?.timeZone as string);

//         yield put({ type: types.HRM_UPDATE_TEMPLATE_SUCCESS, payload: data });
//         toastHelper.toastStartContent("success", "Template updated successfully.");
//     } catch (error: any) {
//         console.error(error);
//         yield put({ type: types.HRM_UPDATE_TEMPLATE_FAILURE, payload: error });
//         displayAnyError(error);
//     }
// }

// /**
//  * @description delete schedule template by id
//  */
// function* hrmDeleteTemplate({ payload }: IHRMActions["deleteScheduleTemplate"]) {
//     try {
//         const { user }: { user: User } = yield select(({ AuthReducer }) => ({
//             user: AuthReducer.user,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.delete))
//             throw "User is not allowed to delete schedule.";

//         yield call(HRMClass.hrmDeleteTemplate, payload);
//         yield put({ type: types.HRM_DELETE_TEMPLATE_SUCCESS, payload });
//         popupHelper.popupEnd();

//         toastHelper.toastStartContent("success", "Template deleted successfully.");
//     } catch (error: any) {
//         yield put({ type: types.HRM_DELETE_TEMPLATE_FAILURE, payload: error });
//         displayAnyError(error);
//     }
// }

// /**
//  * @description assign template to a employee
//  */
// function* hrmAssignTemplate({ payload }: IHRMActions["hrmAssignTemplate"]) {
//     try {
//         const { user }: { user: User } = yield select(({ AuthReducer }: RootState) => ({
//             user: AuthReducer.user,
//         }));

//         if (!haveAccess(user.roleInfo?.permissions.hrm?.schedules.write))
//             throw "User is not allowed to assign template.";

//         yield call(HRMClass.hrmAssignTemplate, payload);
//         yield put({ type: types.HRM_ASSIGN_TEMPLATE_SUCCESS });

//         // refresh if has query string in url
//         const query = new URLSearchParams(history.location.search);
//         const qStart = query.get("start");
//         const qEnd = query.get("end");
//         if (qStart && qEnd)
//             yield put({
//                 type: types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST,
//                 payload: {
//                     start: qStart,
//                     end: qEnd,
//                 },
//             });

//         toastHelper.toastStartContent("success", "Template assigned successfully.");
//         popupHelper.popupEnd();
//     } catch (error: any) {
//         console.error(error);
//         yield put({
//             type: types.HRM_ASSIGN_TEMPLATE_FAILURE,
//             payload: error,
//         });
//         displayAnyError(error);
//     }
// }

// /**
//  * @description Check schedules collisions
//  */
// function* hrmCheckCollisions({ payload }: IHRMActions["hrmCheckCollisions"]) {
//     try {
//         yield call(HRMClass.hrmCheckCollisions, payload);
//         yield put({ type: types.HRM_CHECK_COLLISIONS_SUCCESS, payload: false });
//     } catch (error: any) {
//         if (error.error?.code === 409) {
//             yield put({ type: types.HRM_CHECK_COLLISIONS_SUCCESS, payload: true });
//         } else {
//             yield put({ type: types.HRM_CHECK_COLLISIONS_FAILURE, payload: error });
//         }
//     }
// }

/**
 * @description get employee schedule
 */
function* hrmGetEmployeeSchedule({ payload }: IHRMActions["hrmGetEmployeeSchedule"]) {
    try {
        const { data } = yield call(HRMClass.hrmGetEmployeeSchedule, payload);

        const { org }: { user: User; org: Organization } = yield select(({ OrgReducer }) => ({
            org: OrgReducer.org,
        }));

        const scheduleData: SingleEmployeeSchedules = { ...data };

        // convert to org time format
        if (org.timeFormat === "12hours") {
            scheduleData.weekdays = scheduleData.weekdays.map((splits) => {
                splits = splits.map((d) => {
                    d.start = convert24hrTo12hr(d.start);
                    d.end = convert24hrTo12hr(d.end);
                    return d;
                });
                return splits;
            });
        }

        yield put({
            type: types.HRM_GET_EMPLOYEE_SCHEDULE_SUCCESS,
            payload: scheduleData,
        });
    } catch (error: any) {
        if (error.error?.code === 404) {
            yield put({
                type: types.HRM_GET_EMPLOYEE_SCHEDULE_SUCCESS,
                payload: null,
            });
            return;
        }
        yield put({
            type: types.HRM_GET_EMPLOYEE_SCHEDULE_FAILURE,
            payload: error,
        });
    }
}

function* hrmUpdateEmployeeSchedule({ payload }: IHRMActions["hrmUpdateEmployeeSchedule"]) {
    try {
        const { data } = yield call(HRMClass.hrmUpdateEmployeeSchedule, payload);

        const { org, employee }: { user: User; org: Organization; employee: TEmployee } = yield select(({ OrgReducer, HRMReducer }) => ({
            org: OrgReducer.org,
            employee: HRMReducer.employee,
        }));

        const scheduleData: SingleEmployeeSchedules = { ...data };

        // convert to org time format
        if (org.timeFormat === "12hours") {
            scheduleData.weekdays = scheduleData.weekdays.map((splits) => {
                splits = splits.map((d) => {
                    d.start = convert24hrTo12hr(d.start);
                    d.end = convert24hrTo12hr(d.end);
                    return d;
                });
                return splits;
            });
        }

        yield put({
            type: types.HRM_UPDATE_EMPLOYEE_SCHEDULE_SUCCESS,
            payload: scheduleData,
        });

        toastHelper.toastStartContent("success", "Schedule updated successfully.");

        // update the employee timezone
        if (employee.timezone === payload.schedule.timezone) return;
        const {
            userId,
            avatar,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            birthDate,
            address,
            address2,
            city,
            zipCode,
            country,
            addressState,
            hourlyRate,
            workPlace,
            businessEmail,
            hireDate,
            workAddress1,
            workAddress2,
            workCountry,
            workState,
            workCity,
            workZipCode,
        } = employee;
        const userPayload = {
            avatar,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            birthDate,
            address,
            address2,
            city,
            country,
            zipCode,
            addressState,
            // role: "",
            hourlyRate,
            workPlace,
            businessEmail,
            hireDate,
            workAddress1,
            workAddress2,
            workCountry,
            workState,
            workCity,
            workZipCode,
            employeeId: userId,
            timezone: payload.schedule.timezone, // set the new timezone
        };

        yield call(HRMClass.hrmUpdateEmployee, userPayload);
    } catch (error: any) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent(error.flag || "danger", error.message);
        }
        yield put({
            type: types.HRM_UPDATE_EMPLOYEE_SCHEDULE_FAILURE,
            payload: error,
        });

        displayAnyError(error);
    }
}

/**
 * @description update payroll Status.
 */
function* hrmUpdatePayroll({ payload }: IHRMActions["hrmUpdatePayroll"]) {
    try {
        let { payrolls }: { payrolls: TPayrolls } = yield select(({ HRMReducer }: RootState) => ({
            payrolls: HRMReducer.payrolls,
        }));

        if (!Array.isArray(payload)) {
            yield call(HRMClass.hrmUpdatePayrollStatus, {
                dates: payload.dates,
                userId: payload.userId,
                hourlyRate: payload.hourlyRate,
                status: payload.status,
            });
            payrolls = payrolls.filter((payroll) => payload.dates.toString() !== payroll.date.toString() && payload.userId !== payroll.employeeId);
        } else {
            const reqList = payload.map((p) => {
                return call(HRMClass.hrmUpdatePayrollStatus, {
                    dates: p.dates,
                    userId: p.userId,
                    hourlyRate: p.hourlyRate,
                    status: p.status,
                });
            });

            yield all(reqList);
            payrolls = payrolls.filter((payroll) => !payload.find((p) => p.dates.toString() === payroll.date.toString() && p.userId === payroll.employeeId));
        }

        yield put({
            type: types.HRM_UPDATE_PAYROLL_SUCCESS,
            payload: {
                payrolls,
            },
        });

        toastHelper.toastStartContent("success", `Payroll status updated successfully.`);
    } catch (error: any) {
        console.error(error);
        yield put({
            type: types.HRM_UPDATE_PAYROLL_FAILURE,
            payload: error,
        });
        displayAnyError(error);
    }
}

/**
 * @description get employee by id
 */
function* hrmGetEmployee({ payload }: IHRMActions["hrmGetEmployee"]) {
    try {
        const { data } = yield call(HRMClass.hrmGetEmployee, payload);

        yield put({
            type: types.HRM_GET_EMPLOYEE_BY_ID_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_GET_EMPLOYEE_BY_ID_FAILURE });
        history.push("/administration/team/employees");

        if (error.error?.code === 404) {
            toastHelper.toastStartContent("danger", "Employee does not exist.");
        }
    }
}

/**
 * @description delete employee.
 */
function* hrmDeleteEmployee({ payload }: IHRMActions["hrmDeleteEmployee"]) {
    try {
        let { org, organizations }: { org: Organization; organizations: Organization[] } = yield select(({ OrgReducer }: RootState) => ({
            org: OrgReducer.org,
            organizations: OrgReducer.organizations,
        }));
        yield call(HRMClass.hrmDeleteEmployee, payload);
        popupHelper.popupEnd();
        toastHelper.toastStartContent("success", "Employee deleted successfully.");
        history.push("/administration/team/employees");

        yield put({ type: types.HRM_DELETE_EMPLOYEE_SUCCESS, payload: [] });

        yield put({ type: types.HRM_GET_ALL_EMPLOYEES_REQUEST, payload: { includeInactive: true } });

        yield put({
            type: orgTypes.ORG_CHANGE_STATE_REQUEST,
            payload: {
                // Update at organization
                org: {
                    ...org,
                    userNumber: org.userNumber > 0 ? org.userNumber - 1 : 0,
                },
                // Update listing
                organizations: organizations.map((o) => {
                    if (o.orgId === org.orgId) {
                        o.userNumber = org.userNumber > 0 ? org.userNumber - 1 : 0;
                    }
                    return o;
                }),
            },
        });
    } catch (error: any) {
        yield put({ type: types.HRM_DELETE_EMPLOYEE_FAILURE, payload: error });
        displayAnyError(error);
    }
}

/**
 * @description update employee by id
 */
function* hrmResetEmployeePassword({ payload }: IHRMActions["hrmResetEmployeePassword"]) {
    try {
        yield call(HRMClass.hrmResetEmployeePassword, payload);

        yield put({ type: types.HRM_RESET_EMPLOYEE_PASSWORD_SUCCESS, payload });
        toastHelper.toastStartContent("success", "Employee's password was reseted successfully.");
    } catch (error: any) {
        console.error(error);
        if (error.error?.code >= 400 && error.error?.code <= 499) {
            toastHelper.toastStartContent("danger", error.error?.message || "Failed to reset employee's password.");
            yield put({
                type: types.HRM_RESET_EMPLOYEE_PASSWORD_FAILURE,
                payload: error,
            });
            return;
        }
    }
}

/**
 * @description update employee.
 */
function* hrmUpdateEmployee({ payload: reqPayload }: IHRMActions["hrmUpdateEmployee"]): Generator<SelectEffect | CallEffect | Promise<File> | PutEffect | RaceEffect<TakeEffect>, void, any> {
    try {
        yield delay(2000);
        let { employees, employee, user, cognitoUser }: { employees: EmployeeList; employee: TEmployee; user: User; cognitoUser: any } = yield select(({ HRMReducer, AuthReducer }: RootState) => ({
            employee: HRMReducer.employee,
            employees: HRMReducer.employees,
            user: AuthReducer.user,
            cognitoUser: AuthReducer.cognitoUser,
        }));
        let payload = { ...reqPayload };
        delete payload.role;
        const avatar = payload.avatar;

        if (!payload.firstName) {
            throw new Error("First name is required.");
        } else if (!payload.hourlyRate && payload.hourlyRate !== 0) {
            throw new Error("Hourly Rate is required.");
        }
        if (avatar && typeof avatar !== "string" && payload.employeeId === user.userId) {
            // IMPORTANT:: this block only runs if the employee being edited is the autheticated user.
            if (!/(jpg|png|jpeg|webp)/g.test(avatar.type?.toLowerCase())) {
                throw new Error("Picture can only be jpeg, jpg, webp or png.");
            }

            const { data: assignedUrl } = yield call(HRMClass.getSignedUrl, {
                file: avatar,
            });

            let fd = new FormData();
            Object.keys(assignedUrl.fields).forEach((key) => {
                fd.append(key, assignedUrl.fields[key]);
            });

            const image: File = yield soltivoHelper.processImageSize(avatar, {
                dimensions: {
                    width: 512,
                    height: 512,
                    fill: true,
                },
            });

            fd.append("file", image);
            fd.append("Content-Type", image.type);
            fd.append("success_action_status", "200");
            fd.append("Cache-Control", "max-age=300,public");
            const res: Response = yield call(fetch, assignedUrl.url, {
                method: "POST",
                body: fd,
            });

            if (res.status >= 200 && res.status < 500) {
                payload.avatar = `https://cdn.soltivo.dev/${assignedUrl.fields.key}?createdAt=${new Date().getTime()}`;
            } else {
                payload.avatar = employee.avatar || "";
            }
        }

        if (employee.personalEmail !== payload.personalEmail && user.userId === employee.userId) {
            yield put({
                type: authTypes.AUTH_UPDATE_USER_ATTRIBUTES_REQUEST,
                payload: {
                    user: cognitoUser,
                    attributes: {
                        // It is not possible to update admins that have a null personnal email address
                        email: payload.personalEmail || user.email,
                    },
                },
            });

            const { success, failure } = yield race({
                success: take(authTypes.AUTH_UPDATE_USER_ATTRIBUTES_SUCCESS),
                failure: take(authTypes.AUTH_UPDATE_USER_ATTRIBUTES_FAILURE),
            });

            if (failure) {
                throw failure.payload;
            } else if (success) {
                yield put({ type: authTypes.AUTH_CURRENT_AUTHENTICATED_USER_REQUEST, payload: { refresh: true } });
            }
        } else {
            // block anyone that's not the employee to change his/her email
            payload.personalEmail = employee.personalEmail;
        }

        const { data } = yield call(HRMClass.hrmUpdateEmployee, payload);

        if (payload.employeeId === user.userId) {
            // update user employee in auth reducer.
            Object.keys(user).forEach((key) => {
                (user as { [index: string]: any })[key] = data[key];
            });
            yield put({ type: authTypes.AUTH_CHANGE_STATE, payload: { user: { ...user } } });
        }

        employees = employees.map((employee) => (employee.userId === payload.employeeId ? data : employee));
        yield put({
            type: types.HRM_UPDATE_EMPLOYEE_SUCCESS,
            payload: {
                employee: { ...employee, ...data },
                employees,
            },
        });
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_UPDATE_EMPLOYEE_FAILURE, payload: error });
        displayAnyError(error);
    }
}

/**
 * @description create a role.
 */
function* hrmCreateRole({ payload }: IHRMActions["hrmCreateRole"]) {
    try {
        const { roles }: { roles: TRole[] } = yield select(({ HRMReducer }: RootState) => ({
            roles: HRMReducer.roles,
        }));

        const found = roles.find((r) => r?.roleName?.toLowerCase() === payload.roleName.toLowerCase());
        if (found) {
            throw new Error(`Role ${payload.roleName} already exists.`);
        }

        const { data } = yield call(HRMClass.hrmCreateRole, payload);
        let role = data;
        yield put({ type: types.HRM_CREATE_ROLE_SUCCESS, payload: role });
        toastHelper.toastStartContent("success", `Role "${payload.roleName}" has been created successfully.`);
        popupHelper.popupEnd();
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_CREATE_ROLE_FAILURE, payload: error });
        displayAnyError(error);
    }
}

/**
 * @description get all roles.
 */
function* hrmGetAllRoles() {
    try {
        const { data } = yield call(HRMClass.hrmGetAllRoles);
        yield put({ type: types.HRM_GET_ALL_ROLES_SUCCESS, payload: data });
    } catch (error: any) {
        console.error(error);
        if (error.code === 404) {
            yield put({ type: types.HRM_GET_ALL_ROLES_SUCCESS, payload: [] });
            return;
        }
        yield put({
            type: types.HRM_GET_ALL_ROLES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get role by id
 */
function* hrmGetRole({ payload }: IHRMActions["hrmGetRole"]) {
    try {
        const { data } = yield call(HRMClass.hrmGetRole, payload);
        yield put({ type: types.HRM_GET_ROLE_BY_ID_SUCCESS, payload: data });
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_GET_ROLE_BY_ID_FAILURE, error });
    }
}

/**
 * @description delete role.
 */
function* hrmDeleteRole({ payload }: IHRMActions["hrmDeleteRole"]) {
    try {
        yield call(HRMClass.hrmDeleteRole, payload);
        let { roles }: { roles: TRole[] } = yield select(({ HRMReducer }: RootState) => ({
            roles: HRMReducer.roles,
        }));
        roles = roles.filter((r) => r.roleId !== payload.roleId);
        yield put({ type: types.HRM_DELETE_ROLE_SUCCESS, payload: roles });
        toastHelper.toastStartContent("success", "Role deleted successfully.");
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_DELETE_ROLE_FAILURE, payload: error });
        displayAnyError(error);
    }
}

/**
 * @description update role.
 */
function* hrmUpdateRole({ payload }: IHRMActions["hrmUpdateRole"]) {
    try {
        yield call(HRMClass.hrmUpdateRole, payload);
        const { roles }: { roles: TRoles } = yield select(({ HRMReducer }: RootState) => ({
            roles: HRMReducer.roles,
        }));
        const _roles = roles.map((_role) => {
            if (_role.roleId === payload.roleId) {
                _role = {
                    ..._role,
                    ...payload.role,
                };
            }
        });
        yield put({ type: types.HRM_UPDATE_ROLE_SUCCESS, payload: _roles });
    } catch (error: any) {
        yield put({ type: types.HRM_UPDATE_ROLE_FAILURE });
        displayAnyError(error);
    }
}

/**
 * @description update employee role.
 */
function* hrmUpdateEmployeeRole({ payload }: IHRMActions["hrmUpdateEmployeeRole"]) {
    try {
        let { employee, roles }: { employee: TEmployee; roles: TRoles } = yield select(({ HRMReducer }: RootState) => ({
            employee: HRMReducer.employee,
            roles: HRMReducer.roles,
        }));
        yield call(HRMClass.hrmUpdateEmployeeRole, payload);
        const role: TRole = roles.find((r) => r.roleId === payload.roleId) as TRole;
        const updatedEmployee = { ...employee, roleId: role.roleId, roleName: role.roleName };
        yield put({ type: types.HRM_UPDATE_EMPLOYEE_ROLE_SUCCESS, payload: { employee: updatedEmployee } });
    } catch (error: any) {
        yield put({ type: types.HRM_UPDATE_EMPLOYEE_ROLE_FAILURE });
    }
}

/**
 * @description Validate role.
 */
function* hrmValidateRole() {
    try {
        yield call(HRMClass.hrmValidateRole);
        yield put({ type: types.HRM_VALIDATE_ROLES_SUCCESS });
    } catch (error: any) {
        yield put({ type: types.HRM_VALIDATE_ROLES_FAILURE });
    }
}

/**
 * @description delete role.
 */
function* hrmResendEmployeeInvitation({ payload }: IHRMActions["hrmResendEmployeeInvitation"]) {
    try {
        yield call(HRMClass.hrmResendEmployeeInvitation, payload);

        yield put({ type: types.HRM_RESEND_EMPLOYEE_INVITATION_SUCCESS });
        toastHelper.toastStartContent("success", "Invitation was sent successfully.");
        popupHelper.popupEnd();
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_RESEND_EMPLOYEE_INVITATION_FAILURE, payload: error });
        displayAnyError(error);
    }
}

/**
 * @description reset permissions
 */
function* hrmResetPermissions({ payload }: IHRMActions["hrmResetPermissions"]) {
    try {
        yield call(HRMClass.hrmResetPermissions, payload);

        yield put({ type: types.HRM_RESET_PERMISSIONS_SUCCESS });
        toastHelper.toastStartContent("success", "Permissions reset successfully.");
    } catch (error: any) {
        console.error(error);
        yield put({ type: types.HRM_RESET_PERMISSIONS_FAILURE, payload: error });
        displayAnyError(error);
    }
}

/**
 * @description verify permissions
 */
function* hrmVerifyPermissions() {
    try {
        yield call(HRMClass.hrmVerifyPermissions);
        yield put({ type: types.HRM_VERIFY_PERMISSIONS_SUCCESS });
    } catch (error: any) {
        yield put({ type: types.HRM_VERIFY_PERMISSIONS_FAILURE, payload: error });
    }
}

export default function* HRMSaga() {
    yield takeLatest(types.HRM_CREATE_EMPLOYEE_REQUEST, hrmCreateEmployee);
    yield takeLatest(types.HRM_GET_ALL_EMPLOYEES_REQUEST, hrmGetAllEmployees);
    yield takeLatest(types.HRM_GET_EMPLOYEE_BY_ID_REQUEST, hrmGetEmployee);
    yield takeLatest(types.HRM_DELETE_EMPLOYEE_REQUEST, hrmDeleteEmployee);
    yield takeLatest(types.HRM_RESET_EMPLOYEE_PASSWORD_REQUEST, hrmResetEmployeePassword);
    yield takeLatest(types.HRM_UPDATE_EMPLOYEE_REQUEST, hrmUpdateEmployee);
    yield takeLatest(types.HRM_GET_EMPLOYEE_ACTIVITIES_REQUEST, hrmGetEmployeeActivities);

    yield takeLatest(types.HRM_CREATE_ROLE_REQUEST, hrmCreateRole);
    yield takeEvery(types.HRM_GET_ALL_ROLES_REQUEST, hrmGetAllRoles);
    yield takeEvery(types.HRM_GET_ROLE_BY_ID_REQUEST, hrmGetRole);
    yield takeLatest(types.HRM_DELETE_ROLE_REQUEST, hrmDeleteRole);
    yield takeLatest(types.HRM_UPDATE_ROLE_REQUEST, hrmUpdateRole);
    yield takeLatest(types.HRM_RESET_PERMISSIONS_REQUEST, hrmResetPermissions);
    yield takeLatest(types.HRM_UPDATE_EMPLOYEE_ROLE_REQUEST, hrmUpdateEmployeeRole);
    yield takeLatest(types.HRM_VALIDATE_ROLES_REQUEST, hrmValidateRole);
    yield takeLatest(types.HRM_VERIFY_PERMISSIONS_REQUEST, hrmVerifyPermissions);

    yield takeLatest(types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST, hrmGetAllEmployeeSchedules);
    // yield takeLatest(types.HRM_GET_ALL_SCHEDULES_TEMPLATE_REQUEST, hrmGetAllScheduleTemplates);
    // yield takeEvery(types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_REQUEST, hrmGetEmployeeSchedules);
    // // yield takeEvery(types.HRM_GET_TEMPLATE_BY_ID_REQUEST, getScheduleTemplate);
    // yield takeLatest(types.HRM_UPDATE_TEMPLATE_REQUEST, hrmUpdateTemplate);
    // yield takeLatest(types.HRM_DELETE_TEMPLATE_REQUEST, hrmDeleteTemplate);
    // yield takeLatest(types.HRM_CREATE_SCHEDULE_REQUEST, hrmCreateSchedule);
    // yield takeLatest(types.HRM_UPDATE_SCHEDULE_REQUEST, hrmUpdateSchedule);
    // yield takeLatest(types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_REQUEST, hrmUpdateScheduleWithTemplate);
    // yield takeLatest(types.HRM_DELETE_SCHEDULE_REQUEST, hrmDeleteSchedule);
    // yield takeLatest(types.HRM_CREATE_TEMPLATE_REQUEST, hrmCreateTemplate);
    yield takeLatest(types.HRM_GET_ALL_PAYROLLS_REQUEST, hrmGetAllPayrolls);
    yield takeLatest(types.HRM_UPDATE_PAYROLL_REQUEST, hrmUpdatePayroll);
    // yield takeLatest(types.HRM_ASSIGN_TEMPLATE_REQUEST, hrmAssignTemplate);
    // yield takeLatest(types.HRM_CHECK_COLLISIONS_REQUEST, hrmCheckCollisions);
    yield takeLatest(types.HRM_UPDATE_EMPLOYEE_SCHEDULE_REQUEST, hrmUpdateEmployeeSchedule);
    yield takeLatest(types.HRM_GET_EMPLOYEE_SCHEDULE_REQUEST, hrmGetEmployeeSchedule);

    yield takeLatest(types.HRM_RESEND_EMPLOYEE_INVITATION_REQUEST, hrmResendEmployeeInvitation);
}

import { FC } from "react";
import loadable from "@loadable/component";
import { Switch, Redirect } from "react-router-dom";
import pMinDelay from "p-min-delay";
import AppFallback from "../components/app.fallback/app.fallback";

// Custom Routers
import PrivateRoute from "./private.route";
import NormalRoute from "./normal.route";
// import NotFound from "../pages/404/404";

import AuthRoutes from "../../applications/auth/routes/auth.routes";
import useHeaderChangeAppName from "../layout/header/hooks/useHeaderChangeAppName";
import InvoicePDF from "../../applications/sales/components/invoice.pdf/invoice.pdf";

import useRolePermissions from "../../applications/auth/hooks/useRolePermissions";
import { RolePermissions } from "../../applications/hrm/hrm";


// const AuthRoutes = loadable(() => import("../../applications/auth/routes/auth.routes"));

// const NotFound = loadable(() => import("../pages/404/404"), {
//     fallback: <AppFallback />,
// });

// import DashboardRoutes from "../../applications/dashboard/routes/dashboard.routes";
const DashboardRoutes = loadable(() => import("../../applications/dashboard/routes/dashboard.routes"), {
    fallback: <AppFallback />,
});

//How long to await to show the component (this is not delaying the import, this is just for the layout to fit better to all lazy components)
const _DELAY_COMPONENT = 2000;

// import WebsiteRoutes from "../../applications/website/routes/website.routes";
const WebsiteRoutes = loadable(() => pMinDelay(import("../../applications/website/routes/website.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// import ProjectRoutes from "../../applications/project/routes/project.routes";
// const ProjectRoutes = loadable(() => pMinDelay(import("../../applications/project/routes/project.routes"), _DELAY_COMPONENT), {
//     fallback: <AppFallback start={true} />,
// });

// import EmailRoutes from "../../applications/email/routes/email.routes";
// const EmailRoutes = loadable(() => pMinDelay(import("../../applications/email/routes/email.routes"), _DELAY_COMPONENT), {
//     fallback: <AppFallback />,
// });

// import CalendarRoutes from "../../applications/calendar/routes/calendar.routes";

// import ChatRoutes from "../../applications/chat/routes/chat.routes";

// import CRMRoutes from "../../applications/crm/routes/crm.routes";
const ContactsRoutes = loadable(() => pMinDelay(import("../../applications/contacts/routes/contacts.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// import AccountSettings from "../../applications/settings/routes/settings.routes";
const AccountSettings = loadable(() => pMinDelay(import("../../applications/settings/routes/settings.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// import NavigationsRoute from "./navigations.route";
const NavigationsRoute = loadable(() => pMinDelay(import("./navigations.route"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// import StorageRoutes from "../../applications/storage/routes/storage.routes";
// import HRMRoutes from "../../applications/hrm/routes/hrm.routes";
const TeamRoutes = loadable(() => pMinDelay(import("../../applications/hrm/routes/hrm.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// import AccountingRoutes from "../../applications/accounting/routes/accounting.routes";
const InventoryRoutes = loadable(() => pMinDelay(import("../../applications/inventory/routes/inventory.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// import ServiceRoutes from "../../applications/service/routes/service.routes";
const ServiceRoutes = loadable(() => pMinDelay(import("../../applications/service/routes/service.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

const AppointmentRoutes = loadable(() => pMinDelay(import("../../applications/appointment/routes/appointment.routes"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

// const SalesRoutes = loadable(() => pMinDelay(import("../../applications/sales/routes/sales.routes"), _DELAY_COMPONENT), {
//     fallback: <AppFallback start={true} />,
// });

// import NotAuthorized from "../../applications/auth/pages/403/403";
const NotAuthorized = loadable(() => pMinDelay(import("../../applications/auth/pages/403/403"), _DELAY_COMPONENT), {
    fallback: <AppFallback start={true} />,
});

interface IRoutes {}

/**
 * @description this is the main routes for the main application src/app.
 * @observe use :module route param when you are creating an application that is inside
 * a module.
 * AVAILABLE : collaboration, marketing, sales, administration.
 * use ./navigation.js file to set your routes in the navbar and sidebar.
 */
const Routes: FC<IRoutes> = () => {
    // change header based on route.
    useHeaderChangeAppName();

    const permissions = useRolePermissions() as RolePermissions;

    return (
        <Switch>
            {/* Authentication Routes. */}
            <NormalRoute
                component={AuthRoutes}
                path={[
                    "/login",
                    "/forgot-password",
                    "/forgot-password-code",
                    "/forgot-password-reset",
                    "/sign-up",
                    "/phone-validation",
                    "/phone-validation-code",
                    "/add-info",
                    "/create-organization",
                    "/employee-registration",
                    "/employee-addinfo",
                ]}
            />

            {/* Organization block view. */}
            <PrivateRoute component={NotAuthorized} path={"/organization/403"} clearLayout={true} />

            {/* Invoice view */}
            <PrivateRoute component={InvoicePDF} path={"/sales/invoice/:id"} clearLayout={true} />

            {/* Dashboard Routes */}
            <PrivateRoute component={DashboardRoutes} path={"/dashboard"} />

            {/* Chat Routes */}
            {/* <PrivateRoute component={ChatRoutes} path={"/:module/chat"} /> */}

            {/* Email Routes */}
            {/* <PrivateRoute
                render={() => <EmailRoutes permissions={permissions?.emails} />}
                path={[
                    "/:module/email/401",
                    "/:module/email/102",
                    "/:module/email/configuration/mailbox/blocked",
                    "/:module/email/configuration/mailbox",
                    "/:module/email/:view",
                    "/:module/email/:view/:exchangeId",
                    "/:module/email",
                ]}
            /> */}

            {/* Calendar Routes */}
            {/* <PrivateRoute component={CalendarRoutes} path={"/:module/calendar"} /> */}

            {/* Account Settings Routes */}
            <PrivateRoute
                render={() => <AccountSettings permissions={permissions} />}
                path={[
                    "/settings/search-domain",
                    "/settings/register-domain",
                    "/settings/organization",
                    "/settings/organization/:view",
                    "/settings/billings",
                    "/settings/billings/invoice",
                    "/settings/profile",
                    // "/settings/permissions",
                ]}
            />

            {/* Website Routes */}
            <PrivateRoute 
                render={() => <WebsiteRoutes />}
                path={["/:module/website", "/:module/website/themes", "/:module/website/infos", "/:module/website/themes/:themeId"]} 
            />

            {/* Project Routes */}
            {/* <PrivateRoute
                component={ProjectRoutes}
                path={["/:module/project/team/taskslist/tasks", "/:module/project/team/taskslist", "/:module/project/personal", "/:module/project/team", "/:module/project"]}
            /> */}

            {/* Storage Routes */}
            {/* <PrivateRoute component={StorageRoutes} path={["/:module/storage/:type", "/:module/storage"]} /> */}

            {/* Inventory Routes */}
            <PrivateRoute 
                render={() => <InventoryRoutes permissions={permissions?.inventory} />} 
                path={[
                    "/:module/inventory/overview",
                    "/:module/inventory/products", 
                    "/:module/inventory/products/:productId", 
                    "/:module/inventory/equipments", 
                    "/:module/inventory/equipments/:equipmentId",
                    "/:module/inventory/equipments/:equipmentId/:view",
                    "/:module/inventory/products/:productId/:view",
                    "/:module/inventory",
                ]} 
            />

            {/* Sales Routes */}
            {/* <PrivateRoute
                render={() => <SalesRoutes permissions={permissions?.sales} />}
                path={[
                    "/:module/sales/overview",
                    "/:module/sales/invoices",
                    "/:module/sales/invoices/create",
                    "/:module/sales/invoices/:invoiceId",
                    "/:module/sales/transactions",
                    "/:module/sales/payments/:transactionId",
                    "/:module/sales/payments",
                    "/:module/sales/refunds",
                    "/:module/sales/refunds/:invoiceId",
                    "/:module/sales/settings/:settingsView",
                    "/administration/sales/settings/:settingsView/:view",
                    "/:module/sales/settings",
                    "/:module/sales/invoices ",
                    "/:module/sales",
                ]}
            /> */}

            {/* Service Routes */}
            <PrivateRoute
                component={ServiceRoutes}
                path={[
                    "/:module/services/categories",
                    "/:module/services/categories/:categoryId/new",
                    "/:module/services/categories/:categoryId",
                    "/:module/services/categories/:categoryId/:serviceId",
                    "/:module/services/categories/:categoryId/:serviceId/configuration",
                    "/:module/services",
                ]}
            />

            {/* Appointment Routes */}
            <PrivateRoute
                render={() => <AppointmentRoutes permissions={permissions} />}
                path={[
                    // "/:module/calendar/event",
                    "/:module/calendar/appointments",
                    "/:module/calendar/:type/:calendarView/:aptDate",
                    "/:module/calendar/:type",
                ]}
            />

            {/* Team Routes */}
            <PrivateRoute
                render={() => <TeamRoutes permissions={permissions?.hrm} />}
                path={[
                    "/:module/team",
                    "/:module/team/:type/roles",
                    "/:module/team/:type/create",
                    "/:module/team/:type",
                    "/:module/team/:type/:employeeId/:profileView",
                    "/:module/team/:type/:employeeId",
                    "/:module/team/401",
                ]}
            />
            {/* Clients Routes */}
            <PrivateRoute
                render={() => <ContactsRoutes permissions={permissions?.crm} />}
                path={[
                    "/:module/contacts/leads",
                    "/:module/contacts/clients",
                    "/:module/contacts/suppliers",
                    "/:module/contacts/:entitiesRoute/:entityId",
                    // "/sales/contacts/clients/:entityId",
                    // "/sales/contacts/contacts/:entityId",
                ]}
            />

            {/* Accounting Routes */}
            {/* <PrivateRoute
                component={AccountingRoutes}
                path={["/:module/accounting/dashboard", "/:module/accounting/invoice"]}
            /> */}

            <NormalRoute path={"/"}>
                <Redirect to="/login" />
            </NormalRoute>

            {/* Navigations Route start. 
                Redirect routes to dashboard if no route was found.
            */}
            <PrivateRoute component={NavigationsRoute} path={"*"} />

            {/* <Route path="*" component={NotFound} /> */}
        </Switch>
    );
};

export default Routes;

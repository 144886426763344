export const types = {
    WEBSITE_CHANGE_STATE: "WEBSITE_CHANGE_STATE",

    WEBSITE_GET_THEMES_REQUEST: "WEBSITE_GET_THEMES_REQUEST",
    WEBSITE_GET_THEMES_SUCCESS: "WEBSITE_GET_THEMES_SUCCESS",
    WEBSITE_GET_THEMES_FAILURE: "WEBSITE_GET_THEMES_FAILURE",

    WEBSITE_GET_THEME_PAGES_REQUEST: "WEBSITE_GET_THEME_PAGES_REQUEST",
    WEBSITE_GET_THEME_PAGES_SUCCESS: "WEBSITE_GET_THEME_PAGES_SUCCESS",
    WEBSITE_GET_THEME_PAGES_FAILURE: "WEBSITE_GET_THEME_PAGES_FAILURE",

    CONTACT_FORM_GET_MESSAGES_REQUEST: "CONTACT_FORM_GET_MESSAGES_REQUEST",
    CONTACT_FORM_GET_MESSAGES_SUCCESS: "CONTACT_FORM_GET_MESSAGES_SUCCESS",
    CONTACT_FORM_GET_MESSAGES_FAILURE: "CONTACT_FORM_GET_MESSAGES_FAILURE",

    CONTACT_FORM_GET_MESSAGE_REQUEST: "CONTACT_FORM_GET_MESSAGE_REQUEST",
    CONTACT_FORM_GET_MESSAGE_SUCCESS: "CONTACT_FORM_GET_MESSAGE_SUCCESS",
    CONTACT_FORM_GET_MESSAGE_FAILURE: "CONTACT_FORM_GET_MESSAGE_FAILURE",

    CONTACT_FORM_UPDATE_MESSAGE_REQUEST: "CONTACT_FORM_UPDATE_MESSAGE_REQUEST",
    CONTACT_FORM_UPDATE_MESSAGE_SUCCESS: "CONTACT_FORM_UPDATE_MESSAGE_SUCCESS",
    CONTACT_FORM_UPDATE_MESSAGE_FAILURE: "CONTACT_FORM_UPDATE_MESSAGE_FAILURE",

    CONTACT_FORM_DELETE_MESSAGE_REQUEST: "CONTACT_FORM_DELETE_MESSAGE_REQUEST",
    CONTACT_FORM_DELETE_MESSAGE_SUCCESS: "CONTACT_FORM_DELETE_MESSAGE_SUCCESS",
    CONTACT_FORM_DELETE_MESSAGE_FAILURE: "CONTACT_FORM_DELETE_MESSAGE_FAILURE",
};

import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import "./error.boundary.styles.scss";

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        // if move route pathname move history.
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(() => ({ hasError: false }));
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error__boundary p-20">
                    <h3
                        style={{
                            textAlign: "center",
                        }}
                        className="text-primary mb-20">
                        Something went wrong :(
                    </h3>
                    <p
                        className="text-geyser-700 text-15"
                        style={{
                            textAlign: "center",
                        }}>
                        Looks like you have a connection problem, please make sure you are connected to the internet to continue.
                    </p>

                    <small className="d-block text-geyser-500 text-12 mt-20">If the error persits contact Soltivo Team.</small>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);

import React from "react";
import Button, { ButtonProps } from "@soltivo/draw-a-line/core/components/buttons/button/button";
import "./empty.block.styles.scss";

interface EmptyBlockProps {
    title?: string;
    svg?: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    description?: string;
    button?: ButtonProps & { value: string };
    /**
     * @description used inside a column.
     */
    column?: boolean;
    /**
     * @description if empty should display as row or column. (flexbox logic)
     * @default "column"
     */
    type?: "row" | "column";
    svgProps?: React.HTMLAttributes<SVGElement>;
    /**
     * @description always use 12 columns inside its container.
     */
    fill?: boolean;
}

/**
 * @description this component was created to display a message with an ilustration, and it requires to have
 * a full parent height of the screen, else if may not look good and you will need to either add an new prop
 * to the component or adjust your application.
 */
const EmptyBlock: React.FC<EmptyBlockProps> = ({ title, svg, description, button, fill = false, column, type = "column", svgProps }) => {
    const Svg = svg;

    return (
        <div aria-label="empty-block" id="empty__block" className={`container ${column ? "empty__block--column" : ""}`}>
            <div className="empty__block--container row">
                {type === "column" ? (
                    <div className={`col-12 col-lg-8 mx-auto ${`eb__${type}`}`} data-testid="container">
                        {Svg ? <Svg className="eb__svg" width="100%" data-testid="svg" /> : null}

                        {title ? (
                            <span className="eb__title" data-testid="title">
                                {title}
                            </span>
                        ) : null}

                        {description ? (
                            <p className="eb__description" data-testid="description">
                                {description}
                            </p>
                        ) : null}

                        {button ? (
                            <div className="empty__block-actions">
                                <Button className="eb__button" variant="primary" outline border={false} {...button}>
                                    {button?.value}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className={`col-12  mx-auto ${!fill ? "col-lg-8 col-xxl-6" : ""} ${`eb__${type}`}`} data-testid="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="eb__svg-container">
                                    {Svg ? <Svg {...svgProps} className={`eb__svg ${svgProps?.className ? svgProps?.className : ""}`} width="100%" data-testid="svg" /> : null}
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="eb__content">
                                    {title ? (
                                        <span className="eb__title" data-testid="title">
                                            {title}
                                        </span>
                                    ) : null}

                                    {description ? (
                                        <p className="eb__description" data-testid="description">
                                            {description}
                                        </p>
                                    ) : null}

                                    {button ? (
                                        <div className="empty__block-actions">
                                            <Button className="eb__button" variant="primary" outline border={false} {...button}>
                                                {button?.value}
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmptyBlock;

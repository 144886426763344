import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@soltivo/draw-a-line";
import "./step.employee.services.scss";
import { RootState } from "../../../../../../redux/reducers";
import { ArrowRight24Px, ArrowDropDown24Px } from "@soltivo/draw-a-line/core/components/icons";
import GuideOrgContext from "../../../context/guide.org.context";
import { guideOrgChangeFormState, guideChangeAction } from "../../../context/actions";
import useGuideHeading from "../../../hooks/useGuideHeading";
import { orgSetup } from "../../../../../../applications/auth/redux/org/reducer/actions";
import { StepEmployeesView } from "../step.employees/step.employees";
import { User } from "../../../../../../applications/auth/auth";
import { Employee } from "@soltivo/types";

export interface EmployeeServicesProps {
    user: User;
    employee: Employee | User;
    onChangeView: (view: StepEmployeesView) => void;
}

type Category = {
    id: string;
    name: string;
    services: {
        id: string;
        title: string;
        price: string;
    }[];
};
const EmployeeServices: React.FC<EmployeeServicesProps> = ({ user, employee, onChangeView }) => {
    const isUser = user.userId === employee.userId;

    const dispatch = useDispatch();

    const mapStateToProps = ({ ServiceReducer }: RootState) => ({
        allServices: ServiceReducer.allServices,
        categories: ServiceReducer.categories,
    });
    const { allServices, categories } = useSelector(mapStateToProps);

    const list: Category[] = categories.map((category) => {
        return {
            id: category.id,
            name: category.name,
            services: allServices
                .filter((service) => service.category === category.id)
                .map((service) => ({
                    id: service.id,
                    title: service.title,
                    price: `$${(service.price / 100).toLocaleString("en-CA", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}`,
                })),
        };
    });

    const {
        state: { form },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    const [activeCategory, setActiveCategory] = useState<string>();

    useGuideHeading({
        title: isUser ? `Your services` : `${employee.firstName}'s services`,
        description: isUser ? `Check all the service you are offering inside our organization.` : `Check all the service ${employee.firstName} is offering inside our organization.`,
    });

    const onCheckService = (e: React.ChangeEvent<HTMLInputElement>, categoryId: string, serviceId: string) => {
        const checked = e.target.checked;
        if (checked) {
            dispatchContext(
                guideOrgChangeFormState({
                    employeeServices: [...form.employeeServices, { categoryId, serviceId }],
                })
            );
        } else {
            dispatchContext(
                guideOrgChangeFormState({
                    employeeServices: [...form.employeeServices.filter((s) => s.serviceId !== serviceId)],
                })
            );
        }
    };

    const toggle = (category: Category) => {
        if (category.id === activeCategory) {
            setActiveCategory(undefined);
        } else {
            setActiveCategory(category.id);
        }
    };

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "back",
                payload: {
                    onClick: () => {
                        onChangeView("list");
                    },
                },
            })
        );
    }, []);

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    onClick: () => {
                        dispatch(
                            orgSetup({
                                currentSubStep: "employeeServices",
                                nextSubStep: "list",
                                data: {
                                    employeeServices: {
                                        employeeId: employee.userId,
                                        services: form.employeeServices,
                                    },
                                },
                            })
                        );
                    },
                    disabled: false,
                    value: !form.employeeServices.length ? "Skip" : "Next",
                },
            })
        );
    }, [form.employeeServices.length, allServices.length, employee]);

    useEffect(() => {
        return () => {
            dispatchContext(
                guideOrgChangeFormState({
                    employeeServices: [],
                })
            );
        };
    }, []);

    return (
        <div id="setup__employee__services">
            {list.map((category, index) => (
                <div key={index} className="category">
                    <div className="header" onClick={() => toggle(category)}>
                        {activeCategory === category.id ? <ArrowDropDown24Px className="arrow-icon" /> : <ArrowRight24Px className="arrow-icon" />}
                        <p>{category.name}</p>
                    </div>
                    {activeCategory === category.id ? (
                        <div className="services-list">
                            {category.services?.length
                                ? category.services.map((service, index) => (
                                      <label htmlFor={service.id} key={index} className="service-item">
                                          <Checkbox
                                              id={service.id}
                                              checked={form.employeeServices.map((item) => item.serviceId).includes(service.id)}
                                              className="check-input"
                                              onChange={(e) => onCheckService(e, category.id, service.id)}
                                          />
                                          {`${service.title} - ${service.price}`}
                                      </label>
                                  ))
                                : null}
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default EmployeeServices;

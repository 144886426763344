import { Gregorian } from "../../helpers/unix-time";

const { ONE_DAY_TIME } = Gregorian;

const timeToMilliseconds = (time) => {
    if (typeof time === "string") {
        const date = new Date(time);
        return date.getTime();
    }

    if (typeof time === "number") {
        return time;
    }

    if (time instanceof Date) {
        return time.getTime();
    }
};

export const MONTH_LABELS = Object.freeze(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);

const MONTH_NAMES = Object.freeze(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);

const MONTH_DAY_LABELS = Object.freeze([
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
    "29th",
    "30th",
    "31st",
]);

const TWELVE_HOUR_TIME = (milliseconds) => {
    const seconds = milliseconds / 1000;
    const minutes = (seconds / 60) | 0;
    const hours = (minutes / 60) | 0;

    const remainderMinutes = minutes % 60 | 0;
    let remainderHours = hours % 24 | 0;
    const letter = remainderHours >= 12 ? "P" : "A";
    remainderHours = hours % 12 | 0;

    remainderHours = remainderHours === 0 ? 12 : remainderHours;

    const minutesLabel = remainderMinutes.toString().padStart(2, "0");
    const hoursLabel = remainderHours.toString().padStart(2, "0");

    return `${hoursLabel}:${minutesLabel} ${letter}M`;
};

export const TWENTY_FOUR_HOUR_TIME = (milliseconds) => {
    // military time

    const seconds = milliseconds / 1000;
    const minutes = (seconds / 60) | 0;
    const hours = (minutes / 60) | 0;

    const remainderMinutes = minutes % 60 | 0;

    const stringMinutes = remainderMinutes.toString().padStart(2, "0");
    const stringHours = hours.toString().padStart(2, "0");

    return `${stringHours}:${stringMinutes}`;
};

export const formatAbsoluteDay = (time) => {
    const milliseconds = timeToMilliseconds(time);

    const yearNumber = Gregorian.yearNumber(milliseconds);

    const [monthNumber] = Gregorian.monthData(milliseconds);

    const monthDay = Gregorian.monthDay(milliseconds);

    const yearLabel = yearNumber.toString();
    const monthLabel = MONTH_NAMES[monthNumber];
    const dayLabel = MONTH_DAY_LABELS[monthDay];

    return `${monthLabel}, ${dayLabel} - ${yearLabel}`;
};

/**
 * @description Format the date/time to a format relative to the 'today' time
 * @param { Date | number | string } time
 * @param { Date | number | string } today
 * @returns {{formatedDate}}
 */
export const formatRelativeDate = (time, today) => {
    const milliseconds = timeToMilliseconds(time);

    today = today ? timeToMilliseconds(today) : Date.now();

    today = Gregorian.startDay(today);

    let difference = today - milliseconds;

    if (difference <= 0) {
        // future data

        difference = -difference;

        if (difference < ONE_DAY_TIME) {
            return TWELVE_HOUR_TIME(difference);
        } else {
            // time excessively in the future

            return `FUTURE (${milliseconds})`;
        }
    } else {
        // past data

        const yearNumberA = Gregorian.yearNumber(today);
        const yearNumberB = Gregorian.yearNumber(milliseconds);

        if (yearNumberA === yearNumberB) {
            // "MMM. DD - hh:mm A"

            const [monthNumber, monthDay] = Gregorian.monthData(milliseconds);
            const dayMilliseconds = milliseconds % ONE_DAY_TIME | 0;

            const monthLabel = MONTH_LABELS[monthNumber];
            const dayLabel = monthDay.toString().padStart(2, "0");

            const clockLabel = TWELVE_HOUR_TIME(dayMilliseconds);

            return `${monthLabel}. ${dayLabel} - ${clockLabel}`;
        } else {
            // "MMM. DD. YYYY - hh:mm A"

            const [monthNumber, monthDay] = Gregorian.monthData(milliseconds);
            const dayMilliseconds = milliseconds % ONE_DAY_TIME | 0;

            const yearLabel = yearNumberB.toString();
            const monthLabel = MONTH_LABELS[monthNumber];
            const dayLabel = monthDay.toString().padStart(2, "0");

            const clockLabel = TWELVE_HOUR_TIME(dayMilliseconds);

            return `${monthLabel}. ${dayLabel}, ${yearLabel} - ${clockLabel}`;
        }
    }
};

export const formatTwelveHour = (time) => {
    const milliseconds = timeToMilliseconds(time);

    return TWELVE_HOUR_TIME(milliseconds);
};

export const formatDigitalClock = (time) => {
    const milliseconds = timeToMilliseconds(time);

    const seconds = milliseconds / 1000;
    const minutes = (seconds / 60) | 0;
    const hours = (minutes / 60) | 0;

    const remainderSeconds = seconds % 60 | 0;
    const remainderMinutes = minutes % 60 | 0;

    const stringSeconds = remainderSeconds.toString().padStart(2, "0");
    const stringMinutes = remainderMinutes.toString().padStart(2, "0");
    const stringHours = hours.toString().padStart(2, "0");

    if (hours > 0) {
        return `${stringHours}:${stringMinutes}:${stringSeconds}`;
    }

    return `${stringMinutes}:${stringSeconds}`;
};

const TimeFormatter = Object.freeze({
    formatAbsoluteDay,
    formatRelativeDate,
    formatTwelveHour,
    formatDigitalClock,
});

export default TimeFormatter;

export const types = {
    DOMAIN_CHANGE_STATE: "DOMAIN_CHANGE_STATE",

    DOMAIN_GET_DOMAIN_INFO_REQUEST: "DOMAIN_GET_DOMAIN_INFO_REQUEST",
    DOMAIN_GET_DOMAIN_INFO_SUCCESS: "DOMAIN_GET_DOMAIN_INFO_SUCCESS",
    DOMAIN_GET_DOMAIN_INFO_FAILURE: "DOMAIN_GET_DOMAIN_INFO_FAILURE",

    DOMAIN_CHECK_AVAILABILITY_REQUEST: "DOMAIN_CHECK_AVAILABILITY_REQUEST",
    DOMAIN_CHECK_AVAILABILITY_SUCCESS: "DOMAIN_CHECK_AVAILABILITY_SUCCESS",
    DOMAIN_CHECK_AVAILABILITY_FAILURE: "DOMAIN_CHECK_AVAILABILITY_FAILURE",

    DOMAIN_CREATE_SETUP_INTENT_REQUEST: "DOMAIN_CREATE_SETUP_INTENT_REQUEST",
    DOMAIN_CREATE_SETUP_INTENT_SUCCESS: "DOMAIN_CREATE_SETUP_INTENT_SUCCESS",
    DOMAIN_CREATE_SETUP_INTENT_FAILURE: "DOMAIN_CREATE_SETUP_INTENT_FAILURE",

    DOMAIN_SUBSCRIBE_DOMAIN_REQUEST: "DOMAIN_SUBSCRIBE_DOMAIN_REQUEST",
    DOMAIN_SUBSCRIBE_DOMAIN_SUCCESS: "DOMAIN_SUBSCRIBE_DOMAIN_SUCCESS",
    DOMAIN_SUBSCRIBE_DOMAIN_FAILURE: "DOMAIN_SUBSCRIBE_DOMAIN_FAILURE",

    DOMAIN_SUBSCRIBE_CONFIRM_REQUEST: "DOMAIN_SUBSCRIBE_CONFIRM_REQUEST",
    DOMAIN_SUBSCRIBE_CONFIRM_SUCCESS: "DOMAIN_SUBSCRIBE_CONFIRM_SUCCESS",
    DOMAIN_SUBSCRIBE_CONFIRM_FAILURE: "DOMAIN_SUBSCRIBE_CONFIRM_FAILURE",

    DOMAIN_RENEW_FLAG_REQUEST: "DOMAIN_RENEW_FLAG_REQUEST",
    DOMAIN_RENEW_FLAG_SUCCESS: "DOMAIN_RENEW_FLAG_SUCCESS",
    DOMAIN_RENEW_FLAG_FAILURE: "DOMAIN_RENEW_FLAG_FAILURE",

    DOMAIN_UPDATE_INFOS_REQUEST: "DOMAIN_UPDATE_INFOS_REQUEST",
    DOMAIN_UPDATE_INFOS_SUCCESS: "DOMAIN_UPDATE_INFOS_SUCCESS",
    DOMAIN_UPDATE_INFOS_FAILURE: "DOMAIN_UPDATE_INFOS_FAILURE",
};

import { User } from "@soltivo/types";
import { types } from "./types";

export interface AuthState {
    user: User | null;
    loading_current_user: boolean;
    cognitoUser: any | undefined;
    phone_number_verified: boolean;
    error: any;
    changingPassword: boolean;
}

const INITIAL_STATE: AuthState = {
    user: null,
    cognitoUser: undefined,
    loading_current_user: true,
    phone_number_verified: false,
    error: null,
    changingPassword: false,
};

const AuthReducer = (
    state = INITIAL_STATE,
    action: {
        type: string;
        payload: any;
    }
): AuthState => {
    switch (action.type) {
        case types.AUTH_CHANGE_STATE:
            return {
                ...state,
                ...(action.payload as AuthState),
            };

        case types.AUTH_CURRENT_AUTHENTICATED_USER_SUCCESS:
            return {
                ...state,
                cognitoUser: action.payload,
                error: null,
            };

        case types.AUTH_CURRENT_AUTHENTICATED_USER_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
                loading_current_user: false,
            };

        case types.AUTH_PROFILE_INFO_SUCCESS:
            const user = action.payload.user;
            return {
                ...state,
                user: {
                    ...user,
                    fullName: `${user.firstName} ${user.lastName}`,
                    status: "Available", // this will be changed to get from database.
                },
                error: null,
                loading_current_user: false,
                phone_number_verified: action.payload.phone_number_verified,
            };

        case types.AUTH_PROFILE_INFO_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
                loading_current_user: false,
            };

        case types.AUTH_SIGN_IN_USER_SUCCESS:
            return {
                ...state,
                cognitoUser: action.payload,
                error: null,
            };

        case types.AUTH_SIGN_IN_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading_current_user: false,
            };

        case types.AUTH_SIGN_UP_USER_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.AUTH_SIGN_UP_USER_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
            };

        case types.AUTH_CONFIRM_SIGN_UP_SUCCESS:
            return {
                ...state,
                user: null,
                error: null,
            };

        case types.AUTH_CONFIRM_SIGN_UP_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
            };

        case types.AUTH_ON_BOARDING_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null,
            };

        case types.AUTH_ON_BOARDING_USER_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
            };

        case types.AUTH_SIGN_UP_RESEND_CODE_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.AUTH_SIGN_UP_RESEND_CODE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_LOGOUT_USER_SUCCESS:
            return {
                ...state,
                // from now on we will just reload the application
                // moving the user to /login with Location Api.
                // user: null,
            };

        case types.AUTH_LOGOUT_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.AUTH_FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_FORGOT_PASSWORD_SUBMIT_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.AUTH_FORGOT_PASSWORD_SUBMIT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_UPDATE_USER_ATTRIBUTES_SUCCESS:
            return {
                ...state,
            };

        case types.AUTH_UPDATE_USER_ATTRIBUTES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_UPDATE_USER_STATUS_SUCCESS:
            return {
                ...state,
                user: {
                    ...(state.user as User),
                    status: action.payload,
                },
                error: null,
            };

        case types.AUTH_UPDATE_USER_STATUS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_UPDATE_ADMIN_INFO_SUCCESS:
            return {
                ...state,
                user: { ...state.user, ...action.payload.user },
                phone_number_verified: action.payload.phone_number_verified,
                error: null,
            };

        case types.AUTH_UPDATE_ADMIN_INFO_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                phone_number_verified: action.payload.phone_number_verified,
                error: null,
            };

        case types.AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.AUTH_CHANGE_USER_PASSWORD_REQUEST:
            return {
                ...state,
                changingPassword: true,
            };
        case types.AUTH_CHANGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false,
            };
        case types.AUTH_CHANGE_USER_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload,
                changingPassword: false,
            };

        default:
            return state;
    }
};

export default AuthReducer;

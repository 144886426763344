import React, { useContext, useRef } from "react";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
// import Footer from "./footer/footer";
import "./layout.styles.scss";
import { useLocation } from "react-router-dom";
import RoutesContext from "../routes/context/routes/routes.context";
import Loader from "@soltivo/draw-a-line/core/components/loading/loader/loader";
import ErrorBoundary from "../components/error.boundary/error.boundary";
import { HeaderContext } from "./header/reducer/header.context";

interface ILayout {
    children: React.ReactNode;
}
/**
 * @description create a full layout for the main application.
 * @observe this layout is only used when user is loggsed in.
 */
const Layout: React.FC<ILayout> = ({ children }) => {
    const {
        state: { loading },
    } = useContext(RoutesContext);
    const {
        state: { showMainSidebar: showSidebar },
    } = useContext(HeaderContext);

    const sidebar = useRef(null);
    const location = useLocation();
    const isDashboard = location.pathname.toLowerCase().startsWith("/dashboard");
    const isSettings = location.pathname.toLowerCase().startsWith("/settings");

    // useClickOutsideRef([window], () => dispatch(headerSidebarToggleDisplay({ show: false })), sidebar);

    return (
        <div id="layout" className={`${isDashboard || isSettings ? "layout-dashboard light" : ""} ${loading ? "loading__component-layout" : ""}`}>
            <div ref={sidebar} className={`sidebar-container ${showSidebar ? "" : "hide"}`}>
                <Sidebar />
            </div>
            {/* Main Content */}
            <div className={`main ${showSidebar ? "--sidebar-active" : ""}`}>
                <Header />

                <div id="content" className={`content ${loading ? "loading__component" : ""}`}>
                    <ErrorBoundary>{loading ? <Loader size="lg" variant="secondary" /> : children}</ErrorBoundary>
                </div>
            </div>
        </div>
    );
};

export default Layout;
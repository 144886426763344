import { call, put, select, delay } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import { ErrorResponse } from "../../../../../helpers/api.v2";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";
import emailValidation from "../../../validation/email.validation";

/**
 * @description setup email business
 */
export function* emailSetupEmailBusiness({ payload }: EmailActions["emailSetupEmailBusiness"]) {
    try {
        yield delay(1000);
        const mapStateToProps = ({ DomainReducer, AuthReducer }: RootState) => ({
            domainInfo: DomainReducer.domainInfo,
            user: AuthReducer.user
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        const { domainInfo, user }: MapStateToProps = yield select(mapStateToProps);

        yield emailValidation.emailSetupEmailBusiness({
            businessEmail: payload.businessEmail,
            userId: user?.userId as string,
            domainInfo: domainInfo,
        });

        yield call(emailClass.emailSetupEmailBusiness, payload);
        yield put({ type: emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_SUCCESS });
    } catch (error) {
        if (error instanceof ErrorResponse) {
            emailValidation.validation.set([
                {
                    message: error.error.errors[0].message || error.message,
                    fieldName: "businessEmail",
                    formName: emailValidation.formTypes.EMAIL_CREATE_MAILBOX,
                    status: "failed",
                },
            ]);

            yield put({
                type: emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_FAILURE,
                payload: error,
            });
        } else {
            yield put({
                type: emailTypes.EMAIL_SETUP_EMAIL_BUSINESS_FAILURE,
                payload: error,
            });
        }
    }
}

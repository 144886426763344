import { call, put, select } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description delete exchange
 */
export function* emailDeleteExchange({ payload }: EmailActions["emailDeleteExchange"]) {
    try {
        yield call(emailClass.emailDeleteExchange, payload);

        let {
            in_exchanges,
            out_exchanges,
            email,
        }: {
            in_exchanges: RootState["EmailReducer"]["in_exchanges"];
            out_exchanges: RootState["EmailReducer"]["out_exchanges"];
            deleted_exchanges: RootState["EmailReducer"]["deleted_exchanges"];
            email: RootState["EmailReducer"]["email"];
        } = yield select(({ EmailReducer }: RootState) => ({
            in_exchanges: EmailReducer.in_exchanges,
            out_exchanges: EmailReducer.out_exchanges,
            email: EmailReducer.email,
        }));

        in_exchanges.items = in_exchanges.items.filter((item) => item.exchangeId !== payload.exchangeId);
        in_exchanges.lastKey = in_exchanges.lastKey.exchangeId === payload.exchangeId ? {} : in_exchanges.lastKey;

        out_exchanges.items = out_exchanges.items.filter((item) => item.exchangeId !== payload.exchangeId);
        out_exchanges.lastKey = out_exchanges.lastKey.exchangeId === payload.exchangeId ? {} : out_exchanges.lastKey;

        email.items = [];
        email.lastKey = {};

        yield put({
            type: emailTypes.EMAIL_DELETE_EXCHANGE_SUCCESS,
            payload: {
                in_exchanges,
                out_exchanges,
                email,
            },
        });

        toastHelper.toastStartContent("success", "Email moved to trash.");
    } catch (error: any) {
        yield put({
            type: emailTypes.EMAIL_DELETE_EXCHANGE_FAILURE,
            payload: error,
        });
    }
}

import { put, takeEvery, select, takeLatest, call } from "redux-saga/effects";
import PopupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import ToastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import { types } from "../reducer/types";
import { IProject, IStep, ITask, ITaskList } from "../../project";
import { sagaDisplayError } from "./saga.helper";
import popupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import projectClass from "./project.class";

//// PROJECT SAGAS ////

/**
 * @description create a project
 */
function* createProject({
    payload,
}: {
    type: string;
    payload: {
        projectName: string;
        projectDesc: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.CREATE_PROJECT_REQUEST,
    });
    try {
        const { data } = yield call(projectClass.createProject, payload);
        yield put({ type: types.CREATE_PROJECT_SUCCESS, payload: data });
        yield PopupHelper.popupEnd();
        localStorage.removeItem("projectPages"); // Clear pagination items
        yield getProjectsList({ type: types.GET_PROJECT_LIST_REQUEST, payload: undefined });
        yield ToastHelper.toastStartContent("success", `Project created successfully`);
    } catch (error) {
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.CREATE_PROJECT_REQUEST },
        });
        sagaDisplayError(error, [422, 409, 500]);
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.CREATE_PROJECT_REQUEST,
        });
    }
}

/**
 * @description Get projects list
 */
function* getProjectsList({ payload }: { type: string; payload?: string }) {
    yield put({
        type: types.START_LOADING,
        payload: types.GET_PROJECT_LIST_REQUEST,
    });
    try {
        const { data } = yield call(projectClass.getProjectsList, payload);
        yield put({ type: types.GET_PROJECT_LIST_SUCCESS, payload: { items: data.projects, lastKey: data?.lastKey || null } });
    } catch (error: any) {
        if (error?.error?.code === 404) {
            yield put({ type: types.GET_PROJECT_LIST_SUCCESS, payload: { items: [] } });
            return;
        }
        sagaDisplayError(error, [422, 409, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_PROJECT_LIST_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.GET_PROJECT_LIST_REQUEST,
        });
    }
}

/**
 * @description Update project
 */
function* updateProject({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        projectName: string;
        projectDesc?: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.UPDATE_PROJECT_REQUEST,
    });
    try {
        const { projectId } = payload;
        const { data } = yield call(projectClass.updateProject, payload);

        const { projects } = yield select(({ ProjectReducer }) => ({
            projects: ProjectReducer.projects,
        }));
        const updatedList = projects?.items.map((project: IProject) => {
            if (project.projectId === projectId) {
                project = data;
            }
            return project;
        });
        yield put({ type: types.UPDATE_PROJECT_SUCCESS, payload: updatedList });
        yield ToastHelper.toastStartContent("success", `Project updated successfully`);
        yield PopupHelper.popupEnd();
    } catch (error) {
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPDATE_PROJECT_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.UPDATE_PROJECT_REQUEST,
        });
    }
}

/**
 * @description Delete project
 */
function* deleteProject({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.DELETE_PROJECT_REQUEST,
    });
    try {
        yield call(projectClass.deleteProject, payload);
        yield put({ type: types.DELETE_PROJECT_SUCCESS, payload });
        yield put({ type: types.GET_PROJECT_LIST_REQUEST });
        yield PopupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [404, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.DELETE_PROJECT_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.DELETE_PROJECT_REQUEST,
        });
    }
}

///// TASKLIST SAGAS /////

/**
 * @description create a Tasks List
 */
function* createTasksList({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        taskListName: string;
        taskListDesc: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.CREATE_TASKS_LIST_REQUEST,
    });
    try {
        const { data } = yield call(projectClass.createTasksList, payload);
        yield put({ type: types.CREATE_TASKS_LIST_SUCCESS, payload: data });
        yield ToastHelper.toastStartContent("success", `Task list created successfully`);
    } catch (error) {
        yield put({ type: types.RENDER_ERROR, payload });
        sagaDisplayError(error, [422, 409, 500]);
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.CREATE_TASKS_LIST_REQUEST,
        });
    }
}

/**
 * @description Get tasklists of a single project
 */
function* getProjectTaskLists({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        lastKey?: string;
        isLoadingMore: boolean;
    };
}) {
    const { isLoadingMore } = payload;
    if (!isLoadingMore)
        yield put({
            type: types.START_LOADING,
            payload: types.GET_TASKLISTS_REQUEST,
        });
    try {
        const { data } = yield call(projectClass.getProjectTaskLists, payload);
        yield put({ type: types.GET_TASKLISTS_SUCCESS, payload: { ...data, isLoadingMore } });
    } catch (error: any) {
        if (error?.error?.code === 404) {
            yield put({ type: types.GET_TASKLISTS_SUCCESS, payload: { taskList: [] } });
            return;
        }
        sagaDisplayError(error, [422, 409, 500]);
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.GET_TASKLISTS_REQUEST,
        });
    }
}

/**
 * @description Update tasklist
 */
function* updateTaskList({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        taskListId: string;
        taskListName: string;
        taskListDesc?: string;
    };
}) {
    try {
        const { data } = yield call(projectClass.updateTaskList, payload);
        yield put({ type: types.UPDATE_TASKS_LIST_SUCCESS, payload: data });
    } catch (error) {
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPDATE_TASKS_LIST_REQUEST },
        });
    }
}

/**
 * @description Delete tasklist
 */
function* deleteTaskList({
    payload,
}: {
    type: string;
    payload: {
        taskListId: string;
        projectId: string;
    };
}) {
    try {
        yield call(projectClass.deleteTaskList, payload);
        const { tasklists } = yield select(({ ProjectReducer }) => ({
            tasklists: ProjectReducer.tasklists,
        }));
        const updatedList = tasklists.filter((tasklist: ITaskList) => {
            return tasklist.taskListId !== payload.taskListId;
        });
        yield put({ type: types.DELETE_TASKS_LIST_SUCCESS, payload: updatedList });
    } catch (error) {
        sagaDisplayError(error, [404, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.DELETE_TASKS_LIST_REQUEST },
        });
    } finally {
        popupHelper.popupEnd();
    }
}

///// PERSONAL TASKS SAGAS /////

/**
 * @description create personal task
 */
function* createPersonalTask({
    payload,
}: {
    type: string;
    payload: {
        taskName: string;
        dueTo?: number;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.CREATE_PERSONAL_TASK_REQUEST,
    });
    try {
        const { data } = yield call(projectClass.createPersonalTask, payload);
        yield put({ type: types.CREATE_PERSONAL_TASK_SUCCESS, payload: data });
        yield PopupHelper.popupEnd();
    } catch (error) {
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.CREATE_PERSONAL_TASK_REQUEST },
        });
        sagaDisplayError(error, [422, 409, 500]);
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.CREATE_PERSONAL_TASK_REQUEST,
        });
    }
}

/**
 * @description Get personal projects
 */
function* getPersonalTasks({
    payload,
}: {
    type: string;
    payload: {
        isLoadingMore?: boolean;
        lastKey?: string;
    };
}) {
    const { isLoadingMore } = payload;
    if (!isLoadingMore)
        yield put({
            type: types.START_LOADING,
            payload: types.GET_PERSONAL_TASKS_REQUEST,
        });
    try {
        const { data } = yield call(projectClass.getPersonalTasks, payload);
        yield put({ type: types.GET_PERSONAL_TASKS_SUCCESS, payload: data });
    } catch (error: any) {
        if (error?.error?.code === 404) {
            yield put({ type: types.GET_PERSONAL_TASKS_SUCCESS, payload: { tasks: [] } });
            return;
        }
        ToastHelper.toastStartContent("danger", `An error occured. Try again.`);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_PERSONAL_TASKS_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.GET_PERSONAL_TASKS_REQUEST,
        });
    }
}

/**
 * @description Update personal task
 */
function* updatePersonalTask({
    payload,
}: {
    type: string;
    payload: {
        taskId: string;
        taskName: string;
        dueTo?: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.UPDATE_PERSONAL_TASKS_REQUEST,
    });
    try {
        const { data } = yield call(projectClass.updatePersonalTask, payload);
        yield put({ type: types.UPDATE_PERSONAL_TASKS_SUCCESS, payload: data });
        yield ToastHelper.toastStartContent("success", `Task updated successfully`);
        yield PopupHelper.popupEnd();
    } catch (error) {
        ToastHelper.toastStartContent("danger", `An error occured. Try again.`);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPDATE_PERSONAL_TASKS_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.UPDATE_PERSONAL_TASKS_REQUEST,
        });
    }
}

/**
 * @description Delete personal task
 */
function* deletePersonalTask({
    payload,
}: {
    type: string;
    payload: {
        taskId: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.DELETE_PERSONAL_TASK_REQUEST,
    });
    try {
        yield call(projectClass.deletePersonalTask, payload);
        yield put({ type: types.DELETE_PERSONAL_TASK_SUCCESS, payload });
        yield PopupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [404, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.DELETE_PERSONAL_TASK_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.DELETE_PERSONAL_TASK_REQUEST,
        });
    }
}

///// TASKS SAGAS /////

/**
 * @description create a task for team
 */
function* createTask({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        taskListId: string;
        taskName: string;
        taskDesc: string;
        status: string;
        assignedTo?: string;
    };
}) {
    yield put({ type: types.START_LOADING, payload: types.CREATE_TASK_REQUEST });
    try {
        const { data } = yield call(projectClass.createTask, payload);
        yield put({ type: types.CREATE_TASK_SUCCESS, payload: data });
        yield ToastHelper.toastStartContent("success", `Task created successfully`);
        yield PopupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [422, 409, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.CREATE_TASK_REQUEST },
        });
    } finally {
        yield put({ type: types.STOP_LOADING, payload: types.CREATE_TASK_REQUEST });
    }
}

/**
 * @description Get tasks inside a specific project and tasklist
 */
function* getTasks({
    payload,
}: {
    type: string;
    payload: {
        isLoadingMore: boolean;
        projectId: string;
        taskListId: string;
        lastKey?: string;
    };
}) {
    const { isLoadingMore } = payload;
    if (!isLoadingMore) yield put({ type: types.START_LOADING, payload: types.GET_TASKS_REQUEST });
    try {
        const { data } = yield call(projectClass.getTasks, payload);
        yield put({ type: types.GET_TASKS_SUCCESS, payload: { ...data, isLoadingMore } });
    } catch (error: any) {
        if (error?.error?.code === 404) {
            yield put({ type: types.GET_TASKS_SUCCESS, payload: { tasks: [] } });
            return;
        }
        sagaDisplayError(error, [422, 409, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_TASKS_REQUEST },
        });
    } finally {
        yield put({ type: types.STOP_LOADING, payload: types.GET_TASKS_REQUEST });
    }
}

/**
 * @description Get single task
 */
function* getSingleTask({
    payload,
}: {
    type: string;
    payload: {
        isInitial: boolean;
        projectId: string;
        taskListId: string;
        task: {
            taskId: string;
        };
    };
}) {
    const { task, isInitial } = payload;
    yield put({
        type: types.START_LOADING,
        payload: types.GET_SINGLE_TASK_REQUEST,
    });
    try {
        if (isInitial) {
            yield put({ type: types.GET_SINGLE_TASK_SUCCESS, payload: task });
        } else {
            const { data } = yield call(projectClass.getSingleTask, payload);
            yield put({ type: types.GET_SINGLE_TASK_SUCCESS, payload: data });
        }
    } catch (error) {
        sagaDisplayError(error, [422, 409, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_SINGLE_TASK_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.GET_SINGLE_TASK_REQUEST,
        });
    }
}

/**
 * @description Update task
 */
function* updateTask({
    payload,
}: {
    type: string;
    payload: {
        task: FormData;
        params: {
            projectId: string;
            taskListId: string;
            taskId: string;
        };
    };
}) {
    try {
        const { data } = yield call(projectClass.updateTask, payload);
        yield put({ type: types.UPDATE_TASK_SUCCESS, payload: data });
    } catch (error) {
        sagaDisplayError(error, [422, 409, 400, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPDATE_TASK_REQUEST },
        });
    }
}

/**
 * @description Delete task
 */
function* deleteTask({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        taskListId: string;
        taskId: string;
    };
}) {
    yield put({
        type: types.START_LOADING,
        payload: types.DELETE_TASK_REQUEST,
    });
    try {
        const { taskId } = payload;
        yield call(projectClass.deleteTask, payload);

        const { tasks } = yield select(({ ProjectReducer }) => ({
            tasks: ProjectReducer.tasks,
        }));
        const newTasksList = tasks.filter((task: ITask) => task.taskId !== taskId);

        yield put({ type: types.DELETE_TASK_SUCCESS, payload: newTasksList });
        yield PopupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [404, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.DELETE_TASK_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.DELETE_TASK_REQUEST,
        });
    }
}

///// SUBTASKS SAGAS /////

/**
 * @description create a subtask
 */
function* createSubTask({
    payload,
}: {
    type: string;
    payload: {
        subTaskName: string;
        taskId: string;
    };
}) {
    try {
        const { data } = yield call(projectClass.createSubTask, payload);
        yield put({ type: types.CREATE_SUBTASK_SUCCESS, payload: data });
    } catch (error) {
        sagaDisplayError(error, [500, 409, 422]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.CREATE_SUBTASK_REQUEST },
        });
    }
}

/**
 * @description Get all subtasks of a task
 */
function* getSubTasks({
    payload,
}: {
    type: string;
    payload: {
        taskId: string;
        lastKey?: string;
        isLoadingMore?: boolean;
    };
}) {
    const { isLoadingMore } = payload;
    if (!isLoadingMore)
        yield put({
            type: types.START_LOADING,
            payload: types.GET_SUBTASK_REQUEST,
        });
    try {
        const { data } = yield call(projectClass.getSubTasks, payload);
        yield put({ type: types.GET_SUBTASK_SUCCESS, payload: { ...data, isLoadingMore } });
    } catch (error: any) {
        if (error?.error?.code === 404) {
            yield put({ type: types.GET_SUBTASK_SUCCESS, payload: { subTasks: [] } });
            return;
        }
        sagaDisplayError(error, [422, 409, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_SUBTASK_REQUEST },
        });
    } finally {
        yield put({ type: types.STOP_LOADING, payload: types.GET_SUBTASK_REQUEST });
    }
}

/**
 * @description Update subtask
 */
function* updateSubTask({
    payload,
}: {
    type: string;
    payload: {
        subTaskId: string;
        subTaskName: string;
        status: number;
    };
}) {
    try {
        const { data } = yield call(projectClass.updateSubTask, payload);
        yield put({ type: types.UPDATE_SUBTASK_SUCCESS, payload: data });
    } catch (error) {
        sagaDisplayError(error, [400, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPDATE_SUBTASK_REQUEST },
        });
    }
}

/**
 * @description Upload task attachment
 */
function* uploadAttachment({ payload }: { type: string; payload: FormData }) {
    yield put({ type: types.START_LOADING, payload: types.UPLOAD_FILE_REQUEST });
    try {
        const { data } = yield call(projectClass.uploadAttachment, payload);
        yield put({ type: types.UPLOAD_FILE_SUCCESS, payload: data });
        yield put({ type: types.CLEAR_ERROR, payload: types.UPLOAD_FILE_REQUEST });
    } catch (error) {
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPLOAD_FILE_REQUEST },
        });
        ToastHelper.toastStartContent("danger", `An error occured. Try again.`);
    } finally {
        yield put({ type: types.STOP_LOADING, payload: types.UPLOAD_FILE_REQUEST });
    }
}

///// STEPS COLUMN /////

/**
 * @description create kaban column task status
 */
function* createTaskStatusColumn({
    payload,
}: {
    type: string;
    payload: {
        status: string;
        taskListId: string;
        projectId: string;
    };
}) {
    try {
        const { data } = yield call(projectClass.createTaskStatusColumn, payload);
        yield put({ type: types.CREATE_STATUS_COLUMN_SUCCESS, payload: data });
    } catch (error) {
        sagaDisplayError(error, [400, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.CREATE_STATUS_COLUMN_REQUEST },
        });
    }
}

/**
 * @description Get statuses for kanban view
 */
function* getStatuses({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        taskListId: string;
    };
}) {
    try {
        yield put({
            type: types.START_LOADING,
            payload: types.GET_STATUSES_REQUEST,
        });
        const { data } = yield call(projectClass.getStatuses, payload);
        let results = data.statuses;
        if (!data || data.status === "error") results = [];
        yield put({ type: types.GET_STATUSES_SUCCESS, payload: results });
    } catch (error) {
        sagaDisplayError(error, [400, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_STATUSES_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.GET_STATUSES_REQUEST,
        });
    }
}

/**
 * @description Reorder statuses
 */
function* reoederColumns({
    payload,
}: {
    type: string;
    payload: {
        projectId: string;
        taskListId: string;
        statuses: string[];
    };
}) {
    try {
        const { data } = yield call(projectClass.reoederColumns, payload);
        yield put({ type: types.REORDER_COLUMNS_SUCCESS, payload: data.statuses });
    } catch (error) {
        sagaDisplayError(error, [400, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.REORDER_COLUMNS_REQUEST },
        });
    }
}

/**
 * @description Update status
 */
function* updateStatus({
    payload,
}: {
    type: string;
    payload: {
        newStep: {
            projectId: string;
            taskListId: string;
            statusId: string;
            status: string;
        };
    };
}) {
    try {
        const { data } = yield call(projectClass.updateStatus, payload);
        yield put({ type: types.UPDATE_STATUS_SUCCESS, payload: data.statuses });
    } catch (error) {
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.UPDATE_STATUS_REQUEST },
        });
    }
}

/**
 * @description Delete personal task
 */
function* deleteStep({
    payload,
}: {
    type: string;
    payload: {
        tasks: { taskId: string }[];
        step: { id: string };
        movedTo: string;
        taskListId: string;
        projectId: string;
    };
}) {
    const { tasks, step } = payload;
    yield put({
        type: types.START_LOADING,
        payload: types.DELETE_STATUS_REQUEST,
    });
    try {
        yield call(projectClass.deleteStep, payload);

        const { statuses } = yield select(({ ProjectReducer }) => ({
            statuses: ProjectReducer.statuses,
        }));
        const newStatuses = statuses.filter((status: IStep) => status.id !== step.id);

        yield put({ type: types.DELETE_STATUS_SUCCESS, payload: newStatuses });
        yield put({ type: types.UPDATE_TASKS_STATUS, payload: tasks });
        yield PopupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [400, 404, 422, 500]);
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.DELETE_STATUS_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.DELETE_STATUS_REQUEST,
        });
    }
}

//// HRM ////

/**
 * @description Get members of organisation
 */
function* getOrganisationMembers() {
    yield put({
        type: types.START_LOADING,
        payload: types.GET_ORGANISATION_MEMBERS_REQUEST,
    });
    let allEmployees = [];
    const { user } = yield select(({ AuthReducer }) => ({
        user: AuthReducer.user,
    }));
    if (user && user.roleId === "0") {
        allEmployees.push(user);
    }
    try {
        const { data } = yield call(projectClass.getOrganisationMembers);
        yield put({
            type: types.GET_ORGANISATION_MEMBERS_SUCCESS,
            payload: [...allEmployees, ...data],
        });
    } catch (error: any) {
        if (error?.error?.code === 404) {
            yield put({
                type: types.GET_ORGANISATION_MEMBERS_SUCCESS,
                payload: allEmployees,
            });
            return;
        }
        yield put({
            type: types.RENDER_ERROR,
            payload: { error, type: types.GET_ORGANISATION_MEMBERS_REQUEST },
        });
    } finally {
        yield put({
            type: types.STOP_LOADING,
            payload: types.GET_ORGANISATION_MEMBERS_REQUEST,
        });
    }
}

export default function* projectSaga() {
    yield takeLatest(types.CREATE_PROJECT_REQUEST, createProject);
    yield takeLatest(types.GET_PROJECT_LIST_REQUEST, getProjectsList);
    yield takeLatest(types.UPDATE_PROJECT_REQUEST, updateProject);
    yield takeLatest(types.DELETE_PROJECT_REQUEST, deleteProject);

    yield takeLatest(types.CREATE_TASKS_LIST_REQUEST, createTasksList);
    yield takeLatest(types.GET_TASKLISTS_REQUEST, getProjectTaskLists);
    yield takeLatest(types.UPDATE_TASKS_LIST_REQUEST, updateTaskList);
    yield takeLatest(types.DELETE_TASKS_LIST_REQUEST, deleteTaskList);

    yield takeLatest(types.CREATE_PERSONAL_TASK_REQUEST, createPersonalTask);
    yield takeLatest(types.GET_PERSONAL_TASKS_REQUEST, getPersonalTasks);
    yield takeLatest(types.UPDATE_PERSONAL_TASKS_REQUEST, updatePersonalTask);
    yield takeLatest(types.DELETE_PERSONAL_TASK_REQUEST, deletePersonalTask);

    yield takeLatest(types.CREATE_TASK_REQUEST, createTask);
    yield takeLatest(types.GET_TASKS_REQUEST, getTasks);
    yield takeLatest(types.UPDATE_TASK_REQUEST, updateTask);
    yield takeEvery(types.UPLOAD_FILE_REQUEST, uploadAttachment);
    yield takeLatest(types.GET_SINGLE_TASK_REQUEST, getSingleTask);
    yield takeLatest(types.DELETE_TASK_REQUEST, deleteTask);

    yield takeLatest(types.CREATE_SUBTASK_REQUEST, createSubTask);
    yield takeLatest(types.GET_SUBTASK_REQUEST, getSubTasks);
    yield takeLatest(types.UPDATE_SUBTASK_REQUEST, updateSubTask);

    yield takeLatest(types.GET_ORGANISATION_MEMBERS_REQUEST, getOrganisationMembers);

    yield takeEvery(types.CREATE_STATUS_COLUMN_REQUEST, createTaskStatusColumn);
    yield takeLatest(types.GET_STATUSES_REQUEST, getStatuses);
    yield takeLatest(types.REORDER_COLUMNS_REQUEST, reoederColumns);
    yield takeLatest(types.DELETE_STATUS_REQUEST, deleteStep);
    yield takeLatest(types.UPDATE_STATUS_REQUEST, updateStatus);
}

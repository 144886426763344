export const checkIfLoader = (reducer, ...actionsToCheck) => 
reducer.loader.actions.some(action => actionsToCheck.includes(action));

/**
 * @description Update data when a change occur
 * @param {array} list
 * @param {object} item updated element
 * @param {string} idName updated element
 * @returns {array}
 */
export const updateList = (list, item, idName) => {
    const index = list.findIndex((listItem) => listItem[idName] === item[idName])
    let updatedList = [...list]
    updatedList[index] = item
    return updatedList
}

/**
 * @description check if invoice dueDate passed and update the status  
 * @param {Array[Object]} invoices 
 */
export const updateInvoicesStatuses = (invoices) => {
    return invoices.map(invoice => {
        const {invoiceStatus} = invoice
        // Check if invoice due date passed
       if(invoiceStatus !== 'draft' && invoiceStatus !== 'paid' &&
            new Date(invoice.dueDate).getTime() <= new Date().getTime()) {
           invoice.invoiceStatus = 'late'
       }
       return invoice
    })
}
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import apiV2 from "../../../../helpers/api.v2";

/**
 * @description display errors in catch.
 */
export const sagaDisplayError = (errorResponse: any, code: number[]) => {
    if (!errorResponse.error) {
        toastHelper.toastStartContent("danger", errorResponse.message || apiV2.UnknownErroMessage);
        return;
    }
    if (typeof code !== "number") {
        code.forEach((c) => {
            if (errorResponse.error.code === c) {
                toastHelper.toastStartContent("danger", apiV2.stringifyErrors(errorResponse, c) || apiV2.UnknownErroMessage);
            }
        });
    } else if (errorResponse.error.code === code) {
        toastHelper.toastStartContent("danger", apiV2.stringifyErrors(errorResponse, code) || apiV2.UnknownErroMessage);
    } else {
        toastHelper.toastStartContent("danger", errorResponse.error.message || apiV2.UnknownErroMessage);
    }
};

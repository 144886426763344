import { PermissionValue } from "../../../../applications/hrm/hrm";
import types from "./types";

type TAction = {
    type: string;
    payload?: any;
    dispatch?: React.Dispatch<{ type: string; payload?: any }>;
};

export type NotificationsMenuProps = "all" | "emails" | "calendar" | "contacts";

export type HeaderState = {
    /**
     * @description if sidebar is enabled.
     */
    showMainSidebar: boolean;
    loadingApp: boolean;
    /**
     * @description header actions comming from the displayed application.
     */
    headerActions?: {
        onNew: () => void | undefined;
        actions?: JSX.Element[];
        text?: string;
        permission?: PermissionValue;
        component?: JSX.Element;
    };
    /**
     * @description header menu links to be displayed
     */
    menu?: {
        name: string;
        path: string;
        subs?: string[];
        actived: boolean;
        permission?: PermissionValue;
    }[];
    /**
     * @description name of the displayed application inside content.
     */
    appName: string;
    /**
     * @description for displaying secondary header
     */
     header?: {
         type: 'INITIAL' | 'SECONDARY',
         backPath?: string,
     };
     /**
     * @description if notifications menu is opened.
     */
    notifs: {
        showNotifications: boolean;
        selectedMenu: NotificationsMenuProps;
        manageNotifications: boolean;
    }
};

export const INITIAL_STATE: HeaderState = {
    showMainSidebar: false,
    loadingApp: false,
    headerActions: undefined,
    appName: "dashboard",
    header: {
        type: "INITIAL",
    },
    notifs: {
        showNotifications: false,
        selectedMenu: "all",
        manageNotifications: false,
    }
};

export const HeaderReducer = (state = INITIAL_STATE, action: TAction) => {
    switch (action.type) {
        case types.HEADER_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...action.payload.state,
            };

        case types.HEADER_SIDEBAR_TOGGLE_DISPLAY_REQUEST:
            return {
                ...state,
                showMainSidebar: action.payload.show,
            };

        case types.HEADER_START_LOADING_APP_REQUEST:
            return {
                ...state,
                loadingApp: action.payload.loading,
            };

        case types.HEADER_ACTIONS_REQUEST:
            return {
                ...state,
                headerActions: action.payload,
            };

        case types.HEADER_MENU_REQUEST:
            return {
                ...state,
                menu: action.payload.menu,
            };

        case types.HEADER_TYPE_REQUEST:
            return {
                ...state,
                header: action.payload.header,
            };

        case types.HEADER_CHANGE_NOTIFICATIONS_BAR_REQUEST:
            return {
                ...state,
                notifs: {
                    ...state.notifs,
                    ...action.payload.notifs,
                },
            };
        default:
            return state;
    }
};

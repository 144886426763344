import { createContext } from "react";
import { INITIAL_STATE } from "./reducer";

const GuideOrgContext = createContext<{
    state: ReturnType<() => typeof INITIAL_STATE>;
    dispatch: React.Dispatch<{
        type: string;
        payload?: any;
    }>;
}>({
    state: INITIAL_STATE,
    dispatch: () => undefined,
});

export default GuideOrgContext;

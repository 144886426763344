import { EntityInfo, ServiceInfo } from "@soltivo/types";

export const getServiceBasicInfo = (serviceInfo?: string | ServiceInfo): Pick<ServiceInfo, "title" | "duration" | "price"> => {
    const title = (() => {
        if (typeof serviceInfo === "string") return "Service not found";
        if (serviceInfo?.title) return serviceInfo?.title;
        return "-";
    })();

    const duration = (() => {
        if (typeof serviceInfo === "string") return 0;
        if (serviceInfo?.duration) return serviceInfo?.duration;
        return 0;
    })();

    const price = (() => {
        if (typeof serviceInfo === "string") return 0;
        if (serviceInfo?.price) return serviceInfo?.price;
        return 0;
    })();

    return {
        price,
        title,
        duration,
    };
};

export const getEntityBasicInfo = (entityInfo?: string | EntityInfo): Pick<EntityInfo, "email" | "fullname" | "phoneNumber"> => {
    const fullname = (() => {
        if (typeof entityInfo === "string") return "Customer not found.";
        if (entityInfo?.fullname) return entityInfo?.fullname;
        if (entityInfo?.firstName && entityInfo?.lastName) return `${entityInfo?.firstName} ${entityInfo?.lastName}`;
        if (entityInfo?.firstName) return entityInfo?.firstName;
        return "Customer not found.";
    })();

    const email = (() => {
        if (typeof entityInfo === "string") return "-";
        return entityInfo?.email ? entityInfo.email : "-";
    })();

    const phoneNumber = (() => {
        if (typeof entityInfo === "string") return "-";
        return entityInfo?.phoneNumber ? entityInfo.phoneNumber : "-";
    })();

    return {
        fullname,
        phoneNumber,
        email,
    };
};

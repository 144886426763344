export const types = {
    APPOINTMENT_CHANGE_STATE: "APPOINTMENT_CHANGE_STATE",
    APPOINTMENT_CHANGE_FORM: "APPOINTMENT_CHANGE_FORM",
    APPOINTMENT_SET_INITIAL_STATE: "APPOINTMENT_SET_INITIAL_STATE",
    APPOINTMENT_SET_INITIAL_FORM: "APPOINTMENT_SET_INITIAL_FORM",
    APPOINTMENT_CREATION_CHANGE_VIEW: "APPOINTMENT_CREATION_CHANGE_VIEW",
    APPOINTMENT_UPDATE_EMPLOYEES_LIST: "APPOINTMENT_UPDATE_EMPLOYEES_LIST",
    APPOINTMENT_UPDATE_SELECTABLE_LOCATIONS: "APPOINTMENT_UPDATE_SELECTABLE_LOCATIONS",
    APPOINTMENT_CHANGE_ENTITY_FORM: "APPOINTMENT_CHANGE_ENTITY_FORM",
};

import { Info24Px, Plus, MoreVert24Px, Delete24Px } from "@soltivo/draw-a-line/core/components/icons";
import { Button, InputLabel, InputText, InputTime, useClickOutsideRef, soltivoHelper } from "@soltivo/draw-a-line";
import { useEffect, useState, useContext, useRef } from "react";
import GuideOrgContext from "../../context/guide.org.context";
import { guideOrgChangeFormState } from "../../context/actions";
import { BUFFER_TIMES } from "../../../../../applications/service/helpers/constants";
import styles from "./category.create.module.scss";
import { useSelector } from "react-redux";
import { GuideOrgContextState } from "../../context/reducer";
import CustomInputSelect from "../custom.selection/custom.selection";
import { RootState } from "../../../../../redux/reducers";
import { Organization } from "../../../../../applications/auth/organization";

interface CategoryCreationProps {
    category?: GuideOrgContextState["form"]["service"]["categories"][0];
    categoryIndex: number;
}

const CategoryCreation = ({ category, categoryIndex }: CategoryCreationProps) => {
    const _menuRef = useRef<HTMLDivElement>(null);
    const _tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const mapStateToProps = ({ OrgReducer, ServiceReducer }: RootState) => ({
        org: OrgReducer.org as Organization,
        allServices: ServiceReducer.allServices,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;

    const { org }: MapStateToProps = useSelector(mapStateToProps);

    const {
        state: { form },
        dispatch: dispatchContext,
    } = useContext(GuideOrgContext);

    const onChange = (payload: { name: string; value: string | string[] }, selectedService?: number) => {
        let categories = [...form.service.categories];

        categories = categories.map((category, _categoryIndex) => {
            if (categoryIndex === _categoryIndex) {
                if (payload.name === "name") {
                    category.name = payload.value as string;
                }
                category.services = [...category.services].map((service, serviceIndex) => {
                    service.category = category.name;
                    if (serviceIndex === selectedService) {
                        service = {
                            ...service,
                            [payload.name]: payload.value,
                        };
                    }
                    return service;
                });
            }
            return category;
        });

        dispatchContext(
            guideOrgChangeFormState({
                service: {
                    ...form.service,
                    categories: categories,
                },
            })
        );
    };

    // Press enter on category name field
    const onkeydown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            if (!form.service.categories[categoryIndex].services.length) addNewServiceLine();
            // Focus on the service name input
            const nextServiceInput: HTMLInputElement | null = document.querySelector(`.${"input__text"}--${categoryIndex}`);
            if (nextServiceInput) nextServiceInput.focus();
        }
    };

    const addNewServiceLine = () => {
        dispatchContext(
            guideOrgChangeFormState({
                service: {
                    ...form.service,
                    categories: form.service.categories.map((category, cIndex) => {
                        if (cIndex === categoryIndex) {
                            return {
                                ...category,
                                services: [
                                    ...category.services,
                                    {
                                        bufferTime: BUFFER_TIMES.length ? BUFFER_TIMES[0].value: "",
                                        category: category.name,
                                        duration: "00:00",
                                        price: "",
                                        title: "",
                                        locations: org.businessType !== "multi-organization" ? org.locations.map(l => l.id) : [],
                                    },
                                ],
                            };
                        }
                        return category;
                    }),
                },
            })
        );
    };

    useEffect(() => {
        let tableInputs: Element[] = [];
        document.querySelectorAll(".custom__select").forEach((input) => {
            tableInputs.push(input);
        });
        document.querySelectorAll("input").forEach((input) => {
            tableInputs.push(input);
        });

        tableInputs.forEach((el) => {
            el.addEventListener("focus", (e) => {
                /** @ts-ignore */
                const cell: HTMLTableCellElement | null = e.target.closest("td");
                if (cell) cell.classList.add(`${styles.cell__focused}`);
            });

            el.addEventListener("blur", (e) => {
                /** @ts-ignore */
                const cell: HTMLTableCellElement | null = e.target.closest("td");
                if (cell) cell.classList.remove(`${styles.cell__focused}`);
            });
        });

        // When Enter key is pressed on the price input
        _tableBodyRef.current?.querySelectorAll('input[name="price"]').forEach((input, index) => {
            input.addEventListener("keypress", (e) => {
                /** @ts-ignore */
                if (e.key === "Enter") {
                    if (form.service.categories[categoryIndex].services.length === index + 1) {
                        addNewServiceLine();
                        // Focus the next service name
                        const nextServiceInput: NodeListOf<HTMLInputElement> | undefined = _tableBodyRef.current?.querySelectorAll(`.${"input__text"}--${categoryIndex}`);
                        if (nextServiceInput) nextServiceInput[index + 1]?.focus();
                    }
                }
            });
        });
    }, [form.service, minimized]);

    useEffect(() => {
        // Autofocus the input category when page load
        const inputCategory: HTMLInputElement | null = document.querySelector('input[name="categoryName"]');
        if (inputCategory) inputCategory.focus();
    }, []);

    useEffect(() => {
        // Autofocus the new input service
        const nextServiceInput: NodeListOf<HTMLInputElement> | undefined = _tableBodyRef.current?.querySelectorAll(`.${"input__text"}--${categoryIndex}`);
        if (nextServiceInput) nextServiceInput[nextServiceInput.length - 1]?.focus();
    }, [form.service.categories[categoryIndex].services.length]);

    useEffect(() => {
        // Autofocus the new input category
        const inputCategories: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="categoryName"]');
        inputCategories[inputCategories.length - 1].focus();
    }, [form.service.categories.length]);

    const onRemoveCategory = () => {
        dispatchContext(
            guideOrgChangeFormState({
                service: {
                    ...form.service,
                    categories: form.service.categories.filter((category, cIndex) => cIndex !== categoryIndex),
                },
            })
        );
        setShowMenu(false);
        setShowModal(false);
    };

    const onRemoveServiceLine = (serviceIndex: number) => {
        dispatchContext(
            guideOrgChangeFormState({
                service: {
                    ...form.service,
                    categories: [
                        ...form.service.categories.map((cat, index) => {
                            if (categoryIndex === index) {
                                cat.services = cat.services.filter((ser, sIndex) => sIndex !== serviceIndex);
                            }
                            return cat;
                        }),
                    ],
                },
            })
        );
    };

    useClickOutsideRef([window], () => setShowMenu(false), _menuRef);

    return (
        <>
            {showModal && (
                <div id={styles.modal__confirm}>
                    <div className={styles.modal__content}>
                        <h6>Are you sure?</h6>
                        <p>This will delete all the services you added in this category</p>
                        <div className={styles.buttons}>
                            <Button onClick={() => setShowModal(false)} variant="primary" outline padding={false}>
                                Cancel
                            </Button>
                            <Button onClick={onRemoveCategory} variant="valencia-500" outline padding={false}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <thead>
                <tr className={styles.table__tr}>
                    <td className={`${styles.table__head_title}`} colSpan={org.businessType === "multi-organization" ? 5 : 4}>
                        <div className={styles.cell__content}>
                            <span className={styles.indicator}></span>
                            <div className={styles.more__actions} style={showMenu ? { width: "30px" } : {}}>
                                <Button
                                    className={styles.menu__button}
                                    onClick={() => {
                                        setShowMenu(!showMenu);
                                    }}
                                    variant="primary"
                                    outline
                                    padding={false}>
                                    <MoreVert24Px />
                                </Button>
                                {showMenu && (
                                    <div ref={_menuRef} className={styles.menu__content}>
                                        <Button
                                            onClick={() => {
                                                setMinimized(!minimized);
                                                setShowMenu(false);
                                            }}
                                            variant="primary"
                                            outline
                                            padding={false}>
                                            {minimized ? "Maximise" : "Minimise"}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setShowModal(true);
                                                setShowMenu(false);
                                            }}
                                            variant="valencia-500"
                                            outline
                                            padding={false}>
                                            Remove category
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <InputText
                                border={false}
                                tabIndex={1}
                                className={`${styles.input__text}`}
                                value={category?.name}
                                onKeyDown={(e) => onkeydown(e)}
                                focus={true}
                                placeholder="Category name"
                                name="categoryName"
                                onChange={(e) => onChange({ name: "name", value: e.target.value })}
                            />
                            <Button
                                onClick={() => {
                                    addNewServiceLine();
                                    setMinimized(false);
                                }}
                                className={styles.add__service}
                                data-title-position="top-left"
                                data-title="Add a service"
                                variant="primary"
                                outline
                                padding={false}>
                                <Plus />
                            </Button>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody className={styles.table__tbody} ref={_tableBodyRef}>
                {minimized ? (
                    <tr className={styles.minimized__services}>
                        <td
                            onClick={() => {
                                setMinimized(false);
                            }}
                            colSpan={4}>
                            ...minimized services
                        </td>
                    </tr>
                ) : (
                    category?.services.map((service, serviceIndex) => (
                        <tr key={serviceIndex}>
                            <td className={`${styles.service__name}`}>
                                <div className={styles.service__actions}>
                                    <span className={styles.indicator}></span>
                                    <Button onClick={() => onRemoveServiceLine(serviceIndex)} className={styles.remove__service__line} variant="valencia-500" outline padding={false}>
                                        <Delete24Px />
                                    </Button>
                                    <InputText
                                        border={false}
                                        tabIndex={1}
                                        className={`${styles.input__text} ${"input__text"}--${categoryIndex}`}
                                        value={service.title}
                                        placeholder="Service name"
                                        name="serviceName"
                                        onChange={(e) => onChange({ name: "title", value: e.target.value }, serviceIndex)}
                                    />
                                </div>
                            </td>
                            {org.businessType === "multi-organization" && (
                                <td data-title-position="top-right" data-title={`Select all the locations\n where you offer this service`} className={styles.locations}>
                                    <CustomInputSelect
                                        multipleChoice={true}
                                        className={`${styles.input__selection}`}
                                        options={org.locations.map((location) => ({ value: soltivoHelper.sliceText(location.name, 10), option: location }))}
                                        value={service.locations}
                                        placeholder="Locations"
                                        name="locations"
                                        onChange={(e) => {
                                            onChange({ name: "locations", value: e.values.map((loc: any) => loc.option.id) }, serviceIndex);
                                        }}
                                    />
                                </td>
                            )}
                            <td data-title-position="top-right" data-title={`How much time does it\n take to perform that service?`} className={styles.duration}>
                                <InputTime
                                    value={service.duration}
                                    className={`${styles.input__time}`}
                                    name="duration"
                                    maxHours={11}
                                    tabIndex={1}
                                    centered
                                    onChange={(e) => onChange({ name: "duration", value: e.value }, serviceIndex)}
                                />
                            </td>
                            <td className={styles.buffer}>
                                <CustomInputSelect
                                    className={`${styles.input__selection}`}
                                    options={BUFFER_TIMES}
                                    value={service.bufferTime}
                                    placeholder="Buffer"
                                    name="bufferTime"
                                    onChange={(e) => onChange({ name: "bufferTime", value: e.value as string }, serviceIndex)}
                                />
                                <span
                                    data-title-position="top-left"
                                    data-title={`The time between one appointment \n and another. For example, the break \n or clean up time for staff before they \n take on another appointment.`}>
                                    <Info24Px />
                                </span>
                            </td>
                            <td data-title-position="top-left" data-title={`How much do you charge\n for that service?`} className={styles.price}>
                                <InputLabel
                                    id={styles.input__label}
                                    value={service.price}
                                    type="currency"
                                    border={false}
                                    tabIndex={1}
                                    labelPositionX="left"
                                    label="$"
                                    placeholder="0.00"
                                    name="price"
                                    onChange={(e) => onChange({ name: "price", value: e.target.value }, serviceIndex)}
                                />
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </>
    );
};

export default CategoryCreation;

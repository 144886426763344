import apiV2 from "../../../../helpers/api.v2";
import { EmailActions } from "../reducer/actions.types";

const _URL = `${process.env.REACT_APP_API_URL}/emails`;
const CRM_URL = `${process.env.REACT_APP_API_URL}/crm`;

/**
 * @description Email API calls.
 */
class EmailClass {
    /**
     * @description setup email business
     */
    public emailSetupEmailBusiness(payload: EmailActions[`emailSetupEmailBusiness`]["payload"]) {
        return apiV2.postRequest(`${_URL}/setup/v1`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description get email out exchanges (SENT MESSAGES)
     */
    public emailGetOutExchanges(payload: EmailActions[`emailGetOutExchanges`]["payload"]) {
        const reqUrl = payload ? `${_URL}/type/outgoing?exchangeId=${payload.exchangeId}&createdAt=${payload.createdAt}` : `${_URL}/type/outgoing`;
        return apiV2.getRequest(reqUrl, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get email in exchanges (INBOX MESSAGE)
     */
    public emailGetInExchanges(payload: EmailActions["emailGetInExchanges"]["payload"]) {
        const reqUrl = payload ? `${_URL}/type/incoming?exchangeId=${payload.exchangeId}&createdAt=${payload.createdAt}` : `${_URL}/type/incoming`;
        return apiV2.getRequest(reqUrl, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get trash exchanges
     */
    public emailGetDeletedExchanges(payload: EmailActions["emailGetDeletedExchanges"]["payload"]) {
        const reqUrl = payload ? `${_URL}/trash?exchangeId=${payload.exchangeId}&createdAt=${payload.createdAt}` : `${_URL}/trash`;
        return apiV2.getRequest(reqUrl, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create and send new email.
     */
    public emailSendEmail(payload: EmailActions["emailSendEmail"]["payload"]) {
        return apiV2.postRequest(`${_URL}`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description get emails and its conversations.
     */
    public emailGetEmail(payload: EmailActions["emailGetEmail"]["payload"]) {
        const reqUrl = payload.lastKey ? `${_URL}/exchange/${payload.lastKey.exchangeId}/${payload.lastKey.createdAt}` : `${_URL}/exchange/${payload.exchangeId}`;
        return apiV2.getRequest(reqUrl, {
            useToken: "accessToken",
        });
    }

    /**
     * @description deletes an exchange (maybe move to deleted folder.)
     */
    public emailDeleteExchange(payload: EmailActions["emailDeleteExchange"]["payload"]) {
        return apiV2.deleteRequest(`${_URL}/trash/${payload.exchangeId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description restore deleted email.
     */
    public emailRestoreEmail(payload: EmailActions["emailRestoreEmail"]["payload"]) {
        return apiV2.postRequest(`${_URL}/restore`, {
            data: JSON.stringify({
                id: payload.exchangeId,
            }),
            useToken: "accessToken",
        });
    }

    /**
     * @description reply email.
     */
    public emailReplyEmail(payload: EmailActions["emailReplyEmail"]["payload"]) {
        return apiV2.postRequest(`${_URL}`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description get signed url
     */
    public getSignedUrl(payload: { contentTypes: string[] }) {
        return apiV2.postRequest(`${_URL}/signedUrl`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * @description search an entity
     */
    public emailSearchEntityContacts(payload: EmailActions["emailSearchEntityContacts"]["payload"]) {
        return apiV2.getRequest(`${CRM_URL}/entity/search?data=${payload}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description mark email as read | unread.
     */
    public emailMarkReadAs(payload: EmailActions["emailMarkReadAs"]["payload"]) {
        return apiV2.putRequest(`${_URL}/unread/${payload.exchangeId}`, {
            useToken: "accessToken",
            data: JSON.stringify({
                unread: payload.unread,
            }),
        });
    }
}

export default new EmailClass();

import { Button, InputText, Loader } from "@soltivo/draw-a-line";
import { ArrowLeft, ExitToApp24Px } from "@soltivo/draw-a-line/core/components/icons";
import { InputTextProps } from "@soltivo/draw-a-line/core/components/inputs/input.text/input.text";
import { FC, Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { appointmentCreateNote, appointmentListNotes } from "../../../redux/reducer/actions";
import NoteItem from "./note.item/note.item";

import styles from "./appointments.notes.module.scss";
import { ButtonProps } from "@soltivo/draw-a-line/core/components/buttons/button/button";
import { AppointmentNotes } from "@soltivo/types";

export interface AppointmentsNotesProps extends StateToProps {
    appointmentId: string;
    /**
     * @description update note.
     */
    /**
     * @description close notes, display appointments details
     */
    closeNotes: () => void;
}

interface NoteListProps {
    notes: AppointmentNotes[];
}
function NoteList({ notes }: NoteListProps) {
    return (
        <>
            {notes
                .slice(0)
                .reverse()
                .map((note) => (
                    <NoteItem key={note.id} note={note} />
                ))}
        </>
    );
}

function Loading() {
    return (
        <div className={styles.loading}>
            <Loader size="sm" variant="secondary" />
        </div>
    );
}

const AppointmentsNotes: FC<AppointmentsNotesProps> = ({ appointmentId, appointmentNotes, closeNotes, loadingNotes }) => {
    const dispatch = useDispatch();
    const [note, setNote] = useState("");

    useEffect(() => {
        dispatch(appointmentListNotes({ appointmentId }));
    }, [appointmentId]);

    const handleNoteChange: InputTextProps["onChange"] = (event) => {
        setNote(event.target.value);
    };

    const createNote = () => {
        if (note.length > 0) {
            dispatch(appointmentCreateNote({ appointmentId, note }));
            setNote("");
        }
    };

    const onPressEnter: InputTextProps["onKeyDown"] = (event) => {
        if (event.key === "Enter") createNote();
    };

    const handleClick: ButtonProps["onClick"] = (event) => {
        event.preventDefault();
        createNote();
    };

    return (
        <div className={styles.notes}>
            <Button data-testid="backButton" onClick={closeNotes} className={styles.activity__button} padding={false} outline variant="geyser-900">
                <ArrowLeft />
                &nbsp; Appointment details
            </Button>
            <div className={styles.notes__wrapper}>
                <div className={styles.notes__container}>
                    <div className={styles.notes__group}>
                        {!appointmentNotes.length && loadingNotes ? <Loading /> : null}

                        {!!appointmentNotes?.length && (
                            <>
                                <NoteList notes={appointmentNotes} />
                                {loadingNotes && <Loading />}
                            </>
                        )}

                        {!appointmentNotes.length && (
                            <div className={styles.message}>
                                <p>This appointment doesn't have any notes.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.input}>
                <InputText autoComplete="off" name="note" placeholder="Write your note and hit enter" type="text" value={note} onKeyDown={onPressEnter} onChange={handleNoteChange} rounded={false} />
                <Button data-testid="addButton" onClick={handleClick} className={styles.add__button} outline padding={false}>
                    <ExitToApp24Px />
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
    appointmentNotes: AppointmentReducer.appointmentNotes,
    loadingNotes: AppointmentReducer.loadingAppointmentNotes,
});

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(AppointmentsNotes);

import { RootState } from "../../../../../redux/reducers";
import { types } from "./types";

/**
 * @description get a plan
 */
export const billingsGetPlan = (payload: { planId: string }) => ({
    type: types.BILLINGS_GET_PLAN_REQUEST,
    payload,
});

/**
 * @description change global state in invoices
 * @warning be careful using this action it may break the app.
 */
export const billingsChangeState = (payload: Partial<RootState["BillingsReducer"]>) => ({
    type: types.BILLINGS_CHANGE_STATE,
    payload,
});

/**
 * @description get customer by id
 */
export const billingsGetCustomer = (payload: { customerId: string }) => ({
    type: types.BILLINGS_GET_CUSTOMER_REQUEST,
    payload,
});

/**
 * @description get all customers within an org
 */
export const billingsGetCustomers = () => ({
    type: types.BILLINGS_GET_CUSTOMERS_REQUEST,
});

/**
 * @description update customer (using only to update default payment method at the time.)
 */
export const billingsUpdateCustomer = (payload: {
    customerId: string;
    invoice_settings?: {
        default_payment_method?: string;
    };
}) => ({
    type: types.BILLINGS_UPDATE_CUSTOMER_REQUEST,
    payload,
});

/**
 * @description attach a payment method to a customer
 */
export const billingsAttachPaymentMethod = (payload: { paymentMethod: string }) => ({
    type: types.BILLINGS_ATTACH_PAYMENT_METHOD_REQUEST,
    payload,
});

/**
 * @description detach a payment method from a customer
 */
export const billingsDetachPaymentMethod = (payload: { paymentMethod: string }) => ({
    type: types.BILLINGS_DETACH_PAYMENT_METHOD_REQUEST,
    payload,
});

/**
 * @description get payment methods of a org
 */
export const billingsGetPaymentMethods = (payload: { stripeCustomerId: string }) => ({
    type: types.BILLINGS_GET_PAYMENT_METHODS_REQUEST,
    payload,
});

/**
 * @description get organization next invoice payment
 */
export const billingsGetNextInvoicePayment = () => ({
    type: types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_REQUEST,
});

/**
 * @description get organization invoices.
 */
export const billingsGetInvoices = (payload?: { lastKey: string | undefined }) => ({
    type: types.BILLINGS_GET_INVOICES_REQUEST,
    payload,
});

/**
 * @description get organization single invoice.
 */
export const billingsGetInvoice = (payload: { invoiceId: string }) => ({
    type: types.BILLINGS_GET_INVOICE_REQUEST,
    payload,
});

/**
 * @description get organization subscriptions.
 */
export const billingsGetSubscriptions = () => ({
    type: types.BILLINGS_GET_SUBSCRIPTIONS_REQUEST,
});

/**
 * @description get organization public subscription.
 */
export const billingsGetPublicSubscription = () => ({
    type: types.BILLINGS_GET_PUBLIC_SUBSCRIPTION_REQUEST,
});

/**
 * @description get the invoice pdf
 */
export const billingsGetInvoicePdf = () => ({
    type: types.BILLINGS_GET_INVOICE_PDF_REQUEST,
});

/**
 * @description Update number of seats
 */
export const billingsUpdateNumberOfSeats = (payload: { seats: number }) => ({
    type: types.BILLINGS_UPDATE_SEATS_REQUEST,
    payload,
});

/**
 * @description Pay invoice
 */
export const billingsPayInvoice = (payload: { paymentMethodId: string | null; invoiceId: string }) => ({
    type: types.BILLINGS_PAY_INVOICE_REQUEST,
    payload,
});

import React, { useState, useRef, useEffect } from "react";
import InputText from "@soltivo/draw-a-line/core/components/inputs/input.text/input.text";
import moment from "moment";
import styles from "./note.item.module.scss";
import { AppointmentsNotesProps } from "../appointments.notes";
import { Loader } from "@soltivo/draw-a-line";
import { useDispatch } from "react-redux";
import { appointmentUpdateNote } from "../../../../redux/reducer/actions";

interface NoteItemProps {
    note: AppointmentsNotesProps["appointmentNotes"][0];
}
/**
 * @description single note item
 */

const NoteItem = React.forwardRef<HTMLDivElement, NoteItemProps>(({ note }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const [newNoteValue, setNewNoteValue] = useState(note.text || "");
    const [noteText, setNoteText] = useState(note.text || "");
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current?.focus();
            inputRef.current?.select();
        }
    }, [edit]);

    useEffect(() => {
        setNoteText(note.text);
        setNewNoteValue(note.text);
    }, [note]);

    const handleUpdateNote = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (newNoteValue.length) {
                const toUpdate = { ...note, note: newNoteValue };
                dispatch(appointmentUpdateNote({ ...toUpdate }))
                setEdit(false);
                setNoteText(newNoteValue);
            }
        }
    };

    if (!note?.updatedBy) {
        return (
            <div ref={ref} className={`${styles.note__item} ${styles.from__system}`}>
                <p className={styles.note__desc}>{`${noteText}`}</p> <span>-</span>
                <p className={styles.note__date}>{moment(note.updatedAt).format("MMMM Do YYYY")}</p>
            </div>
        );
    }
    return (
        <div
            ref={ref}
            onDoubleClick={() => {
                setEdit(true);
            }}
            role="listitem"
            aria-label="Appointment Note"
            className={`${styles.note__item} ${note.id === "new" ? styles.new : ""}`}>
            {note.id === "new" ? <Loader className={styles.loading__new} size="xsm" variant="secondary" /> : null}
            {edit ? (
                <InputText
                    ref={inputRef}
                    name="note"
                    placeholder="Update the note"
                    type="text"
                    value={newNoteValue}
                    onKeyDown={handleUpdateNote}
                    onBlur={() => setEdit(false)}
                    onChange={(e) => setNewNoteValue(e.target.value)}
                />
            ) : (
                <p className={styles.note__desc}>{noteText}</p>
            )}
            <hr />
            <p className={styles.note__date}>
                {moment(note.updatedAt).format("LL [at] LT")} by {note.updatedBy || "N/A"}
            </p>
        </div>
    );
});

export default NoteItem;

import { PaymentMethod } from "@stripe/stripe-js";
import { BillingsCustomer, BillingsInvoice, BillingsPlan, BillingsSubscription } from "../../../billings";
import { types } from "./types";

export interface BillingsState {
    loadingPlans: boolean;
    plans: BillingsPlan[];
    nextPaymentInvoice: BillingsInvoice | null;
    loadingNextPaymentInvoice: boolean;
    loadingInvoices: boolean;
    invoices: {
        list: BillingsInvoice[];
        lastKey: any;
    };
    invoice: BillingsInvoice | null;
    paymentMethods: PaymentMethod[];
    customer: BillingsCustomer | null;
    loadingCustomer: boolean;
    loadingPaymentMethods: boolean;
    error: any;
    updatingSeats: boolean;
    subscriptions: BillingsSubscription[];
    loadingSubscriptions: boolean;
    publicSubscription: BillingsSubscription | null;
    loadingPublicSubscription: boolean;
    loadingInvoice: boolean;
    loadingAttachPayment: boolean;
}

const _INITIAL_STATE: BillingsState = {
    loadingPlans: true,
    plans: [],
    nextPaymentInvoice: null,
    loadingNextPaymentInvoice: true,
    loadingInvoices: true,
    invoices: {
        list: [],
        lastKey: undefined,
    },
    invoice: null,
    paymentMethods: [],
    customer: null,
    loadingCustomer: true,
    loadingPaymentMethods: true,
    error: null,
    updatingSeats: false,
    subscriptions: [],
    loadingSubscriptions: true,
    publicSubscription: null,
    loadingPublicSubscription: true,
    loadingInvoice: true,
    loadingAttachPayment: false,
};

const BillingsReducer = (
    state = _INITIAL_STATE,
    action: {
        type: string;
        payload: any;
    }
): BillingsState => {
    switch (action.type) {
        case types.BILLINGS_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.BILLINGS_GET_PLAN_REQUEST:
            return {
                ...state,
            };

        case types.BILLINGS_GET_PLAN_SUCCESS:
            return {
                ...state,
            };

        case types.BILLINGS_GET_PLAN_FAILURE:
            return {
                ...state,
                loadingPlans: false,
            };

        case types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_REQUEST:
            return {
                ...state,
                loadingNextPaymentInvoice: true,
            };

        case types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_SUCCESS:
            return {
                ...state,
                nextPaymentInvoice: action.payload,
                loadingNextPaymentInvoice: false,
            };

        case types.BILLINGS_GET_NEXT_INVOICE_PAYMENT_FAILURE:
            return {
                ...state,
                loadingNextPaymentInvoice: false,
            };

        case types.BILLINGS_GET_INVOICES_REQUEST:
            return {
                ...state,
                loadingInvoices: !Boolean(state.invoices.lastKey),
            };

        case types.BILLINGS_GET_INVOICES_SUCCESS:
            return {
                ...state,
                loadingInvoices: false,
                invoices: action.payload,
            };

        case types.BILLINGS_GET_INVOICES_FAILURE:
            return {
                ...state,
                loadingInvoices: false,
                // invoices: [],
            };

        case types.BILLINGS_GET_INVOICE_REQUEST:
            return {
                ...state,
                loadingInvoice: true,
            };

        case types.BILLINGS_GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
                loadingInvoice: false,
            };

        case types.BILLINGS_GET_INVOICE_FAILURE:
            return {
                ...state,
                invoice: null,
                loadingInvoice: false,
            };

        case types.BILLINGS_GET_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                loadingSubscriptions: true,
            };

        case types.BILLINGS_GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: action.payload,
                loadingSubscriptions: false,
            };

        case types.BILLINGS_GET_SUBSCRIPTIONS_FAILURE:
            return {
                ...state,
                // subscriptions: null,
                loadingSubscriptions: false,
            };

        case types.BILLINGS_GET_PUBLIC_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                publicSubscription: action.payload,
                loadingPublicSubscription: false,
            };

        case types.BILLINGS_GET_PUBLIC_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                loadingPublicSubscription: false,
            };

        case types.BILLINGS_GET_CUSTOMER_REQUEST:
            return {
                ...state,
                loadingCustomer: true,
            };

        case types.BILLINGS_GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                loadingCustomer: false,
                customer: action.payload,
            };

        case types.BILLINGS_GET_CUSTOMER_FAILURE:
            return {
                ...state,
                loadingCustomer: false,
                customer: null,
            };

        case types.BILLINGS_UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload,
            };

        case types.BILLINGS_UPDATE_CUSTOMER_FAILURE:
            return {
                ...state,
            };

        // case types.INVOICE_GET_INVOICES_PDF_SUCCESS:
        // 	return {
        // 		...state,
        // 		loadingInvoices: false,
        // 		invoices: action.payload
        // 	}

        // case types.INVOICE_GET_INVOICES_PDF_FAILURE:
        // 	return {
        // 		...state,
        // 		loadingInvoices: false,
        // 		invoices: {list: [], lastKey: undefined}
        // 	}

        case types.BILLINGS_ATTACH_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loadingAttachPayment: true,
            };

        case types.BILLINGS_ATTACH_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loadingAttachPayment: false,
                paymentMethods: state.paymentMethods?.length ? [...state.paymentMethods, action.payload] : [action.payload],
            };

        case types.BILLINGS_ATTACH_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                loadingAttachPayment: false,
            };

        case types.BILLINGS_DETACH_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: action.payload,
            };

        case types.BILLINGS_DETACH_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
            };

        case types.BILLINGS_GET_PAYMENT_METHODS_REQUEST:
            return {
                ...state,
                loadingPaymentMethods: true,
            };

        case types.BILLINGS_GET_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                paymentMethods: action.payload,
                loadingPaymentMethods: false,
            };

        case types.BILLINGS_GET_PAYMENT_METHODS_FAILURE:
            return {
                ...state,
                paymentMethods: [],
                loadingPaymentMethods: false,
            };

        case types.BILLINGS_UPDATE_SEATS_REQUEST:
            return {
                ...state,
                updatingSeats: true,
            };
        case types.BILLINGS_UPDATE_SEATS_SUCCESS:
            return {
                ...state,
                updatingSeats: false,
            };
        case types.BILLINGS_UPDATE_SEATS_FAILURE:
            return {
                ...state,
                updatingSeats: false,
            };

        default:
            return state;
    }
};

export default BillingsReducer;

export const types = 
{
    PRODUCT_CHANGE_STATE: "PRODUCT_CHANGE_STATE",

    PRODUCT_GET_CATEGORIES_REQUEST: "PRODUCT_GET_CATEGORIES_REQUEST",
    PRODUCT_GET_CATEGORIES_SUCCESS: "PRODUCT_GET_CATEGORIES_SUCCESS",
    PRODUCT_GET_CATEGORIES_FAILURE: "PRODUCT_GET_CATEGORIES_FAILURE",

    PRODUCT_SEARCH_CATEGORY_REQUEST: "PRODUCT_SEARCH_CATEGORY_REQUEST",
    PRODUCT_SEARCH_CATEGORY_SUCCESS: "PRODUCT_SEARCH_CATEGORY_SUCCESS",
    PRODUCT_SEARCH_CATEGORY_FAILURE: "PRODUCT_SEARCH_CATEGORY_FAILURE",

    PRODUCT_GET_CATEGORY_REQUEST: "PRODUCT_GET_CATEGORY_REQUEST",
    PRODUCT_GET_CATEGORY_SUCCESS: "PRODUCT_GET_CATEGORY_SUCCESS",
    PRODUCT_GET_CATEGORY_FAILURE: "PRODUCT_GET_CATEGORY_FAILURE",

    PRODUCT_CREATE_CATEGORY_REQUEST: "PRODUCT_CREATE_CATEGORY_REQUEST",
    PRODUCT_CREATE_CATEGORY_SUCCESS: "PRODUCT_CREATE_CATEGORY_SUCCESS",
    PRODUCT_CREATE_CATEGORY_FAILURE: "PRODUCT_CREATE_CATEGORY_FAILURE",

    PRODUCT_UPDATE_CATEGORY_REQUEST: "PRODUCT_UPDATE_CATEGORY_REQUEST",
    PRODUCT_UPDATE_CATEGORY_SUCCESS: "PRODUCT_UPDATE_CATEGORY_SUCCESS",
    PRODUCT_UPDATE_CATEGORY_FAILURE: "PRODUCT_UPDATE_CATEGORY_FAILURE",

    PRODUCT_DELETE_CATEGORY_REQUEST: "PRODUCT_DELETE_CATEGORY_REQUEST",
    PRODUCT_DELETE_CATEGORY_SUCCESS: "PRODUCT_DELETE_CATEGORY_SUCCESS",
    PRODUCT_DELETE_CATEGORY_FAILURE: "PRODUCT_DELETE_CATEGORY_FAILURE",

    PRODUCT_GET_PRODUCTS_REQUEST: "PRODUCT_GET_PRODUCTS_REQUEST",
    PRODUCT_GET_PRODUCTS_SUCCESS: "PRODUCT_GET_PRODUCTS_SUCCESS",
    PRODUCT_GET_PRODUCTS_FAILURE: "PRODUCT_GET_PRODUCTS_FAILURE",

    // PRODUCT_SEARCH_PRODUCT_REQUEST: "PRODUCT_SEARCH_PRODUCT_REQUEST",
    // PRODUCT_SEARCH_PRODUCT_SUCCESS: "PRODUCT_SEARCH_PRODUCT_SUCCESS",
    // PRODUCT_SEARCH_PRODUCT_FAILURE: "PRODUCT_SEARCH_PRODUCT_FAILURE",
    
    PRODUCT_GET_PRODUCT_REQUEST: "PRODUCT_GET_PRODUCT_REQUEST",
    PRODUCT_GET_PRODUCT_SUCCESS: "PRODUCT_GET_PRODUCT_SUCCESS",
    PRODUCT_GET_PRODUCT_FAILURE: "PRODUCT_GET_PRODUCT_FAILURE",

    PRODUCT_CREATE_PRODUCT_REQUEST: "PRODUCT_CREATE_PRODUCT_REQUEST",
    PRODUCT_CREATE_PRODUCT_SUCCESS: "PRODUCT_CREATE_PRODUCT_SUCCESS",
    PRODUCT_CREATE_PRODUCT_FAILURE: "PRODUCT_CREATE_PRODUCT_FAILURE",

    PRODUCT_UPDATE_PRODUCT_REQUEST: "PRODUCT_UPDATE_PRODUCT_REQUEST",
    PRODUCT_UPDATE_PRODUCT_SUCCESS: "PRODUCT_UPDATE_PRODUCT_SUCCESS",
    PRODUCT_UPDATE_PRODUCT_FAILURE: "PRODUCT_UPDATE_PRODUCT_FAILURE",

    PRODUCT_DELETE_PRODUCT_REQUEST: "PRODUCT_DELETE_PRODUCT_REQUEST",
    PRODUCT_DELETE_PRODUCT_SUCCESS: "PRODUCT_DELETE_PRODUCT_SUCCESS",
    PRODUCT_DELETE_PRODUCT_FAILURE: "PRODUCT_DELETE_PRODUCT_FAILURE",

    PRODUCT__REQUEST: "PRODUCT__REQUEST",
    PRODUCT__SUCCESS: "PRODUCT__SUCCESS",
    PRODUCT__FAILURE: "PRODUCT__FAILURE",
}
import { TBreak, TWeekDay } from "../hrm";

export const WeekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const WeekDaysStart = [
    {
        weekday: "Sunday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
    {
        weekday: "Monday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
    {
        weekday: "Tuesday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
    {
        weekday: "Wednesday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
    {
        weekday: "Thursday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
    {
        weekday: "Friday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
    {
        weekday: "Saturday" as TWeekDay["weekday"],
        start: "00:00",
        end: "00:00",
        breaks: [] as TBreak[],
    },
];

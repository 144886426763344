import React, { useContext, useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useError from "@soltivo/draw-a-line/core/hooks/useError";
import { Uneye, Eye } from "@soltivo/draw-a-line/core/components/icons";
import { Button, InputText } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeSUForm } from "../../context/actions";
import { ReactComponent as SteveHello } from "@soltivo/draw-a-line/core/assets/illutrations/SteveHello.svg";
import { RootState } from "../../../../redux/reducers";

interface SignUpProps extends MapStateToProps {}

/**
 * @description this is the first step for sign up.
 * if user passes the user will be moved to phone.number page.
 */
const SignUp: React.FC<SignUpProps> = ({ error }) => {
    const {
        state: { SUForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);

    const history = useHistory();
    const [typing, setTyping] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [passwordError, setpasswordError] = useState("");

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatchContext(authOnChangeSUForm({ ...form, [e.target.name]: e.target.value }));

    useEffect(() => {
        if (form.password) {
            if (!new RegExp("^(?=.*[a-z])").test(form.password)) {
                setpasswordError("You are missing small alphabetical letters.");
            } else if (!new RegExp("^(?=.*[A-Z])").test(form.password)) {
                setpasswordError("You are missing capital letter.");
            } else if (!new RegExp("^(?=.*[0-9])").test(form.password)) {
                setpasswordError("You are missing number.");
            } else if (!new RegExp("^(?=.*[!@#$%^&*])").test(form.password)) {
                setpasswordError("You are missing special character.");
            } else if (form.password.trim().length < 8) {
                setpasswordError("Password most have 8 or more characters");
            } else {
                setpasswordError("");
            }
        }
    }, [form.password]);

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setTyping(true);

        if (form.email && !passwordError.length) {
            setSubmited(true);
            history.push("/phone-validation");
        }
    };

    /**
     * @description Change the input type for the password to show or hide it
     */
    const [showPassword, setPassword] = useState(false);

    useError(error, [() => setSubmited(false)]);

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Create an account</h2>
                        
                        {Object.keys(form).map((input, index) => {
                            if (input === "email" || input === "password") {
                                return (
                                    <Fragment key={index}>
                                        <div className="auth-container__input-container">
                                            <InputText
                                                name={input}
                                                onChange={onChange}
                                                bottomBorderStyle={true}
                                                value={form[input]}
                                                placeholder={`${input.replace("_", " ")}`}
                                                type={input.match(/password/) ? (showPassword ? "text" : "password") : input.match(/email/) ? "email" : "text"}
                                                autoComplete="off"
                                                required
                                                className={`auth-container__input ${input === "password" ? "password" : ""} ${input === "password" && typing && passwordError ? "form-invalid" : ""}`}
                                            />
                                            {input === "password" ? showPassword ? <Uneye onClick={() => setPassword(!showPassword)} /> : <Eye onClick={() => setPassword(!showPassword)} /> : ""}
                                        </div>
                                        {input === "password" &&
                                            (typing && passwordError ? (
                                                <p className="error__text">{input === "password" && typing && passwordError}</p>
                                            ) : (
                                                <p className="info__text">
                                                    {/* {input === "password" && (
                                                        <span>
                                                            Use 8 or more characters with a mix of
                                                            <br /> letters, numbers & symbols
                                                        </span>
                                                    )} */}
                                                </p>
                                            ))}
                                    </Fragment>
                                );
                            } else {
                                return null;
                            }
                        })}
                        <div className="auth-container__button-container">
                            <span className="auth-container__link--create-account">
                                Already have an account?  {" "}
                                <Link to="/login">
                                Login here
                                </Link>
                            </span>
                        </div>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="auth-container__button-primary signup__btn" disabled={submited}>
                            Sign Up
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <SteveHello />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(SignUp);

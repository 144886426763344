import React, { useContext, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { authSignUp, authChangePhoneNumberValidation } from "../../redux/auth/reducer/actions";
import useError from "../../../../helpers/hooks/useError";
import "../../assets/styles/auth.style.scss";
import { Button, InputPhone } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeSUForm } from "../../context/actions";
import { ReactComponent as PhoneCaracter } from "@soltivo/draw-a-line/core/assets/illutrations/SteveTypeInPhone.svg";
import { RootState } from "../../../../redux/reducers";

interface PhoneValidationProps extends MapStateToProps {}

/**
 * @description sets the phone number of the user and hits the aws api for sign up
 * which will send a message code to user's cellphone and then user will be moved
 * to phone.code component.
 */
const PhoneValidation: React.FC<PhoneValidationProps> = ({ error }) => {
    const location = useLocation<{changingPhone: boolean}>();
    const {
        state: { SUForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);

    const dispatch = useDispatch();
    const [submited, setSubmited] = useState(false);
    const changingPhoneNumber = location.state?.changingPhone;

    /**
     * @description change form input values.
     */
    const onChange = (value: string) => dispatchContext(authOnChangeSUForm({ ...form, phone_number: value }));

    /**
     * @description on submit sign up form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!changingPhoneNumber) {
            dispatch(
                authSignUp({
                    email: form.email,
                    password: form.password,
                    phone_number: `+${form.phone_number}`,
                })
            );
        } else {
            // When the user update his password
            dispatch(authChangePhoneNumberValidation({
                phoneNumber: `+${form.phone_number}`,
                userId: form.email
            }))
        }
        setSubmited(true);
    };

    useError(error, [() => setSubmited(false)]);

    if (!form.email || !form.password) {
        return <Redirect to="/sign-up" />;
    }

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">{changingPhoneNumber ? "Change phone number" : "Verify your identity"}</h2>
                        <p className="auth-container__desc">Your phone number is for verification purposes only. We<br/> do not send promotional SMS.</p>

                        <div className="auth-container__input-container">
                            <InputPhone
                                country={"ca"}
                                preferredCountries={["us", "ca", "fr"]}
                                inputProps={{
                                    name: "phone_number",
                                    required: true,
                                    autoFocus: true,
                                    autoComplete: "off",
                                }}
                                value={form.phone_number}
                                onChange={onChange}
                            />
                        </div>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="auth-container__button-primary" disabled={submited}>
                            {changingPhoneNumber ? "Change & Send code" : "Send Code "}
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <PhoneCaracter />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PhoneValidation);

import { soltivoHelper } from "@soltivo/draw-a-line";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchUserAttributes, confirmUserAttribute, sendUserAttributeVerificationCode, type ConfirmUserAttributeInput, type VerifiableUserAttributeKey, type AuthSession, updateUserAttributes, signIn, signUp, confirmSignIn, confirmSignUp, resendSignUpCode, signOut, updatePassword, resetPassword, confirmResetPassword, fetchAuthSession, autoSignIn } from "aws-amplify/auth";
import apiV2 from "../../../../../helpers/api.v2";
var _URL = `${process.env.REACT_APP_API_URL}/public-profile`;

/**
import { type ResourcesConfig } from 'aws-amplify';
 * @description Authentication API calls.
 */
class AuthClass {
    constructor() {
        if (process.env.NODE_ENV !== "production") {
            Hub.listen("auth", (data: any) => {
                console.log(data);
            });
        }
    }

    /**
     * @description check the current autheticated user.
     */
    authCurrentAuthenticatedUser() {
        return getCurrentUser();
    }

    /**
     * @description get user attributes
     * */
    authGetUserAttributes() {
        return fetchUserAttributes();
    }

    /**
     * @description submit code recevied from verify current user attribute.
     */
    authVerifyUserAttributeSubmit({ attribute, code }: { attribute: ConfirmUserAttributeInput["userAttributeKey"]; code: string }) {
        return confirmUserAttribute({ userAttributeKey: attribute, confirmationCode: code });
    }

    /**
     * @description verify user attribute.
     */
    authVerifyUserAttribute({ attribute }: { attribute: VerifiableUserAttributeKey }) {
        return sendUserAttributeVerificationCode({ userAttributeKey: attribute });
    }

    /**
     * @description update user attributes.
     */
    authUpdateUserAttributes({ attributes }: { attributes: object }) {
        return updateUserAttributes({
            userAttributes: attributes
        });
    }

    /**
     * @description sign in user.
     */
    authSignIn({ email, password }: { email: string; password: string }) {
        return signIn({ username: email, password });
    }

    /**
     * @description auto sign in user after signup.
     */
    authAutauSignIn() {
        return autoSignIn();
    }

    /**
     * @description create user account.
     */
    authSignUp({ email, phone_number, password }: { email: string; phone_number: string; password: string }) {
        return signUp({
            username: email,
            password: password,
            options: {
                userAttributes: {
                    email,
                    phone_number,
                },
            },
        });
    }

    /**
     * @description update phone number validation
     */
    authChangePhoneNumberValidation(payload: { phoneNumber: string; userId: string }) {
        return apiV2.postRequest(`${_URL}/registration/phone`, {
            useToken: false,
            data: JSON.stringify(payload),
            useOrg: false,
        });
    }

    /**
     * @description signin employee account first time
     */
    authOnBoardEmployeeSignin({ email, oldPassword }: { email: string; oldPassword: string }) {
        return signIn({ username: email, password: oldPassword });
    }
    /**
     * @description Employee Change password first time
     */
    authOnBoardEmployeeChangePassword({ password, step }: { password: string, step: string }) {
        if (step === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
            return confirmSignIn({ challengeResponse: password });
        }
    }

    /**
     * @description verify user after sign up.
     */
    authConfirmSignUp({ email, code }: { email: string; code: string }) {
        if (code.length < 6) {
            throw {
                code: "InvalidCodeException",
                message: "Invalid code. try again.",
            };
        } else if (!email) {
            throw {
                code: "InvalidEmailException",
                message: "Email not found, please try to create your account later.",
            };
        }

        return confirmSignUp({ username: email, confirmationCode: code });
    }

    /**
     * @description resend code to confirm account created in sign up.
     */
    authResendCodeSignUp({ email }: { email: string }) {
        return resendSignUpCode({ username: email });
    }

    /**
     * @description logout user.
     */
    authLogout() {
        return signOut();
    }

    /**
     * @description change user password
     */
    authChangeUserPassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) {
        return updatePassword({ oldPassword, newPassword });
    }

    /**
     * @description user forgot password & send code to user cellphone.
     * @returns Promise
     */
    authForgotPassword({ email }: { email: string }) {
        if (soltivoHelper.validateEmail(email)) {
            throw {
                code: "InvalidEmailException",
                message: soltivoHelper.validateEmail(email),
            };
        }
        return resetPassword({ username: email });
    }

    /**
     * @description user forgot password submit.
     */
    authForgotPasswordSubmit({ email, code, new_password, confirm_password }: { email: string; code: string; new_password: string; confirm_password: string }) {
        if (soltivoHelper.validateEmail(email)) {
            throw {
                code: "InvalidEmailException",
                message: soltivoHelper.validateEmail(email),
            };
        } else if (new_password !== confirm_password) {
            throw {
                code: "PasswordDoesNotMatch",
                message: "New password and confirm password fields does not match.",
            };
        }

        return confirmResetPassword({ username: email, confirmationCode: code, newPassword: new_password});
    }

    /**
     * @description get current session, this is also used to refresh the tokens.
     */
    authCurrentSession(forceRefresh?: boolean) {
        return fetchAuthSession({ forceRefresh });
    }

    /**
     * @description refresh token
     */
    async authRefreshToken(
        calback?: (
            /**
             * @description if error is not undefined something bad happened in the refresh token request.
             */
            error?: any
        ) => void
    ) {
        try {
            /* V6: Note that session will no longer contain refreshToken and clockDrift
            The fetchAuthSession API automatically refreshes the user's session when the authentication tokens have expired and a valid refreshToken is present.*/
            const { tokens: session } = await this.authCurrentSession(true);
            
            if (!session || !session.idToken) {
                throw ("Unable to refresh token without session");
            }
            const { idToken, accessToken } : AuthSession["tokens"] = session;

            localStorage.setItem("token", accessToken.toString());
            localStorage.setItem("idToken", idToken.toString());
            if (calback) calback("Unable to refresh token without session");
        } catch (error) {
            throw error;
        }
    }
}

export default new AuthClass();

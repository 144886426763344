import apiV2 from "../../../../../helpers/api.v2";

var _URL = `${process.env.REACT_APP_API_URL}/profile`;
// var _URL_PUBLIC = `${process.env.REACT_APP_API_URL}/public-profile`;

/**
 * @description Authentication API calls.
 */
class AdminClass {
    /**
     * @description api to get the status of user's profile
     */
    profileInfo() {
        return apiV2.getRequest(`${_URL}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update admin profile
     */
    updateAdminInfo(payload: { firstName: string; lastName: string; phoneNumber: string; hourlyRate: number; avatar: string; email: string }) {
        return apiV2.postRequest(`${_URL}/admin/profile`, {
            useToken: "accessToken",
            data: JSON.stringify({
                firstName: payload.firstName,
                lastName: payload.lastName,
                hourlyRate: payload.hourlyRate,
                avatar: payload.avatar,
                phoneNumber: payload.phoneNumber,
                email: payload.email,
            }),
        });
    }

    /**
     * @description upload profile pic
     */
    getSignedUrl(payload: { file: File }) {
        return apiV2.getRequest(`${_URL}/url?contentType=${payload.file.type}&cacheControl=max-age=300,public`);
    }
}

export default new AdminClass();

/**
 * @description allow to create industry object.
 */
class Industry {
    protected industry = {
        id: "",
        name: "",
        subs: [
            {
                id: "other",
                name: "Other",
            },
        ],
    };

    /**
     * @description create industry object.
     */
    public createIndustry(id: Industry["industry"]["id"], name: Industry["industry"]["name"], subs: Industry["industry"]["subs"]) {
        this.industry.id = id;
        this.industry.name = id;
        this.industry.subs = subs;
        return this.industry;
    }

    /**
     * @description create industry id for organization.
     * @note subId is "other" by default.
     * @returns "{industryId}_{subId}"
     */
    public createIndustryId(industryId: string, subId: string = "") {
        return industryId === "other" ? "other" : `${industryId}_${subId}`;
    }
}

export { Industry };

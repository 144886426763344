import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputSelect, InputSwitch } from "@soltivo/draw-a-line";
import "./create.schedule.scss";
import { RootState } from "../../../../../../redux/reducers";
import { Location, Organization } from "../../../../../../applications/auth/organization";
import GuideOrgContext from "../../../context/guide.org.context";
import { guideChangeAction } from "../../../context/actions";
import { User } from "../../../../../../applications/auth/auth";
import useGuideHeading from "../../../hooks/useGuideHeading";
import ConfigSchedule from "./config.schedule";
import { TWeekDay } from "../../../../../../applications/hrm/hrm";
import { orgSetup } from "../../../../../../applications/auth/redux/org/reducer/actions";
import { hrmGetEndStart__helper } from "../../../../../../applications/hrm/helpers/helpers";
import { StepEmployeesView } from "../step.employees/step.employees";
import { Employee } from "@soltivo/types";

export interface CreateScheduleProps {
    user: User;
    employee: Employee | User;
    onChangeView: (view: StepEmployeesView) => void;
}

const WEEKDAYS: {
    id: number;
    name: TWeekDay["weekday"];
}[] = [
    { id: 1, name: "Monday" },
    { id: 5, name: "Friday" },
    { id: 2, name: "Tuesday" },
    { id: 6, name: "Saturday" },
    { id: 3, name: "Wednesday" },
    { id: 7, name: "Sunday" },
    { id: 4, name: "Thursday" },
];

const CreateSchedule: React.FC<CreateScheduleProps> = ({ user, employee, onChangeView }) => {
    const isUser = user.userId === employee.userId;

    const dispatch = useDispatch();
    const initialWeekdays: TWeekDay[] = [
        {
            weekday: "Sunday",
            start: "00:00",
            end: "00:00",
        },
        {
            weekday: "Monday",
            start: "00:00",
            end: "00:00",
        },
        {
            weekday: "Tuesday",
            start: "00:00",
            end: "00:00",
        },
        {
            weekday: "Wednesday",
            start: "00:00",
            end: "00:00",
        },
        {
            weekday: "Thursday",
            start: "00:00",
            end: "00:00",
        },
        {
            weekday: "Friday",
            start: "00:00",
            end: "00:00",
        },
        {
            weekday: "Saturday",
            start: "00:00",
            end: "00:00",
        },
    ];

    const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
        user: AuthReducer.user as User,
        org: OrgReducer.org as Organization,
        tour: OrgReducer.tour,
    });

    const { org } = useSelector(mapStateToProps);

    const { dispatch: dispatchContext } = useContext(GuideOrgContext);

    const [location, setLocation] = useState<Location>();

    const [selectedOption, setSelectedOption] = useState<1 | 2>();
    const [selectedDays, setSelectedDays] = useState<{ id: number; name: TWeekDay["weekday"] }[]>([]);
    const [view, setView] = useState<"INITIAL" | "DAYS_SELECTION" | "DAYS_CONFIG" | "BUSINESS_HOURS">("INITIAL");
    const [workingDays, setWorkingDays] = useState<TWeekDay[]>(initialWeekdays);
    const [manualCheck, setManualCheck] = useState(false);

    /** Check days with business hours when user select business hour */
    useEffect(() => {
        if (selectedDays.length) return; // Do not check default days if some days are already checked
        if (view === "DAYS_SELECTION" && selectedOption === 1) {
            const businessHours = location?.businessHours;
            if (businessHours && businessHours.length) {
                setWorkingDays(
                    businessHours.map((d) => ({
                        weekday: d.day,
                        start: d.start,
                        end: d.end,
                        breaks: [],
                    }))
                );
                setSelectedDays([...businessHours.map((d, i) => ({ id: i + 1, name: d.day }))]);
            }
        }
    }, [view, selectedDays, selectedOption]);

    // useEffect(() => {
    //     if (!manualCheck) return;
    //     const workingDays: TWeekDay[] = selectedDays
    //         .sort((a, b) => (a.id < b.id ? -1 : 0))
    //         .map((day) => {
    //             const dayName = day.name as TWeekDay["weekday"];
    //             return {
    //                 weekday: dayName,
    //                 start: "00:00",
    //                 end: "00:00",
    //                 breaks: [],
    //             };
    //         });
    //     setWorkingDays(workingDays);
    // }, [selectedDays]);

    useGuideHeading(
        view === "BUSINESS_HOURS"
            ? {
                  title: isUser ? "Your schedule" : `${employee.firstName}'s schedule`,
                  description: `You have multiple locations, which one do you want to apply?`,
              }
            : view === "DAYS_CONFIG"
            ? {
                  title: isUser ? "Your schedule" : `${employee.firstName}'s schedule`,
                  description: isUser ? `At what time are you working?` : `At what time is ${employee.firstName} working on those days?`,
              }
            : view === "DAYS_SELECTION"
            ? {
                  title: isUser ? "Your schedule" : `${employee.firstName}'s schedule`,
                  description: isUser ? `What are the days of the week where you are working?` : `What are the days of the week where ${employee.firstName} is working?`,
              }
            : {
                  title: isUser ? "Your schedule" : `${employee.firstName}'s schedule`,
                  description: isUser ? `Do you have the same schedule as your business hours?` : `Does ${employee.firstName} has the same schedule as your business hours?`,
              }
    );

    useEffect(() => {
        if (view === "DAYS_SELECTION") {
            dispatchContext(
                guideChangeAction({
                    type: "next",
                    payload: {
                        disabled: selectedOption === 2 ? Boolean(!selectedDays.length || !location) : Boolean(!location),
                        onClick: () => {
                            if (selectedOption === 2) {
                                setView("DAYS_CONFIG");
                                dispatchContext(
                                    guideChangeAction({
                                        type: "next",
                                        payload: {
                                            disabled: true,
                                        },
                                    })
                                );
                            } else if (selectedOption === 1 && location) {
                                dispatch(
                                    orgSetup({
                                        currentSubStep: "schedule",
                                        nextSubStep: "employeeServices",
                                        data: {
                                            employeeSchedule: {
                                                userId: employee.userId,
                                                timezone: org.timeFormat,
                                                orgId: org.orgId,
                                                weekdays: location.businessHours.map((weekday) => [
                                                    {
                                                        start: weekday.start,
                                                        end: weekday.end,
                                                        locationId: location.id,
                                                    },
                                                ]),
                                            },
                                        },
                                    })
                                );
                            }
                        },
                    },
                })
            );
        } else if (view === "DAYS_CONFIG") {
            let invalid = false;
            for (let i = 0; i < workingDays.length; i++) {
                let forWork = workingDays[i];
                for (let b = 0; b < selectedDays.length; b++) {
                    let selected = selectedDays[b];
                    if (forWork.weekday.toLowerCase() === selected.name.toLowerCase()) {
                        const { start: xStart, end: xEnd } = hrmGetEndStart__helper({
                            start: forWork.start,
                            end: forWork.end,
                        });

                        if (forWork.start === "00:00" && forWork.end === "00:00") {
                            invalid = true;
                        } else if (xEnd < xStart) {
                            invalid = true;
                        }
                    }
                }
            }

            if (!location) {
                invalid = true;
            }

            dispatchContext(
                guideChangeAction({
                    type: "next",
                    payload: {
                        disabled: invalid,
                        onClick: () => {
                            if (invalid || !location) return;
                            // Remove schedules on unselected days
                            const daysSelected = selectedDays.map((d) => d.name);
                            const _workingDays = workingDays.map((workingday) => {
                                if (!daysSelected.includes(workingday.weekday)) {
                                    workingday.start = "00:00";
                                    workingday.end = "00:00";
                                }
                                return workingday;
                            });
                            dispatch(
                                orgSetup({
                                    currentSubStep: "schedule",
                                    nextSubStep: "employeeServices",
                                    data: {
                                        employeeSchedule: {
                                            userId: employee.userId,
                                            timezone: org.timeFormat,
                                            orgId: org.orgId,
                                            weekdays: _workingDays.map((weekday) => [
                                                {
                                                    start: weekday.start,
                                                    end: weekday.end,
                                                    locationId: location.id,
                                                },
                                            ]),
                                        },
                                    },
                                })
                            );
                        },
                    },
                })
            );
        }
    }, [selectedDays, location, view, workingDays]);

    useEffect(() => {
        dispatchContext(
            guideChangeAction({
                type: "back",
                payload: {
                    onClick: () => {
                        if (view === "INITIAL") {
                            onChangeView("list");
                        } else if (view === "DAYS_SELECTION") {
                            setView("INITIAL");
                        } else {
                            if (view === "DAYS_CONFIG") {
                                setView("DAYS_SELECTION");
                            } else {
                                setView("INITIAL");
                            }

                            dispatchContext(
                                guideChangeAction({
                                    type: "next",
                                    payload: {
                                        disabled: false,
                                    },
                                })
                            );
                        }
                    },
                },
            })
        );
    }, [view, selectedOption]);

    const onCheck = (e: React.ChangeEvent<HTMLInputElement>, day: { id: number; name: TWeekDay["weekday"] }) => {
        if (e.target.checked) {
            setSelectedDays((previous) => [...previous, day]);
        } else {
            setSelectedDays((previous) => [...previous.filter((d) => d.name !== day.name)]);
        }
        if (!manualCheck) setManualCheck(true);
    };

    const onSelectOption = (option: 1 | 2) => {
        setSelectedOption(option);
        dispatchContext(
            guideChangeAction({
                type: "next",
                payload: {
                    disabled: false,
                    onClick: () => {
                        if (option === 1) {
                            if (org.locations.length === 1) {
                                dispatch(
                                    orgSetup({
                                        currentSubStep: "schedule",
                                        nextSubStep: "employeeServices",
                                        data: {
                                            employeeSchedule: {
                                                userId: employee.userId,
                                                timezone: org.timeFormat,
                                                orgId: org.orgId,
                                                weekdays: org.locations[0].businessHours.map((weekday) => [
                                                    {
                                                        start: weekday.start,
                                                        end: weekday.end,
                                                        locationId: org.locations[0].id,
                                                    },
                                                ]),
                                            },
                                        },
                                    })
                                );
                            } else {
                                setView("DAYS_SELECTION");
                                setLocation(undefined);
                                dispatchContext(
                                    guideChangeAction({
                                        type: "back",
                                        payload: {
                                            disabled: false,
                                            onClick: undefined,
                                        },
                                    })
                                );
                            }
                        } else {
                            setView("DAYS_SELECTION");
                            setLocation(undefined);
                            dispatchContext(
                                guideChangeAction({
                                    type: "next",
                                    payload: {
                                        disabled: true,
                                        onClick: undefined,
                                    },
                                })
                            );
                        }
                    },
                },
            })
        );
    };

    useEffect(() => {
        // automatically select location if only one location
        if (org.locations.length === 1 && view === "DAYS_SELECTION") {
            setLocation(org.locations[0]);

            const workingDays = org.locations[0].businessHours.map((weekday) => ({
                breaks: [],
                end: weekday.end,
                start: weekday.start,
                weekday: weekday.day,
            }));

            setWorkingDays(workingDays);

            setSelectedDays((_state) =>
                workingDays
                    .filter((day) => day.start !== "00:00" && day.end !== "00:00")
                    .map((day, index) => ({
                        id: index + 1,
                        name: day.weekday,
                    }))
            );

            dispatchContext(
                guideChangeAction({
                    type: "next",
                    payload: {
                        disabled: selectedOption === 1 ? false : true,
                        onClick: () => {
                            if (selectedOption === 1) {
                                setView("DAYS_SELECTION");

                                dispatchContext(
                                    guideChangeAction({
                                        type: "next",
                                        payload: {
                                            disabled: false,
                                        },
                                    })
                                );
                            } else {
                                setView("DAYS_CONFIG");
                            }
                        },
                    },
                })
            );
        }
    }, [org.locations, view]);

    let locationOptions: { value: string; option: any }[] = org.locations.map((loc) => ({ value: loc.name, option: loc }));

    if (view === "DAYS_CONFIG") {
        return <ConfigSchedule workingDays={workingDays} setWorkingDays={setWorkingDays} selectedWeekdays={selectedDays} />;
    }

    if (view !== "INITIAL") {
        return (
            <div id="setup__schedules">
                {view === "DAYS_SELECTION" && org.locations.length > 1 ? (
                    <div className="input-select-wrapper">
                        <label>{selectedOption === 1 ? "Business hours" : "Location"}</label>
                        <InputSelect
                            options={locationOptions}
                            value={location?.name || ""}
                            onChange={({ option }) => {
                                setLocation(option);
                                const workingDays = (option as Organization["locations"][0]).businessHours.map((weekday) => ({
                                    breaks: [],
                                    end: weekday.end,
                                    start: weekday.start,
                                    weekday: weekday.day,
                                }));
                                setWorkingDays(workingDays);

                                setSelectedDays((_state) =>
                                    workingDays
                                        .filter((day) => day.start !== "00:00" && day.end !== "00:00")
                                        .map((day, index) => ({
                                            id: index + 1,
                                            name: day.weekday,
                                        }))
                                );

                                dispatchContext(
                                    guideChangeAction({
                                        type: "next",
                                        payload: {
                                            disabled: selectedOption === 1 ? false : true,
                                            onClick: () => {
                                                if (selectedOption === 1) {
                                                    setView("DAYS_SELECTION");

                                                    dispatchContext(
                                                        guideChangeAction({
                                                            type: "next",
                                                            payload: {
                                                                disabled: false,
                                                            },
                                                        })
                                                    );
                                                } else {
                                                    setView("DAYS_CONFIG");
                                                }
                                            },
                                        },
                                    })
                                );
                            }}
                        />
                    </div>
                ) : null}

                {view === "DAYS_SELECTION" && selectedOption === 2 ? (
                    <div className="days">
                        {WEEKDAYS.map((day, index) => (
                            <div key={index} className="day-item">
                                <p className="day">{day.name}</p>
                                <InputSwitch checked={selectedDays.map((d) => d.name).includes(day.name)} onChange={(e) => onCheck(e, day)} />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <div id="setup__schedules">
            <div className="schedule-options">
                <div className={`option option--1 ${selectedOption === 1 ? "selected-option" : ""}`} onClick={() => onSelectOption(1)}>
                    <p>
                        Yes, use the business <br /> hours
                    </p>
                </div>
                <div className={`option option--2 ${selectedOption === 2 ? "selected-option" : ""}`} onClick={() => onSelectOption(2)}>
                    <p>
                        {isUser ? (
                            <span>
                                No, I have a different <br /> schedule
                            </span>
                        ) : (
                            <span>
                                No, {employee.firstName} has a different <br /> schedule
                            </span>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CreateSchedule;

import authClass from "../applications/auth/redux/auth/saga/auth.class";
// import history from "../app/routes/history";
import { toastHelper } from "@soltivo/draw-a-line";

type Method = "GET" | "POST" | "PUT" | "DELETE";
type UseToken = "idToken" | "accessToken" | false;
type Data = string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined;

interface Options {
    method?: Method;
    data?: Data;
    useToken: UseToken;
    useOrg?: boolean;
    useResponseType?: "text" | "json" | "formData" | "blob" | "arrayBuffer";
    headers?: {
        "content-type"?: "application/json" | "application/x-www-form-urlencoded" | "multipart/form-data" | string;
        authorization?: string;
        orgid?: string;
    };
}

interface ErrorItem {
    domain: string;
    field: string;
    message: string;
    reason: string;
}

export interface ErrorResponseV2 {
    message: string;
    error: {
        code: number;
        errors: Array<ErrorItem>;
        message: string;
    };
}

class ErrorItem implements ErrorItem {
    constructor(payload: ErrorItem) {
        this.domain = payload.domain;
        this.field = payload.field;
        this.message = payload.message;
        this.reason = payload.reason;
    }
}

export class ErrorResponse implements ErrorResponseV2 {
    constructor(payload: ErrorResponseV2) {
        this.message = payload.message || "Something went wrong.";
        this.error = payload.error;
    }

    public message: ErrorResponseV2["message"] = "";
    public error: ErrorResponseV2["error"] = {
        errors: [],
        code: 0,
        message: "",
    };
}

export interface ValidationErrorAttributes {
    message: string;
    code?: number;
    flag?: "danger" | "success" | "warning" | "info";
}

/**
 * @description allows you to create custom errors object.
 */
export class ValidationError implements ValidationErrorAttributes {
    constructor(payload: ValidationErrorAttributes) {
        this.message = payload.message;
        this.code = payload.code;
        this.flag = payload.flag;
    }
    message: ValidationErrorAttributes["message"] = "";
    code: ValidationErrorAttributes["code"] = undefined;
    flag: ValidationErrorAttributes["flag"] = undefined;
}

const defaultOptions: Options = {
    useToken: "accessToken",
    useOrg: true,
    useResponseType: "json",
};

class ApiV2 {
    refreshTokenAttempts = 0;

    public get UnknownErroMessage() {
        return "Something went wrong.";
    }

    protected async request(
        method: Method,
        url: string,
        options: Options = {
            useToken: "accessToken",
            useOrg: true,
        }
    ): Promise<any> {
        try {
            const tokens = {
                accessToken: localStorage.getItem("token"),
                idToken: localStorage.getItem("idToken"),
            };
            const orgId: string | null = localStorage.getItem("orgId");
            const { headers, ...restOptions } = options;

            const _options: Options = {
                ...defaultOptions,
                ...restOptions,
            };

            const _headers = new Headers();
            _headers.append("content-type", headers?.["content-type"] ? headers["content-type"] : "application/json");
            if (_options.useToken) _headers.append("authorization", `Bearer ${tokens[_options.useToken]}`);
            if (_options.useOrg) {
                if (!orgId) throw new Error("organization id not defined.");
                _headers.append("orgid", orgId);
            }

            if (!window.fetch) {
                throw new Error("Your browser does not support fetch.");
            }

            let response = await fetch(url, {
                method: method,
                headers: _headers,
                body: options.data,
            });

            // if (response.status === 403) {
            //     const errorResponse = await response.json();
            //     if (errorResponse?.message === "User is not authorized to access this resource with an explicit deny") {
            //         history.push("/organization/403");
            //     }
            //     throw errorResponse;
            // }

            if (this.refreshTokenAttempts >= 5) {
                this.refreshTokenAttempts = 0;
                throw new Error("Failed to refresh token.");
            }

            if (response.status === 401 && _options.useToken) {
                const errorResponse = await response.json();
                if (errorResponse?.message === "Unauthorized" || errorResponse?.message === "Invalid Access Token" || errorResponse?.message === "Access Token has expired") {
                    return await new Promise(async (resolve, reject) => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("idToken");

                        console.log(`%c refreshing token...`, "font-weight:bold; color: rgb(255,0,0);");
                        this.refreshTokenAttempts = this.refreshTokenAttempts + 1;
                        return await authClass
                            .authRefreshToken((error) => {
                                if (error) {
                                    reject(error);
                                } else {
                                    console.log(`%c Token refreshed successfully.`, "font-weight:bold; color: rgb(0,255,0);");
                                    resolve(this.request(method, url, options));
                                }
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                } else {
                    console.log("errorResponse");
                    throw errorResponse;
                }
            }

            if (!response.ok) {
                const res = await response.json();
                if (res?.deprecated) {
                    console.warn(`DEPRECATED REQUEST: ${url}`);
                }
                throw res;
            }

            // refresh attempt.
            this.refreshTokenAttempts = 0;
            switch (_options.useResponseType) {
                case "text":
                    return await response.text();
                case "arrayBuffer":
                    return await response.arrayBuffer();
                case "json":
                    const res = await response.json();
                    if (res?.deprecated) {
                        console.warn(`DEPRECATED REQUEST: ${url}`);
                    }

                    return res;
                case "blob":
                    return await response.blob();
                case "formData":
                    return await response.formData();
                default:
                    return response;
            }
        } catch (error: any) {
            if (process.env.NODE_ENV === "development") {
                console.error(error);
            }

            if (typeof error === "string") {
                throw new ErrorResponse({
                    error: {
                        code: 400,
                        errors: [],
                        message: error,
                    },
                    message: error,
                });
            } else if (error?.error?.code) {
                if (error?.error?.errors.length) {
                    error.error.errors = error.error.errors.map((item: any) => {
                        return new ErrorItem(item);
                    });
                }

                throw new ErrorResponse({
                    error: error.error,
                    message: error.error.message,
                });
            } else if (error instanceof Error) {
                throw new ErrorResponse({
                    error: {
                        code: 400,
                        errors: [],
                        message: error.message,
                    },
                    message: error.message,
                });
            }

            throw error;
        }
    }

    /**
     * @description display all catch errors from api in toast.
     * @deprecated from now on we create the validation messages on FE. so do not rely on this helper.
     */
    toastAllErrors = (
        errorResponse: ErrorResponseV2,
        options: {
            startWithField?: boolean;
        } = {}
    ) => {
        const { startWithField } = options;
        if (errorResponse) {
            if (errorResponse?.error?.errors) {
                const errors = errorResponse?.error?.errors;
                errors.forEach((error) => {
                    toastHelper.toastStartContent("danger", `${startWithField && error.field ? error.field + " " : ""}${error.message}`);
                });
            }
        } else {
            toastHelper.toastStartContent("danger", `${this.UnknownErroMessage}`);
        }
    };

    /**
     * @description transform api errors messages to string.
     * @deprecated from now on we create the validation messages on FE. so do not rely on this helper.
     */
    stringifyErrors(errorResponse: ErrorResponseV2, code?: number | Array<number>): string | undefined {
        try {
            if (typeof code !== "number" && code) {
                return (
                    code
                        .map((c) => {
                            if (errorResponse?.error?.code === c) {
                                const stringErrors = errorResponse.error.errors?.map((error) => `${error.field || ""} ${error.message}`);
                                return stringErrors?.toString().replace(/,/g, "\n\n") || this.UnknownErroMessage;
                            }
                        })
                        ?.toString()
                        .replace(/,/g, "") || this.UnknownErroMessage
                );
            } else if (errorResponse.error.code === code) {
                const stringErrors = errorResponse.error.errors?.map((error) => `${error.field || ""} ${error.message}`);
                return stringErrors?.toString().replace(/,/g, "") || this.UnknownErroMessage;
            } else {
                const stringErrors = errorResponse.error?.errors?.map((error) => `${error.field || ""} ${error.message}`);
                return stringErrors?.toString().replace(/,/g, "") || this.UnknownErroMessage;
            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description get request
     */
    getRequest(url: string, options?: Options): Promise<any> {
        return this.request("GET", url, options);
    }

    /**
     * @description post request
     */
    postRequest(url: string, options?: Options): Promise<any> {
        return this.request("POST", url, options);
    }

    /**
     * @description put request
     */
    putRequest(url: string, options?: Options): Promise<any> {
        return this.request("PUT", url, options);
    }

    /**
     * @description delete request
     */
    deleteRequest(url: string, options?: Options): Promise<any> {
        return this.request("DELETE", url, options);
    }

    /**
     * @description abort controller request.
     * @important Key must be unique.
     */
    abortRequest(_controllerKey: string) {
        // if (this.abortControllers[controllerKey]) {
        //     this.abortControllers[controllerKey].abort();
        // } else {
        //     console.error(new Error(`[ApiV2]: Controller ${controllerKey} does not exist.`));
        // }
    }

    /**
     * @description upload file using assigned url policy
     * @param data data from get assigned url api
     * @param file File to upload
     */
    async postAssignedUrl(data: any, file: File) {
        let formData = new FormData();
        Object.keys(data.fields).forEach((key) => {
            formData.append(key, data.fields[key]);
        });

        formData.append("file", file);
        formData.append("Content-Type", file.type);
        formData.append("success_action_status", "200");
        formData.append("Cache-Control", "max-age=300,public");
        const res = await fetch(data.url, {
            method: "POST",
            body: formData,
        });

        if (res.status >= 200 && res.status < 500) {
            // set new value
            return {
                fileLink: `${process.env.REACT_APP_SOLTIVO_CDN}/${data.fields.key}`,
            };
        } else {
            throw res.json();
        }
    }

    /**
     * @description define a field using default value if value is undefined, this function
     * is useful if you want to define default values for not defined values in your api calls.
     */
    defaultFieldValue<V>(
        /**
         * @description the actual value.
         */
        val: V,
        defaultVal: V
    ) {
        return val !== undefined ? val : defaultVal;
    }
}

export { ApiV2 };
export default new ApiV2();

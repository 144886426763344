export const types = {
    NOTIFICATIONS_CHANGE_STATE: "NOTIFICATIONS_CHANGE_STATE",

    NOTIFICATIONS_GET_ALL_NOTIFICATIONS_REQUEST: "NOTIFICATIONS_GET_ALL_NOTIFICATIONS_REQUEST",
    NOTIFICATIONS_GET_ALL_NOTIFICATIONS_SUCCESS: "NOTIFICATIONS_GET_ALL_NOTIFICATIONS_SUCCESS",
    NOTIFICATIONS_GET_ALL_NOTIFICATIONS_FAILURE: "NOTIFICATIONS_GET_ALL_NOTIFICATIONS_FAILURE",

    NOTIFICATIONS_UPDATE_NOTIFICATION_REQUEST: "NOTIFICATIONS_UPDATE_NOTIFICATION_REQUEST",
    NOTIFICATIONS_UPDATE_NOTIFICATION_SUCCESS: "NOTIFICATIONS_UPDATE_NOTIFICATION_SUCCESS",
    NOTIFICATIONS_UPDATE_NOTIFICATION_FAILURE: "NOTIFICATIONS_UPDATE_NOTIFICATION_FAILURE",

    NOTIFICATIONS_GET_SETTINGS_REQUEST: "NOTIFICATIONS_GET_SETTINGS_REQUEST",
    NOTIFICATIONS_GET_SETTINGS_SUCCESS: "NOTIFICATIONS_GET_SETTINGS_SUCCESS",
    NOTIFICATIONS_GET_SETTINGS_FAILURE: "NOTIFICATIONS_GET_SETTINGS_FAILURE",

    NOTIFICATIONS_UPDATE_SETTINGS_REQUEST: "NOTIFICATIONS_UPDATE_SETTINGS_REQUEST",
    NOTIFICATIONS_UPDATE_SETTINGS_SUCCESS: "NOTIFICATIONS_UPDATE_SETTINGS_SUCCESS",
    NOTIFICATIONS_UPDATE_SETTINGS_FAILURE: "NOTIFICATIONS_UPDATE_SETTINGS_FAILURE",

    NOTIFICATIONS_DELETE_NOTIFICATION_REQUEST: "NOTIFICATIONS_DELETE_NOTIFICATION_REQUEST",
    NOTIFICATIONS_DELETE_NOTIFICATION_SUCCESS: "NOTIFICATIONS_DELETE_NOTIFICATION_SUCCESS",
    NOTIFICATIONS_DELETE_NOTIFICATION_FAILURE: "NOTIFICATIONS_DELETE_NOTIFICATION_FAILURE",

    NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_REQUEST: "NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_REQUEST",
    NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_SUCCESS: "NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_SUCCESS",
    NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_FAILURE: "NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_FAILURE",
};

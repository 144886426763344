import React, { useState, useEffect, useContext } from "react";
import { useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { authOnBoardEmployee, validateRegistration } from "../../redux/auth/reducer/actions";
import useError from "../../../../helpers/hooks/useError";
import { Uneye, Eye } from "../../../../app/components/icon";
import Button from "@soltivo/draw-a-line/core/components/buttons/button/button";
import authContext from "../../context/auth.context";
import { employeeOnChangeOnboarding } from "../../context/actions";
import { RootState } from "../../../../redux/reducers";
import { ReactComponent as AuthImg } from "@soltivo/draw-a-line/core/assets/illutrations/SteveWithKey.svg";
import { InputText } from "@soltivo/draw-a-line";

interface OnBoardEmployeeProps extends MapStateToProps {}
/**
 * @description validate the registation, if valid CONFIRM user and reset password.
 * if above check are successful redirect to addinfo page.
 */
const OnBoardEmployee: React.FC<OnBoardEmployeeProps> = ({ error }) => {
    const {
        state: { employeeForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);

    const dispatch = useDispatch();
    const history = useHistory();
    const [submited, setSubmited] = useState(false);

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "email") return;
        dispatchContext(employeeOnChangeOnboarding({ ...form, [e.target.name]: e.target.value }));
    };

    /**
     * @description on submit login and change password form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (form.email && form.password) {
            setSubmited(true);
            dispatch(
                authOnBoardEmployee({
                    email: form.email,
                    password: form.password,
                    oldPassword: form.oldPassword, //code in url.
                })
            );
        }
    };

    /**
     * @description Change the input type for the password to show or hide it
     */
    const [showPassword, setPassword] = useState(false);

    useError(error, [() => setSubmited(false)]);

    useEffect(() => {
        const { location } = history;
        if (location.search) {
            var searchParams = new URLSearchParams(location.search);

            dispatch(
                validateRegistration({
                    employeeId: searchParams.get("id") || "",
                    registrationId: searchParams.get("registerId") || "",
                })
            );

            const fields = {
                email: searchParams.get("email"),
                oldPassword: searchParams.get("code"),
            };
            if (fields.email && fields.oldPassword) {
                dispatchContext(
                    employeeOnChangeOnboarding({
                        ...form,
                        email: fields.email,
                        oldPassword: fields.oldPassword,
                        // id: searchParams.get("id"),
                    })
                );
            }
        }
    }, []);

    return (
        <div className="auth-container container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Create an account</h2>

                        {Object.keys(form).map((input, index) => {
                            if (input === "email" || input === "password") {
                                return (
                                    <div key={index} className="auth-container__input-container">
                                        <InputText
                                            bottomBorderStyle={true}
                                            name={input}
                                            onChange={input === "email" ? undefined : onChange}
                                            value={form[input as ReturnType<() => keyof typeof form>]}
                                            placeholder={`${input}`}
                                            type={input === "password" ? (showPassword ? "text" : "password") : "email"}
                                            autoComplete={"off"}
                                            required
                                            disabled={input === "email"}
                                            className={`auth-container__input ${input === "password" ? "password" : ""}`}
                                        />{" "}
                                        {input === "password" ? showPassword ? <Uneye onClick={() => setPassword(!showPassword)} /> : <Eye onClick={() => setPassword(!showPassword)} /> : ""}{" "}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="auth-container__button-primary" disabled={submited}>
                            Create account
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <p className="welcome-text">Welcome!</p>
                    <AuthImg className="auth-img" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(OnBoardEmployee);

import { DateSlot } from "../../appointment";
import { types } from "./types";
import { Appointment, AppointmentNotes, Event } from "@soltivo/types";
import { uniqBy } from "lodash";

export interface AppointmentState {
    error: any;
    /** Selected appointment */
    appointment: Appointment | null;
    /** Listing appointments */
    appointments: Appointment[];
    /**
     * Represent the last appointment from the appointments array, it will
     * be null if there's no more appointment to be fetched (API return empty object)
     * */
    lastAppointment: Appointment | null;
    /** isLoading a single appointment */
    loadingAppointment: boolean;
    /** isLoading many appointments */
    loadingAppointments: boolean;

    appointmentNotes: AppointmentNotes[];
    loadingAppointmentNotes: boolean;

    quickDates: string[];
    availableDates: string[];
    loadingAvailableDates: boolean;
    slots: DateSlot[];
    loadingSlots: boolean;
    loadingQuickDates: boolean;
    creatingAppointment: boolean;
    updatingAppointment: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    newAppointment: any;
    /**
     * @description appointments only for kanban view.
     */
    kanbanAppointments: {
        /** Global loading state for the entire Kanban */
        isLoading?: boolean;
        statuses: {
            [statusId: string]:
                | {
                      items: Appointment[];
                      lastItem: Appointment | null;
                      loading: boolean;
                  }
                | undefined;
        };
        filters?: {
            range?: {
                start: string;
                end: string;
            };
            userId?: string;
            entityId?: string;
            includeArchived?: boolean;
        };
    };
    /**
     * @description all status for appointments.
     */
    // statuses: {
    //     items: Statuses[];
    //     loading: boolean;
    //     /**
    //      * @description how many time this api was requested.
    //      */
    //     requestCount: number;
    //     loadingFirstTime: boolean;
    //     /**
    //      * @description moving index of columns around.
    //      */
    //     rearranging?: {
    //         previousItems?: Statuses[];
    //         loading: boolean;
    //         status?: "success" | "failed";
    //         message?: string;
    //     };
    //     /**
    //      * @description check if deleting status.
    //      */
    //     deleting: {
    //         loading: boolean;
    //         status?: "success" | "failed";
    //         message?: string;
    //     };
    //     renaming: boolean;
    // };
    deletingStatus: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    updateAppointmentNotes: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    personalCalendarAppointments: {
        list: Pick<Appointment, "date" | "end" | "entityInfo" | "id" | "serviceInfo" | "start" | "userId" | "status">[];
        loading: boolean;
    };
    deleteAppointment: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    creatingStatus: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    archiving: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    creatingPersonalEvent: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    /**
     * @description team events and events for a specific employee
     */
    teamEvents: {
        items: (Event | Appointment)[];
        loading: boolean;
    };
    updateEvent: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    rescheduleAppointment: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    deleteEvent: {
        loading: boolean;
        status?: "success" | "failed";
        message?: string;
    };
    eventsDateRange: {
        start: string;
        end: string;
    };
    newEvent?: Event;
}

const _INITIAL_STATE: AppointmentState = {
    error: null,
    lastAppointment: null,

    appointment: null,
    loadingAppointment: false,

    appointments: [],
    loadingAppointments: false,

    appointmentNotes: [],
    loadingAppointmentNotes: false,

    quickDates: [],
    availableDates: [],
    loadingAvailableDates: false,
    slots: [],
    loadingSlots: false,
    loadingQuickDates: false,
    creatingAppointment: false,
    updatingAppointment: {
        loading: false,
    },
    newAppointment: null,
    kanbanAppointments: {
        isLoading: false,
        statuses: {},
        filters: {
            includeArchived: false,
        },
    },
    deletingStatus: {
        loading: false,
    },
    updateAppointmentNotes: {
        loading: false,
    },
    personalCalendarAppointments: {
        list: [],
        loading: false,
    },
    deleteAppointment: {
        loading: false,
    },
    creatingStatus: {
        loading: false,
    },
    archiving: {
        loading: false,
    },
    creatingPersonalEvent: {
        loading: false,
    },
    teamEvents: {
        items: [],
        loading: false,
    },
    updateEvent: {
        loading: false,
    },
    deleteEvent: {
        loading: false,
    },
    rescheduleAppointment: {
        loading: false,
    },
    eventsDateRange: {
        start: "",
        end: "",
    },
};

const AppointmentReducer = (state = _INITIAL_STATE, action: { type: string; payload: any }): AppointmentState => {
    switch (action.type) {
        case types.APPOINTMENT_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...(action.payload as Partial<AppointmentState>),
            };

        case types.APPOINTMENT_SET_APPOINTMENT_REQUEST: {
            return {
                ...state,
                appointment: action.payload,
            }
        }

        case types.APPOINTMENT_GET_APPOINTMENT_REQUEST:
            return {
                ...state,
                appointment: null,
                loadingAppointment: true,
            };
        case types.APPOINTMENT_GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loadingAppointment: false,
                appointment: action.payload,
            };
        case types.APPOINTMENT_GET_APPOINTMENT_FAILURE:
            return {
                ...state,
                loadingAppointment: false,
                appointment: null,
            };

        case types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST: {
            return {
                ...state,
                kanbanAppointments: {
                    ...state.kanbanAppointments,
                    isLoading: true,
                    statuses: {
                        ...state.kanbanAppointments.statuses,
                        [action.payload.statusId]: {
                            items: action.payload.lastKey ? state?.kanbanAppointments?.statuses?.[action.payload.statusId]?.items || [] : [],
                            lastItem: null,
                            loading: true,
                        },
                    },
                },
            };
        }
        case types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_SUCCESS: {
            const appointments = state.kanbanAppointments?.statuses?.[action.payload.statusId]?.items || [];
            const kanbanAppointments = uniqBy([...appointments, ...action.payload?.data], "id");
            const lastItem = action.payload?.data?.length ? kanbanAppointments?.[kanbanAppointments.length - 1] : null;
            return {
                ...state,
                kanbanAppointments: {
                    ...state.kanbanAppointments,
                    isLoading: false,
                    statuses: {
                        ...state.kanbanAppointments.statuses,
                        [action.payload.statusId]: {
                            lastItem,
                            items: kanbanAppointments,
                            loading: false,
                        },
                    },
                },
            };
        }
        case types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_FAILURE: {
            return {
                ...state,
                kanbanAppointments: {
                    ...state.kanbanAppointments,
                    isLoading: false,
                    statuses: {
                        ...state.kanbanAppointments.statuses,
                        [action.payload.statusId]: {
                            items: action.payload.lastKey ? state?.kanbanAppointments?.statuses?.[action.payload.statusId]?.items || [] : [],
                            loading: false,
                        },
                    },
                },
            };
        }

        case types.APPOINTMENT_CREATE_APPOINTMENT_REQUEST:
            return {
                ...state,
                creatingAppointment: true,
                newAppointment: null,
            };
        case types.APPOINTMENT_CREATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                kanbanAppointments: action.payload.kanbanAppointments ? action.payload.kanbanAppointments : state.kanbanAppointments,
                creatingAppointment: false,
                newAppointment: action.payload?.newAppointment,
            };
        case types.APPOINTMENT_CREATE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
                creatingAppointment: false,
                newAppointment: null,
            };

        case types.APPOINTMENT_LIST_BY_ENTITY_ID_REQUEST:
            return {
                ...state,
                // When the request fails while fetching more we don't want to clear the state
                appointments: action.payload.lastKey ? state.appointments : [],
                lastAppointment: null,
                loadingAppointments: true,
            };
        case types.APPOINTMENT_LIST_BY_ENTITY_ID_SUCCESS:
            const appointments = uniqBy([...state.appointments, ...action.payload.items], "id");
            return {
                ...state,
                appointments,
                lastAppointment: action.payload.items.length ? appointments[appointments.length - 1] : null,
                loadingAppointments: false,
            };
        case types.APPOINTMENT_LIST_BY_ENTITY_ID_FAILURE:
            return {
                ...state,
                error: action.payload,
                // When the request fails while fetching more we don't want to clear the state
                appointments: action.payload.lastKey ? state.appointments : [],
                lastAppointment: null,
                loadingAppointments: false,
            };

        case types.APPOINTMENT_GET_QUICK_DATES_REQUEST:
            return {
                ...state,
                loadingQuickDates: true,
            };
        case types.APPOINTMENT_GET_QUICK_DATES_SUCCESS:
            return {
                ...state,
                quickDates: action.payload,
                loadingQuickDates: false,
            };
        case types.APPOINTMENT_GET_QUICK_DATES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingQuickDates: false,
                quickDates: [],
            };

        case types.APPOINTMENT_GET_AVAILABLE_DATES_REQUEST:
            return {
                ...state,
                loadingAvailableDates: true,
            };
        case types.APPOINTMENT_GET_AVAILABLE_DATES_SUCCESS:
            return {
                ...state,
                availableDates: action.payload,
                loadingAvailableDates: false,
            };
        case types.APPOINTMENT_GET_AVAILABLE_DATES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingAvailableDates: false,
            };

        case types.APPOINTMENT_GET_SLOTS_REQUEST:
            return {
                ...state,
                loadingSlots: true,
            };
        case types.APPOINTMENT_GET_SLOTS_SUCCESS:
            return {
                ...state,
                slots: action.payload,
                loadingSlots: false,
            };
        case types.APPOINTMENT_GET_SLOTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                slots: [],
                loadingSlots: false,
            };

        case types.APPOINTMENT_BULK_UPDATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
            };
        case types.APPOINTMENT_BULK_UPDATE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.APPOINTMENT_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                updatingAppointment: {
                    loading: true,
                },
            };

        case types.APPOINTMENT_UPDATE_STATUS_KANBAN_VIEW:
            return {
                ...state,
                kanbanAppointments: action.payload,
            };

        case types.APPOINTMENT_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                error: null,
                updatingAppointment: {
                    loading: false,
                    message: "Status updated successfully.",
                    status: "success",
                },
                appointments: action.payload.appointments,
                appointment: action.payload.appointment,
                personalCalendarAppointments: { ...action.payload.personalCalendarAppointments },
                teamEvents: {
                    ...state.teamEvents,
                    items: action.payload.teamEvents.items,
                },
            };
        case types.APPOINTMENT_UPDATE_STATUS_FAILURE:
            return {
                ...state,
                error: action.payload,
                updatingAppointment: {
                    loading: false,
                    message: "Status update failed.",
                    status: "failed",
                },
            };

        case types.APPOINTMENT_LIST_NOTE_REQUEST: {
            return {
                ...state,
                appointmentNotes: [],
                loadingAppointmentNotes: true,
            };
        }
        case types.APPOINTMENT_LIST_NOTE_SUCCESS: {
            return {
                ...state,
                appointmentNotes: action.payload,
                loadingAppointmentNotes: false,
            };
        }
        case types.APPOINTMENT_LIST_NOTE_FAILURE: {
            return {
                ...state,
                error: action.payload,
                loadingAppointmentNotes: false,
            };
        }

        case types.APPOINTMENT_CREATE_NOTE_REQUEST: {
            return {
                ...state,
                loadingAppointmentNotes: true,
            };
        }
        case types.APPOINTMENT_CREATE_NOTE_SUCCESS: {
            return {
                ...state,
                appointmentNotes: action.payload,
                loadingAppointmentNotes: false,
            };
        }
        case types.APPOINTMENT_CREATE_NOTE_FAILURE: {
            return {
                ...state,
                appointmentNotes: [],
                error: action.payload,
                loadingAppointmentNotes: false,
            };
        }

        case types.APPOINTMENT_UPDATE_NOTE_REQUEST: {
            return {
                ...state,
                loadingAppointmentNotes: true,
            };
        }
        case types.APPOINTMENT_UPDATE_NOTE_SUCCESS: {
            return {
                ...state,
                appointmentNotes: action.payload,
                loadingAppointmentNotes: false,
            };
        }
        case types.APPOINTMENT_UPDATE_NOTE_FAILURE: {
            return {
                ...state,
                error: action.payload,
                loadingAppointmentNotes: false,
            };
        }

        case types.APPOINTMENT_GET_PERSONAL_APPOINTMENTS_REQUEST:
            return {
                ...state,
                personalCalendarAppointments: {
                    ...state.personalCalendarAppointments,
                    loading: true,
                },
            };
        case types.APPOINTMENT_GET_PERSONAL_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                error: null,
                personalCalendarAppointments: {
                    list: action.payload,
                    loading: false,
                },
            };
        case types.APPOINTMENT_GET_PERSONAL_APPOINTMENTS_FAILURE:
            return {
                ...state,
                error: null,
                personalCalendarAppointments: {
                    ...state.personalCalendarAppointments,
                    loading: false,
                },
            };

        case types.APPOINTMENT_DELETE_APPOINTMENT_REQUEST:
            return {
                ...state,
                deleteAppointment: {
                    loading: true,
                },
            };
        case types.APPOINTMENT_DELETE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                error: null,
                kanbanAppointments: action.payload ? action.payload : state.kanbanAppointments,
                deleteAppointment: {
                    loading: false,
                    status: "success",
                    message: "Appointment deleted successfully.",
                },
            };
        case types.APPOINTMENT_DELETE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: null,
                deleteAppointment: {
                    loading: false,
                    status: "failed",
                    message: "Appointment deleted failed.",
                },
            };

        case types.APPOINTMENT_ARCHIVE_APPOINTMENTS_REQUEST:
            return {
                ...state,
                archiving: {
                    loading: true,
                    status: undefined,
                },
            };
        case types.APPOINTMENT_ARCHIVE_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                archiving: {
                    loading: false,
                    status: "success",
                    message: "Appointments archived successfully.",
                },
            };
        case types.APPOINTMENT_ARCHIVE_APPOINTMENTS_FAILURE:
            return {
                ...state,
                archiving: {
                    loading: false,
                    status: "failed",
                    message: "Appointments archive failed.",
                },
            };

        case types.APPOINTMENT_ARCHIVE_APPOINTMENT_REQUEST:
            return {
                ...state,
                archiving: {
                    loading: true,
                },
            };
        case types.APPOINTMENT_ARCHIVE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                archiving: {
                    loading: false,
                    status: "success",
                    message: "Appointment archived successfully.",
                },
            };
        case types.APPOINTMENT_ARCHIVE_APPOINTMENT_FAILURE:
            return {
                ...state,
                archiving: {
                    loading: false,
                    status: "failed",
                    message: "Appointment archive failed.",
                },
            };

        case types.APPOINTMENT_GET_TEAM_EVENTS_REQUEST:
            return {
                ...state,
                teamEvents: {
                    ...state.teamEvents,
                    loading: true,
                },
            };
        case types.APPOINTMENT_GET_TEAM_EVENTS_SUCCESS:
            return {
                ...state,
                teamEvents: {
                    items: action.payload,
                    loading: false,
                },
            };
        case types.APPOINTMENT_GET_TEAM_EVENTS_FAILURE:
            return {
                ...state,
                teamEvents: {
                    ...state.teamEvents,
                    loading: false,
                },
            };

        case types.APPOINTMENT_CREATE_PERSONAL_EVENT_REQUEST:
            return {
                ...state,
                creatingPersonalEvent: {
                    loading: true,
                },
            };
        case types.APPOINTMENT_CREATE_PERSONAL_EVENT_SUCCESS:
            return {
                ...state,
                newEvent: action.payload,
                creatingPersonalEvent: {
                    loading: false,
                    status: "success",
                    message: "Event created successfully.",
                },
            };
        case types.APPOINTMENT_CREATE_PERSONAL_EVENT_FAILURE:
            return {
                ...state,
                creatingPersonalEvent: {
                    loading: false,
                    status: "failed",
                    message: "Event creation failed.",
                },
            };

        case types.APPOINTMENT_UPDATE_EVENT_REQUEST:
            return {
                ...state,
                updateEvent: {
                    loading: true,
                },
            };
        case types.APPOINTMENT_UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                error: null,
                updateEvent: {
                    loading: false,
                    status: "success",
                    message: "Event updated successfully.",
                },
            };
        case types.APPOINTMENT_UPDATE_EVENT_FAILURE:
            return {
                ...state,
                error: null,
                updateEvent: {
                    loading: false,
                    status: "failed",
                    message: "Event updated failed.",
                },
            };

        case types.APPOINTMENT_DELETE_EVENT_REQUEST:
            return {
                ...state,
                deleteEvent: {
                    loading: true,
                },
            };
        case types.APPOINTMENT_DELETE_EVENT_SUCCESS:
            return {
                ...state,
                error: null,
                deleteEvent: {
                    loading: false,
                    status: "success",
                    message: "Event deleted successfully.",
                },
            };
        case types.APPOINTMENT_DELETE_EVENT_FAILURE:
            return {
                ...state,
                error: null,
                deleteEvent: {
                    loading: false,
                    status: "failed",
                    message: "Event deleted failed.",
                },
            };

        case types.APPOINTMENT_RESCHEDULE_APPOINTMENT_REQUEST:
            return {
                ...state,
                rescheduleAppointment: {
                    loading: true,
                },
            };
        case types.APPOINTMENT_RESCHEDULE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                error: null,
                rescheduleAppointment: {
                    loading: false,
                    status: "success",
                    message: "Appointment rescheduled successfully.",
                },
            };
        case types.APPOINTMENT_RESCHEDULE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: null,
                rescheduleAppointment: {
                    loading: false,
                    status: "failed",
                    message: "Appointment reschedule failed.",
                },
            };

        default:
            return state;
    }
};

export default AppointmentReducer;

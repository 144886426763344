import { PermissionValue } from "../../hrm/hrm";

/**
 * @description Check access based on the permission
 * @returns {boolean}
 */
const haveAccess = (permission: PermissionValue | undefined, assign=true): boolean => {
    const _permission = permission || "allow";
    if(_permission === "allow" || (_permission === "assign" && assign)) return true;
    return false;
}
export default haveAccess;

/**
 * @description check if all the read rights inside a module are denied, 
 * then hide the module in the side bar
 * @returns {boolean}
 */
export const hideSideBarModule = (moduleReadPermissions: PermissionValue[]) => {
    if(!moduleReadPermissions.length) return false; // Admin does'nt have permissions
    let hideModule = true;
    moduleReadPermissions.forEach(permission => {
        if(permission !== "deny") {
            hideModule = false;
            return;
        }
    });
    return hideModule;
};

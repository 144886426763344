import { MapboxPlaces, Organization } from "../../../organization";
import { types } from "./types";

export interface OrganizationState {
    error: any;
    loadingOrg: boolean;
    org: Organization | null;
    organization: Organization | null;
    configuration: {
        loading: boolean;
    };
    createOrganization: {
        loading: boolean;
        status?: "failed" | "success";
        message?: string;
    };
    loadingOrganization: boolean;
    organizations: Pick<Organization, "colorCode" | "createdAt" | "industry" | "name" | "orgId" | "seats" | "stripeCustomerId" | "subscriptionId" | "subscriptionItemId" | "userNumber" | "goal">[];
    loadingOrganizations: boolean;
    loadingUpdateOrg: boolean;
    settingLocations: boolean;
    creatingLocation: boolean;
    deletingLocation: boolean;
    deletingOrg: boolean;
    /**
     * @description used in the dashboard to create organization first location.
     */
    createdFirstLocation: boolean;
    /**
     * @description from custom api
     */
    places: MapboxPlaces["features"];
    loadingPlaces: boolean;
    settingSalesTaxes: boolean;
    orgStats: any;
    code: number;
    tour: {
        loadingRequest: boolean;
        status?: "pending" | "success" | "failed";
        stepName: keyof Organization["configuration"];
        step: number;
        subStep?: "invitation" | "list" | "schedule" | "employeeServices";
        stepsProgress: Readonly<OrganizationState["tour"]["stepName"][]>;
    };
}

export const _INITIAL_STATE: OrganizationState = {
    /**
     * @description organization user is logged in.
     */
    org: null,
    configuration: {
        loading: false,
    },
    /**
     * @important this loader is important for loading the app, do not use it for
     * loading modules;
     */
    loadingOrg: true,
    /**
     * @description organization user is updating or viewing at account settings/organization profile.
     */
    organization: null,
    createOrganization: {
        loading: false,
    },
    loadingOrganization: true,
    loadingUpdateOrg: false,
    organizations: [],
    settingLocations: false,
    creatingLocation: false,
    deletingLocation: false,
    createdFirstLocation: false,
    settingSalesTaxes: false,
    deletingOrg: false,
    /**
     * @important this loader is important for loading the app, do not use it for
     * loading modules, if needed create a custom one and place aside with this one.
     */
    loadingOrganizations: true,
    orgStats: null,
    places: [],
    loadingPlaces: false,
    code: 200,
    error: null,
    tour: {
        loadingRequest: false,
        stepName: "businessType", // default businessType
        step: 0,
        stepsProgress: ["businessType", "industry", "locations", "services", "employees", "confirmation"],
    },
};

const OrgReducer = (
    state = _INITIAL_STATE,
    action: {
        type: string;
        payload: any;
    }
): OrganizationState => {
    switch (action.type) {
        case types.ORG_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...(action.payload as OrganizationState),
            };

        case types.ORG_UPDATE_CONFIGURATION_REQUEST:
            return {
                ...state,
                configuration: {
                    loading: true,
                },
            };

        case types.ORG_UPDATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                configuration: {
                    loading: false,
                },
            };

        case types.ORG_UPDATE_CONFIGURATION_FAILURE:
            return {
                ...state,
                configuration: {
                    loading: false,
                },
            };

        case types.ORG_CREATE_PRIMARY_ORGANIZATION_REQUEST:
            return {
                ...state,
                error: null,
            };

        case types.ORG_CREATE_PRIMARY_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.ORG_CREATE_PRIMARY_ORGANIZATION_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.ORG_CREATE_SECONDARY_ORGANIZATION_REQUEST:
            return {
                ...state,
                createOrganization: {
                    ...state.createOrganization,
                    loading: true,
                },
            };
        case types.ORG_CREATE_SECONDARY_ORGANIZATION_FAILURE:
            return {
                ...state,
                createOrganization: {
                    ...state.createOrganization,
                    loading: false,
                    message: "Failed to create organization.",
                },
            };
        case types.ORG_CREATE_SECONDARY_ORGANIZATION_SUCCESS:
            return {
                ...state,
                createOrganization: {
                    ...state.createOrganization,
                    loading: false,
                    message: "Organization created successfully.",
                },
            };
        case types.ORG_GET_ORGANIZATION_REQUEST:
            return {
                ...state,
                loadingOrganization: true,
                error: null,
            };
        case types.ORG_GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload,
                loadingOrganization: false,
                error: null,
            };

        case types.ORG_GET_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingOrganization: false,
            };
        case types.ORG_GET_ALL_ORGANIZATIONS_REQUEST:
            return {
                ...state,
                error: null,
            };
        case types.ORG_GET_ALL_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizations: action.payload,
                error: null,
                loadingOrganizations: false,
            };
        case types.ORG_GET_ALL_ORGANIZATIONS_FAILURE:
            return {
                ...state,
                organizations: [],
                error: action.payload,
                loadingOrganizations: false,
            };
        case types.ORG_DELETE_ORGANIZATION_REQUEST:
            return {
                ...state,
                error: null,
                deletingOrg: true,
            };
        case types.ORG_DELETE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                error: null,
                deletingOrg: false,
            };
        case types.ORG_DELETE_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                deletingOrg: false,
            };

        case types.ORG_AUTO_UPDATE_ORGANIZATION_REQUEST:
            return {
                ...state,
                loadingUpdateOrg: true,
            };
        case types.ORG_AUTO_UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loadingUpdateOrg: false,
            };
        case types.ORG_AUTO_UPDATE_ORGANIZATION_FAILURE:
            return {
                ...state,
                loadingUpdateOrg: false,
            };

        case types.ORG_UPDATE_ORGANIZATION_REQUEST:
            return {
                ...state,
                error: null,
                loadingUpdateOrg: true,
            };
        case types.ORG_UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loadingUpdateOrg: false,
                error: null,
            };
        case types.ORG_UPDATE_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingUpdateOrg: false,
            };

        case types.ORG_GET_ORGANIZATION_STATS_SUCCESS:
            return {
                ...state,
                orgStats: action.payload,
                error: null,
            };

        case types.ORG_GET_ORGANIZATION_STATS_FAILURE:
            return {
                ...state,
                orgStats: null,
                error: action.payload,
            };

        case types.ORG_ENTER_ORGANIZATION_REQUEST:
            return {
                ...state,
                error: null,
            };

        case types.ORG_ENTER_ORGANIZATION_SUCCESS:
            return {
                ...state,
                org: action.payload ? action.payload : state.org,
                error: null,
                loadingOrg: false,
            };

        case types.ORG_ENTER_ORGANIZATION_FAILURE:
            return {
                ...state,
                org: null,
                error: action.payload,
                // loadingOrg: false, this never happens because we are logging the user out.
            };

        case types.ORG_MANAGE_ORGANIZATION_LOCATIONS_REQUEST:
            return {
                ...state,
                settingLocations: true,
            };
        case types.ORG_MANAGE_ORGANIZATION_LOCATIONS_SUCCESS:
            return {
                ...state,
                settingLocations: false,
                ...action.payload,
                error: null,
            };
        case types.ORG_MANAGE_ORGANIZATION_LOCATIONS_FAILURE:
            return {
                ...state,
                settingLocations: false,
                error: action.payload,
            };

        case types.ORG_CREATE_LOCATION_REQUEST:
            return {
                ...state,
                settingLocations: true,
            };
        case types.ORG_CREATE_LOCATION_SUCCESS:
            return {
                ...state,
                settingLocations: false,
                ...action.payload,
                error: null,
            };
        case types.ORG_CREATE_LOCATION_FAILURE:
            return {
                ...state,
                settingLocations: false,
                error: action.payload,
            };

        case types.ORG_UPDATE_LOCATION_REQUEST:
            return {
                ...state,
                creatingLocation: true,
            };
        case types.ORG_UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                creatingLocation: false,
                error: null,
            };
        case types.ORG_UPDATE_LOCATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                creatingLocation: false,
            };

        case types.ORG_DELETE_LOCATION_REQUEST:
            return {
                ...state,
                deletingLocation: true,
            };
        case types.ORG_DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                deletingLocation: false,
                error: null,
            };
        case types.ORG_DELETE_LOCATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                deletingLocation: false,
            };

        case types.ORG_GET_PLACES_REQUEST:
            return {
                ...state,
                loadingPlaces: true,
            };

        case types.ORG_GET_PLACES_SUCCESS:
            return {
                ...state,
                places: action.payload,
                loadingPlaces: false,
                error: null,
            };

        case types.ORG_GET_PLACES_FAILURE:
            return {
                ...state,
                loadingPlaces: false,
                error: action.payload,
            };

        case types.ORG_SET_ORGANIZATION_SALES_TAXES_REQUEST:
            return {
                ...state,
                settingSalesTaxes: true,
            };
        case types.ORG_SET_ORGANIZATION_SALES_TAXES_SUCCESS:
            return {
                ...state,
                settingSalesTaxes: false,
                organization: state.organization ? { ...state.organization, taxes: action.payload } : null,
                error: null,
            };
        case types.ORG_SET_ORGANIZATION_SALES_TAXES_FAILURE:
            return {
                ...state,
                settingSalesTaxes: false,
                error: action.payload,
            };

        case types.ORG_SETUP_REQUEST:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    loadingRequest: true,
                    status: "pending",
                },
                error: null,
            };
        case types.ORG_SETUP_SUCCESS:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    loadingRequest: false,
                    stepName: action.payload.stepName,
                    subStep: action.payload.subStep,
                    step: state.tour.stepsProgress.indexOf(action.payload.stepName),
                    status: "success",
                },
                org: action.payload.org,
                organizations: action.payload.organizations,
                error: null,
            };
        case types.ORG_SETUP_FAILURE:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    loadingRequest: false,
                    status: "failed",
                },
                error: action.payload
            };

        case types.ORG_TOUR_STEP_REQUEST:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    stepName: action.payload.stepName,
                    step: state.tour.stepsProgress.indexOf(action.payload.stepName),
                },
            };

        default:
            return state;
    }
};

export default OrgReducer;

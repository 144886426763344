import { timeToUTC, utcToTimezone } from "../../appointment/helpers/time";
import { TWeekDay } from "../hrm";

/**
 * @description create date object to end and start in hrm
 */
export const hrmGetEndStart__helper = ({ end, start }: { end: string; start: string }) => {
    let xStart = new Date("01-02-2021");
    let xEnd = new Date("01-02-2021");
    const splitStart = start.split(/:/g);
    const splitEnd = end.split(/:/g);

    xStart.setHours(parseInt(splitStart[0]), parseInt(splitStart[1]));
    xEnd.setHours(parseInt(splitEnd[0]), parseInt(splitEnd[1]));

    return {
        start: xStart,
        end: xEnd,
    };
};

export const hrmTwoDigits__helper = (number: number | string) => `0${number}`.slice(-2);

export const hrmGetFullTime__helper = (delta: number, digits: 1 | 2 = 2) => {
    const h = Math.floor(delta / 1000 / 3600);
    const m = Math.floor((delta / 60000) % 60);
    const s = Math.floor((delta / 1000) % 60);
    const hours = digits === 1 ? `${h}` : hrmTwoDigits__helper(h);
    const minutes = digits === 1 ? `${m}` : hrmTwoDigits__helper(m);
    const seconds = digits === 1 ? `${s}` : hrmTwoDigits__helper(s);
    return {
        hours,
        minutes,
        seconds,
        full: `${hrmTwoDigits__helper(hours)}:${hrmTwoDigits__helper(minutes)}:${hrmTwoDigits__helper(seconds)}`,
    };
};

// Convert schedule time from UTC to org time zone
export const convertWeekDaysToLocalTimeZone = (weekDays: TWeekDay[], timeZone: string): TWeekDay[] => {
    return weekDays.map((_weekday: TWeekDay) => {
        const weekDay = { ..._weekday };
        const { start, end } = weekDay;
        // Do not change time for unscheduled days
        if (start === "00:00" && end === "00:00") return weekDay;
        weekDay.start = utcToTimezone(start, timeZone);
        weekDay.end = utcToTimezone(end, timeZone);

        return weekDay;
    });
};

// Convert schedule time from org timezone to UTC
export const convertWeekDaysToUtc = (weekDays: TWeekDay[], timeZone: string): TWeekDay[] => {
    return weekDays.map((_weekday) => {
        const weekDay = { ..._weekday };
        const { start, end } = weekDay;
        // Do not change time for unscheduled days
        if (start === "00:00" && end === "00:00") return weekDay;
        weekDay.start = timeToUTC(start, timeZone);
        weekDay.end = timeToUTC(end, timeZone);

        return weekDay;
    });
};

export const convert12hrTo24hr = (time12hr: string) => {
    const timeRegexPattern = /^(0[1-9]|1[0-2]):[0-5][0-9](am|pm)$/i;
    if (!timeRegexPattern.test(time12hr)) return "00:00";

    const [time, period] = [time12hr.substring(0, 5), time12hr.substring(5, time12hr.length)];
    const [hours, minutes] = time.split(":");
    let hh = parseInt(hours, 10);

    if (period.toLowerCase() === "pm" && hh !== 12) {
        hh += 12;
    } else if (period.toLowerCase() === "am" && hh === 12) {
        hh = 0;
    }

    const HH = String(hh).padStart(2, "0");
    const mm = String(minutes).padStart(2, "0");

    return `${HH}:${mm}`;
};

export const convert24hrTo12hr = (time24hr: string) => {
    const [hours, minutes] = time24hr.split(":");
    let period = "am";

    let hh = parseInt(hours);

    if (hh >= 12) {
        period = "pm";
        if (hh > 12) {
            hh -= 12;
        }
    }

    const hh12 = hh.toString().padStart(2, "0");
    const mm = minutes.padStart(2, "0");

    return `${hh12}:${mm}${period}`;
};

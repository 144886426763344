import { FC, useEffect, useState } from "react";
import { Checkbox } from "@soltivo/draw-a-line";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import styles from "./notifications.manage.module.scss";
import { NotificationSettings } from "@soltivo/types";
import { notificationsUpdateSettings } from "../../../../../applications/settings/redux/notifications/reducer/actions";

type NotificationsServices = "appointments" | "contact" | "emails";
type NotificationsServiceOptions = "created" | "rescheduled" | "cancelled" | "received" | "updated" | "assigned";
interface NotificationsManageProps extends MapStateType {}

/**
 * @description notification item
 */
const NotificationsManage: FC<NotificationsManageProps> = ({ notificationSettings }) => {
    const dispatch = useDispatch();
    const [settings, setSettings] = useState<NotificationSettings>();

    useEffect(() => {
        setSettings(notificationSettings.settings);
    }, [notificationSettings]);

    const onChangeForm = (settingsForm: NotificationSettings) => {
        setSettings(settingsForm);
        // Update notifications settings
        dispatch(notificationsUpdateSettings({ settings: settingsForm }));
    };

    return (
        <div className={`${styles.notifications__manage}`}>
            <h6 className="text-geyser-900">Notifications management</h6>
            {settings && Object.keys(settings).map((key, index) => {
                if (key === "appointments") {
                    return (
                        <div key={index} className={styles.notifications__manage__service}>
                            <h6 className="text-geyser-700">Appointment notifications</h6>
                            <NotificationCheckOption
                                service={key}
                                option={"created"}
                                form={settings}
                                onChangeForm={onChangeForm}
                                title="New Appointment"
                                checkedValues={settings.appointments.created}
                            />
                            <NotificationCheckOption 
                                service={key} 
                                option="rescheduled" 
                                form={settings} 
                                onChangeForm={onChangeForm} 
                                title="Appointment rescheduled"
                                checkedValues={settings.appointments.rescheduled}
                            />
                            <NotificationCheckOption 
                                service={key} 
                                option="cancelled" 
                                form={settings} 
                                onChangeForm={onChangeForm} 
                                title="Appointment cancelled"
                                checkedValues={settings.appointments.cancelled}
                            />
                        </div>
                    );
                } else if (key === "emails") {
                    return null;
                    // return (
                    //     <div key={index} className={styles.notifications__manage__service}>
                    //         <h6 className="text-geyser-700">Email notifications</h6>
                    //         <NotificationCheckOption 
                    //             service={key} 
                    //             option="received" 
                    //             form={settings} 
                    //             onChangeForm={onChangeForm} 
                    //             title="New email" 
                    //             checkedValues={settings.emails.received}
                    //         />
                    //     </div>
                    // );
                } else if (key === "contact") {
                    return (
                        <div key={index} className={styles.notifications__manage__service}>
                            <h6 className="text-geyser-700">Contact notifications</h6>
                            <NotificationCheckOption 
                                service={key} 
                                option="updated"
                                form={settings} 
                                onChangeForm={onChangeForm} 
                                title="Contact updated"
                                checkedValues={settings.contact.updated}
                            />
                            <NotificationCheckOption 
                                service={key} 
                                option="assigned" 
                                form={settings} 
                                onChangeForm={onChangeForm} 
                                title="Contact assigned to you"
                                checkedValues={settings.contact.assigned}
                            />
                        </div>
                    );
                } else return null;
            })}
        </div>
    );
};

const NotificationCheckOption = ({
    title,
    service,
    option,
    form,
    checkedValues,
    onChangeForm,
}: {
    title: string;
    service: NotificationsServices;
    option: NotificationsServiceOptions;
    form: NotificationSettings;
    checkedValues: { web: boolean; email: boolean };
    onChangeForm(val: NotificationSettings): void;
}) => {

    const { web, email } = checkedValues;

    const handleUpdateSettings = (key: "web" | "email", value: boolean) => {
        onChangeForm({
            ...form,
            [service]: {
                ...form[service],
                [option]: {
                    //@ts-ignore
                    ...form[service][option],
                    [key]: value,
                },
            },
        });
    };

    return (
        <div className={`${styles.notifications__manage__service__option} ${service}-${option}`}>
            <p className="text-15 text-geyser-700 title">{title}</p>
            <div className={styles.notifications__manage__service__check}>
                <label className={styles.labelOption}>
                    <Checkbox onChange={(e) => handleUpdateSettings("web", e.target.checked)} checked={web} /> 
                    <span className="text-geyser-700 text-12">Web</span>
                </label>
                <label className={styles.labelOption}>
                    <Checkbox onChange={(e) => handleUpdateSettings("email", e.target.checked)} checked={email} /> 
                    <span className="text-geyser-700 text-12">Email</span>
                </label>
            </div>
        </div>
    );
};

const mapStateToProps = ({ NotificationReducer }: RootState) => ({
    notificationSettings: NotificationReducer.notificationSettings,
});
type MapStateType = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NotificationsManage);

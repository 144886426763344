import { types } from "./types";

/**
 * @description change global state in service
 * @warning you this action carefully because you could break the application.
 */
export const serviceChangeState = (payload: any) => ({
    type: types.SERVICE_CHANGE_STATE_REQUEST,
    payload,
});

/**
 * @description toggle style between column and table
 */
export const serviceToggleStyle = () => ({
    type: types.SERVICE_TOGGLE_STYLE_REQUEST,
});

/**
 * @description adds a empty variant to array.
 */
export const serviceAddVariant = () => ({
    type: types.SERVICE_ADD_VARIANT_REQUEST,
});

/**
 * @description update a single variant in array of a service.
 */
export const serviceUpdateVariant = (payload: { name: string; items: string[]; index: number }) => ({
    type: types.SERVICE_UPDATE_VARIANT_REQUEST,
    payload,
});

/**
 * @description when user has left the add variants, clear the empty ones or not valid.
 */
export const serviceClearVariants = () => ({
    type: types.SERVICE_CLEAR_VARIANTS_REQUEST,
});

/**
 * @description get suggestions for name service category.
 */
export const serviceGetCategorySuggestions = () => ({
    type: types.SERVICE_GET_CATEGORY_SUGGESTIONS_REQUEST,
});

/**
 * @description assign employee to a service
 */
export const serviceAssignEmployee = (payload: {
    /**
     * @description userId
     */
    user: string;
    /**
     * @description serviceId listing
     */
    services: string[];
}) => ({
    type: types.SERVICE_ASSIGN_EMPLOYEE_REQUEST,
    payload,
});

/**
 * @description get service categories of services.
 */
export const serviceGetCategories = (payload?: any) => ({
    type: types.SERVICE_GET_CATEGORIES_REQUEST,
    payload,
});

/**
 * @description search service category of services.
 */
export const serviceSearchCategory = (payload: { name: string }) => ({
    type: types.SERVICE_SEARCH_CATEGORY_REQUEST,
    payload,
});

/**
 * @description close search service category of services.
 */
export const serviceCloseSearchCategory = () => ({
    type: types.SERVICE_SEARCH_CATEGORY_CLOSE,
});

/**
 * @description get service category by id.
 */
export const serviceGetCategory = (payload: { categoryId: string }) => ({
    type: types.SERVICE_GET_CATEGORY_REQUEST,
    payload,
});

/**
 * @description create category of services.
 */
export const serviceCreateCategory = (payload: { name: string; description: string }) => ({
    type: types.SERVICE_CREATE_CATEGORY_REQUEST,
    payload,
});

/**
 * @description update category of services.
 */
export const serviceUpdateCategory = (payload: { categoryId: string; name: string; description: string }) => ({
    type: types.SERVICE_UPDATE_CATEGORY_REQUEST,
    payload,
});

/**
 * @description delete service category by id.
 */
export const serviceDeleteCategory = (payload: { categoryId: string }) => ({
    type: types.SERVICE_DELETE_CATEGORY_REQUEST,
    payload,
});

/**
 * @description get services of a category.
 */
export const serviceGetServices = (payload: { categoryId: string }) => ({
    type: types.SERVICE_GET_SERVICES_REQUEST,
    payload,
});

/**
 * @description get service by id
 */
export const serviceGetService = (payload: { serviceId: string }) => ({
    type: types.SERVICE_GET_SERVICE_REQUEST,
    payload,
});

/**
 * @description clear list services of a category.
 */
export const serviceCloseServices = () => ({
    type: types.SERVICE_CLOSE_SERVICES_REQUEST,
});

export const serviceCreateService = (payload: {
    image?: File | string;
    title: string;
    price: number;
    description?: string;
    category: string;
    bufferTime: number;
    duration: number;
    employees?: string[];
    locations?: string[];
    equipments?: {
        id: string;
        name: string;
        quantity: number;
    }[];
}) => ({
    type: types.SERVICE_CREATE_SERVICE_REQUEST,
    payload,
});

/**
 * @description update service
 */
export const serviceUpdateService = (payload: {
    serviceId: string;
    service: {
        image: File | string;
        title: string;
        price: number;
        description: string;
        category: string;
        bufferTime: number;
        duration: number;
        employees?: string[];
        locations?: string[];
        equipments: {
            id: string;
            name: string;
            quantity: number;
        }[];
    };
}) => ({
    type: types.SERVICE_UPDATE_SERVICE_REQUEST,
    payload,
});

/**
 * @description get all services.
 */
export const serviceGetAllServices = () => ({
    type: types.SERVICE_GET_ALL_SERVICES_REQUEST,
});

/**
 * @description delete service
 */
export const serviceDeleteService = (payload: { serviceId: string; categoryId: string }) => ({
    type: types.SERVICE_DELETE_SERVICE_REQUEST,
    payload,
});

//// EARNINGS  START ////

/**
 * @description Get earnings
 */
export const serviceGetEarningsMetrics = (payload: { date: string }) => ({
    type: types.SERVICE_GET_EARNINGS_METRICS_REQUEST,
    payload,
});

//// EARNINGS  END ////

/**
 * @description Get unsplash images
 */
export const serviceGetUnsplashImages = (payload: { query: string; page: number }) => ({
    type: types.SERVICE_GET_UNSPLASH_IMAGES_REQUEST,
    payload,
});

import Gregorian from '../../../helpers/unix-time/gregorian';
import moment from 'moment'

export const nextDay = (time: number): number => {
    let next = (new Date(time))
    next.setDate(new Date(time).getDate()+1)
    return next.getTime()
}

export const previousDay = (time: number): number => {
    let previous = (new Date(time))
    previous.setDate(new Date(time).getDate()-1)
    return previous.getTime()
}

/**
 * @description format time to hh:mm
 */
export const formatTime = (date: string | number): string => {
    let hh = new Date(date).getHours().toString()
    let mm = new Date(date).getMinutes().toString()
    if(hh.length === 1) hh = `0${hh}`
    if(mm.length === 1) mm = `0${mm}`
    return `${hh}:${mm}`
}

/**
 * @description format Date to yyy:mm:dd
 */
export const dateTimeToDate = (date: Date | number): string => {
    const givenDateInSeconds = new Date(date).getTime();
    return moment(new Date(givenDateInSeconds)).format("YYYY-MM-DD")
}

/**
 * @description check if a given date is the date of today or tomorrow other a coming date
 * @param {Date} date | string
 */
export const prettyDate = (date: Date): string => {
    const givenDateInSeconds = new Date(date).getTime() + new Date().getTimezoneOffset()*60*1000;
    const givenDate = new Date(givenDateInSeconds)
    const today = new Date(new Date(moment().format("YYYY-MM-DD")).getTime() + new Date().getTimezoneOffset()*60*1000);
    
    if (today.getFullYear() === givenDate.getFullYear() &&
    today.getMonth() === givenDate.getMonth() &&
    today.getDate() === givenDate.getDate()) {
        return 'Today'
    } else if(today.getFullYear() === givenDate.getFullYear() &&
    today.getMonth() === givenDate.getMonth() &&
    today.getDate()+1 === givenDate.getDate()) {
        return 'Tomorrow'
    } else {
        return moment(givenDate).format('DD MMM YYYY')
    }
}

/**
 * @description from yyyy-mm-dd format to Date string
 * @param {string} date yyyy-mm-dd 
 */
export const dateToDateTime = (date: string): Date => {
    const items = date.split('-')
    let yyyy = parseInt(items[0])
    let mm = parseInt(items[1])
    let dd = parseInt(items[2])
    return new Date(yyyy, mm, dd)
}

export const startDay = (time: number): number => {
    return Gregorian.startDay(time)
}

/**
 * @description get elapsed time in seconds in a day
 */
export const getElapsedSeconds = (date: number): number => {
    // console.log(console.log(new Date(Gregorian.startDay(date))))
    return date - Gregorian.startDay(date)
}

/**
 * @description convert hours to seconds
 */
export const hoursToSeconds = (hours: string, minutes: string): number => {
    return parseInt(hours)*60*60 + parseInt(minutes)*60
}

/**
 * @description convert from seconds to hours and minutes
 */
export const secondsToHoursSeconds = (seconds: number): {hours: number, minutes: number} => {
    const hours = Math.trunc(seconds/3600)
    const minutes = (seconds % 3600) / 60
    return {
        hours, minutes
    }
}
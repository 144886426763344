import { FC } from "react";
import { Link } from "react-router-dom";
import { PermissionValue } from "../../../../../applications/hrm/hrm";
import CheckPermission from "../../../../components/check.permissions/check.permissions";

import styles from "./header.nav.module.scss";

export interface HeaderSectionNavProps {
    menu: {
        name: string;
        path: string;
        subs?: string[];
        actived: boolean;
        permission?: PermissionValue;
    }[];
}

const HeaderSectionNav: FC<HeaderSectionNavProps> = ({ menu }) => {
    return (
        <nav className={styles.header_section_navigation}>
            {menu?.map((item, index) => (
                <CheckPermission key={index} assign permission={item.permission}>
                    <Link className={`${styles.header_section_navigation__link} ${item.actived ? styles.header_section_navigation__link__active : ""} subtitle-1`} key={index} to={item.path}>
                        {item.name}
                    </Link>
                </CheckPermission>
            ))}
        </nav>
    );
};

export default HeaderSectionNav;

import { Color } from "@soltivo/draw-a-line";
import { HourItem } from "../appointment";

export const CALENDAR_ROW_WIDTH = 144;
export const CALENDAR_ROW_HEIGHT = "100%";

export const MONTHS_LABEL = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const HOURS_INTERVALS: HourItem[] = [
    { hour: 0, label: "00:00 - 01:00", width: CALENDAR_ROW_WIDTH },
    { hour: 1, label: "01:00 - 02:00", width: CALENDAR_ROW_WIDTH },
    { hour: 2, label: "02:00 - 03:00", width: CALENDAR_ROW_WIDTH },
    { hour: 3, label: "03:00 - 04:00", width: CALENDAR_ROW_WIDTH },
    { hour: 4, label: "04:00 - 05:00", width: CALENDAR_ROW_WIDTH },
    { hour: 5, label: "05:00 - 06:00", width: CALENDAR_ROW_WIDTH },
    { hour: 6, label: "06:00 - 07:00", width: CALENDAR_ROW_WIDTH },
    { hour: 7, label: "07:00 - 08:00", width: CALENDAR_ROW_WIDTH },
    { hour: 8, label: "08:00 - 09:00", width: CALENDAR_ROW_WIDTH },
    { hour: 9, label: "09:00 - 10:00", width: CALENDAR_ROW_WIDTH },
    { hour: 10, label: "10:00 - 11:00", width: CALENDAR_ROW_WIDTH },
    { hour: 11, label: "11:00 - 12:00", width: CALENDAR_ROW_WIDTH },
    { hour: 12, label: "12:00 - 13:00", width: CALENDAR_ROW_WIDTH },
    { hour: 13, label: "13:00 - 14:00", width: CALENDAR_ROW_WIDTH },
    { hour: 14, label: "14:00 - 15:00", width: CALENDAR_ROW_WIDTH },
    { hour: 15, label: "15:00 - 16:00", width: CALENDAR_ROW_WIDTH },
    { hour: 16, label: "16:00 - 17:00", width: CALENDAR_ROW_WIDTH },
    { hour: 17, label: "17:00 - 18:00", width: CALENDAR_ROW_WIDTH },
    { hour: 18, label: "18:00 - 19:00", width: CALENDAR_ROW_WIDTH },
    { hour: 19, label: "19:00 - 20:00", width: CALENDAR_ROW_WIDTH },
    { hour: 20, label: "20:00 - 21:00", width: CALENDAR_ROW_WIDTH },
    { hour: 21, label: "21:00 - 22:00", width: CALENDAR_ROW_WIDTH },
    { hour: 22, label: "22:00 - 23:00", width: CALENDAR_ROW_WIDTH },
    { hour: 23, label: "23:00 - 24:00", width: CALENDAR_ROW_WIDTH },
];

export const TIME_LIST = [
    { id: 0, label: "00 AM" },
    { id: 1, label: "01 AM" },
    { id: 2, label: "02 AM" },
    { id: 3, label: "03 AM" },
    { id: 4, label: "04 AM" },
    { id: 5, label: "05 AM" },
    { id: 6, label: "06 AM" },
    { id: 7, label: "07 AM" },
    { id: 8, label: "08 AM" },
    { id: 9, label: "09 AM" },
    { id: 10, label: "10 AM" },
    { id: 11, label: "11 AM" },
    { id: 12, label: "12 AM" },
    { id: 13, label: "1 PM" },
    { id: 14, label: "2 PM" },
    { id: 15, label: "3 PM" },
    { id: 16, label: "4 PM" },
    { id: 17, label: "5 PM" },
    { id: 18, label: "6 PM" },
    { id: 19, label: "7 PM" },
    { id: 20, label: "8 PM" },
    { id: 21, label: "9 PM" },
    { id: 22, label: "10 PM" },
    { id: 23, label: "11 PM" },
];

export const _BUFFER_INFO = "The time between one appointment and another. For example, the break or clean up time for staff before they take on another appointment.";

export const STATUSES_ID = {
    unconfirmed: "unconfirmed",
    confirmed: "confirmed",
    arrived: "arrived",
    started: "started",
    completed: "completed",
    not_show: "not_show",
} as const;

export const STATUSES_NAMES = {
    unconfirmed: "Unconfirmed",
    confirmed: "Confirmed",
    arrived: "Arrived",
    started: "Started",
    completed: "Completed",
    not_show: "No Show",
} as const;

export const KANBAN_DEFAULT_COLUMNS = [
    {
        id: STATUSES_ID.unconfirmed,
        color: Color["geyser"]["geyser-600"],
        status: STATUSES_NAMES.unconfirmed,
    },
    {
        id: STATUSES_ID.confirmed,
        color: Color["meteor"]["meteor-600"],
        status: STATUSES_NAMES.confirmed,
    },
    {
        id: STATUSES_ID.arrived,
        color: Color["kumera"]["kumera-600"],
        status: STATUSES_NAMES.arrived,
    },
    {
        id: STATUSES_ID.started,
        color: Color["elm"]["elm-600"],
        status: STATUSES_NAMES.started,
    },
    {
        id: STATUSES_ID.completed,
        color: Color["bluemine"]["bluemine-600"],
        status: STATUSES_NAMES.completed,
    },
    {
        id: STATUSES_ID.not_show,
        color: Color["valencia"]["valencia-600"],
        status: STATUSES_NAMES.not_show,
    },
] as const;

import { call, put, CallEffect, AllEffect, PutEffect, SelectEffect, all, delay, cancel, CancelEffect, take, race, RaceEffect, cancelled, CancelledEffect } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import apiV2 from "../../../../../helpers/api.v2";
import { EmailActions } from "../../reducer/actions.types";
import history from "../../../../../app/routes/history";
import emailValidation from "../../../validation/email.validation";

/**
 * @description create/send new email.
 */
export function* emailSendEmail({
    payload,
}: EmailActions["emailSendEmail"]): Generator<Promise<any> | CancelledEffect | CancelEffect | RaceEffect<any> | CallEffect | AllEffect<any> | PutEffect | SelectEffect, void, any> {
    try {
        emailValidation.emailSendEmail(payload);

        const { canceled } = yield race({
            deleyed: delay(5000),
            canceled: take(emailTypes.EMAIL_CANCEL_REQUEST),
        });

        if (canceled) {
            yield cancel();
        }

        if (payload.attachments.length) {
            //create array of content types based on each file
            const contentTypes = payload.attachments.map((file: File) => file.type.split("/")[1]);
            // get signed urls in array.
            const { data } = yield call(emailClass.getSignedUrl, { contentTypes: contentTypes });
            // create request to be made based on file and data signed url (payload attachments comming from form matchs data coming from server.)
            // const requests = yield Promise.all(payload.attachments.map((file: File, index) => apiV2.postAssignedUrl(data[index], file)));
            const requests = payload.attachments.map((file: File, index) => call(apiV2.postAssignedUrl, data[index], file));

            // call all requests and wait from to finish upload.
            const responses: {
                fileLink: string;
            }[] = yield all(requests);

            payload.attachments = responses.map((res, index) => ({ name: (payload.attachments as File[])[index].name, link: res.fileLink }));
        }

        const { data } = yield call(emailClass.emailSendEmail, payload);

        yield put({
            type: emailTypes.EMAIL_SEND_EMAIL_SUCCESS,
            payload: data,
        });

        history.push(`/collaboration/email/sent/${btoa(data.exchangeId)}`);
    } catch (error: any) {
        //MESSAGE WITH FAILURE.
        yield put({ type: emailTypes.EMAIL_SEND_EMAIL_FAILURE, payload: error });
    } finally {
        if (yield cancelled()) yield put({ type: emailTypes.EMAIL_SEND_EMAIL_CANCELED });
    }
}

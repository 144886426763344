import { call, put, select, CallEffect, AllEffect, PutEffect, SelectEffect, all, delay, cancel, CancelEffect, take, race, RaceEffect, cancelled, CancelledEffect } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import apiV2 from "../../../../../helpers/api.v2";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";
import history from "../../../../../app/routes/history";
import emailValidation from "../../../validation/email.validation";

/**
 * @description reply email
 */
export function* emailReplyEmail({
    payload,
}: EmailActions["emailReplyEmail"]): Generator<CallEffect | CancelEffect | CancelledEffect | RaceEffect<any> | AllEffect<any> | PutEffect | SelectEffect, void, any> {
    try {
        emailValidation.emailReplyEmail(payload);

        let { email }: { email: RootState["EmailReducer"]["email"] } = yield select(({ EmailReducer }: RootState) => ({
            email: EmailReducer.email,
        }));

        const { canceled } = yield race({
            deleyed: delay(5000),
            canceled: take(emailTypes.EMAIL_CANCEL_REQUEST),
        });

        if (canceled) {
            yield cancel();
        }

        const subject = email.items[email.items.length - 1].subject;

        if (payload.attachments.length) {
            //create array of content types based on each file
            const contentTypes = payload.attachments.map((file: File) => file.type.split("/")[1]);
            // get signed urls in array.
            const { data } = yield call(emailClass.getSignedUrl, { contentTypes: contentTypes });
            // create request to be made based on file and data signed url (payload attachments comming from form matchs data coming from server.)
            // const requests = yield Promise.all(payload.attachments.map((file: File, index) => apiV2.postAssignedUrl(data[index], file)));
            const requests = payload.attachments.map((file: File, index) => call(apiV2.postAssignedUrl, data[index], file));

            // call all requests and wait from to finish upload.
            const responses: {
                fileLink: string;
            }[] = yield all(requests);

            payload.attachments = responses.map((res, index) => ({ name: (payload.attachments as File[])[index].name, link: res.fileLink }));
        }

        const { data } = yield call(emailClass.emailReplyEmail, {
            ...payload,
            subject: `RE: ${subject.replace(/^(RE:|Re:|re:)/, "")}`,
        });

        //update email items.
        email.items = email.items = [data, ...email.items];

        yield put({
            type: emailTypes.EMAIL_REPLY_EMAIL_SUCCESS,
            payload: email,
        });

        history.push(`/collaboration/email/sent/${btoa(data.exchangeId)}`);
    } catch (error: any) {
        yield put({ type: emailTypes.EMAIL_REPLY_EMAIL_FAILURE, payload: error });
    } finally {
        if (yield cancelled()) yield put({ type: emailTypes.EMAIL_SEND_EMAIL_CANCELED });
    }
}

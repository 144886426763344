import apiV2 from "../../../../helpers/api.v2";

const PROJECT_URL = `${process.env.REACT_APP_API_URL}/projects`;
const HRM_URL = `${process.env.REACT_APP_API_URL}/hrm`;

/**
 * @description Project manager API calls.
 */
class ProjectManagerClass {
    /**
     * @description get service categories of services.
     */
    createProject(payload: {
        projectName: string;
        projectDesc: string;
    }) {
        return apiV2.postRequest(`${PROJECT_URL}`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description Get projects list
     */
    getProjectsList(payload?: string) {
        let url = `${PROJECT_URL}`;
        if (payload) url = `${PROJECT_URL}?lastKey=${payload}`;
        return apiV2.getRequest(url, {
            useToken: "accessToken"
        });
    }

    /**
     * @description Update a service
     */
     updateProject(payload: {
        projectId: string;
        projectName: string;
        projectDesc?: string;
    }) {
        const {projectId, projectName, projectDesc} = payload
        return apiV2.putRequest(
            `${PROJECT_URL}/${projectId}`,
            {
              data: JSON.stringify({projectName, projectDesc}),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Delete a service
     */
     deleteProject(payload: {
        projectId: string
    }) {
        return apiV2.deleteRequest(`${PROJECT_URL}/${payload.projectId}`)
    }

    /**
     * @description create a Tasks List
     */
    createTasksList(payload: {
        projectId : string;
        taskListName: string;
        taskListDesc: string;
    }) {
        return apiV2.postRequest(`${PROJECT_URL}/tasklists`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description Get tasklists of a single project
     */
    getProjectTaskLists(payload: {
        projectId: string;
        lastKey?: string;
        isLoadingMore: boolean;
    }) {
        const { projectId, lastKey, isLoadingMore } = payload;
        let url = `${PROJECT_URL}/${projectId}/tasklists`;
        if (lastKey && isLoadingMore) url += `?lastKey=${lastKey}`;
        return apiV2.getRequest(url, {
            useToken: "accessToken"
        });
    }

    /**
     * @description Update a tasklist
    */
     updateTaskList(payload: {
        projectId: string;
        taskListId: string;
        taskListName: string;
        taskListDesc?: string;
    }) {
        const {projectId, taskListId, taskListName, taskListDesc} = payload
        return apiV2.putRequest(
            `${PROJECT_URL}/${projectId}/tasklists/${taskListId}`,
            {
              data: JSON.stringify({taskListName, taskListDesc}),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Delete TaskList
     */
     deleteTaskList(payload: {
        taskListId: string,
        projectId: string,
    }) {
        return apiV2.deleteRequest(`${PROJECT_URL}/${payload.projectId}/tasklists/${payload.taskListId}`)
    }

    /**
     * @description create personal task
     */
    createPersonalTask(payload: {
        taskName: string;
        dueTo?: number;
    }) {
        return apiV2.postRequest(`${PROJECT_URL}/tasks/personal`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description Get personal projects
     */
    getPersonalTasks(payload: {
        isLoadingMore?: boolean;
        lastKey?: string;
    }) {
        const { lastKey, isLoadingMore } = payload;
        let url = `${PROJECT_URL}/tasks/personal`;
        if (lastKey && isLoadingMore ) url += `?lastKey=${lastKey}`;
        return apiV2.getRequest(url, {
            useToken: "accessToken"
        });
    }

    /**
     * @description Update personal task
     */
    updatePersonalTask(payload: {
        taskId: string;
        taskName: string;
        dueTo?: string;
    }) {
        const {taskId, taskName, dueTo} = payload
        return apiV2.putRequest(
            `${PROJECT_URL}/tasks/personal/${taskId}`,
            {
                data: JSON.stringify({taskName, dueTo}),
                useToken: "accessToken",
            }
        );
    }

    /**
     * @description Delete personal task
     */
    deletePersonalTask(payload: {
        taskId: string
    }) {
        return apiV2.deleteRequest(`${PROJECT_URL}/tasks/personal/${payload.taskId}`, {
            useToken: "accessToken"
        });
    }

    /**
     * @description create a task for team
     */
    createTask(payload: {
        projectId: string;
        taskListId: string;
        taskName: string;
        taskDesc: string;
        status: string;
        assignedTo?: string;
    }) {
        return apiV2.postRequest(
            `${PROJECT_URL}/team/tasklists/tasks`,
            {
              data: JSON.stringify(payload),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Get tasks inside a specific project and tasklist
     */
    getTasks(payload: {
        isLoadingMore: boolean;
        projectId: string;
        taskListId: string;
        lastKey?: string;
    }) {
        const { projectId, taskListId, lastKey, isLoadingMore } = payload;
        let url = `${PROJECT_URL}/${projectId}/tasklists/${taskListId}/tasks`;
        if (lastKey && isLoadingMore) {console.log('pagination')
            url += `?lastKey=${lastKey}`;
        }
        return apiV2.getRequest(url, {
            useToken: "accessToken"
        });
    }

    /**
     * @description Get single task
     */
    getSingleTask(payload: {
        isInitial: boolean;
        projectId: string;
        taskListId: string;
        task: {
            taskId: string;
        };
    }) {
        const { projectId, taskListId, task } = payload;
        return apiV2.getRequest(
            `${PROJECT_URL}/${projectId}/tasklists/${taskListId}/tasks/${task.taskId}`
        );

    }

    /**
     * @description Update task
     */
    updateTask(payload: {
        task: FormData,
        params: {
            projectId: string,
            taskListId: string,
            taskId: string,
        }
    }) {
        const {task, params} = payload;
        return apiV2.putRequest(
            `${PROJECT_URL}/${params.projectId}/tasklists/${params.taskListId}/tasks/${params.taskId}`, {
              data: JSON.stringify(task),
              useToken: "accessToken"
            }
        )
    }

    /**
     * @description Delete task
    */
    deleteTask(payload: {
        projectId: string, 
        taskListId: string,
        taskId: string,
    }) {
        const {projectId, taskListId, taskId} = payload
        return apiV2.deleteRequest(`${PROJECT_URL}/${projectId}/tasklists/${taskListId}/tasks/${taskId}`);
    }

    /**
     * @description create a subtask
     */
    createSubTask(payload: {
        subTaskName: string;
        taskId: string;
    }) {
        return apiV2.postRequest(`${PROJECT_URL}/subtasks`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description Get all subtasks for a task
     */
    getSubTasks(payload: {
        taskId: string;
        lastKey?: string;
        isLoadingMore?: boolean;
    }) {
        const { taskId, lastKey, isLoadingMore } = payload;
        let url = `${PROJECT_URL}/subtasks/task/${taskId}`;
        if (lastKey && isLoadingMore) url += `?lastKey=${lastKey}`;
        return apiV2.getRequest(url);
    }

    /**
     * @description Update subtask
    */
    updateSubTask(payload: {
        subTaskId: string;
        subTaskName: string;
        status: number
    }) {
        const {subTaskId, subTaskName, status} = payload
        return apiV2.putRequest(
            `${PROJECT_URL}/subtasks/${subTaskId}`,
            {
              data: JSON.stringify({subTaskName, status}),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Upload task attachment
     */
    uploadAttachment(payload: FormData) {
        return apiV2.putRequest(
            `${PROJECT_URL}/team/tasklists/tasks/${payload.get("taskId")}`, {
                data: payload,
                useToken: "accessToken",
            }
        );
    }

    /**
     * @description create kaban column task status
     */
    createTaskStatusColumn(payload: {
        status: string;
        taskListId: string;
        projectId: string;
    }) {
        return apiV2.postRequest(
            `${PROJECT_URL}/tasklists/kanban/steps`,
            {
              data: JSON.stringify(payload),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Get statuses for kanban view
     */
    getStatuses(payload: {
        projectId: string;
        taskListId: string;
    }) {
        const { projectId, taskListId } = payload;
        return apiV2.getRequest(
            `${PROJECT_URL}/${projectId}/tasklists/${taskListId}`);
    }

    /**
     * @description Reorder statuses
    */
    reoederColumns(payload: {
        projectId: string;
        taskListId: string;
        statuses: string[];
    }) {
        return apiV2.postRequest(
            `${PROJECT_URL}/tasklists/kanban/steps/reorder`,
            {
              data: JSON.stringify(payload),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Update status
     */
    updateStatus(payload: {
        newStep: {
            projectId: string;
            taskListId: string;
            statusId:  string;
            status: string;
          }
    }) {
        const { newStep } = payload;
        const {taskListId, projectId, statusId, status} = newStep
        return apiV2.putRequest(
            `${PROJECT_URL}/tasklists/kanban/steps/${statusId}`,
            {
              data: JSON.stringify({projectId, taskListId, status}),
              useToken: "accessToken",
            }
        );
    }

    /**
     * @description Delete personal task
     */
    deleteStep(payload: {
        tasks: {taskId: string;}[]; 
        step: {id: string;}; 
        movedTo: string; 
        taskListId: string; 
        projectId: string; 
    }) {
        const { step, movedTo, taskListId, projectId } = payload;
        const param = { projectId, taskListId, movedTo };
        return apiV2.deleteRequest(`${PROJECT_URL}/tasklists/kanban/steps/${step.id}`, {
            data: JSON.stringify(param),
            useToken: "accessToken",
        });
    }

    /**
     * @description Get members of organisation
     */
    getOrganisationMembers() {
        return apiV2.getRequest(
            `${HRM_URL}/employees`);
    }


}

export default new ProjectManagerClass();

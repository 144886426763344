import { call, put, select } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import { EmailActions } from "../../reducer/actions.types";
import history from "../../../../../app/routes/history";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description restores a folder within all exchanges inside.
 */
export function* emailRestoreEmail({ payload }: EmailActions["emailRestoreEmail"]) {
    try {
        const { message } = yield call(emailClass.emailRestoreEmail, payload);

        let {
            deleted_exchanges,
        }: {
            deleted_exchanges: RootState["EmailReducer"]["deleted_exchanges"];
        } = yield select(({ EmailReducer }: RootState) => ({
            deleted_exchanges: EmailReducer.deleted_exchanges,
        }));

        deleted_exchanges.items = deleted_exchanges.items.filter((item) => item.exchangeId !== payload.exchangeId);
        deleted_exchanges.lastKey = deleted_exchanges.lastKey.exchangeId === payload.exchangeId ? {} : deleted_exchanges.lastKey;

        // yield put({ type: types.EMAIL_GET_FOLDERS_REQUEST });
        yield put({ type: emailTypes.EMAIL_RESTORE_EMAIL_SUCCESS, payload: deleted_exchanges });
        history.push("/collaboration/email/trash");
        toastHelper.toastStartContent("success", message || "Email restored successfully.");
    } catch (error: any) {
        yield put({
            type: emailTypes.EMAIL_RESTORE_EMAIL_FAILURE,
            payload: error,
        });
    }
}

import apiV2 from "../../../../helpers/api.v2";
import { AppointmentActions } from "../reducer/actions.types";

var _URL_CRM = `${process.env.REACT_APP_API_URL}/crm`;
var _SERVICE_URL = `${process.env.REACT_APP_API_URL}/services`;
const _PUBLIC_URL = `${process.env.REACT_APP_API_URL}/public-bookings`;
var _BOOKING_URL = `${process.env.REACT_APP_API_URL}/bookings`;

/**
 * @description Appointment API calls.
 */
class ApptClass {
    /**
     * @description get appointment by id
     */
    appointmentGetAppointment(payload: AppointmentActions["appointmentGetAppointment"]["payload"]) {
        return apiV2.getRequest(`${_BOOKING_URL}/events/${payload.appointmentId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create appointment
     */
    appointmentCreateAppointment(payload: AppointmentActions["appointmentCreateAppointment"]["payload"]) {
        return apiV2.postRequest(`${_BOOKING_URL}/v2`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get entity past appointments
     */
    appointmentListByEntityId(payload: AppointmentActions["appointmentListByEntityId"]["payload"]) {
        const searchParams = new URLSearchParams();
        searchParams.append("includeCancelled", "true");
        searchParams.append("entity", payload.entityId);
        searchParams.append("type", "apt");
        searchParams.append("limit", `${payload?.limit || 10}`);

        if (payload?.lastKey) {
            searchParams.append("lastKey", payload?.lastKey);
        }

        return apiV2.getRequest(`${_BOOKING_URL}/v1/events?${searchParams.toString()}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get quick dates for appointments
     */
    appointmentGetQuickDates(payload: AppointmentActions["appointmentGetQuickDates"]["payload"]) {
        return apiV2.postRequest(`${_PUBLIC_URL}/v3/quick-dates`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description get slots times
     */
    appointmentGetSlotTimes(payload: AppointmentActions["appointmentGetSlotTimes"]["payload"]) {
        return apiV2.postRequest(`${_PUBLIC_URL}/v3/slots`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description bulk update appointments
     */
    appointmentBulkUpdateAppointment(payload: AppointmentActions["appointmentBulkUpdateAppointment"]["payload"]) {
        return apiV2.putRequest(`${_SERVICE_URL}/kanban/appointments/status`, {
            data: JSON.stringify({
                appointmentsIds: payload.appointmentsIds,
                status: payload.moveTo,
            }),
            useToken: "accessToken",
        });
    }

    /**
     * @description get appointments statuses
     */
    appointmentGetAppointmentStatuses() {
        return apiV2.getRequest(`${_SERVICE_URL}/kanban/statuses`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description Create status
     */
    // appointmentCreateStatus(payload: AppointmentActions["appointmentCreateStatus"]["payload"]) {
    //     return apiV2.postRequest(`${_SERVICE_URL}/kanban/status`, {
    //         data: JSON.stringify(payload),
    //         useToken: "accessToken",
    //     });
    // }

    /**
     * @description Update appointment status
     */
    appointmentUpdateStatus(payload: AppointmentActions["appointmentUpdateStatus"]["payload"]) {
        return apiV2.putRequest(`${_BOOKING_URL}/${payload.appointmentId}`, {
            data: JSON.stringify({ status: payload.moveTo }),
            useToken: "accessToken",
        });
    }

    /**
     * @description Arrange appointments statuses
     */
    // appointmentArrangeStatuses(payload: AppointmentActions["appointmentArrangeStatuses"]["payload"]) {
    //     return apiV2.postRequest(`${_SERVICE_URL}/kanban/arrange`, {
    //         data: JSON.stringify(payload),
    //         useToken: "accessToken",
    //     });
    // }

    /**
     * @description update status name
     */
    // appointmentUpdateStatusName(payload: AppointmentActions["appointmentUpdateStatusName"]["payload"]) {
    //     return apiV2.putRequest(`${_SERVICE_URL}/kanban/status/${payload.statusId}`, {
    //         useToken: "accessToken",
    //         data: JSON.stringify({
    //             name: payload.name,
    //         }),
    //     });
    // }

    /**
     * @description Delete status column
     */
    // appointmentDeleteStatusColumn(payload: AppointmentActions["appointmentDeleteStatusColumn"]["payload"]) {
    //     return apiV2.deleteRequest(`${_SERVICE_URL}/kanban/status/${payload.statusId}`, {
    //         data: JSON.stringify({ movedTo: payload.movedTo }),
    //         useToken: "accessToken",
    //     });
    // }

    /**
     * @description  List appointment's notes.
     */
    appointmentListNotes(payload: AppointmentActions["appointmentListNotes"]["payload"]) {
        return apiV2.getRequest(`${_BOOKING_URL}/notes/${payload.appointmentId}`, {
            useToken: "accessToken",
        });
    }
    /**
     * @description create appointment note.
     */
    appointmentCreateNote(payload: AppointmentActions["appointmentCreateNote"]["payload"]) {
        return apiV2.postRequest(`${_BOOKING_URL}/notes`, {
            data: JSON.stringify({
                appointmentId: payload.appointmentId,
                text: payload.note,
            }),
            useToken: "accessToken",
        });
    }

    /**
     * @description create appointment note.
     */
    appointmentUpdateNote(payload: AppointmentActions["appointmentUpdateNote"]["payload"]) {
        return apiV2.putRequest(`${_BOOKING_URL}/notes/${payload.appointmentId}`, {
            data: JSON.stringify({ text: payload.note }),
            useToken: "accessToken",
        });
    }

    /**
     * @description delete appointment
     */
    appointmentDeleteAppointment(payload: AppointmentActions["appointmentDeleteAppointment"]["payload"]) {
        return apiV2.deleteRequest(`${_BOOKING_URL}/${payload.appointmentId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description get the appointments of a specific kanban status
     */
    appointmentGetAppointmentsByStatus(payload: AppointmentActions["appointmentGetAppointmentsByStatus"]["payload"]) {
        const searchParams = new URLSearchParams();
        searchParams.append("type", "apt");
        searchParams.append("status", payload.statusId);
        searchParams.append("start", payload.range.startDate);
        searchParams.append("end", payload.range.endDate);
        searchParams.append("limit", "10");
        searchParams.append("show", payload.includeArchived ? "all" : "no_archived");

        if (payload.entityId) {
            searchParams.append("entity", payload.entityId);
        }

        if (payload.userId) {
            searchParams.append("user", payload.userId);
        }

        if (payload?.lastKey) {
            searchParams.append("lastKey", payload.lastKey);
        }

        return apiV2.getRequest(`${_BOOKING_URL}/v1/events?${searchParams.toString()}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description archive appointments
     */
    // eslint-disable-next-line unused-imports/no-unused-vars
    appointmentArchiveAppointments({ statusId, ...payload }: AppointmentActions["appointmentArchiveAppointments"]["payload"]) {
        return apiV2.postRequest(`${_BOOKING_URL}/v1/archive`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description archive single appointment
     */
    appointmentArchiveAppointment(payload: AppointmentActions["appointmentArchiveAppointment"]["payload"]) {
        return apiV2.postRequest(`${_BOOKING_URL}/v1/archive`, {
            data: JSON.stringify({
                appointmentIds: [payload.aptId],
                isArchived: payload.isArchived,
            }),
            useToken: "accessToken",
        });
    }

    /**
     * @description list team events
     */
    appointmentGetTeamEvents(payload: AppointmentActions["appointmentGetTeamEvents"]["payload"]) {
        const url = new URL(`${_BOOKING_URL}/v1/events`);
    
        url.searchParams.append("show", payload.includeArchived ? "all" : "no_archived");

        if (payload.userId) {
            url.searchParams.append("user", payload.userId);
        }
        if (payload.type) {
            url.searchParams.append("type", payload.type);
        }
        if (payload.start && payload.end) {
            url.searchParams.append("start", payload.start);
            url.searchParams.append("end", payload.end);
        }

        return apiV2.getRequest(url.toString(), {
            useToken: "accessToken",
        });
    }

    /**
     * @description create personal events
     */
    appointmentCreatepersonalEvent(payload: AppointmentActions["appointmentCreatepersonalEvent"]["payload"]) {
        return apiV2.postRequest(`${_BOOKING_URL}/events`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description update personal events
     */
    appointmentUpdateEvent(payload: AppointmentActions["appointmentUpdateEvent"]["payload"]) {
        const { event, id } = payload;
        return apiV2.putRequest(`${_BOOKING_URL}/events/${id}`, {
            data: JSON.stringify(event),
            useToken: "accessToken",
        });
    }

    /**
     * @description delete personal event
     */
    appointmentDeleteEvent(payload: AppointmentActions["appointmentDeleteEvent"]["payload"]) {
        return apiV2.deleteRequest(`${_BOOKING_URL}/events/${payload.id}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description reschedule appointments
     */
    appointmentRescheduleAppointment(payload: AppointmentActions["appointmentRescheduleAppointment"]["payload"]) {
        const { appointment, appointmentId } = payload;
        return apiV2.putRequest(`${_BOOKING_URL}/reschedule/${appointmentId}`, {
            data: JSON.stringify(appointment),
            useToken: "accessToken",
        });
    }
}

export default new ApptClass();

import { FC, useState, useEffect } from "react";
import { connect, useDispatch  } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { Button, FormControl, InputDatepicker, InputText, popupHelper, InputSwitch, toastHelper } from "@soltivo/draw-a-line";
import { PopupState } from "@soltivo/draw-a-line/core/components/popup/reducer/reducer";
import styles from "./popup.create.event.module.scss";
import { Help } from "@soltivo/draw-a-line/core/components/icons";
import { appointmentCreatePersonalEvent, appointmentUpdateEvent, appointmentDeleteEvent } from "../../redux/reducer/actions";
import { Event, User } from "@soltivo/types";
import moment from "moment";
import useOrgTimeZone from "../../../settings/hooks/useOrgTimeZone";
import { timeToUTC } from "../../helpers/time";

interface PopupCreatePersonalEventProps extends MapStateProps {
    event?: Event;
    footer: ((
        children: (
          props: Pick<
            PopupState,
            | "show"
            | "actions"
            | "removeActions"
            | "animation"
            | "width"
            | "cancelEscEvent"
          >
        ) => JSX.Element | React.ReactNode
    ) => JSX.Element | React.ReactNode) | undefined;
}

const PopupCreatePersonalEvent: FC<PopupCreatePersonalEventProps> = ({ user, event, creatingPersonalEvent, deleteEvent, updateEvent, footer }) => {
    const dispatch = useDispatch();
    const [editing, setEditing] = useState(false);
    const today = new Date().toISOString();
    const [form, setForm] = useState<{
        title: string;
        start: string;
        end: string;
        isPrivateEvent: boolean;
    }>({
        title: "",
        start: today,
        end: today,
        isPrivateEvent: false,
    });

    const orgTimeZone = useOrgTimeZone();

    useEffect(() => {
        if(event) {
            // Initialize form with event data
            const { end, start, title, isPrivate } = event;
            setEditing(true);
            setForm({
                end,
                start,
                title,
                isPrivateEvent: !!isPrivate,
            });
        }
    }, [event]);

    const onDeleteEvent = () => {
        if(!event) return;
        dispatch(appointmentDeleteEvent({ id: event.id, employeeId: event.userId }));
    };

    const onSubmit = () => {
        const { title, start, end, isPrivateEvent } = form;
        if(moment(start).valueOf() >= moment(end).valueOf()) {
            toastHelper.toastStartContent("danger", "End time must be greater than start time.");
            return;
        }
        if(editing) {
            if(!event) return;
            // Update personal event
            const startTime = moment(start);
            startTime.set({ seconds: 0 });
            const endTime = moment(end);
            endTime.set({ seconds: 0 });

            dispatch(appointmentUpdateEvent({
                id: event.id,
                event: {
                    title,
                    start: timeToUTC(startTime.format(), orgTimeZone),
                    end: timeToUTC(endTime.format(), orgTimeZone),
                    isPrivate: isPrivateEvent,
                    userId: user.userId ,
                }
            }));
        } else {
            const startTime = moment(start);
            startTime.set({ seconds: 0 });
            const endTime = moment(end);
            endTime.set({ seconds: 0 });

            // Create personal event
            dispatch(appointmentCreatePersonalEvent({
                title,
                start: timeToUTC(startTime.format(), orgTimeZone),
                end: timeToUTC(endTime.format(), orgTimeZone),
                isPrivate: isPrivateEvent,
                userId: user.userId,
            }));
            
        }
    };

    return (
        <>
        <div className={styles.popup__create__event}>
            <p className="text-12 text-geyser-700">
                Block a given time frame on your calendar so no appointment can be book.
            </p>
            <div className={styles.items__inputs}>
                <FormControl labelProps={{ value: "Event title" }}>
                    <InputText 
                        name="title"
                        value={form.title}
                        onChange={e => setForm({ ...form, title: e.target.value })}
                        placeholder={"Birthday party"}
                        autoComplete="off"
                    />
                </FormControl>
            </div>

            <div className={styles.event__time}>
                <FormControl labelProps={{ value: "Event start" }}>
                    <InputDatepicker 
                        date={form.start}
                        format={"YYYY-MM-DD [at] HH:mm"}
                        className="inputStartTime"
                        showTime
                        onChange={date => setForm({ ...form, start: date as string })}
                    />
                </FormControl>
                <FormControl labelProps={{ value: "Event end" }}>
                    <InputDatepicker 
                        date={form.end}
                        format={"YYYY-MM-DD [at] HH:mm"}
                        className="inputEndTime"
                        showTime
                        onChange={date => setForm({ ...form, end: date as string })}
                    />
                </FormControl>
            </div>

            <div className={styles.radio__input__wrapper}>
                <InputSwitch 
                    checked={form.isPrivateEvent}
                    onChange={e => setForm({ ...form, isPrivateEvent: e.target.checked })}
                />
                <p className="text-12 text-geyser-800">Private event</p>
                <span data-title={`A private event won’t display the title of\n your appointment in the team calendar.\n It will only show you as busy.`}>
                    <Help />
                </span>
            </div>
        </div>
        {footer && footer(() => (
            <div className={styles.popup__actions}>
                <Button onClick={() => popupHelper.popupEnd()} variant="geyser-500" outline border={false} padding={false}>
                    Cancel
                </Button>
                {editing &&
                <Button disabled={deleteEvent.loading} loading={deleteEvent.loading ? { keepContent: true }: false} onClick={() => onDeleteEvent()} variant="valencia-600" outline border={false} padding={false}>
                    Delete
                </Button>}
                <Button data-testid="submitButton" loading={creatingPersonalEvent.loading || updateEvent.loading} onClick={onSubmit} variant="primary" disabled={creatingPersonalEvent.loading || !form.title.length}>
                    {editing ? "Update" : "Create"}
                </Button>
            </div>
        ))}
        </>
    );
};

const mapStateToProps = ({ AppointmentReducer, AuthReducer }: RootState) => ({
    updateEvent: AppointmentReducer.updateEvent,
    creatingPersonalEvent: AppointmentReducer.creatingPersonalEvent,
    deleteEvent: AppointmentReducer.deleteEvent,
    user: AuthReducer.user as User,
});

type MapStateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PopupCreatePersonalEvent);

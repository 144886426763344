import { User } from "@soltivo/types";
import React, { FC } from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { RootState } from "../../redux/reducers";
import history from "./history";
import useAppNavigation from "./navigations.all";
import useQuery from "../../helpers/hooks/useQuery";

interface NormalRouteProps extends MapStateToProps {
    user: User | null;
    path: string | string[];
    render?: (
        props: RouteComponentProps<
            {
                [x: string]: string | undefined;
            },
            any,
            unknown
        >
    ) => React.ReactNode;
    component?: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, any, unknown>>;
    children?: React.ReactNode | any;
}
/**
 * @description creates a normal route to any route.
 * @observe if user is logged in this route will push the user to the dashboard, if you do not want this to happen
 * just use a Route component from react-router-dom.
 */
const NormalRoute: FC<NormalRouteProps> = ({ user, loading_current_user, children, path, render, component }) => {
    const _NAVIGATIONS = useAppNavigation();
    const queryParam = useQuery()
    // This checks if the user is being loaded before take any action to move to dashboard or stay in login.
    // this does not happen inside the dashboard because there's a load component there.
    if (loading_current_user) {
        return (
            <span
                style={{
                    fontSize: "1.5rem",
                    color: "#555555",
                }}>
                Loading...
            </span>
        );
    }

    if (user) {
        return <Redirect to={history.location.search ? decodeURIComponent(queryParam.get("next") || "") : _NAVIGATIONS[0].apps[0].path} />;
    }
    return <Route exact path={path} component={component ? component : undefined} render={render ? render : children ? () => children : undefined} />;
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    user: AuthReducer.user,
    loading_current_user: AuthReducer.loading_current_user,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(NormalRoute);

import { IServicesEarnings } from "../../service";
import { types } from "./types";
import { ServiceCategory, Service } from "@soltivo/types/types/applications/services/index";

export interface CategoryItemType extends ServiceCategory {
    services: Service[];
}

export interface ServiceState {
    error: any;
    categories: CategoryItemType[];
    loadingCategories: boolean;
    loadingCategory: boolean;
    searchedCategories: ServiceCategory[];
    creating: boolean;
    searching: boolean;
    creatingService: boolean;
    updatingService: boolean;
    searchRequest: boolean;
    category: ServiceCategory | null;
    services: Service[];
    allServices: Service[];
    loadingServices: boolean;
    loadingAllServices: boolean;
    service: Service | null;
    loadingService: boolean;
    deletingService: boolean;
    earnings: IServicesEarnings | any;
    loadingEarnings: boolean;
    loadingImages: boolean;
    updatingCategory: boolean;
    deletingCategory: boolean;
    unsplashImages: {
        images: any[];
        totalPages: number;
        page: number;
    };
    categorySuggestions: {
        categories: { name: string }[];
        loading: boolean;
        status?: "failed" | "success";
    };
}

const _INITIAL_STATE: ServiceState = {
    error: null,
    categories: [],
    loadingCategories: true,
    loadingCategory: true,
    searchedCategories: [],
    creating: false,
    updatingService: false,
    searching: false,
    searchRequest: false,
    category: null,
    services: [],
    allServices: [],
    loadingServices: true,
    creatingService: false,
    loadingAllServices: true,
    service: null,
    loadingService: true,
    deletingService: false,
    earnings: {},
    loadingEarnings: true,
    loadingImages: false,
    updatingCategory: false,
    deletingCategory: false,
    unsplashImages: {
        images: [],
        totalPages: 0,
        page: 1,
    },
    categorySuggestions: {
        loading: true,
        categories: [],
        status: undefined,
    },
};

const ServiceReducer = (state = _INITIAL_STATE, action: { type: string; payload: any }): ServiceState => {
    switch (action.type) {
        case types.SERVICE_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...(action.payload as Partial<ServiceState>)
            };

        case types.SERVICE_GET_CATEGORY_SUGGESTIONS_REQUEST:
            return {
                ...state,
                categorySuggestions: {
                    ...state.categorySuggestions,
                    loading: true,
                },
            };

        case types.SERVICE_GET_CATEGORY_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                categorySuggestions: {
                    ...state.categorySuggestions,
                    categories: action.payload,
                    loading: false,
                },
            };

        case types.SERVICE_GET_CATEGORY_SUGGESTIONS_FAILURE:
            return {
                ...state,
                categorySuggestions: {
                    ...state.categorySuggestions,
                    loading: false,
                },
            };

        case types.SERVICE_GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loadingCategories: true,
            };

        case types.SERVICE_GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                loadingCategories: false,
                error: null,
            };

        case types.SERVICE_GET_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingCategories: false,
            };

        case types.SERVICE_SEARCH_CATEGORY_REQUEST:
            return {
                ...state,
                searchRequest: true,
            };

        case types.SERVICE_SEARCH_CATEGORY_SUCCESS:
            return {
                ...state,
                searching: true,
                searchRequest: false,
                searchedCategories: action.payload,
                error: null,
            };

        case types.SERVICE_SEARCH_CATEGORY_FAILURE:
            return {
                ...state,
                searchRequest: false,
                error: action.payload,
            };

        case types.SERVICE_SEARCH_CATEGORY_CLOSE:
            return {
                ...state,
                searching: false,
                searchRequest: false,
                searchedCategories: [],
                error: null,
            };

        case types.SERVICE_GET_CATEGORY_REQUEST:
            return {
                ...state,
                category: null,
                loadingCategory: true,
                error: null,
            };

        case types.SERVICE_GET_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loadingCategory: false,
                error: null,
            };

        case types.SERVICE_GET_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingCategory: false,
            };

        case types.SERVICE_CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                error: null,
                creating: true,
            };
        case types.SERVICE_CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                error: null,
                creating: false,
            };

        case types.SERVICE_CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
                creating: false,
            };

        case types.SERVICE_UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                updatingCategory: true,
            };

        case types.SERVICE_UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload.categories,
                searchedCategories: action.payload.searchedCategories,
                updatingCategory: false,
                error: null,
            };

        case types.SERVICE_UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
                updatingCategory: false,
            };

        case types.SERVICE_DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                deletingCategory: true,
            };

        case types.SERVICE_DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                deletingCategory: false,
                error: null,
            };

        case types.SERVICE_DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.payload,
                deletingCategory: false,
            };

        case types.SERVICE_GET_SERVICES_REQUEST:
            return {
                ...state,
                loadingServices: true,
                service: null,
            };

        case types.SERVICE_GET_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload,
                loadingServices: false,
                error: null,
            };

        case types.SERVICE_GET_SERVICES_FAILURE:
            return {
                ...state,
                services: [],
                error: action.payload,
                loadingServices: false,
            };

        case types.SERVICE_CLOSE_SERVICES_REQUEST:
            return {
                ...state,
                services: [],
                loadingServices: true,
                service: null,
            };

        case types.SERVICE_GET_ALL_SERVICES_REQUEST:
            return {
                ...state,
                loadingAllServices: true,
            };

        case types.SERVICE_GET_ALL_SERVICES_SUCCESS:
            return {
                ...state,
                allServices: action.payload,
                loadingAllServices: false,
                error: null,
            };

        case types.SERVICE_GET_ALL_SERVICES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingAllServices: false,
            };

        case types.SERVICE_GET_SERVICE_REQUEST:
            return {
                ...state,
                service: null,
                loadingService: true,
            };

        case types.SERVICE_GET_SERVICE_SUCCESS:
            return {
                ...state,
                service: action.payload,
                loadingService: false,
                error: null,
            };

        case types.SERVICE_GET_SERVICE_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingService: false,
            };

        case types.SERVICE_CREATE_SERVICE_REQUEST:
            return {
                ...state,
                creatingService: true,
            };

        case types.SERVICE_CREATE_SERVICE_SUCCESS:
            return {
                ...state,
                error: null,
                service: action.payload,
                creatingService: false,
            };

        case types.SERVICE_CREATE_SERVICE_FAILURE:
            return {
                ...state,
                error: action.payload,
                creatingService: false,
            };

        case types.SERVICE_UPDATE_SERVICE_REQUEST:
            return {
                ...state,
                updatingService: true,
                error: null,
            };

        case types.SERVICE_UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                service: action.payload,
                updatingService: false,
                error: null,
            };

        case types.SERVICE_UPDATE_SERVICE_FAILURE:
            return {
                ...state,
                error: action.payload,
                updatingService: false,
            };

        case types.SERVICE_DELETE_SERVICE_REQUEST:
            return {
                ...state,
                deletingService: true,
            };

        case types.SERVICE_DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                error: null,
                deletingService: false,
                loadingService: true,
                service: null,
                categories: action.payload.categories
            };

        case types.SERVICE_DELETE_SERVICE_FAILURE:
            return {
                ...state,
                error: action.payload,
                deletingService: false,
                service: null,
            };

        //// EARNINGS START ////

        case types.SERVICE_GET_EARNINGS_METRICS_REQUEST:
            return {
                ...state,
                loadingEarnings: true,
            };
        case types.SERVICE_GET_EARNINGS_METRICS_SUCCESS:
            return {
                ...state,
                earnings: action.payload,
                loadingEarnings: false,
            };
        case types.SERVICE_GET_EARNINGS_METRICS_FAILURE:
            return {
                ...state,
                earnings: {},
                error: action.payload,
                loadingEarnings: false,
            };

        //// EARNINGS END ////

        //// UNSPLASH IMAGES ////

        case types.SERVICE_GET_UNSPLASH_IMAGES_REQUEST:
            return {
                ...state,
                loadingImages: true,
                unsplashImages: {
                    ...state.unsplashImages,
                    page: action.payload.page,
                },
            };

        case types.SERVICE_GET_UNSPLASH_IMAGES_SUCCESS:
            return {
                ...state,
                unsplashImages: {
                    ...state.unsplashImages,
                    images: state.unsplashImages.page === 1 ? action.payload.results : [...state.unsplashImages.images, ...action.payload.results],
                    totalPages: action.payload.totalPages,
                    page: action.payload.page + 1,
                },
                loadingImages: false,
                error: null,
            };

        case types.SERVICE_GET_UNSPLASH_IMAGES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingImages: false,
            };

        //// END UNSPLASH IMAGES ////

        default:
            return state;
    }
};

export default ServiceReducer;

import { createContext } from "react";
import { INITIAL_STATE } from "./reducer";

const state = {
    ...INITIAL_STATE,
};

export const HeaderContext = createContext<{
    state: ReturnType<() => typeof state>;
    dispatch: React.Dispatch<{
        type: string;
        payload?: any;
        dispatch?:
            | React.Dispatch<{
                  type: string;
                  payload?: any;
              }>
            | undefined;
    }>;
}>({
    state: {
        ...INITIAL_STATE,
    },
    dispatch: () => undefined,
});

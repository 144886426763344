import { useContext, useEffect } from "react";
import ModuleName from "./components/module-name/module.name";
import RoutesContext from "../../routes/context/routes/routes.context";
import { HeaderContext } from "./reducer/header.context";
import { headerChangeNotificationsBar, headerSidebarToggleDisplay } from "./reducer/actions";
import AppFallback from "../../components/app.fallback/app.fallback";
import { Button, useComponentVisible } from "@soltivo/draw-a-line";
import { Plus, ArrowBackward, Bell } from "@soltivo/draw-a-line/core/components/icons";
import HeaderSectionNav from "./components/nav/header.nav";
import { useHistory } from "react-router";
import CheckPermission from "../../components/check.permissions/check.permissions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { notificationsGetAllNotifications } from "../../../applications/settings/redux/notifications/reducer/actions";
import Notifications from "../../layout/notifications/notifications";

/**
 * @description creates the main application navbar.
 */
const Header = () => {
    const {
        state: { loading },
    } = useContext(RoutesContext);
    const dispatch = useDispatch();
    const {
        dispatch: dispatchContext,
        state: { showMainSidebar, loadingApp, menu, headerActions, appName, header, notifs },
    } = useContext(HeaderContext);
    const history = useHistory();

    const onToggleSidebar = () => dispatchContext(headerSidebarToggleDisplay({ show: !showMainSidebar }));

    useEffect(() => {
        dispatch(notificationsGetAllNotifications());
    }, []);

    const { ref: notifsRef, isComponentVisible, setIsComponentVisible } = useComponentVisible();

    const onToggleNotifications = () => {
        setIsComponentVisible(true);
        dispatchContext(
            headerChangeNotificationsBar({
                notifs: {
                    ...notifs,
                    showNotifications: true,
                },
            })
        );
    }

    const mapStateToProps = ({ NotificationReducer }: RootState) => ({
        notifications: NotificationReducer.notifications,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;

    const { notifications }: MapStateToProps = useSelector(mapStateToProps);
    const unReadNotifications = notifications.list?.filter((n) => !n.isRead).length;

    return (
        <header id="header" className={`${loading ? "loading__component-nav " : ""}`}>
            <div className={`app-container header__container`}>
                {header?.type === "INITIAL" ? (
                    <>
                        <div className="mobile-menu d-flex justify-between">
                            <div className="header__app-name">
                                <ModuleName onClick={onToggleSidebar}>{appName.replace(/(-)/g, " ")}</ModuleName>
                            </div>

                            {/* for mobile */}
                            <div className="d-flex d-md-none">
                                {/* Applications actions, if this gets to big you can move to a component :)*/}
                                <div className="d-flex">
                                    <div className="d-lg-flex header_icon_actions">
                                        {headerActions?.actions?.map((Element, index) => {
                                            const ComponentElement = () => Element;
                                            return <ComponentElement key={index} />;
                                        })}
                                    </div>

                                    {!(loading || loadingApp) && (
                                        <div ref={notifsRef} className={`notification_action ${!headerActions?.onNew ? "notification_action__pdright": ""}`}>
                                            <Button onClick={onToggleNotifications} border={false} padding={false} outline>
                                                <Bell />
                                                {!!unReadNotifications && <div className="notifications__length">{unReadNotifications}</div>}
                                            </Button>
                                            {isComponentVisible && <Notifications />}
                                        </div>
                                    )}

                                    {headerActions?.onNew ? (
                                        <>
                                            {headerActions.component ? (
                                                (() => {
                                                    const { component } = headerActions;
                                                    const ComponentElement = () => (component ? component : <></>);
                                                    return (
                                                        <CheckPermission assign permission={headerActions.permission}>
                                                            <ComponentElement />
                                                        </CheckPermission>
                                                    );
                                                })()
                                            ) : (
                                                <CheckPermission assign permission={headerActions.permission}>
                                                    <Button className="header_action" variant="white" onClick={headerActions?.onNew} border={false} stroke={false} outline={false}>
                                                        <Plus />
                                                        {headerActions.text || "New"}
                                                    </Button>
                                                </CheckPermission>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        {/* Section menu */}
                        <div className="navigation-wrapper">{menu && <HeaderSectionNav menu={menu} />}</div>

                        {/* Actions Context components */}
                        <div className="d-flex justify-end">
                            <div className="d-none show d-md-flex justify-end">
                                {/* Applications actions, if this gets to big you can move to a component :)*/}
                                <div className="d-flex header_icon_actions__wrap">
                                    <div className={`d-lg-flex header_icon_actions ${headerActions?.actions ? "header_icon_actions__visible" : ""}`}>
                                        {headerActions?.actions?.map((Element, index) => {
                                            const ComponentElement = () => Element;
                                            return <ComponentElement key={index} />;
                                        })}
                                    </div>

                                    {!(loading || loadingApp) && (
                                        <div ref={notifsRef} className={`notification_action ${!headerActions?.onNew ? "notification_action__pdright": ""}`}>
                                            <Button onClick={onToggleNotifications} border={false} padding={false} outline>
                                                <Bell />
                                                {!!unReadNotifications && <div className="notifications__length">{unReadNotifications}</div>}
                                            </Button>
                                            {isComponentVisible && <Notifications />}
                                        </div>
                                    )}

                                    {/* Do not move new from this the last children */}
                                    {headerActions?.onNew ? (
                                        <>
                                            {headerActions.component ? (
                                                (() => {
                                                    const { component } = headerActions;
                                                    const ComponentElement = () => (component ? component : <></>);
                                                    return (
                                                        <CheckPermission assign permission={headerActions.permission}>
                                                            <ComponentElement />
                                                        </CheckPermission>
                                                    );
                                                })()
                                            ) : (
                                                <CheckPermission assign permission={headerActions.permission}>
                                                    <Button data-testid="header_action_button" className="header_action" variant="white" onClick={headerActions?.onNew} border={false} stroke={false} outline={false}>
                                                        <Plus />
                                                        {headerActions.text || "New"}
                                                    </Button>
                                                </CheckPermission>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="d-flex justify-between">
                        <div className="header__app-name">
                            <Button
                                onClick={() => {
                                    if (header?.backPath) {
                                        history.push(header.backPath);
                                        return;
                                    }
                                    history.goBack();
                                }}
                                variant="white"
                                outline
                                border={false}>
                                <ArrowBackward /> &nbsp;&nbsp; Back
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {loadingApp ? <AppFallback /> : null}
        </header>
    );
};

export default Header;
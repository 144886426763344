import { ContactEntity, ContactEntityList, CustomFieldItem, EntityNoteList, ContactEntityItem, ContactSearchItem } from "../../contacts";
import { types } from "./types";

export interface ContactsState {
    entity: ContactEntity | null;
    newEntity: ContactEntityItem | null;
    loadingEntity: boolean;
    leads: ContactEntityList;
    loadingLeads: boolean;
    creatingLead: boolean;
    clients: ContactEntityList;
    loadingClients: boolean;
    creatingClient: boolean;
    suppliers: ContactEntityList;
    loadingSuppliers: boolean;
    creatingSupplier: boolean;
    notes: EntityNoteList;
    loadingNotes: boolean;
    statuses: any;
    entityDeleted: boolean;
    error: any;
    loadingCustomFields: boolean;
    customFields: CustomFieldItem[];
    searchResults: ContactSearchItem[];
    searchingEntity: boolean;
    deletingEntity: boolean;
}

const INITIAL_STATE: ContactsState = {
    entity: null,
    newEntity: null,
    loadingEntity: true,
    leads: { items: [], lastKey: undefined },
    loadingLeads: true,
    creatingLead: false,
    clients: { items: [], lastKey: undefined },
    loadingClients: true,
    creatingClient: false,
    suppliers: { items: [], lastKey: undefined },
    loadingSuppliers: true,
    creatingSupplier: false,
    notes: { list: [], lastKey: undefined },
    loadingNotes: true,
    statuses: {
        lead: [],
        client: [],
    },
    entityDeleted: false,
    error: null,
    loadingCustomFields: true,
    customFields: [],
    searchResults: [],
    searchingEntity: false,
    deletingEntity: false,
};

const ContactsReducer = (
    state = INITIAL_STATE,
    action: {
        type: string;
        payload: any;
    }
): ContactsState => {
    switch (action.type) {
        case types.CONTACTS_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...action.payload,
            };

        case types.CONTACTS_GET_ENTITY_REQUEST:
            return {
                ...state,
                loadingEntity: true,
                entityDeleted: false,
            };

        case types.CONTACTS_GET_ENTITY_SUCCESS:
            return {
                ...state,
                entity: action.payload,
                loadingEntity: false,
            };

        case types.CONTACTS_GET_ENTITY_FAILURE:
            return {
                ...state,
                entity: null,
                error: action.payload,
                loadingEntity: false,
            };

        /////////LEADS

        case types.CONTACTS_CREATE_LEAD_REQUEST:
            return {
                ...state,
                creatingLead: true,
                newEntity: null,
            };

        case types.CONTACTS_CREATE_LEAD_SUCCESS:
            return {
                ...state,
                creatingLead: false,
                newEntity: action.payload,
                error: null,
            };

        case types.CONTACTS_CREATE_LEAD_FAILURE:
            return {
                ...state,
                error: action.payload,
                newEntity: null,
                creatingLead: false,
            };

        case types.CONTACTS_GET_LEADS_REQUEST:
            return {
                ...state,
                loadingLeads: true,
            };

        case types.CONTACTS_GET_LEADS_SUCCESS:
            return {
                ...state,
                leads: action.payload,
                loadingLeads: false,
            };

        case types.CONTACTS_GET_LEADS_FAILURE:
            return {
                ...state,
                loadingLeads: false,
            };

        case types.CONTACTS_DELETE_LEAD_REQUEST:
            return {
                ...state,
                deletingEntity: true,
            };

        case types.CONTACTS_DELETE_LEAD_SUCCESS:
            return {
                ...state,
                error: null,
                entityDeleted: true,
                deletingEntity: false,
                leads: {
                    ...state.leads,
                    items: [...state.leads.items.filter((lead: any) => lead.entityId !== action.payload.entityId)],
                },
            };

        case types.CONTACTS_DELETE_LEAD_FAILURE:
            return {
                ...state,
                error: action.payload,
                entityDeleted: false,
                deletingEntity: false,
            };

        case types.CONTACTS_UPDATE_LEAD_SUCCESS:
            return {
                ...state,
                entity: action.payload.entity,
                leads: action.payload.leads,
                error: null,
            };

        case types.CONTACTS_UPDATE_LEAD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.CONTACTS_CONVERT_LEAD_SUCCESS:
            return {
                ...state,
                leads: action.payload.leads,
                entity: action.payload.client,
            };

        case types.CONTACTS_CONVERT_LEAD_FAILURE:
            return {
                ...state,
            };

        /////////CLIENTS

        case types.CONTACTS_CREATE_CLIENT_REQUEST:
            return {
                ...state,
                creatingClient: true,
                newEntity: null,
            };

        case types.CONTACTS_CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                creatingClient: false,
                newEntity: action.payload,
                error: null,
            };

        case types.CONTACTS_CREATE_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
                creatingClient: false,
            };

        case types.CONTACTS_GET_CLIENTS_REQUEST:
            return {
                ...state,
                loadingClients: true,
            };

        case types.CONTACTS_GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                loadingClients: false,
            };

        case types.CONTACTS_GET_CLIENTS_FAILURE:
            return {
                ...state,
                loadingClients: false,
            };

        case types.CONTACTS_DELETE_CLIENT_REQUEST:
            return {
                ...state,
                deletingEntity: true,
            };

        case types.CONTACTS_DELETE_CLIENT_SUCCESS:
            return {
                ...state,
                error: null,
                entityDeleted: true,
                deletingEntity: false,
                clients: {
                    ...state.clients,
                    items: [...state.clients.items.filter((client: any) => client.entityId !== action.payload.entityId)],
                },
            };

        case types.CONTACTS_DELETE_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
                deletingEntity: false,
                entityDeleted: false,
            };

        case types.CONTACTS_UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                entity: action.payload.entity,
                clients: action.payload.clients,
                error: null,
            };

        case types.CONTACTS_UPDATE_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        /////////Suppliers

        case types.CONTACTS_CREATE_SUPPLIER_REQUEST:
            return {
                ...state,
                creatingSupplier: true,
            };

        case types.CONTACTS_CREATE_SUPPLIER_SUCCESS:
            return {
                ...state,
                creatingSupplier: false,
                error: null,
            };

        case types.CONTACTS_CREATE_SUPPLIER_FAILURE:
            return {
                ...state,
                error: action.payload,
                creatingSupplier: false,
            };

        case types.CONTACTS_GET_SUPPLIERS_REQUEST:
            return {
                ...state,
                loadingSuppliers: true,
            };

        case types.CONTACTS_GET_SUPPLIERS_SUCCESS:
            return {
                ...state,
                suppliers: action.payload,
                loadingSuppliers: false,
            };

        case types.CONTACTS_GET_SUPPLIERS_FAILURE:
            return {
                ...state,
                loadingSuppliers: false,
            };

        case types.CONTACTS_DELETE_SUPPLIER_REQUEST:
            return {
                ...state,
                deletingEntity: true,
            };

        case types.CONTACTS_DELETE_SUPPLIER_SUCCESS:
            return {
                ...state,
                error: null,
                entityDeleted: true,
                deletingEntity: false,
                suppliers: {
                    ...state.suppliers,
                    items: [...state.suppliers.items.filter((contact: any) => contact.entityId !== action.payload.entityId)],
                },
            };

        case types.CONTACTS_DELETE_SUPPLIER_FAILURE:
            return {
                ...state,
                error: action.payload,
                entityDeleted: false,
                deletingEntity: false,
            };

        case types.CONTACTS_UPDATE_SUPPLIER_SUCCESS:
            return {
                ...state,
                entity: action.payload.entity,
                suppliers: action.payload.suppliers,
                error: null,
            };

        case types.CONTACTS_UPDATE_SUPPLIER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        /////////NOTES

        case types.CONTACTS_CREATE_NOTE_SUCCESS:
            return {
                ...state,
                notes: { ...action.payload },
                error: null,
            };

        case types.CONTACTS_CREATE_NOTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.CONTACTS_GET_NOTES_REQUEST:
            return {
                ...state,
                loadingNotes: true,
            };

        case types.CONTACTS_GET_NOTES_SUCCESS:
            return {
                ...state,
                notes: action.payload,
                loadingNotes: false,
            };

        case types.CONTACTS_GET_NOTES_FAILURE:
            return {
                ...state,
                loadingNotes: false,
            };

        case types.CONTACTS_GET_CUSTOM_FIELDS_REQUEST:
            return {
                ...state,
                loadingCustomFields: true,
            };

        case types.CONTACTS_GET_CUSTOM_FIELDS_SUCCESS:
            return {
                ...state,
                customFields: action.payload,
                error: null,
                loadingCustomFields: false,
            };

        case types.CONTACTS_GET_CUSTOM_FIELDS_FAILURE:
            return {
                ...state,
                customFields: [],
                loadingCustomFields: false,
            };

        case types.CONTACTS_CREATE_CUSTOM_FIELDS_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.CONTACTS_CREATE_CUSTOM_FIELDS_FAILURE:
            return {
                ...state,
                customFields: state.customFields.length ? [...state.customFields] : [],
            };

        //there's no delete for notes.

        case types.CONTACTS_UPDATE_NOTE_SUCCESS:
            return {
                ...state,
                notes: action.payload.notes,
                error: null,
            };

        case types.CONTACTS_UPDATE_NOTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.CONTACTS_SEARCH_ENTITY_REQUEST:
            return {
                ...state,
                searchingEntity: true,
            };

        case types.CONTACTS_SEARCH_ENTITY_SUCCESS:
            return {
                ...state,
                searchResults: action.payload,
                searchingEntity: false,
            };

        case types.CONTACTS_SEARCH_ENTITY_FAILURE:
            return {
                ...state,
                error: action.payload,
                searchingEntity: false,
            };

        default:
            return state;
    }
};

export default ContactsReducer;

import { call, put, takeEvery, takeLatest, select, PutEffect, CallEffect, SelectEffect, ActionChannelEffect, TakeEffect, takeLeading } from "redux-saga/effects";
import { types } from "../reducer/types";
import { types as serviceTypes } from "../../../service/redux/reducer/types";
import { popupHelper, toastHelper } from "@soltivo/draw-a-line";
import apptClass from "./appointment.class";
import moment from "moment-timezone";
import history from "../../../../app/routes/history";
import { AppointmentActions } from "../reducer/actions.types";
import apiV2, { ValidationError, ErrorResponseV2 } from "../../../../helpers/api.v2";
import { AppointmentState } from "../reducer/reducer";
import { RootState } from "../../../../redux/reducers";
import { DateSlot } from "../../appointment";
import appointmentValidation from "../../validation/appointment.validation";
import { getBrowserTimezone, utcToTimezone } from "../../helpers/time";
import { Appointment, User } from "@soltivo/types";
import appointmentClass from "./appointment.class";

/**
 *
 * @description helper to redirect user after update, delete, create a new event.
 */
function* refreshAppointmentView(employeeId?: string) {
    if (window.location.pathname.match(/\/calendar\/appointments/)) return;
    const isTeamDayViewRoute = /(calendar)\/(team)\/(day)/g.test(history.location.pathname.toLowerCase());

    const mapStateToProps = ({ AppointmentReducer, AuthReducer }: RootState) => ({
        eventsDateRange: AppointmentReducer.eventsDateRange,
        user: AuthReducer.user,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;
    let {
        eventsDateRange: { start, end },
        user,
    }: MapStateToProps = yield select(mapStateToProps);

    const _start = moment(start).utc().startOf("day").format();
    const _end = moment(end).utc().endOf("day").format();

    if (isTeamDayViewRoute) {
        // Team day view
        yield put({
            type: types.APPOINTMENT_GET_TEAM_EVENTS_REQUEST,
            payload: { start: _start, end: _end },
        });
    } else {
        if (user?.userId !== employeeId) return;
        // Only refresh appointments if it is the connected user
        yield put({
            type: types.APPOINTMENT_GET_TEAM_EVENTS_REQUEST,
            payload: { start: _start, end: _end, userId: employeeId },
        });
    }
}

/**
 * @description get appointment by id
 */
function* appointmentGetAppointment({ payload }: AppointmentActions["appointmentGetAppointment"]) {
    try {
        const { data } = yield call(apptClass.appointmentGetAppointment, payload);
        const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
            org: OrgReducer.org,
            user: AuthReducer.user as User,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;
        let { org, user }: MapStateToProps = yield select(mapStateToProps);

        // @ts-ignore
        const timezone = user.timezone ? user.timezone : org?.timeZone ? org?.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
        // convert utc time to org timezone
        data.start = utcToTimezone(data.start, timezone);
        data.end = utcToTimezone(data.end, timezone);

        yield put({
            type: types.APPOINTMENT_GET_APPOINTMENT_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({
            type: types.APPOINTMENT_GET_APPOINTMENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description create an appointment.
 */
function* appointmentCreateAppointment({ payload, options }: AppointmentActions["appointmentCreateAppointment"]) {
    try {
        yield appointmentValidation.appointmentCreateAppointment(payload);

        const { data } = yield call(apptClass.appointmentCreateAppointment, payload);

        //for reschedule route.
        if (options?.reschedule) {
            yield call(apptClass.appointmentUpdateStatus, {
                appointmentId: options.reschedule.appointmentId,
                moveTo: options.reschedule.moveTo,
            });
        }

        yield put({
            type: types.APPOINTMENT_CREATE_APPOINTMENT_SUCCESS,
            payload: { newAppointment: data },
        });

        yield refreshAppointmentView(data.userId);

        yield put({
            type: serviceTypes.SERVICE_GET_EARNINGS_METRICS_REQUEST,
            payload: { date: moment().format("YYYY-MM-DD") },
        });
    } catch (error: any) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        } else {
            apiV2.toastAllErrors(error);
        }
        yield put({
            type: types.APPOINTMENT_CREATE_APPOINTMENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get entity past appointments
 */
function* appointmentListByEntityId({ payload }: AppointmentActions["appointmentListByEntityId"]) {
    try {
        const { data } = yield call(apptClass.appointmentListByEntityId, payload);

        const result = {
            isMore: payload.lastKey ? true : false,
            items: data,
        };

        yield put({ type: types.APPOINTMENT_LIST_BY_ENTITY_ID_SUCCESS, payload: result });
    } catch (error: any) {
        console.log(error);

        yield put({ type: types.APPOINTMENT_LIST_BY_ENTITY_ID_FAILURE, payload: error });
    }
}

/**
 * @description Get employee quick available days
 */
function* appointmentGetQuickDates({ payload }: AppointmentActions["appointmentGetQuickDates"]) {
    try {
        const { data } = yield call(apptClass.appointmentGetQuickDates, payload);

        const dates = data.map((date: { start: string; end: string }) => moment(date.start).format("YYYY-MM-DD"));

        // Just to make sure we don't have duplicates
        const quickDates = [...new Set(dates)];

        yield put({
            type: types.APPOINTMENT_GET_QUICK_DATES_SUCCESS,
            payload: quickDates,
        });
    } catch (error) {
        yield put({
            type: types.APPOINTMENT_GET_QUICK_DATES_FAILURE,
            payload: error,
        });
    }
}

function* appointmentGetAvailableDates({ payload }: AppointmentActions["appointmentGetAvailableDates"]) {
    try {
        const { data } = yield call(apptClass.appointmentGetSlotTimes, payload);
        const browserTimezone = getBrowserTimezone();

        const dates = data.map((date: { start: string }) => moment.tz(date.start, browserTimezone).format("YYYY-MM-DD"));

        const availableDates = [...new Set(dates)];

        yield put({
            type: types.APPOINTMENT_GET_AVAILABLE_DATES_SUCCESS,
            payload: availableDates,
        });
    } catch (error) {
        yield put({
            type: types.APPOINTMENT_GET_AVAILABLE_DATES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Get employee other available dates
 */
function* appointmentGetSlotTimes({ payload }: AppointmentActions["appointmentGetSlotTimes"]) {
    try {
        const { data } = yield call(apptClass.appointmentGetSlotTimes, payload);

        const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
            org: OrgReducer.org,
            user: AuthReducer.user,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, user }: MapStateToProps = yield select(mapStateToProps);

        // @ts-ignore
        const timezone = user.timezone ? user.timezone : org?.timeZone ? org?.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

        // convert utc time to org timezone
        const slotsTimeZone = [...data].map((item: DateSlot) => {
            const slotItem = { ...item };
            slotItem.start = utcToTimezone(slotItem.start, timezone);
            slotItem.end = utcToTimezone(slotItem.end, timezone);
            return slotItem;
        });

        // remove slots of past dates
        // const activeSlots = slotsTimeZone.filter((item: DateSlot) => {
        //     const slotStartTime = moment(item.start).valueOf();
        //     const timezoneTime = momentTz().utc().tz(timezone).valueOf();
        //     return slotStartTime >= timezoneTime;
        // });

        yield put({
            type: types.APPOINTMENT_GET_SLOTS_SUCCESS,
            payload: slotsTimeZone,
        });
    } catch (error) {
        yield put({
            type: types.APPOINTMENT_GET_SLOTS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Bulk update appointments
 */
function* appointmentBulkUpdateAppointment({ payload }: AppointmentActions["appointmentBulkUpdateAppointment"]) {
    try {
        const { data } = yield call(apptClass.appointmentBulkUpdateAppointment, payload);
        yield put({
            type: types.APPOINTMENT_BULK_UPDATE_APPOINTMENT_SUCCESS,
            payload: data,
        });
        toastHelper.toastStartContent("success", "Appointments updated successfully");
    } catch (error) {
        yield put({
            type: types.APPOINTMENT_BULK_UPDATE_APPOINTMENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Create status
 */
// function* appointmentCreateStatus({ payload }: AppointmentActions["appointmentCreateStatus"]) {
//     try {
//         if (!payload.name) {
//             throw new ValidationError({ message: "You must insert a status name." });
//         } else if (payload.name.length > 30) {
//             throw new ValidationError({ message: "Status name should have maximum of 30 characters." });
//         } else if (payload.name.length < 3) {
//             throw new ValidationError({ message: "Status name should have minimum of 3 characters." });
//         }

//         const { data }: { data: Pick<Statuses, "color" | "id" | "name"> } = yield call(apptClass.appointmentCreateStatus, payload);
//         const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
//             kanbanAppointments: AppointmentReducer.kanbanAppointments,
//             statuses: AppointmentReducer.statuses,
//         });
//         type mapState = ReturnType<typeof mapStateToProps>;
//         let { statuses, kanbanAppointments }: mapState = yield select(mapStateToProps);

//         statuses.items = [...statuses.items, { ...data }];

//         yield put({
//             type: types.APPOINTMENT_CREATE_STATUS_SUCCESS,
//             payload: {
//                 kanbanAppointments,
//                 statuses,
//             },
//         });
//     } catch (error) {
//         if (error instanceof ValidationError) {
//             toastHelper.toastStartContent("danger", error.message);
//         }

//         yield put({
//             type: types.APPOINTMENT_CREATE_STATUS_FAILURE,
//             payload: error,
//         });
//     }
// }

/**
 * @description Update status column
 */
function* appointmentUpdateStatus({ payload }: AppointmentActions["appointmentUpdateStatus"]): Generator<TakeEffect | PutEffect | ActionChannelEffect | CallEffect | SelectEffect, void, any> {
    try {
        const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
            personalCalendarAppointments: AppointmentReducer.personalCalendarAppointments,
            appointment: AppointmentReducer.appointment,
            appointments: AppointmentReducer.appointments,
            kanbanAppointments: AppointmentReducer.kanbanAppointments,
            teamEvents: AppointmentReducer.teamEvents,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;
        const { kanbanAppointments, personalCalendarAppointments, teamEvents, appointment, appointments }: MapStateToProps = yield select(mapStateToProps);

        //pre update kanban column
        if (payload.kanban) {
            const clonedKanbanAppointments = structuredClone(kanbanAppointments);

            const sourceStatus = payload.kanban.source.droppableId;
            const sourceIndex = payload.kanban.source.index;

            const destinationStatus = payload.kanban.destination.droppableId as Appointment["status"];
            const destinationIndex = payload.kanban.destination.index;

            const kanbanUpdatedAppointment: Appointment = {
                ...payload.kanban.appointment,
                status: destinationStatus,
            };

            // Remove the item from the source status
            clonedKanbanAppointments?.statuses?.[sourceStatus]?.items?.splice(sourceIndex, 1);
            // @ts-ignore - Splice take a third parameter
            // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
            // Insert it on the destination status
            clonedKanbanAppointments?.statuses?.[destinationStatus]?.items.splice(destinationIndex, 0, kanbanUpdatedAppointment);

            // pre update kanban view.
            yield put({ type: types.APPOINTMENT_UPDATE_STATUS_KANBAN_VIEW, payload: clonedKanbanAppointments });
        }

        const result: { data: Appointment[] } = yield call(apptClass.appointmentUpdateStatus, payload);

        const data = result.data[0];

        const updatedAppointment = {
            ...appointment,
            ...data,
            status: payload.moveTo,
        };

        const persoIndexApt = personalCalendarAppointments.list.findIndex((a) => a.id === data.id);
        // @ts-ignore - Splice take a third parameter
        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
        personalCalendarAppointments.list.splice(persoIndexApt, 1, updatedAppointment);

        const teamEventsIndex = teamEvents.items.findIndex((a) => a.id === data.id);
        // @ts-ignore - Splice take a third parameter
        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
        teamEvents.items.splice(teamEventsIndex, 1, updatedAppointment);

        const appointmentsIndex = appointments.findIndex((a) => a.id === data.id);
        // @ts-ignore - Splice take a third parameter
        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
        appointments.splice(appointmentsIndex, 1, updatedAppointment);

        const updatedStatus = {
            appointment: updatedAppointment,
            appointments,
            teamEvents,
            personalCalendarAppointments,
        };

        yield put({ type: types.APPOINTMENT_UPDATE_STATUS_SUCCESS, payload: updatedStatus });
    } catch (error) {
        yield put({ type: types.APPOINTMENT_UPDATE_STATUS_FAILURE });

        if (payload.kanban) {
            toastHelper.toastStartContent("danger", "Failed to update appointment status, try again.");
        }
    }
}

// /**
//  * @description if user moves a cell very fast we will not call every action at once
//  * we are storing it and calling after the previous one is done.
//  * @effect custom effect.
//  */
// function* watchEffect(type: string, saga: (action: { type: string; payload: any }) => any): Generator<ActionChannelEffect | TakeEffect, void, any> {
//     const requestChan = yield actionChannel(type);
//     while (true) {
//         const action = yield take(requestChan);
//         yield saga(action);
//     }
// }

// /**
//  * @description Arrange appointments statuses
//  */
// function* appointmentArrangeStatuses({ payload }: AppointmentActions["appointmentArrangeStatuses"]) {
//     try {
//         const { data } = yield call(apptClass.appointmentArrangeStatuses, payload);

//         yield put({
//             type: types.APPOINTMENT_ARRANGE_STATUSES_SUCCESS,
//             payload: data,
//         });
//     } catch (error) {
//         const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
//             statuses: AppointmentReducer.statuses,
//         });

//         type MapStateToProps = ReturnType<typeof mapStateToProps>;

//         let { statuses }: MapStateToProps = yield select(mapStateToProps);

//         yield put({
//             type: types.APPOINTMENT_ARRANGE_STATUSES_FAILURE,
//             payload: statuses.rearranging?.previousItems || statuses.items,
//         });
//     }
// }

/**
 * @description Get appointment statuses
 */
// function* appointmentGetAppointmentStatuses() {
//     try {
//         const { data } = yield call(apptClass.appointmentGetAppointmentStatuses);
//         yield put({
//             type: types.APPOINTMENT_GET_APPOINTMENT_STATUSES_SUCCESS,
//             payload: data,
//         });
//     } catch (error) {
//         yield put({
//             type: types.APPOINTMENT_GET_APPOINTMENT_STATUSES_FAILURE,
//             payload: error,
//         });
//     }
// }

/**
 * @description Delete status column
 */
// function* appointmentDeleteStatusColumn({ payload }: AppointmentActions["appointmentDeleteStatusColumn"]) {
//     try {
//         yield call(apptClass.appointmentDeleteStatusColumn, payload);
//         let { kanbanAppointments, statuses }: AppointmentState = yield select(({ AppointmentReducer }: RootState) => ({
//             kanbanAppointments: AppointmentReducer.kanbanAppointments,
//             statuses: AppointmentReducer.statuses,
//         }));

//         statuses.items = statuses.items.filter((item) => item.id !== payload.statusId);

//         yield put({
//             type: types.APPOINTMENT_DELETE_STATUS_SUCCESS,
//             payload: {
//                 kanbanAppointments,
//                 statuses,
//             },
//         });
//         popupHelper.popupEnd();
//     } catch (error) {
//         yield put({
//             type: types.APPOINTMENT_DELETE_STATUS_FAILURE,
//             payload: error,
//         });
//     }
// }

function* appointmentListNotes({ payload }: AppointmentActions["appointmentListNotes"]) {
    try {
        const { data } = yield call(appointmentClass.appointmentListNotes, payload);

        yield put({ type: types.APPOINTMENT_LIST_NOTE_SUCCESS, payload: data });
    } catch (error) {
        console.error(error);
        yield put({ type: types.APPOINTMENT_LIST_NOTE_FAILURE, payload: error });
    }
}

/**
 * @description create appointment note.
 */
function* appointmentCreateNote({ payload }: AppointmentActions["appointmentCreateNote"]): Generator<SelectEffect | PutEffect | CallEffect, void, any> {
    try {
        const { data } = yield call(apptClass.appointmentCreateNote, payload);

        const { appointmentNotes }: RootState["AppointmentReducer"] = yield select(({ AppointmentReducer }: RootState) => ({
            appointmentNotes: AppointmentReducer.appointmentNotes,
        }));

        appointmentNotes.unshift(data);

        yield put({
            type: types.APPOINTMENT_CREATE_NOTE_SUCCESS,
            payload: appointmentNotes,
        });
    } catch (error: any) {
        console.error(error);
        yield put({
            type: types.APPOINTMENT_CREATE_NOTE_FAILURE,
            payload: [],
        });
    }
}

/**
 * @description cancel appointment.
 */
function* appointmentCancelAppointment({ payload }: AppointmentActions["appointmentDeleteAppointment"]) {
    try {
        yield call(apptClass.appointmentDeleteAppointment, payload);
        const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
            kanbanAppointments: AppointmentReducer.kanbanAppointments,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;
        let { kanbanAppointments }: MapStateToProps = yield select(mapStateToProps);

        yield put({
            type: types.APPOINTMENT_DELETE_APPOINTMENT_SUCCESS,
            payload: kanbanAppointments,
        });

        yield refreshAppointmentView(payload.employeeId);
        toastHelper.toastStartContent("success", "Appointment deleted sucessfully.");
        popupHelper.popupEnd();
    } catch (error: any) {
        yield put({
            type: types.APPOINTMENT_DELETE_APPOINTMENT_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", "Appointment delete failed.");
    }
}

/**
 * @description Update status ane, appointment.
 */
// function* appointmentUpdateStatusName({ payload }: AppointmentActions["appointmentUpdateStatusName"]): Generator<PutEffect | CallEffect, void, any> {
//     try {
//         if (!payload.name) {
//             throw new ValidationError({ message: "You must insert a status name." });
//         } else if (payload.name.length > 30) {
//             throw new ValidationError({ message: "Status name should have maximum of 30 characters." });
//         } else if (payload.name.length < 3) {
//             throw new ValidationError({ message: "Status name should have minimum of 3 characters." });
//         }

//         yield call(apptClass.appointmentUpdateStatusName, payload);
//         yield put({ type: types.APPOINTMENT_UPDATE_STATUS_NAME_SUCCESS });
//     } catch (error: any) {
//         if (error instanceof ValidationError) {
//             toastHelper.toastStartContent("danger", error.message);
//         }

//         yield put({
//             type: types.APPOINTMENT_UPDATE_STATUS_NAME_FAILURE,
//             payload: error,
//         });
//     }
// }

/**
 * @description get appointments by status.
 */
function* appointmentGetAppointmentsByStatus({ payload }: AppointmentActions["appointmentGetAppointmentsByStatus"]) {
    try {
        const { data }: { data: Appointment[] } = yield call(apptClass.appointmentGetAppointmentsByStatus, payload);

        yield put({
            type: types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_SUCCESS,
            payload: {
                statusId: payload.statusId,
                data,
            },
        });
    } catch (error: any) {
        yield put({
            type: types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Bulk Archive appointments.
 */
function* appointmentBulkArchiveAppointments({ payload }: AppointmentActions["appointmentArchiveAppointments"]): Generator<PutEffect | CallEffect, void, any> {
    try {
        yield call(apptClass.appointmentArchiveAppointments, payload);
        yield put({ type: types.APPOINTMENT_ARCHIVE_APPOINTMENTS_SUCCESS });
        toastHelper.toastStartContent("success", "Appointments archived successfully.");

        if (window.location.pathname.match(/\/calendar\/appointments/)) {
            const query = new URLSearchParams(history.location.search);
            const start = query.get("start");
            const end = query.get("end");
            const userId = query.get("userId") || undefined;
            const entityId = query.get("entityId") || undefined;
            const includeArchived = query.get("includeArchived") === "true" || undefined;

            if (!start || !end) return;

            const params = {
                range: {
                    startDate: moment(start).utc().format(),
                    endDate: moment(end).utc().format(),
                },
            };

            // Refetch only the Kanban column where the Bulk Archive was performed
            yield put({
                type: types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST,
                payload: {
                    ...params,
                    statusId: payload.statusId,
                    userId,
                    entityId,
                    includeArchived,
                },
            });
        }
    } catch (error: any) {
        yield put({
            type: types.APPOINTMENT_ARCHIVE_APPOINTMENTS_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", "Appointments archive failed.");
    }
}

/**
 * @description archive single appointment.
 */
function* appointmentArchiveAppointment({ payload }: AppointmentActions["appointmentArchiveAppointment"]): Generator<PutEffect | SelectEffect | CallEffect, void, any> {
    try {
        const { data }: { data: Appointment[] } = yield call(apptClass.appointmentArchiveAppointment, payload);

        // We always get One appointment because we still use the bulk but for 1 appointment.
        const archivedAppointment = data[0];

        const mapStateToProps = ({ AppointmentReducer }: RootState) => ({
            appointments: AppointmentReducer.appointments,
            appointment: AppointmentReducer.appointment,
        });
        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        const { appointment, appointments }: MapStateToProps = yield select(mapStateToProps);

        // Change Kanban appointment state
        if (window.location.pathname.match(/\/calendar\/appointments/)) {
            const query = new URLSearchParams(history.location.search);
            const start = query.get("start");
            const end = query.get("end");
            const userId = query.get("userId") || undefined;
            const entityId = query.get("entityId") || undefined;
            const includeArchived = query.get("includeArchived") === "true" || undefined;

            if (!start || !end) return;

            const params = {
                range: {
                    startDate: moment(start).utc().format(),
                    endDate: moment(end).utc().format(),
                },
            };

            // Refetch only the Kanban column where the Bulk Archive was performed
            yield put({
                type: types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST,
                payload: {
                    ...params,
                    statusId: archivedAppointment.status,
                    userId,
                    entityId,
                    includeArchived,
                },
            });
        }

        // If we are in the appointment details popup we need to change its state
        if (appointment?.id === archivedAppointment.id) {
            appointment.isArchived = archivedAppointment.isArchived;
        }

        // If we are in the calendar view we need to change its state
        const appointmentIndex = appointments.findIndex((a) => a.id === archivedAppointment.id);
        if (appointments[appointmentIndex]) {
            appointments[appointmentIndex].isArchived = archivedAppointment.isArchived;
        }

        const newPayload: Pick<AppointmentState, "appointment" | "appointments"> = {
            appointment,
            appointments,
        };

        yield put({ type: types.APPOINTMENT_ARCHIVE_APPOINTMENT_SUCCESS, payload: newPayload });

        toastHelper.toastStartContent("success", "Appointment archived successfully.");

        popupHelper.popupEnd();
    } catch (error: any) {
        yield put({
            type: types.APPOINTMENT_ARCHIVE_APPOINTMENT_FAILURE,
            payload: error,
        });
        console.log(error);

        toastHelper.toastStartContent("danger", "Appointment archive failed.");
    }
}

/**
 * @description get employees appointments
 */
function* appointmentGetTeamEvents({ payload }: AppointmentActions["appointmentGetTeamEvents"]) {
    try {
        const { data } = yield call(apptClass.appointmentGetTeamEvents, payload);

        const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
            org: OrgReducer.org,
            user: AuthReducer.user,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;
        let { org, user }: MapStateToProps = yield select(mapStateToProps);

        // convert utc time to org timezone
        // @ts-ignore
        const timezone = user.timezone ? user.timezone : org?.timeZone ? org?.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
        const utcAppointmentData = data.map((apt: Appointment) => {
            const aptItem = { ...apt };
            aptItem.start = utcToTimezone(aptItem.start, timezone);
            aptItem.end = utcToTimezone(aptItem.end, timezone);
            return aptItem;
        });
        yield put({
            type: types.APPOINTMENT_GET_TEAM_EVENTS_SUCCESS,
            payload: utcAppointmentData,
        });
    } catch (error) {
        yield put({
            type: types.APPOINTMENT_GET_TEAM_EVENTS_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description create personal event.
 */
function* appointmentCreatepersonalEvent({ payload }: AppointmentActions["appointmentCreatepersonalEvent"]) {
    try {
        yield appointmentValidation.appointmentCreatepersonalEvent(payload);

        const { data } = yield call(apptClass.appointmentCreatepersonalEvent, payload);

        yield put({
            type: types.APPOINTMENT_CREATE_PERSONAL_EVENT_SUCCESS,
            payload: data,
        });

        toastHelper.toastStartContent("success", "Event created successfully.");
        popupHelper.popupEnd();

        yield refreshAppointmentView(payload.userId);
    } catch (error) {
        let errorV2 = error as ErrorResponseV2;
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        } else if (errorV2.error.errors) {
            errorV2.error.errors.forEach((errorItem) => {
                toastHelper.toastStartContent("danger", errorItem.message);
            });
        }
        yield put({
            type: types.APPOINTMENT_CREATE_PERSONAL_EVENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description update personal event.
 */
function* appointmentUpdateEvent({ payload }: AppointmentActions["appointmentUpdateEvent"]) {
    try {
        yield appointmentValidation.appointmentCreatepersonalEvent(payload.event);

        yield call(apptClass.appointmentUpdateEvent, payload);

        yield put({
            type: types.APPOINTMENT_UPDATE_EVENT_SUCCESS,
        });

        toastHelper.toastStartContent("success", "Event updated successfully.");
        popupHelper.popupEnd();

        yield refreshAppointmentView(payload.event.userId);
    } catch (error) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        }
        yield put({
            type: types.APPOINTMENT_UPDATE_EVENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description delete personal event.
 */
function* appointmentDeleteEvent({ payload }: AppointmentActions["appointmentDeleteEvent"]) {
    try {
        yield call(apptClass.appointmentDeleteEvent, payload);

        yield put({
            type: types.APPOINTMENT_DELETE_EVENT_SUCCESS,
        });

        toastHelper.toastStartContent("success", "Event deleted successfully.");
        popupHelper.popupEnd();

        yield refreshAppointmentView(payload.employeeId);
    } catch (error) {
        yield put({
            type: types.APPOINTMENT_DELETE_EVENT_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description reschedule appointment
 */
function* appointmentRescheduleAppointment({ payload }: AppointmentActions["appointmentRescheduleAppointment"]) {
    try {
        const { data } = yield call(apptClass.appointmentRescheduleAppointment, payload);

        yield put({
            type: types.APPOINTMENT_RESCHEDULE_APPOINTMENT_SUCCESS,
            appointment: data,
        });

        toastHelper.toastStartContent("success", "Appointment rescheduled successfully.");
        popupHelper.popupEnd();

        yield refreshAppointmentView(payload.appointment.userId);
    } catch (error) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        }
        yield put({
            type: types.APPOINTMENT_RESCHEDULE_APPOINTMENT_FAILURE,
            payload: error,
        });
    }
}

//// APPOINTMENT END ////

export default function* AppointmentSaga() {
    /** List or Getting Events */
    yield takeLatest(types.APPOINTMENT_GET_APPOINTMENT_REQUEST, appointmentGetAppointment);
    yield takeLatest(types.APPOINTMENT_GET_TEAM_EVENTS_REQUEST, appointmentGetTeamEvents);
    yield takeEvery(types.APPOINTMENT_LIST_BY_ENTITY_ID_REQUEST, appointmentListByEntityId);
    yield takeEvery(types.APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST, appointmentGetAppointmentsByStatus);
    
    /** Creating events */
    yield takeLatest(types.APPOINTMENT_CREATE_APPOINTMENT_REQUEST, appointmentCreateAppointment);
    yield takeLatest(types.APPOINTMENT_CREATE_PERSONAL_EVENT_REQUEST, appointmentCreatepersonalEvent);
    yield takeLeading(types.APPOINTMENT_RESCHEDULE_APPOINTMENT_REQUEST, appointmentRescheduleAppointment);
    
    /** Managing Events */
    yield takeLeading(types.APPOINTMENT_UPDATE_EVENT_REQUEST, appointmentUpdateEvent);
    yield takeLatest(types.APPOINTMENT_UPDATE_STATUS_REQUEST, appointmentUpdateStatus);
    yield takeLatest(types.APPOINTMENT_BULK_UPDATE_APPOINTMENT_REQUEST, appointmentBulkUpdateAppointment);
   
    /** Archiving Events or type appointments */
    yield takeLatest(types.APPOINTMENT_ARCHIVE_APPOINTMENTS_REQUEST, appointmentBulkArchiveAppointments);
    yield takeLatest(types.APPOINTMENT_ARCHIVE_APPOINTMENT_REQUEST, appointmentArchiveAppointment);
    
    /** Cancelling or Deleting events */
    yield takeLatest(types.APPOINTMENT_DELETE_APPOINTMENT_REQUEST, appointmentCancelAppointment);
    yield takeLatest(types.APPOINTMENT_DELETE_EVENT_REQUEST, appointmentDeleteEvent);
    
    /** Get availabilities */
    yield takeEvery(types.APPOINTMENT_GET_SLOTS_REQUEST, appointmentGetSlotTimes);
    yield takeEvery(types.APPOINTMENT_GET_QUICK_DATES_REQUEST, appointmentGetQuickDates);
    yield takeLatest(types.APPOINTMENT_GET_AVAILABLE_DATES_REQUEST, appointmentGetAvailableDates);
    
    /** APPOINTMENT NOTES MANAGEMENT */
    yield takeLatest(types.APPOINTMENT_LIST_NOTE_REQUEST, appointmentListNotes);
    yield takeLatest(types.APPOINTMENT_CREATE_NOTE_REQUEST, appointmentCreateNote);
    yield takeLatest(types.APPOINTMENT_UPDATE_NOTE_REQUEST, appointmentCreateNote);

    /** Custom Status management (Removed for now) */
    // yield takeLatest(types.APPOINTMENT_CREATE_STATUS_REQUEST, appointmentCreateStatus);
    // yield takeLatest(types.APPOINTMENT_GET_APPOINTMENT_STATUSES_REQUEST, appointmentGetAppointmentStatuses);
    // yield takeLatest(types.APPOINTMENT_DELETE_STATUS_REQUEST, appointmentDeleteStatusColumn);
    // yield takeLatest(types.APPOINTMENT_UPDATE_STATUS_NAME_REQUEST, appointmentUpdateStatusName);
}

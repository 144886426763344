import { call, put, delay } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import { EmailActions } from "../../reducer/actions.types";

/**
 * @description search entity
 */
export function* emailSearchEntityContacts({ payload }: EmailActions["emailSearchEntityContacts"]) {
    try {
        // this delay is important while the user is typing.
        yield delay(1500);
        const { data } = yield call(emailClass.emailSearchEntityContacts, payload);

        if (!data.length) {
            yield put({ type: emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_FAILURE });
        } else {
            yield put({
                type: emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_SUCCESS,
                payload: data,
            });
        }
    } catch (error: any) {
        yield put({ type: emailTypes.EMAIL_SEARCH_ENTITY_CONTACTS_FAILURE, payload: error });
    }
}

import { InputSwitch } from "@soltivo/draw-a-line";
import styles from "./grid.time.module.scss";
import InputTimeV2 from "../../components/input.time/input.time";

interface GridTimeProps {
    businessHours: { start: string; end: string; day: "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" }[];
    enabledWeekdays: ("Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday")[];
    onEnableWeekday: (checked: boolean, weekday: GridTimeProps["enabledWeekdays"][0]) => void;
    onChangeTime: (e: { value: string; name: "start" | "end"; weekday: GridTimeProps["enabledWeekdays"][0] }) => void;
}

const GridTime = ({ businessHours, enabledWeekdays, onEnableWeekday, onChangeTime: onChangeStart }: GridTimeProps) => {
    return (
        <div className={styles.grid__time}>
            <div>
                {businessHours?.map((weekday, index) => {
                    if (index > 3) return null;
                    return (
                        <div className={styles.input__wrapper} key={index}>
                            <div className={styles.check__wrapper}>
                                <span>{weekday.day}</span>
                                <InputSwitch onChange={(e) => onEnableWeekday(e.target.checked, weekday.day)} checked={enabledWeekdays.includes(weekday.day)} />
                            </div>

                            {enabledWeekdays.includes(weekday.day) ? (
                                <div className={styles.time}>
                                    <div className={styles.time__input}>
                                        <InputTimeV2
                                            name={"start"}
                                            value={weekday.start}
                                            placeholder="Open at"
                                            onChange={(e) => {
                                                onChangeStart({ ...e, name: "start", weekday: weekday.day });
                                            }}
                                        />
                                    </div>

                                    <div className={styles.time__input}>
                                        <InputTimeV2
                                            name="end"
                                            value={weekday.end}
                                            placeholder="Close at"
                                            onChange={(e) => {
                                                onChangeStart({ ...e, name: "end", weekday: weekday.day });
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
            <div>
                {businessHours?.map((weekday, index) => {
                    if (index <= 3) return null;
                    return (
                        <div className={styles.input__wrapper} key={index}>
                            <div className={styles.check__wrapper}>
                                <span>{weekday.day}</span>
                                <InputSwitch onChange={(e) => onEnableWeekday(e.target.checked, weekday.day)} checked={enabledWeekdays.includes(weekday.day)} />
                            </div>

                            {enabledWeekdays.includes(weekday.day) ? (
                                <div className={styles.time}>
                                    <div className={styles.time__input}>
                                        <InputTimeV2
                                            name={"start"}
                                            value={weekday.start}
                                            placeholder="Open at"
                                            onChange={(e) => {
                                                onChangeStart({ ...e, name: "start", weekday: weekday.day });
                                            }}
                                        />
                                    </div>

                                    <div className={styles.time__input}>
                                        <InputTimeV2
                                            name="end"
                                            value={weekday.end}
                                            placeholder="Close at"
                                            onChange={(e) => {
                                                onChangeStart({ ...e, name: "end", weekday: weekday.day });
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GridTime;

import { call, put, select } from "redux-saga/effects";
import { emailTypes } from "../../reducer/types";
import emailClass from "../email.class";
import { EmailActions } from "../../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";

/**
 * @description get emails and its conversations.
 */
export function* emailGetEmail({ payload }: EmailActions["emailGetEmail"]) {
    try {
        const { data } = yield call(emailClass.emailGetEmail, payload);

        const mapStateToProps = ({ EmailReducer }: RootState) => ({
            email: EmailReducer.email,
        });
        type MapStateToProps = ReturnType<typeof mapStateToProps>;
        let { email }: MapStateToProps = yield select(mapStateToProps);

        // set new key
        email.lastKey = data.lastKey;

        //with last key (pagination)
        if (payload.lastKey) {
            email.items = [...email.items, ...data.items];
        } else {
            email.items = data.items;
        }

        yield put({
            type: emailTypes.EMAIL_GET_EMAIL_SUCCESS,
            payload: data,
        });
    } catch (error: any) {
        yield put({ type: emailTypes.EMAIL_GET_EMAIL_FAILURE, payload: error });
    }
}

export const types = {
    HRM_CHANGE_STATE_REQUEST: "HRM_CHANGE_STATE_REQUEST",

    ////
    HRM_REQUEST_DONE: "HRM_REQUEST_DONE",
    HRM_REQUEST_PENDENT: "HRM_REQUEST_PENDENT",

    RENDER_LOADER: "RENDER_LOADER",
    REMOVE_LOADER: "REMOVE_LOADER",

    CHANGE_DATE: "CHANGE_DATE",
    ///

    HRM_CREATE_EMPLOYEE_REQUEST: "HRM_CREATE_EMPLOYEE_REQUEST",
    HRM_CREATE_EMPLOYEE_SUCCESS: "HRM_CREATE_EMPLOYEE_SUCCESS",
    HRM_CREATE_EMPLOYEE_FAILURE: "HRM_CREATE_EMPLOYEE_FAILURE",

    HRM_GET_ALL_EMPLOYEES_REQUEST: "HRM_GET_ALL_EMPLOYEES_REQUEST",
    HRM_GET_ALL_EMPLOYEES_SUCCESS: "HRM_GET_ALL_EMPLOYEES_SUCCESS",
    HRM_GET_ALL_EMPLOYEES_FAILURE: "HRM_GET_ALL_EMPLOYEES_FAILURE",

    HRM_GET_EMPLOYEE_BY_ID_REQUEST: "HRM_GET_EMPLOYEE_BY_ID_REQUEST",
    HRM_GET_EMPLOYEE_BY_ID_SUCCESS: "HRM_GET_EMPLOYEE_BY_ID_SUCCESS",
    HRM_GET_EMPLOYEE_BY_ID_FAILURE: "HRM_GET_EMPLOYEE_BY_ID_FAILURE",

    HRM_DELETE_EMPLOYEE_REQUEST: "HRM_DELETE_EMPLOYEE_REQUEST",
    HRM_DELETE_EMPLOYEE_SUCCESS: "HRM_DELETE_EMPLOYEE_SUCCESS",
    HRM_DELETE_EMPLOYEE_FAILURE: "HRM_DELETE_EMPLOYEE_FAILURE",

    HRM_RESET_EMPLOYEE_PASSWORD_REQUEST: "HRM_RESET_EMPLOYEE_PASSWORD_REQUEST",
    HRM_RESET_EMPLOYEE_PASSWORD_SUCCESS: "HRM_RESET_EMPLOYEE_PASSWORD_SUCCESS",
    HRM_RESET_EMPLOYEE_PASSWORD_FAILURE: "HRM_RESET_EMPLOYEE_PASSWORD_FAILURE",

    HRM_UPDATE_EMPLOYEE_REQUEST: "HRM_UPDATE_EMPLOYEE_REQUEST",
    HRM_UPDATE_EMPLOYEE_SUCCESS: "HRM_UPDATE_EMPLOYEE_SUCCESS",
    HRM_UPDATE_EMPLOYEE_FAILURE: "HRM_UPDATE_EMPLOYEE_FAILURE",

    HRM_GET_EMPLOYEE_ACTIVITIES_REQUEST: "HRM_GET_EMPLOYEE_ACTIVITIES_REQUEST",
    HRM_GET_EMPLOYEE_ACTIVITIES_SUCCESS: "HRM_GET_EMPLOYEE_ACTIVITIES_SUCCESS",
    HRM_GET_EMPLOYEE_ACTIVITIES_FAILURE: "HRM_GET_EMPLOYEE_ACTIVITIES_FAILURE",

    /////////////////////////////////////////////////////////////////////////////////////////

    HRM_CREATE_ROLE_REQUEST: "HRM_CREATE_ROLE_REQUEST",
    HRM_CREATE_ROLE_SUCCESS: "HRM_CREATE_ROLE_SUCCESS",
    HRM_CREATE_ROLE_FAILURE: "HRM_CREATE_ROLE_FAILURE",

    HRM_GET_ALL_ROLES_REQUEST: "HRM_GET_ALL_ROLES_REQUEST",
    HRM_GET_ALL_ROLES_SUCCESS: "HRM_GET_ALL_ROLES_SUCCESS",
    HRM_GET_ALL_ROLES_FAILURE: "HRM_GET_ALL_ROLES_FAILURE",

    HRM_GET_ROLE_BY_ID_REQUEST: "HRM_GET_ROLE_BY_ID_REQUEST",
    HRM_GET_ROLE_BY_ID_SUCCESS: "HRM_GET_ROLE_BY_ID_SUCCESS",
    HRM_GET_ROLE_BY_ID_FAILURE: "HRM_GET_ROLE_BY_ID_FAILURE",

    HRM_DELETE_ROLE_REQUEST: "HRM_DELETE_ROLE_REQUEST",
    HRM_DELETE_ROLE_SUCCESS: "HRM_DELETE_ROLE_SUCCESS",
    HRM_DELETE_ROLE_FAILURE: "HRM_DELETE_ROLE_FAILURE",

    HRM_UPDATE_ROLE_REQUEST: "HRM_UPDATE_ROLE_REQUEST",
    HRM_UPDATE_ROLE_SUCCESS: "HRM_UPDATE_ROLE_SUCCESS",
    HRM_UPDATE_ROLE_FAILURE: "HRM_UPDATE_ROLE_FAILURE",

    HRM_RESET_PERMISSIONS_REQUEST: "HRM_RESET_PERMISSIONS_REQUEST",
    HRM_RESET_PERMISSIONS_SUCCESS: "HRM_RESET_PERMISSIONS_SUCCESS",
    HRM_RESET_PERMISSIONS_FAILURE: "HRM_RESET_PERMISSIONS_FAILURE",

    HRM_VERIFY_PERMISSIONS_REQUEST: "HRM_VERIFY_PERMISSIONS_REQUEST",
    HRM_VERIFY_PERMISSIONS_SUCCESS: "HRM_VERIFY_PERMISSIONS_SUCCESS",
    HRM_VERIFY_PERMISSIONS_FAILURE: "HRM_VERIFY_PERMISSIONS_FAILURE",
    
    HRM_UPDATE_EMPLOYEE_ROLE_REQUEST: "HRM_UPDATE_EMPLOYEE_ROLE_REQUEST",
    HRM_UPDATE_EMPLOYEE_ROLE_SUCCESS: "HRM_UPDATE_EMPLOYEE_ROLE_SUCCESS",
    HRM_UPDATE_EMPLOYEE_ROLE_FAILURE: "HRM_UPDATE_EMPLOYEE_ROLE_FAILURE",

    HRM_VALIDATE_ROLES_REQUEST: "HRM_VALIDATE_ROLES_REQUEST",
    HRM_VALIDATE_ROLES_SUCCESS: "HRM_VALIDATE_ROLES_SUCCESS",
    HRM_VALIDATE_ROLES_FAILURE: "HRM_VALIDATE_ROLES_FAILURE",

    /////////////////////////////////////////////////////////////////

    HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST: "HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST",
    HRM_GET_ALL_EMPLOYEE_SCHEDULES_SUCCESS: "HRM_GET_ALL_EMPLOYEE_SCHEDULES_SUCCESS",
    HRM_GET_ALL_EMPLOYEE_SCHEDULES_FAILURE: "HRM_GET_ALL_EMPLOYEE_SCHEDULES_FAILURE",

    HRM_CREATE_SCHEDULE_REQUEST: "HRM_CREATE_SCHEDULE_REQUEST",
    HRM_CREATE_SCHEDULE_SUCCESS: "HRM_CREATE_SCHEDULE_SUCCESS",
    HRM_CREATE_SCHEDULE_FAILURE: "HRM_CREATE_SCHEDULE_FAILURE",

    HRM_UPDATE_SCHEDULE_REQUEST: "HRM_UPDATE_SCHEDULE_REQUEST",
    HRM_UPDATE_SCHEDULE_SUCCESS: "HRM_UPDATE_SCHEDULE_SUCCESS",
    HRM_UPDATE_SCHEDULE_FAILURE: "HRM_UPDATE_SCHEDULE_FAILURE",

    HRM_UPDATE_EMPLOYEE_SCHEDULE_REQUEST: "HRM_UPDATE_EMPLOYEE_SCHEDULE_REQUEST",
    HRM_UPDATE_EMPLOYEE_SCHEDULE_SUCCESS: "HRM_UPDATE_EMPLOYEE_SCHEDULE_SUCCESS",
    HRM_UPDATE_EMPLOYEE_SCHEDULE_FAILURE: "HRM_UPDATE_EMPLOYEE_SCHEDULE_FAILURE",

    HRM_GET_EMPLOYEE_SCHEDULE_REQUEST: "HRM_GET_EMPLOYEE_SCHEDULE_REQUEST",
    HRM_GET_EMPLOYEE_SCHEDULE_SUCCESS: "HRM_GET_EMPLOYEE_SCHEDULE_SUCCESS",
    HRM_GET_EMPLOYEE_SCHEDULE_FAILURE: "HRM_GET_EMPLOYEE_SCHEDULE_FAILURE",

    HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_REQUEST: "HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_REQUEST",
    HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_SUCCESS: "HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_SUCCESS",
    HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_FAILURE: "HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_FAILURE",

    HRM_DELETE_SCHEDULE_REQUEST: "HRM_DELETE_SCHEDULE_REQUEST",
    HRM_DELETE_SCHEDULE_SUCCESS: "HRM_DELETE_SCHEDULE_SUCCESS",
    HRM_DELETE_SCHEDULE_FAILURE: "HRM_DELETE_SCHEDULE_FAILURE",

    HRM_ASSIGN_TEMPLATE_REQUEST: "HRM_ASSIGN_TEMPLATE_REQUEST",
    HRM_ASSIGN_TEMPLATE_SUCCESS: "HRM_ASSIGN_TEMPLATE_SUCCESS",
    HRM_ASSIGN_TEMPLATE_FAILURE: "HRM_ASSIGN_TEMPLATE_FAILURE",

    HRM_GET_ALL_SCHEDULES_TEMPLATE_REQUEST: "HRM_GET_ALL_SCHEDULES_TEMPLATE_REQUEST",
    HRM_GET_ALL_SCHEDULES_TEMPLATE_SUCCESS: "HRM_GET_ALL_SCHEDULES_TEMPLATE_SUCCESS",
    HRM_GET_ALL_SCHEDULES_TEMPLATE_FAILURE: "HRM_GET_ALL_SCHEDULES_TEMPLATE_FAILURE",

    HRM_GET_TEMPLATE_BY_ID_REQUEST: "HRM_GET_TEMPLATE_BY_ID_REQUEST",
    HRM_GET_TEMPLATE_BY_ID_SUCCESS: "HRM_GET_TEMPLATE_BY_ID_SUCCESS",
    HRM_GET_TEMPLATE_BY_ID_FAILURE: "HRM_GET_TEMPLATE_BY_ID_FAILURE",

    HRM_CREATE_TEMPLATE_REQUEST: "HRM_CREATE_TEMPLATE_REQUEST",
    HRM_CREATE_TEMPLATE_SUCCESS: "HRM_CREATE_TEMPLATE_SUCCESS",
    HRM_CREATE_TEMPLATE_FAILURE: "HRM_CREATE_TEMPLATE_FAILURE",

    HRM_UPDATE_TEMPLATE_REQUEST: "HRM_UPDATE_TEMPLATE_REQUEST",
    HRM_UPDATE_TEMPLATE_SUCCESS: "HRM_UPDATE_TEMPLATE_SUCCESS",
    HRM_UPDATE_TEMPLATE_FAILURE: "HRM_UPDATE_TEMPLATE_FAILURE",

    HRM_DELETE_TEMPLATE_REQUEST: "HRM_DELETE_TEMPLATE_REQUEST",
    HRM_DELETE_TEMPLATE_SUCCESS: "HRM_DELETE_TEMPLATE_SUCCESS",
    HRM_DELETE_TEMPLATE_FAILURE: "HRM_DELETE_TEMPLATE_FAILURE",

    HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_REQUEST: "HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_REQUEST",
    HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_SUCCESS: "HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_SUCCESS",
    HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_FAILURE: "HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_FAILURE",

    HRM_GET_ALL_PAYROLLS_REQUEST: "HRM_GET_ALL_PAYROLLS_REQUEST",
    HRM_GET_ALL_PAYROLLS_SUCCESS: "HRM_GET_ALL_PAYROLLS_SUCCESS",
    HRM_GET_ALL_PAYROLLS_FAILURE: "HRM_GET_ALL_PAYROLLS_FAILURE",

    HRM_UPDATE_PAYROLL_REQUEST: "HRM_UPDATE_PAYROLL_REQUEST",
    HRM_UPDATE_PAYROLL_SUCCESS: "HRM_UPDATE_PAYROLL_SUCCESS",
    HRM_UPDATE_PAYROLL_FAILURE: "HRM_UPDATE_PAYROLL_FAILURE",

    HRM_ADD_TEMPLATE_REQUEST: "HRM_ADD_TEMPLATE_REQUEST",

    HRM_RESEND_EMPLOYEE_INVITATION_REQUEST: "HRM_RESEND_EMPLOYEE_INVITATION_REQUEST",
    HRM_RESEND_EMPLOYEE_INVITATION_SUCCESS: "HRM_RESEND_EMPLOYEE_INVITATION_SUCCESS",
    HRM_RESEND_EMPLOYEE_INVITATION_FAILURE: "HRM_RESEND_EMPLOYEE_INVITATION_FAILURE",

    HRM_CHECK_COLLISIONS_REQUEST: "HRM_CHECK_COLLISIONS_REQUEST",
    HRM_CHECK_COLLISIONS_SUCCESS: "HRM_CHECK_COLLISIONS_SUCCESS",
    HRM_CHECK_COLLISIONS_FAILURE: "HRM_CHECK_COLLISIONS_FAILURE",
};

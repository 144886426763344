import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app/app";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Router } from "react-router-dom";
import authConfig from "./helpers/aws/aws-confg";
import { Amplify } from "aws-amplify";
import history from "./app/routes/history";
import "./assets/styles/index.styles.scss";
import Popup from "@soltivo/draw-a-line/core/components/popup/popup";
import ToastContainer from "@soltivo/draw-a-line/core/components/toast/toast.container";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render!
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
Amplify.configure(authConfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

const root = createRoot(document.getElementById("root")!);

root.render(
    <Elements stripe={stripePromise}>
        <Provider store={store}>
            <Router history={history}>
                <App />
                <ToastContainer />
                <Popup />
            </Router>
        </Provider>
    </Elements>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

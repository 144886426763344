import { types } from "./types";
import {updateList, updateInvoicesStatuses} from './utils'

/**
 * @param {array} leads
 * @param {object} loader
 * @param {object} invoice
 * @param {array} invoices
 * @param {boolean} isListView
 */
const INITIAL_STATE = {
    isListView: true,
    invoice: null,
    invoices: [],
    loader: {
        actions: []
    },
    clients: []
}

const AccountingReducer = (state = INITIAL_STATE, action) => {
    const { loader } = state;
    const { payload } = action;
    switch (action.type) {

        case types.CHANGE_VIEW:
            return {
                ...state,
                isListView: !state.isListView,
            };

        case types.START_LOADING:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...new Set([...loader.actions, payload])], 
                }
            };

        case types.STOP_LOADING:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: loader.actions.filter(action => action !== payload),
                }
            };

        case types.CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data,
                invoices: [action.payload.data, ...state.invoices]
            };
            
        case types.UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data,
                invoices: updateList(state.invoices, action.payload.data, "invoiceId")
            };

        case types.GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: updateInvoicesStatuses(action.payload)
            };

        case types.GET_SINGLE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data
            };

        case types.INIT_INVOICE:
            return {
                ...state,
                invoice: null
            };

        case types.SEND_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data,
                invoices: updateList(state.invoices, action.payload.data, "invoiceId")
            };

        case types.DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload
            };

        case types.SEARCH_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.payload
            };

        case types.GET_INVOICE_PDF_SUCCESS:
            return {
                ...state,
            };

        default:
            return state;
    }
}

export default AccountingReducer;
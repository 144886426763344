import { types } from "./types";

/**
 * @param {object} loader
 * @param {Boolean} eventsLoaded
 */
const INITIAL_STATE = {
    loader: {
        actions: []
    },
    eventsLoaded: false
}

const CalendarReducer = (state = INITIAL_STATE, action) => {
    const { loader } = state;
    const { payload } = action;
    
    switch (action.type) {

        case types.START_LOADING:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...new Set([...loader.actions, payload])], 
                }
            };

        case types.STOP_LOADING:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: loader.actions.filter(action => action !== payload),
                }
            };

        case types.LOAD_EVENTS_SUCCESS:
            return {
                ...state,
                eventsLoaded: true
            };

        default:
            return state;
    }
}

export default CalendarReducer;
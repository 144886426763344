import React, { useContext, useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authForgotPasswordSubmit } from "../../redux/auth/reducer/actions";
import { Uneye, Eye } from "@soltivo/draw-a-line/core/components/icons";

import "../../assets/styles/auth.style.scss";
import { Button, InputText } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeFPForm } from "../../context/actions";
import { RootState } from "../../../../redux/reducers";
import { ReactComponent as SteveKey } from "@soltivo/draw-a-line/core/assets/illutrations/SteveKey.svg";

interface ConfirmForgotPasswordProps extends MapStateToProps {
    error: any;
}

const ConfirmForgotPassword: React.FC<ConfirmForgotPasswordProps> = ({ error }) => {
    const [showPassword, setPassword] = useState(false);
    const {
        state: { FPForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);
    const dispatch = useDispatch();
    const [submited, setSubmited] = useState(false);

    /**
     * @description change form input values.
     */
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatchContext(authOnChangeFPForm({ ...form, [e.target.name]: e.target.value }));

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (submited) {
            return;
        }
        dispatch(authForgotPasswordSubmit(form));
        setSubmited(true);
    };

    useEffect(() => {
        if (error) {
            setSubmited(false);
        }
    }, [error]);

    if (!form.email || !form.code || error?.code === "ExpiredCodeException" || error?.code === "InvalidEmailException") {
        return <Redirect to="/forgot-password" />;
    }

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">New Password</h2>

                        {Object.keys(form).map((input, index) => {
                            if (["code", "email"].includes(input)) return null;

                            return (
                                <div key={index} className="auth-container__input-container">
                                    <InputText
                                        name={input}
                                        onChange={onChange}
                                        bottomBorderStyle={true}
                                        value={form[input as ReturnType<() => keyof typeof form>]}
                                        placeholder={`Insert your ${input.replace("_", " ")}`}
                                        type={input.match(/password/) && !showPassword ? "password" : "text"}
                                        autoComplete="off"
                                        required
                                        className={`auth-container__input ${input.match(/password/) ? "password" : ""}`}
                                    />
                                    {/password/g.test(input) ? showPassword ? <Uneye onClick={() => setPassword(!showPassword)} /> : <Eye onClick={() => setPassword(!showPassword)} /> : ""}
                                </div>
                            );
                        })}

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="btn auth-container__button-primary" disabled={submited}>
                            Reset Password
                        </Button>
                    </form>
                </div>
                <div className="column col-12 col-lg-6 img-container">
                    <SteveKey />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ConfirmForgotPassword);

import { FC, useContext, useEffect } from "react";
import { headerStartLoadingApp } from "../../layout/header/reducer/actions";
import { HeaderContext } from "../../layout/header/reducer/header.context";
import "./app.fallback.styles.scss";

interface IApppFallBack {
    start?: boolean;
}

/**
 * @description when this component receives start as a true boolean
 * it will won't display anything, but it will tell the header appFallback
 * to be displayed.
 */
const AppFallback: FC<IApppFallBack> = ({ start }) => {
    const {
        dispatch,
        state: { loadingApp },
    } = useContext(HeaderContext);
    useEffect(() => {
        if (start === true) dispatch(headerStartLoadingApp({ loading: start }));
    }, [start]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (loadingApp) {
            timeout = setTimeout(() => {
                dispatch(headerStartLoadingApp({ loading: false }));
            }, 2000);
        }
        return () => clearTimeout(timeout);
    }, [loadingApp]);
    if (start) return null;
    return (
        <div className="loading__app--fallback">
            <div className="line"></div>
        </div>
    );
};

export default AppFallback;

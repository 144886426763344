import { types } from "./types";
import { Notification, NotificationSettings } from "@soltivo/types";

export interface NotificationsState {
    /**
     * @description list all notifications
     */
    notifications: {
        list: Notification[];
        loading: Boolean;
        status?: "success" | "failed";
    };
    /**
     * @description list all notifications
     */
    updateNotification: {
        loading: Boolean;
        status?: "success" | "failed";
    };
    /**
     * @description get notifications settings
     */
    notificationSettings: {
        settings?: NotificationSettings,
        loading: Boolean;
        status?: "success" | "failed";
    },
    deletingNotification: boolean;
    clearingNotifications: boolean;
}

export const _INITIAL_STATE: NotificationsState = {
    notifications: {
        list: [],
        loading: false,
    },
    updateNotification: {
        loading: false,
    },
    notificationSettings: {
        loading: false,
    },
    deletingNotification: false,
    clearingNotifications: false,
};

const NotificationsReducer = (
    state = _INITIAL_STATE,
    action: {
        type: string;
        payload: any;
    }
): NotificationsState => {
    switch (action.type) {
        case types.NOTIFICATIONS_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                notifications: {
                    list: [],
                    loading: true
                }
            };

        case types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: {
                    list: action.payload,
                    loading: false,
                    status: "success",
                }
            };

        case types.NOTIFICATIONS_GET_ALL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                notifications: {
                    list: [],
                    loading: false,
                    status: "failed",
                }
            };

        case types.NOTIFICATIONS_UPDATE_NOTIFICATION_REQUEST:
            return {
                ...state,
                updateNotification: {
                    loading: true
                }
            };

        case types.NOTIFICATIONS_UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                updateNotification: {
                    loading: false,
                    status: "success",
                },
                notifications: action.payload,
            };

        case types.NOTIFICATIONS_UPDATE_NOTIFICATION_FAILURE:
            return {
                ...state,
                updateNotification: {
                    loading: false,
                    status: "failed",
                }
            };

        case types.NOTIFICATIONS_GET_SETTINGS_REQUEST:
            return {
                ...state,
                notificationSettings: {
                    loading: true
                }
            };

        case types.NOTIFICATIONS_GET_SETTINGS_SUCCESS:
            return {
                ...state,
                notificationSettings: {
                    settings: action.payload,
                    loading: false,
                    status: "success",
                }
            };

        case types.NOTIFICATIONS_GET_SETTINGS_FAILURE:
            return {
                ...state,
                notificationSettings: {
                    loading: false,
                    status: "failed",
                }
            };

        case types.NOTIFICATIONS_DELETE_NOTIFICATION_REQUEST:
            return {
                ...state,
                deletingNotification: true,
            };

        case types.NOTIFICATIONS_DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                deletingNotification: false,
                notifications: action.payload,
            };

        case types.NOTIFICATIONS_DELETE_NOTIFICATION_FAILURE:
            return {
                ...state,
                deletingNotification: false,
            };

        case types.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                clearingNotifications: true,
            };

        case types.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                clearingNotifications: false,
                notifications: action.payload,
            };

        case types.NOTIFICATIONS_CLEAR_ALL_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                clearingNotifications: false,
            };

        default:
            return state;
    }
};

export default NotificationsReducer;

import { FC } from "react";
import { connect } from "react-redux";

interface PopupRescheduleProps {}

const PopupReschedule: FC<PopupRescheduleProps> = () => {
    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
    };

    return (
        <div className="popup__cancel-apt">
            <form onSubmit={onSubmit}>
                <p className="text-15 text-geyser-900">Are you sure that you want to reschedule this appointment ?</p>
            </form>
        </div>
    );
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PopupReschedule);

import { soltivoHelper } from "@soltivo/draw-a-line";
import { useEffect } from "react";

/**
 * @description This hook is listening to builder requesting authentication and sending back localStorage to confirm if the user has logged in.
 */
const useBuilderAuthentication = () => {
    useEffect(() => {
        const onMessage = (event: MessageEvent<any>) => {
            //check if message is from the builder application
            if (event.origin === process.env.REACT_APP_BUILDER_URL) {
                (event.source as Window)?.postMessage(
                    {
                        SOLTIVO_BUILDER: {
                            hasSetOrg: Boolean(soltivoHelper.getCookie("orgId")),
                        },
                    },
                    `${event.origin}`
                );
            }
        };
        window.addEventListener("message", onMessage, true);
        return () => {
            window.removeEventListener("message", onMessage, true);
        };
    }, []);
};

export default useBuilderAuthentication;

import { types } from "./types";
import { TEmployee, TEmployeeActivity, TPayrolls, TRole, TRoles, TTemplate, TSchedulesList, SingleEmployeeSchedules } from "../../hrm";
import { Employee } from "@soltivo/types";

export type HRMReducerState = {
    request: any;
    error: any;
    employees: Employee[];
    loadingEmployees: boolean;
    updatingEmployee: boolean;
    loadingInactiveEmployees: boolean;
    employee: TEmployee | null;
    employeeActivities: TEmployeeActivity[] | null;
    loadingEmployeeActivities: boolean;
    schedules: TSchedulesList;
    loadingSchedules: boolean;
    scheduleTemplates: TTemplate[];
    scheduleTemplate: TTemplate | null;
    payrolls: TPayrolls;
    loadingPayrolls: boolean;
    creatingEmployee: boolean;
    roles: TRoles;
    loadingRoles: boolean;
    role: TRole | null;
    date: string;
    loader: {
        actions: any[];
    };
    loadingResendInvitation: boolean;
    checkingCollisions: boolean;
    collisions: boolean;
    assigningTemplate: boolean;
    deletingEmployee: boolean;
    updatingPassword: boolean;
    deletingSchedule: boolean;
    updatingSchedule: boolean;
    deletingTemplate: boolean;
    resetingPermissions: boolean;
    employeeSchedule: SingleEmployeeSchedules | null;
    loadingEmployeeSchedule: boolean;
    updatingEmployeeSchedule: boolean;
};

const _INITIAL_STATE: HRMReducerState = {
    request: false, // either or not user is in the middle of a request.
    error: null,
    employees: [],
    loadingEmployees: true,
    loadingInactiveEmployees: true,
    updatingEmployee: false,
    employee: null,
    employeeActivities: null,
    loadingEmployeeActivities: true,
    schedules: [],
    loadingSchedules: true,
    scheduleTemplates: [],
    scheduleTemplate: null,
    payrolls: [],
    loadingPayrolls: true,
    creatingEmployee: false,
    roles: [],
    loadingRoles: true,
    role: null,
    date: new Date().toISOString(),
    loader: {
        actions: [],
    },
    loadingResendInvitation: false,
    checkingCollisions: false,
    collisions: false,
    assigningTemplate: false,
    deletingEmployee: false,
    updatingPassword: false,
    deletingSchedule: false,
    updatingSchedule: false,
    deletingTemplate: false,
    resetingPermissions: false,
    employeeSchedule: null,
    loadingEmployeeSchedule: true,
    updatingEmployeeSchedule: false,
};

const HRMReducer = (state = _INITIAL_STATE, action: { type: string; payload: any }): HRMReducerState => {
    const { loader } = state;

    switch (action.type) {
        case types.HRM_CHANGE_STATE_REQUEST:
            return {
                ...state,
                ...action.payload,
            };

        case types.RENDER_LOADER:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...new Set([...loader.actions, action.payload])],
                },
            };

        case types.REMOVE_LOADER:
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [loader.actions.filter((a) => a !== action.payload)],
                },
            };

        case types.CHANGE_DATE:
            return {
                ...state,
                date: action.payload,
            };

        //
        // EMPLOYEES APIS
        //
        case types.HRM_GET_ALL_EMPLOYEES_REQUEST:
            return {
                ...state,
                loadingEmployees: true,
            };

        case types.HRM_GET_ALL_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload,
                loadingEmployees: false,
                error: null,
            };

        case types.HRM_GET_ALL_EMPLOYEES_FAILURE:
            return {
                ...state,
                employees: [],
                loadingEmployees: false,
                error: action.payload,
            };

        case types.HRM_CREATE_EMPLOYEE_REQUEST:
            return {
                ...state,
                creatingEmployee: true,
            };

        case types.HRM_CREATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                creatingEmployee: false,
                error: null,
            };

        case types.HRM_CREATE_EMPLOYEE_FAILURE:
            return {
                ...state,
                creatingEmployee: false,
                error: action.payload,
            };
        case types.HRM_GET_EMPLOYEE_BY_ID_SUCCESS:
            return {
                ...state,
                employee: action.payload,
                error: null,
            };
        case types.HRM_GET_EMPLOYEE_BY_ID_FAILURE:
            return {
                ...state,
            };

        case types.HRM_UPDATE_EMPLOYEE_REQUEST:
            return {
                ...state,
                updatingEmployee: true,
            };

        case types.HRM_UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employee: action.payload.employee,
                employees: action.payload.employees,
                updatingEmployee: false,
                error: null,
            };

        case types.HRM_UPDATE_EMPLOYEE_FAILURE:
            return {
                ...state,
                updatingEmployee: false,
                error: action.payload,
            };

        case types.HRM_RESET_EMPLOYEE_PASSWORD_REQUEST:
            return {
                ...state,
                updatingPassword: true,
            };

        case types.HRM_RESET_EMPLOYEE_PASSWORD_SUCCESS:
            return {
                ...state,
                error: null,
                updatingPassword: false,
            };

        case types.HRM_RESET_EMPLOYEE_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload,
                updatingPassword: false,
            };

        case types.HRM_DELETE_EMPLOYEE_REQUEST:
            return {
                ...state,
                deletingEmployee: true,
            };

        case types.HRM_DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                deletingEmployee: false,
                employee: null,
            };

        case types.HRM_DELETE_EMPLOYEE_FAILURE:
            return {
                ...state,
                deletingEmployee: false,
                error: action.payload,
            };

        case types.HRM_GET_EMPLOYEE_ACTIVITIES_REQUEST:
            return {
                ...state,
                loadingEmployeeActivities: true,
            };

        case types.HRM_GET_EMPLOYEE_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loadingEmployeeActivities: false,
                employeeActivities: action.payload,
                error: null,
            };

        case types.HRM_GET_EMPLOYEE_ACTIVITIES_FAILURE:
            return {
                ...state,
                loadingEmployeeActivities: false,
                error: action.payload,
            };
        //
        // ROLES APIS
        //
        case types.HRM_CREATE_ROLE_SUCCESS:
            return {
                ...state,
                roles: [action.payload, ...state.roles],
                error: null,
            };

        case types.HRM_CREATE_ROLE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.HRM_GET_ALL_ROLES_REQUEST:
            return {
                ...state,
                loadingRoles: true,
            };

        case types.HRM_GET_ALL_ROLES_SUCCESS:
            return {
                ...state,
                loadingRoles: false,
                roles: action.payload,
                error: null,
            };

        case types.HRM_GET_ALL_ROLES_FAILURE:
            return {
                ...state,
                loadingRoles: false,
            };

        case types.HRM_GET_ROLE_BY_ID_SUCCESS:
            return {
                ...state,
                role: action.payload,
                error: null,
            };

        case types.HRM_GET_ROLE_BY_ID_FAILURE:
            return {
                ...state,
                role: null,
            };

        case types.HRM_UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                error: null,
            };

        case types.HRM_UPDATE_ROLE_FAILURE:
            return {
                ...state,
                error: action.payload
            };

        case types.HRM_DELETE_ROLE_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                error: null,
            };

        case types.HRM_DELETE_ROLE_FAILURE:
            return {
                ...state,
            };

        case types.HRM_RESET_PERMISSIONS_REQUEST:
            return {
                ...state,
                resetingPermissions: true,
            };

        case types.HRM_RESET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                resetingPermissions: false,
            };

        case types.HRM_RESET_PERMISSIONS_FAILURE:
            return {
                ...state,
                resetingPermissions: false,
            }
            
        case types.HRM_UPDATE_EMPLOYEE_ROLE_SUCCESS:
            return {
                ...state,
                employee: action.payload.employee,
                error: null,
            };

        case types.HRM_UPDATE_EMPLOYEE_ROLE_FAILURE:
            return {
                ...state,
                error: action.payload
            };

        //
        // SCHEDULES APIS
        //

        case types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_REQUEST:
            return {
                ...state,
                loadingSchedules: true,
            };
        case types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_SUCCESS:
            return {
                ...state,
                schedules: action.payload,
                loadingSchedules: false,
            };
        case types.HRM_GET_ALL_EMPLOYEE_SCHEDULES_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingSchedules: false,
            };

        case types.HRM_GET_ALL_SCHEDULES_TEMPLATE_SUCCESS:
            return {
                ...state,
                scheduleTemplates: action.payload,
            };
        case types.HRM_GET_ALL_SCHEDULES_TEMPLATE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.HRM_CREATE_SCHEDULE_SUCCESS:
            return {
                ...state,
            };
        case types.HRM_CREATE_SCHEDULE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.HRM_CREATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                scheduleTemplates: state.scheduleTemplates.concat(action.payload),
                scheduleTemplate: action.payload,
            };
        case types.HRM_CREATE_TEMPLATE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case types.HRM_DELETE_TEMPLATE_REQUEST:
            return {
                ...state,
                deletingTemplate: true,
            };
        case types.HRM_DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                deletingTemplate: false,
                scheduleTemplates: state.scheduleTemplates.filter((template) => template.templateId !== action.payload.templateId),
                scheduleTemplate: null,
            };
        case types.HRM_DELETE_TEMPLATE_FAILURE:
            return {
                ...state,
                deletingTemplate: false,
                error: action.payload,
            };
        case types.HRM_UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                scheduleTemplates: state.scheduleTemplates.filter((template) => template.templateId !== action.payload.templateId).concat(action.payload),
                scheduleTemplate: action.payload,
            };
        case types.HRM_UPDATE_TEMPLATE_FAILURE:
            return {
                ...state,
                error: action.payload,
                scheduleTemplate: { ...(state.scheduleTemplate as TTemplate) },
            };
        case types.HRM_GET_TEMPLATE_BY_ID_REQUEST:
            return {
                ...state,
                scheduleTemplate: action.payload.template,
            };

        case types.HRM_UPDATE_SCHEDULE_REQUEST:
            return {
                ...state,
                updatingSchedule: true,
            };
        case types.HRM_UPDATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                updatingSchedule: false,
                error: null,
            };
        case types.HRM_UPDATE_SCHEDULE_FAILURE:
            return {
                ...state,
                updatingSchedule: false,
                error: action.payload,
            };

        case types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_REQUEST:
            return {
                ...state,
                updatingSchedule: true,
            };
        case types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_SUCCESS:
            return {
                ...state,
                updatingSchedule: false,
                error: null,
            };
        case types.HRM_UPDATE_SCHEDULE_WITH_TEMPLATE_FAILURE:
            return {
                ...state,
                updatingSchedule: false,
                error: action.payload,
            };
        // case types.HRM_GET_TEMPLATE_BY_ID_SUCCESS:
        //   return {
        //     ...state,
        //     scheduleTemplate: action.payload,
        //     error: null,
        //   };
        // case types.HRM_GET_TEMPLATE_BY_ID_FAILURE:
        //   return {
        //     ...state,
        //     error: action.payload,
        //   };
        case types.HRM_ADD_TEMPLATE_REQUEST:
            return {
                ...state,
                scheduleTemplate: action.payload.template,
            };

        case types.HRM_ASSIGN_TEMPLATE_REQUEST:
            return {
                ...state,
                error: null,
                assigningTemplate: true,
            };
        case types.HRM_ASSIGN_TEMPLATE_SUCCESS:
            return {
                ...state,
                assigningTemplate: false,
                error: null,
            };
        case types.HRM_ASSIGN_TEMPLATE_FAILURE:
            return {
                ...state,
                assigningTemplate: false,
                error: action.payload,
            };

        case types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_REQUEST:
            return {
                ...state,
                loadingSchedules: true,
            };

        case types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_SUCCESS:
            return {
                ...state,
                schedules: action.payload,
                loadingSchedules: false,
            };
        case types.HRM_GET_EMPLOYEE_SCHEDULES_BY_ID_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingSchedules: false,
            };

        case types.HRM_CHECK_COLLISIONS_REQUEST:
            return {
                ...state,
                checkingCollisions: true,
                collisions: false,
            };
        case types.HRM_CHECK_COLLISIONS_SUCCESS:
            return {
                ...state,
                collisions: action.payload,
                checkingCollisions: false,
            };
        case types.HRM_CHECK_COLLISIONS_FAILURE:
            return {
                ...state,
                checkingCollisions: false,
            };

        case types.HRM_DELETE_SCHEDULE_REQUEST:
            return {
                ...state,
                deletingSchedule: true,
            };
        case types.HRM_DELETE_SCHEDULE_SUCCESS:
            return {
                ...state,
                error: null,
                deletingSchedule: false,
            };
        case types.HRM_DELETE_SCHEDULE_FAILURE:
            return {
                ...state,
                error: action.payload,
                deletingSchedule: false,
            };

        case types.HRM_GET_EMPLOYEE_SCHEDULE_REQUEST:
            return {
                ...state,
                loadingEmployeeSchedule: true,
            };
        case types.HRM_GET_EMPLOYEE_SCHEDULE_SUCCESS:
            return {
                ...state,
                error: null,
                loadingEmployeeSchedule: false,
                employeeSchedule: action.payload,
            };
        case types.HRM_GET_EMPLOYEE_SCHEDULE_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingEmployeeSchedule: false,
            };

        case types.HRM_UPDATE_EMPLOYEE_SCHEDULE_REQUEST:
            return {
                ...state,
                updatingEmployeeSchedule: true,
            };
        case types.HRM_UPDATE_EMPLOYEE_SCHEDULE_SUCCESS:
            return {
                ...state,
                error: null,
                updatingEmployeeSchedule: false,
                employeeSchedule: action.payload,
            };
        case types.HRM_UPDATE_EMPLOYEE_SCHEDULE_FAILURE:
            return {
                ...state,
                error: action.payload,
                updatingEmployeeSchedule: false,
            };

        //
        //PAYROLL APIS
        //

        case types.HRM_GET_ALL_PAYROLLS_REQUEST:
            return {
                ...state,
                loadingPayrolls: true,
            };

        case types.HRM_GET_ALL_PAYROLLS_SUCCESS:
            return {
                ...state,
                payrolls: action.payload,
                loadingPayrolls: false,
            };
        case types.HRM_GET_ALL_PAYROLLS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingPayrolls: false,
            };
        case types.HRM_UPDATE_PAYROLL_SUCCESS:
            return {
                ...state,
                payrolls: action.payload.payrolls,
            };
        case types.HRM_UPDATE_PAYROLL_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case types.HRM_RESEND_EMPLOYEE_INVITATION_REQUEST:
            return {
                ...state,
                loadingResendInvitation: true,
            };

        case types.HRM_RESEND_EMPLOYEE_INVITATION_SUCCESS:
            return {
                ...state,
                loadingResendInvitation: false,
            };

        case types.HRM_RESEND_EMPLOYEE_INVITATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingResendInvitation: false,
            };

        default:
            return state;
    }
};
// export type THRMReducer = ReturnType<() => typeof HRMReducer>;
export default HRMReducer;

import { call, delay, put, takeLatest, takeLeading, CallEffect, PutEffect, select, SelectEffect } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { types } from "../reducer/types";
import { types as orgTypes } from "../../org/reducer/types";
// import { types as storageTypes } from "../../../../storage/redux/reducer/types";
// import { types as hrmTypes } from "../../../../hrm/redux/reducer/types";

import authClass from "./auth.class";
import adminClass from "./admin.class";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import history from "../../../../../app/routes/history";
import hrmClass from "../../../../hrm/redux/saga/hrm.class";
import apiV2 from "../../../../../helpers/api.v2";
import { RootState } from "../../../../../redux/reducers";
import popupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import { IHRMActions } from "../../../../hrm/redux/reducer/actions.d";
import { OrgActions } from "../../org/reducer/actions.types";
import { AuthState } from "../reducer/reducer";
import { soltivoHelper } from "@soltivo/draw-a-line";

/**
 *
 * @Helper this function is just to get the phone_number_verified attribute from amplify
 * @reason the autheticated user does not give us the for the current autheticated user the right state if the user
 * updates the phone number and confirm his SMS code, it would only work in current authenticated user if user
 * sign in again.
 */
function* isPhoneNumberVerified(): Generator<CallEffect, boolean, any> {
    const userAttrs: {[key: string]: string} = yield call(authClass.authGetUserAttributes);
    return userAttrs?.phone_number_verified === "true";
}

/**
 * @description check the current autheticated user.
 */
function* authCurrentAuthenticatedUser({
    payload,
}: {
    type: string;
    payload?: {
        refresh: boolean;
    };
}): Generator<PutEffect | CallEffect, void, any> {
    try {
        const { tokens: session } = yield call(authClass.authCurrentSession);

        const { idToken, accessToken } = session;

        localStorage.setItem("token", accessToken.toString());
        localStorage.setItem("idToken", idToken.toString());

        if (!payload?.refresh) {
            const orgId = localStorage.getItem("orgId") || idToken.payload["custom:org_id"];
            localStorage.setItem("orgId", orgId);
        }
        yield put({
            type: types.AUTH_CURRENT_AUTHENTICATED_USER_SUCCESS,
            payload: idToken.payload,
        });
        yield put({ type: types.AUTH_PROFILE_INFO_REQUEST, payload });
    } catch (error: any) {
        if (typeof error === "string") {
            if (error === "The user is not authenticated") {
                history.push(`/login?next=${encodeURIComponent(history.location.pathname)}`);
                localStorage.clear();
                soltivoHelper.deleteAllCookies();
            }
        } else if (error.code === "NotAuthorizedException") {
            history.push(`/login?next=${encodeURIComponent(history.location.pathname)}`);
            localStorage.clear();
            soltivoHelper.deleteAllCookies();
        }

        yield put({
            type: types.AUTH_CURRENT_AUTHENTICATED_USER_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description get the status of user's profile
 */
function* authProfileInfo({
    payload,
}: {
    type: string;
    payload?: {
        refresh: boolean;
    };
}): SagaIterator<void> {
    const { cognitoUser } = yield select(({ AuthReducer }: RootState) => ({
        cognitoUser: AuthReducer.cognitoUser,
    }));

    try {
        const orgId = localStorage.getItem("orgId");
        if (!orgId || orgId === "undefined") {
            throw {
                code: 400,
                message: "Organization not found",
                status: "OrganizationException",
            };
        }
        const res = yield call(adminClass.profileInfo);

        if (!res) {
            throw new Error("User profile not found");
        }
        const { data } = res;

        /**
         * @description checks if user is an admin / roleId === "0".
         */
        const isAdmin = data.roleId === "0";

        // verify phone number.
        const phone_number_verified = yield* isPhoneNumberVerified();

        if (cognitoUser) {
            if (!phone_number_verified && isAdmin) {
                // history.push("/settings/profile");
            }
        }

        // force employee to always have firstName and last name... we do not have any check from the BE at the time.
        if (!data.firstName && !isAdmin && !payload?.refresh) {
            throw {
                code: 400,
                message: "Please SetUp Your Profile",
                status: "EmployeeProfileException",
            };
        }
        localStorage.setItem("user", JSON.stringify(data));

        if (!payload?.refresh) {
            if (isAdmin) yield put<OrgActions["orgGetOrganizations"]>({ type: orgTypes.ORG_GET_ALL_ORGANIZATIONS_REQUEST });

            yield put<OrgActions["orgEnterOrganization"]>({
                type: orgTypes.ORG_ENTER_ORGANIZATION_REQUEST,
                payload: {
                    orgId: data.orgId,
                    preventReload: true,
                },
            });
        }

        yield put({
            type: types.AUTH_PROFILE_INFO_SUCCESS,
            payload: {
                user: data,
                phone_number_verified: phone_number_verified,
            },
        });
    } catch (error: any) {
        if (payload?.refresh) return;
        if (error.code === 400 && error.message === "Please SetUp Your Profile") {
            if (error.status === "EmployeeProfileException") {
                history.push("/employee-addinfo");
            } else {
                history.push("/add-info");
            }
        } else if (error.code === 400 && error.message === "Organization not found") {
            history.push("/add-info");
        } else if (error.code === "UserNotFoundException") {
            localStorage.clear();
            soltivoHelper.deleteAllCookies();
            history.replace(`/login?next=${encodeURIComponent(history.location.pathname)}`);
        }
        yield put({ type: types.AUTH_PROFILE_INFO_FAILURE, payload: error });
    }
}

/**
 * @description user sign in.
 */
function* authSignIn({
    payload,
}: {
    type: string;
    payload: {
        email: string;
        password: string;
    };
}): SagaIterator<void> {
    try {
        yield call(authClass.authSignIn, payload);
        const userSession = yield call(authClass.authCurrentSession);
        const userInfos = userSession.tokens.idToken.payload;

        yield put({ type: types.AUTH_SIGN_IN_USER_SUCCESS, payload: userInfos });

        localStorage.setItem("token", userSession.tokens.accessToken.toString());
        localStorage.setItem("idToken", userSession.tokens.idToken.toString());
        localStorage.setItem("orgId", userInfos["custom:org_id"]);
        yield put({ type: types.AUTH_PROFILE_INFO_REQUEST });
    } catch (error: any) {
        const { message, code } = error;

        // check if user has not confirmed account.
        if (code === "UserNotConfirmedException") {
            yield put({ type: types.AUTH_SIGN_IN_USER_FAILURE, payload: error });
            // resend code
            yield put({ type: types.AUTH_SIGN_UP_RESEND_CODE_REQUEST, payload });
            return;
        }

        yield put({ type: types.AUTH_SIGN_IN_USER_FAILURE, payload: error });
        toastHelper.toastStartContent("danger", message);
    }
}

/**
 * @description create user account.
 */
function* authSignUp({
    payload,
}: {
    type: string;
    payload: {
        email: string;
        phone_number: string;
        password: string;
    };
}): SagaIterator<void> {
    try {
        if (soltivoHelper.validateEmail(payload.email)) {
            throw {
                message: soltivoHelper.validateEmail(payload.email),
                code: "InvalidEmailException",
            };
        } else if (!payload.phone_number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/)) {
            throw {
                message: "phone number is invalid.",
                code: "InvalidPhoneNumberException",
            };
        }

        yield call(authClass.authSignUp, payload);
        history.push("/phone-validation-code");

        yield put({ type: types.AUTH_SIGN_UP_USER_SUCCESS });
        toastHelper.toastStartContent("info", "You will be receiving a sms with your code in a few minutes.");
    } catch (error: any) {
        if (error?.code !== "InvalidPhoneNumberException" || !/phone/g.test(error?.message)) {
            history.push("/sign-up");
        }
        yield put({ type: types.AUTH_SIGN_IN_USER_FAILURE, payload: error });
        toastHelper.toastStartContent("danger", error.message);
    }
}

/**
 * @description change phone number validation
 */
 function* authChangePhoneNumberValidation({
    payload,
}: {
    type: string;
    payload: {
        phoneNumber: string;
        userId: string;
    };
}): SagaIterator<void> {
    try {
        if (!payload.phoneNumber.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/)) {
            throw {
                message: "phone number is invalid.",
                code: "InvalidPhoneNumberException",
            };
        }

        yield call(authClass.authChangePhoneNumberValidation, payload);
        history.push("/phone-validation-code");

        yield put({ type: types.AUTH_CHANGE_PHONE_NUMBER_VALIDATION_SUCCESS });
        toastHelper.toastStartContent("info", "You will be receiving a sms with your code in a few minutes.");
    } catch (error: any) {
        if (error?.code !== "InvalidPhoneNumberException" || !/phone/g.test(error?.message)) {
            history.push("/sign-up");
        }
        yield put({ type: types.AUTH_SIGN_IN_USER_FAILURE, payload: error });
        toastHelper.toastStartContent("danger", error.message);
    }
}

/**
 * @description verify user account after sign up.
 */
function* authConfirmSignUp({
    payload,
}: {
    type: string;
    payload: {
        email: string;
        code: string;
    };
}): SagaIterator<void> {
    try {
        yield call(authClass.authConfirmSignUp, payload);
        // Trigger autoSignIn event - will not be triggered automatically
        yield call(authClass.authAutauSignIn);
        yield put({ type: types.AUTH_CONFIRM_SIGN_UP_SUCCESS });
        yield put({ type: types.AUTH_SIGN_IN_USER_REQUEST, payload });
    } catch (error: any) {
        yield put({ type: types.AUTH_CONFIRM_SIGN_UP_FAILURE, payload: error });
        toastHelper.toastStartContent("danger", error.message);
    }
}

/**
 * @description resend code to confirm account created in sign up.
 */
function* authResendCodeSignUp({
    payload,
}: {
    type: string;
    payload: {
        email: string;
    };
}): SagaIterator<void> {
    try {
        const resendCondeVerification = soltivoHelper.getCookie("resendCode");
        if (resendCondeVerification) {
            throw {
                code: 403,
                message: "You should wait a few second to resend a new code SMS.",
                status: "resendCodeException",
            };
        }

        yield call(authClass.authResendCodeSignUp, payload);
        soltivoHelper.setCookie("resendCode", new Date().toISOString(), 0.000347222);

        history.push("/phone-validation-code");
        yield put({ type: types.AUTH_SIGN_UP_RESEND_CODE_SUCCESS });

        toastHelper.toastStartContent("info", "You will be receiving a sms with your code in a few minutes.");
    } catch (error: any) {
        if (error.code === 403 && error.code === "resendCodeException") {
            toastHelper.toastStartContent("danger", error.message);
        } else {
            toastHelper.toastStartContent("danger", error.message || apiV2.UnknownErroMessage);
        }

        yield put({ type: types.AUTH_SIGN_UP_RESEND_CODE_FAILURE, payload: error });
    }
}

/**
 * @description user logout.
 */
function* authLogout(): SagaIterator<void> {
    try {
        const data = yield call(authClass.authLogout);
        yield put({ type: types.AUTH_LOGOUT_USER_SUCCESS, payload: data });
        soltivoHelper.deleteAllCookies();
        localStorage.clear();
        window.location.assign("/login");
    } catch (error: any) {
        yield put({ type: types.AUTH_LOGOUT_USER_FAILURE, payload: error });
    }
}

/**
 * @description user forgot password & send code to user cellphone.
 */
function* authForgotPassword({
    payload,
}: {
    type: string;
    payload: {
        email: string;
    };
}): SagaIterator<void> {
    try {
        yield call(authClass.authForgotPassword, payload);
        toastHelper.toastStartContent("info", "You will be receiving a sms with your code in a few minutes.");
        yield put({ type: types.AUTH_FORGOT_PASSWORD_SUCCESS, payload });
    } catch (error: any) {
        yield put({ type: types.AUTH_FORGOT_PASSWORD_FAILURE, payload: error });
    }
}

/**
 * @description user forgot password submit.
 */
function* authForgotPasswordSubmit({
    payload,
}: {
    type: string;
    payload: {
        email: string;
        code: string;
        new_password: string;
        confirm_password: string;
    };
}): SagaIterator<void> {
    try {
        yield call(authClass.authForgotPasswordSubmit, payload);
        yield put({ type: types.AUTH_FORGOT_PASSWORD_SUBMIT_SUCCESS });
        toastHelper.toastStartContent("success", "Password changed successfully, you will be redirected to sign in page.");
        yield delay(5000);
        history.push("/login");
    } catch (error: any) {
        const { message } = error;
        yield put({
            type: types.AUTH_FORGOT_PASSWORD_SUBMIT_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", message);
    }
}

/**
 * @description user update attributes
 */
function* authUpdateUserAttributes({
    payload,
}: {
    type: string;
    payload: {
        attributes: object;
    };
}): SagaIterator<void> {
    try {
        yield call(authClass.authUpdateUserAttributes, payload);
        yield put({ type: types.AUTH_UPDATE_USER_ATTRIBUTES_SUCCESS });
    } catch (error: any) {
        const { message } = error;
        yield put({
            type: types.AUTH_UPDATE_USER_ATTRIBUTES_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", message);
    }
}

/**
 * @description update user status
 */
function* updateUserStatus({
    payload,
}: {
    type: string;
    payload: {
        status: "unavailable" | "available" | "idle" | "busy";
    };
}): SagaIterator<void> {
    try {
        yield put({ type: types.AUTH_UPDATE_USER_STATUS_SUCCESS, payload });
    } catch (error: any) {
        yield put({ type: types.AUTH_UPDATE_USER_STATUS_FAILURE, payload: error });
    }
}

/**
 * @description onboard Employee.
 */
function* authOnBoardEmployee({
    payload,
}: {
    type: string;
    payload: {
        email: string;
        oldPassword: string;
        password: string;
    };
}): SagaIterator<void> {
    try {
        //login in employee account using old Password (genereted password)
        const signInUser = yield call(authClass.authOnBoardEmployeeSignin, {
            email: payload.email,
            oldPassword: payload.oldPassword,
        });

        //change password
        yield call(authClass.authOnBoardEmployeeChangePassword, {
            password: payload.password,
            step: signInUser.nextStep.signInStep
        });

        //authenticated the user.
        const userSession = yield call(authClass.authCurrentSession);
        const currentUser = userSession.tokens.idToken.payload;
        
        localStorage.setItem("user", JSON.stringify({ orgId: currentUser["custom:org_id"] }));
        localStorage.setItem("orgId", currentUser["custom:org_id"]);
        localStorage.setItem("token", userSession.tokens.accessToken.toString());
        localStorage.setItem("idToken", userSession.tokens.idToken.toString());
        localStorage.setItem("id", currentUser["cognito:username"]);

        history.push("/employee-addinfo");

        toastHelper.toastStartContent("info", "Your Account is Created and Password is Updated Successfully");
    } catch (error: any) {
        yield put({ type: types.AUTH_ON_BOARDING_USER_FAILURE, payload: error });
        toastHelper.toastStartContent("danger", error.message);
    }
}

/**
 * @description Employee update attributes
 */
function* updateEmployeeAttributes({ payload }: { type: string; payload: IHRMActions["hrmUpdateEmployee"]["payload"] }): SagaIterator<void> {
    try {
        payload.inviteAccepted = true;
        yield call(hrmClass.hrmUpdateEmployee, {
            ...payload,
            employeeId: localStorage.getItem("id") || "undefined",
            firstName: payload.firstName,
            hourlyRate: payload.hourlyRate,
        });
        localStorage.removeItem("id");

        yield put({ type: types.AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_SUCCESS });
        yield put({ type: types.AUTH_CURRENT_AUTHENTICATED_USER_REQUEST });
    } catch (error: any) {
        const { message } = error;
        yield put({
            type: types.AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", message);
    }
}

/**
 * @description Validate Employee Registration
 */
function* validateEmployeeRegistration({
    payload,
}: {
    type: string;
    payload: {
        employeeId: string;
        registrationId: string;
    };
}): SagaIterator<void> {
    try {
        yield call(hrmClass.hrmValidateRegistration, payload);
        yield put({ type: types.AUTH_VALIDATE_EMPLOYEE_REGISTRATION_SUCCESS });
    } catch (error: any) {
        const { message } = error;
        yield put({
            type: types.AUTH_VALIDATE_EMPLOYEE_REGISTRATION_SUCCESS,
            payload: error,
        });
        toastHelper.toastStartContent("danger", message);
    }
}

/**
 * @description update admin profile
 */
function* authUpdateAdminInfo({
    payload,
}: {
    type: string;
    payload: {
        firstName: string;
        lastName: string;
        hourlyRate: number;
        email: string;
        avatar: File | string;
        phoneNumber: string;
    };
}): Generator<CallEffect | SelectEffect | PutEffect | any, void, any> {
    try {
        yield delay(2000);

        let { email, phoneNumber, firstName, lastName, hourlyRate, avatar } = payload;

        if (!email || soltivoHelper.validateEmail(email)) {
            throw {
                code: 422,
                message: "A valid email is required.",
                status: "AdminValidationException",
            };
        } else if (!firstName) {
            throw {
                code: 422,
                message: "First name and last name are required.",
                status: "AdminValidationException",
            };
        } else if (!phoneNumber) {
            throw {
                code: 422,
                message: "Phone number is required.",
                status: "AdminValidationException",
            };
        } else if (!hourlyRate && hourlyRate !== 0) {
            throw {
                code: 422,
                message: "Hourly rate is required.",
                status: "AdminValidationException",
            };
        } else if (typeof avatar !== "string") {
            if (!/(jpeg|jpg|png|webp)/g.test(avatar?.type.toLowerCase())) {
                throw {
                    code: 422,
                    message: "Avatar picture can only be jpeg, jpg, webp or png.",
                    status: "AdminValidationException",
                };
            }
        }

        const { user, phone_number_verified } = yield select(({ AuthReducer }: RootState) => ({
            cognitoUser: AuthReducer.cognitoUser,
            user: AuthReducer.user,
            phone_number_verified: AuthReducer.phone_number_verified,
        }));

        // if user is trying to upload an image.
        if (typeof avatar !== "string") {
            const { data: assignedUrl } = yield call(adminClass.getSignedUrl, {
                file: avatar,
            });

            let fd = new FormData();
            Object.keys(assignedUrl.fields).forEach((key) => {
                fd.append(key, assignedUrl.fields[key]);
            });

            const image = yield soltivoHelper.processImageSize(avatar, {
                dimensions: {
                    width: 512,
                    height: 512,
                    fill: true,
                },
            });

            fd.append("file", image);
            fd.append("Content-Type", image.type);
            fd.append("success_action_status", "200");
            fd.append("Cache-Control", "max-age=300,public");
            const res = yield fetch(assignedUrl.url, {
                method: "POST",
                body: fd,
            });

            if (res.status >= 200 && res.status < 500) {
                const avatarUrl = new URL(`https://cdn.soltivo.dev/${assignedUrl.fields.key}`);
                avatarUrl.searchParams.set("cache", new Date().getTime().toString());
                avatar = avatarUrl.toString();
            } else {
                avatar = (user.avatar as string) || "";
            }
        }

        let requireSMScode = false;

        yield call(authClass.authUpdateUserAttributes, {
            attributes: {
                email,
                phone_number: phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`,
            },
        });

        if (phoneNumber !== user.phoneNumber) requireSMScode = true;

        const { data } = yield call(adminClass.updateAdminInfo, {
            avatar: avatar,
            email: email,
            firstName: firstName,
            hourlyRate: hourlyRate,
            lastName: lastName,
            phoneNumber: phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`,
        });

        yield put({
            type: types.AUTH_UPDATE_ADMIN_INFO_SUCCESS,
            payload: {
                user: avatar ? { ...data, avatar: `${avatar}` } : data,
                phone_number_verified: requireSMScode ? false : phone_number_verified,
            },
        });
    } catch (error: any) {
        if (error.code === 422 && error.status === "AdminValidationException") {
            toastHelper.toastStartContent("danger", error.message);
        } else if (typeof error.message === "string") {
            toastHelper.toastStartContent("danger", error.message);
        } else if (error.error?.code > 399 && error.error?.code < 500) {
            const errorsString = apiV2.stringifyErrors(error, [403, 404, 400, 422]);
            if (errorsString) toastHelper.toastStartContent("danger", errorsString);
        }

        yield put({
            type: types.AUTH_UPDATE_ADMIN_INFO_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description verifyCurrentUserAttributeSubmit
 */
function* authVerifyCurrentUserAttribute({
    payload,
}: {
    type: string;
    payload: {
        attribute: "phone_number" | "email";
        code?: string;
    };
}): SagaIterator<void> {
    try {
        if (payload.attribute !== "phone_number") return;

        if (!payload.code) {
            toastHelper.toastStartContent("info", "Sending SMS...");
            yield delay(3000);
            const resendCondeVerification = soltivoHelper.getCookie("resendCode");
            if (resendCondeVerification) {
                throw {
                    code: 403,
                    message: "You should wait a few second to resend a new code SMS.",
                    status: "resendCodeException",
                };
            }
            yield call(authClass.authVerifyUserAttribute, {
                attribute: payload.attribute,
            });

            soltivoHelper.setCookie("resendCode", new Date().toISOString(), 0.000347222);
            toastHelper.toastStartContent("success", "We have sent a SMS to your phone number, you should get it in the next few seconds.");
        } else {
            yield call(authClass.authVerifyUserAttributeSubmit, {
                attribute: payload.attribute,
                code: payload.code,
            });
            yield put({
                type: types.AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_SUCCESS,
                payload: {
                    phone_number_verified: true,
                },
            });

            toastHelper.toastStartContent("success", "Phone number verified successfully.");
            popupHelper.popupEnd();
        }
    } catch (error: any) {
        yield put({
            type: types.AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_FAILURE,
            payload: error,
        });
        toastHelper.toastStartContent("danger", error?.message || apiV2.UnknownErroMessage);
    }
}

/**
 * @description change user password
 */
function* authChangeUserPassword({
    payload,
}: {
    type: string;
    payload: { oldPassword: string; newPassword: string; confirmPassword: string };
}): Generator<CallEffect | SelectEffect | PutEffect, void, any> {
    try {
        const { confirmPassword, newPassword, oldPassword } = payload;

        const { phone_number_verified, user }: AuthState = yield select(({ AuthReducer }: RootState) => ({
            cognitoUser: AuthReducer.cognitoUser,
            phone_number_verified: AuthReducer.phone_number_verified,
            user: AuthReducer.user,
        }));

        //only for admin, because when the admin is registered we do ask for the phone number,
        //but this is not the case for employee.
        if (!phone_number_verified && user?.roleId === "0") {
            throw {
                code: 422,
                status: "PasswordChangeException",
                message: `You must verify your phone number before change password.`,
            };
        }

        let required = [];
        for (let key in payload) {
            if (!(payload as { [index: string]: string })[key]) {
                required.push(key);
            }
        }

        if (required.length) {
            throw {
                code: 422,
                status: "PasswordChangeException",
                message: `${required.toString()} ${required.length > 1 ? "are" : "is"} required`,
            };
        } else if (newPassword !== confirmPassword) {
            throw {
                code: 422,
                status: "PasswordChangeException",
                message: `New password and confirm password does not match.`,
            };
        } else if (newPassword === oldPassword) {
            throw {
                code: 422,
                status: "PasswordChangeException",
                message: `New password should not be equal to the previous password.`,
            };
        }

        yield call(authClass.authChangeUserPassword, {
            newPassword: newPassword,
            oldPassword: oldPassword,
        });

        toastHelper.toastStartContent("success", "Password changed successfully.");
        popupHelper.popupEnd();
    } catch (error: any) {
        if (error.code === 422 && error.status === "PasswordChangeException") {
            toastHelper.toastStartContent("danger", error.message);
        } else if (typeof error.message === "string") {
            toastHelper.toastStartContent("danger", error.message);
        } else if (error.error?.code > 399 && error.error?.code < 500) {
            const errorsString = apiV2.stringifyErrors(error, [403, 404, 400, 422]);
            if (errorsString) toastHelper.toastStartContent("danger", errorsString);
        }

        yield put({
            type: types.AUTH_CHANGE_USER_PASSWORD_FAILURE,
            payload: error,
        });
    }
}

export default function* AuthSaga() {
    yield takeLatest(types.AUTH_CURRENT_AUTHENTICATED_USER_REQUEST, authCurrentAuthenticatedUser);
    yield takeLatest(types.AUTH_PROFILE_INFO_REQUEST, authProfileInfo);
    yield takeLatest(types.AUTH_SIGN_IN_USER_REQUEST, authSignIn);
    yield takeLatest(types.AUTH_SIGN_UP_USER_REQUEST, authSignUp);
    yield takeLatest(types.AUTH_CONFIRM_SIGN_UP_REQUEST, authConfirmSignUp);
    yield takeLatest(types.AUTH_SIGN_UP_RESEND_CODE_REQUEST, authResendCodeSignUp);
    yield takeLeading(types.AUTH_LOGOUT_USER_REQUEST, authLogout);
    yield takeLatest(types.AUTH_FORGOT_PASSWORD_REQUEST, authForgotPassword);
    yield takeLatest(types.AUTH_FORGOT_PASSWORD_SUBMIT_REQUEST, authForgotPasswordSubmit);
    yield takeLatest(types.AUTH_UPDATE_USER_STATUS_REQUEST, updateUserStatus);
    yield takeLatest(types.AUTH_UPDATE_USER_ATTRIBUTES_REQUEST, authUpdateUserAttributes);
    yield takeLatest(types.AUTH_ON_BOARDING_USER_REQUEST, authOnBoardEmployee);
    yield takeLatest(types.AUTH_UPDATE_EMPLOYEE_ATTRIBUTES_REQUEST, updateEmployeeAttributes);
    yield takeLatest(types.AUTH_VALIDATE_EMPLOYEE_REGISTRATION_REQUEST, validateEmployeeRegistration);
    yield takeLatest(types.AUTH_UPDATE_ADMIN_INFO_REQUEST, authUpdateAdminInfo);
    yield takeLatest(types.AUTH_VERIFY_CURRENT_USER_ATTRIBUTE_REQUEST, authVerifyCurrentUserAttribute);
    yield takeLatest(types.AUTH_CHANGE_USER_PASSWORD_REQUEST, authChangeUserPassword);
    yield takeLatest(types.AUTH_CHANGE_PHONE_NUMBER_VALIDATION_REQUEST, authChangePhoneNumberValidation);
}

import apiV2 from "../../../../helpers/api.v2";
import { IHRMActions } from "../reducer/actions.d";

var _URL_USER = `${process.env.REACT_APP_API_URL}/profile`; // same as auth for users.
var _URL_HRM = `${process.env.REACT_APP_API_URL}/hrm`;
var _URL_SCHEDULES = `${process.env.REACT_APP_API_URL}/schedules`;
var _URL_HRM_PUBLIC = `${process.env.REACT_APP_API_URL}/public-hrm`;
// const _URL_PRIVATE_BILLING = `${process.env.REACT_APP_API_URL}/billing`;
/**
 * @description HRM API calls.
 * @todo actions related to Schedule, Timer tracker & Payroll wasn't created yet.
 */
class HRMClass {
    ////////////////////////////////////////////////////////////////////
    //                                                                //
    //                           EMPLOYEES                            //
    //                                                                //
    ////////////////////////////////////////////////////////////////////

    /**
     * @description create a employee.
     */
    hrmCreateEmployee(payload: IHRMActions["hrmCreateEmployee"]["payload"]) {
        return apiV2.postRequest(`${_URL_HRM}/employees`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
        // return postRequest(`${_URL}/employees`, payload);
    }

    /**
     * @description get all active employees.
     */
    hrmGetAllEmployees(payload: IHRMActions["hrmGetAllEmployees"]["payload"]) {
        const includeInactive = payload?.includeInactive ? true : false;
        return apiV2.getRequest(`${_URL_HRM}/employees?includeInactive=${includeInactive}`);
    }

    /**
     * @description get all inactive employees.
     */
    hrmGetAllInactiveEmployees() {
        return apiV2.getRequest(`${_URL_HRM}/employees?includeInactive=true`);
    }

    /**
     * @description get employee by id
     */
    hrmGetEmployee(payload: IHRMActions["hrmGetEmployee"]["payload"]) {
        return apiV2.getRequest(`${_URL_HRM}/employees/${payload.employeeId}`);
    }

    /**
     * @description delete employee by id
     */
    hrmDeleteEmployee(payload: IHRMActions["hrmDeleteEmployee"]["payload"]) {
        return apiV2.deleteRequest(`${_URL_HRM}/employees/${payload.employeeId}`);
    }

    /**
     * @description reset employee's password
     */
    hrmResetEmployeePassword({ employeeId, ...payload }: IHRMActions["hrmResetEmployeePassword"]["payload"]) {
        return apiV2.postRequest(`${_URL_HRM}/employees/password/${employeeId}`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description update employee by id
     */
    hrmUpdateEmployee({ employeeId, ...payload }: IHRMActions["hrmUpdateEmployee"]["payload"]) {
        return apiV2.putRequest(`${_URL_HRM}/employees/${employeeId}`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description api to check the validity for registration Request
     */
    hrmValidateRegistration(payload: { employeeId: string; registrationId: string }) {
        return apiV2.postRequest(`${_URL_HRM_PUBLIC}/employees/validateregistration`, {
            useToken: false,
            data: JSON.stringify(payload),
            useOrg: false,
        });
    }

    /**
     * @description upload profile pic
     */
    getSignedUrl(payload: { file: File }) {
        return apiV2.getRequest(`${_URL_USER}/url?contentType=${payload.file.type}&cacheControl=max-age=300,public`);
    }

    /**
     *
     * @description get employees activities
     */
    hrmGetEmployeeActivities(payload: IHRMActions["hrmGetEmployeeActivities"]["payload"]) {
        const params = new URL(`${_URL_HRM}/activities`);

        params.searchParams.append("employeeId", payload.employeeId);
        params.searchParams.append("exactDate", payload.exactDate);

        return apiV2.getRequest(`${_URL_HRM}/activities?${params.searchParams.toString()}`);
    }

    hrmResendEmployeeInvitation(payload: IHRMActions["hrmResendEmployeeInvitation"]["payload"]) {
        return apiV2.postRequest(`${_URL_HRM}/employees/resendinvite`, {
            useToken: "accessToken",
            data: JSON.stringify({
                email: payload.inactiveEmployee.email,
            }),
        });
    }

    ////////////////////////////////////////////////////////////////////
    //                                                                //
    //                            ROLES                               //
    //                                                                //
    ////////////////////////////////////////////////////////////////////

    /**
     * @description create a role.
     */
    hrmCreateRole(payload: IHRMActions["hrmCreateRole"]["payload"]) {
        return apiV2.postRequest(`${_URL_HRM}/roles`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description get all roles.
     */
    hrmGetAllRoles() {
        return apiV2.getRequest(`${_URL_HRM}/roles`);
    }

    /**
     * @description get role by id
     */
    hrmGetRole(payload: IHRMActions["hrmGetRole"]["payload"]) {
        return apiV2.getRequest(`${_URL_HRM}/roles/${payload.roleId}`);
    }

    /**
     * @description delete role by id
     */
    hrmDeleteRole(payload: IHRMActions["hrmDeleteRole"]["payload"]) {
        return apiV2.deleteRequest(`${_URL_HRM}/roles/${payload.roleId}`);
    }

    /**
     * @description update role by id
     */
    hrmUpdateRole(payload: IHRMActions["hrmUpdateRole"]["payload"]) {
        return apiV2.putRequest(`${_URL_HRM}/roles/${payload.roleId}`, {
            data: JSON.stringify(payload.role),
            useToken: "accessToken",
        });
    }

    /**
     * @description reset permissions by id
     */
     hrmResetPermissions(payload: IHRMActions['hrmResetPermissions']['payload']) {
        return apiV2.putRequest(`${_URL_HRM}/permissions/reset/${payload.roleId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description verify permissions
     */
     hrmVerifyPermissions() {
        return apiV2.getRequest(`${_URL_HRM}/permissions/verify`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update employee role
     */
     hrmUpdateEmployeeRole(payload: IHRMActions["hrmUpdateEmployeeRole"]["payload"]) {
        return apiV2.postRequest(`${_URL_HRM}/roles/assign`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description Validate role
     */
     hrmValidateRole() {
        return apiV2.postRequest(`${_URL_HRM}/roles/validate`, {
            data: JSON.stringify({}),
            useToken: "accessToken",
        });
    }

    ////////////////////////////////////////////////////////////////////
    //                                                                //
    //                        SCHEDULES                               //
    //                                                                //
    ////////////////////////////////////////////////////////////////////

    /**
     * @description get all employees schedule.
     */
    hrmGetAllEmployeeSchedules() {
        return apiV2.getRequest(`${_URL_SCHEDULES}/v3`);
    }

    /**
     * @description create/update employee schedule
     */
    hrmUpdateEmployeeSchedule(payload: IHRMActions["hrmUpdateEmployeeSchedule"]["payload"]) {
        const { schedule, userId } = payload;
        return apiV2.putRequest(`${_URL_SCHEDULES}/v3/${userId}`, {
            data: JSON.stringify(schedule),
            useToken: "accessToken",
        });
    }

    /**
     * @description get employee schedule
     */
    hrmGetEmployeeSchedule(payload: IHRMActions["hrmGetEmployeeSchedule"]["payload"]) {
        return apiV2.getRequest(`${_URL_SCHEDULES}/v3/${payload.userId}`, {
            useToken: "accessToken",
        });
    }

    ////////////////////////////////////////////////////////////////////
    //                                                                //
    //                         PAYROLLS                               //
    //                                                                //
    ////////////////////////////////////////////////////////////////////

    /**
     * @description get all payrolls.
     */
    hrmGetAllPayrolls(payload: IHRMActions["hrmGetAllPayrolls"]["payload"]) {
        return apiV2.getRequest(`${_URL_HRM}/payrolls?status=${payload.status}&from=${payload.date.from}&to=${payload.date.to}`);
    }

    /**
     * @description update Payroll range Status
     */
    hrmUpdatePayrollStatus(payload: IHRMActions["hrmUpdatePayroll"]["payload"]) {
        return apiV2.postRequest(`${_URL_HRM}/payrolls`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }
}

export default new HRMClass();

export const types = {

    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    CLEAR_ERROR: 'CLEAR_ERROR',
    RENDER_ERROR: 'RENDER_ERROR',

    CHANGE_VIEW: 'CHANGE_VIEW',

    CREATE_PROJECT_REQUEST: 'CREATE_PROJECT_REQUEST',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',

    UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',

    DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',

    CREATE_TASKS_LIST_REQUEST: 'CREATE_TASKS_LIST_REQUEST',
    CREATE_TASKS_LIST_SUCCESS: 'CREATE_TASKS_LIST_SUCCESS',

    UPDATE_TASKS_LIST_REQUEST: 'UPDATE_TASKS_LIST_REQUEST',
    UPDATE_TASKS_LIST_SUCCESS: 'UPDATE_TASKS_LIST_SUCCESS',

    DELETE_TASKS_LIST_REQUEST: 'DELETE_TASKS_LIST_REQUEST',
    DELETE_TASKS_LIST_SUCCESS: 'DELETE_TASKS_LIST_SUCCESS',

    CREATE_TASK_REQUEST: 'CREATE_TASK_REQUEST',
    CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',

    CREATE_PERSONAL_TASK_REQUEST: 'CREATE_PERSONAL_TASK_REQUEST',
    CREATE_PERSONAL_TASK_SUCCESS: 'CREATE_PERSONAL_TASK_SUCCESS',

    CREATE_SUBTASK_REQUEST: 'CREATE_SUBTASK_REQUEST',
    CREATE_SUBTASK_SUCCESS: 'CREATE_SUBTASK_SUCCESS',

    CREATE_STATUS_COLUMN_REQUEST: 'CREATE_STATUS_COLUMN_REQUEST',
    CREATE_STATUS_COLUMN_SUCCESS: 'CREATE_STATUS_COLUMN_SUCCESS',

    UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
    UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',

    DELETE_TASK_REQUEST: 'DELETE_TASK_REQUEST',
    DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',

    UPDATE_SUBTASK_REQUEST: 'UPDATE_SUBTASK_REQUEST',
    UPDATE_SUBTASK_SUCCESS: 'UPDATE_SUBTASK_SUCCESS',

    UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',

    UPDATE_PERSONAL_TASKS_REQUEST: 'UPDATE_PERSONAL_TASKS_REQUEST',
    UPDATE_PERSONAL_TASKS_SUCCESS: 'UPDATE_PERSONAL_TASKS_SUCCESS',

    UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    
    DELETE_PERSONAL_TASK_REQUEST: 'DELETE_PERSONAL_TASK_REQUEST',
    DELETE_PERSONAL_TASK_SUCCESS: 'DELETE_PERSONAL_TASK_SUCCESS',

    GET_PROJECT_LIST_REQUEST: 'GET_PROJECT_LIST_REQUEST',
    GET_PROJECT_LIST_SUCCESS: 'GET_PROJECT_LIST_SUCCESS',

    GET_TASKLISTS_REQUEST: 'GET_TASKLISTS_REQUEST',
    GET_TASKLISTS_SUCCESS: 'GET_TASKLISTS_SUCCESS',

    GET_PERSONAL_TASKS_REQUEST: 'GET_PERSONAL_TASKS_REQUEST',
    GET_PERSONAL_TASKS_SUCCESS: 'GET_PERSONAL_TASKS_SUCCESS',

    GET_SUBTASK_REQUEST: 'GET_SUBTASK_REQUEST',
    GET_SUBTASK_SUCCESS: 'GET_SUBTASK_SUCCESS',

    GET_TASKS_REQUEST: 'GET_TASKS_REQUEST', // Tasks inside a tasklist
    GET_TASKS_SUCCESS: 'GET_TASKS_SUCCESS', // Tasks inside a tasklist
    UPDATE_TASKS_STATUS: 'UPDATE_TASKS_STATUS', // Tasks inside a tasklist

    GET_SINGLE_TASK_REQUEST: 'GET_SINGLE_TASK_REQUEST',
    GET_SINGLE_TASK_SUCCESS: 'GET_SINGLE_TASK_SUCCESS',

    GET_ORGANISATION_MEMBERS_REQUEST: 'GET_ORGANISATION_MEMBERS_REQUEST',
    GET_ORGANISATION_MEMBERS_SUCCESS: 'GET_ORGANISATION_MEMBERS_SUCCESS',

    GET_STATUSES_REQUEST: 'GET_STATUSES_REQUEST',
    GET_STATUSES_SUCCESS: 'GET_STATUSES_SUCCESS',
    
    DELETE_STATUS_REQUEST: 'DELETE_STATUS_REQUEST',
    DELETE_STATUS_SUCCESS: 'DELETE_STATUS_SUCCESS',

    REORDER_COLUMNS_REQUEST: 'REORDER_COLUMNS_REQUEST',
    REORDER_COLUMNS_SUCCESS: 'REORDER_COLUMNS_SUCCESS',
}
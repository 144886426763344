import apiV2 from "../../../../helpers/api.v2";
import { ContactsActions } from "../reducer/actions.types";
export const API_URL = `${process.env.REACT_APP_API_URL}/crm`;

class ContactsClass {
    /**
     * @description update a lead
     */
    contactsUpdateEntity({ entityId, ...payload }: ContactsActions["contactsUpdateLead"]["payload"] | ContactsActions["contactsUpdateClient"]["payload"]) {
        return apiV2.putRequest(`${API_URL}/entities/${entityId}`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get entity (client | lead | supplier)
     */
    contactsGetEntity({ entityId }: ContactsActions["contactsGetEntity"]["payload"]) {
        return apiV2.getRequest(`${API_URL}/entity/${entityId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create new lead
     */
    contactsCreateLead(payload: ContactsActions["contactsCreateLead"]["payload"]) {
        return apiV2.postRequest(`${API_URL}/leads`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get leads
     * @ps payload is only required if you want to do pagination,
     * not if you want get all the leads do not pass any payload.
     */
    contactsGetLeads(payload: ContactsActions["contactsGetLeads"]["payload"]) {
        return apiV2.getRequest(`${API_URL}/entity/list/lead${payload?.lastKey ? `?lastKey=${payload.lastKey}` : ""}`, {
            useToken: "accessToken",
        });

        // return apiV2.getRequest(`${API_URL}/leads`, {
        //   useToken: "accessToken",
        // });
    }

    /**
     * @description delete a lead
     */
    contactsDeleteLead({ entityId }: ContactsActions["contactsDeleteLead"]["payload"]) {
        return apiV2.deleteRequest(`${API_URL}/entities/${entityId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description convert lead to client
     */
    contactsConvertLead(payload: ContactsActions["contactsConvertLead"]["payload"]) {
        return apiV2.postRequest(`${API_URL}/leads/convert`, {
            useToken: "accessToken",
            data: JSON.stringify({ entityId: payload.entityId }),
        });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * @description create new client
     */
    contactsCreateClient(payload: ContactsActions["contactsCreateClient"]["payload"]) {
        return apiV2.postRequest(`${API_URL}/clients`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get clients
     */
    contactsGetClients(payload: ContactsActions["contactsGetClients"]["payload"]) {
        return apiV2.getRequest(`${API_URL}/entity/list/client${payload?.lastKey ? `?lastKey=${payload.lastKey}` : ""}`, {
            useToken: "accessToken",
        });

        // return apiV2.getRequest(`${API_URL}/clients`, {
        //   useToken: "accessToken",
        // });
    }

    /**
     * @description delete a client
     */
    contactsDeleteClient({ entityId }: ContactsActions["contactsDeleteClient"]["payload"]) {
        return apiV2.deleteRequest(`${API_URL}/entities/${entityId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description create new supplier
     */
    contactsCreateSupplier(payload: ContactsActions["contactsCreateSupplier"]["payload"]) {
        return apiV2.postRequest(`${API_URL}/contacts`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get suppliers
     */
    contactsGetSuppliers(payload: ContactsActions["contactsGetSuppliers"]["payload"]) {
        return apiV2.getRequest(`${API_URL}/entity/list/contact${payload?.lastKey ? `?lastKey=${payload.lastKey}` : ""}`, {
            useToken: "accessToken",
        });

        // return apiV2.getRequest(`${API_URL}/contacts`, {
        //   useToken: "accessToken",
        // });
    }

    /**
     * @description delete a supplier
     */
    contactsDeleteSupplier({ entityId }: ContactsActions["contactsDeleteSupplier"]["payload"]) {
        return apiV2.deleteRequest(`${API_URL}/entities/${entityId}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description update a supplier
     */
    contactsUpdateSupplier({ entityId, ...payload }: ContactsActions["contactsUpdateSupplier"]["payload"]) {
        return apiV2.putRequest(`${API_URL}/contacts/${entityId}`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description create new note
     */
    contactsCreateNote(payload: ContactsActions["contactsCreateNote"]["payload"]) {
        return apiV2.postRequest(`${API_URL}/notes`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get notes pagination
     */
    contactsGetNotes(payload: ContactsActions["contactsGetNotes"]["payload"]) {
        const url = new URL(`${API_URL}/v1/notes/entities/${payload.entityId}`);
        if (payload?.lastKey) url.searchParams.set("lastKey", payload.lastKey);
        return apiV2.getRequest(url.toString(), {
            useToken: "accessToken",
        });
    }

    /**
     * @description get update note
     */
    contactsUpdateNote({ noteId, entityId, noteText, }: ContactsActions["contactsUpdateNote"]["payload"]) {
        return apiV2.putRequest(`${API_URL}/notes/${noteId}`, {
            useToken: "accessToken",
            data: JSON.stringify({ noteText, entityId }),
        });
    }

    /**
     * @description add entity custom fields
     */
    contactsCreateCustomFields(payload: ContactsActions["contactsCreateCustomFields"]["payload"]) {
        return apiV2.postRequest(`${API_URL}/entities/fields`, {
            useToken: "accessToken",
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description add entity custom fields
     */
    contactsGetCustomFields(payload: ContactsActions["contactsGetCustomFields"]["payload"]) {
        return apiV2.getRequest(`${API_URL}/entities/fields?entityType=${payload.entityType}`);
    }

    /**
     * @description search an entity
     */
    contactsSearchEntity(payload: ContactsActions["contactsSearchEntity"]["payload"]) {
        return apiV2.getRequest(`${API_URL}/entity/search?data=${payload}`, {
            useToken: "accessToken",
        });
    }
}

export default new ContactsClass();

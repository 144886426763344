export const types = {
    CONTACTS_CHANGE_STATE_REQUEST: "CONTACTS_CHANGE_STATE_REQUEST",

    // GET ENTITY MEANS CLIENT CONTACT OR LEAD
    CONTACTS_GET_ENTITY_REQUEST: "CONTACTS_GET_ENTITY_REQUEST",
    CONTACTS_GET_ENTITY_FAILURE: "CONTACTS_GET_ENTITY_FAILURE",
    CONTACTS_GET_ENTITY_SUCCESS: "CONTACTS_GET_ENTITY_SUCCESS",

    // Lead
    CONTACTS_CREATE_LEAD_REQUEST: "CONTACTS_CREATE_LEAD_REQUEST",
    CONTACTS_CREATE_LEAD_SUCCESS: "CONTACTS_CREATE_LEAD_SUCCESS",
    CONTACTS_CREATE_LEAD_FAILURE: "CONTACTS_CREATE_LEAD_FAILURE",

    CONTACTS_GET_LEADS_REQUEST: "CONTACTS_GET_LEADS_REQUEST",
    CONTACTS_GET_LEADS_FAILURE: "CONTACTS_GET_LEADS_FAILURE",
    CONTACTS_GET_LEADS_SUCCESS: "CONTACTS_GET_LEADS_SUCCESS",

    CONTACTS_DELETE_LEAD_REQUEST: "CONTACTS_DELETE_LEAD_REQUEST",
    CONTACTS_DELETE_LEAD_SUCCESS: "CONTACTS_DELETE_LEAD_SUCCESS",
    CONTACTS_DELETE_LEAD_FAILURE: "CONTACTS_DELETE_LEAD_FAILURE",

    CONTACTS_UPDATE_LEAD_REQUEST: "CONTACTS_UPDATE_LEAD_REQUEST",
    CONTACTS_UPDATE_LEAD_SUCCESS: "CONTACTS_UPDATE_LEAD_SUCCESS",
    CONTACTS_UPDATE_LEAD_FAILURE: "CONTACTS_UPDATE_LEAD_FAILURE",

    CONTACTS_CONVERT_LEAD_REQUEST: "CONTACTS_CONVERT_LEAD_REQUEST",
    CONTACTS_CONVERT_LEAD_SUCCESS: "CONTACTS_CONVERT_LEAD_SUCCESS",
    CONTACTS_CONVERT_LEAD_FAILURE: "CONTACTS_CONVERT_LEAD_FAILURE",

    // Client
    CONTACTS_CREATE_CLIENT_REQUEST: "CONTACTS_CREATE_CLIENT_REQUEST",
    CONTACTS_CREATE_CLIENT_SUCCESS: "CONTACTS_CREATE_CLIENT_SUCCESS",
    CONTACTS_CREATE_CLIENT_FAILURE: "CONTACTS_CREATE_CLIENT_FAILURE",

    CONTACTS_GET_CLIENTS_REQUEST: "CONTACTS_GET_CLIENTS_REQUEST",
    CONTACTS_GET_CLIENTS_SUCCESS: "CONTACTS_GET_CLIENTS_SUCCESS",
    CONTACTS_GET_CLIENTS_FAILURE: "CONTACTS_GET_CLIENTS_FAILURE",

    CONTACTS_DELETE_CLIENT_REQUEST: "CONTACTS_DELETE_CLIENT_REQUEST",
    CONTACTS_DELETE_CLIENT_SUCCESS: "CONTACTS_DELETE_CLIENT_SUCCESS",
    CONTACTS_DELETE_CLIENT_FAILURE: "CONTACTS_DELETE_CLIENT_FAILURE",

    CONTACTS_UPDATE_CLIENT_REQUEST: "CONTACTS_UPDATE_CLIENT_REQUEST",
    CONTACTS_UPDATE_CLIENT_SUCCESS: "CONTACTS_UPDATE_CLIENT_SUCCESS",
    CONTACTS_UPDATE_CLIENT_FAILURE: "CONTACTS_UPDATE_CLIENT_FAILURE",

    // Suppliers
    CONTACTS_CREATE_SUPPLIER_REQUEST: "CONTACTS_CREATE_SUPPLIER_REQUEST",
    CONTACTS_CREATE_SUPPLIER_SUCCESS: "CONTACTS_CREATE_SUPPLIER_SUCCESS",
    CONTACTS_CREATE_SUPPLIER_FAILURE: "CONTACTS_CREATE_SUPPLIER_FAILURE",

    CONTACTS_GET_SUPPLIERS_REQUEST: "CONTACTS_GET_SUPPLIERS_REQUEST",
    CONTACTS_GET_SUPPLIERS_SUCCESS: "CONTACTS_GET_SUPPLIERS_SUCCESS",
    CONTACTS_GET_SUPPLIERS_FAILURE: "CONTACTS_GET_SUPPLIERS_FAILURE",

    CONTACTS_DELETE_SUPPLIER_REQUEST: "CONTACTS_DELETE_SUPPLIER_REQUEST",
    CONTACTS_DELETE_SUPPLIER_SUCCESS: "CONTACTS_DELETE_SUPPLIER_SUCCESS",
    CONTACTS_DELETE_SUPPLIER_FAILURE: "CONTACTS_DELETE_SUPPLIER_FAILURE",

    CONTACTS_UPDATE_SUPPLIER_REQUEST: "CONTACTS_UPDATE_SUPPLIER_REQUEST",
    CONTACTS_UPDATE_SUPPLIER_SUCCESS: "CONTACTS_UPDATE_SUPPLIER_SUCCESS",
    CONTACTS_UPDATE_SUPPLIER_FAILURE: "CONTACTS_UPDATE_SUPPLIER_FAILURE",

    // Notes
    CONTACTS_CREATE_NOTE_REQUEST: "CONTACTS_CREATE_NOTE_REQUEST",
    CONTACTS_CREATE_NOTE_SUCCESS: "CONTACTS_CREATE_NOTE_SUCCESS",
    CONTACTS_CREATE_NOTE_FAILURE: "CONTACTS_CREATE_NOTE_FAILURE",

    CONTACTS_GET_NOTES_REQUEST: "CONTACTS_GET_NOTES_REQUEST",
    CONTACTS_GET_NOTES_SUCCESS: "CONTACTS_GET_NOTES_SUCCESS",
    CONTACTS_GET_NOTES_FAILURE: "CONTACTS_GET_NOTES_FAILURE",

    // CONTACTS_DELETE_NOTE_REQUEST: "CONTACTS_DELETE_NOTE_REQUEST",
    // CONTACTS_DELETE_NOTE_SUCCESS: "CONTACTS_DELETE_NOTE_SUCCESS",
    // CONTACTS_DELETE_NOTE_FAILURE: "CONTACTS_DELETE_NOTE_FAILURE",

    CONTACTS_UPDATE_NOTE_REQUEST: "CONTACTS_UPDATE_NOTE_REQUEST",
    CONTACTS_UPDATE_NOTE_SUCCESS: "CONTACTS_UPDATE_NOTE_SUCCESS",
    CONTACTS_UPDATE_NOTE_FAILURE: "CONTACTS_UPDATE_NOTE_FAILURE",

    CONTACTS_CREATE_CUSTOM_FIELDS_REQUEST: "CONTACTS_CREATE_CUSTOM_FIELDS_REQUEST",
    CONTACTS_CREATE_CUSTOM_FIELDS_SUCCESS: "CONTACTS_CREATE_CUSTOM_FIELDS_SUCCESS",
    CONTACTS_CREATE_CUSTOM_FIELDS_FAILURE: "CONTACTS_CREATE_CUSTOM_FIELDS_FAILURE",

    CONTACTS_GET_CUSTOM_FIELDS_REQUEST: "CONTACTS_GET_CUSTOM_FIELDS_REQUEST",
    CONTACTS_GET_CUSTOM_FIELDS_SUCCESS: "CONTACTS_GET_CUSTOM_FIELDS_SUCCESS",
    CONTACTS_GET_CUSTOM_FIELDS_FAILURE: "CONTACTS_GET_CUSTOM_FIELDS_FAILURE",

    CONTACTS_SEARCH_ENTITY_REQUEST: "CONTACTS_SEARCH_ENTITY_REQUEST",
    CONTACTS_SEARCH_ENTITY_SUCCESS: "CONTACTS_SEARCH_ENTITY_SUCCESS",
    CONTACTS_SEARCH_ENTITY_FAILURE: "CONTACTS_SEARCH_ENTITY_FAILURE",
};

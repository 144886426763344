import { useEffect } from "react";

/**
 * @description handles errors from the reducer.
 */
const useError = (error: any, callbacks: Array<(error: any) => void> = []) => {
    useEffect(() => {
        let times: NodeJS.Timeout[] = [];
        if (!callbacks.length || !error) return;

        callbacks.forEach((callback) => {
            times.push(setTimeout(() => callback(error), 10));
        });

        return () => {
            times.forEach((t) => clearTimeout(t));
        };
    }, [error]);
};

export default useError;

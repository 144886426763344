import { call, delay, put, takeLatest, CallEffect, SelectEffect, PutEffect } from "redux-saga/effects";
import { types } from "../reducer/types";
import { toastHelper, popupHelper, soltivoHelper } from "@soltivo/draw-a-line";
import inventoryClass from "./inventory.class";
import { sagaDisplayError } from "./saga.helper";
import history from "../../../../app/routes/history";
import { InventoryActionsTypes } from "../reducer/actions.types";
import apiV2, { ErrorResponse } from "../../../../helpers/api.v2";

/**
 * @description get inventory items
 */
function* inventoryGetEquipments() {
    try {
        const { data } = yield call(inventoryClass.inventoryGetEquipments);
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.INVENTORY_GET_EQUIPMENTS_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_FAILURE, payload: error });
    }
}

/**
 * @description get single inventory item
 */
function* inventoryGetEquipment({ payload }: InventoryActionsTypes["inventoryGetEquipment"]) {
    try {
        const { data } = yield call(inventoryClass.inventoryGetEquipment, payload);
        yield put({ type: types.INVENTORY_GET_EQUIPMENT_SUCCESS, payload: data });
    } catch (error) {
        sagaDisplayError(error, [404]);
        yield put({ type: types.INVENTORY_GET_EQUIPMENT_FAILURE, payload: error });
    }
}

/**
 * @description Create inventory item
 */
function* inventoryCreateEquipment({ payload }: InventoryActionsTypes["inventoryCreateEquipment"]) {
    try {
        const { data } = yield call(inventoryClass.inventoryCreateEquipment, payload);
        yield put({ type: types.INVENTORY_CREATE_EQUIPMENT_SUCCESS, payload: data });
        toastHelper.toastStartContent("success", "Equipment successfully created.");
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_REQUEST });
        popupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [422, 409]);
        yield put({ type: types.INVENTORY_CREATE_EQUIPMENT_FAILURE, payload: error });
    }
}

/**
 * @description Auto Update inventory item
 */
function* inventoryAutoUpdateEquipment({ payload, autoSave }: InventoryActionsTypes["inventoryAutoUpdateEquipment"]) {
    const { autoSaveAPI, fieldName } = autoSave;
    try {
        autoSaveAPI.updateByAttributeName(fieldName, {
            loading: true,
            message: "Saving",
            status: {
                type: "pending",
            },
        });
        yield delay(1500);
        yield call(inventoryClass.inventoryUpdateEquipment, payload);
        yield put({ type: types.INVENTORY_UPDATE_EQUIPMENT_SUCCESS, payload: { ...payload.item, id: payload.id } });
        autoSaveAPI.updatePending({
            loading: false,
            message: "Saved successufully.",
            status: {
                type: "success",
            },
        });
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_REQUEST });
    } catch (error) {
        if (error instanceof ErrorResponse) {
            autoSaveAPI.updateByAttributeName(fieldName, {
                loading: false,
                message: error.error.errors.length ? `${error.error.errors[0].field} ${error.error.errors[0].message}` : error.error.message || error.message,
                status: {
                    type: "failed",
                },
            });
        }
        yield put({ type: types.INVENTORY_UPDATE_EQUIPMENT_FAILURE, payload: error });
    }
}

/**
 * @description Update inventory item
 */
function* inventoryUpdateEquipment({ payload }: InventoryActionsTypes["inventoryUpdateEquipment"]) {
    try {
        yield call(inventoryClass.inventoryUpdateEquipment, payload);
        yield put({ type: types.INVENTORY_UPDATE_EQUIPMENT_SUCCESS, payload: { ...payload.item, id: payload.id } });
        popupHelper.popupEnd();
        // refresh equipments listing
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_REQUEST });
    } catch (error) {
        sagaDisplayError(error, [422, 409]);
        yield put({ type: types.INVENTORY_UPDATE_EQUIPMENT_FAILURE, payload: error });
    }
}

/**
 * @description delete inventory item
 */
function* inventoryDeleteEquipment({ payload }: { payload: string; type: string }) {
    try {
        const { data } = yield call(inventoryClass.inventoryDeleteEquipment, payload);
        yield put({ type: types.INVENTORY_DELETE_EQUIPMENT_SUCCESS, payload: data });
        toastHelper.toastStartContent("success", "Equipment successfully deleted.");
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_REQUEST });
        popupHelper.popupEnd();
        history.push("/administration/inventory/equipments");
    } catch (error) {
        sagaDisplayError(error, [404]);
        yield put({ type: types.INVENTORY_DELETE_EQUIPMENT_FAILURE, payload: error });
    }
}

/**
 * @description Archive equipments
 */
function* inventoryArchiveEquipment({ payload }: InventoryActionsTypes["inventoryArchiveEquipment"]) {
    try {
        yield call(inventoryClass.inventoryArchiveEquipment, payload);
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_REQUEST });
        yield put({ type: types.INVENTORY_ARCHIVE_EQUIPMENT_SUCCESS, payload: { isArchived: payload.isArchived } });
        toastHelper.toastStartContent("success", `Equipment ${!payload.isArchived ? "archived": "unarchived"} successfully`);
    } catch (error) {
        sagaDisplayError(error, [404]);
        yield put({ type: types.INVENTORY_GET_EQUIPMENTS_FAILURE, payload: error });
    }
}

/**
 * @description search equipments
 */
function* inventorySearchEquipments({ payload }: InventoryActionsTypes["inventorySearchEquipments"]) {
    try {
        yield delay(1000);
        const { data } = yield call(inventoryClass.inventorySearchEquipments, payload);
        yield put({ type: types.INVENTORY_SEARCH_EQUIPMENTS_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.INVENTORY_SEARCH_EQUIPMENTS_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.INVENTORY_SEARCH_EQUIPMENTS_FAILURE, payload: error });
    }
}

/**
 * @description search product
 */
function* inventorySearchProduct({ payload }: InventoryActionsTypes["inventorySearchProduct"]) {
    try {
        yield delay(1000);
        const { data } = yield call(inventoryClass.inventorySearchProduct, payload);
        yield put({ type: types.INVENTORY_SEARCH_PRODUCT_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.INVENTORY_SEARCH_PRODUCT_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.INVENTORY_SEARCH_PRODUCT_FAILURE, payload: error });
    }
}

/**
 * @description get products
 */
function* inventoryGetProducts() {
    try {
        const { data } = yield call(inventoryClass.inventoryGetProducts);
        yield put({ type: types.INVENTORY_GET_PRODUCTS_SUCCESS, payload: data });
    } catch (error: any) {
        if (error.error) {
            if (error.error.code === 404) {
                yield put({ type: types.INVENTORY_GET_PRODUCTS_SUCCESS, payload: [] });
                return;
            }
        }
        yield put({ type: types.INVENTORY_GET_PRODUCTS_FAILURE, payload: error });
    }
}

/**
 * @description Create prodcuts
 */
 function* inventoryCreateProduct({ payload }: InventoryActionsTypes["inventoryCreateProduct"]) {
    try {
        const { data } = yield call(inventoryClass.inventoryCreateProduct, payload);
        yield put({ type: types.INVENTORY_CREATE_PRODUCT_SUCCESS, payload: data });
        yield put({ type: types.INVENTORY_GET_PRODUCT_REQUEST, payload: { productId: data.id } });
        toastHelper.toastStartContent("success", "Product created successfully");
        history.push(`/administration/inventory/products/${data.id}/details`)
        popupHelper.popupEnd();
    } catch (error) {
        sagaDisplayError(error, [422, 409]);
        yield put({ type: types.INVENTORY_CREATE_PRODUCT_FAILURE, payload: error });
    }
}

/**
 * @description Update prodcuts
 */
 function* inventoryUpdateProduct({ payload, autoSave }: InventoryActionsTypes["inventoryUpdateProduct"]): Generator<CallEffect | SelectEffect | PutEffect | any, void, any> {
    const { autoSaveAPI, fieldName } = autoSave;
    try {
        autoSaveAPI.updateByAttributeName(fieldName, {
            loading: true,
            message: "Saving",
            status: {
                type: "pending",
            },
        });

        yield delay(1500);

        let { ...send } = payload;
        if (payload.image) {
            const image = yield soltivoHelper.processImageSize(payload?.image, {
                dimensions: {
                    width: 512,
                    height: 512,
                    fill: true,
                },
            });

            const { data: assignedUrl } = yield call(inventoryClass.getSignedUrl, {
                file: image,
            });

            const {
                fileLink,
            }: {
                fileLink: string;
            } = yield call(apiV2.postAssignedUrl, assignedUrl, image);

            const images = send["product"]["images"] || [];
            images.push({
                url: `${fileLink}?createdAt=${new Date().getTime()}`,
                primary: Boolean(images.length === 1)
            });
            send["product"]["images"] = images;
        }
        const { data } = yield call(inventoryClass.inventoryUpdateProduct, send);
        yield put({ type: types.INVENTORY_UPDATE_PRODUCT_SUCCESS, payload: data });

        autoSaveAPI.updatePending({
            loading: false,
            message: "Saved successufully.",
            status: {
                type: "success",
            },
        });
    } catch (error) {
        if (error instanceof ErrorResponse) {
            autoSaveAPI.updateByAttributeName(fieldName, {
                loading: false,
                message: error.error.errors.length ? `${error.error.errors[0].field} ${error.error.errors[0].message}` : error.error.message || error.message,
                status: {
                    type: "failed",
                },
            });
        }
        yield put({ type: types.INVENTORY_UPDATE_PRODUCT_FAILURE, payload: error });
    }
}

/**
 * @description get a prodcut
 */
 function* inventoryGetProduct({ payload }: InventoryActionsTypes["inventoryGetProduct"]) {
    try {
        const { data } = yield call(inventoryClass.inventoryGetProduct, payload);
        yield put({ type: types.INVENTORY_GET_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
        sagaDisplayError(error, [404]);
        yield put({ type: types.INVENTORY_GET_PRODUCT_FAILURE, payload: error });
    }
}

/**
 * @description Delete product 
 */
 function* inventoryDeleteProduct({ payload }: InventoryActionsTypes["inventoryDeleteProduct"]) {
    try {
        yield call(inventoryClass.inventoryDeleteProduct, payload);
        yield put({ type: types.INVENTORY_GET_PRODUCTS_REQUEST });
        yield put({ type: types.INVENTORY_DELETE_PRODUCT_SUCCESS });
        toastHelper.toastStartContent("success", "Product deleted successfully.");
        history.push("/administration/inventory/products");
    } catch (error) {
        sagaDisplayError(error, [404]);
        yield put({ type: types.INVENTORY_DELETE_PRODUCT_FAILURE, payload: error });
    }
}

/**
 * @description Archive prodcut
 */
 function* inventoryArchiveProduct({ payload }: InventoryActionsTypes["inventoryArchiveProduct"]) {
    try {
        yield call(inventoryClass.inventoryArchiveProduct, payload);
        yield put({ type: types.INVENTORY_GET_PRODUCTS_REQUEST });
        yield put({ type: types.INVENTORY_ARCHIVE_PRODUCT_SUCCESS });
        toastHelper.toastStartContent("success", "Product archived successfully.");
        history.push("/administration/inventory/products");
    } catch (error) {
        sagaDisplayError(error, [404]);
        yield put({ type: types.INVENTORY_ARCHIVE_PRODUCT_FAILURE, payload: error });
    }
}

export default function* InventorySaga() {
    yield takeLatest(types.INVENTORY_GET_EQUIPMENTS_REQUEST, inventoryGetEquipments);
    yield takeLatest(types.INVENTORY_GET_EQUIPMENT_REQUEST, inventoryGetEquipment);
    yield takeLatest(types.INVENTORY_CREATE_EQUIPMENT_REQUEST, inventoryCreateEquipment);
    yield takeLatest(types.INVENTORY_UPDATE_EQUIPMENT_REQUEST, inventoryUpdateEquipment);
    yield takeLatest(types.INVENTORY_AUTO_UPDATE_EQUIPMENT_REQUEST, inventoryAutoUpdateEquipment);
    yield takeLatest(types.INVENTORY_DELETE_EQUIPMENT_REQUEST, inventoryDeleteEquipment);
    yield takeLatest(types.INVENTORY_ARCHIVE_EQUIPMENT_REQUEST, inventoryArchiveEquipment);
    yield takeLatest(types.INVENTORY_SEARCH_EQUIPMENTS_REQUEST, inventorySearchEquipments);
    yield takeLatest(types.INVENTORY_SEARCH_PRODUCT_REQUEST, inventorySearchProduct);
    yield takeLatest(types.INVENTORY_GET_PRODUCTS_REQUEST, inventoryGetProducts);
    yield takeLatest(types.INVENTORY_CREATE_PRODUCT_REQUEST, inventoryCreateProduct);
    yield takeLatest(types.INVENTORY_UPDATE_PRODUCT_REQUEST, inventoryUpdateProduct);
    yield takeLatest(types.INVENTORY_GET_PRODUCT_REQUEST, inventoryGetProduct);
    yield takeLatest(types.INVENTORY_DELETE_PRODUCT_REQUEST, inventoryDeleteProduct);
    yield takeLatest(types.INVENTORY_ARCHIVE_PRODUCT_REQUEST, inventoryArchiveProduct);
}

import { FC, useContext } from "react";
import "./modulename.style.scss";
import { useLocation } from "react-router-dom";
import RoutesContext from "../../../../routes/context/routes/routes.context";
import { Button } from "@soltivo/draw-a-line";
import { Apps24Px } from "@soltivo/draw-a-line/core/components/icons";
import { HeaderContext } from "../../reducer/header.context";

interface ModuleNameProps {
    onClick: () => void;
    children: React.ReactNode;
}
const ModuleName: FC<ModuleNameProps> = ({ onClick, children }) => {
    const {
        state: { loading },
    } = useContext(RoutesContext);
    const {
        state: { showMainSidebar },
    } = useContext(HeaderContext);
    const location = useLocation();

    return (
        <div id="module" className={`${location.pathname.startsWith("/dashboard") ? "orgname--actived" : ""} ${loading ? "loading__component" : ""}`}>
            {!loading && (
                <>
                    <Button
                        variant={"secondary"}
                        padding={false}
                        outline
                        border={false}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick();
                        }}
                        className={`module--menu ${showMainSidebar ? "active" : ""}`}>
                        <Apps24Px />
                    </Button>
                    <div>
                        <p>{children ? children : ""}</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default ModuleName;

import { configureStore } from "@reduxjs/toolkit";
// import { persistStore } from 'redux-persist';
import createSagaMiddleware from "redux-saga";
import reducer from "./reducers";
import sagas from "./sagas";
// import { CacheHelper } from "./cache.helper";
// import { types as authTypes } from "../applications/auth/redux/auth/reducer/types";
// const cacheHelper = new CacheHelper();
const sagaMiddleware = createSagaMiddleware();

// const whiteList = ["ServiceReducer"];

// const indexedDbMiddleware: Middleware = (store) => (next) => async (action) => {
//     try {
//         const _STORE_STATE = store.getState();
//         if (action.type === authTypes.AUTH_GET_INDEXED_DB_DATA) {
//             //check support
//             if (cacheHelper.checkDbSupport()) {
//                 //create db context or set it up.
//                 await cacheHelper.createOrSetMainDb();
//                 if (!cacheHelper.dbInstance) return;

//                 // get stringify json state of all reducers
//                 let state: any = await cacheHelper.dbInstance?.getItem("state");
//                 if (state && typeof state === "string") {
//                     state = JSON.parse(state);
//                     console.log(state);
//                     // Create type based on the reducers name.
//                     const types = Object.keys(state).map((reducerName) => ({ name: `INDEXEDDB_${reducerName.toUpperCase()}_REQUEST`, reducer: state[reducerName] }));
//                     for (let i = 0; i < types.length; i++) {
//                         //dispatch all reducers types.
//                         store.dispatch({ type: types[i].name, payload: types[i].reducer });
//                     }
//                 }
//             }
//         }

//         //if logout remove state.
//         if (authTypes.AUTH_LOGOUT_USER_SUCCESS) {
//             cacheHelper.dbInstance?.removeItem("state");
//         }

//         if (cacheHelper.checkDbSupport() && !action.type.startsWith("INDEXEDDB")) {
//             cacheHelper.createOrSetMainDb();
//             if (cacheHelper.dbInstance?.ready()) {
//                 // Store state in every new dispatch.
//                 cacheHelper.dbInstance?.setItem("state", JSON.stringify(_STORE_STATE));
//             }
//         }

//         // go to the next dispatch.
//         return next(action);
//     } catch (err) {
//         console.error(err);
//         //go to next after issue too.
//         return next(action);
//     }
// };

export const store = configureStore({
    reducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== "production",
});

// cacheHelper.setStore(store);

// export const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export default store;

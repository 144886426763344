import { Button } from "@soltivo/draw-a-line";
import { Delete24Px, Eye } from "@soltivo/draw-a-line/core/components/icons";
import { FC } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notificationsUpdateNotification, notificationsDeleteNotifications } from "../../../../../applications/settings/redux/notifications/reducer/actions";
import styles from "./notifications.item.module.scss";
import { Notification } from "@soltivo/types";
import { RootState } from "../../../../../redux/reducers";
import useCreateAppointmentHandlers from "../../../../../applications/appointment/hooks/useCreateAppointmentHandlers";
import moment from "moment";

interface NotificationItemProps extends MapStateType {
    notification: Notification;
    closeNotifications(): void;
}

/**
 * @description notification item
 */
const NotificationItem: FC<NotificationItemProps> = ({ notification, deletingNotification, closeNotifications }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { onAppointmentDetailsPopup } = useCreateAppointmentHandlers();

    /**
     * @description redirect on notification click
     */
    const onClickNotification = (e: React.MouseEvent) => {
        e.stopPropagation();
        const { eventType, details, isRead } = notification;
        switch (eventType) {
            case "EmailReceived":
                //@ts-ignore
                history.push(`/collaboration/email/inbox/${details.messageId}`);
                break;
            case "AppointmentCreated":
                onAppointmentDetailsPopup(details.id);
                break;
            case "AppointmentRescheduled":
                onAppointmentDetailsPopup(details.id);
                break;
            case "AppointmentCancelled":
                history.push(`/collaboration/calendar/team`);
                break;
            case "LeadUpdated":
                history.push(`/sales/contacts/leads/${details.id}`);
                break;
            case "ClientUpdated":
                history.push(`/sales/contacts/clients/${details.id}`);
                break;
            default:
                break;
        }

        // update notification read status
        if (!isRead) dispatch(notificationsUpdateNotification({ id: notification.id, isRead: true }));
        closeNotifications();
    };

    const handleDeleteNotification = () => {
        dispatch(notificationsDeleteNotifications({ id: notification.id }));
    };

    const onCheckNotification = () => {
        dispatch(notificationsUpdateNotification({ id: notification.id, isRead: !notification.isRead }));
    };

    const notificationText = (() => {
        let text = notification.text;
        if (notification.attributes) {
            Object.entries(notification.attributes).forEach(([key, attribute]) => {
                if (attribute.type === "dateTime") {
                    text = text.replace(key, moment(attribute.value || "").format("LL [at] LT"));
                    return;
                }
                if (["entity", "user"].includes(attribute.type)) {
                    text = text.replace(key, attribute.fullname);
                    return;
                }
            });
        }
        return text;
    })();

    return (
        <div data-testid="notificationItem" className={`${styles.notifications__item} ${!notification.isRead ? styles.notifications__item__selected : ""}`}>
            <div onClick={onClickNotification} className={styles.notification__title}>
                <p>{notificationText}</p>
                <span data-testid="viewButton">Click to see more.</span>
            </div>
            <div className={styles.actions}>
                <Button onClick={onCheckNotification} disabled={deletingNotification} variant="geyser-900" outline padding={false}>
                    <Eye />
                </Button>
                <Button disabled={deletingNotification} onClick={handleDeleteNotification} variant="geyser-900" outline padding={false}>
                    <Delete24Px />
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = ({ NotificationReducer }: RootState) => ({
    deletingNotification: NotificationReducer.deletingNotification,
});
type MapStateType = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NotificationItem);

import React, { useState, useContext, ChangeEvent } from "react";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import { authSignIn } from "../../redux/auth/reducer/actions";
import useError from "@soltivo/draw-a-line/core/hooks/useError";
import { Uneye, Eye } from "@soltivo/draw-a-line/core/components/icons";
import "../../assets/styles/auth.style.scss";
import { Button, InputText } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeLoginForm, authOnChangeSUForm } from "../../context/actions";
import { ReactComponent as AuthImg } from "@soltivo/draw-a-line/core/assets/illutrations/SteveWithKey.svg";
import { RootState } from "../../../../redux/reducers";

interface LoginProps extends MapStateToProps {}

const Login: React.FC<LoginProps> = ({ error, loading_current_user }) => {
    const {
        state: { form },
        dispatch: dispatchContext,
    } = useContext(authContext);

    const [showPassword, setPassword] = useState(false);
    const dispatch = useDispatch();
    const [submited, setSubmited] = useState(false);

    /**
     * @description change form input values.
     */
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatchContext(
            authOnChangeLoginForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        );
        dispatchContext(
            authOnChangeSUForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        );
    };

    /**
     * @description on submit login form.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (submited) return;

        setSubmited(true);
        dispatch(authSignIn(form));
    };

    useError(error, [() => setSubmited(false)]);

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Log in to your account</h2>

                        {Object.keys(form).map((input, index) => (
                            <div key={index} className="auth-container__input-container">
                                <InputText
                                    bottomBorderStyle={true}
                                    name={input}
                                    onChange={onChange}
                                    value={form[input as ReturnType<() => keyof typeof form>]}
                                    placeholder={`${input}`}
                                    type={input === "password" ? (showPassword ? "text" : "password") : "email"}
                                    autoComplete={"off"}
                                    required
                                    className={`auth-container__input ${input === "password" ? "password" : ""}`}
                                />{" "}
                                {input === "password" ? showPassword ? <Uneye onClick={() => setPassword(!showPassword)} /> : <Eye onClick={() => setPassword(!showPassword)} /> : ""}{" "}
                            </div>
                        ))}

                        <div className="auth-container__button-container">
                            <span className="auth-container__link--create-account">
                                You don’t have an account?  {" "}
                                <Link to="/sign-up">
                                Create one here
                                </Link>
                            </span>
                        </div>

                        <Button
                            reversedPrimary={true}
                            variant="primary"
                            type="submit"
                            loading={submited || loading_current_user}
                            className="auth-container__button-primary"
                            disabled={submited || loading_current_user}>
                            Log In
                        </Button>

                        <Link className="auth-container__link--forgot" to="/forgot-password">
                            Forgot password ?
                        </Link>

                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <AuthImg className="auth-img" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
    loading_current_user: AuthReducer.loading_current_user,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Login);

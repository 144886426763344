import { useContext, useEffect } from "react";
import { guideOrgChangeContextState } from "../context/actions";
import GuideOrgContext from "../context/guide.org.context";
import { GuideOrgContextState } from "../context/reducer";

const useGuideHeading = (stepHeading: GuideOrgContextState["stepHeading"]) => {
    const { dispatch: dispatchContext } = useContext(GuideOrgContext);

    useEffect(() => {
        dispatchContext(
            guideOrgChangeContextState({
                stepHeading,
            })
        );
    }, [stepHeading.title, stepHeading.description]);
};

export default useGuideHeading;

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../redux/reducers";
import currencyLocales from "../../../helpers/json/currenciesLocales.json";
import currencies from "../../../helpers/json/currencies.json";

/**
 * @description save currency options in the localstorage
 */
export const useCurrency = (): { currency: string; symbol: string } => {
    const [symbol, setSymbol] = useState("");
    const mapStateToProps = ({ OrgReducer }: RootState) => ({
        org: OrgReducer.org,
    });

    type MapStateToProps = ReturnType<typeof mapStateToProps>;
    const { org }: MapStateToProps = useSelector(mapStateToProps);

    /**
     * @description update the local storage when the org currency changes
     */
    useEffect(() => {
        if (!org) return;
        const locale = currencyLocales.find((currency) => currency.code.toLowerCase() === org.currency)?.locale || "en-US";

        const symbol = Object.values(currencies).find(val => val.code === org.currency.toUpperCase())?.symbol_native || "";
        setSymbol(symbol);

        localStorage.setItem("currencyOptions", JSON.stringify({ locale, currency: org.currency.toUpperCase() }));
    }, [org?.currency]);
    
    return {
        currency: org?.currency.toUpperCase() || "CAD",
        symbol: symbol || "",
    };
};

import { soltivoHelper } from "@soltivo/draw-a-line";
import { AppointmentActions } from "../redux/reducer/actions.types";
import { Validation } from "@soltivo/draw-a-line/core/helpers/classes/Validation";

/**
 * @description validation for appointment.
 */
class AppointmentValidation {
    /**
     * @description form types.
     */
    public formTypes = {
        APPOINTMENT_CREATE: "APPOINTMENT_CREATE",
        APPOINTMENT_CREATE_ENTITY_INFO: "APPOINTMENT_CREATE_ENTITY_INFO",
        APPOINTMENT_CREATE_EVENT: "APPOINTMENT_CREATE_EVENT",
    } as const;

    public validation = new Validation<keyof AppointmentValidation["formTypes"]>([]);

    /**
     * @description create appointment
     */
    public appointmentCreateAppointment(payload: AppointmentActions["appointmentCreateAppointment"]["payload"]) {
        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.userId) {
            errors.push({
                fieldName: "employee",
                formName: this.formTypes.APPOINTMENT_CREATE,
                message: "Employee is required.",
                status: "unprocessable",
            });
        }

        if (!payload.locationId) {
            errors.push({
                fieldName: "location",
                formName: this.formTypes.APPOINTMENT_CREATE,
                message: "Location is required.",
                status: "unprocessable",
            });
        }

        if (!payload.serviceId) {
            errors.push({
                fieldName: "service",
                formName: this.formTypes.APPOINTMENT_CREATE,
                message: "Service is required.",
                status: "unprocessable",
            });
        }

        if (!payload.start) {
            errors.push({
                fieldName: "date",
                formName: this.formTypes.APPOINTMENT_CREATE,
                message: "Start time is required.",
                status: "unprocessable",
            });
        } else if (!payload.end) {
            errors.push({
                fieldName: "date",
                formName: this.formTypes.APPOINTMENT_CREATE,
                message: "End time is required.",
                status: "unprocessable",
            });
        }

        if (!payload.entityInfo) {
            errors.push({
                fieldName: "entityInfo",
                formName: this.formTypes.APPOINTMENT_CREATE,
                message: "Contact entity information is required.",
                status: "unprocessable",
            });
        } else {
            // const contactType = ["lead", "supplier", "client"];
            const entityInfo = payload.entityInfo;

            if (!entityInfo.firstName && !entityInfo.lastName && !entityInfo.email) {
                errors.push({
                    fieldName: "entityInfo",
                    formName: this.formTypes.APPOINTMENT_CREATE_ENTITY_INFO,
                    message: "Contact is required.",
                    status: "unprocessable",
                });
            } else if (!entityInfo.firstName) {
                errors.push({
                    fieldName: "entityInfo",
                    formName: this.formTypes.APPOINTMENT_CREATE_ENTITY_INFO,
                    message: "Contact first name is required.",
                    status: "unprocessable",
                });
            } else if (!entityInfo.lastName) {
                errors.push({
                    fieldName: "entityInfo",
                    formName: this.formTypes.APPOINTMENT_CREATE_ENTITY_INFO,
                    message: "Contact last name is required.",
                    status: "unprocessable",
                });
            } else if (!entityInfo.email) {
                errors.push({
                    fieldName: "entityInfo",
                    formName: this.formTypes.APPOINTMENT_CREATE_ENTITY_INFO,
                    message: "Contact email is required.",
                    status: "unprocessable",
                });
            } else if (soltivoHelper.validateEmail(entityInfo.email)) {
                errors.push({
                    fieldName: "entityInfo",
                    formName: this.formTypes.APPOINTMENT_CREATE_ENTITY_INFO,
                    message: "Contact email is invalid.",
                    status: "unprocessable",
                });
            } else if (typeof entityInfo.phoneNumber !== "string") {
                errors.push({
                    fieldName: "entityInfo",
                    formName: this.formTypes.APPOINTMENT_CREATE_ENTITY_INFO,
                    message: `Contact phone number must be a string.`,
                    status: "unprocessable",
                });
            }
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }

    /**
     * @description create appointment
     */
    public appointmentCreatepersonalEvent(payload: AppointmentActions["appointmentCreatepersonalEvent"]["payload"]) {

        let errors: ConstructorParameters<typeof Validation>[0] = [];

        if (!payload.title) {
            errors.push({
                fieldName: "title",
                formName: this.formTypes.APPOINTMENT_CREATE_EVENT,
                message: "You must enter a title.",
                status: "unprocessable",
            });
        }

        this.validation.set(errors);
        if (errors.length) {
            throw this.validation;
        }
    }
}

export default new AppointmentValidation();

export const types = {
    APPOINTMENT_CHANGE_STATE_REQUEST: "APPOINTMENT_CHANGE_STATE_REQUEST",
    APPOINTMENT_SET_APPOINTMENT_REQUEST: "APPOINTMENT_SET_APPOINTMENT_REQUEST",

    APPOINTMENT_GET_APPOINTMENT_REQUEST: "APPOINTMENT_GET_APPOINTMENT_REQUEST",
    APPOINTMENT_GET_APPOINTMENT_SUCCESS: "APPOINTMENT_GET_APPOINTMENT_SUCCESS",
    APPOINTMENT_GET_APPOINTMENT_FAILURE: "APPOINTMENT_GET_APPOINTMENT_FAILURE",

    APPOINTMENT_CREATE_APPOINTMENT_REQUEST: "APPOINTMENT_CREATE_APPOINTMENT_REQUEST",
    APPOINTMENT_CREATE_APPOINTMENT_SUCCESS: "APPOINTMENT_CREATE_APPOINTMENT_SUCCESS",
    APPOINTMENT_CREATE_APPOINTMENT_FAILURE: "APPOINTMENT_CREATE_APPOINTMENT_FAILURE",

    // Update the statusId of a given appointment
    APPOINTMENT_UPDATE_STATUS_REQUEST: "APPOINTMENT_UPDATE_STATUS_REQUEST", 
    APPOINTMENT_UPDATE_STATUS_KANBAN_VIEW: "APPOINTMENT_UPDATE_STATUS_KANBAN_VIEW",
    APPOINTMENT_UPDATE_STATUS_SUCCESS: "APPOINTMENT_UPDATE_STATUS_SUCCESS",
    APPOINTMENT_UPDATE_STATUS_FAILURE: "APPOINTMENT_UPDATE_STATUS_FAILURE",

    APPOINTMENT_LIST_BY_ENTITY_ID_REQUEST: "APT_GET_ENTITY_PAST_APTS_REQUEST",
    APPOINTMENT_LIST_BY_ENTITY_ID_SUCCESS: "APT_GET_ENTITY_PAST_APTS_SUCCESS",
    APPOINTMENT_LIST_BY_ENTITY_ID_FAILURE: "APT_GET_ENTITY_PAST_APTS_FAILURE",

    // Get appointment of a single column
    APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST: "APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_REQUEST",
    APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_SUCCESS: "APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_SUCCESS",
    APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_FAILURE: "APPOINTMENT_GET_APPOINTMENTS_BY_STATUS_FAILURE",

    // Get available appointments dates
    APPOINTMENT_GET_AVAILABLE_DATES_REQUEST: "APPOINTMENT_GET_AVAILABLE_DATES_REQUEST",
    APPOINTMENT_GET_AVAILABLE_DATES_SUCCESS: "APPOINTMENT_GET_AVAILABLE_DATES_SUCCESS",
    APPOINTMENT_GET_AVAILABLE_DATES_FAILURE: "APPOINTMENT_GET_AVAILABLE_DATES_FAILURE",

    APPOINTMENT_GET_QUICK_DATES_REQUEST: "APPOINTMENT_GET_QUICK_DATES_REQUEST",
    APPOINTMENT_GET_QUICK_DATES_SUCCESS: "APPOINTMENT_GET_QUICK_DATES_SUCCESS",
    APPOINTMENT_GET_QUICK_DATES_FAILURE: "APPOINTMENT_GET_QUICK_DATES_FAILURE",

    APPOINTMENT_GET_SLOTS_REQUEST: "APPOINTMENT_GET_SLOTS_REQUEST",
    APPOINTMENT_GET_SLOTS_SUCCESS: "APPOINTMENT_GET_SLOTS_SUCCESS",
    APPOINTMENT_GET_SLOTS_FAILURE: "APPOINTMENT_GET_SLOTS_FAILURE",

    APPOINTMENT_BULK_UPDATE_APPOINTMENT_REQUEST: "APPOINTMENT_BULK_UPDATE_APPOINTMENT_REQUEST",
    APPOINTMENT_BULK_UPDATE_APPOINTMENT_SUCCESS: "APPOINTMENT_BULK_UPDATE_APPOINTMENT_SUCCESS",
    APPOINTMENT_BULK_UPDATE_APPOINTMENT_FAILURE: "APPOINTMENT_BULK_UPDATE_APPOINTMENT_FAILURE",

    // APPOINTMENT_GET_APPOINTMENT_STATUSES_REQUEST: "APPOINTMENT_GET_APPOINTMENT_STATUSES_REQUEST",
    // APPOINTMENT_GET_APPOINTMENT_STATUSES_SUCCESS: "APPOINTMENT_GET_APPOINTMENT_STATUSES_SUCCESS",
    // APPOINTMENT_GET_APPOINTMENT_STATUSES_FAILURE: "APPOINTMENT_GET_APPOINTMENT_STATUSES_FAILURE",

    // APPOINTMENT_CREATE_STATUS_REQUEST: "APPOINTMENT_CREATE_STATUS_REQUEST",
    // APPOINTMENT_CREATE_STATUS_SUCCESS: "APPOINTMENT_CREATE_STATUS_SUCCESS",
    // APPOINTMENT_CREATE_STATUS_FAILURE: "APPOINTMENT_CREATE_STATUS_FAILURE",

    // APPOINTMENT_UPDATE_STATUS_NAME_REQUEST: "APPOINTMENT_UPDATE_STATUS_NAME_REQUEST",
    // APPOINTMENT_UPDATE_STATUS_NAME_SUCCESS: "APPOINTMENT_UPDATE_STATUS_NAME_SUCCESS",
    // APPOINTMENT_UPDATE_STATUS_NAME_FAILURE: "APPOINTMENT_UPDATE_STATUS_NAME_FAILURE",

    // APPOINTMENT_ARRANGE_STATUSES_REQUEST: "APPOINTMENT_ARRANGE_STATUSES_REQUEST",
    // APPOINTMENT_ARRANGE_STATUSES_SUCCESS: "APPOINTMENT_ARRANGE_STATUSES_SUCCESS",
    // APPOINTMENT_ARRANGE_STATUSES_FAILURE: "APPOINTMENT_ARRANGE_STATUSES_FAILURE",

    // APPOINTMENT_DELETE_STATUS_REQUEST: "APPOINTMENT_DELETE_STATUS_REQUEST",
    // APPOINTMENT_DELETE_STATUS_SUCCESS: "APPOINTMENT_DELETE_STATUS_SUCCESS",
    // APPOINTMENT_DELETE_STATUS_FAILURE: "APPOINTMENT_DELETE_STATUS_FAILURE",

    APPOINTMENT_LIST_NOTE_REQUEST: "APPOINTMENT_LIST_NOTE_REQUEST",
    APPOINTMENT_LIST_NOTE_SUCCESS: "APPOINTMENT_LIST_NOTE_SUCCESS",
    APPOINTMENT_LIST_NOTE_FAILURE: "APPOINTMENT_LIST_NOTE_FAILURE",

    APPOINTMENT_CREATE_NOTE_REQUEST: "APPOINTMENT_CREATE_NOTE_REQUEST",
    APPOINTMENT_CREATE_NOTE_SUCCESS: "APPOINTMENT_CREATE_NOTE_SUCCESS",
    APPOINTMENT_CREATE_NOTE_FAILURE: "APPOINTMENT_CREATE_NOTE_FAILURE",

    APPOINTMENT_UPDATE_NOTE_REQUEST: "APPOINTMENT_UPDATE_NOTE_REQUEST",
    APPOINTMENT_UPDATE_NOTE_SUCCESS: "APPOINTMENT_UPDATE_NOTE_SUCCESS",
    APPOINTMENT_UPDATE_NOTE_FAILURE: "APPOINTMENT_UPDATE_NOTE_FAILURE",

    APPOINTMENT_GET_PERSONAL_APPOINTMENTS_REQUEST: "APPOINTMENT_GET_PERSONAL_APPOINTMENTS_REQUEST",
    APPOINTMENT_GET_PERSONAL_APPOINTMENTS_SUCCESS: "APPOINTMENT_GET_PERSONAL_APPOINTMENTS_SUCCESS",
    APPOINTMENT_GET_PERSONAL_APPOINTMENTS_FAILURE: "APPOINTMENT_GET_PERSONAL_APPOINTMENTS_FAILURE",

    APPOINTMENT_DELETE_APPOINTMENT_REQUEST: "APPOINTMENT_DELETE_APPOINTMENT_REQUEST",
    APPOINTMENT_DELETE_APPOINTMENT_SUCCESS: "APPOINTMENT_DELETE_APPOINTMENT_SUCCESS",
    APPOINTMENT_DELETE_APPOINTMENT_FAILURE: "APPOINTMENT_DELETE_APPOINTMENT_FAILURE",

    APPOINTMENT_ARCHIVE_APPOINTMENTS_REQUEST: "APPOINTMENT_ARCHIVE_APPOINTMENTS_REQUEST",
    APPOINTMENT_ARCHIVE_APPOINTMENTS_SUCCESS: "APPOINTMENT_ARCHIVE_APPOINTMENTS_SUCCESS",
    APPOINTMENT_ARCHIVE_APPOINTMENTS_FAILURE: "APPOINTMENT_ARCHIVE_APPOINTMENTS_FAILURE",

    APPOINTMENT_ARCHIVE_APPOINTMENT_REQUEST: "APPOINTMENT_ARCHIVE_APPOINTMENT_REQUEST",
    APPOINTMENT_ARCHIVE_APPOINTMENT_SUCCESS: "APPOINTMENT_ARCHIVE_APPOINTMENT_SUCCESS",
    APPOINTMENT_ARCHIVE_APPOINTMENT_FAILURE: "APPOINTMENT_ARCHIVE_APPOINTMENT_FAILURE",

    APPOINTMENT_CREATE_PERSONAL_EVENT_REQUEST: "APPOINTMENT_CREATE_PERSONAL_EVENT_REQUEST",
    APPOINTMENT_CREATE_PERSONAL_EVENT_SUCCESS: "APPOINTMENT_CREATE_PERSONAL_EVENT_SUCCESS",
    APPOINTMENT_CREATE_PERSONAL_EVENT_FAILURE: "APPOINTMENT_CREATE_PERSONAL_EVENT_FAILURE",

    APPOINTMENT_GET_TEAM_EVENTS_REQUEST: "APPOINTMENT_GET_TEAM_EVENTS_REQUEST",
    APPOINTMENT_GET_TEAM_EVENTS_SUCCESS: "APPOINTMENT_GET_TEAM_EVENTS_SUCCESS",
    APPOINTMENT_GET_TEAM_EVENTS_FAILURE: "APPOINTMENT_GET_TEAM_EVENTS_FAILURE",

    APPOINTMENT_UPDATE_EVENT_REQUEST: "APPOINTMENT_UPDATE_EVENT_REQUEST",
    APPOINTMENT_UPDATE_EVENT_SUCCESS: "APPOINTMENT_UPDATE_EVENT_SUCCESS",
    APPOINTMENT_UPDATE_EVENT_FAILURE: "APPOINTMENT_UPDATE_EVENT_FAILURE",

    APPOINTMENT_DELETE_EVENT_REQUEST: "APPOINTMENT_DELETE_EVENT_REQUEST",
    APPOINTMENT_DELETE_EVENT_SUCCESS: "APPOINTMENT_DELETE_EVENT_SUCCESS",
    APPOINTMENT_DELETE_EVENT_FAILURE: "APPOINTMENT_DELETE_EVENT_FAILURE",

    APPOINTMENT_RESCHEDULE_APPOINTMENT_REQUEST: "APPOINTMENT_RESCHEDULE_APPOINTMENT_REQUEST",
    APPOINTMENT_RESCHEDULE_APPOINTMENT_SUCCESS: "APPOINTMENT_RESCHEDULE_APPOINTMENT_SUCCESS",
    APPOINTMENT_RESCHEDULE_APPOINTMENT_FAILURE: "APPOINTMENT_RESCHEDULE_APPOINTMENT_FAILURE",
};

import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import "../../assets/styles/auth.style.scss";
import { Redirect, useHistory } from "react-router-dom";
import { Button, InputText } from "@soltivo/draw-a-line";
import authContext from "../../context/auth.context";
import { authOnChangeFPForm } from "../../context/actions";
import { ReactComponent as SteveTypePhone } from "@soltivo/draw-a-line/core/assets/illutrations/SteveTypePhone.svg";
import { RootState } from "../../../../redux/reducers";

interface PhoneValidationCodeProps extends MapStateToProps {}

const PhoneValidationCode: React.FC<PhoneValidationCodeProps> = () => {
    const history = useHistory();
    const {
        state: { FPForm: form },
        dispatch: dispatchContext,
    } = useContext(authContext);

    const [submited] = useState(false);
    const CODE_LENGTH = new Array(6).fill(0);
    const [value, setValue] = useState("");
    const [focused, setFocused] = useState(false);
    const inputRef = React.createRef<HTMLInputElement>();

    const handleClick = () => {
        inputRef.current?.focus();
    };

    const handleFocused = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Backspace") {
            setValue(value.slice(0, value.length - 1));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event.target.value;
        setValue(value.length >= CODE_LENGTH.length ? value : (value + _value).slice(0, CODE_LENGTH.length));
    };

    const values = value.split("");

    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

    /**
     * @description on submit code.
     */
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (value.length < 6) {
            toastHelper.toastStartContent("danger", "Code should have 6 characters.");
        } else if (!form.email) {
            toastHelper.toastStartContent("danger", "Email not found, please go back and enter your email again.");
        } else {
            dispatchContext(authOnChangeFPForm({ ...form, code: value }));
            history.push("/forgot-password-reset");
        }
    };

    if (!form.email) {
        return <Redirect to="/forgot-password" />;
    }

    return (
        <div className="auth-container">
            <div className="row">
                <div className="column col-12 col-lg-6">
                    <form onSubmit={onSubmit} className="auth-container__card">
                        <h2 className="auth-container__title">Check your SMS for the verification code</h2>

                        <div className="auth-container__input-container-code" onClick={handleClick}>
                            <InputText
                                type="number"
                                value=""
                                ref={inputRef}
                                bottomBorderStyle={true}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                onFocus={handleFocused}
                                onBlur={handleBlur}
                                className="auth-container__input-code"
                                style={{
                                    width: "32px",
                                    top: "0px",
                                    bottom: "0px",
                                    left: `${selectedIndex * 50}px`,
                                }}
                            />
                            {CODE_LENGTH.map((v, index) => {
                                const selected = values.length === index;
                                const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                return (
                                    <div key={index} className="auth-container__code-box">
                                        {values[index]}
                                        {(selected || filled) && focused && <div className="shadows" />}
                                    </div>
                                );
                            })}
                        </div>

                        <p className="auth-container__description">An SMS code was sent to your account phone number.</p>

                        <Button reversedPrimary={true} variant="primary" type="submit" loading={submited} className="auth-container__button-primary" disabled={submited}>
                            Validate
                        </Button>
                    </form>
                </div>

                <div className="column col-12 col-lg-6 img-container">
                    <SteveTypePhone />
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({ AuthReducer }: RootState) => ({
    error: AuthReducer.error,
});
type MapStateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(PhoneValidationCode);

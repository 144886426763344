import { useDropContainer } from "@soltivo/draw-a-line";
import React, { useState } from "react";
import styles from "./input.time.module.scss";

interface InputTimeV2Props extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
    onChange?: (e: { value: string; name?: string }) => void;
    bordered?: "true";
    timeFormat?: "24hours" | "12hours" | "";
}

/**
 * @description csutom input time for location business setup.
 */
const InputTimeV2 = ({ placeholder = "time", timeFormat = "24hours", ...rest }: InputTimeV2Props) => {
    const { containerRef, droppedRef, dropState, onDropdown } = useDropContainer({
        centeredX: true,
        containerGap: 5,
        withParentWidth: true,
    });

    const hours =
        timeFormat === "12hours"
            ? ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
            : ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const mins = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
    const allHours = hours
        .map((hour, index) =>
            mins.map((min) => {
                let _hour = hour + ":" + min;
                if (timeFormat === "12hours") _hour = index > 12 ? (_hour += "pm") : (_hour += "am");
                return _hour;
            })
        )
        .reduce((prev, current) => [...prev, ...current]);
    allHours.push("24:00");

    const [hoursOptions, setHoursOptions] = useState<string[]>([]);

    const onChangeValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        let val = e.currentTarget.value;

        if (val.length <= 5) {
            if (/^[0-9:]*$/.test(val)) {
                if (parseInt(val.slice(0, 2)) > 24) {
                    return;
                } else if (parseInt(val.slice(3, 5)) > 59) {
                    return;
                } else if (parseInt(val.slice(0, 2)) === 24 && parseInt(val.slice(3, 5)) > 0) {
                    return;
                } else if (val === "" || val.length <= 5) {
                    if (parseInt(val.slice(0, 1)) > 2) {
                        e.target.value = `0${val}`;
                    }

                    e.target.value = `${val.length <= 2 ? val : `${val.slice(0, 2)}:${val.slice(3, val.length)}`}`;
                    if (rest.onChange) rest.onChange({ value: e.target.value, name: rest.name });
                }
            }
        } else if (val.length <= 7) {
            // Give the possibility to the user to enter am or pm manually at the end of the time entered
            if (timeFormat === "24hours") return;
            const val = e.currentTarget.value;
            if (/^[a-z]+$/.test(val.slice(5, 7))) {
                if (!["a", "p"].includes(val[5].toLowerCase())) return;
                if (val.length > 6 && val[6].toLowerCase() !== "m") return;
                if (rest.onChange) rest.onChange({ value: e.target.value, name: rest.name });
            } else {
                e.target.value = val.slice(0, val.length - 1);
            }
        }

        let options = allHours.filter((hour) => hour.startsWith(e.target.value.slice(0, 4)));
        const options2 = allHours.filter((hour) => hour.includes(e.target.value));
        if (timeFormat === "24hours") {
            setHoursOptions(!val.length || val.length >= 5 ? [] : options.length ? options : options2);
        } else if (val.length && val.length <= 2) {
            setHoursOptions(options.length ? options : options2);
        } else {
            setHoursOptions(!val.length || val.length >= 7 ? [] : options2.length ? options2 : options);
        }
        onDropdown(Boolean(val.length));
    };

    return (
        <div className={styles.input__wrapper} ref={containerRef}>
            <input {...rest} className={`${styles.input__time} ${rest.bordered ? styles.input__time__border : ""}`} onChange={onChangeValue} placeholder={placeholder} autoComplete="off" />
            <ul className={`${hoursOptions.length && dropState.drop ? styles.show : ""} scroll-bluemine`} ref={droppedRef}>
                {hoursOptions.map((hour, index) => (
                    <li
                        key={index}
                        onClick={() => {
                            if (rest.onChange) rest.onChange({ value: hour, name: rest.name });
                            setHoursOptions([]);
                        }}>
                        {hour}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InputTimeV2;

import { PaymentResponse, Tax, discountTypes, Invoice } from "@soltivo/types";
import { SalesState } from "./reducer";
import { types } from "./types";

/**
 * @description on change sales state
 * @warning be careful using this action you could break the application.
 */
export const salesChangeState = (payload: Partial<SalesState>) => ({
    type: types.SALES_CHANGE_STATE_REQUEST,
    payload,
});

/**
 * @description get all transactions
 */
export const salesGetTransactions = () => ({
    type: types.SALES_GET_TRANSACTIONS_REQUEST,
});

/**
 * @description get all payments
 */
export const salesGetPayments = () => ({
    type: types.SALES_GET_PAYMENTS_REQUEST,
});

/**
 * @description get transaction by id
 */
export const salesGetTransaction = (payload: { transactionId: string }) => ({
    type: types.SALES_GET_TRANSACTION_REQUEST,
    payload,
});

export const salesUpdateInvoiceNote = (payload: { note: string; transactionId: string }) => ({
    type: types.SALES_UPDATE_INVOICE_NOTE_REQUEST,
    payload,
});

export const salesVerifyStripeConnect = () => ({
    type: types.SALES_VERIFY_STRIPE_CONNECT_REQUEST,
});

export const salesLinkStripeConnect = () => ({
    type: types.SALES_LINK_STRIPE_CONNECT_REQUEST,
});

export const salesGetLastSixMonthSales = (payload: { lastMonth: string, last6Month: string }) => ({
    type: types.SALES_GET_SIX_MONTHS_SALES_REQUEST,
    payload
});

export const salesGetMonthlyIncome = (payload: { month: string; year: string }) => ({
    type: types.SALES_GET_MONTHLY_INCOME_REQUEST,
    payload
});

export const salesGetWeeklyIncome = (payload: { startDate: string; endDate: string }) => ({
    type: types.SALES_GET_WEEKLY_INCOME_REQUEST,
    payload
});

export const salesGetTransactionsMetrics = (payload: { unPaidStartDate: string; paidStartDate: string; date: string }) => ({
    type: types.SALES_GET_TRANSACTIONS_METRICS_REQUEST,
    payload
});

export const salesCreateInvoice = (payload: { 
    invoice: {
        issuedDate: string;
        dueDate: string;
        ref: number;
        employee: string;
        location: string;
        subtotal: number;
        total: number;
        discount: {
            type: discountTypes;
            amount: number;
        };
        reference: string;
        contact: Invoice["contact"];
        items: {
            id: string;
            name: string;
            description: string;
            quantity: number;
            unitPrice: number;
            tax?: Tax;
            total: number;
        }[];
        isPartial: boolean;
        notes?: string;
        isDraft?: boolean;
        sendEmail: boolean;
    };
    nextAction: 'send' | 'close' | 'recordPayment';
 }) => ({
    type: types.SALES_CREATE_INVOICE_REQUEST,
    payload
});

/**
 * @description get invoice
 */
 export const salesGetInvoice = (payload: { invoiceId: string }) => ({
    type: types.SALES_GET_INVOICE_REQUEST,
    payload,
});

/**
 * @description get invoices
*/
 export const salesGetInvoices = () => ({
    type: types.SALES_GET_INVOICES_REQUEST,
});


/**
 * @description update invoice
*/
 export const salesUpdateInvoice = (payload: {
    invoice: {
        issuedDate: string;
        dueDate: string;
        ref: number;
        employee: string;
        location: string;
        subtotal: number;
        total: number;
        discount: {
            type: discountTypes,
            amount: number;
        }
        reference: string;
        contact: Invoice["contact"];
        items: {
            id: string;
            name: string;
            description: string;
            quantity: number;
            unitPrice: number;
            tax?: Tax;
            total: number;
        }[];
        isPartial: boolean;
        notes?: string;
    },
    nextAction?: 'close' | 'send';
    invoiceId: string;
 }) => ({
    type: types.SALES_UPDATE_INVOICE_REQUEST,
    payload
});

/**
 * @description update invoice
*/
export const salesCreatePayment = (payload: {
    paymentData: {
        items: {
            id: string;
            amount: number;
        }[];
        ref: number;
        paymentDate: string;
        contact: Invoice["contact"];
        method: PaymentResponse['method'];
        amount: number;
        notes?: string;
    };
    nextAction?: 'close' | 'send' | 'new';
    sendEmail: boolean;
 }) => ({
    type: types.SALES_CREATE_PAYMENT_REQUEST,
    payload
});

/**
 * @description update invoice
*/
export const salesUpdatePayment = (payload: {
    payment: {
        items: {
            id: string;
            amount: number;
        }[];
        ref: number;
        paymentDate: string;
        contact: Invoice["contact"];
        method: PaymentResponse['method'];
        amount: number;
        notes?: string;
    },
    nextAction?: "close" | "send" | "new";
    transactionId: string;
 }) => ({
    type: types.SALES_UPDATE_PAYMENT_REQUEST,
    payload
});

/**
 * @description get invoices
*/
export const salesGetInvoicesInPayment = (payload: { paymentId: string }) => ({
    type: types.SALES_GET_INVOICES_IN_PAYMENT_REQUEST,
    payload,
});

/**
 * @description void payment
*/
export const salesVoidPayment = (payload: { paymentId: string }) => ({
    type: types.SALES_VOID_PAYMENT_REQUEST,
    payload,
});

/**
 * @description get next invoice number
*/
 export const salesGetNextInvoiceNumber = () => ({
    type: types.SALES_GET_NEXT_INVOICE_NUMBER_REQUEST,
});

/**
 * @description get next payment number
*/
 export const salesGetNextPaymentNumber = () => ({
    type: types.SALES_GET_NEXT_PAYMENT_NUMBER_REQUEST,
});

/**
 * @description get next refund number
*/
 export const salesGetNextRefundNumber = () => ({
    type: types.SALES_GET_NEXT_REFUND_NUMBER_REQUEST,
});

/**
 * @description get configurations
*/
export const salesGetConfigurations = () => ({
    type: types.SALES_GET_CONFIGURATIONS_REQUEST,
});

/**
 * @description update configurations
*/
export const salesUpdateConfigurations = (payload: {
    senderEmail: string;
    invoice: {
        terms: string;
        notes: string;
        isPartial: boolean;
    }
}) => ({
    type: types.SALES_UPDATE_CONFIGURATIONS_REQUEST,
    payload
});

/**
 * @description Create tax
*/
export const salesCreateTax = (payload: {
    displayName: string;
    totalRate: number;
    agency: string;
    components: {
        displayName: string;
        rate: number;
        type: string;
    }[];
}) => ({
    type: types.SALES_CREATE_TAX_REQUEST,
    payload
});

/**
 * @description Update tax
*/
export const salesUpdateTax = (payload: {
    tax: {
        displayName: string;
        totalRate: number;
        agency: string;
        components: {
            displayName: string;
            rate: number;
            type: string;
            id?: string;
        }[];
    },
    taxId: string;
}) => ({
    type: types.SALES_UPDATE_TAX_REQUEST,
    payload
});

/**
 * @description Create refund
*/
export const salesCreateRefund = (payload: {
    refund: {
        ref: number;
        amount: number
        refundDate: string;
        invoiceId: string;
        paymentId: string;
        method: string;
        contact: Invoice["contact"];
        items: {
            id: string;
            name: string;
            description: string;
            quantity: number;
            unitPrice: number;
            tax?: Tax;
            total: number;
            amount: number;
        }[];
        notes: string;
    },
    nextAction: "close" | "send";
    sendEmail: boolean;
}) => ({
    type: types.SALES_CREATE_REFUND_REQUEST,
    payload
});

/**
 * @description Create online refund
*/
export const salesCreateOnlineRefund = (payload: {
    invoiceId: string;
    paymentId: string;
    contact: Invoice["contact"];
}) => ({
    type: types.SALES_CREATE_ONLINE_REFUND_REQUEST,
    payload
});

/**
 * @description Create terminal refund
*/
export const salesCreateTerminalRefund = (payload: {
    amount: number;
    invoiceId: string;
    paymentId: string;
    contact: Invoice["contact"];
}) => ({
    type: types.SALES_CREATE_TERMINAL_REFUND_REQUEST,
    payload
});

/**
 * @description Update refund
*/
export const salesUpdateRefund = (payload: {
    refund: {
        ref: number;
        amount: number
        refundDate: string;
        invoiceId: string;
        paymentId: string;
        method: string;
        contact: Invoice["contact"];
        items: {
            id: string;
            name: string;
            description: string;
            quantity: number;
            unitPrice: number;
            tax?: Tax;
            total: number;
            amount: number;
        }[];
        notes: string;
    },
    refundId: string;
    nextAction: "close" | "send";
}) => ({
    type: types.SALES_UPDATE_REFUND_REQUEST,
    payload
});

/**
 * @description void invoice
*/
export const salesVoidInvoice = (payload: { invoiceId: string }) => ({
    type: types.SALES_VOID_INVOICE_REQUEST,
    payload,
});

/**
 * @description void refund
*/
export const salesVoidRefund = (payload: { refundId: string }) => ({
    type: types.SALES_VOID_REFUND_REQUEST,
    payload,
});

/**
 * @description get invoices
*/
export const salesGetRefund = (payload: { refundId: string }) => ({
    type: types.SALES_GET_REFUND_REQUEST,
    payload,
});

/**
 * @description send invoices/payments/refunds by email
*/
export const salesSendEmail = (payload: { id: string, type: 'invoices' | 'payments' | 'refunds', showToast?: boolean }) => ({
    type: types.SALES_SEND_EMAIL_REQUEST,
    payload,
});

/**
 * @description get all taxes
*/
export const salesGetAllTaxes = () => ({
    type: types.SALES_GET_ALL_TAXES_REQUEST
});

/**
 * @description get tax
*/
export const salesGetTax = (payload: { taxId: string }) => ({
    type: types.SALES_GET_TAX_REQUEST,
    payload,
});

/**
 * @description Invoice calculator
*/
export const salesInvoiceCalculator = (payload: { 
    items: {
        service: string;
        description: string;
        quantity: number;
        unitPrice: number;
        total: number;
        tax?: Tax;
    }[],
    discount: {
        type: discountTypes;
        amount: number;
    };
 }) => ({
    type: types.SALES_INVOICE_CALCULATOR_REQUEST,
    payload,
});

/**
 * @description Update business number
*/
export const salesUpdateBusinessNumber = (payload: { 
    registrationNumber: string;
    QST: string;
    RST: string;
    GST: string;
 }) => ({
    type: types.SALES_UPDATE_BUSINESS_NUMBER_REQUEST,
    payload,
});

/**
 * @description Search invoices
*/
export const salesSearchInvoices = (payload: { 
    mainFilter: {
        type: 'customer' | 'status';
        value: string;
    },
    secondaryFilters?: [
        {
            type: 'customer' | 'status';
            values: string[];
        }
    ]
 }) => ({
    type: types.SALES_SEARCH_INVOICES_REQUEST,
    payload,
});

/**
 * @description List stripe locations
*/
export const salesGetStripeLocations = () => ({
    type: types.SALES_LIST_STRIPE_LOCATIONS_REQUEST,
});

/**
 * @description Register terminal
*/
export const salesRegisterTerminal = (payload: { 
    terminal: {
        label: string;
        registrationCode: string;
        stripeLocationId: string;
    };
    loadTerminals?: boolean;
 }) => ({
    type: types.SALES_REGISTER_TERMINAL_REQUEST,
    payload,
});

/**
 * @description Create stripe location
*/
export const salesCreateStripeLocation = (payload: {
    locationId: string;
 }) => ({
    type: types.SALES_CREATE_STRIPE_LOCATIONS_REQUEST,
    payload,
});

/**
 * @description List terminal in a location
*/
export const salesGetLocationTerminals = (payload: { stripeLocationId: string }) => ({
    type: types.SALES_GET_LOCATION_TERMINALS_REQUEST,
    payload
});

/**
 * @description Edit terminal label
*/
export const salesEditTerminalLabel = (payload: { stripeTerminalId: string, label: string }) => ({
    type: types.SALES_EDIT_TERMINAL_LABEL_REQUEST,
    payload
});

/**
 * @description delete terminal
*/
export const salesDeleteTerminal = (payload: { stripeTerminalId: string }) => ({
    type: types.SALES_REMOVE_TERMINAL_REQUEST,
    payload
});

/**
 * @description delete stripe location
*/
export const salesDeleteStripeLocation = (payload: { stripeLocationId: string }) => ({
    type: types.SALES_REMOVE_STRIPE_LOCATIONS_REQUEST,
    payload
});

/**
 * @description delete terminal
*/
export const salesCheckTerminalConfiguration = () => ({
    type: types.SALES_VERIFY_TERMINAL_CONFIGURATION_REQUEST,
});

/**
 * @description create payment object
*/
export const salesCreatePaymentObject = (payload: { 
    paymentIntentId: string; 
    invoiceId: string 
}) => ({
    type: types.SALES_CREATE_PAYMENT_OBJECT_REQUEST,
    payload
});
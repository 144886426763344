import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { User } from '../auth';
import { RolePermissions, RoleModules } from '../../hrm/hrm';

/** 
 @description return user permissions
*/
const useRolePermissions = (appName?: RoleModules ) => {
    const mapStateToProps = ({ AuthReducer }: RootState) => ({
        user: AuthReducer.user as User,
    });

    const { user } = useSelector(mapStateToProps);
    const [rolePermissions, setRolePermissions] = useState<RolePermissions[RoleModules] | RolePermissions>();

    useEffect(() => {
        if(user?.roleInfo) {
            if(appName) {
                setRolePermissions(user.roleInfo.permissions[appName]);
            } else {
                setRolePermissions(user.roleInfo.permissions);
            }
        }
    }, [user]);

    return rolePermissions || undefined;
}

export default useRolePermissions;
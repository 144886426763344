export const types = {
    BILLINGS_CHANGE_STATE: "BILLINGS_CHANGE_STATE",

    BILLINGS_GET_PLAN_REQUEST: "BILLINGS_GET_PLAN_REQUEST",
    BILLINGS_GET_PLAN_FAILURE: "BILLINGS_GET_PLAN_FAILURE",
    BILLINGS_GET_PLAN_SUCCESS: "BILLINGS_GET_PLAN_SUCCESS",

    BILLINGS_CREATE_PLAN_REQUEST: "BILLINGS_CREATE_PLAN_REQUEST",
    BILLINGS_CREATE_PLAN_FAILURE: "BILLINGS_CREATE_PLAN_FAILURE",
    BILLINGS_CREATE_PLAN_SUCCESS: "BILLINGS_CREATE_PLAN_SUCCESS",

    BILLINGS_GET_CUSTOMER_REQUEST: "BILLINGS_GET_CUSTOMER_REQUEST",
    BILLINGS_GET_CUSTOMER_FAILURE: "BILLINGS_GET_CUSTOMER_FAILURE",
    BILLINGS_GET_CUSTOMER_SUCCESS: "BILLINGS_GET_CUSTOMER_SUCCESS",

    BILLINGS_GET_CUSTOMERS_REQUEST: "BILLINGS_GET_CUSTOMERS_REQUEST",
    BILLINGS_GET_CUSTOMERS_FAILURE: "BILLINGS_GET_CUSTOMERS_FAILURE",
    BILLINGS_GET_CUSTOMERS_SUCCESS: "BILLINGS_GET_CUSTOMERS_SUCCESS",

    BILLINGS_UPDATE_CUSTOMER_REQUEST: "BILLINGS_UPDATE_CUSTOMER_REQUEST",
    BILLINGS_UPDATE_CUSTOMER_FAILURE: "BILLINGS_UPDATE_CUSTOMER_FAILURE",
    BILLINGS_UPDATE_CUSTOMER_SUCCESS: "BILLINGS_UPDATE_CUSTOMER_SUCCESS",

    BILLINGS_GET_INVOICE_PDF_REQUEST: "BILLINGS_GET_INVOICE_PDF_REQUEST",
    BILLINGS_GET_INVOICE_PDF_FAILURE: "BILLINGS_GET_INVOICE_PDF_FAILURE",
    BILLINGS_GET_INVOICE_PDF_SUCCESS: "BILLINGS_GET_INVOICE_PDF_SUCCESS",

    BILLINGS_GET_NEXT_INVOICE_PAYMENT_REQUEST: "BILLINGS_GET_NEXT_INVOICE_PAYMENT_REQUEST",
    BILLINGS_GET_NEXT_INVOICE_PAYMENT_FAILURE: "BILLINGS_GET_NEXT_INVOICE_PAYMENT_FAILURE",
    BILLINGS_GET_NEXT_INVOICE_PAYMENT_SUCCESS: "BILLINGS_GET_NEXT_INVOICE_PAYMENT_SUCCESS",

    BILLINGS_GET_INVOICES_REQUEST: "BILLINGS_GET_INVOICES_REQUEST",
    BILLINGS_GET_INVOICES_FAILURE: "BILLINGS_GET_INVOICES_FAILURE",
    BILLINGS_GET_INVOICES_SUCCESS: "BILLINGS_GET_INVOICES_SUCCESS",

    BILLINGS_GET_INVOICE_REQUEST: "BILLINGS_GET_INVOICE_REQUEST",
    BILLINGS_GET_INVOICE_FAILURE: "BILLINGS_GET_INVOICE_FAILURE",
    BILLINGS_GET_INVOICE_SUCCESS: "BILLINGS_GET_INVOICE_SUCCESS",

    BILLINGS_GET_SUBSCRIPTIONS_REQUEST: "BILLINGS_GET_SUBSCRIPTIONS_REQUEST",
    BILLINGS_GET_SUBSCRIPTIONS_FAILURE: "BILLINGS_GET_SUBSCRIPTIONS_FAILURE",
    BILLINGS_GET_SUBSCRIPTIONS_SUCCESS: "BILLINGS_GET_SUBSCRIPTIONS_SUCCESS",

    BILLINGS_GET_PUBLIC_SUBSCRIPTION_REQUEST: "BILLINGS_GET_PUBLIC_SUBSCRIPTION_REQUEST",
    BILLINGS_GET_PUBLIC_SUBSCRIPTION_FAILURE: "BILLINGS_GET_PUBLIC_SUBSCRIPTION_FAILURE",
    BILLINGS_GET_PUBLIC_SUBSCRIPTION_SUCCESS: "BILLINGS_GET_PUBLIC_SUBSCRIPTION_SUCCESS",

    BILLINGS_ATTACH_PAYMENT_METHOD_REQUEST: "BILLINGS_ATTACH_PAYMENT_METHOD_REQUEST",
    BILLINGS_ATTACH_PAYMENT_METHOD_FAILURE: "BILLINGS_ATTACH_PAYMENT_METHOD_FAILURE",
    BILLINGS_ATTACH_PAYMENT_METHOD_SUCCESS: "BILLINGS_ATTACH_PAYMENT_METHOD_SUCCESS",

    BILLINGS_DETACH_PAYMENT_METHOD_REQUEST: "BILLINGS_DETACH_PAYMENT_METHOD_REQUEST",
    BILLINGS_DETACH_PAYMENT_METHOD_FAILURE: "INVOICE_DETACH_PAYMENT_METHOD_FAILURE",
    BILLINGS_DETACH_PAYMENT_METHOD_SUCCESS: "BILLINGS_DETACH_PAYMENT_METHOD_SUCCESS",

    BILLINGS_GET_PAYMENT_METHODS_REQUEST: "BILLINGS_GET_PAYMENT_METHODS_REQUEST",
    BILLINGS_GET_PAYMENT_METHODS_FAILURE: "BILLINGS_GET_PAYMENT_METHODS_FAILURE",
    BILLINGS_GET_PAYMENT_METHODS_SUCCESS: "BILLINGS_GET_PAYMENT_METHODS_SUCCESS",

    BILLINGS_UPDATE_SEATS_REQUEST: "BILLINGS_UPDATE_SEATS_REQUEST",
    BILLINGS_UPDATE_SEATS_FAILURE: "BILLINGS_UPDATE_SEATS_FAILURE",
    BILLINGS_UPDATE_SEATS_SUCCESS: "BILLINGS_UPDATE_SEATS_SUCCESS",

    BILLINGS_PAY_INVOICE_REQUEST: "BILLINGS_PAY_INVOICE_REQUEST",
    BILLINGS_PAY_INVOICE_FAILURE: "BILLINGS_PAY_INVOICE_FAILURE",
    BILLINGS_PAY_INVOICE_SUCCESS: "BILLINGS_PAY_INVOICE_SUCCESS",

    BILLINGS_PAYMENT_INTENT_DOMAINS_REQUEST: "BILLINGS_PAYMENT_INTENT_DOMAINS_REQUEST",
    BILLINGS_PAYMENT_INTENT_DOMAINS_FAILURE: "BILLINGS_PAYMENT_INTENT_DOMAINS_FAILURE",
    BILLINGS_PAYMENT_INTENT_DOMAINS_SUCCESS: "BILLINGS_PAYMENT_INTENT_DOMAINS_SUCCESS",

    BILLINGS_CONFIRM_PAYMENT_INTENT_REQUEST: "BILLINGS_CONFIRM_PAYMENT_INTENT_DOMAINS_REQUEST",
    BILLINGS_CONFIRM_PAYMENT_INTENT_FAILURE: "BILLINGS_CONFIRM_PAYMENT_INTENT_DOMAINS_FAILURE",
    BILLINGS_CONFIRM_PAYMENT_INTENT_SUCCESS: "BILLINGS_CONFIRM_PAYMENT_INTENT_DOMAINS_SUCCESS",
};


export const checkIfError = (reducer: any, ...actionsToCheck: string[]) => 
reducer.error.actions.some((action: string) => actionsToCheck.includes(action));

export const checkIfLoader = (reducer: any, ...actionsToCheck: string[]) => 
reducer.loader.actions.some((action: string) => actionsToCheck.includes(action));

/**
 * @description Update data when a change occur
 * @param {array} list
 * @param {object} item updated element
 * @param {string} idName updated element
 * @returns {array}
 */
export const updateList = (list: {}[], item: any, idName: string): {}[] => {
    const index = list.findIndex(((listItem: any)=> listItem[idName] === item[idName]));
    let updatedList: {}[] = [...list]
    updatedList[index] = item
    return updatedList
}
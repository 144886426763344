export const types = {
    SALES_CHANGE_STATE_REQUEST: "SALES_CHANGE_STATE_REQUEST",

    SALES_GET_TRANSACTIONS_REQUEST: "SALES_GET_TRANSACTIONS_REQUEST",
    SALES_GET_TRANSACTIONS_SUCCESS: "SALES_GET_TRANSACTIONS_SUCCESS",
    SALES_GET_TRANSACTIONS_FAILURE: "SALES_GET_TRANSACTIONS_FAILURE",

    SALES_GET_PAYMENTS_REQUEST: "SALES_GET_PAYMENTS_REQUEST",
    SALES_GET_PAYMENTS_SUCCESS: "SALES_GET_PAYMENTS_SUCCESS",
    SALES_GET_PAYMENTS_FAILURE: "SALES_GET_PAYMENTS_FAILURE",

    SALES_GET_SIX_MONTHS_SALES_REQUEST: "SALES_GET_SIX_MONTHS_SALES_REQUEST",
    SALES_GET_SIX_MONTHS_SALES_SUCCESS: "SALES_GET_SIX_MONTHS_SALES_SUCCESS",
    SALES_GET_SIX_MONTHS_SALES_FAILURE: "SALES_GET_SIX_MONTHS_SALES_FAILURE",

    SALES_GET_MONTHLY_INCOME_REQUEST: "SALES_GET_MONTHLY_INCOME_REQUEST",
    SALES_GET_MONTHLY_INCOME_SUCCESS: "SALES_GET_MONTHLY_INCOME_SUCCESS",
    SALES_GET_MONTHLY_INCOME_FAILURE: "SALES_GET_MONTHLY_INCOME_FAILURE",

    SALES_GET_WEEKLY_INCOME_REQUEST: "SALES_GET_WEEKLY_INCOME_REQUEST",
    SALES_GET_WEEKLY_INCOME_SUCCESS: "SALES_GET_WEEKLY_INCOME_SUCCESS",
    SALES_GET_WEEKLY_INCOME_FAILURE: "SALES_GET_WEEKLY_INCOME_FAILURE",

    SALES_GET_TRANSACTIONS_METRICS_REQUEST: "SALES_GET_TRANSACTIONS_METRICS_REQUEST",
    SALES_GET_TRANSACTIONS_METRICS_SUCCESS: "SALES_GET_TRANSACTIONS_METRICS_SUCCESS",
    SALES_GET_TRANSACTIONS_METRICS_FAILURE: "SALES_GET_TRANSACTIONS_METRICS_FAILURE",

    SALES_GET_TRANSACTION_REQUEST: "SALES_GET_TRANSACTION_REQUEST",
    SALES_GET_TRANSACTION_SUCCESS: "SALES_GET_TRANSACTION_SUCCESS",
    SALES_GET_TRANSACTION_FAILURE: "SALES_GET_TRANSACTION_FAILURE",

    SALES_CREATE_INVOICE_REQUEST: "SALES_CREATE_INVOICE_REQUEST",
    SALES_CREATE_INVOICE_SUCCESS: "SALES_CREATE_INVOICE_SUCCESS",
    SALES_CREATE_INVOICE_FAILURE: "SALES_CREATE_INVOICE_FAILURE",

    SALES_GET_INVOICES_REQUEST: "SALES_GET_INVOICES_REQUEST",
    SALES_GET_INVOICES_SUCCESS: "SALES_GET_INVOICES_SUCCESS",
    SALES_GET_INVOICES_FAILURE: "SALES_GET_INVOICES_FAILURE",

    SALES_GET_INVOICE_REQUEST: "SALES_GET_INVOICE_REQUEST",
    SALES_GET_INVOICE_SUCCESS: "SALES_GET_INVOICE_SUCCESS",
    SALES_GET_INVOICE_FAILURE: "SALES_GET_INVOICE_FAILURE",

    SALES_UPDATE_INVOICE_REQUEST: "SALES_UPDATE_INVOICE_REQUEST",
    SALES_UPDATE_INVOICE_SUCCESS: "SALES_UPDATE_INVOICE_SUCCESS",
    SALES_UPDATE_INVOICE_FAILURE: "SALES_UPDATE_INVOICE_FAILURE",

    SALES_PAY_INVOICE_REQUEST: "SALES_PAY_INVOICE_REQUEST",
    SALES_PAY_INVOICE_SUCCESS: "SALES_PAY_INVOICE_SUCCESS",
    SALES_PAY_INVOICE_FAILURE: "SALES_PAY_INVOICE_FAILURE",

    SALES_UPDATE_INVOICE_NOTE_REQUEST: "SALES_UPDATE_INVOICE_NOTE_REQUEST",
    SALES_UPDATE_INVOICE_NOTE_SUCCESS: "SALES_UPDATE_INVOICE_NOTE_SUCCESS",
    SALES_UPDATE_INVOICE_NOTE_FAILURE: "SALES_UPDATE_INVOICE_NOTE_FAILURE",

    SALES_CREATE_PAYMENT_REQUEST: "SALES_CREATE_PAYMENT_REQUEST",
    SALES_CREATE_PAYMENT_SUCCESS: "SALES_CREATE_PAYMENT_SUCCESS",
    SALES_CREATE_PAYMENT_FAILURE: "SALES_CREATE_PAYMENT_FAILURE",

    SALES_UPDATE_PAYMENT_REQUEST: "SALES_UPDATE_PAYMENT_REQUEST",
    SALES_UPDATE_PAYMENT_SUCCESS: "SALES_UPDATE_PAYMENT_SUCCESS",
    SALES_UPDATE_PAYMENT_FAILURE: "SALES_UPDATE_PAYMENT_FAILURE",

    SALES_GET_INVOICES_IN_PAYMENT_REQUEST: "SALES_GET_INVOICES_IN_PAYMENT_REQUEST",
    SALES_GET_INVOICES_IN_PAYMENT_SUCCESS: "SALES_GET_INVOICES_IN_PAYMENT_SUCCESS",
    SALES_GET_INVOICES_IN_PAYMENT_FAILURE: "SALES_GET_INVOICES_IN_PAYMENT_FAILURE",

    SALES_VOID_PAYMENT_REQUEST: "SALES_VOID_PAYMENT_REQUEST",
    SALES_VOID_PAYMENT_SUCCESS: "SALES_VOID_PAYMENT_SUCCESS",
    SALES_VOID_PAYMENT_FAILURE: "SALES_VOID_PAYMENT_FAILURE",

    SALES_VERIFY_STRIPE_CONNECT_REQUEST: "SALES_VERIFY_STRIPE_CONNECT_REQUEST",
    SALES_VERIFY_STRIPE_CONNECT_SUCCESS: "SALES_VERIFY_STRIPE_CONNECT_SUCCESS",
    SALES_VERIFY_STRIPE_CONNECT_FAILURE: "SALES_VERIFY_STRIPE_CONNECT_FAILURE",

    SALES_LINK_STRIPE_CONNECT_REQUEST: "SALES_LINK_STRIPE_CONNECT_REQUEST",
    SALES_LINK_STRIPE_CONNECT_SUCCESS: "SALES_LINK_STRIPE_CONNECT_SUCCESS",
    SALES_LINK_STRIPE_CONNECT_FAILURE: "SALES_LINK_STRIPE_CONNECT_FAILURE",

    SALES_GET_NEXT_INVOICE_NUMBER_REQUEST: "SALES_GET_NEXT_INVOICE_NUMBER_REQUEST",
    SALES_GET_NEXT_INVOICE_NUMBER_SUCCESS: "SALES_GET_NEXT_INVOICE_NUMBER_SUCCESS",
    SALES_GET_NEXT_INVOICE_NUMBER_FAILURE: "SALES_GET_NEXT_INVOICE_NUMBER_FAILURE",

    SALES_GET_NEXT_PAYMENT_NUMBER_REQUEST: "SALES_GET_NEXT_PAYMENT_NUMBER_REQUEST",
    SALES_GET_NEXT_PAYMENT_NUMBER_SUCCESS: "SALES_GET_NEXT_PAYMENT_NUMBER_SUCCESS",
    SALES_GET_NEXT_PAYMENT_NUMBER_FAILURE: "SALES_GET_NEXT_PAYMENT_NUMBER_FAILURE",

    SALES_GET_NEXT_REFUND_NUMBER_REQUEST: "SALES_GET_NEXT_REFUND_NUMBER_REQUEST",
    SALES_GET_NEXT_REFUND_NUMBER_SUCCESS: "SALES_GET_NEXT_REFUND_NUMBER_SUCCESS",
    SALES_GET_NEXT_REFUND_NUMBER_FAILURE: "SALES_GET_NEXT_REFUND_NUMBER_FAILURE",

    SALES_GET_CONFIGURATIONS_REQUEST: "SALES_GET_CONFIGURATIONS_REQUEST",
    SALES_GET_CONFIGURATIONS_SUCCESS: "SALES_GET_CONFIGURATIONS_SUCCESS",
    SALES_GET_CONFIGURATIONS_FAILURE: "SALES_GET_CONFIGURATIONS_FAILURE",

    SALES_UPDATE_CONFIGURATIONS_REQUEST: "SALES_UPDATE_CONFIGURATIONS_REQUEST",
    SALES_UPDATE_CONFIGURATIONS_SUCCESS: "SALES_UPDATE_CONFIGURATIONS_SUCCESS",
    SALES_UPDATE_CONFIGURATIONS_FAILURE: "SALES_UPDATE_CONFIGURATIONS_FAILURE",

    SALES_CREATE_TAX_REQUEST: "SALES_CREATE_TAX_REQUEST",
    SALES_CREATE_TAX_SUCCESS: "SALES_CREATE_TAX_SUCCESS",
    SALES_CREATE_TAX_FAILURE: "SALES_CREATE_TAX_FAILURE",

    SALES_UPDATE_TAX_REQUEST: "SALES_UPDATE_TAX_REQUEST",
    SALES_UPDATE_TAX_SUCCESS: "SALES_UPDATE_TAX_SUCCESS",
    SALES_UPDATE_TAX_FAILURE: "SALES_UPDATE_TAX_FAILURE",

    SALES_CREATE_REFUND_REQUEST: "SALES_CREATE_REFUND_REQUEST",
    SALES_CREATE_REFUND_SUCCESS: "SALES_CREATE_REFUND_SUCCESS",
    SALES_CREATE_REFUND_FAILURE: "SALES_CREATE_REFUND_FAILURE",

    SALES_GET_REFUND_REQUEST: "SALES_GET_REFUND_REQUEST",
    SALES_GET_REFUND_SUCCESS: "SALES_GET_REFUND_SUCCESS",
    SALES_GET_REFUND_FAILURE: "SALES_GET_REFUND_FAILURE",

    SALES_UPDATE_REFUND_REQUEST: "SALES_UPDATE_REFUND_REQUEST",
    SALES_UPDATE_REFUND_SUCCESS: "SALES_UPDATE_REFUND_SUCCESS",
    SALES_UPDATE_REFUND_FAILURE: "SALES_UPDATE_REFUND_FAILURE",

    SALES_VOID_INVOICE_REQUEST: "SALES_VOID_INVOICE_REQUEST",
    SALES_VOID_INVOICE_SUCCESS: "SALES_VOID_INVOICE_SUCCESS",
    SALES_VOID_INVOICE_FAILURE: "SALES_VOID_INVOICE_FAILURE",

    SALES_VOID_REFUND_REQUEST: "SALES_VOID_REFUND_REQUEST",
    SALES_VOID_REFUND_SUCCESS: "SALES_VOID_REFUND_SUCCESS",
    SALES_VOID_REFUND_FAILURE: "SALES_VOID_REFUND_FAILURE",

    SALES_SEND_EMAIL_REQUEST: "SALES_SEND_EMAIL_REQUEST",
    SALES_SEND_EMAIL_SUCCESS: "SALES_SEND_EMAIL_SUCCESS",
    SALES_SEND_EMAIL_FAILURE: "SALES_SEND_EMAIL_FAILURE",

    SALES_GET_ALL_TAXES_REQUEST: "SALES_GET_ALL_TAXES_REQUEST",
    SALES_GET_ALL_TAXES_SUCCESS: "SALES_GET_ALL_TAXES_SUCCESS",
    SALES_GET_ALL_TAXES_FAILURE: "SALES_GET_ALL_TAXES_FAILURE",

    SALES_GET_TAX_REQUEST: "SALES_GET_TAX_REQUEST",
    SALES_GET_TAX_SUCCESS: "SALES_GET_TAX_SUCCESS",
    SALES_GET_TAX_FAILURE: "SALES_GET_TAX_FAILURE",

    SALES_INVOICE_CALCULATOR_REQUEST: "SALES_INVOICE_CALCULATOR_REQUEST",
    SALES_INVOICE_CALCULATOR_SUCCESS: "SALES_INVOICE_CALCULATOR_SUCCESS",
    SALES_INVOICE_CALCULATOR_FAILURE: "SALES_INVOICE_CALCULATOR_FAILURE",

    SALES_UPDATE_BUSINESS_NUMBER_REQUEST: "SALES_UPDATE_BUSINESS_NUMBER_REQUEST",
    SALES_UPDATE_BUSINESS_NUMBER_SUCCESS: "SALES_UPDATE_BUSINESS_NUMBER_SUCCESS",
    SALES_UPDATE_BUSINESS_NUMBER_FAILURE: "SALES_UPDATE_BUSINESS_NUMBER_FAILURE",

    SALES_SEARCH_INVOICES_REQUEST: "SALES_SEARCH_INVOICES_REQUEST",
    SALES_SEARCH_INVOICES_SUCCESS: "SALES_SEARCH_INVOICES_SUCCESS",
    SALES_SEARCH_INVOICES_FAILURE: "SALES_SEARCH_INVOICES_FAILURE",

    SALES_REGISTER_TERMINAL_REQUEST: "SALES_REGISTER_TERMINAL_REQUEST",
    SALES_REGISTER_TERMINAL_SUCCESS: "SALES_REGISTER_TERMINAL_SUCCESS",
    SALES_REGISTER_TERMINAL_FAILURE: "SALES_REGISTER_TERMINAL_FAILURE",

    SALES_EDIT_TERMINAL_LABEL_REQUEST: "SALES_EDIT_TERMINAL_LABEL_REQUEST",
    SALES_EDIT_TERMINAL_LABEL_SUCCESS: "SALES_EDIT_TERMINAL_LABEL_SUCCESS",
    SALES_EDIT_TERMINAL_LABEL_FAILURE: "SALES_EDIT_TERMINAL_LABEL_FAILURE",

    SALES_REMOVE_TERMINAL_REQUEST: "SALES_REMOVE_TERMINAL_REQUEST",
    SALES_REMOVE_TERMINAL_SUCCESS: "SALES_REMOVE_TERMINAL_SUCCESS",
    SALES_REMOVE_TERMINAL_FAILURE: "SALES_REMOVE_TERMINAL_FAILURE",

    SALES_LIST_STRIPE_LOCATIONS_REQUEST: "SALES_LIST_STRIPE_LOCATIONS_REQUEST",
    SALES_LIST_STRIPE_LOCATIONS_SUCCESS: "SALES_LIST_STRIPE_LOCATIONS_SUCCESS",
    SALES_LIST_STRIPE_LOCATIONS_FAILURE: "SALES_LIST_STRIPE_LOCATIONS_FAILURE",

    SALES_CREATE_STRIPE_LOCATIONS_REQUEST: "SALES_CREATE_STRIPE_LOCATIONS_REQUEST",
    SALES_CREATE_STRIPE_LOCATIONS_SUCCESS: "SALES_CREATE_STRIPE_LOCATIONS_SUCCESS",
    SALES_CREATE_STRIPE_LOCATIONS_FAILURE: "SALES_CREATE_STRIPE_LOCATIONS_FAILURE",

    SALES_UPDATE_STRIPE_LOCATIONS_REQUEST: "SALES_UPDATE_STRIPE_LOCATIONS_REQUEST",
    SALES_UPDATE_STRIPE_LOCATIONS_SUCCESS: "SALES_UPDATE_STRIPE_LOCATIONS_SUCCESS",
    SALES_UPDATE_STRIPE_LOCATIONS_FAILURE: "SALES_UPDATE_STRIPE_LOCATIONS_FAILURE",

    SALES_REMOVE_STRIPE_LOCATIONS_REQUEST: "SALES_REMOVE_STRIPE_LOCATIONS_REQUEST",
    SALES_REMOVE_STRIPE_LOCATIONS_SUCCESS: "SALES_REMOVE_STRIPE_LOCATIONS_SUCCESS",
    SALES_REMOVE_STRIPE_LOCATIONS_FAILURE: "SALES_REMOVE_STRIPE_LOCATIONS_FAILURE",

    SALES_GET_LOCATION_TERMINALS_REQUEST: "SALES_GET_LOCATION_TERMINALS_REQUEST",
    SALES_GET_LOCATION_TERMINALS_SUCCESS: "SALES_GET_LOCATION_TERMINALS_SUCCESS",
    SALES_GET_LOCATION_TERMINALS_FAILURE: "SALES_GET_LOCATION_TERMINALS_FAILURE",

    SALES_VERIFY_TERMINAL_CONFIGURATION_REQUEST: "SALES_VERIFY_TERMINAL_CONFIGURATION_REQUEST",
    SALES_VERIFY_TERMINAL_CONFIGURATION_SUCCESS: "SALES_VERIFY_TERMINAL_CONFIGURATION_SUCCESS",
    SALES_VERIFY_TERMINAL_CONFIGURATION_FAILURE: "SALES_VERIFY_TERMINAL_CONFIGURATION_FAILURE",

    SALES_CREATE_PAYMENT_OBJECT_REQUEST: "SALES_CREATE_PAYMENT_OBJECT_REQUEST",
    SALES_CREATE_PAYMENT_OBJECT_SUCCESS: "SALES_CREATE_PAYMENT_OBJECT_SUCCESS",
    SALES_CREATE_PAYMENT_OBJECT_FAILURE: "SALES_CREATE_PAYMENT_OBJECT_FAILURE",

    SALES_CREATE_ONLINE_REFUND_REQUEST: "SALES_CREATE_ONLINE_REFUND_REQUEST",
    SALES_CREATE_ONLINE_REFUND_SUCCESS: "SALES_CREATE_ONLINE_REFUND_SUCCESS",
    SALES_CREATE_ONLINE_REFUND_FAILURE: "SALES_CREATE_ONLINE_REFUND_FAILURE",

    SALES_CREATE_TERMINAL_REFUND_REQUEST: "SALES_CREATE_TERMINAL_REFUND_REQUEST",
    SALES_CREATE_TERMINAL_REFUND_SUCCESS: "SALES_CREATE_TERMINAL_REFUND_SUCCESS",
    SALES_CREATE_TERMINAL_REFUND_FAILURE: "SALES_CREATE_TERMINAL_REFUND_FAILURE",
};

import GregorianExport from './gregorian';

/////////////////////////////////////////////////////////////////////////////////
// RE-EXPORTED OBJECTS                                                         //
/////////////////////////////////////////////////////////////////////////////////

export const Gregorian = GregorianExport;

/////////////////////////////////////////////////////////////////////////////////
// METHODS                                                                     //
/////////////////////////////////////////////////////////////////////////////////

export const timeToMilliseconds = (time) => {

    if (typeof time === 'string') {

        const date = new Date(time);
        return date.getTime();

    }

    if (typeof time === 'number') {

        return time;

    }

    if (time instanceof Date) {

        return time.getTime();

    }

    return NaN;

};

const UnixTime = Object.freeze({
    Gregorian,

    timeToMilliseconds,
});

export default UnixTime;
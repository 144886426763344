import apiV2 from "../../../../../helpers/api.v2";
import { OrgActions } from "../reducer/actions.types";

// const _URL_CREATE_PRIMARY_ORG = "https://1t2hgcofyi.execute-api.us-east-1.amazonaws.com/Stage/organizations";
const _URL_CREATE_PRIMARY_ORG = `${process.env.REACT_APP_API_URL}/public-organizations`;

// const _URL = "https://rtcdkcf6ig.execute-api.us-east-1.amazonaws.com/Stage/organizations";
const _URL = `${process.env.REACT_APP_API_URL}/organizations`;

const MAPBOX_API_KEY = `${process.env.REACT_APP_MAPBOX_KEY}`;

/**
 * @description organization API calls.
 */
class OrgClass {
    /**
     * @description get single organization
     */
    orgGetOrganization({ orgId }: OrgActions["orgGetOrganization"]["payload"]) {
        return apiV2.getRequest(`${_URL}/${orgId}`);
    }

    /**
     * @description get list of organizations. (not working maybe)
     */
    orgGetAllOrganizations() {
        return apiV2.getRequest(`${_URL}`);
    }

    /**
     * @description create primary organization
     */
    orgCreatePrimaryOrganization(payload: OrgActions["orgCreatePrimaryOrganization"]["payload"]) {
        return apiV2.postRequest(`${_URL_CREATE_PRIMARY_ORG}/v1/primary`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
            useOrg: false,
        });
    }

    /**
     * @description create secondary organization
     */
    orgCreateSecondaryOrganization(payload: OrgActions["orgCreateSecondaryOrganization"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v1/secondary`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description upload image before create service.
     */
    getSignedUrl(payload: { file: File }) {
        return apiV2.getRequest(`${_URL}/url?contentType=${payload.file.type}&cacheControl=max-age=300,public`);
    }

    /**
     * @description update organization
     */
    orgUpdateOrganization(payload: OrgActions["orgUpdateOrganization"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v1`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
            useOrg: true,
        });
    }

    /**
     * @description delete organization
     */
    orgDeleteOrganization(payload: OrgActions["orgDeleteOrganization"]["payload"]) {
        return apiV2.deleteRequest(`${_URL}/v1/${payload.orgId}`);
    }

    /**
     * @description manage organization locations
     */
    orgManageLocations(payload: OrgActions["orgManageLocations"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v1/locations/manage`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description create organization location
     */
    orgCreateLocation(payload: OrgActions["orgCreateLocation"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v1/locations`, {
            data: JSON.stringify(payload.location),
            useToken: "accessToken",
        });
    }

    /**
     * @description update org location
     */
    orgUpdateLocation(payload: OrgActions["orgUpdateLocation"]["payload"]) {
        const { id, location } = payload;
        return apiV2.putRequest(`${_URL}/v1/locations/${id}`, {
            data: JSON.stringify(location),
            useToken: "accessToken",
        });
    }

    /**
     * @description delete org location
     */
    orgDeleteLocation(payload: { id: string }) {
        return apiV2.deleteRequest(`${_URL}/v1/locations/${payload.id}`, {
            useToken: "accessToken",
        });
    }

    /**
     * @description search places with mapbox service for organization.
     */
    orgGetPlaces(payload: OrgActions["orgGetPlaces"]["payload"]) {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            payload.query
        )}.json?access_token=${MAPBOX_API_KEY}&cachebuster=${Date.now()}&types=address&autocomplete=true`;
        return apiV2.getRequest(url, {
            useToken: false,
            useOrg: false,
            useResponseType: "json",
        });
    }

    /**
     * @description Set organization sales taxes
     */
    orgSetOrganizationSalesTaxes(payload: OrgActions["orgSetOrganizationSalesTaxes"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v1/taxes`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }

    /**
     * @description setup industry and business of organization.
     */
    orgInitialChanges(payload: {
        type: "industry" | "business";
        data: {
            business?: string;
            industry?: string;
        };
    }) {
        return apiV2.postRequest(`${_URL}/tour/${payload.type}`, {
            data: JSON.stringify(payload.data),
            useToken: "accessToken",
        });
    }

    /**
     * @description update configuration object in organization.
     */
    orgUpdateConfiguration(payload: OrgActions["orgUpdateConfiguration"]["payload"]) {
        return apiV2.postRequest(`${_URL}/tour/configuration`, {
            data: JSON.stringify(payload),
            useToken: "accessToken",
        });
    }
}

export default new OrgClass();

import { put, call, takeLeading, takeLatest, select, delay, CallEffect, PutEffect, SelectEffect, all, AllEffect, race, RaceEffect, take } from "redux-saga/effects";
import { types as authTypes } from "../../auth/reducer/types";
// import { types as storageTypes } from "../../../../storage/redux/reducer/types";
import { types as hrmTypes } from "../../../../hrm/redux/reducer/types";
import { types } from "../reducer/types";
import toastHelper from "@soltivo/draw-a-line/core/components/toast/reducer/toast.helper";
import orgClass from "./org.class";
import apiV2, { ErrorResponse, ErrorResponseV2, ValidationError } from "../../../../../helpers/api.v2";
import popupHelper from "@soltivo/draw-a-line/core/components/popup/reducer/popup.helper";
import soltivoHelper from "@soltivo/draw-a-line/core/helpers/soltivo.helper";
import { OrgActions } from "../reducer/actions.types";
import { RootState } from "../../../../../redux/reducers";
import { User } from "../../../auth";
import { Location, MapboxPlaces, Organization } from "../../../organization";
import { Industries } from "../../../../../helpers/industry/Industries";
import * as hrmActions from "../../../../hrm/redux/reducer/actions";
import * as billingsActions from "../../../../settings/redux/billings/reducer/actions";
import * as domainActions from "../../../../settings/redux/domain/reducer/actions";
import * as serviceActions from "../../../../service/redux/reducer/actions";
import * as notificationActions from "../../../../settings/redux/notifications/reducer/actions";
import { types as serviceTypes } from "../../../../service/redux/reducer/types";
import { hoursToSeconds } from "../../../../service/helpers/time";
import serviceClass from "../../../../service/redux/saga/service.class";
import hrmClass from "../../../../hrm/redux/saga/hrm.class";
import { EmployeeSchedules, TWeekDay } from "../../../../hrm/hrm";
import timezones from "../../../../../helpers/json/timezones.json";
import moment from "moment";
import history from "../../../../../app/routes/history";
import organizationValidation from "../../../validation/organization.validation";
import { OrganizationState } from "../reducer/reducer";
import appointmentValidation from "../../../../appointment/validation/appointment.validation";
import { Validation } from "@soltivo/draw-a-line";
import { WeekDays } from "../../../../hrm/helpers/contants";

/**
 * @description create primary organization
 */
function* orgCreatePrimaryOrganization({ payload }: OrgActions["orgCreatePrimaryOrganization"]) {
    try {
        const {
            data,
        }: {
            data: {
                organization: Organization;
                user: User;
            };
        } = yield call(orgClass.orgCreatePrimaryOrganization, payload);

        localStorage.setItem("orgId", data.organization.orgId);

        yield delay(3500);

        yield put({
            type: types.ORG_CREATE_PRIMARY_ORGANIZATION_SUCCESS,
        });

        yield put({ type: authTypes.AUTH_PROFILE_INFO_REQUEST });
    } catch (error: any) {
        yield put({
            type: types.ORG_CREATE_PRIMARY_ORGANIZATION_FAILURE,
            payload: error,
        });

        if (error?.error.code) {
            apiV2.toastAllErrors(error);
        } else if (error.message) {
            toastHelper.toastStartContent("danger", error.message || "Something went wrong while creating your organization try again, if the error persists contact soltivo team.");
            return;
        }
    }
}

/**
 * @description Create secondary organization organization
 */
function* orgCreateSecondaryOrganization({ payload }: OrgActions["orgCreateSecondaryOrganization"]) {
    try {
        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organizations: OrgReducer.organizations as Organization[],
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organizations }: MapStateToProps = yield select(mapStateToProps);

        yield organizationValidation.orgCreateSecondaryOrganization(payload, {
            org,
            organizations,
        });

        const {
            data,
        }: {
            data: {
                organization: Organization;
                user: User;
            };
        } = yield call(orgClass.orgCreateSecondaryOrganization, payload);

        yield delay(3500);

        yield put({ type: types.ORG_GET_ALL_ORGANIZATIONS_REQUEST });

        yield put({
            type: types.ORG_CREATE_SECONDARY_ORGANIZATION_SUCCESS,
        });

        // Enter organization
        yield put({ type: types.ORG_ENTER_ORGANIZATION_REQUEST, payload: { orgId: data.organization.orgId, load: false } });
        popupHelper.popupEnd();
    } catch (error) {
        if (error instanceof ErrorResponse) {
            appointmentValidation.validation.set([
                {
                    fieldName: "global",
                    formName: organizationValidation.formTypes.ORG_CREATE_SECONDARY,
                    message: error?.error?.errors?.length ? error.error.errors[0].message : error.error.message || error.message,
                    status: "failed",
                },
            ]);
        }

        yield put({
            type: types.ORG_CREATE_SECONDARY_ORGANIZATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Get all organizations
 */
function* orgGetAllOrganizations(): Generator<CallEffect | PutEffect | AllEffect<CallEffect> | SelectEffect, void, any> {
    try {
        let {
            data,
        }: {
            data: OrganizationState["organizations"];
        } = yield call(orgClass.orgGetAllOrganizations);

        data = data.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 0));

        yield put({
            type: types.ORG_GET_ALL_ORGANIZATIONS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const { loadingOrganizations } = yield select(({ OrgReducer }: RootState) => ({
            loadingOrganizations: OrgReducer.loadingOrganizations,
        }));

        if (loadingOrganizations) {
            // only reload app if organizations are loading for the first time.
            yield put({ type: authTypes.AUTH_LOGOUT_USER_REQUEST });
        } else {
            yield put({
                type: types.ORG_GET_ALL_ORGANIZATIONS_FAILURE,
                payload: error,
            });
        }
    }
}

/**
 * @description Get user organization by orgid
 */
function* orgGetOrganization({ payload }: OrgActions["orgGetOrganization"]) {
    try {
        const { data } = yield call(orgClass.orgGetOrganization, payload);
        yield put({ type: types.ORG_GET_ORGANIZATION_SUCCESS, payload: data });
    } catch (error: any) {
        yield put({
            type: types.ORG_GET_ORGANIZATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Delete organization
 */
function* orgDeleteOrganization({ payload }: OrgActions["orgDeleteOrganization"]) {
    try {
        const mapStateToProps = ({ OrgReducer, AuthReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organizations: OrgReducer.organizations as Organization[],
            cognitoUser: AuthReducer.cognitoUser,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organizations, cognitoUser }: MapStateToProps = yield select(mapStateToProps);

        yield organizationValidation.orgDeleteOrganization(
            {
                orgId: payload.orgId,
            },
            {
                cognitoUser,
                org,
                organizations,
            }
        );

        const { data } = yield call(orgClass.orgDeleteOrganization, payload);
        yield put({ type: types.ORG_DELETE_ORGANIZATION_SUCCESS, payload: data });

        const orgList = organizations.filter((org) => org.orgId !== payload.orgId);
        const customOrgId = cognitoUser?.attributes["custom:org_id"];

        if (orgList.length) {
            /** @description redirect the user to  primary organization */
            yield put({ type: types.ORG_ENTER_ORGANIZATION_REQUEST, payload: { orgId: customOrgId } });
        } else {
            /** @description lo the user out */
            yield put({ type: authTypes.AUTH_LOGOUT_USER_REQUEST });
        }

        popupHelper.popupEnd();
    } catch (error) {
        yield put({
            type: types.ORG_DELETE_ORGANIZATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description auto update organization
 */
function* orgAutoUpdateOrganization({ payload, autoSave }: OrgActions["orgAutoUpdateOrganization"]): Generator<CallEffect | SelectEffect | PutEffect | any, void, any> {
    const { autoSaveAPI, fieldName } = autoSave;
    try {
        autoSaveAPI.updateByAttributeName(fieldName, {
            loading: true,
            message: "Saving",
            status: {
                type: "pending",
            },
        });

        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organizations: OrgReducer.organizations as OrganizationState["organizations"],
            organization: OrgReducer.organization as Organization,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organizations, organization }: MapStateToProps = yield select(mapStateToProps);

        yield organizationValidation.orgAutoUpdateOrganization(payload, [fieldName]);

        yield delay(2000);

        let { ...send } = payload;

        if (typeof send["logo"] !== "string" && send["logo"]?.type) {
            const image = yield soltivoHelper.processImageSize(send?.logo, {
                dimensions: {
                    width: 512,
                    height: 512,
                    fill: true,
                },
            });

            const { data: assignedUrl } = yield call(orgClass.getSignedUrl, {
                file: image,
            });

            const {
                fileLink,
            }: {
                fileLink: string;
            } = yield call(apiV2.postAssignedUrl, assignedUrl, image);

            send["logo"] = `${fileLink}?createdAt=${new Date().getTime()}`;
        }

        Object.keys(send).forEach((index) => {
            const key = index as ReturnType<() => keyof typeof send>;
            if (!send[key]) delete send[key];
            if (typeof send[key] === "object") {
                if (!Object.keys(send[key] as object).length) delete send[key];
            }
        });

        yield call(orgClass.orgUpdateOrganization, send);

        org = {
            ...org,
            ...(send as Partial<Organization>),
        };

        organizations = organizations.map((item) => {
            if (item.orgId === org.orgId) {
                return {
                    ...item,
                    ...(send as Partial<Organization>),
                };
            }

            return item;
        });

        yield put({
            type: types.ORG_AUTO_UPDATE_ORGANIZATION_SUCCESS,
            payload: {
                organization: { ...organization, ...send },
                org: org,
                organizations: organizations,
            },
        });

        autoSaveAPI.updatePending({
            loading: false,
            message: "Saved successufully.",
            status: {
                type: "success",
            },
        });
    } catch (error) {
        if (error instanceof Validation) {
            const form = error.forms[organizationValidation.formTypes.ORG_UPDATE];
            const err = form?.[fieldName];
            autoSaveAPI.updateByAttributeName(fieldName, {
                message: err?.message || "Unproccessed",
                status: {
                    type: err?.status?.type || "unprocessable",
                },
                loading: false,
            });
        } else if (error instanceof ErrorResponse) {
            autoSaveAPI.updateByAttributeName(fieldName, {
                loading: false,
                message: error.error.errors.length ? `${error.error.errors[0].field} ${error.error.errors[0].message}` : error.error.message || error.message,
                status: {
                    type: "failed",
                },
            });
        }

        yield put({
            type: types.ORG_AUTO_UPDATE_ORGANIZATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description update organization
 */
function* orgUpdateOrganization({ payload }: OrgActions["orgUpdateOrganization"]): Generator<CallEffect | SelectEffect | PutEffect | any, void, any> {
    try {
        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organizations: OrgReducer.organizations as OrganizationState["organizations"],
            organization: OrgReducer.organization as Organization,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organizations, organization }: MapStateToProps = yield select(mapStateToProps);

        yield organizationValidation.orgUpdateOrganization(
            payload,
            {
                organizations,
            },
            Object.keys(payload)
        );

        yield delay(2000);

        let { ...send } = payload;

        if (typeof send["logo"] !== "string" && send["logo"]?.type) {
            // const type = send?.logo?.type.toLowerCase();
            // if (!type || !/(jpg|png|jpeg|webp)/g.test(type?.toLowerCase())) {
            //     throw new Error("Logo picture can only be jpeg, jpg, webp or png.");
            // }

            const image = yield soltivoHelper.processImageSize(send?.logo, {
                dimensions: {
                    width: 512,
                    height: 512,
                    fill: true,
                },
            });

            const { data: assignedUrl } = yield call(orgClass.getSignedUrl, {
                file: image,
            });

            const {
                fileLink,
            }: {
                fileLink: string;
            } = yield call(apiV2.postAssignedUrl, assignedUrl, image);

            send["logo"] = `${fileLink}?createdAt=${new Date().getTime()}`;
        }

        Object.keys(send).forEach((index) => {
            const key = index as ReturnType<() => keyof typeof send>;
            if (!send[key]) delete send[key];
            if (typeof send[key] === "object") {
                if (!Object.keys(send[key] as object).length) delete send[key];
            }
        });

        yield call(orgClass.orgUpdateOrganization, send);

        //if update is in the authenticated organization
        if (org.orgId === payload["orgId"]) {
            org = {
                ...org,
                ...(send as Partial<Organization>),
            };
        }

        organizations = organizations.map((item) => {
            if (item.orgId === org.orgId) {
                return {
                    ...item,
                    ...(send as Partial<Organization>),
                };
            }

            return item;
        });

        yield put({
            type: types.ORG_UPDATE_ORGANIZATION_SUCCESS,
            payload: {
                organization: { ...organization, ...send },
                org: org,
                organizations: organizations,
            },
        });
    } catch (error) {
        if (error instanceof ErrorResponse) {
            organizationValidation.validation.set([
                {
                    fieldName: "global",
                    formName: organizationValidation.formTypes.ORG_UPDATE,
                    message: error.error.errors.length ? error.error.errors[0].message : error.error.message || error.message,
                    status: "failed",
                },
            ]);

            yield put({
                type: types.ORG_UPDATE_ORGANIZATION_FAILURE,
                payload: error,
            });
        }
    }
}

/**
 * @description enter in organization
 */
function* orgEnterOrganization({ payload }: OrgActions["orgEnterOrganization"]): Generator<CallEffect | PutEffect | AllEffect<PutEffect> | SelectEffect, void, any> {
    try {
        const { orgId, preventReload, redirect } = payload;

        const enterWithOrgId: string | null = preventReload ? localStorage.getItem("orgId") : orgId;

        if (!enterWithOrgId) return;

        // We must update the orgId to be able to fetch another organization
        localStorage.setItem("orgId", enterWithOrgId);
        soltivoHelper.setCookie("orgId", enterWithOrgId, 10);

        const { data }: { data: Organization } = yield call(orgClass.orgGetOrganization, { orgId: enterWithOrgId });

        if (redirect) {
            history.push(redirect);
        }

        if (!preventReload) {
            window.location.reload();
            return;
        }

        yield put({
            type: types.ORG_ENTER_ORGANIZATION_SUCCESS,
            payload: !preventReload ? null : data,
        });

        yield all([
            // Employees & Staff calls
            put(hrmActions.hrmValidateRole()),
            put(hrmActions.hrmVerifyPermissions()),
            put(hrmActions.hrmGetAllEmployees({ includeInactive: true })),

            // DOMAIN
            put(domainActions.domainGetDomainInfo()),

            //BILLINGS
            put(
                billingsActions.billingsGetCustomer({
                    customerId: data.stripeCustomerId,
                })
            ),

            // NOTIFICATIONS SETTINGS
            put(notificationActions.notificationsGetSettings()),

            // put(billingsActions.billingsGetPublicSubscription()),
            // put(billingsActions.billingsGetSubscriptions()),
            // put(billingsActions.billingsGetInvoices()),
            // put(
            //     billingsActions.billingsGetPaymentMethods({
            //         stripeCustomerId: data.stripeCustomerId,
            //     })
            // ),
        ]);
    } catch (error: any) {
        yield put({ type: authTypes.AUTH_LOGOUT_USER_REQUEST });

        yield put({
            type: types.ORG_ENTER_ORGANIZATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description manage organization locations
 */
function* orgManageLocations({ payload }: OrgActions["orgManageLocations"]) {
    try {
        const { locations } = payload;
        if (!locations.length) {
            throw new ValidationError({ message: "Your organization should have at least one location." });
        } else {
            for (let i = 0; i < locations.length; i++) {
                if (!locations[i].name) {
                    throw new ValidationError({ message: `Your locations should have a name.` });
                } else if (!locations[i].email) {
                    throw new ValidationError({ message: `Your location "${locations[i].name}" should have one email.` });
                } else if (soltivoHelper.validateEmail(locations[i].email || "")) {
                    throw new ValidationError({ message: `Your location "${locations[i].name}" should have a valid email.` });
                } else if (!locations[i].phoneNumber) {
                    throw new ValidationError({ message: `Your location "${locations[i].name}" should have at least one phone number.` });
                } else if (!locations[i].taxes) {
                    throw new ValidationError({ message: `Your location ${locations[i].name} should have a valid tax.` });
                } else if (!locations[i].businessHours) {
                    throw new ValidationError({ message: `Your location ${locations[i].name} is missing business hours.` });
                } else {
                    const businessHoursCompleted = locations[i].businessHours.filter((b) => !(b.start === "00:00" && b.end === "00:00"));
                    if (!businessHoursCompleted.length) {
                        throw new ValidationError({ message: `Business hours is missing for your location ${locations[i].name}.` });
                    } else {
                        for (let i = 0; i < locations[i].businessHours.length; i++) {
                            const weekday = locations[i].businessHours[i];

                            if (!/([0-9]{2}):([0-9]{2})/.test(weekday.start) || !/([0-9]{2}):([0-9]{2})/.test(weekday.end)) {
                                throw new ValidationError({ message: `${weekday.day} is invalid, make sure to add when you open and close with 24 hours format.` });
                            } else if (weekday.start === weekday.end) {
                                throw new ValidationError({ message: `${weekday.day} is invalid, you cannot open and close at the same time.` });
                            } else {
                                let dateStart = moment();
                                let dateEnd = moment();
                                const splitStart = weekday.start.split(":");
                                const splitEnd = weekday.end.split(":");

                                dateStart.set("hour", parseInt(splitStart[0]));
                                dateStart.set("minute", parseInt(splitStart[1]));

                                dateEnd.set("hour", parseInt(splitEnd[0]));
                                dateEnd.set("minute", parseInt(splitEnd[1]));

                                if (dateStart.valueOf() >= dateEnd.valueOf()) {
                                    throw new ValidationError({ message: `You cannot open at ${weekday.start} and close at ${weekday.end} on ${weekday.day}.` });
                                }
                            }
                        }
                    }
                }
            }
        }
        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organizations: OrgReducer.organizations as Organization[],
            organization: OrgReducer.organization as Organization,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organizations, organization }: MapStateToProps = yield select(mapStateToProps);

        const { data } = yield call(orgClass.orgManageLocations, {
            locations,
        });

        for (let i = 0; i < organizations.length; i++) {
            if (organizations[i].orgId === org.orgId) {
                organizations[i] = {
                    ...organizations[i],
                    ...data,
                };
            }
        }

        if (organization) {
            organization = {
                ...organization,
                ...data,
            };
        }

        org = {
            ...org,
            ...data,
        };

        yield put({ type: types.ORG_MANAGE_ORGANIZATION_LOCATIONS_SUCCESS, payload: { org, organizations, organization } });

        // toastHelper.toastStartContent("success", "Location set successfully");
        popupHelper.popupEnd();
    } catch (error) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        }

        yield put({
            type: types.ORG_MANAGE_ORGANIZATION_LOCATIONS_FAILURE,
            payload: error,
        });
    }
}

/** @description validate location form fields */
const validateLocationFields = (location: OrgActions["orgCreateLocation"]["payload"]["location"]) => {
    if (!location.name) {
        throw new ValidationError({ message: `Your locations should have a name.` });
    } else if (location.email) {
        if (soltivoHelper.validateEmail(location.email || "")) {
            throw new ValidationError({ message: `Your location "${location.name}" should have a valid email.` });
        }
    } else if (!location.taxes) {
        throw new ValidationError({ message: `Your location ${location.name} should have a valid tax.` });
    }
};

/**
 * @description create org locations
 */
function* orgCreateLocation({ payload }: OrgActions["orgCreateLocation"]) {
    try {
        const { location } = payload;

        if (!location.email) delete location.email;

        validateLocationFields(location);

        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organization: OrgReducer.organization as Organization,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organization }: MapStateToProps = yield select(mapStateToProps);

        const { data } = yield call(orgClass.orgCreateLocation, {
            location,
        });

        yield delay(3000);

        if (organization) {
            organization = {
                ...organization,
                locations: [...organization.locations, data],
            };
        }

        org = {
            ...org,
            locations: [...org.locations, data],
        };

        yield put({ type: types.ORG_CREATE_LOCATION_SUCCESS, payload: { org, organization } });
        popupHelper.popupEnd();
    } catch (error) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        }

        yield put({
            type: types.ORG_CREATE_LOCATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Update org location
 */
function* orgUpdateLocation({ payload }: OrgActions["orgUpdateLocation"]) {
    try {
        const { location } = payload;

        validateLocationFields(location);

        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organization: OrgReducer.organization as Organization,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organization }: MapStateToProps = yield select(mapStateToProps);

        const { data }: { data: Location } = yield call(orgClass.orgUpdateLocation, payload);

        const updatedLocations = (locations: Location[]): Location[] => {
            return locations.map((loc) => {
                if (loc?.id === payload.id) loc = data;
                return loc;
            });
        };

        if (organization) {
            organization = {
                ...organization,
                locations: updatedLocations(organization.locations),
            };
        }

        org = {
            ...org,
            locations: updatedLocations(org.locations),
        };

        yield put({ type: types.ORG_UPDATE_LOCATION_SUCCESS, payload: { org, organization } });

        toastHelper.toastStartContent("success", "Location updated successfully");
        popupHelper.popupEnd();
    } catch (error) {
        console.error(error);
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        }

        yield put({
            type: types.ORG_UPDATE_LOCATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Delete org location
 */
function* orgDeleteLocation({ payload }: { payload: { id: string }; type: string }) {
    try {
        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organization: OrgReducer.organization as Organization,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organization }: MapStateToProps = yield select(mapStateToProps);

        yield call(orgClass.orgDeleteLocation, payload);

        const updatedLocations = (locations: Location[]): Location[] => {
            return locations.filter((loc) => loc?.id !== payload.id);
        };

        if (organization) {
            organization = {
                ...organization,
                locations: updatedLocations(organization.locations),
            };
        }

        org = {
            ...org,
            locations: updatedLocations(org.locations),
        };

        yield put({ type: types.ORG_DELETE_LOCATION_SUCCESS, payload: { org, organization } });

        toastHelper.toastStartContent("success", "Location deleted successfully");
        popupHelper.popupEnd();
    } catch (error) {
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        }

        yield put({
            type: types.ORG_DELETE_LOCATION_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description search for available locations.
 */
function* orgGetPlaces({ payload }: OrgActions["orgGetPlaces"]): Generator<CallEffect | PutEffect, void, MapboxPlaces> {
    try {
        yield delay(3000);
        const data = yield call(orgClass.orgGetPlaces, payload);
        yield put({ type: types.ORG_GET_PLACES_SUCCESS, payload: data?.features });
    } catch (error) {
        yield put({
            type: types.ORG_GET_PLACES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description Set org sales taxes
 */
function* orgSetOrganizationSalesTaxes({ payload }: OrgActions["orgSetOrganizationSalesTaxes"]) {
    try {
        organizationValidation.orgSetOrganizationSalesTaxes(payload);

        yield call(orgClass.orgSetOrganizationSalesTaxes, payload);

        let { taxes }: Organization = yield select(({ OrgReducer }: RootState) => ({
            taxes: OrgReducer.org?.taxes,
        }));

        taxes[payload.country][payload.region].taxes = payload.taxes;

        yield put({ type: types.ORG_SET_ORGANIZATION_SALES_TAXES_SUCCESS, payload: taxes });

        toastHelper.toastStartContent("success", "Sales tax updated successfully.");
        popupHelper.popupEnd();
    } catch (error) {
        if (error instanceof ErrorResponse) {
            toastHelper.toastStartContent("danger", `${error.error.errors[0].field} ${error.error.errors[0].message}`);
        }
        yield put({
            type: types.ORG_SET_ORGANIZATION_SALES_TAXES_FAILURE,
            payload: error,
        });
    }
}

/**
 * @description update configuration object in organization
 */
function* orgUpdateConfiguration({ payload }: OrgActions["orgUpdateConfiguration"]) {
    try {
        const mapStateToProps = ({ OrgReducer }: RootState) => ({
            org: OrgReducer.org as Organization,
            organizations: OrgReducer.organizations as Organization[],
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let { org, organizations }: MapStateToProps = yield select(mapStateToProps);

        const { data }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
            configuration: {
                ...org.configuration,
                ...payload.configuration,
                employees: {
                    ...org.configuration.employees,
                    ...payload.configuration.employees,
                },
            },
        });

        for (let i = 0; i < organizations.length; i++) {
            let organization = organizations[i];
            if (organization.orgId === org.orgId) {
                organization.configuration = data;
            }
        }

        org.configuration = data;

        yield put({
            type: types.ORG_UPDATE_CONFIGURATION_SUCCESS,
            payload: {
                org,
                organizations,
            },
        });
    } catch (error) {
        yield put({ type: types.ORG_UPDATE_CONFIGURATION_FAILURE });
    }
}

/**
 * @description goes through the organization tour.
 */
function* orgSetup({ payload }: OrgActions["orgSetup"]): Generator<SelectEffect | CallEffect | AllEffect<CallEffect> | PutEffect | RaceEffect<any>, void, any> {
    try {
        const validRangeDays: TWeekDay["weekday"][] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        const mapStateToProps = ({ OrgReducer, AuthReducer, ServiceReducer, HRMReducer }: RootState) => ({
            org: OrgReducer.org as Readonly<Organization>,
            organizations: OrgReducer.organizations as Readonly<Organization>[],
            tour: OrgReducer.tour,
            user: AuthReducer.user as Readonly<User>,
            allServices: ServiceReducer.allServices,
            categories: ServiceReducer.categories,
            employees: HRMReducer.employees,
            roles: HRMReducer.roles,
            scheduleTemplates: HRMReducer.scheduleTemplates,
        });

        type MapStateToProps = ReturnType<typeof mapStateToProps>;

        let state: MapStateToProps = yield select(mapStateToProps);

        let newOrg = { ...state.org };
        let newOrgs = [...state.organizations];

        const updateOrgObjects = (data: Partial<Organization>) => {
            newOrg = {
                ...newOrg,
                ...data,
            };

            for (let i = 0; i < newOrgs.length; i++) {
                if (newOrgs[i].orgId === state.org.orgId) {
                    newOrgs[i] = {
                        ...newOrgs[i],
                        ...data,
                    };
                }
            }
        };

        /**
         * @description available business types.
         */
        const businessTypes: Organization["businessType"][] = ["business", "individual", "multi-organization"];
        const industries = new Industries();

        switch (state.tour.stepName) {
            case "businessType":
                if (!payload.data.businessType) {
                    throw new ValidationError({
                        message: "You must select a business type.",
                    });
                } else if (businessTypes.includes(payload.data.businessType) === false) {
                    throw new ValidationError({
                        message: "You should select a valid business type.",
                    });
                }
                break;
            case "industry":
                if (!payload.data.industry) {
                    throw new ValidationError({
                        message: "You must select an industry.",
                    });
                } else if (!industries.validateIndustryId(payload.data.industry)) {
                    throw new ValidationError({
                        message: "You should select a valid industry.",
                    });
                }
                break;
            case "locations":
                if (!payload.data?.locations?.length) {
                    throw new ValidationError({ message: "Your organization should have at least one location." });
                } else if ((payload.data?.locations.length > 1 && state.org.businessType === "individual") || (payload.data?.locations.length > 1 && state.org.businessType === "business")) {
                    throw new ValidationError({ message: "Your organization type does not allow you to add more than one location." });
                } else {
                    for (let i = 0; i < payload.data.locations.length; i++) {
                        const location = payload.data.locations[i];
                        if (!location.name) {
                            throw new ValidationError({ message: `Your locations should have a name.` });
                        } else if (!location.taxes) {
                            throw new ValidationError({ message: `Your location ${location.name} should have a valid tax.` });
                        } else if (!location.businessHours.length) {
                            throw new ValidationError({ message: `Your location ${location.name} is missing business hours.` });
                        } else {
                            const businessHoursCompleted = location.businessHours.filter((b) => !(b.start === "00:00" && b.end === "00:00"));
                            if (!businessHoursCompleted.length) {
                                throw new ValidationError({ message: `Business hours is missing for your location ${location.name}.` });
                            } else {
                                for (let i = 0; i < businessHoursCompleted.length; i++) {
                                    const weekday = businessHoursCompleted[i];

                                    if (!validRangeDays.includes(weekday.day)) {
                                        throw new ValidationError({ message: `Business hours is missing a weekday on ${location.name} location.` });
                                    } else if (!/([0-9]{2}):([0-9]{2})/.test(weekday.start) || !/([0-9]{2}):([0-9]{2})/.test(weekday.end)) {
                                        throw new ValidationError({ message: `${weekday.day} is invalid, make sure to add when you open and close with 24 hours format.` });
                                    } else {
                                        let dateStart = moment();
                                        let dateEnd = moment();
                                        const splitStart = weekday.start.split(":");
                                        const splitEnd = weekday.end.split(":");

                                        dateStart.set("hour", parseInt(splitStart[0]));
                                        dateStart.set("minute", parseInt(splitStart[1]));

                                        dateEnd.set("hour", parseInt(splitEnd[0]));
                                        dateEnd.set("minute", parseInt(splitEnd[1]));

                                        if (dateStart.valueOf() >= dateEnd.valueOf()) {
                                            throw new ValidationError({ message: `You cannot open at ${weekday.start} and close at ${weekday.end} on ${weekday.day}.` });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                break;

            case "services":
                if (!payload.data.service) {
                    throw new ValidationError({ message: "Your organization is missing services." });
                } else if (!payload.data.service?.categories.length) {
                    throw new ValidationError({ message: "Your organization should have at least one category for services." });
                } else {
                    for (let i = 0; i < payload.data.service.categories.length; i++) {
                        const category = payload.data.service.categories[i];

                        if (category.name.length < 3) {
                            throw new ValidationError({ message: `Services category name must have at least 3 caracters.` });
                        } else if (!category.services.length) {
                            throw new ValidationError({ message: `Services category should have at least 1 service.` });
                        } else {
                            for (let b = 0; b < category.services.length; b++) {
                                const service = category.services[b];

                                if (service.title.length < 3) {
                                    throw new ValidationError({ message: `Service name should have at least 3 caracters in your ${category.name} service category.` });
                                } else if (service.duration === "00:00") {
                                    throw new ValidationError({ message: `Category ${category.name} contains service named ${service.title} with 00:00 duration.` });
                                } else if (!payload.data.service.categories.map((category) => category.name).includes(service.category)) {
                                    throw new ValidationError({ message: `Couldn't not find service category named ${service.category}, move your service to a valid category in your organization.` });
                                } else if (service.price && isNaN(parseInt(service.price))) {
                                    throw new ValidationError({ message: `Service ${service.title} contains invalid price in ${category.name} category.` });
                                } else if (!service.locations) {
                                    throw new ValidationError({ message: `Service ${service.title} is missing location.` });
                                }
                            }
                        }
                    }
                }
                break;
            case "employees":
                switch (payload.currentSubStep) {
                    case "invitation":
                        const { employees } = payload.data;

                        let newEmployees = employees?.filter((emp) => !state.employees.find((employee) => employee.email === emp.personalEmail.toLowerCase()));
                        if (!newEmployees || !newEmployees.length) {
                            throw new ValidationError({ message: `Empty list of employees to be invited.` });
                        } else {
                            for (let i = 0; i < newEmployees.length; i++) {
                                let employee = newEmployees[i];
                                if (!employee.firstName) {
                                    throw new ValidationError({ message: `Employee is missing first name.` });
                                } else if (!employee.lastName) {
                                    throw new ValidationError({ message: `Employee is missing last name.` });
                                } else if (soltivoHelper.validateEmail(employee.personalEmail)) {
                                    throw new ValidationError({ message: `Employee ${employee.firstName} ${employee.lastName} contains invalid email.` });
                                }
                            }
                        }
                        break;

                    case "schedule":
                        const { employeeSchedule } = payload.data;
                        if (!employeeSchedule?.userId) {
                            throw new ValidationError({ message: `You cannot create an schedule without an employee.` });
                        } else {
                            // const orderWeekDays = validRangeDays.map((item) => employeeSchedule?.weekdays[0].find((day) => day.weekday === item)).filter((item) => item);

                            if (employeeSchedule.weekdays.length !== 7) {
                                throw new ValidationError({ message: `Missing all weekdays for the schedule.` });
                            }

                            for (let i = 0; i < employeeSchedule.weekdays.length; i++) {
                                let schedule = employeeSchedule.weekdays[i][0];

                                if (schedule.start === "00:00" && schedule.end === "00:00") continue;

                                if (!/^([0-9]{2}):([0-9]{2})$/.test(schedule.start)) {
                                    throw new ValidationError({ message: `Start Time ${schedule.start} for ${validRangeDays[i]} does not contain valid time format.` });
                                } else if (!/^([0-9]{2}):([0-9]{2})$/.test(schedule.end)) {
                                    throw new ValidationError({ message: `End Time ${schedule.end} for ${validRangeDays[i]} does not contain valid time format.` });
                                } else {
                                    let scheduleStart = moment();
                                    let scheduleEnd = moment();
                                    const scheduleSplitStart = schedule.start.split(":");
                                    const scheduleSplitEnd = schedule.end.split(":");

                                    scheduleStart.set("hour", parseInt(scheduleSplitStart[0]));
                                    scheduleStart.set("minute", parseInt(scheduleSplitStart[1]));

                                    scheduleEnd.set("hour", parseInt(scheduleSplitEnd[0]));
                                    scheduleEnd.set("minute", parseInt(scheduleSplitEnd[1]));

                                    if (scheduleStart.valueOf() >= scheduleEnd.valueOf()) {
                                        throw new ValidationError({ message: `You cannot set schedule to start at ${schedule.start} and end at ${schedule.end} on ${validRangeDays[i]}.` });
                                    }
                                }
                            }
                        }

                        break;

                    case "employeeServices":
                        const { employeeServices } = payload.data;
                        if (!employeeServices?.employeeId) {
                            throw new ValidationError({ message: "Employee is missing." });
                        } else if (employeeServices?.services?.length) {
                            for (let i = 0; i < employeeServices.services.length; i++) {
                                let service = employeeServices.services[i];

                                if (!service.categoryId) {
                                    throw new ValidationError({ message: "Category was not found." });
                                } else if (!service.serviceId) {
                                    throw new ValidationError({ message: "Service was not found." });
                                }
                            }
                        }
                        break;

                    default:
                        break;
                }
                break;

            case "confirmation":
                const { confirmation } = payload.data;

                if (!confirmation) {
                    throw new ValidationError({ message: "You must complete the form for the confirmation tour." });
                } else if (!confirmation?.name?.length) {
                    throw new ValidationError({ message: "Organization name is required." });
                } else if (!confirmation.currency) {
                    throw new ValidationError({ message: "Organization currency is required." });
                } else if (!confirmation.fiscalYear) {
                    throw new ValidationError({ message: "Fiscal year is required." });
                } else if (
                    ![
                        "January - December",
                        "February - January",
                        "March - February",
                        "April - March",
                        "May - April",
                        "June - May",
                        "July - June",
                        "August - July",
                        "September - August",
                        "October - September",
                        "November - October",
                        "December - November",
                    ].includes(confirmation.fiscalYear)
                ) {
                    throw new ValidationError({ message: `Select a valid fiscal year.` });
                } else if (!confirmation.headquarter) {
                    throw new ValidationError({ message: `Headquarter field is required.` });
                } else if (!state.org.locations.map((loc) => loc.id).includes(confirmation.headquarter)) {
                    throw new ValidationError({ message: `Headquarter was not found in your organization locations, please select a valid one.` });
                } else if (!confirmation.timeFormat) {
                    throw new ValidationError({ message: `Time format is required.` });
                } else if (!["24hours", "12hours"].includes(confirmation.timeFormat)) {
                    throw new ValidationError({ message: `Time format should be either 24 hours or 12 hours.` });
                } else if (!confirmation.timeZone) {
                    throw new ValidationError({ message: `Timezone is required.` });
                } else if (!timezones.map((tz) => tz.replace(/\s/g, "_")).includes(confirmation.timeZone)) {
                    throw new ValidationError({ message: `Please select an valid timezone.` });
                }

                break;
        }

        if (state.tour.stepName === "businessType") {
            const { data } = yield call(orgClass.orgInitialChanges, {
                type: "business",
                data: payload.data,
            });
            updateOrgObjects(data);
        } else if (state.tour.stepName === "industry") {
            const { data } = yield call(orgClass.orgInitialChanges, {
                type: "industry",
                data: payload.data,
            });
            updateOrgObjects(data);
        } else if (state.tour.stepName === "locations") {
            if (payload.data.locations?.length) {
                const locations: Organization["locations"] = JSON.parse(JSON.stringify(payload.data.locations));
                const {
                    data,
                }: {
                    data: {
                        locations: Organization["locations"];
                    };
                } = yield call(orgClass.orgManageLocations, {
                    locations: locations.map((loc) => {
                        loc.businessHours = loc.businessHours.sort((a, b) => validRangeDays.indexOf(a.day) - validRangeDays.indexOf(b.day));
                        return loc;
                    }),
                });

                updateOrgObjects({
                    locations: data.locations,
                });

                if (data.locations.length === 1 && state.org.businessType === "individual") {
                    let schedules = [];
                    try {
                        // Try to fetch user's schedule, catch 404 error
                        let { data }: { data: EmployeeSchedules } = yield call(hrmClass.hrmGetEmployeeSchedule, { userId: state.user.userId });
                        schedules = data;
                    } catch (error) {
                        console.warn(error);
                    }

                    if (!schedules.length) {
                        const location: Organization["locations"][0] = JSON.parse(JSON.stringify(data.locations[0]));

                        const businessHoursByWeekday = location.businessHours.sort((a, b) => {
                            const day1 = a.day;
                            const day2 = b.day;
                            return WeekDays.indexOf(day1) - WeekDays.indexOf(day2);
                        });

                        const orderBusinessDays = businessHoursByWeekday.map((hours) => {
                            return [
                                {
                                    start: hours.start,
                                    locationId: location.id,
                                    end: hours.end,
                                },
                            ];
                        });

                        yield call(hrmClass.hrmUpdateEmployeeSchedule, {
                            schedule: {
                                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                weekdays: orderBusinessDays,
                            },
                            userId: state.user.userId,
                        });
                    }
                }

                const { data: orgConfigurationAttributes }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
                    configuration: {
                        ...state.org.configuration,
                        locations: true,
                        employees:
                            state.org.businessType === "individual"
                                ? {
                                      [state.user.userId]: {
                                          schedule: true,
                                          services: false,
                                      },
                                  }
                                : {},
                    },
                });

                updateOrgObjects({
                    ...data,
                    configuration: {
                        ...orgConfigurationAttributes,
                    },
                });

                yield put(hrmActions.hrmGetAllScheduleTemplates());
            }
        } else if (state.tour.stepName === "services") {
            if (payload.data.service) {
                // create categories first.

                for (let i = 0; i < payload.data.service.categories.length; i++) {
                    //get category with same name if it exists.
                    const savedCategory = state.categories.find((ca) => ca.name.toLowerCase() === payload.data.service?.categories[i].name.toLowerCase());

                    let category = state.categories.find((ca) => ca.name.toLowerCase() === payload.data.service?.categories[i].name.toLowerCase()) || payload.data.service.categories[i];

                    if (!savedCategory) {
                        // create cateogry
                        yield call(serviceClass.serviceCreateCategory, {
                            name: category.name,
                            description: category.description,
                        });
                    }
                }

                //refresh categories.
                yield put(serviceActions.serviceGetCategories());

                //wait for api to refresh.
                yield race({
                    success: take(serviceTypes.SERVICE_GET_CATEGORIES_SUCCESS),
                    failure: take(serviceTypes.SERVICE_GET_CATEGORIES_FAILURE),
                });

                state = yield select(mapStateToProps);

                // create services for each category based on naming.
                const allNewServices = payload.data.service.categories.map((category) => category.services).reduce((prev, current) => [...prev, ...current]);

                for (let i = 0; i < state.categories.length; i++) {
                    let category = state.categories[i];

                    const servicesInCategory = allNewServices.filter((service) => service.category.toLowerCase() === category.name.toLowerCase());

                    for (let b = 0; b < servicesInCategory.length; b++) {
                        let service = servicesInCategory[b];

                        //check if service already exists.
                        if (!state.allServices.map((s) => s.title.toLowerCase()).includes(service.title.toLowerCase())) {
                            let durationSplit = service.duration.split(":");

                            yield call(serviceClass.serviceCreateService, {
                                title: service.title,
                                price: parseInt(service.price),
                                duration: hoursToSeconds(durationSplit[0] || "0", durationSplit[1] || "0"),
                                bufferTime: parseInt(service.bufferTime.split(" ")[0]) * 60,
                                category: category.id,
                                description: "",
                                locations: service.locations,
                            });
                        }
                    }
                }

                yield put(serviceActions.serviceGetAllServices());

                yield race({
                    success: take(serviceTypes.SERVICE_GET_ALL_SERVICES_SUCCESS),
                    failure: take(serviceTypes.SERVICE_GET_ALL_SERVICES_FAILURE),
                });

                state = yield select(mapStateToProps);

                if (state.org.businessType === "individual") {
                    yield call(serviceClass.serviceAssignEmployee, {
                        user: state.user.userId,
                        services: state.allServices.map((service) => service.id),
                    });
                }

                const { data: orgConfigurationAttributes }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
                    configuration: {
                        ...state.org.configuration,
                        services: true,
                        employees:
                            state.org.businessType === "individual"
                                ? {
                                      [state.user.userId]: {
                                          schedule: true,
                                          services: true,
                                      },
                                  }
                                : {},
                    },
                });

                updateOrgObjects({
                    ...state.org,
                    configuration: {
                        ...orgConfigurationAttributes,
                    },
                });
            }
        } else if (state.tour.stepName === "employees") {
            if (payload.currentSubStep === "invitation") {
                const { employees } = payload.data;
                if (employees) {
                    let newEmployees = employees.filter((emp) => !state.employees.find((employee) => employee.personalEmail === emp.personalEmail.toLowerCase()));
                    for (let i = 0; i < newEmployees.length; i++) {
                        let employee = newEmployees[i];

                        try {
                            const { data } = yield call(hrmClass.hrmCreateEmployee, {
                                email: employee.personalEmail,
                                roleId: "employee",
                                firstName: employee.firstName,
                                lastName: employee.lastName,
                            });

                            const { data: orgConfigurationAttributes }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
                                configuration: {
                                    ...state.org.configuration,
                                    employees: {
                                        ...state.org.configuration.employees,
                                        // add admin.
                                        [state.user.userId]: {
                                            schedule: false,
                                            services: false,
                                        },
                                        [data.userId]: {
                                            schedule: false,
                                            services: false,
                                        },
                                    },
                                },
                            });

                            updateOrgObjects({
                                ...state.org,
                                configuration: {
                                    ...orgConfigurationAttributes,
                                },
                            });
                        } catch (error) {
                            let errorV2 = error as ErrorResponseV2;
                            if (errorV2.error.errors) {
                                if (errorV2.error.errors[0].reason === "UsernameExistsException" || errorV2.error.errors[0].reason === "InternalServerErrorException") {
                                    throw {
                                        reason: "UsernameExistsException",
                                        message: `An account with the given email ${employee.personalEmail} already exists.`,
                                    };
                                }
                            }
                        }
                    }

                    yield put(hrmActions.hrmGetAllEmployees({ includeInactive: true }));

                    yield race({
                        success: take(hrmTypes.HRM_GET_ALL_EMPLOYEES_SUCCESS),
                        failure: take(hrmTypes.HRM_GET_ALL_EMPLOYEES_FAILURE),
                    });
                }
            } else if (payload.currentSubStep === "schedule") {
                let { employeeSchedule } = payload.data;

                if (employeeSchedule) {
                    let schedules = [];
                    // Try to get the current user schedule
                    try {
                        const { data }: { data: EmployeeSchedules } = yield call(hrmClass.hrmGetEmployeeSchedule, { userId: employeeSchedule.userId });
                        schedules = data;
                    } catch (error) {
                        console.info(error);
                    }

                    if (!schedules?.length) {
                        // Replace unscheduled days by empty `start` and `end` values
                        const activedWeekdays = employeeSchedule.weekdays.map((splits) => {
                            splits = splits.map((splitItem) => {
                                if (splitItem.start === "00:00" && splitItem.end === "00:00") {
                                    splitItem.start = "";
                                    splitItem.end = "";
                                }
                                return splitItem;
                            });
                            return splits;
                        });

                        yield call(hrmClass.hrmUpdateEmployeeSchedule, {
                            userId: employeeSchedule.userId,
                            schedule: {
                                timezone: employeeSchedule.timezone,
                                weekdays: activedWeekdays,
                            },
                        });
                    }
                    const { data: orgConfigurationAttributes }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
                        configuration: {
                            ...state.org.configuration,
                            employees: {
                                ...state.org.configuration.employees,
                                [employeeSchedule.userId]: {
                                    services: state.org.configuration.employees[employeeSchedule.userId].services || false,
                                    schedule: true,
                                },
                            },
                        },
                    });
                    updateOrgObjects({
                        ...state.org,
                        configuration: {
                            ...orgConfigurationAttributes,
                        },
                    });
                }
            } else if (payload.currentSubStep === "employeeServices") {
                const { employeeServices } = payload.data;
                if (employeeServices) {
                    if (employeeServices.services.length) {
                        yield call(serviceClass.serviceAssignEmployee, {
                            services: employeeServices.services.map((item) => item.serviceId),
                            user: employeeServices.employeeId,
                        });
                    }

                    // if the user skip we also update the services to true.
                    const { data: orgConfigurationAttributes }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
                        configuration: {
                            ...state.org.configuration,
                            employees: {
                                ...state.org.configuration.employees,
                                [employeeServices.employeeId]: {
                                    schedule: true,
                                    services: true,
                                },
                            },
                        },
                    });

                    updateOrgObjects({
                        ...state.org,
                        configuration: {
                            ...orgConfigurationAttributes,
                        },
                    });
                }
            }
        } else if (state.tour.stepName === "confirmation") {
            const { confirmation } = payload.data;
            const form = confirmation as Required<ReturnType<() => typeof confirmation>>;
            if (form) {
                const { data: orgUpdated }: { data: Organization } = yield call(orgClass.orgUpdateOrganization, {
                    ...form,
                });

                const { data: orgConfigurationAttributes }: { data: Organization["configuration"] } = yield call(orgClass.orgUpdateConfiguration, {
                    configuration: {
                        ...state.org.configuration,
                        confirmation: true,
                    },
                });

                updateOrgObjects({
                    ...orgUpdated,
                    configuration: {
                        ...orgConfigurationAttributes,
                    },
                });
            }
        }
        yield put({
            type: types.ORG_SETUP_SUCCESS,
            payload: {
                org: newOrg,
                organizations: newOrgs,
                subStep: payload.nextSubStep ? payload.nextSubStep : state.tour.subStep,
                stepName: typeof payload.nextStep === "string" ? payload.nextStep : state.tour.stepName,
            },
        });
    } catch (error: any) {
        let usernameExistsError = null;
        if (error instanceof ValidationError) {
            toastHelper.toastStartContent("danger", error.message);
        } else if (error?.reason === "UsernameExistsException") {
            usernameExistsError = error.message;
        }
        yield put(serviceActions.serviceGetCategories());
        yield put(hrmActions.hrmGetAllEmployees({ includeInactive: true }));
        yield put(hrmActions.hrmGetAllScheduleTemplates());
        yield put(serviceActions.serviceGetAllServices());
        yield put({
            type: types.ORG_SETUP_FAILURE,
            payload: usernameExistsError || error,
        });
    }
}

export default function* OrgSaga() {
    yield takeLatest(types.ORG_CREATE_PRIMARY_ORGANIZATION_REQUEST, orgCreatePrimaryOrganization);
    yield takeLatest(types.ORG_CREATE_SECONDARY_ORGANIZATION_REQUEST, orgCreateSecondaryOrganization);
    yield takeLatest(types.ORG_GET_ORGANIZATION_REQUEST, orgGetOrganization);
    yield takeLatest(types.ORG_GET_ALL_ORGANIZATIONS_REQUEST, orgGetAllOrganizations);
    yield takeLeading(types.ORG_DELETE_ORGANIZATION_REQUEST, orgDeleteOrganization);
    yield takeLatest(types.ORG_UPDATE_ORGANIZATION_REQUEST, orgUpdateOrganization);
    yield takeLatest(types.ORG_AUTO_UPDATE_ORGANIZATION_REQUEST, orgAutoUpdateOrganization);
    yield takeLatest(types.ORG_ENTER_ORGANIZATION_REQUEST, orgEnterOrganization);
    yield takeLatest(types.ORG_MANAGE_ORGANIZATION_LOCATIONS_REQUEST, orgManageLocations);
    yield takeLatest(types.ORG_CREATE_LOCATION_REQUEST, orgCreateLocation);
    yield takeLatest(types.ORG_UPDATE_LOCATION_REQUEST, orgUpdateLocation);
    yield takeLatest(types.ORG_DELETE_LOCATION_REQUEST, orgDeleteLocation);
    yield takeLatest(types.ORG_GET_PLACES_REQUEST, orgGetPlaces);
    yield takeLatest(types.ORG_SET_ORGANIZATION_SALES_TAXES_REQUEST, orgSetOrganizationSalesTaxes);
    yield takeLatest(types.ORG_UPDATE_CONFIGURATION_REQUEST, orgUpdateConfiguration);
    yield takeLatest(types.ORG_SETUP_REQUEST, orgSetup);
}

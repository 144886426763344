import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { headerChangeState } from "../reducer/actions";
import { HeaderContext } from "../reducer/header.context";
import useAppNavigation from "../../../routes/navigations.all";

/**
 * @description change app name in header based on the routing and name in "navigations.all" file, if
 * there's no match it will get the last slug item. /slug/slug/lastSlug
 */
const useHeaderChangeAppName = () => {
    const location = useLocation();
    const { dispatch: dispatchHeader } = useContext(HeaderContext);
    const _NAVIGATIONS = useAppNavigation();
    useEffect(() => {
        const foundApp = _NAVIGATIONS[0].apps.find((app) => location.pathname.startsWith(app.path));
        if (foundApp) {
            dispatchHeader(
                headerChangeState({
                    state: {
                        appName: foundApp.name,
                    },
                })
            );
        } else {
            const slugs = location.pathname.split(/(\/)/g);

            if (!slugs.length) return;
            dispatchHeader(
                headerChangeState({
                    state: {
                        appName: slugs[4] || slugs[2],
                    },
                })
            );
        }
    }, [location.pathname]);
};

export default useHeaderChangeAppName;

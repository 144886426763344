import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { appointmentChangeForm, appointmentCreationChangeView } from "../context/actions";
import { AppointmentContextState } from "../context/reducer";
import { appointmentChangeState } from "../redux/reducer/actions";
import { AppointmentCreationSteps } from "../appointment";
import { popupHelper } from "@soltivo/draw-a-line";

type UseCreateAppointmentSteps = (dispatchContext: React.Dispatch<{ type: string; payload?: any; }>, state: AppointmentContextState, action: "create" | "reschedule" | "quickCreate") => {
    /**
     * @description move to the next step
     */
    moveNext(): void;
    /**
     * @description back to the previous step
     */
    onGoBack(): void;
     /**
     * @description change any attribute inside the form based on a name key and value.
     */
    onChangeForm: ({ name, value }: { name: keyof AppointmentContextState["form"]; value: any }) => void;
    /**
     * @description clear form and used attributes in redux if component unmount.
     */
    clearState: () => void;
};

/**
 * @description Set the next step each time the current step is updated
 */
const useCreateAppointmentSteps: UseCreateAppointmentSteps = (dispatchContext: React.Dispatch<{ type: string; payload?: any; }>, state: AppointmentContextState, action: "create" | "reschedule" | "quickCreate") => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [isMounted, setIsMounted] = useState(false);

    const { appointmentCreationView: { currentStep, nextStep, previousStep } } = state;

    const moveNext = () => {
        dispatchContext(appointmentCreationChangeView({ currentStep: nextStep }));
    };

    const onGoBack = () => {
        dispatchContext(appointmentCreationChangeView({ currentStep: previousStep }));
    };

    const onChangeForm = ({ name, value }: { name: keyof AppointmentContextState["form"]; value: any }) => {
        dispatchContext(
            appointmentChangeForm({
                [name]: value,
            })
        );
    };

    const clearState = () => {
        dispatch(
            appointmentChangeState({
                slots: [],
                availableDates: [],
                quickDates: [],
                newAppointment: undefined,
                // appointment: undefined,
            })
        );

        // dispatch(
        //     contactsChangeState({
        //         searchResults: [],
        //         searchingEntity: false,
        //     })
        // );
    };

    useEffect(() => {
        if(!isMounted) setIsMounted(true);

        const queryParam = location.search.split("=");
        let currentUrlParamStep = queryParam.length > 1 ? queryParam[1] as AppointmentCreationSteps : undefined;

        if(currentStep !== currentUrlParamStep) {
            // Update the state query param in the url
            if(currentStep) {
                history.push(`${location.pathname}?step=${currentStep}`);
            } else {
                history.replace(location.pathname)
            }
        }

        switch (currentStep) {
            case "categories":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: "services",
                    disableButton: true,
                }));
                break;
            case "services":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: action === "quickCreate" ? "customer" : "location", 
                    previousStep: "categories",
                    disableButton: true,
                }));
                break;
            case "location":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: "availableDates",
                    previousStep: "services",
                    disableButton: true,
                }));
                break;
            case "availableDates":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: "slots",
                    previousStep: "location",
                    disableButton: true,
                }));
                break;
            case "slots":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: action === "create" ? "customer" : "confirmation", 
                    previousStep: "availableDates",
                    disableButton: true,
                }));
                break;
            case "customer":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: "confirmation", 
                    previousStep: action === "quickCreate" ? "services": "slots",
                    disableButton: true,
                }));
                break;
            case "confirmation":
                dispatchContext(appointmentCreationChangeView({ 
                    nextStep: "success", 
                    previousStep: action === "reschedule" ? "slots": "customer",
                    disableButton: true,
                }));
                break;

            default:
                break;
        }
        
        if(!currentUrlParamStep && !currentStep && isMounted) popupHelper.popupEnd();
    }, [currentStep]);

    useEffect(() => {
        const queryParam = location.search.split("=");
        let currentUrlParamStep = queryParam.length > 1 ? queryParam[1] as AppointmentCreationSteps : undefined;

        // Avoid go back to confirmation step when the success view is shown
        if(previousStep === "confirmation") return;

        dispatchContext(appointmentCreationChangeView({ currentStep: currentUrlParamStep }));
    }, [location.search]);

    return {
        moveNext,
        onGoBack,
        onChangeForm,
        clearState,
    }
};

export default useCreateAppointmentSteps;

import { ReactComponent as Actiondot } from './actiondot.svg';
import { ReactComponent as AddGroup } from './add_group.svg';
import { ReactComponent as AddImage } from './add_image.svg';
import { ReactComponent as Anex } from './anex.svg';
import { ReactComponent as AngleBracketLeft } from './angle_bracket_left.svg';
import { ReactComponent as AngleBracketRight } from './angle_bracket_right.svg';
import { ReactComponent as ArrowBackwards } from './arrow_backwards.svg';
import { ReactComponent as ArrowDownFilled } from './arrow_down_filled.svg';
import { ReactComponent as ArrowDropdown } from './arrow_dropdown.svg';
import { ReactComponent as ArrowLeft } from './arrow_left.svg';
import { ReactComponent as ArrowRight } from './arrow_right.svg';
import { ReactComponent as Assignment } from './assignment.svg';
import { ReactComponent as AssignmentInd } from './assignment_ind.svg';
import { ReactComponent as Attachment } from './attachment.svg';
import { ReactComponent as AudioFile } from './audio_file.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as Basket } from './basket.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as BlankFile } from './blank_file.svg';
import { ReactComponent as Block } from './block.svg';
import { ReactComponent as Building } from './building.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CallHangUp } from './call_hang_up.svg';
import { ReactComponent as CallPickUp } from './call_pick_up.svg';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as CheckCicleOutline } from './check_cicle_outline.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as ChevronLeft } from './chevron_left.svg';
import { ReactComponent as ChevronRight } from './chevron_right.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as CloudUpload } from './cloud_upload.svg';
import { ReactComponent as CloudUploadOutlined } from './cloud_upload_outlined.svg';
import { ReactComponent as Computer } from './computer.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Crm } from './crm.svg';
import { ReactComponent as CropFree } from './crop_free.svg';
import { ReactComponent as Date } from './date.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DisableAudio } from './disable_audio.svg';
import { ReactComponent as DisableVideo } from './disable_video.svg';
import { ReactComponent as Doublecheck } from './doublecheck.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as ErrorCircle } from './error_circle.svg';
import { ReactComponent as ErrorTriangle } from './error_triangle.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as FirstPage } from './first_page.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as FolderAdd } from './folder_add.svg';
import { ReactComponent as FolderDoc } from './folder_doc.svg';
import { ReactComponent as FolderImage } from './folder_image.svg';
import { ReactComponent as FolderPdf } from './folder_pdf.svg';
import { ReactComponent as FolderPresentation } from './folder_presentation.svg';
import { ReactComponent as FolderSound } from './folder_sound.svg';
import { ReactComponent as FolderVideo } from './folder_video.svg';
import { ReactComponent as GlobalFile } from './global-file.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Illustration } from './illustration.svg';
import { ReactComponent as Image } from './image.svg';
import { ReactComponent as ImageFile } from './image_file.svg';
import { ReactComponent as ImagePreview } from './image_preview.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Kanban } from './kanban.svg';
import { ReactComponent as LastPage } from './last_page.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as LoadingFile } from './loading_file.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as LostCallAnotherCall } from './lost_call_another_call.svg';
import { ReactComponent as LostCallUnavailable } from './lost_call_unavailable.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Mobile } from './mobile.svg';
import { ReactComponent as Pallette } from './pallette.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as PdfFile } from './pdf-file.svg';
import { ReactComponent as Pen } from './pen.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as PencilSecondary } from './pencil_secondary.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Pipe } from './pipe.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Redo } from './redo.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Reply } from './reply.svg';
import { ReactComponent as Schedule } from './schedule.svg';
import { ReactComponent as Scissors } from './scissors.svg';
import { ReactComponent as Screen } from './screen.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as SearchSecondary } from './search_secondary.svg';
import { ReactComponent as Sent } from './sent.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as ShareScreen } from './share_screen.svg';
import { ReactComponent as Smiley } from './smiley.svg';
import { ReactComponent as SmileyFlame } from './smiley_flame.svg';
import { ReactComponent as SmileyHappy } from './smiley_happy.svg';
import { ReactComponent as SmileyKnockedOut } from './smiley_knocked_out.svg';
import { ReactComponent as SmileyNeutral } from './smiley_neutral.svg';
import { ReactComponent as SmileyOverjoyed } from './smiley_overjoyed.svg';
import { ReactComponent as SmileySad } from './smiley_sad.svg';
import { ReactComponent as Storage } from './storage.svg';
import { ReactComponent as Store } from './store.svg';
import { ReactComponent as SuccessCicle } from './success_cicle.svg';
import { ReactComponent as Surline } from './surline.svg';
import { ReactComponent as SystemVerticalAlt } from './system_vertical_alt.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as TagHeart } from './tag_heart.svg';
import { ReactComponent as TeamCalendar } from './team_calendar.svg';
import { ReactComponent as Template } from './template.svg';
import { ReactComponent as ThumbsDown } from './thumbs_down.svg';
import { ReactComponent as ThumbsUp } from './thumbs_up.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as Uneye } from './uneye.svg';
import { ReactComponent as UnknownFile } from './unknown_file.svg';
import { ReactComponent as Update } from './update.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as UsersConf } from './users_conf.svg';
import { ReactComponent as VideoCall } from './video_call.svg';
import { ReactComponent as VideoFile } from './video_file.svg';
import { ReactComponent as VideoSwitch } from './video_switch.svg';
import { ReactComponent as ViewAgenda } from './view_agenda.svg';
import { ReactComponent as ViewCarousel } from './view_carousel.svg';
import { ReactComponent as ViewWeek } from './view_week.svg';
import { ReactComponent as VisaLogo } from './visa_logo.svg';
import { ReactComponent as VpnKey } from './vpn_key.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Website } from './website.svg';

export default {
"actiondot": Actiondot, 
"add_group": AddGroup, 
"add_image": AddImage, 
"anex": Anex, 
"angle_bracket_left": AngleBracketLeft, 
"angle_bracket_right": AngleBracketRight, 
"arrow_backwards": ArrowBackwards, 
"arrow_down_filled": ArrowDownFilled, 
"arrow_dropdown": ArrowDropdown, 
"arrow_left": ArrowLeft, 
"arrow_right": ArrowRight, 
"assignment": Assignment, 
"assignment_ind": AssignmentInd, 
"attachment": Attachment, 
"audio_file": AudioFile, 
"back": Back, 
"basket": Basket, 
"bell": Bell, 
"blank_file": BlankFile, 
"block": Block, 
"building": Building, 
"calendar": Calendar, 
"call_hang_up": CallHangUp, 
"call_pick_up": CallPickUp, 
"chat": Chat, 
"check": Check, 
"check_cicle_outline": CheckCicleOutline, 
"checked": Checked, 
"chevron_left": ChevronLeft, 
"chevron_right": ChevronRight, 
"clock": Clock, 
"close": Close, 
"cloud_upload": CloudUpload, 
"cloud_upload_outlined": CloudUploadOutlined, 
"computer": Computer, 
"copy": Copy, 
"crm": Crm, 
"crop_free": CropFree, 
"date": Date, 
"delete": Delete, 
"disable_audio": DisableAudio, 
"disable_video": DisableVideo, 
"doublecheck": Doublecheck, 
"download": Download, 
"email": Email, 
"error_circle": ErrorCircle, 
"error_triangle": ErrorTriangle, 
"eye": Eye, 
"first_page": FirstPage, 
"folder": Folder, 
"folder_add": FolderAdd, 
"folder_doc": FolderDoc, 
"folder_image": FolderImage, 
"folder_pdf": FolderPdf, 
"folder_presentation": FolderPresentation, 
"folder_sound": FolderSound, 
"folder_video": FolderVideo, 
"global-file": GlobalFile, 
"help": Help, 
"illustration": Illustration, 
"image": Image, 
"image_file": ImageFile, 
"image_preview": ImagePreview, 
"info": Info, 
"kanban": Kanban, 
"last_page": LastPage, 
"list": List, 
"loading_file": LoadingFile, 
"lock": Lock, 
"logout": Logout, 
"lost_call_another_call": LostCallAnotherCall, 
"lost_call_unavailable": LostCallUnavailable, 
"minus": Minus, 
"mobile": Mobile, 
"pallette": Pallette, 
"pause": Pause, 
"pdf-file": PdfFile, 
"pen": Pen, 
"pencil": Pencil, 
"pencil_secondary": PencilSecondary, 
"phone": Phone, 
"pipe": Pipe, 
"play": Play, 
"plus": Plus, 
"redo": Redo, 
"refresh": Refresh, 
"reply": Reply, 
"schedule": Schedule, 
"scissors": Scissors, 
"screen": Screen, 
"search": Search, 
"search_secondary": SearchSecondary, 
"sent": Sent, 
"share": Share, 
"share_screen": ShareScreen, 
"smiley": Smiley, 
"smiley_flame": SmileyFlame, 
"smiley_happy": SmileyHappy, 
"smiley_knocked_out": SmileyKnockedOut, 
"smiley_neutral": SmileyNeutral, 
"smiley_overjoyed": SmileyOverjoyed, 
"smiley_sad": SmileySad, 
"storage": Storage, 
"store": Store, 
"success_cicle": SuccessCicle, 
"surline": Surline, 
"system_vertical_alt": SystemVerticalAlt, 
"tag": Tag, 
"tag_heart": TagHeart, 
"team_calendar": TeamCalendar, 
"template": Template, 
"thumbs_down": ThumbsDown, 
"thumbs_up": ThumbsUp, 
"undo": Undo, 
"uneye": Uneye, 
"unknown_file": UnknownFile, 
"update": Update, 
"upload": Upload, 
"user": User, 
"users_conf": UsersConf, 
"video_call": VideoCall, 
"video_file": VideoFile, 
"video_switch": VideoSwitch, 
"view_agenda": ViewAgenda, 
"view_carousel": ViewCarousel, 
"view_week": ViewWeek, 
"visa_logo": VisaLogo, 
"vpn_key": VpnKey, 
"warning": Warning, 
"website": Website, 
};
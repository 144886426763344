// import { Column, Row } from "@soltivo/draw-a-line";
// import moment from "moment";
import { Payment } from "@soltivo/types";
import { FC, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Location } from "../../../auth/organization";

import styles from "./invoice.pdf.module.scss";

interface InvoicePDFProps {
    transaction: Payment;
    location?: Location;
}

const InvoicePDF: FC<InvoicePDFProps> = ({ transaction: _transaction, location: _location }) => {
    const routerLocation = useLocation<{ invoiceLocation: Location; transaction: Payment }>();
    const history = useHistory();
    const [transaction, setTransaction] = useState<Payment>(_transaction);
    const [location, setLocation] = useState(_location);

    useEffect(() => {
        document.getElementById("launcher")?.style.setProperty("display", "none");
        if (transaction?.id && location?.address) {
            window.print();
            history.goBack();
        }
        return () => {
            document.getElementById("launcher")?.style.setProperty("display", "initial");
        };
    }, [transaction, location]);

    useEffect(() => {
        if (routerLocation.state?.invoiceLocation) {
            setLocation(routerLocation.state.invoiceLocation);
        }
        if (routerLocation.state?.transaction) {
            setTransaction(routerLocation.state.transaction);
        }
    }, []);

    if (!transaction?.id && !location?.address) return null;

    return (
        <div id={transaction.id} className={styles.invoice__pdf}>
            {/* <div className={styles.content__wrapper}>
                <div className={styles.invoice__infos}>
                    <Row className={styles.row}>
                        <Column size="6">
                            <div className={styles.invoice__details}>
                                <h4>Invoice - {transaction.status.toUpperCase()}</h4>
                                <p>Date: {transaction.createdAt}</p>
                                <p>Invoice No: {transaction.ref}</p>
                                <p className={styles.label}>Client</p>
                                <p>
                                    {transaction.contact.firstName || ""} {transaction.contact.lastName || ""}
                                </p>
                                <p>{transaction.contact.email}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className={styles.invoice__details__right}>
                                <h6>{transaction.service.title}</h6>
                                <p>{location?.address}</p>
                                <p>{location?.name}</p>
                                <p>{location?.email}</p>
                            </div>
                        </Column>
                    </Row>
                    <Row className={styles.row}>
                        <Column size="12">
                            <div className={styles.description__price}>
                                <h6>Description</h6>
                                <h6>Price</h6>
                            </div>
                            <div className={styles.description__price__value}>
                                <p>{transaction.service.title}</p>
                                <p>
                                    {(transaction.service.price / 100).toLocaleString("en-CA", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: "currency",
                                        currency: "CAD",
                                    })}
                                </p>
                            </div>
                        </Column>
                    </Row>
                    <Row className={styles.row}>
                        <Column size="6">
                            <div className={styles.note}>
                                <h6>Note</h6>
                                <p>{transaction.note}</p>
                            </div>
                        </Column>
                    </Row>
                </div>

                <div className={styles.payment__summary}>
                    <Row>
                        <Column size="6">
                            <div className={styles.payment__infos}>
                                {transaction.status !== "unpaid" && transaction?.paymentMethod ? <h6>Payment Received</h6> : <h6>No Payment Was Received</h6>}
                                {transaction.status !== "unpaid" && transaction?.paymentMethod ? (
                                    <div className={styles.info}>
                                        <h6>Date</h6>
                                        <p>
                                            {moment(transaction?.payment.date).format("DD MMMM YYYY")} at
                                            {moment(transaction?.payment.date).format("h:mm a")}
                                        </p>
                                    </div>
                                ) : null}
                                {transaction.status !== "unpaid" && transaction?.paymentMethod ? (
                                    <div className={styles.info}>
                                        <h6>Total received</h6>
                                        <p>
                                            ${" "}
                                            {(transaction.total / 100).toLocaleString("en-CA", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                style: "currency",
                                                currency: "CAD",
                                            })}
                                        </p>
                                    </div>
                                ) : null}
                                {transaction.status !== "unpaid" && transaction?.paymentMethod ? (
                                    <div className={styles.info}>
                                        <h6>Payment method</h6>
                                        <p>{transaction?.paymentMethod?.replace(/([A-Z])/g, " $1")}</p>
                                    </div>
                                ) : null}
                            </div>
                        </Column>
                        <Column size="6">
                            <div className={styles.total}>
                                <div>
                                    <p>Subtotal:</p>
                                    <span>
                                        {(transaction.subTotal / 100).toLocaleString("en-CA", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "CAD",
                                        })}
                                    </span>
                                </div>
                                <div>
                                    <p>Discount:</p>
                                    <span>
                                        {(transaction.discount / 100).toLocaleString("en-CA", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "CAD",
                                        })}
                                    </span>
                                </div>
                                {(() => {
                                    if (transaction.taxes?.length) {
                                        return transaction.taxes.map((t) => (
                                            <div key={t.name}>
                                                <p>
                                                    {t.name} ({t.percent}):
                                                </p>
                                                <span>
                                                    {(t.total / 100).toLocaleString("en-CA", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "CAD",
                                                    })}
                                                </span>
                                            </div>
                                        ));
                                    }
                                })()}
                                <div>
                                    <p>Tips:</p>
                                    <span>
                                        {(transaction.tips / 100).toLocaleString("en-CA", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "CAD",
                                        })}
                                    </span>
                                </div>
                                <div>
                                    <p>Total:</p>
                                    <span>
                                        {(transaction.total / 100).toLocaleString("en-CA", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "CAD",
                                        })}
                                    </span>
                                </div>
                                {transaction.status !== "unpaid" && transaction?.paymentMethod ? (
                                    <div>
                                        <p>Paid ({transaction?.paymentMethod?.replace(/([A-Z])/g, " $1")}):</p>
                                        <span>
                                            {(transaction?.payment?.amount / 100).toLocaleString("en-CA", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                style: "currency",
                                                currency: "CAD",
                                            })}
                                        </span>
                                    </div>
                                ) : null}
                                <div className={styles.balance}>
                                    <div className={styles.line}></div>
                                    <h5>
                                        {((transaction.total - (transaction?.payment?.amount || 0)) / 100).toLocaleString("en-CA", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "CAD",
                                        })}
                                    </h5>
                                </div>
                            </div>
                        </Column>
                    </Row>
                </div>
            </div> */}
        </div>
    );
};

export default InvoicePDF;

import apiV2 from "../../../../helpers/api.v2";

const API_URL = `${process.env.REACT_APP_API_URL}/builder`;

/**
 * @description apis related to the wizard editor.
 */
class ContactFormMessageClass {
    contactFormGetMessages() {
        return apiV2.getRequest(`${API_URL}/contactform`, {
            useToken: "accessToken",
            useOrg: true,
        });
    }
    contactFormGetMessage(payload: { id: string }) {
        return apiV2.getRequest(`${API_URL}/contactform/${payload.id}`, {
            useToken: "accessToken",
            useOrg: true,
        });
    }

    contactFormUpdateMessage(payload: { id: string; status: string }) {
        return apiV2.putRequest(`${API_URL}/contactform/${payload.id}`, {
            useToken: "accessToken",
            useOrg: true,
            data: JSON.stringify({ status: payload.status }),
        });
    }
    contactFormDeleteMessage(payload: { id: string }) {
        return apiV2.deleteRequest(`${API_URL}/contactform/${payload.id}`, {
            useToken: "accessToken",
            useOrg: true,
        });
    }
}

export default new ContactFormMessageClass();

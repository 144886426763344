import { AppointmentCreationSteps, AppointmentCreationSubSteps } from "../appointment";
import { AppointmentContextState } from "./reducer";
import { types } from "./types";

/**
 * @description change any attribute in context state
 * @note it does not change form attribute, use aptChangeForm action instead.
 * @important this action must be used carefully it may break the app.
 */
export const appointmentChangeContextState = (payload: Partial<Omit<AppointmentContextState, "form" | "boardId" | "dateFormat">>) => ({
    type: types.APPOINTMENT_CHANGE_STATE,
    payload,
});

/**
 * @description change any attribute in form to create/update an appointment.
 */
export const appointmentChangeForm = (payload: Partial<AppointmentContextState["form"]>) => ({
    type: types.APPOINTMENT_CHANGE_FORM,
    payload,
});

/**
 * @description reset context state.
 */
export const appointmentSetInitialState = () => ({
    type: types.APPOINTMENT_SET_INITIAL_STATE,
});

/**
 * @description reset context form state.
 */
export const appointmentSetInitialForm = () => ({
    type: types.APPOINTMENT_SET_INITIAL_FORM,
});

/**
 * @description change view
 */
 export const appointmentCreationChangeView = (payload: {
     currentStep?: AppointmentCreationSteps;
     nextStep?: AppointmentCreationSteps;
     previousStep?: AppointmentCreationSteps;
     disableButton?: boolean;
     subStep?: AppointmentCreationSubSteps;
 }) => ({
    type: types.APPOINTMENT_CREATION_CHANGE_VIEW,
    payload,
});

/**
 * @description change employees list
 */
 export const appointmentChangeEmployeesList = (payload: AppointmentContextState["employeeList"]) => ({
    type: types.APPOINTMENT_UPDATE_EMPLOYEES_LIST,
    payload,
});

/**
 * @description change selectable locations
 */
 export const appointmentChangeLocations = (payload: AppointmentContextState["selectableLocations"]) => ({
    type: types.APPOINTMENT_UPDATE_SELECTABLE_LOCATIONS,
    payload,
});

/**
 * @description change entity form
 */
 export const appointmentChangeEntityForm = (payload: AppointmentContextState["createEntityForm"]) => ({
    type: types.APPOINTMENT_CHANGE_ENTITY_FORM,
    payload,
});